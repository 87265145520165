import {makeStyles} from "@material-ui/core/styles";
import {useHistory, useLocation} from "react-router-dom";
import {useDispatch} from "react-redux";
import MedicationStatusCell from "../MedicationStatusCell";
import {clientCache} from "../../../cache/slices/client/clientSlice";
import {formatMedication} from "../../../common/utils/formatUtils";
import { PRIMARY_COLOR } from "../../../common/constants";

const useStyles = makeStyles(() => ({
    medicationCard: {
        width: '100%',
        // minHeight: '68px',
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: "#ffffff",
        borderBottom: '1px solid #d0d0d0',
        paddingTop: '14px',
        paddingBottom: '9px',
        paddingLeft: '16px',
        paddingRight: '16px',
    },
    medicationColumn: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'auto',
        overflowY: 'hidden'
     },
    medication: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: '8px'
    },
    medicationDescription: {
        textOverflow: 'ellipsis',
        overflowX: 'hidden',
        whiteSpace: 'nowrap',
        fontSize: '16px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: PRIMARY_COLOR
    },
    imageColumn: {
        alignSelf: 'center'
    },
    imageContainer: {
        width: '40px',
        height: '40px'
    },
    image: {
        height: '100%',
        width: '100%'
    }
}));

export default function MedicationCard({medication}) {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const styles = useStyles();

    const handleMedicationClick = () => {
        dispatch(clientCache.selectMedication(medication));
        history.push(`${location.pathname}/medication`);
    }

    return (
        <div data-testid='MedicationCard' className={styles.medicationCard} onClick={handleMedicationClick}>
            <div className={styles.medicationColumn}>
                <div className={styles.medication}>
                    <div className={styles.medicationDescription}>{formatMedication(medication)}</div>
                </div>
                <MedicationStatusCell medication={medication}/>
            </div>
            <div className={styles.imageColumn}>
                <div className={styles.imageContainer}>
                    <img className={styles.image} src='../../images/iconIcoChevronRight.svg' alt='medication details'/>
                </div>
            </div>
        </div>
    )
}
