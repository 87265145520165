import {makeStyles} from "@material-ui/core/styles";
import {
    formatBirthDate,
    formatCityStateZip,
    formatCountyCountry,
    formatGender,
    getClientEmail,
    getPrimaryDiagnosis,
    getPhoneInfo,
    getPhysicalAddress
} from "../../../common/utils/formatUtils";
import {showEmailApplication, showMapApplication, showPhoneApplication} from "../../../common/utils/systemUtils";
import {useSelector} from "react-redux";
import syncService from "../../../common/services/syncService";
import {useEffect, useState} from "react";
import { QUATERNARY_COLOR } from "../../../common/constants";

const useStyles = makeStyles(() => ({
    addressItemsGrid: {
      width: '100%',
      display: 'grid',
      rowGap: '5px',
      gridTemplateColumns: '1fr',
      alignItems: 'flex-start'
    },
    emphasizedDetailValue: {
      textOverflow: 'ellipsis',
      overflowX: 'hidden',
      fontSize: '14px',
      fontWeight: "normal",
      fontStyle: "normal",
      color: QUATERNARY_COLOR
    },
    checked: {
      "&:after": {
        content: "'\\2713'",
        display: "inline-block",
        fontSize: '16px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: "#006c5A",
        paddingLeft: '10px'
      }
    },
    tableContainer: {
      width: "100%",
    },
    table: {
      width: '100%',
      border: 'none'
    },
    tableCell: {
      padding: '5px',
      border: 'none',
      borderColor: 'white',
      fontSize: '14px'
    },
    labelCell: {
      fontWeight: 'bold',
      fontSize: "14px"
    },
    textColor: {
      color: QUATERNARY_COLOR,
    },
    labelAge: {
      paddingLeft: "30px"
    },
    dob: {
      display: "flex",
      alignItems: "center"
    },
    align: {
      verticalAlign: "top"
    },
    labelWidth: {
      width: "25%"
    }
}));

export default function ClientDetails({client}) {
    const phoneTypes = useSelector(syncService.descriptorCacheService.phoneContactType.getResults());
    const diagnoses = useSelector(syncService.diagnosisService.getResults());
    const [physicalAddress, setPhysicalAddress] = useState({});
    const [phoneInfo, setPhoneInfo] = useState({});
    const [diagnosis, setDiagnosis] = useState('');
    const styles = useStyles();

    useEffect(() => {
        setPhysicalAddress(getPhysicalAddress(client.addresses));
    }, [client.addresses]);

    useEffect(() => {
        setPhoneInfo(getPhoneInfo(client.phones, phoneTypes));
    }, [client.phones, phoneTypes]);

    useEffect(() => {
        setDiagnosis(getPrimaryDiagnosis(diagnoses));
    }, [diagnoses]);

    function physicalAddressDiv() {
      return (
        <div data-testid='ClientDetails_address' className={styles.addressItemsGrid}  onClick={() => showMapApplication(client)}>
          {physicalAddress.street1 &&
          <div className={styles.emphasizedDetailValue}>{physicalAddress.street1}</div>
          }
          {physicalAddress.street2 &&
          <div className={styles.emphasizedDetailValue}>{physicalAddress.street2}</div>
          }
          {physicalAddress.aptSuite &&
          <div className={styles.emphasizedDetailValue}>{'APT/Suite ' + physicalAddress.aptSuite}</div>
          }
          {(physicalAddress.city || physicalAddress.state || physicalAddress.zip) &&
          <div className={styles.emphasizedDetailValue}>{formatCityStateZip(physicalAddress.city, physicalAddress.state, physicalAddress.zip, physicalAddress.zipPlusFour)}</div>
          }
          {(physicalAddress.county || physicalAddress.country) &&
          <div className={styles.emphasizedDetailValue}>{formatCountyCountry(physicalAddress.county, physicalAddress.country)}</div>
          }
        </div>
      )
    }

    return (
      <div className={styles.tableContainer}>
        <div className={`${styles.table} ${styles.heading}`}>
          <tbody>
            <tr>
              <td className={`${styles.tableCell} ${styles.labelCell}`}>Gender:</td>
              <td className={`${styles.tableCell} ${styles.valuesPadding}`}> {formatGender(client.gender)}</td>
            </tr>
            <tr>
              <td className={`${styles.tableCell} ${styles.labelCell}`}>DOB:</td>
              <td className={`${styles.tableCell} ${styles.dob} ${styles.valuesPadding}`}>{formatBirthDate(client.birthDate)}
              <td className={`${styles.tableCell} ${styles.labelCell} ${styles.labelAge}`}>Age:</td>
              <td className={`${styles.tableCell} `}>{client.age}</td></td>
            </tr>
            <tr>
              {phoneInfo.primaryPhoneNumber && <td className={`${styles.tableCell} ${styles.labelCell}`}>{phoneInfo.primaryLabel}</td>}  
              {phoneInfo.primaryPhoneNumber && <td data-testid='ClientDetails_phone' className={`${styles.tableCell} ${styles.textColor} ${styles.valuesPadding}`  + (phoneInfo.primaryChecked ? ' ' + styles.checked : '')} onClick={() => showPhoneApplication(phoneInfo.primaryPhoneNumber)}>{phoneInfo.primaryPhoneNumber}</td>}
            </tr>
            <tr>
              {phoneInfo.primaryPhoneNumber && <td className={`${styles.tableCell} ${styles.labelCell}`}>{phoneInfo.secondaryLabel}</td>}
              {phoneInfo.primaryPhoneNumber &&<td data-testid='ClientDetails_phone_secondary' className={`${styles.tableCell} ${styles.textColor} ${styles.valuesPadding}`+  (phoneInfo.secondaryChecked ? ' ' + styles.checked : '')} onClick={() => showPhoneApplication(phoneInfo.secondaryPhoneNumber)}>{phoneInfo.secondaryPhoneNumber}</td>}
            </tr>
            <tr>
              <td className={`${styles.tableCell} ${styles.labelCell}`}>Email:</td>
              <td data-testid='ClientDetails_email' className={`${styles.tableCell} ${styles.textColor} ${styles.valuesPadding}`} onClick={() => showEmailApplication(getClientEmail(client.emails))}>{getClientEmail(client.emails)}</td>
            </tr>
            <tr>
              <td className={`${styles.tableCell} ${styles.labelCell} ${styles.align} ${styles.labelWidth}`}>Physical Address:</td>
              <td className={`${styles.tableCell} ${styles.textColor} ${styles.valuesPadding}`}>{physicalAddressDiv()}</td>
            </tr>
            <tr>
              <td className={`${styles.tableCell} ${styles.labelCell} ${styles.align}`}>Diagnosis:</td>
              <td className={`${styles.tableCell} ${styles.valuesPadding}`}>{diagnosis}</td>
            </tr>
          </tbody>
        </div>
      </div>
    )
}
