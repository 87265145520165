import {makeStyles} from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";
import { FILTER_COLOR, labelForAllergy, labelForDocument, labelForMessages, labelForVitals, PRIMARY_COLOR } from "../../../common/constants";
import { useSelector } from "react-redux";
import syncService from "../../../common/services/syncService";
import { useEffect, useState } from "react";
import { orderBy } from "lodash";

const useStyles = makeStyles((theme) => ({
    sectionContainer: {
        width: '100%',
        height: '62px',
        backgroundColor: "#ffffff",
        display: 'flex',
        borderTop: '1px solid #979797',
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingLeft: '8px',
        paddingRight: '8px',
        justifyContent: 'space-between'
    },
    leftContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    rightContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    sectionLabel: {
        fontWeight: "bold",
        fontStyle: "normal",
        color: PRIMARY_COLOR,
        paddingBottom: '4px'
    },
    sectionImageContainer: {
        paddingLeft: '14px',
        paddingRight: '14px',
        filter: FILTER_COLOR
    },
    chevronImageContainer: {
        paddingRight: '14px'
    },
    messages: {
        fontSize: '14px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        paddingTop: '6px',
        width:'63vw',
        margin: 0,
        color: '#28292B',
        fontWeight: 'normal'
    },
    sectionLabelDisable: {
        color: 'grey'
    },
    commanLabel: {
        height: '45px',
        display: 'flex',
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingLeft: '8px',
        justifyContent: 'space-between',
        filter: FILTER_COLOR
    },
    sectionImageContainerDisable: {
        height: '42px',
        display: 'flex',
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingLeft: '8px',
        paddingRight: '20px',
        justifyContent: 'space-between'
    },
    allLabels: {
        height: '45px',
        display: 'flex',
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingLeft: '8px',
        justifyContent: 'space-between',
        filter: FILTER_COLOR
    },
    sectionImageContainerEnable: {
        paddingRight: '26px',
    },
    sectionImageContainerAllergy: {
        paddingRight: '19px',
    },
    sectionImageContainerDocument: {
        paddingRight: '28px',
    },
    sectionImageContainerMessageEnable: {
        paddingRight: '21px',
    },
    allLabelsNoFilter: {
        height: '45px',
        display: 'flex',
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingLeft: '8px',
        justifyContent: 'space-between'
    },
    sectionImageContainerVitals: {
        paddingRight: '20px !important'
    }
}));

export default function SectionButton({label, imageSrc, destination}) {
    const history = useHistory();
    const styles = useStyles();
    const clientMessages = useSelector(syncService.messageService.getResults());

    const [messages, setMessages] = useState([]);

    useEffect(() => {
        let orderedMessages = [];
        if(clientMessages && clientMessages.length > 0) {
          orderedMessages = orderBy(clientMessages, ['postDate'], ['desc']);
        }
        setMessages(orderedMessages);
    }, [clientMessages]);
    
    const handleSectionClick = () => {
        if (destination) {
            history.push(destination);
        }
    }

    return (
        <div data-testid='SectionButton' className={styles.sectionContainer} onClick={ handleSectionClick}>
            <div className={styles.leftContainer}>
                <div className={ (label === labelForMessages && messages?.length === 0) ? `${ styles.allLabelsNoFilter} ${ styles.sectionImageContainerDisable}` : (label === labelForMessages && messages?.length > 0) ? `${ styles.allLabels} ${styles.sectionImageContainerMessageEnable}`  :  (label === labelForAllergy) ? `${ styles.allLabels} ${styles.sectionImageContainerAllergy}`: (label === labelForVitals) ? `${ styles.allLabels} ${styles.sectionImageContainerVitals}` : (label === labelForDocument) ?  `${ styles.allLabels} ${styles.sectionImageContainerDocument}` :  `${ styles.allLabels} ${styles.sectionImageContainerEnable}` } >
                    <img src= {imageSrc} alt="images"/>
                </div>
                <div className={messages?.length === 0 && label === labelForMessages  ? styles.sectionLabelDisable : styles.sectionLabel}>
                    {label}
                    {(label === labelForMessages && messages?.length === 0) ? <div style={{fontSize:"12px"}}>No messages found</div> : (label === labelForMessages && messages?.length > 0) ?  <div className={styles.messages}>{messages[0].message}</div>:  ''}
                </div>
            </div>
            <div className={styles.rightContainer}>
                <div className={styles.chevronImageContainer} >
                    <img style={{height:"40px"}} src= {label === labelForMessages && messages?.length === 0? '../images/arrowRightIcon.png' : '../images/iconIcoChevronRight.svg'} alt='section detail' />
                </div>
            </div>
        </div>
    )
}
