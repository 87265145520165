import {dateToStringWithFormat} from "../../utils/formatUtils";
import Section from "../Section";
import {DateTime} from "luxon";
import AppointmentDay from "../../../features/dashboard/AppointmentDay";
import {createNewAppointment, sortAppointments} from "../../utils/appointmentUtils";
import {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import AppointmentDialog from "../../../features/facesheet/AppointmentDialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as msgConst from '../../../features/forms/common/Messages';
import { useSelector, useStore } from "react-redux";
import { appCache } from "../../../cache/slices/app/appSlice";
import syncService from "../../../common/services/syncService";
import { PRIMARY_COLOR } from "../../constants"
import clientService from "../../services/clientService";
import { ASYNC_OPERATION_STATUS } from "../../../cache/asyncHandler";

const useStyles = makeStyles((theme) => ({
    appointments: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    appointmentDays: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            borderTop: '2px solid #979797',
        },
        [theme.breakpoints.up(601)]: {
            paddingRight: '10px'
        }
    },
    loadingSpinnerContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    loadingSpinner: {
        color: PRIMARY_COLOR
    },
    noAppointmentMessage: {
        width: '100%',
        height: '100%',
        display: 'flex',
        fontSize: '18px',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'bold'
    }
}));

function getAppointmentDays(appointments, startDate, maxDaysToDisplay, maxAppointmentsToDisplay, includeEmpty, ignorePastAppointments, maxDate){
    const nowMinus15Minutes = DateTime.now().minus({minutes: 15});
    const appointmentsByDay = {};
    let counter = 0;

    if (includeEmpty && maxDaysToDisplay > 0){
        let sequentialAppointmentDay = startDate;

        for (let index = 0; index < maxDaysToDisplay; index++){
            if (!maxDate || (maxDate && sequentialAppointmentDay <= maxDate)) {
                const formattedDay = dateToStringWithFormat(sequentialAppointmentDay, 'LLLL dd, yyyy');
                appointmentsByDay[formattedDay] = [];
                sequentialAppointmentDay = sequentialAppointmentDay.plus({days: 1});
            }
        }
    }

    return appointments.reduce((groupedAppointments, appointment) => {
            if (appointment && (maxAppointmentsToDisplay <= 0|| counter < maxAppointmentsToDisplay)) {
                if (!ignorePastAppointments || (ignorePastAppointments && appointment.endDateInstance > nowMinus15Minutes)) {
                    counter++;
                    const formattedDay = dateToStringWithFormat(appointment.startDateInstance, 'LLLL dd, yyyy');
                    if (!groupedAppointments[formattedDay] && maxDaysToDisplay <= 0) {
                        groupedAppointments[formattedDay] = [];
                    }

                    if (groupedAppointments[formattedDay]) {
                        groupedAppointments[formattedDay].push(appointment);
                    }
                }
            }

        return groupedAppointments;
    }, appointmentsByDay);
}

function renderAppointmentsByDay(appointmentsByDay, expandAll, hideAccordianIcon, call = ''){
    const appointmentDays = [];

    Object.entries(appointmentsByDay).forEach(([day, appointments], index) =>
        appointmentDays.push(<AppointmentDay key={day} initialExpanded={index === 0 || expandAll} date={day} appointments={appointments} hideIcon={hideAccordianIcon && appointments.length <= 0} call = {call}/>)
    );

    return appointmentDays;
}

export default function Appointments({appointments, appointmentsLoading, startDate, maxDate, maxDaysToDisplay = -1, maxAppointmentsToDisplay = -1, standalone = false, expandAll = false, includeEmpty = false, ignorePastAppointments = false, hideAccordianIcon = false, call = '', selectedClient }) {
    const [showAppointmentDialog, setShowAppointmentDialog] = useState(false);
    const [currentAppointment, setCurrentAppointment] = useState(null);
    const [appointmentsByDay, setAppointmentsByDay] = useState();
    const styles = useStyles();
    const isHandheld = useSelector(appCache.isHandheld);

    const programsLoading = useSelector(syncService.programService.isLoading());
    const serviceLocationsLoading = useSelector(syncService.serviceLocationService.isLoading());
    const organizationsLoading = useSelector(syncService.organizationService.isLoading());
    const caseloadsLoading = useSelector(syncService.caseloadService.isLoading());
    const activityServiceStatus = useSelector(syncService.activityService.getStatus());
    const store = useStore();
    const [clientData, setClientData] = useState([]);

    useEffect(() => {
        if (appointments) {
            setAppointmentsByDay(getAppointmentDays(sortAppointments(appointments), startDate, maxDaysToDisplay, maxAppointmentsToDisplay, includeEmpty, ignorePastAppointments, maxDate));
        }
    }, [appointments, startDate, maxDate, maxDaysToDisplay, maxAppointmentsToDisplay, includeEmpty, ignorePastAppointments]);

    const handleAddRequest = () => {
        clientService.fetchAddresses({store}, appCache.getClients(store.getState()), setClientData);
        setCurrentAppointment(createNewAppointment(startDate, '', selectedClient));
        setShowAppointmentDialog(true);
    }

    const handleAppointmentDialogClose = (appointment) => {
        setShowAppointmentDialog(false);
    }

    const renderAppointment = () => {
            return (
                <div className={styles.appointmentDays}>
                    {appointmentsByDay && renderAppointmentsByDay(appointmentsByDay, expandAll, hideAccordianIcon, call)}
                </div>
            );
    }

    const renderEmptyAppointment = () => {
        return (
            <div className={styles.noAppointmentMessage}>
                {msgConst.noAppointmentMessage}
            </div>
        );
    }
    
    const findAppointments = () => {
        if(appointmentsByDay !== undefined) {
            let scheduledAppointment = [];
            const appointments = renderAppointmentsByDay(appointmentsByDay);
            appointments.forEach((appointment) => {
                const appointments = appointment.props.appointments;
                if(appointments && appointments.length > 0) {
                    scheduledAppointment.push(...appointments);
                }
            });
            if(scheduledAppointment && scheduledAppointment.length > 0) {
                return (
                    renderAppointment()
                )
            } else {
                return (
                    renderEmptyAppointment()
                )
            }
        }
    }

    const renderAppointmentDays = (call) => {
        if (caseloadsLoading ||programsLoading || activityServiceStatus !== ASYNC_OPERATION_STATUS.SUCCESS || organizationsLoading || serviceLocationsLoading || appointmentsLoading) {
            return (
                <div className={`${styles.appointmentDays} ${styles.loadingSpinnerContainer}`}>
                    <CircularProgress size={50} className={styles.loadingSpinner} />
                </div>
            );
        } else {
            if(isHandheld) {
                return(
                    findAppointments()
                )
            } else {
                if(call === 'FacesheetPage') {
                    return(
                        findAppointments()
                    )
                } else {
                    return (
                        renderAppointment()
                    )
                }
            }
        }
    }
    return (
        <div className={styles.appointments}>
            <Section title='Appointments' standalone={standalone} handleAddRequest={handleAddRequest} paddingRight='10px' />
            {renderAppointmentDays(call)}
            {showAppointmentDialog &&
            <AppointmentDialog
                onClose={handleAppointmentDialogClose}
                appointment={currentAppointment}
                showStatus={false}
                clientData={clientData}
            />
            }
        </div>
    );
}
