import React from 'react'
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    errorMessage : {
        top: (theme.textFieldHeightRaw + 6) + 'px',
        fontSize: '12px',
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: '16px',
        letterSpacing: '0.18px',
        color: "#dc0707"
    },
}));

const DisplayErrorMessages = (props) => {
    const styles = useStyles();
    let fieldDescriptor = props.fieldDescriptor;
    let isNextClicked =  props.fieldDescriptor.nextClicked;

    return (
        <div className={styles.configurableForm}>
            { fieldDescriptor.onChangeError && <div className={styles.errorMessage}> { fieldDescriptor.onChangeError } </div> }
            
            { isNextClicked && ( !fieldDescriptor.onChangeError || (fieldDescriptor.onChangeError && fieldDescriptor.onChangeError === '') )
            ?
                ( fieldDescriptor.requiredFieldErrorMessage !== '' )
                ?
                    <div className={styles.errorMessage}> { fieldDescriptor.requiredFieldErrorMessage } </div>
                :
                    ( fieldDescriptor.businessRuleError ) && <div className={styles.errorMessage}> { fieldDescriptor.businessRuleError } </div>
            :
            ''
            }
        </div>
    )
}   

export default DisplayErrorMessages