import {DateTime} from "luxon";
import {sortWithDirection} from "./miscUtils";

export const DEFAULT_DATE_TIME_FORMAT = 'LL/dd/yyyy HH:mm';
export const DEFAULT_DATE_TIME_FORMAT_MERIDIAN = 'LL/dd/yyyy hh:mm a';
export const DEFAULT_DATE_FORMAT = 'LL/dd/yyyy';
export const DEFAULT_TIME_FORMAT = 'hh:mm a';
export const DEFAULT_DATE_EMPTY = 'Not Set';
export const MILITARY_TIME_FORMAT = 'HH:mm';

export const APPOINTMENT_START_STOP_DATE_TIME_FORMAT = 'yyyy-LL-dd hh:mm:ss a';
export const DEFAULT_DESCRIPTOR_DATE_TIME_FORMAT = "yyyy-LL-dd'T'HH:mm";
export const VISIT_DATE_TIME_FORMAT = "yyyy-LL-dd'T'HH:mm:ss'Z'";
export const ISO_DATE_TIME_FORMAT = "yyyy-MM-dd HH:mm:ss";

export const formatOrganization = (organization, includeId) => {
    if (includeId){
        return organization ? organization.name + ' - ' + organization.organizationId : '';
    }

    return organization ? organization.name : '';
}

export const formatUserName = (user) => {
    return user && user.lastName!== undefined ? user.lastName + ', ' + user.firstName : '';
}

export const formatStaffName = (staff) => {
    return staff ? staff.lastName + ', ' + staff.firstName + ' (' + staff.staffId + ')': '';
}

export const formatClientId = (client) => {
    if (!client){
        return '';
    }

    return client.idNumber ?? client.id;
}

export const formatClientName = (client) => {
    const clientId = formatClientId(client);
    return client ? `${client.lastName}, ${client.firstName} (${clientId})` : '';
}

export const formatClientNameWithoutId = (client) => {
    return client ? `${client.lastName}, ${client.firstName}` : '';
}

export const formatGender = (gender) => {
    if (gender) {
        const genderUpper = gender.toUpperCase();

        if (genderUpper === 'M' || genderUpper === 'MALE') {
            return 'Male';
        } else if (genderUpper === 'F' || genderUpper === 'FEMALE') {
            return 'Female';
        } else {
            return gender;
        }
    }

    return '';
}

export const capitalize = (s) => {
    if (typeof s !== 'string') return s;
    return s.charAt(0).toUpperCase() + s.slice(1);
}

export const formatMedication = (medication) => {
    return medication ? `${(medication.medication)} ${medication.dosage}` : '';
}

export const formatActivity = (activity) => {
    return activity.description !== undefined ? `${activity.description} (${activity.activityId})` : '';
}

export const formatDiagnosis = (diagnosis) => {
    return diagnosis ? `${diagnosis.iCD10Code}` : '';
}

export const formatText = (text, textToPrepend, textToAppend) => {
    let trimmedText = text ? text.trim() : '';
    let formattedText = '';

    if (trimmedText.length > 0){
        if (textToPrepend){
            formattedText += (textToPrepend + trimmedText);
        } else {
            formattedText = trimmedText;
        }

        if (textToAppend){
            formattedText += textToAppend;
        }
    }

    return formattedText;
}

export const formatAddress = (address, evvState, aptSuitePrefix = 'APT/Suite ') => {
    let formattedText = '';

    if (address.street1){
        formattedText += formatText(address.street1);
    }

    if (address.street2){
        if (formattedText.length > 0){
            formattedText += ' ';
        }
        formattedText += formatText(address.street2);
    }

    if (address.aptSuite){
        if (formattedText.length > 0){
            formattedText += ', ';
        }
        formattedText += aptSuitePrefix + formatText(address.aptSuite);
    }

    if (address.city){
        if (formattedText.length > 0){
            formattedText += ', ';
        }
        formattedText += formatText(address.city);
    }

    if (address.state){
        if (formattedText.length > 0){
            formattedText += ', ';
        }
        formattedText += formatText(address.state);
    }

    if (address.zip){
        if (formattedText.length > 0){
            formattedText += ' ';
        }
        formattedText += formatText(address.zip);
    }

    if (evvState === 'Georgia'){
        if(address.type){
            if (address.type === 'PHYS'){
                formattedText += ' (PHYSICAL)';  
            } else {
                formattedText += ` (${formatText(address.type)})`;
            }
        }    
    }

    return formattedText;
}

export const formatCityStateZip = (city, state, zip, zipPlusFour) => {
    const formattedCity = formatText(city);
    const formattedState = formatText(state);
    let formattedText = '';
    let separator = '';

    if (formattedCity && formattedState){
        separator = ", ";
    }

    formattedText += (formattedCity + separator + formattedState + formatText(zip, ' ') + formatText(zipPlusFour, ' - '));

    return formattedText;
}

export const formatCountyCountry = (county, country) => {
    const formattedCounty = formatText(county);
    const formattedCountry = formatText(country || 'United States');
    let formattedText = '';
    let separator = '';

    if (formattedCounty && formattedCountry){
        separator = ", ";
    }

    formattedText += formattedCounty + separator + formattedCountry;

    return formattedText;
}

export const isToday = (dateToCheck) => {
    return dateToCheck && dateToCheck.toISODate() === DateTime.local().toISODate();
}

export const defaultDateAsString = (date, emptyValue = DEFAULT_DATE_EMPTY) => {
    return date ? dateToStringWithFormat(date, DEFAULT_DATE_FORMAT) : emptyValue;
}

export const defaultUTCDateAsString = (date, emptyValue = DEFAULT_DATE_EMPTY) => {
    return date ? convertUTCDateToMMDDYYYY(date): emptyValue ;
}

export const convertUTCDateToMMDDYYYY = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const year = date.getUTCFullYear();
    const formattedDate = `${month}/${day}/${year}`;
    return formattedDate;
}

export const defaultDateTimeAsString = (date, emptyValue = DEFAULT_DATE_EMPTY) => {
    return date ? dateToStringWithFormat(date, DEFAULT_DATE_TIME_FORMAT) : emptyValue;
}

export const defaultTimeAsString = (date, emptyValue = DEFAULT_DATE_EMPTY) => {
    return date ? dateToStringWithFormat(date, DEFAULT_TIME_FORMAT) : emptyValue;
}

export const dateToString = (date) => {
    return dateToStringWithFormat(date, DEFAULT_DATE_TIME_FORMAT);
}

export const dateToStringWithFormat = (date, format) => {
    return date ? date.toFormat(format ? format : DEFAULT_DATE_TIME_FORMAT) : '';
}

export const dateTimeZoneToString = (date) => {
    return dateToStringWithFormat(date, VISIT_DATE_TIME_FORMAT);
}

export const isoDateToString = (date) => {
    return DateTime.fromFormat(date,'yyyy-MM-dd HH:mm:ss').toFormat('yyyy/MM/dd');
}

export const stringToDate = (text) => {
    return stringToDateWithFormat(text, DEFAULT_DATE_FORMAT);
}

export const stringToDateDescriptor = (text) => {
    return text ? DateTime.fromFormat(text, DEFAULT_DESCRIPTOR_DATE_TIME_FORMAT) : null;
}

export const stringToDateWithFormat = (text, format) => {
    return text ? DateTime.fromFormat(text, format ? format : DEFAULT_DATE_FORMAT) : null;
}

export const stringToDateToStringWithFormat = (text, inputFormat, outputFormat) => {
    const date = stringToDateWithFormat(text, inputFormat);

    return dateToStringWithFormat(date, outputFormat || DEFAULT_DATE_FORMAT);
}

export const stringToDateWithTime = (value) => {
    return DateTime.fromFormat(value,'yyyy-MM-dd HH:mm:ss').toFormat('MM/dd/yyyy');
}

export const stringToDateWithTimeTandZ = (value) => {
    return DateTime.fromFormat(value,"yyyy-LL-dd'T'HH:mm:ss'Z'").toFormat('MM/dd/yyyy');
}

export const formatBirthDate = (birthDate) => {
    let formattedDate = birthDate;

    if (birthDate && birthDate.length > 0){
        const birthDateAsDate = stringToDateWithFormat(birthDate, 'yyyy-LL-dd');

        formattedDate = defaultDateAsString(birthDateAsDate, '');
    }

    return formattedDate;
}

export const formatLastSeenDate = (dateLastActivity) => {
    return dateLastActivity ? stringToDateWithTimeTandZ(dateLastActivity) : '';
}

export const getClientEmail = (emails) => {
    let email = '';

    if (emails){
        const emailEntity = emails.find(e => e.email && e.email.length > 0);
        if (emailEntity){
            email = emailEntity.email;
        }
    }

    return email;
}

export const getPhoneInfo = (phones, phoneTypes) => {
    const phoneInfo = {phoneCount: 0};

    if (phones && phones.length > 0){
        const copyOfPhones = [...phones];
        let secondaryPhoneEntity = null;
        let primaryPhoneEntity = null;
        let extraPhoneEntity = null;

        phoneInfo.phoneCount = copyOfPhones.length;

        for (let phone of copyOfPhones){
            if (phone.primary){
                if (primaryPhoneEntity){
                    secondaryPhoneEntity = phone;
                }else {
                    primaryPhoneEntity = phone;
                }
                continue;
            }

            if (!phone.primary){
                if (secondaryPhoneEntity && !secondaryPhoneEntity.primary){
                    extraPhoneEntity = phone;
                } else {
                    secondaryPhoneEntity = phone;
                }
            }
        }

        if (!primaryPhoneEntity){
            if (secondaryPhoneEntity){
                primaryPhoneEntity = secondaryPhoneEntity;
                if (extraPhoneEntity){
                    secondaryPhoneEntity = extraPhoneEntity;
                } else {
                    secondaryPhoneEntity = null;
                }
            }
        }

        if (primaryPhoneEntity){
            const primaryPhoneType = phoneTypes.find(pt => pt.descriptorId === primaryPhoneEntity.typeDscId);
            phoneInfo.primaryLabel = (primaryPhoneType ? primaryPhoneType.name : 'Phone') + ':';
            phoneInfo.primaryPhoneNumber = formatPhoneNumber(primaryPhoneEntity);
            phoneInfo.primaryChecked = primaryPhoneEntity.okToId === 'Y';
        }

        if (secondaryPhoneEntity) {
            const secondaryPhoneType = phoneTypes.find(pt => pt.descriptorId === secondaryPhoneEntity.typeDscId);
            phoneInfo.secondaryLabel = (secondaryPhoneType ? secondaryPhoneType.name : 'Phone') + ":";
            phoneInfo.secondaryPhoneNumber = formatPhoneNumber(secondaryPhoneEntity);
            phoneInfo.secondaryChecked = secondaryPhoneEntity.okToId === 'Y';
        }
    }

    return phoneInfo;
}

export const formatPhoneNumber = (phoneEntity) => {
    let phoneNumber = '';

    if (phoneEntity){
        phoneNumber = formatText(phoneEntity.numberIntl, '+', ' ')
                    + formatText(phoneEntity.areaCode, '(', ') ')
                    + formatText(phoneEntity.number)
                    + formatText(phoneEntity.extension, ' x:')
    }

    return phoneNumber;
}

export const getPhysicalAddress = (addresses) => {
    let address;

    if (addresses){
        address = addresses.find(a => a.type && a.type.toUpperCase() === 'PHYS');
    }

    return address || {};
}

export const getPrimaryDiagnosis = (diagnoses) => {
    let diagnosis = 'No diagnosis found';

    if (diagnoses && diagnoses.length > 0){
        const sortedDiagnoses = sortWithDirection(
            diagnoses,
            ['priority'],
            ['asc']
        );

        diagnosis = sortedDiagnoses[0].diagnosis;
    }

    return diagnosis;
}

export const getAgeFromDateText = (dateString) => {
    return dateString && getAgeFromDate(stringToDate(dateString));
}

// For luxon, monday is start of week and so considered day 1 so we account for that by considering Sunday (day 7)
// as the first day, returning 1 and returning the weekday + 1 in all other cases
export const dayOfWeek = (dateTime) => {
    return dateTime.weekday === 7 ? 1 : dateTime.weekday + 1;
}

export const boundaries = () => {
    return {
        past: pastBoundary(),
        future: futureBoundary(),
        isDateTimeOutOfBounds: function(dateTime){
            const startOfDate = dateTime?.startOf('day');
            return startOfDate < this.past || startOfDate > this.future;
        }
    };
}

export const pastBoundary = () => {
    return DateTime.now().startOf('day').minus({days: 7});
}

export const futureBoundary = () => {
    return DateTime.now().startOf('day').plus({days: 30});
}

export const getAgeFromDate = (birthDate) => {
    return Math.floor(DateTime.now().diff(birthDate, "years").years);
}

export const formatInputValue = (value) => {
    let formattedValue = value;

    if (value && value.length > 0){
        formattedValue = value.trim()
    }

    return formattedValue;
}

export function getFormattedStartDate(startDate){
    return dateToStringWithFormat(startDate, 'LL/dd/yyyy')
}

export function getFormattedStartTime(startDate){
    return dateToStringWithFormat(startDate, 'hh:mm a')
}

export function getFormattedTimeMilitary(dateTime){
    return dateToStringWithFormat(dateTime, MILITARY_TIME_FORMAT);
}

export function getFormattedAppointmentDuration(appointment) {
    let endDateInstance = appointment.endDateInstance;
    if (endDateInstance < appointment.startDateInstance && appointment.status === 'In Session'){
        endDateInstance = DateTime.now();
    }

    return getFormattedDuration(appointment.startDateInstance, endDateInstance);
}

export function getFormattedDuration(startDate, endDate) {
    let diffInMs = endDate.toMillis() - startDate.toMillis();
    const seconds = diffInMs / 1000;
    const minutes = seconds / 60;

    const hrs = Math.floor(minutes / 60);
    const mins =  Math.floor(minutes % 60);
    const secs =  Math.floor(seconds);
    let formattedDuration = '';

    if (hrs >= 1) {
        formattedDuration = hrs + ((hrs === 1) ? ' hr' : ' hrs');
    }

    if (mins >= 1){
        formattedDuration += ((formattedDuration.length > 0 ? ', ' : '') + mins + ((mins === 1) ? ' min' : ' mins'));
    }

    if (formattedDuration.length === 0){
        formattedDuration = secs + ((secs === 1) ? ' sec' : ' secs');
    }

    return formattedDuration;
}

export function getFormattedDurationAsCountdown(startDate, endDate) {
    return endDate.diff(startDate, ['hours', 'minutes']).toFormat("hh:mm");
}

export function formatSecondsToEnglish(diffInSeconds) {
    const seconds = Math.floor(diffInSeconds >=60 ? diffInSeconds % 60 : diffInSeconds);
    const minutes = Math.floor(diffInSeconds >=60 ? diffInSeconds / 60 : 0);
    let formattedDuration = '';

    if (minutes > 0){
        formattedDuration += ((formattedDuration.length > 0 ? ', ' : '') + minutes + ((minutes === 1) ? ' minute' : ' minutes'));
    }

    if (seconds > 0){
        formattedDuration += ((formattedDuration.length > 0 ? ', ' : '') + seconds + ((seconds === 1) ? ' second' : ' seconds'));
    }

    return formattedDuration;
}

export const convertToJSDate = (value) => {
    if (isLuxonDate(value)){
        return value.toJSDate();
    } else if (isString(value) && value.length >= 10){
        return new Date(value);
    } else if (isDate(value)){
        return value;
    }

    throw new Error("Unable to convert " + value + " to a Javascript Date.");
}

export const convertToLuxonDate = (value) => {
    if (isLuxonDate(value)){
        return value;
    } else {
        const jsDate = convertToJSDate(value);
        if (jsDate){
            return DateTime.fromJSDate(jsDate);
        }
    }

    throw new Error("Unable to convert " + value + " to a Luxon DateTime.");
}

function isLuxonDate(value){
    return value && DateTime.isDateTime(value);
}

function isDate(value){
    return (value instanceof Date);
}

function isString(value){
    return (typeof value === 'string' || value instanceof String);
}

export const booleanToString = (value) => {
    return value ? 'Y' : 'N';
}

const IMAGE_START_URL = 'data:image/png;base64,';
export const formatSignatureForWrite = (signature) => {
    const index = signature ? signature.indexOf(IMAGE_START_URL) : -1;

    return index !== -1 ? signature.substring(index + IMAGE_START_URL.length) : signature;
}

export const convertTandZToDateTime = (value) => {
    return DateTime.fromFormat(value,"yyyy-LL-dd'T'HH:ms'Z'").toFormat('yyyy-MM-dd HH:mm:ss');
}

export const formatUserNameForSign = (user) => {
    return user ? user.firstName + ' ' + user.lastName : '';
}

export const convertTandZToMMDDYYYY = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const year = date.getUTCFullYear();
    let hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    // Format the time string with AM/PM
    const time = `${hours}:${minutes} ${ampm}`;
    const formattedDate = `${month}/${day}/${year} ${time}`;
    return formattedDate;
}

export const dateForWeek = (newDate) => {
    return new Date(newDate);
}

export const weekViewDateFormat = (currentDate) => {
    let month = (newdate) => {
        const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        let monthIndex = dateForWeek(newdate).getMonth();
        let monthName = monthNames[monthIndex];
        return monthName;
    }
    let startOfWeek = ((currentDate).startOf('week')).minus({days: 1});
    let endOfWeek = ((currentDate).endOf('week')).minus({days: 1});
   
    let startDayOfMonth = dateForWeek(startOfWeek).getDate();
    let endDayOfMonth = dateForWeek(endOfWeek).getDate();
    let startMonth = month(startOfWeek);
    let endMonth = month(endOfWeek);
    let startYear = dateForWeek(startOfWeek).getFullYear();
    let endYear = dateForWeek(endOfWeek).getFullYear();
   
    if (startMonth === 'December' && endMonth === 'January') {
        endYear = startYear + 1;
    }
           
    let fullWeekDateFormat = `${startMonth} ${startDayOfMonth}, ${startYear !== endYear ? startYear : endYear} - ${endMonth} ${endDayOfMonth}, ${startYear === endYear ? startYear : endYear}`;
        return fullWeekDateFormat;
}

