import { evvRepository } from "../../../db/evv";
import { databaseValue } from "./HandleChangedInputUtils";
import { isDescriptorExpired } from "../../../common/utils/appointmentUtils";
import { CONVERTDD } from "../../../common/constants";

export const medicationDb = () => {
    return evvRepository.evvDb.medication.toArray();
}
export const programDb = () =>{
    return evvRepository.evvDb.program.toArray();
}
export const descriptorDropDownDb = (currentOrganizationId) =>{
    return evvRepository?.evvDb?.descriptor?.where('organizationId').anyOf('', currentOrganizationId).toArray();
}

export const referralSourceDropDownDb = () =>{
    return evvRepository.evvDb.referralSource.toArray();
}

export const getDescriptorList = async (currentOrganizationId) => {
    let result = await descriptorDropDownDb(currentOrganizationId);
    return result;
}

export const assignOptionsToDropDown = async (field, selectedClient, documentController) => {
    const currentOrganizationId = documentController?.dataProvider.getUser().currentOrganizationId;

    switch (field.type) {
        case "StaffDropDown":
            field.options = documentController.dataProvider.getStaffForOrganization(currentOrganizationId);
        break;

        case "ProgramDropDown":
            await programDb().then((result) => {
                // assign options from table.
                field.options = changeTheFormatAsPerRequirement(field, result, field.type);
            }).catch((error) => {
                console.log('Error: ', error);
            });
        break;

        case "ActiveMedicationDropDown":
            await medicationDb().then((result) => {
                // assign options from table.
                field.options = changeTheFormatAsPerRequirement(field, result, field.type, selectedClient);
            }).catch((error) => {
                console.log('Error: ', error);
            });
        break;

        case "Descriptor DropDown":
            await descriptorDropDownDb(currentOrganizationId).then((result) => {
                // assign options from table.
                field.options = changeTheFormatAsPerRequirement(field, result, field.type);
            }).catch((error) => {
                console.log('Error: ', error);
            });
        break;

        case "ReferralSourceDropDown":
            await referralSourceDropDownDb().then((result) => {
                // assign options from table.
                let referralSource = result.filter(res => res.organizationIds.includes(currentOrganizationId));
                field.options = changeTheFormatAsPerRequirement(field, referralSource, field.type);
            }).catch((error) => {
                console.log('Error: ', error);
            });
        break;

        default:
        break;
    }
    return field.options;
};

export const changeTheFormatAsPerRequirement = (field, dropDownList, type, selectedClient) => {
    let options = [];
    if(type === "ActiveMedicationDropDown") {
        dropDownList.forEach((value) => {
            // eslint-disable-next-line
            if(value.status === "Active" && value.clientId == selectedClient.clientId) {
                options.push({'value': value.medication});
            }
        })
    } else if(type === "ProgramDropDown") {
        dropDownList.forEach((value) => {
            const arrayWithFilterObjects = options.filter((o) => o.programId === value.programId);
            if (!arrayWithFilterObjects.length) {
                options.push({'programId': value.programId, 'value': value.name +  " ("+value.code+")"});
            }
        })
    } else if(type === "StaffDropDown") {
            dropDownList.forEach((value) => {
            const display = value.lastName + ", " + value.firstName + " (" + value.staffId  +") ";
            options.push({'staffId': value.staffId, 'value': display});
        })
    } else if(type === "Descriptor DropDown") {
        let attributeType = "";
        let filteredObj = field.attributes.filter((attribute) => attribute.attributeId === field.id +"_type");
        attributeType = filteredObj[0].value;
        dropDownList.forEach((value) => {
            if(value.type === attributeType && !isDescriptorExpired(value.endDate)) {
                options.push({'value': value.name});
            }
        })
    } else if(type === "ReferralSourceDropDown") {
        dropDownList.forEach((value) => {
            options.push({'value': value.name, 'referralSourceId': value.referralSourceId});
        })
    }
    return options;
}

export const findValueForDropdown = (fieldDescriptor) => {
    let value = '';
    if(CONVERTDD.includes(fieldDescriptor?.type)){
       value = databaseValue(fieldDescriptor?.dbSavedValue, fieldDescriptor);
    } else {
        value = fieldDescriptor?.dbSavedValue ;
    }
    return value;
}