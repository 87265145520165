import {evvRepository} from './evv';

const createActivityLog = (user, action, data) => {
    return {
        staffId: user.staffId,
        action,
        createdDateTime: Date.now(),
        ...(data || {})
    }
};

const saveActivityLog = (user, action, data) => {
    return evvRepository.evvDb.activityLog.put(createActivityLog(user, action, data));
}

const activityLogRepository = {
    createActivityLog,
    saveActivityLog
};

export default activityLogRepository;
