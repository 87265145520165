import syncService from "../../common/services/syncService";

export default class DataProvider{
    constructor(state){
        this.state = state;
    }

    getSelectedClient() {
        return this.state.client.selectedClient;
    }

    getProgramsForOrganizationAndClient(clientId) {
        const programs = this.getPrograms();
        
        // eslint-disable-next-line eqeqeq
        return clientId ? this.getClientPrograms().filter(cp => cp.clientId == clientId).map(cp => programs.find(p => p.programId === cp.programId)) : [];
    }

    getStaffForOrganization(organizationId){
        return syncService.organizationService.getStaffForOrganization(this.getStaffMembers(), organizationId);
    }

    getCurrentOrganizationId(){
        return this.getUser().currentOrganizationId;
    }

    getPrimaryOrganizationId(){
        return this.getUser().primaryOrganizationId;
    }

    getOrganization(organizationId){
        return this.getOrganizations().find(org => org.organizationId === organizationId);
    }

    isHandheld(){
        return this.state.app.handheldFlag;
    }

    getOrientation(){
        return this.state.app.orientation;
    }

    getClients(){
        return this.state.app.clients.results;
    }

    getUser(){
        return this.state.app.user;
    }

    getStaff(){
        return this.getUser().staff;
    }

    getStaffMembers(){
        return this.state.sync.staff.results;
    }

    getAppointments(){
        return this.state.sync.appointment.results;
    }

    getCaseloads(){
        return this.state.sync.caseload.results;
    }

    getOrganizations(){
        return this.state.sync.organization.results;
    }

    getActivities(){
        return this.state.sync.activity.results;
    }

    getPrograms(){
        return this.state.sync.program.results;
    }

    getActivityPrograms(){
        return this.state.sync.activityProgram.results;
    }

    getClientPrograms(){
        return this.state.sync.clientProgram.results;
    }

    getServiceLocations(){
        return this.state.sync.serviceLocation.results;
    }

    getServiceLocationOrgs(){
        return this.state.sync.serviceLocationOrganization.results;
    }

    getDocumentCrossswalks(){
        return this.state.sync.documentCrosswalk.results;
    }

    getServiceDocuments(){
        return this.state.sync.serviceDocument.results;
    }

    getConfigurableForms(){
        return this.state.sync.configurableForm.results;
    }

    getAllergies(){
        return this.state.sync.allergy.results;
    }

    getMedications(){
        return this.state.sync.medication.results;
    }

    getDiagnoses(){
        return this.state.sync.diagnosis.results;
    }

    getReasonCodes(){
        return this.state.sync.reasonCodes.results;
    }

    getCreditCards(){
        return this.state.sync.creditCard.results;
    }

    getAllergyTypes(){
        return this.state.sync.allergyType.results;
    }

    getActivityQualifiers(){
        return this.state.sync.activityQualifiers.results;
    }

    getStaffSignatures(){
        return this.state.sync.staffSignature.results;
    }

    getReferralSource(){
        return this.state.sync.referralSource.results;
    }

    getGoalAddressedTxplan(){
        return this.state.sync.goalAddressedTxplan.results;
    }

    getGoalAddressedPgoi(){
        return this.state.sync.goalAddressedPgoi.results;
    }

    getGoalAddressedPgoMap(){
        return this.state.sync.goalAddressedPgoMap.results;
    }

    getGoalAddressedPgoIntMap(){
        return this.state.sync.goalAddressedPgoIntMap.results;
    }

    getImpactConfig(){
        return this.state.sync.impactConfig.results;
    }

    getMessages(){
        return this.state.sync.message.results;
    }

    getVitals(){
        return this.state.sync.vital.results;
    }
}
