import {useEffect, useRef, useState} from "react";
import EvvButton from "../../../common/components/EvvButton";
import Logo from "../../../assets/images/CareLogic-Mobile.png";
import userService, {LOGIN_ERRORS} from "../../../common/services/userService";
import AlertDialog from "../../../common/components/AlertDialog";
import {makeStyles} from "@material-ui/core/styles";
import LoginTextField from "../../../common/components/LoginTextField";
import {formatInputValue} from "../../../common/utils/formatUtils";
import {useDispatch, useSelector, useStore} from "react-redux";
import {appCache} from "../../../cache/slices/app/appSlice";
import {evvRepository} from "../../../db/evv";
import activityLogRepository from "../../../db/activityLogRepository";
import ResetPasswordDialog from "../ResetPasswordDialog";
import api from "../../../common/services/api";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useHistory} from "react-router-dom";
import syncService from "../../../common/services/syncService";
import InputForPassword from "../ResetPasswordDialog/InputForPassword";
import * as rdd from 'react-device-detect';
import { getDevicePlatformType } from "../../../common/utils/systemUtils";
import { PRIMARY_COLOR } from "../../../common/constants";
import { loginData } from "../../../common/utils/appointmentUtils";

const useStyles = makeStyles((theme) => ({
    loginPageContainer: {
        width: '100%',
        height: '100%',
        paddingLeft: theme.appPaddingX + 'px',
        paddingRight: theme.appPaddingX + 'px',
        background: "#F9F9F9",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    logoAndFormContainer: {
        width: '100%',
        maxWidth: 443 - (theme.appPaddingX * 2) + 'px',
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '22px',
        paddingRight: '22px',
        paddingTop: '32px',
        paddingBottom: '20px',
        backgroundColor: "#ffffff",
        borderRadius: '7px',
        boxShadow: '0px 1px 5px 2px rgba(0, 0, 0, 0.2)',
    },
    formContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    errorMessage : {
        position: 'absolute',
        top: (theme.textFieldHeightRaw + 4) + 'px',
        fontSize: '13px',
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: '16px',
        letterSpacing: 0,
        color: "#dc0707"
    },
    syncingMessage: {
        paddingTop: '10px',
        position: 'relative'
    },
    spaceBelow: {
        paddingBottom: theme.verticalSpaceBetween + 'px',
        position: 'relative'
    },
    loadingSpinnerContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    loadingSpinner: {
        color: PRIMARY_COLOR
    }
}));

const ALERT_DIALOG_PASSWORD_UPDATE_REQUEST = 'PASSWORD_UPDATE_REQUEST';
const ALERT_DIALOG_PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
const ALERT_DIALOG_FAIL_LOGIN_OFFLINE_RECONNECT = 'FAIL_LOGIN_OFFLINE_RECONNECT';
// const ALERT_DIALOG_SYNC_REQUIRED = 'SYNC_REQUIRED';

export default function LoginPage() {
    let devicePlatformType = getDevicePlatformType(rdd);
    const isHandheld = (devicePlatformType === "Mobile" ? true : false);
    const [loggingIn, setLoggingIn] = useState(false);
    const [syncing, setSyncing] = useState(false);
    const [alertDialogConfig, setAlertDialogConfig] = useState(null);
    const [loginAttempted, setLoginAttempted] = useState(false);
    const [account, setAccount] = useState(evvRepository.userAccount || '');
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [loggedInUser, setLoggedInUser] = useState('');
    const [daysUntilPasswordExpires, setDaysUntilPasswordExpires] = useState(0);
    const [loginsDisabled, setLoginsDisabled] = useState(false);
    const [showResetPasswordDialog, setShowResetPasswordDialog] = useState(false);
    const failedLoginAttempts = useSelector(appCache.getFailedLoginAttempts);
    const dispatch = useDispatch();
    const history = useHistory();
    const styles = useStyles();
    const store = useStore();

    const loggedInUserRef = useRef();

    const userSetters = {
        userName: setUserName,
        password: setPassword
    };

    const initializePage = () => {
        history.replace({ pathname: "/"});
        evvRepository.refreshToken = '';
        evvRepository.accessToken = '';
        evvRepository.expiresAt = '';
        evvRepository.writingProcess = 'false';
    }

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(initializePage, []);

    const handleTextFieldChange = (e) => {
        userSetters[e.target.id](e.target.value);
    };

    const handleAccountChange = (e) => {
        const account = e.target.value;

        evvRepository.userAccount = account;

        setAccount(account);
    };

    const showPasswordExpirationWarningDialog = () => {
        setAlertDialogConfig({
            dialogId: ALERT_DIALOG_PASSWORD_UPDATE_REQUEST,
            dialogTitle: 'Password Expiration',
            dialogMessage: 'Please note: Your password will expire soon. You can update it in CareLogic.',
            showOkButton: true,
            okButtonText: 'Set Password',
            showCancelButton: true
        });
    }

    const showPasswordChangeRequiredDialog = () => {
        setAlertDialogConfig({
            dialogId: ALERT_DIALOG_PASSWORD_UPDATE_REQUEST,
            dialogTitle: 'Update Password',
            dialogMessage: 'Please Note: Your CareLogic password must be updated to continue.',
            showOkButton: true,
            okButtonText: 'Set Password',
            showCancelButton: true
        });
    }

    const showPasswordResetSuccessDialog = () => {
        setAlertDialogConfig({
            dialogId: ALERT_DIALOG_PASSWORD_RESET_SUCCESS,
            dialogTitle: 'Password Reset',
            dialogMessage: 'Your CareLogic password and signature have been reset successfully. You may now begin to use CareLogic EVV.',
            showOkButton: true
        });
    }

    const showNetworkConnectionError = () => {
        setAlertDialogConfig({
            dialogId: ALERT_DIALOG_PASSWORD_RESET_SUCCESS,
            dialogTitle: 'Network connection error',
            dialogMessage: 'The device is offline. Please check your network connection and try again.',
            okButtonText: 'Close',
            showOkButton: true,
            handleClose: handleNetworkConnectionErrorClose
        });
    }

    const handleNetworkConnectionErrorClose = (okClicked) => {
        setAlertDialogConfig(null);
    }

    const changeUser = (syncFirst, userFromServer) => {
        let syncPromise = Promise.resolve();

        if (syncFirst){
            setSyncing(true);
            syncPromise = syncService.changeUser({store, doNotClear: true});
        }

        syncPromise
            .then(() => {
                evvRepository.changeUser(userName, password)
                    .then(evvDb => {
                        setSyncing(false);
                        loginSuccessful(userFromServer)
                    })
                    .catch(err => {
                        console.log('LoginPage.changeUser: error changing user in repository - fail login');
                        if (err) console.log(err);
                        setSyncing(false);
                        failLogin(LOGIN_ERRORS.ERROR_ATTEMPTING_DEVICE_LOGIN);
                    });
            })
            .catch(err => {
                setSyncing(false);
                showNetworkConnectionError();
            });
    }

    // Commented out until the sync solution is found
    // const showSyncRequiredDialog = () => {
    //     setAlertDialogConfig({
    //         dialogId: ALERT_DIALOG_SYNC_REQUIRED,
    //         dialogTitle: 'Synchronization Required',
    //         dialogMessage: 'A previous user of this device has data that was not synced. This data needs to be synced before a new user can log in. This process could take a while. Do you want to continue?',
    //         showOkButton: true,
    //         okButtonText: 'Yes',
    //         showCancelButton: true,
    //         cancelButtonText: 'No',
    //         handleClose: handleSyncRequiredClose
    //     });
    // }
    // const handleSyncRequiredClose = (okClicked) => {
    //     setAlertDialogConfig(null);
    //
    //     if (okClicked){
    //         changeUser(okClicked, loggedInUserRef.current);
    //     }
    // }

    const handlePasswordExpiration = (user) => {
        const passwordExpiresInDays = userService.daysUntilPasswordExpires(user);

        if (password === 'password' || passwordExpiresInDays < 0){
            setDaysUntilPasswordExpires(-1);
            showPasswordChangeRequiredDialog();
            return true;
        }

        setDaysUntilPasswordExpires(passwordExpiresInDays);

        const warningDays = isNaN(user.securityRule.warningDays) ? 15 : user.securityRule.warningDays;
        if (passwordExpiresInDays <= warningDays){
            showPasswordExpirationWarningDialog();
            return true;
        }

        return false;
    }

    const loginSuccessful = (user) => {
        loggedInUserRef.current = user;
        setLoggedInUser(user);
        localStorage.setItem('maximunAttempts', user.securityRule.maximunAttempts);
        localStorage.setItem('lockoutInterval', user.securityRule.lockoutInterval);

        if (handlePasswordExpiration(user)){
            return;
        }

        if (failedLoginAttempts > 0){
            dispatch(appCache.setFailedLoginAttempts(0));
        }

        dispatch(appCache.toggleHandheldFlag(isHandheld));
        dispatch(appCache.login(user));
        activityLogRepository.saveActivityLog(user, 'Login');
    }

    const handleLoginRequest = (e) => {
        if (e) {
            e.preventDefault();
        }

        const formattedAccount = formatInputValue(account);
        const formattedUserName = formatInputValue(userName);
        const formattedPassword = formatInputValue(password);

        setLoginAttempted(true);

        if (!formattedAccount || !formattedUserName || !formattedPassword){
            failLogin(LOGIN_ERRORS.PASSWORD_MISMATCH);
        }else {
            if (loginsDisabled){
                failLogin(LOGIN_ERRORS.MAX_LOGIN_ATTEMPTS_LOCAL);
                return;
            }

            attemptLoginOnline(formattedAccount, formattedUserName, formattedPassword);
        }
    };

    const loadExistingUserFromDbOnline = (userFromServer) => {
        userService.loadExistingUserFromDb()
            .then((userFromDb) => {
                if (userFromDb) {
                    if (userFromDb.userName === userFromServer.userName){
                        loginSuccessful(userFromServer);
                    } else {
                        failLogin(LOGIN_ERRORS.NOT_PREVIOUS_USER_LOGIN_OFFLINE);
                    }
                } else {
                    loginSuccessful(userFromServer);
                }
            })
            .catch((error) => {
                console.log("Error loading existing user from db - probably different password");
                console.log(error);

                setLoggingIn(false);
                setLoggedInUser(userFromServer);
                loggedInUserRef.current = userFromServer;
                changeUser(false, userFromServer);
                // syncService.appointmentService.isSyncRequired()
                //     .then(syncRequired => {
                //         setLoggingIn(false);
                //         setLoggedInUser(userFromServer);
                //         loggedInUserRef.current = userFromServer;
                //         if (syncRequired){
                //             showSyncRequiredDialog();
                //         }else{
                //             changeUser(false, userFromServer);
                //         }
                //     })
            });
    }

    const handleLoginOnlineResponse = (response) => {
        console.log("Server responded to login online request");
        // dispatch(appCache.toggleOnlineFlag(true));
        if (response.userDetails){
            const userFromServer = response.userDetails;

            userFromServer.userName = userName;
            userFromServer.account = account;
            userFromServer.accessToken = response.tokenResult.access_token;
            userFromServer.refreshToken = response.tokenResult.refresh_token;
            userFromServer.expiresInSeconds = response.tokenResult.expires_in;

            return evvRepository.initializeDatabase(userName, password)
                .then(() => {
                    loadExistingUserFromDbOnline(userFromServer);
                })
        } else if (response.error){
            if(response.error === 'expiring') { 
                showPasswordExpirationWarningDialog();
            } else if(response.error === 'expired'){
                setDaysUntilPasswordExpires(-1);
                showPasswordChangeRequiredDialog();
            } else {
                dispatch(appCache.setFailedLoginAttempts(failedLoginAttempts + 1));
                const maximunAttemptsLog = getMaximunAttempts();
                const lockoutIntervalLog = getLockoutInterval();
                if (failedLoginAttempts + 1 >= maximunAttemptsLog) {
                    disableLogins(lockoutIntervalLog);
                } else {
                    if (response.error === "privilegeRequired") {
                        failLogin({
                            title:  response.error.substring(0, 9) + " " + response.error.substring(9),
                            message: response.error_description.replace("Mobile", "EVV"),
                        });
                    } else {
                        failLogin({
                            title: response.error,
                            message: response.error_description,
                        });
                    }
                }
            }
        } else {
            failLogin({
                title: 'Login Error Response',
                message: `${response.statusText} (${response.status})`
            });
        }
    };

    const attemptLoginOnline = (formattedAccount, formattedUserName, formattedPassword) => {
        console.log("Attempting online login");
        setLoggingIn(true);

        const loginConfig = {
            store: store,
            loginData: {
                account: formattedAccount,
                userName: formattedUserName,
                password: formattedPassword
            }
        };

        api.login(loginConfig)
            .then(response => {
                if (response instanceof Error){
                    throw response;
                }
                handleLoginOnlineResponse(response)
            })
            .catch(response => {
                console.log("Error attempting to login online");
                if (response) console.log(response);
                loginOffline(formattedAccount, formattedUserName, formattedPassword);
            });
    };

    const loadExistingUserFromDbOffline = (account, userName) => {
        userService.loadExistingUserFromDb()
            .then((user) => {
                if (user) {
                    if (user.userName === userName){
                        loginSuccessful(user);
                    } else {
                        failLogin(LOGIN_ERRORS.NOT_PREVIOUS_USER_LOGIN_OFFLINE);
                    }
                } else {
                    failLogin(LOGIN_ERRORS.FIRST_TIME_LOGIN_OFFLINE);
                }
            })
            .catch((error) => {
                console.log("Error loading existing user from db");
                console.log(error);
                if (password === "password"){
                    failLoginForInitialUser(LOGIN_ERRORS.FIRST_TIME_USER_LOGIN_OFFLINE)
                }else {
                   dispatch(appCache.setFailedLoginAttempts(failedLoginAttempts + 1));
                   const maximunAttemptsLog = getMaximunAttempts();
                   const lockoutIntervalLog = getLockoutInterval();
                   if (failedLoginAttempts + 1 >= maximunAttemptsLog) {
                       disableLogins(lockoutIntervalLog);
                   } else {
                    failLogin(LOGIN_ERRORS.ERROR_ATTEMPTING_DEVICE_LOGIN_OFFLINE);
                   }
                }
            });
    }

    const loginOffline = (formattedAccount, formattedUserName, formattedPassword) => {
        console.log("Attempting offline login");
        // dispatch(appCache.toggleOnlineFlag(false));
        evvRepository.databaseExists()
            .then(exists => {
                if (!exists){
                    console.log("database does not exist - must be connected for initial login");
                    failLogin(LOGIN_ERRORS.FIRST_TIME_LOGIN_OFFLINE);
                    return;
                }

                return evvRepository.initializeDatabase(formattedUserName, formattedPassword)
                    .then(() => {
                        loadExistingUserFromDbOffline(formattedAccount, formattedUserName);
                    })
            })
            .catch((error) => {
                console.log("Error checking if database exists");
                console.log(error);
                if (formattedPassword === "password"){
                    failLoginForInitialUser(LOGIN_ERRORS.FIRST_TIME_USER_LOGIN_OFFLINE)
                }else {
                    failLogin(LOGIN_ERRORS.FIRST_TIME_LOGIN_OFFLINE);
                }
            });
    };

    const failLoginForInitialUser = (errorDescriptor, user = {}) => {
        let dialogMessage = errorDescriptor.message;

        if (errorDescriptor.processor){
            dialogMessage = errorDescriptor.processor(user);
        }

        setAlertDialogConfig({
            dialogId: ALERT_DIALOG_FAIL_LOGIN_OFFLINE_RECONNECT,
            dialogTitle: errorDescriptor.title,
            dialogMessage: dialogMessage,
            showOkButton: true,
            okButtonText: 'Reconnect',
            showCancelButton: true
        });
    }

    const enableLogins = () => {
        dispatch(appCache.setFailedLoginAttempts(0));

        setLoginsDisabled(false);

        setAlertDialogConfig(null);
    }

    const disableLogins = (lockoutInterval) => {
        setLoginsDisabled(true);
        
        setTimeout(enableLogins, lockoutInterval * 60 * 1000);

        failLogin(LOGIN_ERRORS.MAX_LOGIN_ATTEMPTS_LOCAL);
    }

    const getMaximunAttempts = () => {
        return localStorage.getItem('maximunAttempts')? parseInt(localStorage.getItem('maximunAttempts')) : 5;
    }
	
    const getLockoutInterval = () => {
        return localStorage.getItem('lockoutInterval')? parseInt(localStorage.getItem('lockoutInterval')) : 3;
    }    

    const failLogin = (errorDescriptor, user = {}) => {
        let dialogMessage = errorDescriptor.message;

        if (errorDescriptor.processor){
            dialogMessage = errorDescriptor.processor(user);
        }

        setAlertDialogConfig({
            dialogTitle: errorDescriptor.title,
            dialogMessage: dialogMessage,
            showOkButton: true,
            showCancelButton: false
        });
    }

    const handleClose = (okClicked) => {
        const dialogConfig = {...alertDialogConfig};

        setAlertDialogConfig(null);
        setLoggingIn(false);

        if (dialogConfig.dialogId === ALERT_DIALOG_PASSWORD_UPDATE_REQUEST){
            if (okClicked){
                setShowResetPasswordDialog(true);
            } else {
                if (daysUntilPasswordExpires >= 0) {
                    if(dialogConfig.dialogTitle === 'Password Expiration' && !loggedInUser) {
                        setAlertDialogConfig(null);
                    } else {
                        loginData(isHandheld, loggedInUserRef, dispatch);
                    }
                } else {
                    appCache.logout(dispatch);
                }
            }
        } else if (dialogConfig.dialogId === ALERT_DIALOG_PASSWORD_RESET_SUCCESS){
            setPassword('');
            activityLogRepository.saveActivityLog(loggedInUserRef.current, 'Password Reset');
            appCache.logout(dispatch);
        }

        if (okClicked && dialogConfig.dialogId === ALERT_DIALOG_FAIL_LOGIN_OFFLINE_RECONNECT){
            handleLoginRequest();
        }
    }

    const handleResetPasswordDialogClose = (buttonClicked) => {
        setShowResetPasswordDialog(false);

        if (buttonClicked === 'ok'){
            showPasswordResetSuccessDialog();
            return;
        } else if (buttonClicked === 'remind'){
            if (daysUntilPasswordExpires >= 0) {
                if(!loggedInUser) {
                    setAlertDialogConfig(null);
                } else {
                    dispatch(appCache.toggleHandheldFlag(isHandheld));
                    dispatch(appCache.login(loggedInUserRef.current));
                    activityLogRepository.saveActivityLog(loggedInUserRef.current, 'Remind Password Reset');
                    activityLogRepository.saveActivityLog(loggedInUserRef.current, 'Login');
                    return;
                }
            }
        }

        appCache.logout(dispatch);
    }

    const renderLoginButton = () => {
        if (loggingIn || syncing) {
            return (
                <div className={styles.loadingSpinnerContainer}>
                    <CircularProgress size={40} className={styles.loadingSpinner} />
                </div>
            );
        } else {
            return (
                <EvvButton id='LoginButton' onClick={handleLoginRequest} type='ctaLogin'>Log In</EvvButton>
            );
        }
    }

    return (
        <div className={styles.loginPageContainer}>
            <div className={styles.logoAndFormContainer}>
                <img className={styles.spaceBelow} style={isHandheld ? {height:'112px'} : {height:'125px'}} src={Logo} alt="logo"/>
                <form className={styles.formContainer} onSubmit={handleLoginRequest} noValidate autoComplete="off">
                    <div className={styles.spaceBelow}>
                        <LoginTextField id='account' placeholder='Account' disabled={loggingIn} onChange={handleAccountChange}
                                        value={account}/>
                        {loginAttempted && account.length === 0 && <div className={styles.errorMessage} >Account: Entry required</div>}
                    </div>
                    <div className={styles.spaceBelow}>
                        <LoginTextField id='userName' placeholder='Username' disabled={loggingIn} onChange={handleTextFieldChange}
                                        value={userName}/>
                        {loginAttempted && userName.length === 0 && <div className={styles.errorMessage} >Username: Entry required</div>}
                    </div>
                    <div className={styles.spaceBelow}>
                        <InputForPassword id='password' placeholder='Password' type="password" disabled={loggingIn}
                                        onChange={handleTextFieldChange} value={password}/>
                        {loginAttempted && password.length === 0 && <div className={styles.errorMessage} >Password: Entry required</div>}
                    </div>
                    {renderLoginButton()}
                    {syncing &&
                    <div className={styles.syncingMessage}>
                        Syncing data from the previous user, please wait...
                    </div>
                    }
                </form>
            </div>
            {alertDialogConfig &&
            <AlertDialog
                open={true}
                dialogTitle={alertDialogConfig.dialogTitle}
                dialogMessage={alertDialogConfig.dialogMessage}
                showOkButton={alertDialogConfig.showOkButton}
                showCancelButton={alertDialogConfig.showCancelButton}
                okButtonText={alertDialogConfig.okButtonText || 'Ok'}
                cancelButtonText={alertDialogConfig.cancelButtonText || 'Cancel'}
                handleClose={alertDialogConfig.handleClose || handleClose}
            />
            }
            {showResetPasswordDialog &&
            <ResetPasswordDialog
                daysUntilPasswordExpires={daysUntilPasswordExpires}
                accountName={account}
                userName={userName}
                user={loggedInUser ? loggedInUser : loggedInUserRef.current}
                onClose={handleResetPasswordDialogClose}
            />
            }
        </div>
    )
}