import React from 'react';
import Button from '../Button';
import {Link} from "react-router-dom";

export default function LinkButton({href, label, children, handleLinkClick, selected}) {
    return (
        <Link to={href || ''}  onClick={handleLinkClick}>
            <Button label={label} selected={selected}>
                {children}
            </Button>
        </Link>
    );
}