import { isGA } from "../../../common/utils/goalsAddressedUtils";
import { isImpact } from "../../../common/utils/impactUtils";

export const focusOnElement = (module) => {
    const moduleId = getModuleId(module);
    let inputArea = document.getElementById(moduleId);
    let isError = false;

    if(inputArea) {
        let errors = inputArea.querySelectorAll(".input-error");
        if (errors.length > 0) {
            isError = true;
            errors[0].scrollIntoView();
            errors[0].focus();
        }
    }

    return isError;
}

export const getModuleId = (module) => {
    if(isGA(module)) {
        return `goalsAddressed${module?.moduleId}`;
    } else if(isImpact(module)) {
        return `impact${module.moduleId}`;
    } else {
        return `fields${module?.moduleId}`;
    }
}

export const getFieldElementToFocusOn = (field) => {
    let fieldType = field.type;
    let element = null;

    const fieldIdMapping = {
        'DropDown': 'dropDown',
        'Descriptor DropDown': 'dropDown',
        'ActiveMedicationDropDown': 'dropDown',
        'ProgramDropDown': 'dropDown',
        'StaffDropDown': 'dropDown',
        'Text Area': 'textArea',
        'Text Field': 'textField',
        'Date': 'date',
        'Time': 'time',
        'DateTime': 'dateTime',
        'SSN': 'SSN',
        'ReferralSourceDropDown': 'dropDown',
    };

    switch (fieldType) {
        case "RadioButton":
            element = document.getElementById(`radio-${field.id}-${field.options[0].id}`);
        break;
            
        case "Gender":
            element = document.getElementById(`gender-${field.id}-m`);
        break;

        case "CheckBox":
        case 'Descriptor CheckBox':
            element = document.getElementById(`checkbox-${field.options[0]?.id}`);
        break;

        case "Yes or No":
            element = document.getElementById(`yes-or-no-${field.id}-yes`);
        break;

        case "Phone":
        case "Address":
            element = document.getElementById(`${field.options[0].id}`);
        break;

        default:
            if (fieldIdMapping[fieldType]) {
                element = document.getElementById(`${fieldIdMapping[fieldType]}-${field.id}`);
            }
        break;
    }

    return element;
}