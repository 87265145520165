import Dexie from 'dexie';
import {applyEncryptionMiddleware, NON_INDEXED_FIELDS, ENCRYPT_LIST} from 'dexie-encrypted';
import nacl from 'tweetnacl';
import {DateTime} from "luxon";

const STORAGE_USER_ACCOUNT = 'userAccount';
const STORAGE_ACCESS_TOKEN = 'accessToken';
const STORAGE_REFRESH_TOKEN = 'refreshToken';
const STORAGE_IS_WRITING_PROCESS = 'isWritingProcess';
const STORAGE_EXPIRES_AT = 'expiresAt';

const DB_NAME = 'EvvDB';
const DB_VERSION = 8;
const DB_STORES = {
  user: 'userName, lastLoginDate, account',
  staff: '[staffId+organizationId]',
  caseload: 'clientStaffId, clientId',
  client: 'clientId, changedDateMillis',
  allergy: '[clientAllergyId+clientId+allergyId], clientId',
  diagnosis: 'id, clientId',
  medication: '[medicationId+clientId], clientId',
  visit: 'evvVisitId, deviceVisitId, clientId, appointmentId, complete, syncState, visitStatus',
  activityLog: '++activityLogId, staffId',
  recentClient: 'clientId, lastVisitedMillis',
  appointment: 'appointmentId, syncState, status',
  document: 'id, serviceDocumentId, appointmentId, documentStatus, clientId',
  organization: 'organizationId',
  activity: '[activityId+organizationId]',
  activityProgram: 'activityProgramMatrixId',
  program: '[programId+organizationId]',
  clientProgram: 'clientProgramId',
  serviceLocation: 'serviceLocationId',
  serviceLocationOrganization: 'serviceLocationOrganizationId',
  descriptor: 'descriptorId, type, subType, organizationId, [type+organizationId], [type+subType+organizationId]',
  documentCrosswalk: 'id, organizationId, serviceDocumentId',
  serviceDocument: 'id,description, setupId',
  configurableForm: 'id,name',
  staffSignature: 'staffHistoryId',
  documentInstance: 'documentId',
  syncInfo:'tableName',
  referralSource: 'referralSourceId',
  auditLog: 'auditLogId, syncState',
  goalAddressedTxplan: 'treatmentPlanId',
  goalAddressedPgoi: 'pgoiId, tPlanMasterId',
  goalAddressedPgoMap: 'pgoMapId, tPlanMasterId',
  goalAddressedPgoIntMap: 'pgoMapIntId, tPlanMasterId, interventionId',
  impactConfig: 'id',
  message: 'id, clientId',
  vital: 'vitalsId, clientId',
  schemaVersion: 'id',
};

Dexie.debug = 'dexie';

class EvvDB extends Dexie {
  user;
  staff;
  client;
  allergy;
  diagnosis;
  medication;
  visit;
  activityLog;
  recentClient;
  appointment;
  document;
  organization;
  activity;
  activityProgram;
  program;
  clientProgram;
  serviceLocation;
  serviceLocationOrganization;
  descriptor;
  syncInfo;
  documentCrosswalk;
  serviceDocument;
  configurableForm;
  staffSignature;
  documentInstance;
  referralSource;
  auditLog;
  goalAddressedTxplan;
  goalAddressedPgoi;
  goalAddressedPgoMap;
  goalAddressedPgoIntMap;
  impactConfig;
  message;
  vital;
  schemaVersion;

  constructor() {
    super(DB_NAME, { autoOpen: false });
    this.version(DB_VERSION).stores(DB_STORES);
    this.syncInfoCache = {};

    /**
     * Event triggered when the database is initialized for the first time.
     * Stores the current database structure in the 'schemaVersion' table.
     */
    this.on('populate', async () => {
      await this.schemaVersion.put({ id: 1, schema: DB_STORES });
    });

    /**
     * Handles the database migration process to a new version.
     * - This is executed when `DB_VERSION` changes.
     * - It compares the previous database structure with the new one to determine:
     *   - If new tables need to be created.
     *   - If existing tables have structural changes.
     *   - If obsolete tables need to be removed.
     */
    this.version(DB_VERSION).upgrade(async (tx) => {
      console.log(`Migrating database to version ${DB_VERSION}...`);

      // If no previous schema exists, store the current schema and exit
      const previousSchema = await this.schemaVersion.get(1);
      if (!previousSchema) {
        await this.schemaVersion.put({ id: 1, schema: DB_STORES });
        return;
      }

      // Extract the previous and current database structures
      const previousStores = previousSchema.schema;
      const newStores = DB_STORES;

      /**
       * Database structure comparison:
       * 1. If a table exists in `newStores` but **not** in `previousStores`, it is a new table and should be created.
       * 2. If a table **already existed**, but its structure (`indexes`) has changed, it means the table was modified
       *    and needs to be updated.
       */
      for (const [tableName, indexes] of Object.entries(newStores)) {
        if (!previousStores[tableName]) {
          console.log(`Creating new table: ${tableName}`);
        } else if (previousStores[tableName] !== indexes) {
          console.log(`Updating table: ${tableName}`);
        }
      }

      /**
       * Removal of obsolete tables:
       * If a table existed in the previous version but is no longer present in the new version,
       * it means the table has been removed and should be deleted from the database.
       */
      for (const tableName of Object.keys(previousStores)) {
        if (!newStores[tableName]) {
          console.log(`Dropping table: ${tableName}`);
        }
      }

      // Store the updated database structure in the 'schemaVersion' table
      await this.schemaVersion.put({ id: 1, schema: DB_STORES });
    });
  }

  /**
   * Opens the database if it is not already open.
   * - If the database is already open, it simply returns `super.open()`.
   * - Otherwise, it checks if the database exists before attempting to open it.
   * - If the database does not exist, it avoids unnecessary initialization.
   * - Ensures that the database is properly opened using Dexie's built-in mechanisms.
   *
   */
  open() {
    if (this.isOpen()) return super.open();

    return Dexie.Promise.resolve()
        .then(() => Dexie.exists(this.name))
        .then((exists) => {
          if (!exists) {
            return;
          }

          return new Dexie(DB_NAME).open();
        })
        .then(() => super.open());
  }

  initialize() {
    this.version(DB_VERSION).stores(DB_STORES);
    return this.open()
        .then ((db) => {
          console.log('Database opened: ');
          console.log(db);
          return Promise.resolve(db);
        })
        .catch ((err) =>{
          console.log('Failed to open db: ');
          console.log(err);
          return Promise.reject(err);
        });
  }

  getLastSyncInfo() {
    return this.syncInfo.toArray()
  }

  getSyncInfo(tableName) {
    console.log("Getting syncInfo for table: " + tableName);
    const syncInfo = this.syncInfoCache[tableName];

    if (syncInfo){
      return Promise.resolve(syncInfo);
    } else {
      return this.syncInfo.get(tableName);
    }
  }

  saveSyncInfo(syncInfo) {
    console.log("Saving syncInfo for table: ");
    console.log(syncInfo);

    this.syncInfoCache[syncInfo.tableName] = syncInfo;

    return this.syncInfo.put(syncInfo);
  }

  saveLastSync(tableName, lastSyncMillis) {
    console.log("Saving syncInfo for table: " + tableName + " - " + DateTime.fromMillis(lastSyncMillis));
    let syncInfo = this.syncInfoCache[tableName];

    if (!syncInfo){
      console.log("Creating syncInfo for table: " + tableName + " - " + lastSyncMillis);
      syncInfo = {tableName, lastSyncMillis};
      this.saveSyncInfo(syncInfo);
    } else {
      syncInfo.lastSyncMillis = lastSyncMillis;
      this.syncInfo.where({tableName: tableName}).modify(item => item.lastSyncMillis = lastSyncMillis)
    }
  }
}

const getLastSyncInfo = () => {
  if (evvRepository.evvDb) {
    return evvRepository.evvDb.getLastSyncInfo()
  }
}

const getSyncInfo = (tableName) => {
  if (evvRepository.evvDb) {
    return evvRepository.evvDb.getSyncInfo(tableName)
  }
}

const saveSyncInfo = (syncInfo) => {
  if (evvRepository.evvDb) {
    evvRepository.evvDb.saveSyncInfo(syncInfo);
  }
}

const saveLastSync = (tableName, lastSyncMillis) => {
  if (evvRepository.evvDb) {
    evvRepository.evvDb.saveLastSync(tableName, lastSyncMillis);
  }
}

const databaseExists = () => {
  return Dexie.exists(DB_NAME);
}

const keyChanged = (db) => {
  console.log("Key changed");
  console.log(db);
  return Promise.resolve("Key changed");
}

const generateKeyPair = (userName, password) => {
  const seedString = `${password}_${userName}`;
  const seedStringPadded = seedString.length <= 32 ? seedString.padEnd(32, 'X') : seedString.substring(0, 32);
  const encoder = new TextEncoder()
  const seed = encoder.encode(seedStringPadded)

  return nacl.sign.keyPair.fromSeed(seed);
}

const initializeDatabase = (userName, password) => {
  console.log("evv.js - instantiating db");
  const evvDB = new EvvDB();

  console.log("evv.js - initializing db");

  closeDatabase();
  let tablesEncrypted = (window.location.hostname === 'evv.qualifacts.org' || window.location.hostname === 'mobile.qualifacts.org')  ? getEncryptedTableConfig() : { user: NON_INDEXED_FIELDS };
  const keyPair = generateKeyPair(userName, password);
  
  applyEncryptionMiddleware(
      evvDB,
      keyPair.publicKey,
      tablesEncrypted,
      keyChanged
  );

  evvRepository.evvDb = evvDB;

  return evvDB.initialize();
}

const updateEncryptionKey = async (currentUserName, currentPin, newPin) => {
  let tablesEncrypted = (window.location.hostname === 'evv.qualifacts.org' || window.location.hostname === 'mobile.qualifacts.org')  ? getEncryptedTableConfig() : { user: NON_INDEXED_FIELDS };
  const currentKeyPair = generateKeyPair(currentUserName, currentPin);
  const evvDBCurrent = new EvvDB();
  const allData = {};

  applyEncryptionMiddleware(
    evvDBCurrent,
    currentKeyPair.publicKey,
    tablesEncrypted,
    keyChanged
  );

  await evvDBCurrent.open();
  
  for (const tableName of evvDBCurrent.tables.map(t => t.name)) {
    allData[tableName] = await evvDBCurrent[tableName].toArray();
  }
  evvDBCurrent.close();

  const newKeyPair = generateKeyPair(currentUserName, newPin);
  const evvDBNew = new EvvDB();

  applyEncryptionMiddleware(
    evvDBNew,
    newKeyPair.publicKey,
    tablesEncrypted,
    keyChanged
  );

  await evvDBNew.open();

  await evvDBNew.transaction('rw', evvDBNew.tables, async () => {
    for (const tableName of Object.keys(allData)) {
      await evvDBNew[tableName].bulkPut(allData[tableName]);
    }

    evvDBNew.close();

    evvRepository.evvDb = evvDBNew;

    if (!evvRepository.evvDb.isOpen()) {
      evvRepository.evvDb.open();
    }

  }).catch(error => {
    console.error('Error opening new Indexed DB: ' + error);
    evvDBCurrent.close();
  });
};

const deleteDatabase = () => {
  if (evvRepository.evvDb) {
    console.log("Deleting db");
    return evvRepository.evvDb.delete();
  } else {
    console.log("evvDb not configured");
    return Promise.resolve(true);
  }
}

const changeUser = (userName, password) => {
  console.log("Changing to new user");
  return deleteDatabase()
      .then(() => {
        console.log("Database successfully deleted");
        return Promise.resolve(initializeDatabase(userName, password));
      })
      .catch((err) => {
        console.error("Could not delete database");
        console.err(err);
        return Promise.reject(err);
      });
}

const getEncryptedTableConfig = () => {
  const encryptedTableConfig = {};
  Object.keys(DB_STORES).forEach(tableName => {
    switch(tableName) {
      case 'program':
        let program = {
          type: ENCRYPT_LIST,
          fields: ['code', 'name', 'carelogicEvvYn', 'beginDate', 'activityId', 'audit', 'endDate']
        }
        encryptedTableConfig[tableName] = program;
      break;
      case 'activity':
        let activity = {
          type: ENCRYPT_LIST,
          fields: ['activityDescriptorList', 'activityType', 'additionalServices', 'audit', 'beginDate', 'billable', 'carelogicEvvYn','code','description','endDate','nonBillableMinutes','overlap','overlapSameActivity']
        }
        encryptedTableConfig[tableName] = activity;
      break;
      case 'staff':
        let staff = {
          type: ENCRYPT_LIST,
          fields: ['additionalOrganizationIds', 'audit', 'beginDate', 'billSupervisor', 'billingCategory', 'display', 'endDate','firstName','isMultiBookSchedule','isPrimaryOrganization','lastName','primaryOrganizationId','requireElectronicServDoc','supervisoryGroupIds','supervisoryIds']
        }
        encryptedTableConfig[tableName] = staff;
      break;
      case 'allergy':
        let allergy = {
          type: ENCRYPT_LIST,
          fields: ['allergyType', 'allergyName', 'allergyReaction', 'audit', 'beginDate', 'endDate', 'nka', 'nkda']
        }
        encryptedTableConfig[tableName] = allergy;
      break;
      case 'medication':
        let medication = {
          type: ENCRYPT_LIST,
          fields: ['amount', 'audit', 'beginDate', 'comments', 'dosage', 'endDate','frequency', 'medication', 'prescriber', 'refills', 'status']
        }
        encryptedTableConfig[tableName] = medication;
      break;
      default:
        encryptedTableConfig[tableName] = NON_INDEXED_FIELDS;
    }
  });
  return encryptedTableConfig;
}

const closeDatabase = () => {
  console.log("evv.js - request close database");

  try {
    if (evvRepository.evvDb && evvRepository.evvDb.isOpen()) {
      console.log("evv.js - db is open - close database");
      evvRepository.evvDb.close();
      console.log("evv.js - database closed");
    }
  }catch(x){
    console.log("evv.js - error closing database");
    console.log(x);
  }

  return evvRepository.evvDb;
}

const getSessionItem = (name) => {
  return sessionStorage.getItem(name);
}
const setSessionItem = (name, value) => {
  sessionStorage.setItem(name, value);
}

const getLocalItem = (name) => {
  return localStorage.getItem(name);
}
const setLocalItem = (name, value) => {
  localStorage.setItem(name, value);
}

export const evvRepository = {
  get userAccount(){return getLocalItem(STORAGE_USER_ACCOUNT)},
  set userAccount(account){setLocalItem(STORAGE_USER_ACCOUNT, account)},
  get accessToken(){return getSessionItem(STORAGE_ACCESS_TOKEN)},
  set accessToken(token){setSessionItem(STORAGE_ACCESS_TOKEN, token)},
  get refreshToken(){return getSessionItem(STORAGE_REFRESH_TOKEN)},
  set refreshToken(token){setSessionItem(STORAGE_REFRESH_TOKEN, token)},
  get expiresAt(){return getSessionItem(STORAGE_EXPIRES_AT)},
  set expiresAt(timeInMilliseconds){setSessionItem(STORAGE_EXPIRES_AT, timeInMilliseconds)},
  get isWritenProcess(){return getSessionItem(STORAGE_IS_WRITING_PROCESS)},
  set writingProcess(isProcess){setSessionItem(STORAGE_IS_WRITING_PROCESS, isProcess)},

  databaseExists,
  initializeDatabase,
  changeUser,
  closeDatabase,

  getLastSyncInfo,
  getSyncInfo,
  saveSyncInfo,
  saveLastSync,
  updateEncryptionKey
}

export { EvvDB}