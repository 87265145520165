// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA

export function register(config) {
  //if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
  if (navigator.serviceWorker) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return;
    }

    const modalContainer = document.createElement("div");
    modalContainer.id = "custom-modal";
    modalContainer.style.display = "none";
    modalContainer.innerHTML = `
      <div style="
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        ">
        <div style="
            background-color: white;
            border-radius: 4px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            text-align: left;
            width: 90%;
            max-width: 400px;
            overflow: hidden;
          ">
          <div style="
              height: 42px;
              background-color: #3b276a;
              color: white;
              font-size: 16px;
              font-weight: bold;
              display: flex;
              align-items: center;
              padding-left: 16px;
            ">
            New Version Available
          </div>
          <div style="
              padding: 10px 16px;
            ">
            <p style="
                font-size: 16px;
                color: #747474;
                margin: 15px 0;
              ">
              A new version of the app is available. Please select Update Now for the latest updates.
            </p>
          </div>
          <div style="
              padding: 0px 16px 8px 16px;
              display: flex;
              justify-content: flex-end;
            ">
            <button id="modal-ok-button" style="
                padding: 10px 20px;
                background-color: #3b276a;
                color: white;
                border: none;
                border-radius: 4px;
                cursor: pointer;
                font-size: 16px;
              ">
              Update Now
            </button>
          </div>
        </div>
      </div>
    `;

    // Spinner overlay
    const spinnerOverlay = document.createElement("div");
    spinnerOverlay.id = "spinner-overlay";
    spinnerOverlay.style = `
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0); /* Transparent background */
      display: none;
      justify-content: center;
      align-items: center;
      z-index: 2000;
    `;
    spinnerOverlay.innerHTML = `
      <div style="
          display: inline-block;
          width: 60px;
          height: 60px;
          position: relative;
        ">
        <div style="
            box-sizing: border-box;
            position: absolute;
            width: 100%;
            height: 100%;
            border: 6px solid rgba(255, 255, 255, 0.3);
            border-top-color: #3b276a; /* Primary color */
            border-right-color: #3b276a; /* Add more arc */
            border-radius: 50%;
            animation: spin 1s linear infinite;
          ">
        </div>
      </div>
    `;

    const spinnerStyle = document.createElement("style");
    spinnerStyle.innerHTML = `
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    `;

    document.head.appendChild(spinnerStyle);
    document.body.appendChild(modalContainer);
    document.body.appendChild(spinnerOverlay);

    // Show spinner and reload
    document.getElementById("modal-ok-button").addEventListener("click", () => {
      modalContainer.style.display = "none";
      spinnerOverlay.style.display = "flex";
      window.location.reload();
    });

    // Hide spinner once the page loads
    window.addEventListener("load", () => {
      const spinner = document.getElementById("spinner-overlay");
      if (spinner) {
        spinner.style.display = "none";
      }
    });

    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/sw.js`;
      registerValidSW(swUrl, config);
    });
  }
}

function registerValidSW(swUrl, config) {
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {

      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.addEventListener('message', (event) => {
          if (event.data.type === 'NEW_VERSION_AVAILABLE') {
            if (window.location.hostname.startsWith('evv')) {
              const modal = document.getElementById("custom-modal");
              modal.style.display = "flex";
            }
          }
        });
      }

      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // At this point, the updated precached content has been fetched,
              // but the previous service worker will still serve the older
              // content until all client tabs are closed.
              console.log(
                'New content is available and will be used when all ' +
                  'tabs for this page are closed. See https://cra.link/PWA.'
              );

              // Execute callback
              if (config && config.onUpdate) {
                config.onUpdate(registration);
              }
            } else {
              // At this point, everything has been precached.
              // It's the perfect time to display a
              // "Content is cached for offline use." message.
              console.log('Content is cached for offline use.');

              // Execute callback
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };
    })
    .catch((error) => {
      console.error('Error during service worker registration:', error);
    });
}

export function unregister() {
  if (navigator.serviceWorker) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}
