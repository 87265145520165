import {makeStyles} from "@material-ui/core/styles";
import {
    formatBirthDate,
    formatCityStateZip,
    formatClientId,
    formatCountyCountry,
    formatGender,
    formatUserName,
    getClientEmail,
    getPrimaryDiagnosis,
    getPhoneInfo,
    getPhysicalAddress
} from "../../../common/utils/formatUtils";
import {showEmailApplication, showMapApplication, showPhoneApplication} from "../../../common/utils/systemUtils";
import {Tooltip} from "@material-ui/core";
import VisitStopwatch from "../VisitStopwatch";
import {useDispatch, useSelector} from "react-redux";
import {getClientImage} from "../../../common/utils/imageUtils";
import syncService from "../../../common/services/syncService";
import {useEffect, useState} from "react";
import { appCache } from "../../../cache/slices/app/appSlice";
import { PRIMARY_COLOR, QUATERNARY_COLOR } from "../../../common/constants";

const useStyles = makeStyles((theme) => ({
    clientSnapshot: {
        ...theme.shadowedContainer,
        display: 'grid',
        gridTemplateColumns: '33% 33% 34%', //'repeat(3, 1fr)',
        padding: '10px 0px 10px 0px',
        overflow: 'unset',
        height: 'auto'
    },
    clientSnapshotColumn: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRight: '1px solid #e4e4e4'
        // alignItems: 'center'
    },
    clientSnapshotColumnOne: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '106px 1fr',
        alignItems: 'center'
    },
    imageColumn: {
        alignSelf: 'start',
        paddingLeft: '20px',
        paddingRight: '20px',
    },
    image: {
        width: '66px',
        height: '66px',
        borderRadius: '50%'
    },
    clientColumn: {
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        overflowX: 'hidden'
    },
    clientNameLabel: {
        fontSize: '20px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: PRIMARY_COLOR,
        paddingBottom: '6px'
    },
    clientRow: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: '6px'
    },
    clientSnapshotColumnTwo: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '20px'
    },
    addressRow: {
        display: 'grid',
        gridTemplateColumns: '65px 1fr',
        alignItems: 'start',
        paddingBottom: '6px'
    },
    addressItemsGrid: {
        width: '100%',
        display: 'grid',
        rowGap: '1px',
        gridTemplateColumns: '1fr',
        alignItems: 'flex-start'
        // paddingTop: '2px'
    },
    phoneRow: {
        display: 'grid',
        gridTemplateColumns: '65px 1fr',
        alignItems: 'start',
        paddingBottom: '6px'
    },
    clientSnapshotColumnThree: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '20px',
        paddingRight: '13px'
    },
    diagnosisRow: {
        width: '100%',
        display: 'flex',
        paddingTop: '9px'
    },
    detailLabel: {
        fontSize: '14px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: "#000000"
    },
    regularDetailValue: {
        fontSize: '14px',
        fontWeight: "normal",
        fontStyle: "normal",
        letterSpacing: 0,
        color: "#000000",
        paddingLeft: '4px',
        paddingRight: '4px'
    },
    emphasizedDetailValue: {
        textOverflow: 'ellipsis',
        overflowX: 'hidden',
        whiteSpace: 'nowrap',
        fontSize: '14px',
        fontWeight: "normal",
        fontStyle: "normal",
        color: QUATERNARY_COLOR,
        paddingLeft: '4px',
        paddingRight: '4px'
    },
    diagnosisValue: {
        fontSize: '14px',
        fontWeight: "normal",
        fontStyle: "normal",
        display: '-webkit-box',
        maxWidth: '200px',
        '-webkit-line-clamp': 4,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        paddingLeft: '4px'
    },
    checked: {
        "&:after": {
            content: "'\\2713'",
            display: "inline-block",
            fontSize: '12px',
            fontWeight: "bold",
            fontStyle: "normal",
            letterSpacing: 0,
            color: "#006c5A",
            paddingLeft: '10px'
        }
    },
    clientSnapshotColumnForTablet: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    imageForTablet: {
        width: '41px',
        height: '41px',
        borderRadius: '50%'
    },
    textLinkFont: {
        color: QUATERNARY_COLOR,
        fontSize: '14px',
        fontWeight: "normal",
        fontStyle: "normal",
        letterSpacing: 0,
        paddingLeft: '4px',
        paddingRight: '4px'
    }
}));

export default function ClientSnapshot({client, call, selectedTab}) {
    const phoneTypes = useSelector(syncService.descriptorCacheService.phoneContactType.getResults());
    const diagnoses = useSelector(syncService.diagnosisService.getResults());
    const visits = useSelector(syncService.visitService.getResults());
    const [physicalAddress, setPhysicalAddress] = useState({});
    const [phoneInfo, setPhoneInfo] = useState({});
    const [diagnosis, setDiagnosis] = useState('');
    const styles = useStyles();
    const isVisible = useSelector(appCache.visible);
    const dispatch = useDispatch();
    useEffect(() => {
        setPhysicalAddress(getPhysicalAddress(client.addresses));
    }, [client.addresses]);

    useEffect(() => {
        setPhoneInfo(getPhoneInfo(client.phones, phoneTypes));
    }, [client.phones, phoneTypes]);

    useEffect(() => {
        setDiagnosis(getPrimaryDiagnosis(diagnoses));
    }, [diagnoses]);

    const handleToggle = () => {
        dispatch(appCache.toggleVisibleFlag(!isVisible));
    }

    return (
        <>
            {isVisible && 
            <div>
                <div className={styles.clientSnapshot}>
                    <div className={styles.clientSnapshotColumn}>
                        <div className={styles.clientSnapshotColumnOne} >
                            <div className={styles.imageColumn}>
                                <img className={styles.image} src={getClientImage(client)} alt='client' />
                            </div>
                            <div className={styles.clientColumn}>
                                <div className={styles.clientNameLabel}>
                                    {formatUserName(client)}
                                </div>
                                <div className={styles.clientRow}>
                                    <span className={styles.detailLabel}>ID:</span>
                                    <span className={styles.regularDetailValue}>{formatClientId(client)}</span>
                                </div>
                                    <div className={styles.clientRow}>
                                        <div className={styles.detailLabel}>Gender:</div>
                                        <div className={styles.regularDetailValue}>{formatGender(client.gender)}</div>
                                    </div>
                                    <div className={styles.clientRow}>
                                        <div className={styles.detailLabel}>DOB:</div>
                                        {client.birthDate && <div className={styles.regularDetailValue}>{formatBirthDate(client.birthDate)}</div>}
                                        {client.birthDate && <div className={styles.detailLabel}>Age:</div>}
                                        {client.birthDate && <div className={styles.regularDetailValue}>{client.age}</div>}
                                    </div>
                                    <div className={styles.clientRow}>
                                        <div className={styles.detailLabel}>Email:</div>
                                        <div data-testid='ClientSnapshot_email' className={styles.emphasizedDetailValue}  onClick={() => showEmailApplication(getClientEmail(client.emails))}>{getClientEmail(client.emails)}</div>
                                    </div>
                            </div>
                        </div>

                    </div>
                        <div className={styles.clientSnapshotColumn}>
                            <div className={styles.clientSnapshotColumnTwo}>
                                <div className={styles.addressRow} >
                                    <div className={styles.detailLabel}>Physical Address:</div>
                                    <div data-testid='ClientSnapshot_address' className={styles.addressItemsGrid} onClick={() => showMapApplication(client)}>
                                        {physicalAddress.street1 &&
                                        <div className={styles.emphasizedDetailValue}>{physicalAddress.street1}</div>
                                        }
                                        {physicalAddress.street2 &&
                                        <div className={styles.emphasizedDetailValue}>{physicalAddress.street2}</div>
                                        }
                                        {physicalAddress.aptSuite &&
                                        <div className={styles.emphasizedDetailValue}>{'APT/Suite ' + physicalAddress.aptSuite}</div>
                                        }
                                        {(physicalAddress.city || physicalAddress.state || physicalAddress.zip) &&
                                        <div className={styles.emphasizedDetailValue}>{formatCityStateZip(physicalAddress.city, physicalAddress.state, physicalAddress.zip, physicalAddress.zipPlusFour)}</div>
                                        }
                                        {(physicalAddress.county || physicalAddress.country) &&
                                        <div className={styles.emphasizedDetailValue}>{formatCountyCountry(physicalAddress.county, physicalAddress.country)}</div>
                                        }
                                    </div>
                                </div>
                                {phoneInfo.primaryPhoneNumber &&
                                <div className={styles.phoneRow}>
                                    <div className={styles.detailLabel}>{phoneInfo.primaryLabel}</div>
                                    <Tooltip title={'Ok to ID'} placement="top-start">
                                        <div data-testid='ClientSnapshot_phone'
                                            className={styles.textLinkFont + (phoneInfo.primaryChecked ? ' ' + styles.checked : '')}
                                            onClick={() => showPhoneApplication(phoneInfo.primaryPhoneNumber)}>
                                            {phoneInfo.primaryPhoneNumber}
                                        </div>
                                    </Tooltip>
                                </div>
                                }
                                {phoneInfo.secondaryPhoneNumber &&
                                <div className={styles.phoneRow}>
                                    <div className={styles.detailLabel}>{phoneInfo.secondaryLabel}</div>
                                    <div data-testid='ClientSnapshot_phone_secondary' className={styles.textLinkFont + (phoneInfo.secondaryChecked ? ' ' + styles.checked : '')}
                                        onClick={() => showPhoneApplication(phoneInfo.secondaryPhoneNumber)}>
                                        {phoneInfo.secondaryPhoneNumber}
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    <div className={styles.clientSnapshotColumn} >
                        <div className={styles.clientSnapshotColumnThree} >
                            <VisitStopwatch embedded={true} durationSize='small' client={client} visits={visits} onhandleToggle={handleToggle} call = {call} selectedTab={selectedTab}/>
                            <div className={styles.diagnosisRow}>
                                <div className={styles.detailLabel}>Diagnosis:</div>
                                <div data-testid="diagnosisValue" className={styles.diagnosisValue}>{diagnosis}</div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>}
            
            <div style={{ display: isVisible ? "none" : "block" }}>
                <div className={styles.clientSnapshot} >
                    <div className={styles.clientSnapshotColumnForTablet}>
                        <div className={styles.clientSnapshotColumnOne} >
                            <div className={styles.imageColumn}>
                                <img className={styles.imageForTablet} src={getClientImage(client)} alt='client' />
                            </div>
                            <div className={styles.clientColumn}>
                                <div className={styles.clientNameLabel} data-testid='ClientSnapshot_UserName'>
                                    {formatUserName(client)}
                                </div>
                                <div className={styles.clientRow}>
                                    <span className={styles.detailLabel}>ID:</span>
                                    <span className={styles.regularDetailValue}>{formatClientId(client)}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                        <div className={styles.clientSnapshotColumnForTablet}>
                        </div>
                    <div className={styles.clientSnapshotColumnForTablet} >
                        <div className={styles.clientSnapshotColumnThree} >
                            <VisitStopwatch embedded={true} durationSize='small' client={client} visits={visits} onhandleToggle={handleToggle} isVisible={isVisible}  call = {call} selectedTab={selectedTab}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
