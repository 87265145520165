import {makeStyles} from "@material-ui/core/styles";
import {formatUserName, getFormattedStartDate, getFormattedStartTime} from "../../../common/utils/formatUtils";
import {DateTime} from "luxon";
import {getSystemInformation, getVersionName} from "../../../common/utils/systemUtils";
import {useSelector} from "react-redux";
import {appCache} from "../../../cache/slices/app/appSlice";
import {getHostName} from "../../../common/utils/systemUtils";
import Bowser from "bowser";
import { evvRepository } from "../../../db/evv";
import { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
    generalInformationContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    generalInformationTitle: {
        fontSize: '18px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: "#4f4f4f",
        [theme.breakpoints.down('sm')]: {
            paddingBottom: '24px'
        },
        [theme.breakpoints.up(601)]: {
            paddingBottom: '14px'
        }
    },
    generalInformationProperties: {
        display: 'grid',
        rowGap: '14px',
        gridTemplateColumns: 'repeat(2, 1fr)'
    },
    propertyLabel: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#4f4f4f'
    },
    propertyValue: {
        fontSize: '16px',
        color: '#4f4f4f'
    }
}));

export default function GeneralInformation() {
    const [lastSyncDateTime, setLastSyncDateTime] = useState(null)
    let systemInformation = getSystemInformation();
    const user = useSelector(appCache.getUser);
    const styles = useStyles();
    const hostName = getHostName();
    const packageFile = require('../../../../package.json');
    const buildNumber = packageFile?.version?.split('.')[1];
    const [osversion, setOsversion] = useState(null);

    useEffect(() => {
        evvRepository.getLastSyncInfo().then(syncInfo => {
            setLastSyncDateTime(DateTime.max(...syncInfo.map(o => DateTime.fromMillis(o.lastSyncMillis))))
        })
    }, [])

    useEffect(() => {
        const systemVersion = async () => {
            try {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                const sysInformation = Bowser.getParser(navigator.userAgent);
                const osVersionAsync = await getVersionName(sysInformation);
                setOsversion(osVersionAsync);
            } catch (error) {
                console.error('Error obtaining system information', error);
            }
        };
        systemVersion();
    }, [])

    const getFormattedDateTime = () => {
        return `${getFormattedStartDate(lastSyncDateTime)} ${getFormattedStartTime(lastSyncDateTime)}`
    }
    
    return (
        <div className={styles.generalInformationContainer}>
            <div className={styles.generalInformationTitle}>General Information</div>
            <div className={styles.generalInformationProperties}>
                <div className={styles.propertyLabel} >Device:</div>
                <div className={styles.propertyValue} >{systemInformation.deviceType}</div>
                <div className={styles.propertyLabel} >Operating System:</div>
                <div className={styles.propertyValue} >{systemInformation.osName + ' ' + osversion}</div>
                <div className={styles.propertyLabel} >Browser:</div>
                <div className={styles.propertyValue} >{systemInformation.browserName + ' ' + systemInformation.browserVersion}</div>
                <div className={styles.propertyLabel} >Environment:</div>
                <div className={styles.propertyValue} >[{hostName}] Build [{buildNumber}]</div>
                <div className={styles.propertyLabel} >Current User:</div>
                <div className={styles.propertyValue} >{formatUserName(user)}</div>
                <div className={styles.propertyLabel} >Last Sync Date/Time:</div>
                <div className={styles.propertyValue} >{lastSyncDateTime ? getFormattedDateTime() : '-' }</div>
            </div>
        </div>
    )
}
