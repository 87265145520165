import {makeStyles} from "@material-ui/core/styles";
import {useSelector, useDispatch} from "react-redux";
import Appointments from "../../../common/components/Appointments";
import {appCache} from "../../../cache/slices/app/appSlice";
import Tasks from "../../../common/components/Tasks";
import {DateTime} from "luxon";
import {useEffect, useState} from "react";
import {clientCache} from "../../../cache/slices/client/clientSlice";
import syncService from "../../../common/services/syncService";
import { prepareTasks } from "../../../common/data/tasks";
import { getTaskData } from "../../forms/common/IncompleteTasksUtils";
import { documentCache } from "../../../cache/slices/document/documentCache";
import { PRIMARY_COLOR } from "../../../common/constants"
import { getMobileAppointments } from "../../../common/utils/appointmentUtils";
import { ASYNC_OPERATION_STATUS } from "../../../cache/asyncHandler";


const useStyles = makeStyles((theme) => ({
    tasksTab: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
        display: 'flex'
    },
    loadingSpinnerContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    loadingSpinner: {
        color: PRIMARY_COLOR
    },
    appointmentsColumn: {
        ...theme.shadowedContainer,
        width: '100%',
        paddingLeft: '22px',
        paddingRight: '7px'
    },
    tasksColumn: {
        ...theme.shadowedContainer,
        width: '100%',
        paddingLeft: '20px',
        paddingRight: '7px'
    },
}));

export default function TasksTab() {
    const selectedClient = useSelector(clientCache.getSelectedClient);
    const appointments = useSelector(syncService.appointmentService.getResults());
    const appointmentsLoading = useSelector(syncService.appointmentService.isLoading());
    const tasks = useSelector(appCache.getTasks);
    const [filteredAppointments, setFilteredAppointments] = useState([]);
    const styles = useStyles();
    const dispatch = useDispatch();
    const user = useSelector(appCache.getUser);
    const documentCrosswalks = useSelector(syncService.documentCrosswalkService.getResults());
    const serviceDocuments = useSelector(syncService.serviceDocumentService.getResults());
    const [taskDataArr, setTaskDataArr] = useState([]);
    const currentDocumentId = useSelector(documentCache.getCurrentDocumentId);
    const organizationServiceStatus = useSelector(syncService.organizationService.getStatus());
    const activities = useSelector(syncService.activityService.getResults());
    const activityServiceStatus = useSelector(syncService.activityService.getStatus());
    
    useEffect(() => {
        setTaskDataArr([]);
        dispatch(appCache.setTasks([]));
        // eslint-disable-next-line
    }, [selectedClient.clientId]);

    useEffect(() => {
        // eslint-disable-next-line eqeqeq
        setFilteredAppointments(getMobileAppointments(appointments, activities).filter(a => a.clientId == selectedClient.clientId))
    }, [appointments,activities, selectedClient.clientId]);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        // eslint-disable-next-line
        let appointmentsFiltered = appointments.filter(a => a.clientId == selectedClient.clientId);
        if ( organizationServiceStatus === ASYNC_OPERATION_STATUS.SUCCESS && activityServiceStatus === ASYNC_OPERATION_STATUS.SUCCESS && (appointmentsFiltered.length > 0 || currentDocumentId) ) {
            getTaskData(appointmentsFiltered, documentCrosswalks, serviceDocuments, setTaskDataArr, user.staff, activities, dispatch);
        }
    }, [dispatch, user, appointments, selectedClient.clientId, currentDocumentId, organizationServiceStatus, activityServiceStatus, activities]);

    useEffect(() => {
        loadIncompleteTasks();
    }, [taskDataArr, selectedClient.clientId]);

    const loadIncompleteTasks = () => {
        const userToTasksMap = {};
        userToTasksMap[user?.userName] = prepareTasks(taskDataArr);
        dispatch(appCache.setTasks(userToTasksMap[user?.userName]));
    }

    return (
        <div className={styles.tasksTab}>
            <div className={styles.appointmentsColumn}>
                <Appointments appointments={filteredAppointments} appointmentsLoading={appointmentsLoading} startDate={DateTime.now()} maxDaysToDisplay={7} expandAll={true} includeEmpty={true} hideAccordianIcon={true} call = 'FacesheetPage' selectedClient={selectedClient}/>
            </div>
            <div className={styles.tasksColumn}>
            <Tasks tasks={tasks} isFacesheet={true}/>
            </div>
        </div>
    )
}