import {makeStyles} from "@material-ui/core/styles";
import {IconButton} from "@material-ui/core";
import SortBar from "../../../common/components/SortBar";
import SortDrawer from "../../../common/components/SortDrawer";
import {useDispatch, useSelector, useStore} from "react-redux";
import syncService from "../../../common/services/syncService";
import {useEffect, useState} from "react";
import {sortWithDirection} from "../../../common/utils/miscUtils";
import ClientDocumentCard from "../ClientDocumentCard";
import CircularProgress from "@material-ui/core/CircularProgress";
import DocumentChooserDialog from "../../forms/DocumentChooserDialog";
import {documentCache} from "../../../cache/slices/document/documentCache";
import {useHistory, useLocation} from "react-router-dom";
import {createNewDocument, convertServiceDocuments, chekDocPdf} from "../../../common/utils/documentUtils";
import {clientCache} from "../../../cache/slices/client/clientSlice";
import {appCache} from "../../../cache/slices/app/appSlice";
import AlertDialog from '../../../common/components/AlertDialog';
import { PDF_ONLINE_ERROR, PRIMARY_COLOR } from "../../../common/constants";
import { SERVICE_DOCUMENT_STATUS_SIGNED } from "../../forms/serviceDocumentUtils";
import { orderBy } from "lodash";

const useStyles = makeStyles(() => ({
    documentsPage: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column'
    },
    sectionHeader: {
        backgroundColor: "#e7e7e7",
        borderStyle: "solid",
        borderWidth: '1px',
        borderColor: '#c5c5c5',
        height: '46px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: '9px',
        paddingBottom: '5px',
        paddingLeft: '16px',
        paddingRight: '16px'
    },
    iconGroup: {
        display: 'flex',
        alignItems: 'center'
    },
    iconLabel: {
        fontSize: '16px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: PRIMARY_COLOR,
        paddingLeft: '5px'
    },
    iconButton: {
        padding: '0px',
        width: '32px',
        height: '32px'
    },
    iconButtonImage: {
        width: '100%',
        height: '100%'
    },
    loadingSpinnerContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    loadingSpinner: {
        color: PRIMARY_COLOR
    },
    noDocuments: {
        fontSize: "18px",
        fontWeight: "bold",
        fontStyle: "normal",
        lineHeight: "28px",
        letterSpacing: "-0.16",
        textAlign: "center",
        color: "#000000",
        paddingTop: '53px'
    }
}));

const columnDescriptors = [
    {
        name: 'status',
        title: 'Status',
        direction: 'asc'
    },
    {
        name: 'description',
        title: 'Name',
        direction: 'asc'
    },
    {
        name: 'serviceDate',
        title: 'Service Date',
        direction: 'asc'
    }
];

const filterColumnDescriptors = [
    {
        name: '',
        title: 'Show All'
    },
    {
        name: 'Complete',
        title: 'Complete'
    },
    {
        name: 'Incomplete',
        title: 'Incomplete'
    },
    {
        name: 'PDF',
        title: 'PDF'
    },
    {
        name: 'Signed',
        title: 'Signed'
    },
    {
        name: 'Unsigned',
        title: 'Unsigned'
    }
];


export default function DocumentsPage() {
    let docs = useSelector(syncService.documentService.getResults());
    const serviceDocuments = useSelector(syncService.serviceDocumentService.getResults());
    const [documents, setDocumentCollection] = useState([]);
    const[loadingDocuments, setLoadingDocuments] = useState(true);
    const isLoading = useSelector(syncService.serviceDocumentService.isLoading());
    const [clientDocuments, setClientDocuments] = useState([]);
    const [sortedDocuments, setSortedDocuments] = useState([]);
    const [sortDescriptor, setSortDescriptor] = useState();
    const [drawerOpened, setDrawerOpened] = useState(false);
    const [filterDrawerOpened, setFilterDrawerOpened] = useState(false);
    const [kebabDrawerOpened, setKebabDrawerOpened] = useState(false);
    const [filteredKebabDescriptors, setFilteredKebabDescriptors] = useState([]);
    const [showDocumentChooser, setShowDocumentChooser] = useState(false);
    const [clickedRow, setClickedRow] = useState(null);
    const [handleDelete, setHandleDelete] = useState(false);
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();
    const styles = useStyles();
    const client = useSelector(clientCache.getSelectedClient);
    const store = useStore();
    const user = useSelector(appCache.getUser);
    const staff = user.staff;
    let newDocument = {};
    const [alertDialogConfig, setAlertDialogConfig] = useState(null);

    const kebabColumnDescriptors = [
        {
            id: 'EDIT_DOCUMENT',
            title: 'Edit',
            image: `${process.env.PUBLIC_URL}/images/iconsEdit.svg`,
            statuses: ['Complete', 'Incomplete', 'Unsigned']
        },
        {
            id: 'DELET_DOCUMENT',
            title: 'Delete',
            image: `${process.env.PUBLIC_URL}/images/iconsDelete.svg`,
            statuses: ['Incomplete', 'Unsigned'],
        },
        {
            id: 'VIEW_DOCUMENT',
            title: 'View',
            image: `${process.env.PUBLIC_URL}/images/iconsView.svg`,
            statuses: ['Signed', 'PDF']
        }
    ];

    useEffect(() => {
        if (clientDocuments && clientDocuments.length > 0) {
            syncService.auditService.saveAuditEvent(null, client, user, "EVV Client Documents", "View");
        }
    }, [clientDocuments, client, user]);

    useEffect(() => {
        if(staff && client) {
            setTimeout(() => {
                syncService.documentService.getDocsCollection(client).then((result) => {
                    let signedDocs = result.filter(res => staff.staffId === res.staffId && (!res.documentStatus || res.documentStatus === SERVICE_DOCUMENT_STATUS_SIGNED));
                    let orderedSignedDocs = orderBy(signedDocs, ['serviceDate'], ['desc']);
                    dispatch(documentCache.setSignedDocuments(orderedSignedDocs));
                    setDocumentCollection(result);
                    setLoadingDocuments(false);
                }).catch((error) => {
                    console.log('Error: ', error);
                });
            },1000)
        }
        // eslint-disable-next-line
    }, [docs, client, staff]);

    const sortDocuments = () => {
        if (clientDocuments && clientDocuments.length > 0) {
            const sortPropertyNames = sortDescriptor ? [sortDescriptor.name] : [columnDescriptors[2].name, columnDescriptors[0].name, columnDescriptors[1].name];
            const sortDirections = sortDescriptor ? [sortDescriptor.direction] : ['desc', 'asc', 'asc'];

            setSortedDocuments(sortWithDirection(
                clientDocuments,
                sortPropertyNames,
                sortDirections
            ));
        } else {
            setSortedDocuments([]);
        }
    }

    const handleSortRequest = () => {
        toggleDrawer();
    }

    const handleSortBy = (columnDescriptor) => {
        let newSortDirection = 'asc';

        if (sortDescriptor && columnDescriptor.name === sortDescriptor.name) {
            if (sortDescriptor.direction === 'asc') {
                newSortDirection = 'desc';
            }
        } else {
            newSortDirection = columnDescriptor.direction
        }

        setDrawerOpened(false);

        setSortDescriptor({...columnDescriptor, direction: newSortDirection});
    }

    const toggleDrawer = () => {
        setDrawerOpened(!drawerOpened);
    }

    const handleFilterRequest = () => {
        toggleFilterDrawer();
    }

    const handleFilterBy = (columnDescriptor) => {
        if (columnDescriptor && columnDescriptor.name){
            setSortedDocuments(clientDocuments.filter(doc => doc.status === columnDescriptor.name));
        } else {
            sortDocuments();
        }

        setFilterDrawerOpened(false);
    }

    const toggleFilterDrawer = () => {
        setFilterDrawerOpened(!filterDrawerOpened);
    }

    const getVisibleColumnDescriptors = (clientDocument) => {
        return kebabColumnDescriptors.filter(item => item.statuses.includes(clientDocument.status));
    }

    const handleKebabRequest = (clientDocument) => {
        setClickedRow(clientDocument);
        setFilteredKebabDescriptors(getVisibleColumnDescriptors(clientDocument));
        toggleKebabDrawer();
    }

    const handleDeleteDocument = () => {
        setHandleDelete(true);
    }

    const processPdfDoc = async (savedDocument) => {
        let docPdfStatus = await chekDocPdf(savedDocument);

        if (docPdfStatus.error) {
            let pdfErrorDialogContent = {...PDF_ONLINE_ERROR, dialogMessage: docPdfStatus.errorMsg, dialogTitle: docPdfStatus.errorTitle};
            setAlertDialogConfig(pdfErrorDialogContent);
        } else {
            savedDocument = { ...savedDocument, pdfUrl: docPdfStatus.pdfUrl }
            dispatch(documentCache.setCurrentAptDocument([savedDocument]));
            history.push({
                pathname: `${location.pathname}/serviceDocumentPdf`
            });
        }
    }

    const handleEditViewDocument = async() => {
        if (clickedRow.status !== "PDF") {
            const aptDocCollection = await syncService.documentService.getServiceDoc(clickedRow.serviceDocumentId);
            if (aptDocCollection && aptDocCollection.length > 0) {
                const savedDocument = await syncService.documentService.findDocumentById(clickedRow.id); 
                dispatch(documentCache.setCurrentDocument(aptDocCollection[0]));
                dispatch(documentCache.setCurrentData({}));
                dispatch(documentCache.setCurrentAptDocument([savedDocument]));
                history.push({
                    pathname: `${location.pathname}/serviceDocument`,
                    state: { serviceDocument: savedDocument }
                });
            }
        } else {
            const savedDocument = await syncService.documentService.findDocumentById(clickedRow.id);
            await processPdfDoc(savedDocument);
        }
    }

    const handleAlertDialogClose = () => {
        setAlertDialogConfig(null);
    }
    
    const handleDeleteDialogClose = (isOk) => {
        setHandleDelete(false);
        if (isOk) {
            syncService.documentService.deleteDocument(clickedRow, {store, doNotClear: true})
        }
    }

    const handleManageAction = (columnDescriptor) => {
        setKebabDrawerOpened(false);
        switch(columnDescriptor.id) {
            case 'EDIT_DOCUMENT':
                handleEditViewDocument();
            break;
            case 'DELET_DOCUMENT':
                handleDeleteDocument();
            break;
            case 'VIEW_DOCUMENT':
                handleEditViewDocument();
            break;
            default:
              // code block
        }
    }

    const toggleKebabDrawer = () => {
        setKebabDrawerOpened(!kebabDrawerOpened);
    }

    const handleAddRequest = () => {
        if (serviceDocuments) {
            setShowDocumentChooser(true);
        }
    }

    const handleDocumentSelection = (document) => {
        setShowDocumentChooser(false);

        if (document){
            dispatch(documentCache.setCurrentDocument(document));
            dispatch(documentCache.setCurrentData({}));
            handleSaveNewDocument(document);
            history.push({
                pathname: `${location.pathname}/serviceDocument`,
                state: {
                    serviceDocument: newDocument
                }
            });
        }
    }

    const handleSaveNewDocument = (document) => {
        newDocument = createNewDocument(document, client, staff);
        syncService.documentService.saveNewDocument(newDocument, store, dispatch);
        dispatch(documentCache.setCurrentAptDocument([newDocument]));
    }

    useEffect(() => {
        setClientDocuments(convertServiceDocuments(documents).filter(doc => doc.clientId === Number(client.clientId)));
    }, [documents, client]);

    useEffect(() => {
        sortDocuments();
        /* eslint-disable react-hooks/exhaustive-deps */
    }, [clientDocuments, sortDescriptor]);

    const renderHeader = () => {
        return (
            <div className={styles.sectionHeader}>
                <div className={styles.iconGroup} onClick={handleAddRequest} >
                    <IconButton data-testid='DocumentsPage_new_document_button' type="button" classes={{label: styles.iconButtonImage}}
                                className={styles.iconButton}
                                aria-label="add">
                        <img className={styles.iconButtonImage} src='../../images/iconAddAppointment.svg' alt="add documents"/>
                    </IconButton>
                    <div className={styles.iconLabel}>New Document</div>
                </div>
                <div data-testid='DocumentsPage_filter_documents_clickee' className={styles.iconGroup} onClick={handleFilterRequest}>
                    <IconButton  data-testid='DocumentsPage_filter_documents_button' type="button" classes={{label: styles.iconButtonImage}}
                                className={styles.iconButton}
                                aria-label="filter" >
                        <img className={styles.iconButtonImage} src='../../images/iconIcoFilter.svg' alt="filter documents"/>
                    </IconButton>
                    <div className={styles.iconLabel}>Filter by Status</div>
                </div>
            </div>
        );
    };

    const renderPageContent = () => {
        if (sortedDocuments && sortedDocuments.length > 0) {
            return (
                sortedDocuments.map(cd => <ClientDocumentCard key={cd.id} clientDocument={cd} onKebabClick={handleKebabRequest}/>)
            );
        } else {
            return (
                <div className={styles.noDocuments}>No Documents Found</div>
            );
        }
    }

    const renderPage = () => {
        if (isLoading || loadingDocuments) {
            return (
                <div className={styles.loadingSpinnerContainer}>
                    <CircularProgress size={50} className={styles.loadingSpinner}/>
                </div>
            );
        } else {
            return (
                <div>
                    {renderHeader()}
                    <SortBar sortedColumn={sortDescriptor ? sortDescriptor : columnDescriptors[2]}
                             handleSortRequest={handleSortRequest}/>
                    {renderPageContent()}
                    <SortDrawer
                        anchor='bottom'
                        labelPrefix='Sort By'
                        open={drawerOpened}
                        columnDescriptors={columnDescriptors}
                        showCancel={true}
                        handleCancel={toggleDrawer}
                        handleSortBy={handleSortBy}
                    />
                    <SortDrawer
                        anchor='bottom'
                        labelPrefix=''
                        open={filterDrawerOpened}
                        columnDescriptors={filterColumnDescriptors}
                        showCancel={true}
                        handleCancel={toggleFilterDrawer}
                        handleSortBy={handleFilterBy}
                    />
                    <SortDrawer
                        anchor='bottom'
                        labelPrefix=''
                        open={kebabDrawerOpened}
                        columnDescriptors={filteredKebabDescriptors}
                        showCancel={true}
                        handleCancel={toggleKebabDrawer}
                        handleSortBy={handleManageAction}
                    />
                    { handleDelete &&
                        <AlertDialog
                        open = { true }
                        dialogTitle = 'Confirm Delete'
                        dialogMessage = 'Are you sure you want to delete this document?'
                        showOkButton = { true }
                        okButtonText = 'Ok'
                        showCancelButton = { true }
                        cancelButtonText = 'Cancel'
                        handleClose = { handleDeleteDialogClose }
                        />
                    }
                    {alertDialogConfig &&
                        <AlertDialog
                            open={true}
                            dialogTitle={alertDialogConfig.dialogTitle}
                            dialogMessage={alertDialogConfig.dialogMessage}
                            showOkButton={alertDialogConfig.showOkButton}
                            showCancelButton={alertDialogConfig.showCancelButton}
                            okButtonText={alertDialogConfig.okButtonText ? alertDialogConfig.okButtonText : 'Ok'}
                            handleClose={handleAlertDialogClose}
                        />
                    }
                </div>
            );
        }
    }

    return (
        <div className={styles.documentsPage}>
            {renderPage()}
            {showDocumentChooser &&
                <DocumentChooserDialog documents={serviceDocuments} onClose={handleDocumentSelection} call = "NewDocument"/>
            }
        </div>
    );
}