import { makeStyles } from "@material-ui/core/styles";
import { DateTime } from "luxon";
import { useEffect, useRef, useState } from "react";
import { getSystemInformation } from "../../../common/utils/systemUtils";
import { START_SESSION, STOP_SESSION } from "../../../common/constants";
import EvvButton from "../EvvButton";
import AlertDialog from "../../../common/components/AlertDialog";

const stopwatchDuration = {
    fontWeight: "bold",
    fontStyle: "normal",
    letterSpacing: 0,
    color: "#4f4f4f"
};

const useStyles = makeStyles(() => ({
    stopwatch: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: "#e7e7e7",
        borderStyle: "solid",
        borderWidth: 0.5,
        borderColor: "#c5c5c5",
        padding: '9px 17px 8px 16px',
    },
    stopwatchEmbedded: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: "inherit",
        borderWidth: "0px",
        padding: '9px 0px 8px 0px',
    },
    stopwatchEmbeddedOnly: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: "inherit",
        borderWidth: "0px",
        padding: '0px',
    },
    stopwatchButton: {
        display: 'flex',
        width: '100%',
        maxWidth: '236px',
        "@media (max-width: 1024px)": {
            maxWidth: '188px'
        }
    },
    stopwatchDurationLarge: {
        ...stopwatchDuration,
        fontSize: '28px',
        paddingRight: '16px'
    },
    stopwatchDurationSmall: {
        ...stopwatchDuration,
        fontSize: '22px',
        paddingRight: '10px',
        "@media (max-width: 1024px)": {
            fontSize: '17px'
        }
    },
    stopwatchDurationTiny: {
        ...stopwatchDuration,
        fontSize: '18px'
    },
    triangleDown: {
        width: '14px',
        height: '10px',
        borderLeft: '10px solid transparent',
        borderRight: '10px solid transparent',
        borderTop: '15px solid #cbcbcb',
        marginTop: '0.5rem',
        marginLeft: '0.5rem',
      },
      triangleRight: {
        width: '14px',
        height: '10px',
        borderLeft: '10px solid transparent',
        borderRight: '10px solid transparent',
        borderTop: '15px solid #cbcbcb',
        marginTop: '0.5rem',
        marginLeft: '0.5rem',
        transform : 'rotate(270deg)',
      },
}));

const INITIAL_DURATION = '00:00:00';
let stateCurrentPosition = '';

export default function Stopwatch({embedded, durationSize='large', hideDuration, hideButton, onStartRequest, onStopRequest, sessionStartTime, renderStopwatchToggleComponent, isVisible, onhandleToggle, isHandheld}) {
    const [stopwatchDuration, setStopwatchDuration] = useState(INITIAL_DURATION);
    const [stopwatchActive, setStopwatchActive] = useState(!!sessionStartTime);
    const stopwatch = useRef({sessionStartTime});
    const styles = useStyles();
    const [alertDialogConfig, setAlertDialogConfig] = useState(null);

    useEffect(() => {
        const stopwatchSessionStartTimeMs = stopwatch.current.sessionStartTime ? stopwatch.current.sessionStartTime.toMillis() : 0;

        if (sessionStartTime && sessionStartTime.toMillis() !== stopwatchSessionStartTimeMs && !stopwatchActive){
            startTimer(sessionStartTime);
        } else if (!sessionStartTime && stopwatchActive){
            stopTimer();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionStartTime]);

    useEffect(() => {
        let interval = null;
        if (stopwatchActive) {
            interval = setInterval(updateDuration, 100);
        } else if (!stopwatchActive && stopwatchDuration !== INITIAL_DURATION) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [stopwatchActive, stopwatchDuration]);

    const updateDuration = () => {
        setStopwatchDuration(DateTime.now().diff(stopwatch.current.sessionStartTime, ['hours', 'minutes', 'seconds']).toFormat("hh:mm:ss"));
    }

    const startTimer = (startTime) => {
        stopwatch.current.sessionStartTime = startTime ? startTime : DateTime.now();
        setStopwatchActive(true);
    }

    const restartTimer = () => {
        stopwatch.current.sessionEndTime = null;
        setStopwatchActive(true);
    }

    const stopTimer = (stopTime) => {
        stopwatch.current.sessionEndTime = stopTime ? stopTime : DateTime.now();
        setStopwatchActive(false);
        setStopwatchDuration(INITIAL_DURATION);
    }

    const toggleSession = () => {
        handlePermission();
    }

    const handlePermission = () => {
        const systemInformation = getSystemInformation();
        const optionsGeolocation = { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 };
        stateCurrentPosition = 'timeout';

        if ((systemInformation.platformType === 'Mobile' || systemInformation.platformType === 'Tablet')
            && (systemInformation.osName === 'macOS' || systemInformation.osName === 'iOS')
            && systemInformation.browserName === 'Safari') {
            const currentPosition = new Promise((resolve) => {
                setTimeout(resolve, 1000, navigator.geolocation.getCurrentPosition(
                    success,
                    handleGeoLocationFailure,
                    optionsGeolocation));
            });

            const timeOutGeolocation = new Promise(() => {
                setTimeout(() => handleGeoLocationNotResponse(), 3000);
            });

            Promise.race([currentPosition, timeOutGeolocation]);

        } else {
            navigator.permissions.query({ name: 'geolocation' }).then((result) => {
                navigator.geolocation.getCurrentPosition(success, handleGeoLocationFailure, optionsGeolocation);
            });
        }
    }

    const success = () => {
        stateCurrentPosition = 'success';
        setAlertDialogConfig(null);
        if (stopwatchActive) {
            if (onStopRequest) {
                onStopRequest(stopTimer, restartTimer);
            } else {
                stopTimer();
            }
        } else {
            if (onStartRequest) {
                onStartRequest(startTimer);
            } else {
                startTimer();
            }
        }
    }
    
    const handleGeoLocationFailure = () => {
        stateCurrentPosition = 'failure';
        failVisit(stateCurrentPosition);
    }

    function handleGeoLocationNotResponse() {
        if (stateCurrentPosition === 'timeout') {
            failVisit(stateCurrentPosition);
        }
    }

    const failVisit = (stateCurrentPosition) => {
        const actionType = stopwatchActive ? STOP_SESSION : START_SESSION;
        let dialogMessage = '';

        if (stateCurrentPosition === 'failure') {
            dialogMessage = `Cannot ${actionType} for a Client with location services turned off. Please turn on location services for your browser and try again.`
        } else if (stateCurrentPosition === 'timeout') {
            dialogMessage = `Cannot ${actionType}. Please set Location Services on your device to Allow for your device and browser.`
        }

        if (stateCurrentPosition === 'failure' || stateCurrentPosition === 'timeout') {
            setAlertDialogConfig({
                dialogTitle: 'GPS Error',
                dialogMessage: dialogMessage,
                showOkButton: true,
                showCancelButton: false
            });
        }
    }

    const handleAlertDialogClose = () => {
        setAlertDialogConfig(null);
    }

    const handleClick = () => {
        onhandleToggle();
    }

    const renderStopwatchDuration = () => {
        if (hideDuration !== true){
            let durationClassName = styles.stopwatchDurationLarge;

            if (durationSize === 'small'){
                durationClassName = styles.stopwatchDurationSmall;
            } else if (durationSize === 'tiny'){
                durationClassName = styles.stopwatchDurationTiny;
            }

            return (<div className={durationClassName}>{stopwatchDuration}</div>);
        }

        return null;
    }

    const renderStopwatchToggle = () => {
        if (hideButton !== true){
            if (renderStopwatchToggleComponent){
                return renderStopwatchToggleComponent(stopwatchActive, toggleSession);
            }

            return (
                <div className={styles.stopwatchButton}>
                    <EvvButton onClick={toggleSession} type='ctaMedium'>{stopwatchActive ? 'Stop Session' : 'Start Session'}</EvvButton>
                    {!isHandheld && <div data-testid='triangle_icon' className = { isVisible === false ? styles.triangleRight : styles.triangleDown } onClick = {() => handleClick()}></div>}
                </div>
            );
        }

        return null;
    }

    let stopwatchClassName = embedded ? styles.stopwatchEmbedded : styles.stopwatch;

    if (hideDuration === true){
        stopwatchClassName = styles.stopwatchEmbeddedOnly;
    }

    return (
        <div className={stopwatchClassName}>
            {renderStopwatchDuration()}
            {renderStopwatchToggle()}
            {alertDialogConfig &&
            <AlertDialog
                open={true}
                dialogTitle={alertDialogConfig.dialogTitle}
                dialogMessage={alertDialogConfig.dialogMessage}
                showOkButton={alertDialogConfig.showOkButton}
                showCancelButton={alertDialogConfig.showCancelButton}
                handleClose={handleAlertDialogClose}
            />
            }
        </div>
    )
}
