import {useState, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import { IconButton } from "@mui/material";
import CloseIcon from '@material-ui/icons/Close';
import { cloneDeep } from "lodash/lang";
import { documentCache } from "../../../cache/slices/document/documentCache";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import DocumentsTab from "./DocumentsTab";
import syncService from "../../../common/services/syncService";
import { tabChangeSetting } from "../../dashboard/AppointmentCard/EditDocumentUtils";
import { PDF_SERVICE_DOC, PRIMARY_COLOR } from "../../../common/constants";

const useStyles = makeStyles((theme) => ({
    tabletFacesheetPage: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        columnGap: '20px'
    },
    tabBar: {
        ...theme.shadowedContainer,
        height: 'auto',
        width: 'auto',
        overflow: 'unset',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: 'none'
    },
    tabs: {
        height: '33px',
    },
    tabIndicator: {
        height: '3px',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent !important',
        '& > span': {
            maxWidth: '120px',
            width: '60%',
            backgroundColor: "transparent !important"
        },
        marginBottom: '1px'
    },
    tab:{
        fontSize: '14px !important',
        fontWeight: "bold !important",
        fontStyle: "normal !important",
        letterSpacing: 0,
        color: "#898989 !important",
        textTransform: 'none !important',
        opacity: 1,
        backgroundColor: "#F1F1F1 !important",
        borderRadius: '3px !important',
        boxShadow: '0px 0px 3px 3px rgba(0, 0, 0, 0.04)',
        border: '2px solid #F1F1F1 !important'
    },
    tabx: {
        ...theme.shadowedContainer,
        overflow: 'inherit'
    },
    selectedTab: {
        opacity: '1 !important',
        color: PRIMARY_COLOR,
        backgroundColor: "#FFFFFF !important"
    },
    
    closeIcon: {
        top: '2px !important'
    },

    taby: {
        margin: '0px',
        overflow: 'auto',
        boxShadow: 'none',
        borderRadius: '3px',
        backgroundColor: 'transparent',
        width: '100%'
    },

    internalTabLabel: {
        whiteSpace: 'nowrap',
        width: '80%',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    const styles = useStyles();

    if (value === index){
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`Tab_Internal_${index}`}
                aria-labelledby={`Tab_Internal_${index}`}
                className={styles.tabx}
                {...other}
            >
                {children}
            </div>
        );
    } else {
        return null;
    }
}

export default function NewDocuments() {
    const selectedDocTab = useSelector(documentCache.getSelectedDocTab);
    const selectedDocTabGlobal = useSelector(documentCache.getSelectedDocTabGlobal);
    const styles = useStyles();
    const serviceDocTab = cloneDeep(useSelector(documentCache.getServiceDocumentsForTabs));
    const [selectedTab, setSelectedTab] = useState(selectedDocTabGlobal);
    const dispatch = useDispatch();

    const updateServiceDocTabData = async(index, id) => {
        const selectedDoc = await syncService.documentService.findDocumentById(id);
        if (selectedDoc) {
            serviceDocTab[index] = selectedDoc;
            dispatch(documentCache.setServiceDocumentsForTabs(serviceDocTab));
        }
    }

    const handleChange = async(event, newValue) => {
        if (newValue > 0 && serviceDocTab[newValue]) {
            if (!serviceDocTab[newValue].documentStatus) {
                await updateServiceDocTabData(newValue, serviceDocTab[newValue].id);
                let changedDocument = serviceDocTab[newValue];
                tabChangeSetting(changedDocument, [PDF_SERVICE_DOC], dispatch);
            } else {
                await updateServiceDocTabData(newValue, serviceDocTab[newValue].id);
                const aptDocCollection = await syncService.documentService.getServiceDoc(serviceDocTab[newValue]?.serviceDocumentId);
                if (aptDocCollection && aptDocCollection.length > 0) {
                    let changedDocument = serviceDocTab[newValue];
                    tabChangeSetting(changedDocument, aptDocCollection, dispatch);
                }
            }
        }
        
        if (serviceDocTab[newValue]) {
            setSelectedTab(newValue);
        }
    };

    useEffect(() => {
        dispatch(documentCache.setSelectedDocTabGlobal(selectedTab));
        // eslint-disable-next-line
    }, [selectedTab]);

    useEffect(() => {
        if (selectedDocTab !== null) {
            if (selectedDocTab.id) {
                let activeDoc = serviceDocTab.find((item) => item.id === selectedDocTab.id);
                if (activeDoc) {
                    let activeDocIndex = serviceDocTab.indexOf(activeDoc);
                    setSelectedTab(activeDocIndex);
                }
            } else {
                setSelectedTab(0);
            }
            dispatch(documentCache.setSelectedDocTab(null));
        }
        // eslint-disable-next-line
    }, [selectedDocTab]);

    let TABS = [];
    if(TABS && TABS.length <= 4) {
        serviceDocTab.forEach(doc => {
            TABS.push(doc);
        });
    }

    const handleDeleteTab = (event, tab, index) => {
        event.stopPropagation();
        if (serviceDocTab) {
            const removeDoc = serviceDocTab.filter((item) => item.id !== tab.id);
            dispatch(documentCache.setServiceDocumentsForTabs(removeDoc));
            
            if (index === selectedTab) {
                setSelectedTab(0);  //Redirect to All Documents tab
                dispatch(documentCache.setSelectedDocTabGlobal(0));
            } 
            // else if (selectedTab > index) {
            //     setSelectedTab(selectedTab - 1);    //Previous tab closing
            // } else {
            //     setSelectedTab(selectedTab);    //Next tab closing
            // }
        }
    }

    return (
        <>
            {serviceDocTab[selectedTab] && <div className={styles.tabBar}>
                <Tabs
                    variant="fullWidth"
                    value={selectedTab}
                    onChange={handleChange}
                    classes={{root: styles.tabs, indicator: styles.tabIndicator}}
                >
                    {TABS.map((tabLabel, index) =>
                        <Tab 
                            key={index} 
                            classes={{root: styles.tab, selected: styles.selectedTab}} 
                            id = {`internal-tab-${index}`}
                            style={{'marginLeft': (index === 0) ? '0px' : '5px', 'marginRight': (index === serviceDocTab.length - 1 ) ? '0px' : '5px'}}
                            label={
                                <span className={styles.internalTabLabel}> 
                                    {tabLabel?.description || tabLabel?.documentName}
                                    { index !== 0 && index === selectedTab && <IconButton
                                        className={styles.closeIcon}
                                        data-testid = {'closeIcon'}
                                        sx={{
                                            position: 'absolute',
                                            right: 0,
                                            top: 4,
                                            color: (theme) => theme.palette.grey[500],
                                        }}
                                        onClick={(event) => handleDeleteTab(event, tabLabel, index)}
                                    >
                                        <CloseIcon />
                                    </IconButton>}
                                </span>
                            }
                        />)}
                </Tabs>
            </div>}

            {serviceDocTab[selectedTab] && TABS.map((tab, index) =>
                <TabPanel key={index} index={index} value={selectedTab}>
                    <DocumentsTab internalCall = { true } selectedTab = { selectedTab } />
                </TabPanel>
            )}
        </>
    )
}