
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DataTable from '../../../common/components/DataTable';
import CircularProgress from '@material-ui/core/CircularProgress';
import { PRIMARY_COLOR } from '../../../common/constants';
import { sortWithDirection } from '../../../common/utils/miscUtils';
import { getOrderByProperty } from '../../../common/utils/tableUtils';
import { useSelector } from 'react-redux';
import syncService from '../../../common/services/syncService';
import { clientCache } from '../../../cache/slices/client/clientSlice';
import { appCache } from '../../../cache/slices/app/appSlice';
import { DateTime } from 'luxon';
 
const useStyles = makeStyles(() => ({
  messagesTab: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column'
  },
  loadingSpinnerContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loadingSpinner: {
    color: PRIMARY_COLOR
  },
  noMessagesFoundContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  noMessagesFound: {
    fontSize: '18px',
    fontWeight: "bold",
    fontStyle: "normal",
    color: "#000000",
    paddingTop: '56px'
  }
}));
 
export const MessageBoard = () => {
  const styles = useStyles();
  const messages = useSelector(syncService.messageService.getResults());
  const isLoading = useSelector(syncService.messageService.isLoading());
  const client = useSelector(clientCache.getSelectedClient);
  const user = useSelector(appCache.getUser);

  useEffect(() => {
    if(messages && messages.length > 0) {
        syncService.auditService.saveAuditEvent(null, client, user, "EVV Client Messages", "View");
    }
  }, [messages, client, user]);

  const getFormattedDate = (date) => {
    if (date) {
      return DateTime.fromFormat(date,"yyyy-LL-dd").toFormat('LL/dd/yyyy')
    } else {
      return "Not Set";
    }
  }

  const getFormattedMessage = (color, message) => {
    return <div style = {{ color: (color && color !== "" && color !== "None") ? color : '#424242' }}> { message ? message : '' } </div>
  }

  const handleSort = (records, columnDescriptor, orderByDirection) => {
    const alwaysFirst = records.filter(message => message.messages === '');
    const recordsToSort = records.filter(message => message.messages !== '');

    return [
      ...alwaysFirst,
      ...sortWithDirection(
          recordsToSort,
          [getOrderByProperty(columnDescriptor), (a) => a?.postDate ? a?.postDate : 0],
          [orderByDirection, 'desc']
        )
    ];
};
  const columnDescriptors = [
    { id: 'postDate', sort: 'desc', width: '15%', label: 'Post Date', fieldName: 'postDate', contentRenderer: (row) => ( getFormattedDate(row?.postDate) ) },
    { id: 'endDate', width: '14%', label: 'Expires On', fieldName: 'endDate', contentRenderer: (row) => ( getFormattedDate(row?.endDate) ) },
    { id: 'message', width: '71%', fieldName: 'message', label: 'Message', contentRenderer: (row) => ( getFormattedMessage(row?.highlightColor, row?.message) )}
  ];

  const renderPage = () => {
    if (isLoading) {
      return (
        <div className={styles.loadingSpinnerContainer}>
          <CircularProgress size={50} className={styles.loadingSpinner} />
        </div>
      );
    } else {
      return (
        <>
         {messages.length > 0 ?
          (<DataTable
            columnDescriptors={columnDescriptors}
            records={messages}
            handleSort={handleSort}
          />
          ) : (
            <div className={styles.noMessagesFoundContainer}>
                <div className={styles.noMessagesFound} >
                  No messages found
                </div>
            </div>
         )}
        </>
      );
    }
  };
 
  return <div className={styles.messagesTab}>{renderPage()}</div>;
};