import { Drawer } from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import { PRIMARY_COLOR, TERTIARY_COLOR } from "../../../common/constants"
import '../../../../src/index.css';
import DataProvider from "../../forms/DataProvider";
import MenuIcon from '@mui/icons-material/Menu';
import CircleIcon from "@mui/icons-material/FiberManualRecord";
import EvvButton from "../../../common/components/EvvButton";
import { useDispatch, useSelector, useStore } from "react-redux";
import ServiceDocumentWizardPagePdf from "./ServiceDocumentWizardPagePdf";
import SignaturePageWithPdf from "../../forms/SignaturePage/SignaturePageWithPdf";
import { documentCache } from "../../../cache/slices/document/documentCache";
import { cloneDeep } from "lodash";
import { getStaffId } from "../../forms/serviceDocumentUtils";
import syncService from "../../../common/services/syncService";
import clientService from "../../../common/services/clientService";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { clientCache } from "../../../cache/slices/client/clientSlice";
import { DateTime } from "luxon";
import { validateSignaturesPDF } from "../../forms/common/ValidateFields";
import { addLatestExtSigDocument } from "../../../common/utils/documentUtils";

const menuItem = {
    minWidth: '242px',
    height: 'auto',
    borderRadius: '3px',
    backgroundColor: '#f2f2f2',
    fontSize: '14px',
    fontWeight: "bold",
    fontStyle: "normal",
    color: "#4f4f4f",
    paddingLeft: '11px',
    paddingTop: '12px',
    paddingBottom: '10px',
    marginBottom: '8px'
};

const useStyles = makeStyles(() => ({
    wizard: {
        width: '100%',
        height: '100%',
        display: 'flex'
    },
    wizardNavigator: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: PRIMARY_COLOR
    },
    wizardNavigator2: {
        height: 'fit-content',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: PRIMARY_COLOR
    },
    navigatorDrawer: {
        top: '114px',
        backgroundColor: PRIMARY_COLOR
    },
    wizardHeader: {
        width: '100%',
        display: 'flex',
        height: '43px',
        backgroundColor: '#f7f7f7',
        borderStyle: "solid",
        borderWidth: '1px',
        borderColor: '#c5c5c5'
    },
    menuAndStatus: {
        width: '100%',
        display: 'flex',
        paddingLeft: '7px',
        paddingTop: '7px',
        paddingBottom: '4px',
        alignItems: 'center'
    },
    hamburgerMenu: {
        width: '32px',
        height: '32px',
        padding: '8px 4px',
        marginRight: '16px'
    },
    hamburgerIconNavigator: {
        fill: 'white',
        marginRight: '16px'
    },
    hamburgerIconHeader: {
        fill: 'black',
        marginRight: '16px'
    },
    wizardBody: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    navigationPanel: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0px 8px',
        backgroundColor: PRIMARY_COLOR
    },
    wizardMenuItem: {
        ...menuItem,
        backgroundColor: TERTIARY_COLOR,
        color: "#ffffff"
    },
    selectedWizardMenuItem: {
        ...menuItem,
        backgroundColor: '#f2f2f2',
        color: "#4f4f4f"
    },
    wizardPage: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
        display: 'flex'
    },
    wizardFooter: {
        width: '100%',
        // height: '86px',
        padding: "15px 16px",
        justifyContent: 'space-between'
    },
    navigationButtonsforhandheld: {
        height: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    navigationButtonsfortablet: {
        height: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        marginLeft:'75%'
    },
    status: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '10px'
    },
    navigatorStatus: {
        color: "#ffffff"
    },
    statusIndicator: {
    },
    statusIndicatorIcon: {
        width: '18px',
        height: '18px'
    },
    statusTitle: {
        fontSize: '17px',
        fontWeight: "bold",
        fontStyle: "normal"
    },
    statusValue: {
        fontSize: '17px',
        fontWeight: "normal",
        fontStyle: "normal",
        paddingLeft: '6px',
    },
    saveButton: {
        padding: '0',
        minWidth: '125px',
        paddingLeft: '17px',
        marginLeft:'auto'
    },
    PreviousButton:{            
        padding: '0',
        minWidth:'125px',
        paddingRight:'17px',
        marginRight:'auto',
    }
}));

export default function ServiceDocumentWizardPageExternalSignNeeded({onHideDocument, jestTestCall}) {
    const styles = useStyles();
    const store = useStore();
    const dispatch = useDispatch()
    const history = useHistory();

    const documentController = {
        dataProvider: new DataProvider(store.getState())
    };

    const modules = [
        {name: "PDF"},
        {name: "Signatures", signaturePage: true}
    ];

    let isHandheld = documentController.dataProvider.isHandheld();
    let isHeaderVisible = isHandheld;
    const serviceDocTab = cloneDeep(useSelector(documentCache.getServiceDocumentsForTabs));
    const selectedDocTabGlobal = useSelector(documentCache.getSelectedDocTabGlobal);
    const appointmentDocument = useSelector(documentCache.getCurrentAptDocument);
    const client = useSelector(clientCache.getSelectedClient);

    const [isNavigatorVisible, setIsNavigatorVisible] = useState(isHandheld ? false : true);
    // eslint-disable-next-line
    const [status, setStatus] = useState("Incomplete");
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    // eslint-disable-next-line
    const [saveDisable, setSaveDisable] = useState(jestTestCall ? false : true);
    const [serviceDocument, setServiceDocument] = useState();
    const [flag, setFlag] = useState(false);
    const [errors, setErrors] = useState({ signatures: [], names: [], reasons: [] });

    const wizardName = serviceDocument && serviceDocument?.docSignaturePadList ? serviceDocument?.documentName : "";

    const isExternalSigNeededDoc = () => {
        return serviceDocTab && selectedDocTabGlobal && serviceDocTab[selectedDocTabGlobal]?.docSignaturePadList && appointmentDocument && appointmentDocument?.length > 0 && appointmentDocument[0]?.id === serviceDocTab[selectedDocTabGlobal]?.id;
    }

    useEffect(() => {
        if (!flag && isExternalSigNeededDoc()) {
            setServiceDocument(serviceDocTab[selectedDocTabGlobal]);
            setFlag(true);
        }
    // eslint-disable-next-line
    }, [serviceDocTab, selectedDocTabGlobal, appointmentDocument]);

    const handleDrawerClose = (event, reason) => {
        if (reason === 'backdropClick' || (reason === 'navigator' && event.target.id === 'navigator')){
            if (isHandheld) {
                setIsNavigatorVisible(false);
            }
        }
    }

    const handleMenuButtonClick = () => {
        setIsNavigatorVisible(!isNavigatorVisible);
    };

    const getColorForStatus = () => {
        return '#e16b5a';
    }

    const renderStatusIndicator = (isNavigator) => {
        let statusClassName = styles.status;
        let iconClassName = isNavigator ? styles.hamburgerIconNavigator : styles.hamburgerIconHeader;

        if (isNavigator){
            statusClassName += (' ' + styles.navigatorStatus);
        }
        return (
            <div className={styles.menuAndStatus}>
                {isHandheld &&
                <MenuIcon className={iconClassName} onClick={handleMenuButtonClick}/>
                }
                <div className={styles.statusIndicator}>
                    <CircleIcon classes={{root: styles.statusIndicatorIcon}} htmlColor={getColorForStatus()} />
                </div>
                <div className={statusClassName}>
                    <div className={styles.statusTitle} >Status:</div>
                    <div className={styles.statusValue} >{status}</div>
                </div>
            </div>
        )
    };

    const handlePageClick = (selectedPageIndex) => {
        changePage(selectedPageIndex);
    };

    const renderNavigationItem = (pageDescriptor, pageIndex) => {
        const isSelected = pageIndex === currentPageIndex;
        const className = isSelected ? styles.selectedWizardMenuItem : styles.wizardMenuItem;

        return (<div data-testid={`WizardMenuItem_${pageDescriptor.name}`} key={`navigationItem_${pageIndex}`} className={className} onClick={() => handlePageClick(pageIndex)} >{pageDescriptor?.name} </div>);
    }

    const renderNavigator = () => {
        return (
            <div id='navigator' className={styles.wizardNavigator} onClick={e => handleDrawerClose(e, "navigator")}>
                {renderStatusIndicator(true)}
                <div className={styles.navigationPanel}>
                    {modules.map((pageDescriptor, index) => renderNavigationItem(pageDescriptor, index))}
                </div>
            </div>
        )
    };

    const renderDrawerNavigator = () => {
        return (
            <Drawer classes={{paper: styles.navigatorDrawer}} anchor="left" open={isNavigatorVisible} onClose={handleDrawerClose}>
                {renderNavigator()}
            </Drawer>
        );
    };

    const renderWizardNavigator = () => {
        if (isHandheld){
            return renderDrawerNavigator();
        } else {
            if (isNavigatorVisible) {
                return renderNavigator();
            } else {
                return null;
            }
        }
    };

    const changePage = (toPageIndex) => {
        setCurrentPageIndex(toPageIndex);
        setIsNavigatorVisible(isHandheld ? false : isNavigatorVisible);
    };

    const nextPage = () => {
        changePage(currentPageIndex + 1);
    };

    const previousPage = () => {
        changePage(currentPageIndex - 1);
    };

    const createParameters = () => {
        let signatureInfo = {...serviceDocument?.signature};
        if (signatureInfo && signatureInfo?.externalSignatures) {
            let filteredExtSig = signatureInfo?.externalSignatures?.filter(ele => ele?.unableToCollectYN === "Y" || (ele?.unableToCollectYN === "N" && ele?.signature));
            signatureInfo = { ...signatureInfo, externalSignatures: filteredExtSig }
        }

        let parameters = {
            resources: [
                {
                    id: serviceDocument?.id,
                    serviceDocumentId: serviceDocument?.serviceDocumentId,
                    appointmentId: serviceDocument?.appointmentId ? serviceDocument?.appointmentId : null,
                    clientId: serviceDocument?.clientId,
                    clientProgramId: serviceDocument?.clientProgramId ? serviceDocument?.clientProgramId : null,
                    staffId: getStaffId(serviceDocument, documentController),
                    organizationId: documentController.dataProvider.getCurrentOrganizationId(),
                    serviceDate: serviceDocument?.serviceDate.substring(0,10),
                    fullySigned: true,
                    moduleData: [],
                    signature: Object.keys(signatureInfo).length > 0 ? signatureInfo : undefined
                }
            ]
        }

        return parameters;
    }

    const updateServiceDocTabData = () => {
        if (serviceDocTab) {
            const updatedServiceDocTab = serviceDocTab.filter((item) => item.id !== serviceDocument?.id);
            dispatch(documentCache.setServiceDocumentsForTabs(updatedServiceDocTab));
            dispatch(documentCache.setSelectedDocTab({description:'All Documents'}));
        }
    }

    const redirectPage = () => {
        if (isHeaderVisible) {
            history.push("/facesheet/documents")
        } else {
            updateServiceDocTabData();
            clientService.visitClient(client, DateTime.now().toMillis());
            dispatch(clientCache.selectClient(client));
            history.push("/caseload/facesheet");
        }
    };

    const handleSave = () => {
        let allowToSave = jestTestCall ? true : validateSignaturesPDF(serviceDocument?.signature?.externalSignatures, setErrors);

        if (allowToSave) {
            let parameters = createParameters();
            if (currentPageIndex === modules?.length - 1){
                syncService.documentService.writeExtSigNeededDoc(parameters).then(response => {
                    setTimeout(() => {
                        //Need to add updated document with updated entry of docSignaturePadList
                        addLatestExtSigDocument(serviceDocument, store, dispatch);
                    }, 2000);
                });
            }

            if(!isHeaderVisible){
                onHideDocument(allowToSave);
            }

            redirectPage();
        }
    }

    const renderWizardHeader = () => {
        return isHeaderVisible ? renderStatusIndicator() : null;
    };
    
    const renderWizardFooter = () => {
        return (
            <>
            <div className={styles.wizardFooter}>
                <div className={isHandheld ? styles.navigationButtonsforhandheld : styles.navigationButtonsfortablet}>
                    {currentPageIndex === 1 &&
                    <div className={isHandheld ? styles.PreviousButton : styles.saveButton}>
                        <EvvButton type='secondary' disabled={false} onClick={previousPage}>Previous</EvvButton>
                    </div>
                    }
                    {currentPageIndex === 0 &&
                    <div className={styles.saveButton}>
                        <EvvButton type='primary' disabled={false} onClick={nextPage}>Next</EvvButton>
                    </div>
                    }
                    {currentPageIndex === 1 &&
                    <div className={styles.saveButton}>
                        <EvvButton type='primary' disabled={saveDisable} onClick={handleSave}>Save</EvvButton>
                    </div>
                    }
                </div>
            </div>
            </>
        );
    };

    const renderFormName = () => {
        return wizardName;
    }

    const renderWizardPages = () => {
        const wizardPages = [];

        modules.forEach((module, index) => {
            let props = {
                key: index,
                module: module,
                serviceDocument,
                hide: currentPageIndex !== index,
                renderFormName,
                documentController,
                setSaveDisable,
                errors,
                setErrors
            }

            if (module?.signaturePage) {
                wizardPages.push(<SignaturePageWithPdf {...props} />);
            }  else {
                wizardPages.push(<ServiceDocumentWizardPagePdf {...props} />);
            }
        });

        return wizardPages;
    };

    return (
        <div className={styles.wizard}>
            { renderWizardNavigator() }
            <div className={styles.wizardBody}>
                {isHandheld &&
                <>
                    <div className='serviceDocNamefForHandheld'>{ wizardName }</div>
                    <div className={styles.wizardHeader}>
                        { renderWizardHeader() }
                    </div>
                </>
                }
                <div className={styles.wizardPage}>
                    { renderWizardPages() }
                </div>
                { renderWizardFooter() }
            </div>
        </div>
    );
}