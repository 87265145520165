import {makeStyles} from "@material-ui/core/styles";
import {useSelector} from "react-redux";
import {defaultDateAsString, formatDiagnosis} from "../../../common/utils/formatUtils";
import {clientCache} from "../../../cache/slices/client/clientSlice";
import StackedField from "../../../common/components/StackedField";
import { PRIMARY_COLOR } from "../../../common/constants";

const useStyles = makeStyles(() => ({
    diagnosisPage: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        padding: '17px 16px'
    },
    diagnosisName: {
        fontSize: '18px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: PRIMARY_COLOR
    },
    allergyField: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '18px'
    },
    allergyFieldLabel: {
        fontSize: '16px',
        fontWeight: "bold",
        fontStyle: "normal",
        lineHeight: '20px',
        letterSpacing: 0,
        color: "#4f4f4f"
    },
    diagnosisFieldValue: {
        minHeight: '20px',
        fontSize: '16px',
        fontWeight: "normal",
        fontStyle: "normal",
        letterSpacing: 0,
        color: "#4f4f4f",
        paddingTop: '2px'
    },
}));

export default function DiagnosisPage() {
    const diagnosis = useSelector(clientCache.getSelectedDiagnosis);
    const styles = useStyles();

    return (
        <div data-testid='diagnosisPage' className={styles.diagnosisPage}>
            <div className={styles.diagnosisName} >
                {formatDiagnosis(diagnosis)}
            </div>
            <StackedField label='Priority' paddingTop="17px">
                <div className={styles.diagnosisFieldValue} >
                    {diagnosis.priority}
                </div>
            </StackedField>
            <StackedField label='Effective Date' paddingTop="17px">
                <div className={styles.diagnosisFieldValue} >
                    {defaultDateAsString(diagnosis.effectiveDate)}
                </div>
            </StackedField>
            <StackedField label='DSM-5 Description' paddingTop="17px">
                <div className={styles.diagnosisFieldValue} >
                    {diagnosis.diagnosis}
                </div>
            </StackedField>
        </div>
    );
}
