import {makeStyles} from "@material-ui/core/styles";
import {useHistory, useLocation} from "react-router-dom";
import {useDispatch} from "react-redux";
import {clientCache} from "../../../cache/slices/client/clientSlice";
import {defaultDateAsString, formatDiagnosis} from "../../../common/utils/formatUtils";
import { PRIMARY_COLOR } from "../../../common/constants";

const useStyles = makeStyles(() => ({
    diagnosisCard: {
        width: '100%',
        height: '68px',
        minHeight: '68px',
        backgroundColor: "#ffffff",
        borderBottom: '1px solid #d0d0d0',
        padding: '8px 16px'
    },
    diagnosis: {
        width: '100%',
        height: '100%',
        display: 'grid',
        gridTemplateColumns: '90% 10%',
        justifyContent: 'space-between'
    },
    diagnosisColumn: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
     },
    diagnosisDescription: {
        fontSize: '16px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: PRIMARY_COLOR,
        textOverflow: 'ellipsis',
        overflowX: 'hidden',
        whiteSpace: 'nowrap',
        paddingBottom: '8px'
    },
    priorityRow: {
        display: 'flex',
        alignItems: 'flex-end'
    },
    priorityContainer: {
    },
    priorityLabel: {
        fontSize: '16px',
        fontWeight: "bold",
        fontStyle: "normal",
        lineHeight: '20px',
        letterSpacing: 0,
        color: "#4f4f4f"
    },
    priorityValue: {
        fontSize: '16px',
        fontWeight: "normal",
        fontStyle: "normal",
        letterSpacing: 0,
        color: "#4f4f4f",
        paddingLeft: '6px'
    },
    effectiveDate: {
        fontSize: '16px',
        fontWeight: "normal",
        fontStyle: "normal",
        letterSpacing: 0,
        color: "#4f4f4f",
        paddingLeft: '14px'
    },
    imageColumn: {
        // paddingLeft: '14px',
    },
    imageContainer: {
        width: '40px',
        height: '40px'
    },
    image: {
        height: '100%',
        width: '100%'
    }
}));

export default function DiagnosisCard({diagnosis}) {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const styles = useStyles();

    const handleDiagnosisClick = () => {
        dispatch(clientCache.selectDiagnosis(diagnosis));
        history.push(`${location.pathname}/diagnosis`);
    }

    return (
        <div data-testid='DiagnosisCard' className={styles.diagnosisCard} onClick={handleDiagnosisClick}>
            <div className={styles.diagnosis}>
                <div className={styles.diagnosisColumn}>
                    <div className={styles.diagnosisDescription}>
                        {formatDiagnosis(diagnosis)}
                    </div>
                    <div className={styles.priorityRow}>
                        <div className={styles.priorityContainer}>
                            <span className={styles.priorityLabel}>Priority:</span>
                            <span className={styles.priorityValue}>{diagnosis.priority}</span>
                        </div>
                        <div className={styles.effectiveDate}>{defaultDateAsString(diagnosis.effectiveDate)}</div>
                    </div>
                </div>
                <div className={styles.imageColumn}>
                    <div className={styles.imageContainer}>
                        <img className={styles.image} src='../../images/iconIcoChevronRight.svg' alt='diagnosis details'/>
                    </div>
                </div>
            </div>
        </div>
    )
}
