import {makeStyles} from "@material-ui/core/styles";
import MoreVert from "@mui/icons-material/MoreVert";
import {stringToDateWithTime} from "../../../common/utils/formatUtils";
import ColorStatusCell from "../../../common/components/ColorStatusCell";
import {colorForStatus} from "../../../common/utils/documentUtils";
import { PRIMARY_COLOR } from "../../../common/constants";

const useStyles = makeStyles(() => ({
    clientDocumentCard: {
        width: '100%',
        // minHeight: '68px',
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: "#ffffff",
        borderBottom: '1px solid #d0d0d0',
        paddingTop: '14px',
        paddingBottom: '9px',
        paddingLeft: '16px',
        paddingRight: '16px',
    },
    documentColumn: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'auto',
        overflowY: 'hidden'
     },
    document: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: '8px'
    },
    documentName: {
        textOverflow: 'ellipsis',
        overflowX: 'hidden',
        whiteSpace: 'nowrap',
        fontSize: "16px",
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        textAlign: "center",
        color: PRIMARY_COLOR
    },
    serviceDate: {
        fontSize: '16px',
        fontWeight: "normal",
        fontStyle: "normal",
        color: "#424242"
    },
    imageColumn: {
        display: 'flex',
        alignSelf: 'center',
        alignItems: 'center'
    },
    imageContainer: {
    },
    image: {
        color: '#424242',
        width: '20px',
        height: '30px'
        // height: '100%',
        // width: '100%'
    }
}));

export default function ClientDocumentCard({clientDocument, onKebabClick}) {
    const styles = useStyles();

    const handleKebabClick = () => {
        if (onKebabClick){
            onKebabClick(clientDocument);
        }
    }

    return (
        <div data-testid='ClientDocumentCard' className={styles.clientDocumentCard}>
            <div className={styles.documentColumn}>
                <div className={styles.document}>
                    <div className={styles.documentName}>{clientDocument.description}</div>
                </div>
                <ColorStatusCell status={clientDocument.status} selectColor={colorForStatus} />
            </div>
            <div className={styles.imageColumn}>
                <div className={styles.serviceDate}>
                    <div>{stringToDateWithTime(clientDocument.serviceDate)}</div>
                </div>
                <div className={styles.imageContainer}>
                    <MoreVert data-testid='ClientDocumentCard_kebab_button' className={styles.image} onClick={handleKebabClick}/>
                </div>
            </div>
        </div>
    )
}
