import {configureStore} from '@reduxjs/toolkit'
import thunk from "redux-thunk";
import logger from 'redux-logger';
import reducer from './reducer';

const preloadedState = {};

const store = configureStore({
    reducer,
    middleware: [thunk, logger],
    preloadedState
})

export default store;
