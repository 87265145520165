import {
    APPOINTMENT_START_STOP_DATE_TIME_FORMAT,
    defaultTimeAsString,
    DEFAULT_TIME_FORMAT,
    MILITARY_TIME_FORMAT,
    stringToDate,
    stringToDateWithFormat
} from "./formatUtils";
import {DateTime} from "luxon";

export const isStopTimeAfterStartTime = (startTime, endTime, startDateTimeAsDate, endDateTimeAsDate) => {
    if (!isTimeValid(startTime) || !isTimeValid(endTime)){
        return false;
    }

    const now = DateTime.now();
    const startDate = startDateTimeAsDate ? startDateTimeAsDate : now;
    const endDate = endDateTimeAsDate ? endDateTimeAsDate : now;
    const startDateTime = combineDateAndTime(startDate, startTime).toMillis();
    const endDateTime = combineDateAndTime(endDate, endTime).toMillis();

    return endDateTime <= startDateTime;
};

// export const hasTimeChanged = () => {
//     return startTime !== defaultTimeAsString(startDateTimeAsDate) || endTime !== defaultTimeAsString(endDateTimeAsDate);
// };

export const isTimeValid = (timeText) => {
    const time = DateTime.fromFormat(timeText, DEFAULT_TIME_FORMAT);

    return time.isValid;
};

export const validateTime = (timeText) => {
    const time = DateTime.fromFormat(timeText, DEFAULT_TIME_FORMAT);

    return time.isValid ? time : null;
};

export const getTimeInstanceFromString = (time) => {
    let timeInstance = null;

    if (time) {
        if (time.length === 8){
            timeInstance = DateTime.fromFormat(time, DEFAULT_TIME_FORMAT);
        } else if (time.length === 5){
            timeInstance = DateTime.fromFormat(time, MILITARY_TIME_FORMAT);
        } else {
            timeInstance = DateTime.fromFormat(time, APPOINTMENT_START_STOP_DATE_TIME_FORMAT);
        }
    }

    return timeInstance;
}

export const combineDateAndTime = (initialDate, time) => {
    const timeInstance = stringToDateWithFormat(time, DEFAULT_TIME_FORMAT);

    return timeInstance.isValid ? initialDate.startOf('day').plus({hours: timeInstance.hour, minutes: timeInstance.minute}) : null;
}

export const getHoursTime = (time) => {
    return typeof time === "string" && time.includes("M") ? time : time.toLocaleString('en-US', { hour: '2-digit', minute:'2-digit', hour12: true });
}

export const validateCurrentTime = (time, date) => {
    let incorrectTime = false;
    let currentTime = DateTime.now();
    let currentTimeFormat = getHoursTime(defaultTimeAsString(currentTime));
    let currentDate = (`${currentTime.toLocaleString('en-US', { year: 'numeric', month:'2-digit',day:'2-digit' })}`);
    let currentDateTime = combineDateAndTime(stringToDate(currentDate).startOf('day'), currentTimeFormat);
    const timeFormat = getHoursTime(time);
    const dateTime = combineDateAndTime(stringToDate(date).startOf('day'), timeFormat);
    incorrectTime = dateTime.ts > currentDateTime.ts;
    return incorrectTime;
}

export const validateCurrentDate = (date) => {
    let incorrectDate = false;
    let currentDate = DateTime.now();
    let currentStartStopDate = (`${currentDate.toLocaleString('en-US', { year: 'numeric', month:'2-digit',day:'2-digit' })}`)

    let startStopDate = new Date(date);
    let newDate = new Date(currentStartStopDate);
    incorrectDate = startStopDate.getTime() > newDate.getTime();
    return incorrectDate;
}