import {makeStyles} from "@material-ui/core/styles";
import Accordion from "../../../common/components/Accordion";
import AppointmentCard from "../AppointmentCard";

const useStyles = makeStyles((theme) => ({
    appointmentDay:{
        width: '100%',
        backgroundColor: "#ffffff",
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '16px',
            paddingRight: '16px'
        },
        [theme.breakpoints.up(601)]: {
        }
    }
}));

export default function AppointmentDay({date, appointments, initialExpanded = false, hideIcon = false, call = ''}) {
    const styles = useStyles();

    return (
        <Accordion title={date} initialExpanded={initialExpanded} hideIcon={hideIcon} >
            <div className={styles.appointmentDay}>
                {appointments.map((appointment, index) =>
                    <AppointmentCard key={index} appointment={appointment} call = {call} />
                )}
            </div>
        </Accordion>
    );
}
