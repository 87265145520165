import { Accordion, AccordionDetails, AccordionSummary, Divider, Typography, makeStyles, styled } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import "../ServiceDocName.css";
import { assignAnsKey, assignInitialData, assignInitialTotalScore, assignNotAssessed, assignTotalScore, calculateScoreFn, getAnswerComponent, getImpactConfigData, isCurrentModule, onChangeUpdates, refreshUi, roundToTwoDecimalPlaces } from "../../../common/utils/impactUtils";
import { orderBy } from "lodash";
import { IMPACTTYPE, PRIMARY_COLOR } from "../../../common/constants";
import { getXSAndSMValueForField } from "../common/Util";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { focusOnElement } from "../common/FindFirstErrorFieldUtils";
import {impactValidationWithFocus, validateImpactFields} from "./ValidateImpactFields";
import AlertDialog from "../../../common/components/AlertDialog";
import EvvButton from "../../../common/components/EvvButton";
import { SERVICE_DOCUMENT_STATUS_SIGNED } from "../serviceDocumentUtils";
import { useSelector } from "react-redux";
import { clientCache } from "../../../cache/slices/client/clientSlice";

const useStyles = makeStyles((theme) => ({
    impact: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '17px',
        paddingLeft: '15px',
        paddingRight: '15px'
    },
    hide: {
        display: 'none'
    },
    questionStyle: {
        color: '#4f4f4f',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 'bold',
        lineHeight: '20px',
        letterSpacing: '.3px',
        padding: '10px 0px'
    },
    sectionTitle: {
        fontSize: '18px',
        fontWeight: 'bold',
        fontStyle: 'normal',
        letterSpacing: 0,
        color: PRIMARY_COLOR,
        padding: '10px 0px',
        display: 'flex',
        alignItems: 'center'
    },
    scoringSection: {
        marginTop: '25px',
        fontWeight: 'bold',
        color: '#4f4f4f'
    },
    alignRight: {
        textAlign: 'right',
        padding: '7px'
    },
    alignLeft: {
        textAlign: 'left',
        padding: '7px 7px 7px 20px'
    },
    instructions: {
        margin: '14px -7px 10px -14px',
        borderColor: '#D5D5D5',
        borderWidth: '1px',
        borderRadius: '5px',
        '& .MuiAccordion-root.Mui-expanded:last-child': {
            backgroundColor:"#F5F5F5"
        },
        '& .MuiAccordion-rounded:last-child': {
            backgroundColor: "#F5F5F5",
            marginLeft: "16px",
            marginRight: "8px"
        },
        '& .MuiAccordionSummary-root.Mui-expanded': {
            minHeight: "0px"
        },
        '& .MuiAccordionSummary-root': {
            minHeight:"0px",
            height:"40px"
        },
    },
    arrowIcon: {
        fontSize:"2rem",
        '& .MuiIconButton-root': {
            padding: "0px",
        },
        '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
            transform: 'rotate(90deg)'
        },
        ' & .MuiAccordionSummary-content.Mui-expanded': {
            margin: "0px",
            paddingTop:"0px"
        }
    },
    errorMessage : {
        position: 'relative',
        fontSize: '13px',
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: '16px',
        letterSpacing: 0,
        color: "#dc0707"
    },
}));

export default function Impact (props) {
    const { hide, module, documentController, currentPage } = props;
    let isHandheld = documentController.dataProvider.isHandheld();
    const styles = useStyles();
    const className = styles.impact + ' ' + (hide ? styles.hide : '');
    const id = `impact${module.moduleId}`;

    let getOrientation = window.matchMedia("(orientation: portrait)");
    let orientation = getOrientation.matches ? "portrait" : "landscape";
    let breakPoints = getXSAndSMValueForField(isHandheld, orientation, 12, 6);
    let document = (props?.documentController?.appointmentDocument && props?.documentController?.appointmentDocument.length > 0) ? props?.documentController?.appointmentDocument[0] : props?.documentForWizard;
    let isReadOnly = document?.documentStatus === SERVICE_DOCUMENT_STATUS_SIGNED ? true : false;
    const client = useSelector(clientCache.getSelectedClient);

    const handleSave = () => {
        let moduleData = {
            moduleId: module.moduleId,
            moduleType: IMPACTTYPE,
            fieldData: module?.fieldData,
            measureId: impactConfig?.id,
            notAssessed: module.notAssessed,
            totalScore: module?.totalScore,
            subTotal: module?.subTotal
        };

        if (!documentController.moduleData){
            documentController.moduleData = {};
        }

        if(module?.notAssessed === "Y") {
            moduleData.fieldData = [];
        }

        documentController.moduleData[module.moduleId] = moduleData;
    }

    const [impactConfig, setImpactConfig] = useState();
    // eslint-disable-next-line
    let [refreshCount, setRefreshCount] = useState(0);
    const [alertDialogConfig, setAlertDialogConfig] = useState(null);
    const [openAlertDialog, setOpenAlertDialog] = useState(true);
    const [totalScoreSetup, setTotalScoreSetup] = useState(false);
    let updateNotAssessed = false;
    const [notAssessed, setNotAssessed] = useState(assignNotAssessed(updateNotAssessed, props));
    props = { ...props, isHandheld, orientation, isReadOnly, handleSave, breakPoints, setNotAssessed };

    const TitleText = styled('span')({
        textTransform: 'initial'
    });

    useEffect(() => {
        applyEventHandlers();
        createFormFields();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getImpactConfigData(module, setImpactConfig);
    }, [module]);

    useEffect(() => {
        if (isCurrentModule(currentPage, module) && module?.impactConfig) {
            validateOnLoad();
            setTimeout(() => {
                refreshUi(setRefreshCount);
            }, 1000);
        }
    // eslint-disable-next-line
    }, [module.impactConfig]);

    useEffect(() => {
        assignMeasureId();
        calculateScoreFn(impactConfig, module);
        assignTotalScore(props?.module);
        handleSave();
        if (!totalScoreSetup && impactConfig && module) {
            assignInitialTotalScore(module);
            setTotalScoreSetup(true);
        }
        // eslint-disable-next-line
    }, [impactConfig, refreshCount]);

    useEffect(() => {
        module.notAssessed = notAssessed;
        handleSave();
         // eslint-disable-next-line
    }, [notAssessed]);

    useEffect(() => {
        if (client) {
            module.client = client;
        }
    }, [client, module]);

    const assignMeasureId = () => {
        if (impactConfig) {
            handleSave();
        }
    }

    const validateOnLoad = () => {
        handleSave();
        calculateScoreFn(impactConfig, module);
        if (module?.formValidated === false) {
            impactValidationWithFocus(module, false);
        } else {
            impactValidationWithFocus(module, true);
        }
    }

    const applyEventHandlers = () =>   {
        module.onValidate = handleValidate;
        module.onPageChange = handlePageChange;
    }

    const createFormFields = () => {
        assignInitialData(props);
    }

    const handleValidate = (currentPage, wizardProps) => {
        let validationResult = validateImpactFields(module, false);
        setTimeout(() => {
            focusOnElement(currentPage);
        }, 1000);
        if(currentPage && validationResult?.showFloatingErrorMsg) {
            props?.documentController.showFloatingErrorMessage();
        }
        // Set floating error message using parent callback function(setFloatingErrorMsg)
        props?.setFloatingErrorMsg(validationResult?.showFloatingErrorMsg);
        refreshUi(setRefreshCount);
        return validationResult.isValid;
    }

    const handlePageChange = (sourcePage, targetPage, changeType) => {
        handleSave();
    }

    const handleNotAssesst = () => {
        setAlertDialogConfig({
            dialogTitle: 'Not assessed at this time',
            dialogMessage: `Are you sure you want to continue?`,
            showOkButton: true,
            okButtonText: 'Yes',
            showCancelButton: true,
            cancelButtonText: 'No'
        });
    }
 
    const handleAlertDialogClose = (okClicked) => {
        setAlertDialogConfig(null);
        if (okClicked){
            setNotAssessed("Y");
            updateNotAssessed = true;
            setOpenAlertDialog(false);
            props?.nextPage();
        } else {
            setOpenAlertDialog(true);
        }
    }

    const handleFieldChange = (event, section, question, props) => {
        if (section && question) {
            props?.setNotAssessed("N");
            question.selectedAnswer = event?.target.value;
            assignAnsKey(question, event?.target.value);
            onChangeUpdates(question, props);
            props.module.notAssessed = "N";
            validateImpactFields(props.module, false);
            handleSave();
            refreshUi(setRefreshCount);
        }
    }

    const getImpactModuleName = () => {
        return `${impactConfig?.longName} (${impactConfig?.shortName})`;
    }

    const renderSectionTitle = (sec, sectionUi) => {
        let className = `${ styles.sectionTitle }`;
        if (sec?.title) {
            sectionUi.push(<div dangerouslySetInnerHTML = {{ __html: sec?.title }} className = { className } />);
        }
        return sectionUi;
    }

    const getInstructions = () => {
        return (
            <div className={ styles.instructions } >
                <Accordion className={styles.arrowIcon}>
                    <AccordionSummary
                        expandIcon={<ArrowRightIcon sx={{fontSize: '2.5rem'}} style = {props?.isHandheld ? {height: '30px'}: {}} className={styles.arrowIcon}/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={styles.heading}><b>Instructions</b></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography styles={{display: "none"}}
                            dangerouslySetInnerHTML={{ __html: impactConfig?.instructions.replace(/instructions:/i, '') }}
                        />
                    </AccordionDetails>
                </Accordion>
            </div>
        );
    };

    const renderQueAns = (sec, sectionUi, newLogical) => {
        if (sec?.questions && sec?.questions.length > 0) {
            let sortedQuestions = [];
            if(newLogical === "N" || !newLogical) {
                // Separate scoring and non-scoring questions
                let scoringQuestions = sec?.questions.filter(q => q.nonScoring === "N");
                let nonScoringQuestions = sec?.questions.filter(q => q.nonScoring === "Y");

                scoringQuestions = orderBy(scoringQuestions, question => +question.sequence);
                nonScoringQuestions = orderBy(nonScoringQuestions, question => +question.sequence);

                sortedQuestions = [...scoringQuestions, ...nonScoringQuestions];
            } else {
                sortedQuestions = orderBy(sec?.questions, question => +question.sequence);
            }
            sortedQuestions?.forEach((que) => {
                sectionUi = renderQuestionUi(sec, que, sectionUi);
            });
        }
        return sectionUi;
    }

    const renderQuestionUi = (sec, que, sectionUi) => {
        const questionClassName = `${styles.questionStyle} ${que.impactError && module.notAssessed === 'N' ? 'input-error' : ''} `;
        sectionUi.push(<div style={{ 'padding': '10px 0' }} class = { isHandheld && isReadOnly ? "wrapText" : "" }><label class = { questionClassName }>{ que?.questionText }</label></div>);
        if (que?.hideFlag === true) {
            sectionUi.push(<>Not Required based on previous answer(s)</>);
        } else {
            sectionUi.push(<>{ getAnswerComponent(sec, que, isHandheld, handleFieldChange, setRefreshCount, props) }</>);
        }
        if(que?.impactError) {
            sectionUi.push(<div className={styles.errorMessage}>{que?.errorMessage}</div>);
        }
        return sectionUi;
    }

    const getScoringRows = (subTotals) => {
        let scoringRowsUi = [];
        let total = roundToTwoDecimalPlaces(module?.totalScore);
        let sortedSubTotals = orderBy(subTotals, subTotal => +subTotal.sequence);
        sortedSubTotals?.forEach((st) => {
            scoringRowsUi.push(
                <tbody><tr>
                    <td class = { styles.alignRight} >{ st?.text }: </td>
                    <td class = { styles.alignLeft }> { st?.calculatedScore ? st?.calculatedScore : 0 } </td>
                </tr></tbody>
            );
        });

        if (impactConfig?.expression) {
            scoringRowsUi.push(
                <tbody>
                    <tr>
                        <td class={styles.alignRight}>Total:</td>
                        <td class={styles.alignLeft}>{total}</td>
                    </tr>
                </tbody>
            );
        }
    
        return scoringRowsUi;
    }

    const getScoringSection = () => {
        let scoringUi = [];

        let subTotals = impactConfig?.subTotals;
        if ((subTotals && subTotals.length > 0) || impactConfig?.expression) {
            scoringUi.push(<div class = { styles.scoringSection } ><table>{ getScoringRows(subTotals) }</table></div>);
        }

        return scoringUi;
    }

    const getSections = () => {
        let sectionUi = [];

        if (impactConfig) {
            let sections = impactConfig?.sections;
            let newLogical = impactConfig?.newLogical;
            let sortedSections = orderBy(sections, section => +section.sequence);
            if (sortedSections && sortedSections?.length > 0) {
                sortedSections?.forEach((sec) => {
                    if (sec?.hideFlag !== true) {
                        sectionUi = renderSectionTitle(sec, sectionUi);
                        sectionUi = renderQueAns(sec, sectionUi, newLogical);
                    }
                });
            }
        }

        return sectionUi;
    }

    return (
        <div id = { id } data-testid = 'impact' className = { className }>
            { impactConfig && <div className = "serviceDocumentName">
                { <><div className = "serviceDocName" data-testid = 'serviceDocName'>{ getImpactModuleName() }</div><Divider /></>}
                { <><div style = {isHandheld ? {display: 'flex',justifyContent: 'center'}: {}}>
                        <EvvButton type='secondary' disabled={isReadOnly} styleOverride={isHandheld ? {width: '80%', fontSize: "19px", marginTop: '2%'} : {width: "50%", fontSize: "19px", marginTop: '2%'}} onClick={handleNotAssesst}>Not assessed at this time</EvvButton>
                    </div>
                    { alertDialogConfig &&
                        <AlertDialog
                            open={openAlertDialog}
                            dialogTitle={<TitleText>{alertDialogConfig.dialogTitle}</TitleText>}
                            dialogMessage={alertDialogConfig.dialogMessage}
                            showOkButton={alertDialogConfig.showOkButton}
                            showCancelButton={alertDialogConfig.showCancelButton}
                            okButtonText={alertDialogConfig.okButtonText || 'Ok'}
                            cancelButtonText={alertDialogConfig.cancelButtonText || 'Cancel'}
                            handleClose={handleAlertDialogClose}
                        />
                    }
                </>}
                {<><div style={{backgroundColor:"#FCFCFC"}}>{impactConfig?.instructions ? getInstructions() : ""}</div></>}
                { getSections() }
                { getScoringSection() }
            </div> }
        </div>
    );
}