import { evvRepository } from "./evv";
import { SERVICE_DOCUMENT_STATUS_SIGNED } from "../features/forms/serviceDocumentUtils";
import { SYNC_STATE_DIRTY } from "../common/constants";

const getDocumentsSignedAndDirty = () => {
    return evvRepository.evvDb.document
                        .where({syncState: SYNC_STATE_DIRTY, documentStatus: SERVICE_DOCUMENT_STATUS_SIGNED})
                        .toArray();
}

const findDocumentById = (id) => {
    return evvRepository.evvDb.document
                        .where({id: id}).first().then(doc => doc);
}

const documentRepository = {
    getDocumentsSignedAndDirty,
    findDocumentById
};

export default documentRepository;