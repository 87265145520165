import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import SplashPage from "../SplashPage";

export default function LoginPagePingOne() {
  const auth = useAuth();
  const [isOffline, setIsOffline] = useState(!window.navigator.onLine);
  const history = useHistory();
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const DELAY_TIME = 5000;

  useEffect(() => {
    const handleOffline = () => setIsOffline(true);
    const handleOnline = () => setIsOffline(false);

    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);

    const signInUser = async () => {
      if (!isOffline) {
        if (!auth.isAuthenticated && !auth.activeNavigator) {
          try {
            await delay(DELAY_TIME);
            await auth.signinRedirect();
          } catch (error) {
            console.error('Error: Sign in failed: ' + error);
          }
        } else {
          await delay(DELAY_TIME);
          history.replace("/oidc/callback")
        }
      } else {
        history.push(`/loginOffline`);
      }
    };

    signInUser();

    return () => {
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOffline]);

  useEffect(() => {
    if (isOffline) {
      history.push(`/loginOffline`);
    }
  }, [isOffline, history]);

  return <SplashPage />;
}