import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector, useStore} from "react-redux";
import Appointments from "../../../common/components/Appointments";
import {appCache} from "../../../cache/slices/app/appSlice";
import Tasks from "../../../common/components/Tasks";
import DashboardCalendar from "../DashboardCalendar";
import ClientStrip from "../ClientStrip";
import {scheduleCache} from "../../../cache/slices/schedule/scheduleCache";
import {useEffect, useState} from "react";
import clientService from "../../../common/services/clientService";
import RecentClient from "../RecentClient";
import {clientCache} from "../../../cache/slices/client/clientSlice";
import {DateTime} from "luxon";
import CircularProgress from "@material-ui/core/CircularProgress";
import syncService from "../../../common/services/syncService";
import { prepareTasks } from "../../../common/data/tasks";
import { getTaskData } from "../../forms/common/IncompleteTasksUtils";
import { getSystemInformation } from "../../../common/utils/systemUtils";
import {evvRepository} from "../../../db/evv";
import { documentCache } from "../../../cache/slices/document/documentCache";
import { PRIMARY_COLOR } from "../../../common/constants"
import { ASYNC_OPERATION_STATUS } from '../../../cache/asyncHandler';
import { getMobileAppointments } from "../../../common/utils/appointmentUtils";

const useStyles = makeStyles((theme) => ({
    dashboardPageTablet: {
        width: '100%',
        height: '100%',
        // backgroundColor: "#ffffff",
        overflow: 'auto',
        backgroundColor: '#f2f2f2'
    },
    dashboard: {
        width: '100%',
        height: '100%',
        display: 'flex'
    },
    loadingSpinnerContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    loadingSpinner: {
        color: PRIMARY_COLOR
    },
    calendarClientColumn: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    recentClients: {
        ...theme.shadowedContainer,
        paddingLeft: '23px',
        paddingRight: '17px',
        overflow:'unset'
    },
    appointmentsColumn: {
        ...theme.shadowedContainer,
        width: '100%',
        paddingLeft: '22px',
        paddingRight: '7px'
    },
    tasksColumn: {
        ...theme.shadowedContainer,
        width: '100%',
        paddingLeft: '20px',
        paddingRight: '7px'
    },
}));

export default function DashboardPageTablet() {
    const selectedRecentClient = useSelector(clientCache.getSelectedRecentClient);
    const selectedDateForSchedule = useSelector(scheduleCache.getSelectedDate);
    const maxDate = DateTime.now().plus({days: 30});
    const recentClientsLoading = useSelector(appCache.recentClientsLoading);
    const recentClients = useSelector(appCache.getRecentClients);
    const clientsLoading = useSelector(appCache.clientsLoading);
    const clients = useSelector(appCache.getClients);
    const appointments = useSelector(syncService.appointmentService.getResults());
    const appointmentsLoading = useSelector(syncService.appointmentService.isLoading());
    const tasks = useSelector(appCache.getTasks);
    const store = useStore();
    const user = useSelector(appCache.getUser);
    const dispatch = useDispatch();
    const styles = useStyles();
    const [firstRender, setFirstRender] = useState(true);
    const documentCrosswalks = useSelector(syncService.documentCrosswalkService.getResults());
    const serviceDocuments = useSelector(syncService.serviceDocumentService.getResults());
    const [taskDataArr, setTaskDataArr] = useState([]);
    const currentDocumentId = useSelector(documentCache.getCurrentDocumentId);
    const organizationServiceStatus = useSelector(syncService.organizationService.getStatus());
    const activityServiceStatus = useSelector(syncService.activityService.getStatus());
    const activities = useSelector(syncService.activityService.getResults());
    
    useEffect(() => {
        const systemInformation = getSystemInformation();
        if (!appointmentsLoading && organizationServiceStatus === ASYNC_OPERATION_STATUS.SUCCESS &&  (systemInformation.platformType === 'Tablet' || systemInformation.platformType === 'Desktop') && evvRepository.isWritenProcess === 'false') {
            syncService.syncAppointmentData({store});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationServiceStatus]);

    useEffect(() => {
        if(firstRender && clients && clients.length > 0) {
            if(clients.length > 1) {
                syncService.auditService.saveAuditEvent(clients, null, user, "EVV Dashboard", "View");
            } else {
                syncService.auditService.saveAuditEvent(null, clients[0] , user, "EVV Dashboard", "View");
            }
            setFirstRender(false);
        }
    },[firstRender, clients, user]);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if ( organizationServiceStatus === ASYNC_OPERATION_STATUS.SUCCESS && activityServiceStatus === ASYNC_OPERATION_STATUS.SUCCESS && (appointments.length > 0 || currentDocumentId) ) {
            getTaskData(appointments, documentCrosswalks, serviceDocuments, setTaskDataArr, user.staff, activities, dispatch);
        }
    }, [appointments, currentDocumentId, organizationServiceStatus, activityServiceStatus, activities]);

    useEffect(() => {
        clientService.loadRecentClients(dispatch, clients);
    }, [clients]);

    useEffect(() => {
        loadIncompleteTasks();
    }, [taskDataArr]);

    const loadIncompleteTasks = () => {
        const userToTasksMap = {};
        userToTasksMap[user?.userName] = prepareTasks(taskDataArr);
        dispatch(appCache.setTasks(userToTasksMap[user?.userName]));
    }

    const handleClick = (client) => {
        if (client) {
            dispatch(clientCache.selectRecentClient(client));
        }

        return false;
    }

    const renderRecentClients = () => {
        if (recentClientsLoading || clientsLoading) {
            return (
                <div className={`${styles.recentClients} ${styles.loadingSpinnerContainer}`}>
                    <CircularProgress size={50} className={styles.loadingSpinner} />
                </div>
            );
        } else {
            let recentClient = selectedRecentClient;
            if (!recentClient){
                if (recentClients && recentClients[0]) {
                    recentClient = recentClients[0];
                }
            }

            return (
                <div className={styles.recentClients}>
                    <ClientStrip
                        title='Recent Clients'
                        clients={recentClients}
                        showFullName={true}
                        clientSelectable={true}
                        selectedClient={selectedRecentClient}
                        handleClick={handleClick}
                    />
                    {recentClient &&
                        <RecentClient client={recentClient} />
                    }
                </div>
            );
        }
    }

    return (
        <div className={styles.dashboardPageTablet}>
            <div className={styles.dashboard}>
                <div className={styles.calendarClientColumn} >
                    <DashboardCalendar />
                    {renderRecentClients()}
                </div>
                <div className={styles.appointmentsColumn}>
                    <Appointments appointments={getMobileAppointments(appointments, activities)} appointmentsLoading={appointmentsLoading} startDate={selectedDateForSchedule} maxDate={maxDate} maxDaysToDisplay={7} expandAll={true} includeEmpty={true} hideAccordianIcon={true} selectedClient={null}/>
                </div>
                <div className={styles.tasksColumn}>
                    <Tasks tasks={tasks} isFacesheet={false}/>
                </div>
            </div>
        </div>
    )
}
