import {makeStyles} from "@material-ui/core/styles";
import {DateTime} from 'luxon';
import {boundaries, dateToStringWithFormat, weekViewDateFormat} from "../../../common/utils/formatUtils";
import {useDispatch, useSelector, useStore} from "react-redux";
import {scheduleCache } from "../../../cache/slices/schedule/scheduleCache";
import {IconButton} from "@material-ui/core";
import EvvSelect from "../../../common/components/EvvSelect";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import {useRef, useState, useLayoutEffect, useEffect} from "react";
import AppointmentDialog from "../../facesheet/AppointmentDialog";
import {createNewAppointment} from "../../../common/utils/appointmentUtils";
import DayScheduler from "../DayScheduler";
import syncService from "../../../common/services/syncService";
import { Views, luxonLocalizer } from "react-big-calendar";
import { DAY, PRIMARY_COLOR, WEEK } from "../../../common/constants";
import clientService from "../../../common/services/clientService";
import { appCache } from "../../../cache/slices/app/appSlice";

const scheduleTitleBar = {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: "#ffffff",
    borderBottom: '4px solid #bdbdbd',
    padding: '16px 12px 18px 12px',
};

const useStyles = makeStyles(() => ({
    scheduleTitleBarForDay: {
        ...scheduleTitleBar
    },
    calendarGroup: {
        display: 'flex',
        alignItems: 'center'
    },
    viewByColumn: {
        paddingRight: '20px'
    },
    viewBySelect: {
        width: '151px',
        height: '43px',
        borderRadius: '2px',
        background: "transparent",
        borderStyle: "solid",
        borderWidth: '1px',
        borderColor: "#b6b6b6"
    },
    navigationColumn: {
        display: 'flex',
        alignItems: 'center'
    },
    imageColumn: {
        alignSelf: 'center'
    },
    imageContainer: {
        width: '32px',
        height: '32px',
        padding: '0px'
    },
    image: {
        height: '100%',
        width: '100%'
    },
    scheduleTitle: {
        fontSize: '18px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        textAlign: "center",
        whiteSpace: 'nowrap',
        color: PRIMARY_COLOR
    },
    addButton: {
        display: 'flex',
        alignItems: 'center'
    },
    iconButton: {
        padding: '0px',
        width: '32x',
        height: '32px'
    },
    iconButtonLabel: {
        width: '100%',
        height: '100%'
    },
    iconButtonLabelDisabled: {
        width: '100%',
        height: '100%',
        opacity: .4
    },
    addLabel: {
        fontSize: '16px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        textAlign: "center",
        color: PRIMARY_COLOR,
        paddingLeft: '4px'
    },

    scheduleTitleBarForWeek: {
        ...scheduleTitleBar,
    },

    scheduler: {
        width: '100%',
        overflow: 'auto'
    },
}));

const VIEW_BY = [
    'Day',
    'Week'
];

const localizer = luxonLocalizer(DateTime)

export default function TabletScheduleTitleBar({selectedDate, showViewBy=true, showAddBtn=true, viewBy='Day'}) {
    const [showAppointmentDialog, setShowAppointmentDialog] = useState(false);
    const [currentAppointment, setCurrentAppointment] = useState(null);
    const boundaryDates = useRef(null);
    const dispatch = useDispatch();
    const styles = useStyles();
    const [schedule, setSchedule] = useState('Day');
    const selectedWeekDate = useSelector(scheduleCache.getSelectedWeekDate);
    const [currentDate, setCurrentDate] = useState(selectedWeekDate);
    const appointments = useSelector(syncService.appointmentService.getResults());
    const store = useStore();
    const [clientData, setClientData] = useState([]);
    useEffect(() => {
        dispatch(scheduleCache.setCalendarView(schedule));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

    const changeSelectedDate = (newSelectedDate) => {
        if (!isOutOfBounds(newSelectedDate)){
            const now = DateTime.now();
            if(schedule === DAY) {
                dispatch(scheduleCache.setSelectedDate(newSelectedDate.startOf('day').plus({hours: now.hour, minutes: now.minute})));
            } else {
                dispatch(scheduleCache.setSelectedWeekDate(newSelectedDate.startOf('day').plus({hours: now.hour, minutes: now.minute})));
            }
        }
    }

    const isOutOfBounds = (dayOfMonth) => {
        if (!boundaryDates.current){
            boundaryDates.current = boundaries();
        }

        return boundaryDates.current.isDateTimeOutOfBounds(dayOfMonth);
    }

    const handlePrevious = () => {
        changeSelectedDate(selectedDate.minus({days: 1}));
    }

    const handleNext = () => {
        changeSelectedDate(selectedDate.plus({days: 1}));
    }

    const goToPreviousWeek = () => {
        const previousWeek = currentDate.minus({week: 1})
        setCurrentDate(previousWeek);
        changeSelectedDate(previousWeek);
    };

    const goToNextWeek = () => {
        const nextWeeks = currentDate.plus({week: 1});
        setCurrentDate(nextWeeks);
        changeSelectedDate(nextWeeks);
    };

    const handleAddRequest = () => {
        clientService.fetchAddresses({store}, appCache.getClients(store.getState()), setClientData);
        setCurrentAppointment(createNewAppointment(selectedDate, ''));
        setShowAppointmentDialog(true);
    }

    const handleAppointmentDialogClose = (appointment) => {
        setShowAppointmentDialog(false);
    }

    useLayoutEffect(() => {
        const daySlot = document.querySelector('.rbc-day-slot');
        const timeslots = daySlot.querySelectorAll('.rbc-timeslot-group');

        const hourSlot = timeslots[selectedDate.hour];
        hourSlot.scrollIntoView();
    
    }, [selectedDate], schedule);

    const handleChange = (newView) => {
        dispatch(scheduleCache.setCalendarView(newView));
        setSchedule(newView); 
    };
  
    const previousDisabled = isOutOfBounds(selectedDate.minus({days: 1}));
    const nextDisabled = isOutOfBounds(selectedDate.plus({days: 1}));

    const previousDisabledForWeek = isOutOfBounds(selectedWeekDate?.minus({days: 7}));
    const nextDisabledForWeek = isOutOfBounds(selectedWeekDate?.plus({days: 7}));

    return (
        <><div id='scheduleTitleBar' className={schedule === DAY ? styles.scheduleTitleBarForDay : styles.scheduleTitleBarForWeek}>
            <div className={styles.calendarGroup} >
                {showViewBy &&
                <div className={styles.viewByColumn}>
                    <EvvSelect id='selectedViewBy' value={schedule} className={styles.viewBySelect} onChange={(e) => handleChange(e.target.value)}>
                        {VIEW_BY.map((item, index) =>
                            <option key={`viewBy_${index}`} value={item}>{item}</option>)
                        }
                    </EvvSelect>
                </div>
                }
                <div className={styles.navigationColumn}>
                    <div className={styles.imageColumn}>
                        <IconButton
                            id='TableScheduleTitleBar_previousButton'
                            type="button"
                            classes={{label: (schedule === DAY ? previousDisabled : previousDisabledForWeek) ? styles.iconButtonLabelDisabled : styles.iconButtonLabel}}
                            className={styles.imageContainer}
                            aria-label="previous"
                            disabled={schedule === DAY ? previousDisabled : previousDisabledForWeek}
                            onClick={schedule === DAY ? handlePrevious : goToPreviousWeek}
                        >
                            <ChevronLeft className={styles.image} htmlColor="#3b276a" alt='previous'/>
                        </IconButton>
                    </div>
                    <div id='scheduleTitle' className={styles.scheduleTitle}>
                        {schedule === DAY ? dateToStringWithFormat(selectedDate, 'EEEE, MMMM d, yyyy') : weekViewDateFormat(currentDate)}
                    </div>
                    <div className={styles.imageColumn}>
                        <IconButton
                            id='TableScheduleTitleBar_nextButton'
                            type="button"
                            classes={{label: (schedule === DAY ? nextDisabled : nextDisabledForWeek) ? styles.iconButtonLabelDisabled : styles.iconButtonLabel}}
                            className={styles.imageContainer}
                            aria-label="next"
                            disabled={schedule === DAY ? nextDisabled : nextDisabledForWeek}
                            onClick={schedule === DAY ? handleNext : goToNextWeek}
                        >
                            <ChevronRight className={styles.image} htmlColor="#3b276a" alt='next'/>
                        </IconButton>
                    </div>
                </div>
            </div>
            {showAddBtn &&
            <div className={styles.addButton}>
                <div>
                    <IconButton data-testid='addAppointmentButton' type="button" classes={{label: styles.iconButtonLabel}}
                                className={styles.iconButton}
                                onClick={handleAddRequest}>
                        <img className={styles.iconButtonLabel} src='../images/iconAddAppointment.svg' alt="add appointment"/>
                    </IconButton>
                </div>
                <div className={styles.addLabel}>New Appointment</div>
            </div>
            }
            {showAppointmentDialog &&
            <AppointmentDialog
                onClose={handleAppointmentDialogClose}
                appointment={currentAppointment}
                showStatus={false}
                clientData={clientData}
            />
            }
        </div>
        <div className={styles.scheduler}>
            <DayScheduler
                    localizer={localizer}
                    events={appointments}
                    startAccessor="start"
                    endAccessor="end"
                        props = { schedule === WEEK ?
                            {
                                scrollToTime: new Date(currentDate),
                                view: [Views.WEEK],
                                defaultDate: new Date(),
                                className: 'week',
                                defaultView: 'week'
                            } : {}
                        }
            />
        </div>
        </>
    )
}
