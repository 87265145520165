import {makeStyles} from "@material-ui/core/styles";
import {useState} from "react";

const useStyles = makeStyles((theme) => ({
    filterBar: {
        display: 'flex',
        paddingTop: '20px',
        paddingBottom: '16px',
        marginLeft: '11px',
        marginRight: '20px',
        alignItems: 'center'
    },
    filterLabel: {
        fontSize: '14px',
        fontWeight: "bold",
        fontStyle: "normal",
        // lineHeight: 14,
        // letterSpacing: -0.06,
        color: "#424242",
        paddingRight: '13px'
    },
    filterSelect: {
        width: '164px',
        height: '27px',
        borderRadius: '2px',
        backgroundColor: "#ededed",
        borderStyle: "solid",
        borderWidth: '1px',
        borderColor: "#b6b6b6"
    }
}));

export default function FilterBar({filterByLabel, options = [], valueForOption, labelForOption, onFilterChange}) {
    const [filter, setFilter] = useState('');
    const styles = useStyles();

    const handleFilterChange = (event) => {
        const option = options.find((option) => (labelForOption ? labelForOption(option) : option) === event.target.value);

        setFilter(option);

        if (onFilterChange){
            onFilterChange(option);
        }
    };

    return (
        <div className={styles.filterBar}>
            <div className={styles.filterLabel}>{filterByLabel}</div>
            <select data-testid='FilterBar_select' className={styles.filterSelect} value={filter} onChange={handleFilterChange} >
                {options && options.map((item, index) => <option key={`option_${index}`} data-testid={`FilterBar_option_${index}`} value={valueForOption ? valueForOption(item) : item}>{labelForOption ? labelForOption(item) : item}</option>)}
            </select>
        </div>
    );
}
