import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {formatOrganization} from "../../utils/formatUtils";
import EvvRadio from "../EvvRadio";
import EvvSelect from "../EvvSelect";
import { orderBy } from "lodash";

export default function Organizations({organizations, currentOrganization, handleOrganizationChange, call}) {
    organizations = orderBy(organizations,['name'],['asc']);
    const handleOrganizationRadioGroupChange = (e) => {
        if (handleOrganizationChange){
            // eslint-disable-next-line eqeqeq
            handleOrganizationChange(organizations.find(org => org.organizationId == e.target.value));
        }
    }

    return organizations && organizations.length > 0 ? (
        <>{!call &&<FormControl component="fieldset">
            <RadioGroup aria-label="organizations" name="organizations" value={currentOrganization ? currentOrganization.organizationId : ''} onChange={handleOrganizationRadioGroupChange}>
                    {organizations.map((org, index) =>
                        <FormControlLabel key={`${org.organizationId}_${index}`} value={org.organizationId} control={<EvvRadio />} label={formatOrganization(org)}/>
                    )}
            </RadioGroup>
        </FormControl>}
        {call && <EvvSelect id='selectedOrganization' value={currentOrganization ? currentOrganization.organizationId : ''} onChange={handleOrganizationRadioGroupChange}>
            {organizations.map((org, index) => <option key={`organization ${index}`} value={org.organizationId}>{org.name}</option>)}
        </EvvSelect>}</>
    ) : null;
}