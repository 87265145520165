import FormField from "./FormField";
import { Grid } from "@material-ui/core";
import DisplayErrorMessages from "../common/DisplayErrorMessages";
import TextAreaComponent from "./TextAreaComponent";
import { getXSAndSMValueForField } from "../common/Util";

class TextArea extends FormField{
    constructor(props) {
        super(props);

        let description = "";
        let datatype = "";
        let minlength = "";
        let maxlength = "";
        let rowslength = "";
        let colslength = "";
        let isRequired = this.isMandatory();
        props.fieldDescriptor = this.fieldDescriptor;

        let descObj = this.fieldDescriptor.attributes.find( (attribute) => attribute.attributeId === this.fieldDescriptor.id +"_description" );
        description = descObj?.value;

        let datatypeobj = this.fieldDescriptor.attributes.find( (attribute) => attribute.attributeId === this.fieldDescriptor.id +"_datatype" );
        datatype = datatypeobj?.value;
       
        let minlengthobj = this.fieldDescriptor.attributes.find( (attribute) => attribute.attributeId === this.fieldDescriptor.id +"_minlength" );
        minlength = minlengthobj?.value;

        let maxlengthobj = this.fieldDescriptor.attributes.find( (attribute) => attribute.attributeId === this.fieldDescriptor.id +"_maxlength" );
        maxlength = maxlengthobj?.value;

        let rowslengthobj = this.fieldDescriptor.attributes.find( (attribute) => attribute.attributeId === this.fieldDescriptor.id +"_rows" );
        rowslength = rowslengthobj?.value;

        let colslengthobj = this.fieldDescriptor.attributes.find( (attribute) => attribute.attributeId === this.fieldDescriptor.id +"_cols" );
        colslength = colslengthobj?.value;

        this.state = {
            description: description,
            data: props,
            isRequired: isRequired,
            datatype: datatype,
            minlength: minlength,
            maxlength: maxlength,
            rowslength:rowslength,
            colslength:colslength,
        };
    }

    getValue = () => {
        if(!this.fieldDescriptor.value && this.fieldDescriptor?.dbSavedValue) {
            this.fieldDescriptor.value = this.fieldDescriptor?.dbSavedValue;
        }
        return this.fieldDescriptor.value;
    }

    renderField(props, state){
        let breakPoints = getXSAndSMValueForField(props.isHandheld, props.orientation, 12, 6);
        props.fieldDescriptor = this.fieldDescriptor;

        return (
                <Grid container xs={breakPoints.xsValue} sm={breakPoints.smValue} >
                    { this.fieldDescriptor?.businessRuleApplied !== "Hide" &&
                    <>
                        <span>{this.state.description}</span>
                        <TextAreaComponent
                            {...props}
                            id={'textArea-' + this.fieldDescriptor.id}
                            name={'textArea-' + this.fieldDescriptor.id}
                            value={ this.getValue() }
                            type={this.state.datatype}
                            required={this.state.isRequired}
                            onChange={(event) => { props.onChange(event, this.fieldDescriptor, props); } }
                            minCharacters={this.state.minlength}
                            maxCharacters={this.state.maxlength}
                            rows={this.state.rowslength}
                            cols={this.state.colslength} 
                            disabled = { state.isReadOnlyDoc }
                        />
                        <span style={{width: '100%'}}>{`Max:${this.state.maxlength} characters.`}</span>
                        <div>
                            <DisplayErrorMessages
                                {...props} 
                            />
                        </div>
                    </>
                    }
                </Grid>
        )
    }
}

export default TextArea;
