import * as constData from "../../../common/constants";
import { getFieldValueToCompare } from '../common/BusinessRuleUtils';
import * as validateFn from './ValidateFieldUtils';
import { getOptions } from './ConfigurableFormsUtils';

const idToValueFields = ['ReferralSourceDropDown', 'ProgramDropDown', 'StaffDropDown'];

export const setRequireErrorMsg = (field, actionValue) => {
    let actionValueMsg = actionValue;

    if (idToValueFields.includes(field.type)) {
        actionValueMsg = getOptions(field, actionValue);
    }

    field.businessRuleError = `${field.label} must equal ${actionValueMsg}`;
    field['hasBRError'] = true;
}

export const setMandatoryErrorMsg = (fieldToCheck) => {
    if (constData.SELECTION_FORM_ITEMS.includes(fieldToCheck.type)) {
        fieldToCheck.businessRuleError = `Please make a selection for ${fieldToCheck.label}, then submit`;
    } else {
        fieldToCheck.businessRuleError = `Please enter a value for ${fieldToCheck.label}, then submit`;
    }

    fieldToCheck['hasBRError'] = true;
}

export const setDNAEmptyErrorMsg = (fieldToCheck) => {
    fieldToCheck.businessRuleError = `${fieldToCheck.label} must not be blank`;
    fieldToCheck['hasBRError'] = true;
}

export const setDNAErrorMsg = (fieldToCheck, actionValue) => {
    let actionValueMsg = actionValue;

    if (idToValueFields.includes(fieldToCheck.type)) {
        actionValueMsg = getOptions(fieldToCheck, actionValue);
    }

    fieldToCheck.businessRuleError = `${fieldToCheck.label} must not equal ${actionValueMsg}`;
    fieldToCheck['hasBRError'] = true;
}

export const getFieldDataType = (field) => {
    let datatype = "";
    let datatypehobj = field.attributes.find( (attribute) => attribute.attributeId === field.id +"_datatype" );
    datatype = datatypehobj?.value;
    return datatype;
}

export const isDataTypeAligned = (datatype, actionValue) => {
    let isAligned = false;
    switch(datatype) {
        case 'AL':
            isAligned = constData.ALPHABETS_REGEX.test(actionValue);
        break;

        case 'NUM':
            isAligned = constData.NUMBER_REGEX.test(actionValue);
        break;

        case 'AL+SP':
            isAligned = constData.SPECIAL_CHARS_REGEX.test(actionValue);
        break;

        default:
            isAligned = true;
    }
    return isAligned;
}

export const byPassAction = (field, actionValue, action) => {
    let shouldByPass = false;
    let fieldType = field.type;

    switch(fieldType) {
        //If the Require action is set on a dropdown type field that has 0 values to select, bypass it
        case 'DropDown':
        case 'Descriptor DropDown':
        case 'ProgramDropDown':
        case 'StaffDropDown':
        case 'ActiveMedicationDropDown':
        case "ReferralSourceDropDown":
            if (field.options && field.options.length === 0) {
                shouldByPass = true;
            }
        break;

        //If the Require action is set to require a specific value on a Text Field or Text Area, but this required value differs from the datatype configured for the field, then also bypass it
        case 'Text Field':
        case 'Text Area':
            if (action === 'Require') {
                if (actionValue !== "") {
                    let datatype = getFieldDataType(field);
                    shouldByPass = !isDataTypeAligned(datatype, actionValue);
                }
            }
        break;

        //If we find a Require action rule on any of these 3 field types, then it is bypassed
        case 'ReadOnlyText':
        case 'FormBreak':
        case 'LookUp':
        case 'ActivityCounter':
            shouldByPass = true;
        break;

        case 'Date':
        case 'Time':
        case 'DateTime':
            if (action === 'Do Not Allow') {
                shouldByPass = true;
            }
        break;

        default:
    }
    return shouldByPass;
}

export const requireErrorCheck = (fieldToCheck, actionValue, fieldValueToCompare) => {
    if (!fieldValueToCompare.includes(actionValue)) {
        setRequireErrorMsg(fieldToCheck, actionValue);
    }
}

export const requireErrorCheckPhone = (fieldToCheck, actionValue, fieldValueToCompare) => {
    if (fieldValueToCompare[0] !== actionValue) {
        setRequireErrorMsg(fieldToCheck, actionValue);
    }
}

export const setRequireActionMultiple = (fieldToCheck, actionValue, fieldValueToCompare) => {
    let fieldType = fieldToCheck.type;

    switch(fieldType) {
        case 'Address':
            if (fieldValueToCompare[0].trim() === '') {
                setMandatoryErrorMsg(fieldToCheck);
            } else if (actionValue !== "") {
                requireErrorCheck(fieldToCheck, actionValue, fieldValueToCompare);
            }
        break;

        case 'Phone':
            if ( (fieldValueToCompare[0].trim() === '' || fieldValueToCompare[1].trim() === '') ) {
                setMandatoryErrorMsg(fieldToCheck);
            } else if ( validateFn.validatePhone(fieldValueToCompare, fieldToCheck) && actionValue !== "" ) {
                requireErrorCheckPhone(fieldToCheck, actionValue, fieldValueToCompare);
            }
        break;

        default:
            fieldValueToCompare = fieldValueToCompare.filter(value => value !== '');
            if (fieldValueToCompare.length === 0) {
                setMandatoryErrorMsg(fieldToCheck);
            } else if (actionValue !== "" && !skipActionCheck(fieldToCheck)) {
                requireErrorCheck(fieldToCheck, actionValue, fieldValueToCompare);
            }
      }
}

export const skipActionCheck = (fieldToCheck) => {
    let { type } = fieldToCheck;
    let skipActionCheck = false;
    let skipActionCheckArr = ['Date', 'Time', 'DateTime'];

    if (skipActionCheckArr.includes(type)) {
        skipActionCheck = true;
    }
    
    return skipActionCheck;
}

export const requireCheckForMultiple = (fieldToCheck, actionValue, fieldValueToCompare) => {
    if (fieldValueToCompare.length > 0) {
        setRequireActionMultiple(fieldToCheck, actionValue, fieldValueToCompare);
    } else {
        setMandatoryErrorMsg(fieldToCheck);
    }
}

export const requireCheckForSingle = (fieldValueToCompare, fieldToCheck, actionValue) => {
    if (fieldValueToCompare === '') {
        setMandatoryErrorMsg(fieldToCheck);
    } else if (actionValue !== "" && !skipActionCheck(fieldToCheck)) {
        if (actionValue !== fieldValueToCompare) {
            setRequireErrorMsg(fieldToCheck, actionValue);
        }
    }
}

export const fieldWithSeparatorInBetween = (fieldVal, separator) => {
    let tempArr = [];
    fieldVal.forEach(val => {
        let hasHyphen = val.includes(separator) ? true : false;
        if (hasHyphen) {
            let splitArr = val.split(separator);
            if (splitArr.length > 0) {
                splitArr.forEach( (str) => {
                    tempArr.push(str);
                });
            }
        } else {
            tempArr.push(val);
        }
    });
    return tempArr;
}

export const checkFieldType = (field, fieldValueToCompare) => {
    let value = fieldValueToCompare;
    let type = field?.type;
    switch(type) {
        case "Phone":
            value = fieldWithSeparatorInBetween(fieldValueToCompare, "-");
        break;

        default:
    }
    return value;
}

export const DNACheckForMultiple = (fieldToCheck, actionValue, fieldValueToCompare) => {
    let hasEmptyValue = ( fieldValueToCompare.length === 0 || fieldValueToCompare.includes('') ) ? true : false;
    if (actionValue === '' && hasEmptyValue) {
        setDNAEmptyErrorMsg(fieldToCheck);
    } else {
        fieldValueToCompare = checkFieldType(fieldToCheck, fieldValueToCompare);    //Phone field has '-' in between
        if (fieldValueToCompare.includes(actionValue)) {
            setDNAErrorMsg(fieldToCheck, actionValue);
        }
    }
}

export const DNACheckForSingle = (fieldValueToCompare, fieldToCheck, actionValue) => {
    if (actionValue === '' && fieldValueToCompare === '') {
        setDNAEmptyErrorMsg(fieldToCheck);
    } else {
        if (fieldValueToCompare === actionValue) {
            setDNAErrorMsg(fieldToCheck, actionValue);
        }
    }
}

export const performActions = (actionToPerform, formFields, selectedClient, descriptorData) => {
    let fieldToCheck = formFields.find((field) => actionToPerform.fieldId === field.id);    //Find the field to perform action on
    if (fieldToCheck) {
        let fieldValueToCompare = getFieldValueToCompare(fieldToCheck, selectedClient, descriptorData);   //Need this as we have different names used to store values for different fields
        let fieldValueDataType = typeof fieldValueToCompare;
        let actionValue = actionToPerform.actionValue;
        let action = actionToPerform?.action;
        let shouldByPass = false;

        switch(action) {
            case 'Do Not Allow':
                //Bypass the Require action rule in some cases, because otherwise it won't allow to save the entire form
                shouldByPass = byPassAction(fieldToCheck, actionValue, action);
                
                if (!shouldByPass) {
                    fieldToCheck.businessRuleApplied = action;

                    //If the "Do Not Allow" action rule does NOT have a "value" present in the rules/actions object of the API, then the related field requires any data to be entered or selected (i.e. the system is "not allowing" the field to be "blank/empty")
                    //If the "Do Not Allow" action rule DOES HAVE a "value" present in the rules/actions object of the API, then the related field allows any data to be entered or selected, EXCEPT the specified value
                    if (constData.IS_ARR_OBJ.includes(fieldValueDataType)) {
                        DNACheckForMultiple(fieldToCheck, actionValue, fieldValueToCompare);
                    } else {
                        DNACheckForSingle(fieldValueToCompare, fieldToCheck, actionValue);
                    }
                }
            break;
            
            case 'Require':
                //Bypass the Require action rule in some cases, because otherwise it won't allow to save the entire form
                shouldByPass = byPassAction(fieldToCheck, actionValue, action);

                if (!shouldByPass) {
                    fieldToCheck.businessRuleApplied = action;
                    if (constData.IS_ARR_OBJ.includes(fieldValueDataType)) {
                        requireCheckForMultiple(fieldToCheck, actionValue, fieldValueToCompare);
                    } else {
                        requireCheckForSingle(fieldValueToCompare, fieldToCheck, actionValue);
                    }
                }
            break;

            default:
                // Hide Case
                fieldToCheck.businessRuleApplied = action;
        }
    }
}