import DescriptorCacheService from "./descriptorCacheService";
import LookupService from "./lookupService";
import AppointmentService from "./appointmentService";
import DocumentService from "./documentService";
import VisitService from "./visitService";
import OrganizationService from "./organizationService";
import {appCache} from "../../cache/slices/app/appSlice";
import {getDispatchFromConfig} from "../utils/miscUtils";
import AuditService from "./auditService";
import {evvRepository} from '../../db/evv';

const EVV_READ_STAFF = '/evv/read/staff';
const EVV_READ_CASELOAD = '/evv/read/caseload';

const EVV_READ_ALLERGY = '/evv/read/allergy';
const EVV_READ_DIAGNOSIS = '/evv/read/diagnosis';
const EVV_READ_MEDICATION = '/evv/read/medication';
const EVV_READ_APPOINTMENT = '/evv/read/appointment';
const EVV_READ_DOCUMENT = '/evv/read/evvdocuments';
const EVV_READ_VISIT = '/evv/read/visit';
const EVV_READ_ORGANIZATION = '/evv/read/organization';
const EVV_READ_ACTIVITY = '/evv/read/activity';
const EVV_READ_PROGRAM = '/evv/read/program';
const EVV_READ_ACTIVITY_PROGRAM = '/evv/read/activityprogrammatrix';
const EVV_READ_CLIENT_PROGRAM = '/evv/read/clientprogram';
const EVV_READ_SERVICE_LOCATION = '/evv/read/servicelocation';
const EVV_READ_SERVICE_LOCATION_ORG = '/evv/read/servicelocationorganization';
const EVV_READ_DESCRIPTOR = '/evv/read/descriptor';
const EVV_READ_DOCUMENT_CROSSWALK = '/evv/read/documentcrosswalk';
const EVV_READ_SERVICE_DOCUMENT = '/evv/read/svcdoc';
const EVV_READ_CONFIGURABLE_FORM = '/evv/read/cfconfig';
const EVV_READ_STAFF_SIGNATURE = '/evv/read/staffsignature';
const EVV_READ_REFERRAL_SOURCE = '/evv/read/referral';

const EVV_WRITE_APPOINTMENT = '/evv/write/appointment';
const EVV_WRITE_VISIT = '/evv/write/visit';
const EVV_WRITE_DOCUMENT = '/evv/write/document';
const EVV_WRITE_AUDIT = '/evv/write/auditevent';
const EVV_READ_GOALADDRESSED_TXPLAN = '/evv/read/goaladdressed-txplan';
const EVV_READ_GOALADDRESSED_PGOI = '/evv/read/goaladdressed-pgoi';
const EVV_READ_GOALADDRESSED_PGOMAP = '/evv/read/goaladdressed-pgomap';
const EVV_READ_GOALADDRESSED_PGOINTMAP = '/evv/read/goaladdressed-pgointmap';
const EVV_READ_IMPACT_CONFIG = '/evv/read/impactconfig';
const EVV_READ_MESSAGE = '/evv/read/message';
const EVV_READ_VITAL = '/evv/read/vitals';

class ClientChildService extends LookupService{
    loadFromRepository = (config) => {
        if (config.dbParameters && config.dbParameters.clientId) {
            const clientId = parseInt(config.dbParameters.clientId);

            return this.repository.loadAllByProperty('clientId', clientId);
        } else {
            return Promise.resolve([]);
        }
    }
}

class SyncService {
    constructor() {
        this.staffService = new LookupService({readUrl: EVV_READ_STAFF, tableName: 'staff', paginated: true, pageSize: 1000});
        this.caseloadService = new LookupService({readUrl: EVV_READ_CASELOAD, tableName: 'caseload'});
        this.allergyService = new ClientChildService({readUrl: EVV_READ_ALLERGY, tableName: 'allergy'});
        this.diagnosisService = new ClientChildService({readUrl: EVV_READ_DIAGNOSIS, tableName: 'diagnosis'});
        this.medicationService = new ClientChildService({readUrl: EVV_READ_MEDICATION, tableName: 'medication'});
        this.appointmentService = new AppointmentService({
            readUrl: EVV_READ_APPOINTMENT,
            writeUrl: EVV_WRITE_APPOINTMENT,
            tableName: 'appointment',
            primaryKey: 'appointmentId',
            syncService: this
        });
        this.visitService = new VisitService({
            readUrl: EVV_READ_VISIT,
            writeUrl: EVV_WRITE_VISIT,
            tableName: 'visit',
            primaryKey: 'evvVisitId',
            syncService: this
        });
        this.documentService = new DocumentService({
            readUrl: EVV_READ_DOCUMENT,
            writeUrl: EVV_WRITE_DOCUMENT,
            tableName: 'document',
            primaryKey: 'id',
            syncService: this
        });
        this.organizationService = new OrganizationService({readUrl: EVV_READ_ORGANIZATION, tableName: 'organization'});
        this.activityService = new LookupService({readUrl: EVV_READ_ACTIVITY, tableName: 'activity'});
        this.programService = new LookupService({readUrl: EVV_READ_PROGRAM, tableName: 'program'});
        this.activityProgramService = new LookupService({readUrl: EVV_READ_ACTIVITY_PROGRAM, tableName: 'activityProgram'});
        this.clientProgramService = new LookupService({readUrl: EVV_READ_CLIENT_PROGRAM, tableName: 'clientProgram'});
        this.serviceLocationService = new LookupService({readUrl: EVV_READ_SERVICE_LOCATION, tableName: 'serviceLocation'});
        this.serviceLocationOrgService = new LookupService({readUrl: EVV_READ_SERVICE_LOCATION_ORG, tableName: 'serviceLocationOrganization'});
        this.descriptorService = new LookupService({readUrl: EVV_READ_DESCRIPTOR, tableName: 'descriptor', ignoreLoad: true});
        this.descriptorCacheService = new DescriptorCacheService(this.descriptorService);
        this.documentCrosswalkService = new LookupService({readUrl: EVV_READ_DOCUMENT_CROSSWALK, tableName: 'documentCrosswalk'});
        this.serviceDocumentService = new LookupService({readUrl: EVV_READ_SERVICE_DOCUMENT, tableName: 'serviceDocument', syncService: this});
        this.configurableFormService = new LookupService({readUrl: EVV_READ_CONFIGURABLE_FORM, tableName: 'configurableForm'});
        this.staffSignatureService = new LookupService({readUrl: EVV_READ_STAFF_SIGNATURE, tableName: 'staffSignature'});
        this.auditService = new AuditService({writeUrl: EVV_WRITE_AUDIT, tableName: 'auditLog'});
        this.referralSourceService = new LookupService({
            readUrl: EVV_READ_REFERRAL_SOURCE,
            tableName: 'referralSource',
            primaryKey: 'referralSourceId',
            syncService: this
        });
        this.goalAddressedTxplanService = new LookupService({
            readUrl: EVV_READ_GOALADDRESSED_TXPLAN,
            tableName: 'goalAddressedTxplan',
            primaryKey: 'treatmentPlanId',
            syncService: this
        });
        this.goalAddressedPgoiService = new LookupService({
            readUrl: EVV_READ_GOALADDRESSED_PGOI,
            tableName: 'goalAddressedPgoi',
            primaryKey: 'pgoiId',
            syncService: this
        });
        this.goalAddressedPgoMapService = new LookupService({
            readUrl: EVV_READ_GOALADDRESSED_PGOMAP,
            tableName: 'goalAddressedPgoMap',
            primaryKey: 'pgoMapId',
            syncService: this
        });
        this.goalAddressedPgoIntMapService = new LookupService({
            readUrl: EVV_READ_GOALADDRESSED_PGOINTMAP,
            tableName: 'goalAddressedPgoIntMap',
            primaryKey: 'pgoMapIntId',
            syncService: this
        });
        this.impactConfigService = new LookupService({
            readUrl: EVV_READ_IMPACT_CONFIG,
            tableName: 'impactConfig',
            primaryKey: 'id',
            syncService: this
        });
        this.messageService = new ClientChildService({readUrl: EVV_READ_MESSAGE, tableName: 'message'});
        this.vitalService = new ClientChildService({readUrl: EVV_READ_VITAL, tableName: 'vital'});
    }

    getReducers = () => {
        return {
            staff: this.staffService.getReducer(),
            caseload: this.caseloadService.getReducer(),
            appointment: this.appointmentService.getReducer(),
            visit: this.visitService.getReducer(),
            document: this.documentService.getReducer(),
            allergy: this.allergyService.getReducer(),
            medication: this.medicationService.getReducer(),
            diagnosis: this.diagnosisService.getReducer(),
            organization: this.organizationService.getReducer(),
            activity: this.activityService.getReducer(),
            program: this.programService.getReducer(),
            activityProgram: this.activityProgramService.getReducer(),
            clientProgram: this.clientProgramService.getReducer(),
            serviceLocation: this.serviceLocationService.getReducer(),
            serviceLocationOrganization: this.serviceLocationOrgService.getReducer(),
            documentCrosswalk: this.documentCrosswalkService.getReducer(),
            serviceDocument: this.serviceDocumentService.getReducer(),
            configurableForm: this.configurableFormService.getReducer(),
            staffSignature: this.staffSignatureService.getReducer(),
            descriptor: this.descriptorService.getReducer(),
            ...this.descriptorCacheService.getReducers(),
            audit: this.auditService.getReducer(),
            referralSource: this.referralSourceService.getReducer(),
            goalAddressedTxplan: this.goalAddressedTxplanService.getReducer(),
            goalAddressedPgoi: this.goalAddressedPgoiService.getReducer(),
            goalAddressedPgoMap: this.goalAddressedPgoMapService.getReducer(),
            goalAddressedPgoIntMap: this.goalAddressedPgoIntMapService.getReducer(),
            impactConfig: this.impactConfigService.getReducer(),
            message: this.messageService.getReducer(),
            vital: this.vitalService.getReducer()
        }
    };

    syncApplicationData = (config, user) => {
        const ignoreLastSyncConfig = {...config, ignoreLastSync: true};

        this.caseloadService.fetch({...config});
        this.organizationService.fetch(ignoreLastSyncConfig);
        this.activityService.fetch({...config});
        this.programService.fetch({...ignoreLastSyncConfig});
        this.activityProgramService.fetch({...ignoreLastSyncConfig});
        this.clientProgramService.fetch({...ignoreLastSyncConfig});
        this.serviceLocationService.fetch({...config});
        this.serviceLocationOrgService.fetch({...config});
        this.descriptorCacheService.fetch({...config});
        this.documentCrosswalkService.fetch({...config});
        this.serviceDocumentService.fetch({...config})
        this.configurableFormService.fetch({...ignoreLastSyncConfig});
        this.staffSignatureService.fetch({...ignoreLastSyncConfig});
        this.allergyService.fetch({...ignoreLastSyncConfig});
        this.medicationService.fetch({...ignoreLastSyncConfig});
        this.diagnosisService.fetch({...ignoreLastSyncConfig});
        this.referralSourceService.fetch({...ignoreLastSyncConfig});
        this.auditService.fetch({...ignoreLastSyncConfig});
        this.goalAddressedTxplanService.fetch({...ignoreLastSyncConfig});
        this.goalAddressedPgoiService.fetch({...ignoreLastSyncConfig});
        this.goalAddressedPgoMapService.fetch({...ignoreLastSyncConfig});
        this.goalAddressedPgoIntMapService.fetch({...ignoreLastSyncConfig});
        this.impactConfigService.fetch({...ignoreLastSyncConfig});
        this.messageService.fetch({...ignoreLastSyncConfig});
        this.vitalService.fetch({...ignoreLastSyncConfig});
    };

    syncFacesheetData = (config, client) => {
        const clientConfig = {...config, dbParameters: client};
        this.allergyService.fetch({...clientConfig});
        this.medicationService.fetch({...clientConfig});
        this.diagnosisService.fetch({...clientConfig});
        this.appointmentService.fetch({...config, parameters: client});
        this.messageService.fetch({...clientConfig});
        this.vitalService.fetch({...clientConfig});
    };

    sendWriteInformation = async (config) => {
        clearInterval(this.intervalID);
        evvRepository.writingProcess = 'true';

        await this.appointmentService.writeToServer(config).then(() => {
            const ignoreLastNotClearSyncConfig = {...config, ignoreLastSync: true, doNotClear: true};
            evvRepository.writingProcess = 'false';
            this.appointmentService.fetch(config);
            this.visitService.fetch(config);
            this.documentService.fetch({...ignoreLastNotClearSyncConfig});
        })
        .catch(error => {
            console.log("Error thrown while writing to server: ");
            console.log(error);
            evvRepository.writingProcess = 'false';
        });
    }

    identifyIfClientisReady = (config) => {
        const state = config.store.getState();
        const isClientsReady = appCache.getClients(state);
        if (isClientsReady.length > 0) {
            const syncParams = {...config, ignoreLastSync: true};
            this.sendWriteInformation(syncParams);
        }
    }

    syncAppointmentData = (config) => {
        if (typeof this.intervalID !== "undefined") {
            clearInterval(this.intervalID);
        }
        this.intervalID = setInterval(() => {
            this.identifyIfClientisReady(config)}
            , 1000);
    };

    changeUser = (config) => {
        return this.appointmentService.writeToServer(config)
            .then(() => Promise.resolve());
    };

    changeCurrentOrganization = (config, selectedOrganization) => {
        const dispatch = getDispatchFromConfig(config);

        const documentCrosswalkParameters = {
            organizationId: selectedOrganization.organizationId
        };
        this.documentCrosswalkService.fetch({dispatch, parameters: documentCrosswalkParameters});

        dispatch(appCache.selectOrganization(selectedOrganization));

        this.filterByOrganization(dispatch, selectedOrganization.organizationId);
    };

    changeIncompleteTaskSortId = (config, incompleteTaskSortId) => {
        const dispatch = getDispatchFromConfig(config);
        dispatch(appCache.selectIncompleteTaskSort(incompleteTaskSortId));
    };

    filterByOrganization = (dispatch, organizationId) => {
        dispatch(this.appointmentService.filterByOrganization({organizationId: organizationId}));
        dispatch(this.serviceDocumentService.filterByOrganization({
            organizationId: organizationId,
            isArrayProperty: true
        }));
    }

    getVisits = (state) => state.sync.visit.results;
    getAppointments = (state) => state.sync.appointment.results;
    getActivities = (state) => state.sync.activity.results;
    getOrganizations = (state) => state.sync.organization.results;
    getClientPrograms = (state) => state.sync.clientProgram.results;
    getPrograms = (state) => state.sync.program.results;
    getServiceLocations = (state) => state.sync.serviceLocation.results;
    getStaff = (state) => state.sync.staff.results;
    getReferralSource = (state) => state.sync.referralSource.results;
    getGoalAddressedTxplan = (state) => state.sync.goalAddressedTxplan.results;
    getGoalAddressedPgoi = (state) => state.sync.goalAddressedPgoi.results;
    getGoalAddressedPgoMap = (state) => state.sync.goalAddressedPgoMap.results;
    getGoalAddressedPgoIntMap = (state) => state.sync.goalAddressedPgoIntMap.results;
    getImpactConfig = (state) => state.sync.impactConfig.results;
    getMessages = (state) => state.sync.message.results;
}

const syncService = new SyncService();

export default syncService;
