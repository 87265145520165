import {makeStyles} from "@material-ui/core/styles";
import LoginTextField from "../../../common/components/LoginTextField";
import {Checkbox, Divider, FormControlLabel} from "@material-ui/core";
import {useSelector} from "react-redux";
import StackedField from "../../../common/components/StackedField";
import React, {useEffect, useRef, useState} from "react";
import SignaturePad from "react-signature-canvas";
import {
    formatUserNameForSign,
    convertTandZToMMDDYYYY
} from "../../../common/utils/formatUtils";
import EvvSelect from "../../../common/components/EvvSelect";
import {EMPTY_PROGRAM} from "../../../common/utils/appointmentUtils";
import {SERVICE_DOCUMENT_STATUS_SIGNED, SERVICE_DOCUMENT_STATUS_UNSIGNED, pageByIndex, getProgramName} from "../serviceDocumentUtils";
import * as msgConst from './Messages';
import {clientCache} from "../../../cache/slices/client/clientSlice";
import './SignaturePage.css';
import Typography from "@material-ui/core/Typography";
import { validateFields } from "../common/ValidateFields";
import { sha256 } from "js-sha256";
import WarningIcon from '@mui/icons-material/Warning';
import { appCache } from "../../../cache/slices/app/appSlice";
import '../ServiceDocumentWizard/ServiceDocumentWizard.css';
import "../ServiceDocName.css";
import { getDescriptorTableData } from "../common/ConditionsUtils";
import { checkAllMandatoryFun, staffSignatureFun, updateSignatures, updateExternalSignature, getSignedDate, convertUTCDateToLocalDate, staffSignatureReadOnly, hasAppointment, updateProgramFun } from "./SignaturePageUtil";
import { PRIMARY_COLOR } from "../../../common/constants";
import { isGA } from "../../../common/utils/goalsAddressedUtils";
import { gaValidationWithFocus } from "../GoalsAddressed/ValidateGaFields";
import { isImpact } from "../../../common/utils/impactUtils";
import { impactValidationWithFocus } from "../Impact/ValidateImpactFields";
import EvvButton from "../../../common/components/EvvButton";
import AddIcon from "@mui/icons-material/Add";
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import AssignStaff from "./AssignStaff";
import { isEqual } from 'lodash';

const useStyles = makeStyles(() => ({
    signaturePage: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        padding: '17px 14px'
    },
    sectionTitle: {
        fontSize: '18px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: PRIMARY_COLOR,
        paddingTop: '14px',
        paddingBottom: '11px'
    },
    externalSignatureBlock: {
        width: '100%',
    },
    checkBoxContainer:{
        height: '60px',
        paddingTop:'10px',
    },
    signaturePanel:{
        minHeight: '160px',
        maxHeight: '160px',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'space-between',
        padding:'10px 20px 0px 20px'
    },
    innerContainer:{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'space-between',
        borderRadius: "3px",
        backgroundColor: "#f6f6f6",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: "#dddddd",
    },
    signatureCanvas:{
        margin: '10px',
        borderRadius: "3px",
        backgroundColor: "#f6f6f6",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: "#dddddd",
        height:'100px',
        width:'100px',
    },
    clearSignatureButton:{
        width: '130px',
        borderRadius: "3px",
        backgroundColor: "#ebebeb",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: "#dddddd",
        fontSize: '11px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: PRIMARY_COLOR,
        margin:'6px'
    },
    arial:{
        fontSize: '16px',
        color: "#4f4f4f"
    },
    errorMessage : {
        position: 'relative',
        // bottom: '-5px',
        fontSize: '13px',
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: '16px',
        letterSpacing: 0,
        color: "#dc0707"
    },
    hide: {
        display: 'none'
    },
    validationIssueContainer: {
        color: '#4f4f4f',
        width: "99.5%",
        borderRadius: "3px",
        backgroundColor: "#f6f6f6",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: "#dddddd",
        paddingTop: '10px',
        paddingLeft: '15px',
        paddingBottom: '15px',
        marginTop:'6px'
    },
    validationIssueTitle: {
        paddingBottom: '12px'
    },
    issueTitle: {
        fontSize: '16px',
        lineHeight: '20px'
    },
    issueSubtitle: {
        fontSize: '13px',
        lineHeight: '18px'
    },
    issueHeading: {
        fontWeight: 'bold'
    },
    validationIssue: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '14px',
        letterSpacing: '0.18px',
        lineHeight: '20px',
        margin: '5px 0',
    },
    alertSvg: {
        maxHeight: '20px',
        maxWeight: '22px',
        marginRight: '5px',
        color: '#F40306'
    },
    issue: {
        lineHeight: '20px'
    },
    hideIssueBox: {
        display: 'none'
    },
    widthSignaturePage: {
        width: "50%"
    },
    widthSignaturePageHandheld: {
        width: "100%"
    },
    externalSign: {
        fontSize: '16px',
        color: "#4F4F4F"
    },
    nextStaff: {
        width: '40vw',
        lineHeight: '24px',
        fontSize: '16px'
    },
    dialogTitle: {
        justifyContent: "space-between",
        display: "flex",
        fontWeight: 'bold',
        fontSize: '22px'
    },
    buttonText: {
        display: 'flex',
        justifyContent: 'center',
    },
    icons: {
        marginRight: '12px'
    },
    selectedStaffText: {
        paddingBottom: '16px', 
        fontSize: '16px', 
        paddingTop: '3px'
    }
}));

function SignaturePage({module, serviceDocument, documentController, hide, setupSignaturePadTest, documentForWizard, currentPageIndex}){
    const selectedClient = documentController.dataProvider.getSelectedClient();
    const user = documentController.dataProvider.getUser();
    const [filteredPrograms, setFilteredPrograms] = useState([]);
    const [selectedStaff, setSelectedStaff] = useState(null);
    const [saveAttempted, setSaveAttempted] = useState(false);
    const [staffSignature, setStaffSignature] = useState('');
    const [updateDisable, setUpdateDisable] = useState(true);
    const [hideIssueBox, setHideIssueBox]= useState(false);
    const [anyValidationtionIssue, setanyValidationtionIssue] = useState(true)
    const [signatureFormData, setSignatureFormData] = useState({});
    const [externalSignSaved, setexternalSignSaved] = useState({});
    const [descriptorList, setDescriptorList] = useState([]);
    const [signatureFormDisableFields, setsignatureFormDisableFields] = useState({});
    const [disableSign, setDisableSign] = useState(false);
    const styles = useStyles();
    const client = useSelector(clientCache.getSelectedClient);
    const isHandheld = useSelector(appCache.isHandheld);
    const signaturePadCanvas = useRef([]);
    const [appointment, setAppointment] = useState([]);
    const [showStaffChooser, setShowStaffChooser] = useState(false);
    const prevAppointmentRef = useRef(null);
    const globalVisit = useSelector(appCache.getGlobalVisit);

    const call = "add";
    let externalSignArray = [];
    let noOfSignaturePad = serviceDocument.signatureConfiguration.numberOfExternalSignatures;
    let externalSignatures;
    let staffSignDate;
    let cpId, appointmentIdForQuery, currentDocument, nextStaff, clientProgram;
    const orgId = user?.currentOrganizationId;

    if (documentController?.appointmentDocument && documentController?.appointmentDocument.length > 0) {
        currentDocument = documentController?.appointmentDocument[0];
        if(currentDocument) {
            externalSignatures = currentDocument?.signature?.externalSignatures;
            staffSignDate = currentDocument?.signature?.signedDate;
            cpId = currentDocument?.clientProgramId;
            appointmentIdForQuery = currentDocument?.appointmentIdForQuery;
            nextStaff = currentDocument?.signature?.nextStaffId;
        }

    } else {
        externalSignatures = documentForWizard?.signature?.externalSignatures;
        staffSignDate = documentForWizard?.signature?.signedDate;
        cpId = documentForWizard?.clientProgramId;
    }

    let currentPage = pageByIndex(currentPageIndex, serviceDocument);

    useEffect(() => {
        if (appointmentIdForQuery && !isEqual(prevAppointmentRef.current, appointment)) {
            getAppointment();
            staffSignatureReadOnly(appointment, setDisableSign);
            prevAppointmentRef.current = appointment;
        }
        // eslint-disable-next-line
    }, [appointmentIdForQuery, appointment]);

    useEffect(() => {
        if (globalVisit?.appointmentId === appointmentIdForQuery) {
            const appointment = [{
                appointmentId: globalVisit?.appointmentId,
                status: globalVisit?.appointmentStatus
            }];
            staffSignatureReadOnly(appointment, setDisableSign);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appointmentIdForQuery, globalVisit]);

    const getAppointment = async() => {
        await hasAppointment(appointmentIdForQuery).then((result) => {
            setAppointment(result);
        }).catch((error) => {
            console.log('Error: ', error);
        });
    } 

      const fetchProgramData = async() => {
        let programData =  await getProgramName(cpId);  
        if (programData && programData.length > 0) {
            setProgram(programData)
            setSelectedProgram(programData[0])
        }
    }
    useEffect(()=>{
        if(cpId){
            fetchProgramData()
        }
           // eslint-disable-next-line
    },[cpId])
    
    const [selectedProgram, setSelectedProgram] = useState(null);
    const [program, setProgram] = useState();

    const setSignatureFormDataFromDB = () => {
        let formStateData = {};
        let signedCount = 0;

        if (externalSignatures) {
            externalSignatures.forEach((ele, index) => {
                formStateData[`externalName${index + 1}`] = ele.name;
                formStateData[`sign${index + 1}`] = true;

                if (ele?.signature && ele.signature !== "") {
                    signedCount++;
                }
            });

            setSignatureFormData({...signatureFormData, ...formStateData});

            if (noOfSignaturePad === signedCount) {
                documentController.setAllSigAvailable(true);
            } else {
                documentController.setAllSigAvailable(false);
            }
        }
    }

    useEffect(() => {
        setSignatureFormDataFromDB();
        getDescriptorTableData(orgId)
        .then(result => setDescriptorList(result))
        .catch(error => {
            console.log("error: ", error);
        });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const clientPrograms = documentController.dataProvider.getProgramsForOrganizationAndClient(
            selectedClient.clientId
        ) || [];
        setFilteredPrograms([EMPTY_PROGRAM, ...clientPrograms]);
    }, [documentController.dataProvider, selectedClient?.clientId]);

    useEffect(() => {
        let selectedProgram = serviceDocument?.appointment?.program;

        if(cpId === null) {
            selectedProgram = filteredPrograms[0];
            setSelectedProgram(selectedProgram);
        }
        else if(selectedProgram){
            setSelectedProgram(selectedProgram);
        }
        else if (!selectedProgram && filteredPrograms.length === 2) {
            selectedProgram = filteredPrograms[1]
            setSelectedProgram(selectedProgram);
        }
        // eslint-disable-next-line
    }, [serviceDocument.appointment, filteredPrograms]);

    useEffect(() => {
        setStaffSignature(documentController.staffSignature);
    }, [documentController.staffSignature]);

    useEffect(() => {
        const programs = documentController.dataProvider.getClientPrograms();
        const clientId = Number(client?.clientId);
        const programId = (selectedProgram?.programId);
        // eslint-disable-next-line
        clientProgram = programs.find(cp => (cp.clientId) === clientId &&(cp.programId) === programId);
        updateProgramFun(serviceDocument, documentController, clientProgram);
    });

    useEffect(() => {
         setSelectedStaff(documentController.nextStaff ? documentController.nextStaff : nextStaff);
        // eslint-disable-next-line
    }, [documentController.nextStaff]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    let checkAllMandatory = []
    useEffect(() => {
        if ((checkAllMandatory.length > 0) && (checkAllMandatory.findIndex((obj) => obj.mandatory === false) < 0) && (checkAllMandatory.findIndex((obj) => obj.nextClicked === false) < 0)) {
            setanyValidationtionIssue(false);
            setHideIssueBox(true);
            setUpdateDisable(false);
        // Even one field has issue and next clicked then requirement not met error
        // eslint-disable-next-line
        } else if ((checkAllMandatory.length > 0)&&
        ((checkAllMandatory.findIndex((obj) => obj.mandatory === false) >= 0) &&
         // eslint-disable-next-line
        (checkAllMandatory.findIndex((obj) => obj.nextClicked === true) >= 0)) ||
        ((checkAllMandatory.findIndex((obj) => obj.mandatory === false) <= 0) &&
        (checkAllMandatory.findIndex((obj) => obj.nextClicked === false) <= 0))) {
            setanyValidationtionIssue(true);
            setHideIssueBox(false);
            setUpdateDisable(true);
        } 
    },[checkAllMandatory])
    useEffect(() => {
        if(anyValidationtionIssue === false) {
            documentController.setSaveDisable(false);
        }else{
            documentController.setSaveDisable(true);
        }
    },[anyValidationtionIssue, documentController])

    useEffect(() => {
        if (setupSignaturePadTest){
            setupSignaturePadTest(setupSignaturePad);
        }
        /* eslint-disable react-hooks/exhaustive-deps */
    }, []);
    
    const isPageReadOnly = () => {
        return documentController.getStatus() === SERVICE_DOCUMENT_STATUS_SIGNED;
    };

    const isProgramReadOnly = () => {
        return documentController.getStatus() === SERVICE_DOCUMENT_STATUS_SIGNED;
    };

    const handleStaffSignatureChange = (evt) => {
        setStaffSignature(evt.target.value);
    };

    const isStaffSignatureVisible = () => {
        return serviceDocument.signatureConfiguration.numberOfStaffSignatures > 0 || serviceDocument.signatureConfiguration.minimumNumberOfStaffSignatures > 0;
    };

    const isNextStaffVisible = () => {
        return serviceDocument.signatureConfiguration.numberOfStaffSignatures > 1 || serviceDocument.signatureConfiguration.minimumNumberOfStaffSignatures > 1;
    };

    const isStaffSignatureRequired = () => {
        return serviceDocument.signatureConfiguration.numberOfStaffSignatures > 0 && serviceDocument.signatureConfiguration.minimumNumberOfStaffSignatures > 0 && documentController.getStatus() !== SERVICE_DOCUMENT_STATUS_SIGNED;
    };

    const isStaffSignatureReadOnly = () => {
        return documentController.getStatus() === SERVICE_DOCUMENT_STATUS_SIGNED && documentController.staffSignature;
    };

    const isNextStaffRequired = () => {
        return serviceDocument.signatureConfiguration.numberOfStaffSignatures > 1 && serviceDocument.signatureConfiguration.minimumNumberOfStaffSignatures > 1;
    };

    const isNextStaffToSend = () => {
        return serviceDocument.signatureConfiguration.numberOfStaffSignatures > 1 && serviceDocument.signatureConfiguration.minimumNumberOfStaffSignatures >= 1;
    };

    const isValidSignature = () => {
        let _staffSignatures = documentController.dataProvider.getStaffSignatures();
        let _sha256 = sha256(staffSignature).toUpperCase();
        if(_sha256 === _staffSignatures[0].electronicSignature) {
            console.log("Valid Signature!");
        }
        return _sha256 === _staffSignatures[0].electronicSignature;
    };

    const isClientProgramRequired = () => {
        return serviceDocument.isClientProgram;
    };

    const formatSignedByAndDate = () => {
        let staffSign;
        if(documentController?.appointmentDocument[0]?.signature?.signedDate) {
            let formattedText = formatUserNameForSign(documentController.dataProvider.getStaff());
            let signDate = convertTandZToMMDDYYYY(convertUTCDateToLocalDate(documentController?.appointmentDocument[0]?.signature?.signedDate));
            staffSign = formattedText + ' - ' + signDate;
        }
        return staffSign;
    };

    const handleSave = () => {
        setSaveAttempted(true);

        if (isStaffSignatureRequired()) {
            if (!staffSignature || !isValidSignature()) {
                return false;
            }
        }

        if (isNextStaffRequired()) {
            if (!selectedStaff){
                return false;
            }
        }

        if (isClientProgramRequired()){
            if (!selectedProgram || selectedProgram.programId === EMPTY_PROGRAM.programId){
                return false;
            }
        }

        let fieldsdata = {...signatureFormDisableFields};
        Object.keys(signatureFormData).forEach(key => {
            fieldsdata[key] = signatureFormData[key] === '' || signatureFormData[key] === false ? false : true
        });
        saveExternalSignature();
        setsignatureFormDisableFields(fieldsdata);
       
        const signed = staffSignature && isValidSignature();
        serviceDocument.signed = signed;
        documentController.setStatus(signed ? SERVICE_DOCUMENT_STATUS_SIGNED : SERVICE_DOCUMENT_STATUS_UNSIGNED);
        documentController.staffSignature = staffSignature;
        documentController.signedDate = getSignedDate();
        documentController.nextStaff = selectedStaff;
        documentController.program = selectedProgram;

        setSignatureToServiceDocument();
        setSaveAttempted(false);
        return true;
    };

    const setSignatureToServiceDocument = () => {
        let signature = {
            externalSignatures: [],
            signedBy: user.staffId,
            signedDate: staffSignDate,
        }

        staffSignatureFun(documentController, signature, user, staffSignDate, staffSignature);

        if (isNextStaffToSend()) {
            signature['nextStaffId'] = documentController.nextStaff;
        }
        signature = updateExternalSignature(signaturePadCanvas, signature, noOfSignaturePad, signatureFormData);

        if(signature['signedDate'] !== undefined){
            serviceDocument['signature'] = signature;
        }

        documentController.serviceDocument = serviceDocument;
    };

    const saveExternalSignature = () => {
        const formStateData = {...signatureFormData};
        signaturePadCanvas.current.forEach((canvas, index) => {
            if(formStateData[`sign${index}`]) {
                let signData = {...externalSignSaved}
                signData[index] = true
                canvas.signature = canvas.toDataURL("image/png");
                canvas.clear()
                setexternalSignSaved(signData)
            }
        })
    };

    const handleProgramChange = (evt) => {
        // eslint-disable-next-line eqeqeq
        setSelectedProgram(filteredPrograms.find(p => p.programId == evt.target.value));
        updateProgramFun(serviceDocument, documentController, clientProgram);
    };
    
    const requirementNotMetError = (moduleName,index) =>
    {
        let requirementNotMetError = ( moduleName?.hasAddressedError) ? msgConst.requirementsNotMetGaString : msgConst.requirementsNotMetString;
     return(   
            <div className={styles.validationIssue}>
                <WarningIcon className={styles.alertSvg}  alt='Alert Warning'/>
                <span className={styles.issue}> {requirementNotMetError} <span id="hyperButton" onClick={()=>handleClick(index)} style={{textDecoration:"underline"}}> {moduleName.name} </span> </span>
            </div>
        );
    };

    const pleaseSelectNextError = (moduleName,index) =>
    {
        return( 
            <div className={styles.validationIssue}>
                <WarningIcon className={styles.alertSvg}  alt='Alert Warning'/>
                <span className={styles.issue}> {msgConst.pleaseSelectNextString} <span id="hyperButton" onClick={()=>handleClick(index)} style={{textDecoration:"underline"}}> {moduleName.name} </span> </span>
            </div>
        );
    };

    const getResult = (module, nextClicked, selectedClient, descriptorList) => {
        let result;
        if (isGA(module)) {
            result = gaValidationWithFocus("renderValidationIssues", module);
        } else if (isImpact(module)) {
            result = impactValidationWithFocus(module, false);
        } else {
            result = validateFields(module, nextClicked, selectedClient, descriptorList)
        }
        return result;
    }

    const renderValidationIssues = () => {
         // eslint-disable-next-line
         return serviceDocument.modules.map((module, index) => {
            let result;
            let checkMandatory;
            let nextClicked =  (isGA(module) && module?.fields) ? module?.fields[0]?.nextClicked : module.configurableForm?.fields[0]?.nextClicked;
            let moduleId = module.moduleId;

            if(module.name !== 'Signatures' && (checkAllMandatory.findIndex((obj) => obj.moduleId === moduleId) < 0)) {
                checkAllMandatory.push({moduleId: moduleId,mandatory : false, nextClicked : false});
            }

            if(module && module.name !== 'Signatures'){
                result = getResult(module, nextClicked, selectedClient, descriptorList);
            }

            checkMandatory = result?.isValid;
            if(module.name === 'Signatures') {
                return null;
            }

            let checkNext = documentController?.modulesToSave?.find(m => m.moduleId === moduleId)?.formValidated;
            result.checkNext = checkNext;
            module.result = result;

            checkAllMandatoryFun(checkAllMandatory, checkMandatory,checkNext, moduleId);

            if(checkMandatory && !checkNext) {
                    return <div key={index}> {pleaseSelectNextError(module,index)} </div>
            } else if (!checkMandatory && !checkNext){
                return <div key={index}> 
                            {requirementNotMetError(module,index)}
                            {pleaseSelectNextError(module,index)}
                        </div>
            } else if (!checkMandatory && checkNext){
                return <div key={index}> 
                            {requirementNotMetError(module,index)}
                        </div>
            }
        })
    };

    const renderIssueBox = () => {
        return (
            <>{!isHandheld && <><div className="serviceDocName">{serviceDocument.description}</div><Divider/></>}
            {isHandheld && <><div className="configurableFormName">Signatures</div><Divider/></>}
            <div className={styles.validationIssueContainer + ' ' + (hideIssueBox ? styles.hideIssueBox : '')} id={'issueBox'}>
                <div className={styles.validationIssueTitle}>
                    <div className={styles.issueHeading + ' ' + styles.issueTitle}>{msgConst.validationIssueString}</div>
                    <div className={styles.issueSubtitle}><span className={styles.issueHeading}>{msgConst.electronicSting}</span>{msgConst.documentString}</div>
                </div>
                {renderValidationIssues()}
            </div></>
        );
    }
 
  function handleClick(id) {
    documentController.wizardSelectPage(id);
  }

    const handleOnChange = (event, fieldName, index, isCheckBox) => {
        const formStateData = {...signatureFormData};
        const value = isCheckBox ? event.target.checked : event.target.value;
        formStateData[fieldName] = value;
  
        if (fieldName === `extSignNameCheckBox${index}` && !value) {
            formStateData[`externalName${index}`] = '';
        } else if(isCheckBox){
            formStateData[`externalName${index}`] = `${client.lastName}, ${client.firstName} (Self)` 
        }
        setSignatureFormData(formStateData);
        updateSignatures({serviceDocument, formData: formStateData, signaturePadCanvas, noOfSignaturePad, user, documentController, call, staffSignDate, selectedStaff, clientProgram});
    }

    const handleEndSigning = (fieldName) => {
        const formData = {...signatureFormData};
        formData[fieldName] = true;
        setSignatureFormData(formData);
        updateSignatures({serviceDocument, formData, signaturePadCanvas, noOfSignaturePad, user, documentController, call, staffSignDate, selectedStaff, clientProgram});
    }

    const sizeCanvasToParent = (canvas) => {
        const parent = canvas.parentNode;

        if (!parent) {
            return;
        }

        const width = parent.clientWidth;
        const height = parent.clientHeight;  

        if (canvas.width !== width || canvas.height !== height) {
            canvas.width = width;
            canvas.height = height;
            canvas.style.display = 'block';
        }
    }

    useEffect(() => {
        if (externalSignatures) {
            setTimeout(() => {
                signaturePadCanvas.current.forEach((canvas, index) => {
                    if (canvas && canvas.fromDataURL && externalSignatures[index-1] && externalSignatures[index-1].signature) {
                        canvas.fromDataURL(`data:image/png;base64,${externalSignatures[index-1]?.signature}`, {ratio: 1});
                        if(documentController.getStatus() === SERVICE_DOCUMENT_STATUS_SIGNED){
                            canvas.off();
                        }
                    }
                })
            },500)
        }
    })

    const setupSignaturePad = (sp, index, hasSignature) => {
        index = index === undefined ? 1 : index ;
        const formStateData = {...signatureFormData};
        if (sp) {
            signaturePadCanvas.current[index] = sp;
        }

        if (sp && hasSignature) {
            sp.fromDataURL(`data:image/png;base64,${externalSignatures[index - 1]?.signature}`, {ratio: 1});
            if(documentController.getStatus() === SERVICE_DOCUMENT_STATUS_SIGNED){
                sp.off();
            }
        }

        if(formStateData[`sign${index}`] && signaturePadCanvas.current[index] && externalSignSaved[index]) {
            signaturePadCanvas.current[index].fromDataURL(signaturePadCanvas.current[index].signature,{ratio: 1});
            signaturePadCanvas.current[index].off()
        }
        if (sp) {
            sizeCanvasToParent(sp.getCanvas());
        }
    }

    const clearSignaturePad = (index) => {
        signaturePadCanvas.current[index].clear();
        let updatedSignatureFormData = { ...signatureFormData };
        updatedSignatureFormData[`sign${index}`] = false;
        setSignatureFormData(updatedSignatureFormData);
        updateSignatures({serviceDocument, formData: updatedSignatureFormData, signaturePadCanvas, noOfSignaturePad, user, documentController, call: 'clear', signPadIndex: index, staffSignDate, externalSignatures });
    }

    const viewExternalSignDate = (index, extSignDate) => {
        return (
            <StackedField label='External Signature Date' paddingBottom="5px">
                <div id={`extsignDate${index}`} className={styles.externalSign}>{convertTandZToMMDDYYYY(convertUTCDateToLocalDate(extSignDate))}</div>
            </StackedField>
        );
    }
    const viewExternalSignName = (index, extSignName) => {
        return (
            <StackedField label='External Signature Name'>
                <div id={`viewname${index}`} className={styles.externalSign}>{extSignName}</div>
            </StackedField>
        );
    }

    const editExternalSignName = (index, hasSignature) => {
        return (
            <StackedField label='External Signature Name'>
                <LoginTextField
                    id={`name${index}`}
                    onChange={(event) => handleOnChange(event, `externalName${index}`, index)}
                    value={signatureFormData?.[`externalName${index}`] || ''}
                    maxCharacters={50}
                />
            </StackedField>
        );
    }

    const externalSignaturePad = (index, extSignName, extSignDate, hasSignature ) => {
        let isReadOnly = isPageReadOnly() && hasSignature;

        externalSignArray.push(
            <div className= {styles.externalSignatureBlock} key={index}> 
                {extSignName && isPageReadOnly() ?
                    viewExternalSignName(index, extSignName)
                :
                    editExternalSignName(index, hasSignature)
                }
                {extSignDate && isReadOnly &&
                    viewExternalSignDate(index, extSignDate)
                }
                <div className={styles.checkBoxContainer} style={isReadOnly ? {display: 'none'} : {display: 'block'}}>
                    <FormControlLabel 
                            label={<Typography className={styles.arial}>External Signature Name Same as Client</Typography>}
                            control={
                                <Checkbox
                                    id={`checkBoxIndex${index}`}
                                    checked={!!signatureFormData?.[`extSignNameCheckBox${index}`]}
                                    onChange={(event) => handleOnChange(event, `extSignNameCheckBox${index}`, index, true)}
                                    color='primary' 
                                />
                            }
                        />
                </div> 
                <StackedField label='External Signature' paddingTop={isReadOnly ? "14px" : "0px"} >
                    <div className={styles.innerContainer}>
                        <div className={styles.signaturePanel}>
                            <SignaturePad
                            id={`sign${index}`}
                            ref={(ref) => setupSignaturePad(ref, index, hasSignature)}
                            throttle={0}
                            onEnd = {(ref) => handleEndSigning(`sign${index}`)}
                            clearOnResize={false}
                            canvasProps={{
                            className: "signatureCanvas",
                            style:{display: 'none'},
                            width: '1px',
                            height: '1px'
                            }}
                            />
                        </div>
                        <button style={isReadOnly ? {display: 'none'} : {display: 'block'}} disabled={(documentController.getStatus() === SERVICE_DOCUMENT_STATUS_SIGNED) && (hasSignature || externalSignSaved?.[index]?true:false)}
                                className={styles.clearSignatureButton} 
                                onClick={() => clearSignaturePad(index)}>CLEAR SIGNATURE</button>
                     </div>
                </StackedField>
            </div>
        )
    }

    const renderSignaturePad = () => {
        for (let index = 1; index <= noOfSignaturePad; index++) {
            let extSignDate;
            let extSignName;

            if(externalSignatures && externalSignatures[index - 1]?.signature) {
                extSignDate = externalSignatures[index - 1]?.signedDate;
                extSignName = (externalSignatures[index - 1]?.name);    
            }
            let hasSignature = (externalSignatures && externalSignatures[index - 1]?.signature && externalSignatures[index - 1]?.signature !== '') ? true : false;

            externalSignaturePad(index, extSignName, extSignDate, hasSignature)
        }
        return externalSignArray;
    }


    
    const renderViewForm = () => {
        return (
            <div data-testid='SignaturePage_ViewForm'>
                <StackedField label='Program'>
                    <div id='program'>{program ? program[0]?.name : ''}</div>
                </StackedField>
                {renderSignaturePad()}
                {isStaffSignatureVisible() &&
                <div>
                    <div className={styles.sectionTitle}>
                        Signatures
                    </div>
                    <StackedField label='Staff Signature' paddingTop="0px">
                        <div id='staffSignatureInfo' className={styles.externalSign}>{formatSignedByAndDate()}</div>
                    </StackedField>
                </div>
                }
                {isNextStaffVisible() &&
                <StackedField label='Next Staff to Sign'>
                    <div id='nextStaffToSign'className={styles.externalSign}>{documentController?.appointmentDocument[0]?.signature?.nextStaffId ? documentController?.appointmentDocument[0]?.signature?.nextStaffId?.value : ''}</div>
                </StackedField>
                }
            </div>
        );
    };

    const renderProgram = () => {
        if (serviceDocument?.appointment?.program){
            return <LoginTextField value={serviceDocument.appointment.program.name} disabled={true} />;
        } else {
            return (
                <EvvSelect id='selectedProgram'
                    value={selectedProgram ? selectedProgram.programId : EMPTY_PROGRAM.programId}
                    onChange={handleProgramChange}
                    disabled = { isProgramReadOnly() }
                >
                    {filteredPrograms.map((item, index) =>
                        <option key={`program_${index}`}
                                value={item.programId}>{item.name + (item.code ? ` (${item.code})` : '')}</option>)
                    }
                </EvvSelect>
            );
        }
    }

    const handleStaff = () =>{
        setShowStaffChooser(true);
    }

    const renderEditForm = () => {
        return (
            <div data-testid='SignaturePage_EditForm'>
                <StackedField label='Program' paddingTop="0px" mandatory={isClientProgramRequired()}>
                    {renderProgram()}
                </StackedField>
                {saveAttempted && isClientProgramRequired() && (!selectedProgram || selectedProgram.programId === EMPTY_PROGRAM.programId) &&
                <div className={styles.errorMessage}>Program is a required field. Please select a value.</div>
                }
                {renderSignaturePad()}
                {isStaffSignatureVisible() && !isStaffSignatureReadOnly() &&
                <StackedField label='Staff Signature' mandatory={isStaffSignatureRequired()}>
                    <LoginTextField
                        id='staffSignature'
                        type='password'
                        disabled={updateDisable || disableSign}
                        value={staffSignature}
                        onChange={handleStaffSignatureChange}
                        maxCharacters={20}
                    />
                </StackedField>
                }
                {saveAttempted && isStaffSignatureRequired() && !staffSignature &&
                <div className={styles.errorMessage}>Staff Signature is a required field. Please enter a value.</div>
                }
                {saveAttempted && isStaffSignatureRequired() && staffSignature && !isValidSignature() &&
                <div className={styles.errorMessage}>The signature entered does not match the signature on file.</div>
                }
                {isNextStaffVisible() && !isStaffSignatureReadOnly() &&
                <StackedField label='Next Staff to Sign' mandatory={isNextStaffRequired()}>
                   <div className = {isHandheld ? {} : styles.nextStaff}>Assign this service document to a staff member to sign.</div>
                </StackedField>
                }
                {saveAttempted && isNextStaffRequired() && !selectedStaff &&
                <div className={styles.errorMessage}>Next Staff to Sign is a required field. Please select a value.</div>
                }
                {isNextStaffVisible() && <StackedField label='Selected Staff:' paddingTop = '16px'>
                    <div className = {styles.selectedStaffText}>{selectedStaff ?  selectedStaff?.value : 'None Selected'}</div>
                    <EvvButton type='secondary' styleOverride={isHandheld ? {fontSize: '20px', width: ''} : {fontSize: '20px', width: '60%'}} onClick = { handleStaff } >
                        {!selectedStaff ? <><div className = {styles.buttonText}><AddIcon className = {styles.icons}/>Assign Staff</div></> : <div className = {styles.buttonText}><RefreshRoundedIcon className = {styles.icons}/>Replace Staff</div>}
                    </EvvButton>
                </StackedField>}
            </div>
        );
    };

    const renderForm = () => {
        return isPageReadOnly()
            ? renderViewForm()
            : renderEditForm()
    };

    module.onSave = handleSave;

    return (
        currentPage && currentPage.name === 'Signatures' && <div id='box' data-testid='signaturePage' className={styles.signaturePage + ' ' + (hide ? styles.hide : '')}>
            { renderIssueBox() }
            <div className={isHandheld ? styles.widthSignaturePageHandheld : styles.widthSignaturePage}>
                {renderForm()}
            </div>

            {showStaffChooser &&
                <AssignStaff
                    showStaffChooser = { showStaffChooser }
                    setShowStaffChooser = { setShowStaffChooser }
                    setSelectedStaff = { setSelectedStaff }
                    user = { user }
                    documentController = { documentController }
                    serviceDocument = { serviceDocument }
                    externalSignatures = { externalSignatures }
                />}
        </div>
    );
}

export default SignaturePage;
