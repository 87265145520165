import {evvRepository} from '../../db/evv';
import {DateTime} from "luxon";
import {appCache} from "../../cache/slices/app/appSlice";
import {getDispatchFromConfig} from "../utils/miscUtils";

const OAUTH_USER_NAME = 'evv-login';
let hostname = window.location.hostname;

export const CONTENT_TYPE = {
    FORM_ENCODED_DATA: 'application/x-www-form-urlencoded',
    JSON_DATA: 'application/json'
}

export const DEFAULT_CONFIG = {
    contentType: CONTENT_TYPE.JSON_DATA,
    parseResponse: true,
    ignoreToken: false
}

export function handleErrors(response){
    if (!response.ok && response.status >= 500){
        throw response;
    }

    // Too many requests
    if (!response.ok && response.status === 429){
        throw response;
    }
    // if (!response.ok && (response.status === 400 || response.status === 401)){
    //     throw new Error("Unable to complete call at this time.");
    // }

    return response;
}

let refreshInProgress = false;

export const isTokenExpired =() => {
    const expiresAtInMillis = evvRepository.expiresAt;
    let expired = false;

    if (expiresAtInMillis){
        const expiredAt = DateTime.fromMillis(parseInt(expiresAtInMillis));

        expired = expiredAt < DateTime.now();
    }

    return expired;
}

const fetchWithTimeout = (url, config) => {
    const controller = new AbortController();
    const promise = fetch(url, { signal: controller.signal, ...config });
    controller.signal.addEventListener("abort", () => controller.abort());
    const timeout = setTimeout(() => controller.abort(), config.timeout);
    return promise.finally(() => clearTimeout(timeout));
};

const EVV_AUTHORIZATION_OAUTH_TOKEN = '/evv/authorization/oauth/token';
const EVV_AUTHORIZATION_OAUTH_USER = '/evv/authorization/oauth/user';

const refreshToken = (config = {}) => {
    config.data = {
        grant_type: 'refresh_token',
        refresh_token: config.refreshToken
    };

    console.log("GET TOKEN FROM REFRESH....");

    return authenticate(config);
}

const login = (config = {}) => {
    config.data = {
        grant_type: 'password',
        username: config.loginData.userName,
        account: config.loginData.account,
        password: config.loginData.password
    };

    // config.timeout = 15000;

    console.log("GET TOKEN FROM LOGIN....");

    return authenticate(config);
}

const authenticate = (config = {}) => {
    config.contentType = CONTENT_TYPE.FORM_ENCODED_DATA;
    config.basic = {
        userName: OAUTH_USER_NAME,
        password: ''
    };

    return api.post(EVV_AUTHORIZATION_OAUTH_TOKEN, config)
        .then(tokenResult => {
            console.log(tokenResult);
            if (tokenResult.error){
                if (api.isTokenInvalid(tokenResult)) {
                    evvRepository.accessToken = null;
                    evvRepository.refreshToken = null;
                    evvRepository.expiresAt = 0;
                    refreshInProgress = false;
                    throw tokenResult;
                }
                return tokenResult;
            }else {
                let expiresAt = DateTime.now().plus({seconds: tokenResult.expires_in});
                console.log("TOKEN EXPIRES IN: " + tokenResult.expires_in);
                console.log("TOKEN EXPIRES AT: ");
                console.log(expiresAt);
                evvRepository.accessToken = tokenResult.access_token;
                evvRepository.refreshToken = tokenResult.refresh_token;
                evvRepository.expiresAt = expiresAt.toMillis();
                console.log("Before user call");
                console.log('AccessToken: ' + evvRepository.accessToken);
                console.log('RefreshToken: ' + evvRepository.refreshToken);

                refreshInProgress = false;
                return api.post(EVV_AUTHORIZATION_OAUTH_USER, {contentType: null})
                    .then(userFromServer => {
                        console.log("After user call");
                        console.log('AccessToken: ' + evvRepository.accessToken);
                        console.log('RefreshToken: ' + evvRepository.refreshToken);
                        const user = userFromServer || {};
                        return {...user, tokenResult};
                    });
            }
        })
        .catch(response => {
            console.log("Error calling token");
            console.log(response);
            return Promise.reject(response);
        });
}

function fetchOrRefresh(url, config = {}) {
    const finalConfig = { ...DEFAULT_CONFIG, ...config };

    if (!finalConfig.headers) {
        finalConfig.headers = {};
    }

    if (finalConfig.contentType) {
        finalConfig.headers['Content-Type'] = finalConfig.contentType;
    }

    if (finalConfig.basic) {
        const encodedAuth = btoa(unescape(encodeURIComponent(`${finalConfig.basic.userName}:${finalConfig.basic.password}`)));
        finalConfig.ignoreToken = true;
        finalConfig.headers.authorization = `Basic ${encodedAuth}`;
    }

    if (finalConfig.ignoreToken !== true) {
        if (hostname.startsWith("mobile")) {
            if (refreshInProgress) {
                return new Promise((resolve, reject) => {
                    function loopingTimeout() {
                        if (refreshInProgress) {
                            setTimeout(loopingTimeout, 200)
                        } else {
                            doFetch(url, finalConfig)
                                .then(result => {
                                    resolve(result);
                                })
                                .catch(error => {
                                    reject(error);
                                })
                        }
                    }

                    loopingTimeout();
                });
            }
        } else {
            if (refreshInProgress) {
                return new Promise((resolve, reject) => {
                    function loopingTimeout() {
                        if (refreshInProgress) {
                            setTimeout(loopingTimeout, 200)
                        } else {
                            doFetch(url, finalConfig)
                                .then(result => {
                                    resolve(result);
                                })
                                .catch(error => {
                                    reject(error);
                                })
                        }
                    }

                    loopingTimeout();
                });
            } else if (isTokenExpired()) {
                refreshInProgress = true;
                const refreshTokenConfig = {
                    store: finalConfig.store,
                    dispatch: finalConfig.dispatch,
                    refreshToken: evvRepository.refreshToken
                };
                return refreshToken(refreshTokenConfig)
                    .then(user => {
                        return doFetch(url, finalConfig);
                    })
                    .catch(response => {
                        if (api.isTokenInvalid(response)) {
                            const dispatch = getDispatchFromConfig(config);
                            dispatch(appCache.showGlobalAlertDialog({
                                dialogId: "REFRESH_TOKEN_FAILED",
                                handleClose: (okClicked, dispatch) => {
                                    evvRepository.accessToken = '';
                                    evvRepository.refreshToken = '';
                                }
                            }));
                            return true;
                        }
                        refreshInProgress = false;
                        console.log("Error refreshing token");
                        console.log(response);
                        return Promise.reject(response);
                    });
            }
        }


    }

    return doFetch(url, finalConfig);
}

function doFetch(url, config={}) {
    const fetchToCall = config.timeout > 1000 ? fetchWithTimeout : fetch;
    if (config.ignoreToken !== true){
        const token = evvRepository.accessToken;

        if (token) {
            config.headers.authorization = `Bearer ${token}`;
        }
    }


    return fetchToCall(url, config)
        .then(handleErrors)
        .then((res) => res.text())
        .then((data) => {
            return Promise.resolve(
                data ? (config.parseResponse ? JSON.parse(data) : data) : null
            );
        }).catch(error => {
            console.log("error on fetch in api.js");
            console.log(error);
            return Promise.reject(error);
        });
}

function createBody(config) {
    let body = '';

    if (config.createBody){
        body = config.createBody(config);
    } else if (config.data){
        for (const propertyName in config.data) {
            body += `${body.length > 0 ? '&' : ''}${propertyName}=${config.data[propertyName]}`;
        }
    } else if (config.json){
        body = JSON.stringify(config.json);
    } else if (config.formData){
        body = config.formData;
    }

    return body;
}

function post(url, config = {}){
    return fetchOrRefresh(url, {
        method: 'POST',
        body: createBody(config),
        ...config
    });
}

function get(url, config){
    return fetchOrRefresh(url, config);
}

const LOCAL_DOMAINS = ["localhost", "127.0.0.1", ''];
function isLocal(){
    return LOCAL_DOMAINS.includes(window.location.hostname);
}

let connectionLost = false;
function isConnectionLost(errorResponse) {
    console.log("IsConnectionLost");
    console.log(errorResponse);

    if (errorResponse instanceof Response) {
        console.log("Headers");
        console.log(errorResponse.headers);

        if (isLocal() && !errorResponse.ok && errorResponse.status === 500) {
            connectionLost = true;
        } else if (isLocal() && !errorResponse.ok && errorResponse.status === 504) {
            // Gateway timeout - connection is lost
            connectionLost = true;
        } else {
            connectionLost = false;
        }
    } else if (errorResponse instanceof Error) {
        connectionLost = true;
    }

    return connectionLost;
}

function throwIfErrorResponse(errorResponse) {
    if (errorResponse && errorResponse.errors) {
        if (errorResponse.errors && errorResponse.errors.length > 0){
            console.log("throwIfErrorResponse - found errors");
            console.log(errorResponse);
            throw new Error(errorResponse.errors[0].message);
        }
    } else if (errorResponse instanceof Error) {
        return errorResponse;
    }
}

function isTokenInvalid(errorInstance) {
    return (errorInstance && errorInstance.error === "invalid_token");
}

function isAuthenticated(){
    return evvRepository.accessToken && evvRepository.accessToken.length > 0;
}

function isOfflineAtLogin(){
    return !isAuthenticated();
}

const api = {
    post,
    get,
    login,
    refreshToken,
    isLocal,
    isConnectionLost,
    isOfflineAtLogin,
    throwIfErrorResponse,
    isTokenInvalid,
    isAuthenticated,
    createBody,
    fetchWithTimeout
};

export default api;