import {Checkbox, withStyles} from "@material-ui/core";
import { PRIMARY_COLOR } from "../../constants";

const EvvCheckBox = withStyles({
    root: {
        width: '50px',
        height: '40px',
        color: '#a1a1a1',
        '&$checked': {
            color: PRIMARY_COLOR,
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default EvvCheckBox;