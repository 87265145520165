import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    inputContainer: {
        width: '100%',
        height:'100%',
        padding: '2px',
        borderRadius: '4px',
        backgroundColor: "#ffffff",
        borderStyle: "solid",
        borderWidth: '1px',
        borderColor: "#afafb3",
        
    },
    inputContainerDisabled: {
        backgroundColor:'#e0e0e0'
    },
    input: {
        width: '100%',
        outline: 'none',
        paddingLeft: '10.5px',
        paddingTop: '10.5px',
        paddingBottom: '9.625px',
        backgroundColor: "#ffffff",
        borderStyle: "none",
        borderWidth: 0,
        borderColor: "#ffffff",
        fontSize: '16px',
        fontWeight: "normal",
        fontStyle: "normal",
        letterSpacing: '.21px',
        color: "#888888",
        '&:disabled':{
            color: "#4f4f4f"
        },
        '&::placeholder': {
            color: "#A8A8A8"
        }
    }
}));

export default function TextAreaComponent(props){
    const {id, placeholder, type, onChange, value, required, defaultValue, disabled, readOnly, maxCharacters, minCharacters, onClick, onInput, inputMode, pattern,rows,cols} = props;
    const styles = useStyles();

    return (
            <div className={styles.inputContainer + (disabled ? ' ' + styles.inputContainerDisabled : '')}>
                <textarea
                data-testid={id}
                id={id}
                disabled={disabled}
                readOnly={readOnly}
                required={required}
                autoComplete="off"
                className={styles.input}
                type={type ? type : 'text'}
                placeholder={placeholder}
                onChange = {onChange}
                onClick = {onClick}
                onInput = {onInput}
                defaultValue={defaultValue}
                value={value ? value : ''}
                maxLength={maxCharacters}
                minLength={minCharacters}
                inputMode={inputMode}
                pattern={pattern}
                autoCorrect="off"
                autoCapitalize="none"
                rows={rows}
                cols={cols}
                />
            </div>
    );
}