import { DateTime } from "luxon";
import syncService from "../../../common/services/syncService";
import { convertToJSDate, convertToLuxonDate, dateTimeZoneToString } from "../../../common/utils/formatUtils";

export const checkAllMandatoryFun = (checkAllMandatory, checkMandatory,checkNext, moduleId) => {
    if(checkMandatory && checkNext) {
        checkAllMandatory.forEach((obj) => {
            if(obj.moduleId === moduleId) {
                obj.mandatory = true;
                obj.nextClicked = true;
            }
        })
        return null;
    }

}

export const updateSignatures = (params) => {
    let { serviceDocument, formData, signaturePadCanvas, noOfSignaturePad, user, documentController, call, signPadIndex, staffSignDate, externalSignatures, selectedStaff, clientProgram, extSigNeededDoc } = params;
    let signature = {
        externalSignatures: externalSignatures ? externalSignatures : []
    };
 
    if(call === "add") {
        signaturePadCanvas.current.forEach((canvas, index) => {
            if(formData[`sign${index}`]) {
                canvas.signature = canvas.toDataURL("image/png");
            }
        });
        signature = updateExternalSignature(signaturePadCanvas, signature, noOfSignaturePad, formData, call, extSigNeededDoc, serviceDocument);
    } else if (call === "clear") {
        if (signaturePadCanvas.current[signPadIndex] && externalSignatures) {
            if (externalSignatures[signPadIndex-1]?.signature !== undefined) {
                signaturePadCanvas.current[signPadIndex].signature = undefined;
                if(extSigNeededDoc) {
                    externalSignatures[signPadIndex-1].signature = "";
                } else {
                    signature.externalSignatures.splice(signPadIndex - 1, 1);
                }
            }
        }
    }

    if(!extSigNeededDoc) {
        signature['nextStaffId'] = selectedStaff;
    }

    signature['signedBy'] = user.staffId;
    signature['signedDate'] = staffSignDate;
    serviceDocument['clientProgramId'] = clientProgram ? clientProgram?.clientProgramId : null
    serviceDocument['signature'] = signature;
    documentController.serviceDocument = serviceDocument;
    return signature;
};

export const updateExternalSignature = (signaturePadCanvas, signature, noOfSignaturePad, formData, callFrom, extSigNeededDoc, serviceDocument) => {
    if (callFrom === 'add') {
        signature.externalSignatures = [];
    }
 
    for (let index = 1; index <= noOfSignaturePad; index++) {
        let sig = signaturePadCanvas?.current[`${index}`]?.signature;

        if (extSigNeededDoc) {
            signature = extSigNeededDocUpdate(index, formData, serviceDocument, sig, signature, callFrom);
        } else {
            if (sig !== undefined) {
                let extSig = {
                    name: formData?.[`externalName${index}`],
                    signature: sig.substring(sig.indexOf(',') + 1),
                    signedDate: dateTimeZoneToString(getSignedDate())
                };

                if (callFrom === 'add') {
                    signature.externalSignatures[index - 1] = extSig;
                } else {
                    signature.externalSignatures.push(extSig);
                }
            }
        }
    }
 
    return signature;
}

export const externalSignDate = (externalSignature, index) => {
    let extSignDate;
    if(externalSignature) {
        extSignDate = externalSignature[index-1]?.signedDate;
    }
    return extSignatureFun(extSignDate);
}

export const staffSignatureFun = (documentController, signature, user, staffSignDate, staffSignature) => {
    if(staffSignature) {
        signature['signedBy'] = user.staffId;
        signature['signedDate'] = staffSignDate ? staffSignDate : dateTimeZoneToString(documentController.signedDate);
    }
}

export const extSignatureFun = (extSignDate) => {
    return extSignDate ? extSignDate : dateTimeZoneToString(DateTime.now());
}

export const getSignedDate = () => {
    let targetTime = new Date();
    //get the timezone offset from local time in minutes
    let tzDifference = targetTime.getTimezoneOffset();
    //convert the offset to milliseconds, add to targetTime, and make a new Date
    let offsetTime = new Date(targetTime.getTime() + (tzDifference * 60 * 1000));
    return convertToLuxonDate(offsetTime);
}

export const convertUTCDateToLocalDate = (date) => {
    let jsDate = convertToJSDate(date);
    let localDate = new Date(jsDate.getTime() - (jsDate.getTimezoneOffset() * 60 * 1000));
    return convertToLuxonDate(localDate);
}

export const staffSignatureReadOnly = (appointment, setDisableSign) => {
    if(appointment) {
        if(appointment[0]?.status === "In Session") {
            setDisableSign(true);
        } else {
            setDisableSign(false);
        }
    }
};

export const hasAppointment = async(appointmentId) => {
    let appointment = await syncService.appointmentService.checkHasAppointment(appointmentId);
    return appointment;
}

export const updateNextStaffFun = (serviceDocument, documentController, externalSignatures, evt) => {
    let signature = {
        externalSignatures: externalSignatures ? externalSignatures : []
    }
    signature['nextStaffId'] = evt;
    serviceDocument['signature'] = signature;
    documentController.serviceDocument = serviceDocument;
    return signature;
}

export const updateProgramFun = (serviceDocument, documentController, program) => {
    serviceDocument['clientProgramId'] = program ? program?.clientProgramId : null
    documentController.serviceDocument = serviceDocument;
}

export const extSigNeededDocUpdate = (index, formData, serviceDocument, sig, signature, callFrom) => {
    let extSig;
    let extSigNeeded = {
        name: formData?.[`externalName${index}`] ? formData?.[`externalName${index}`] : '',
        signature: sig ? sig.substring(sig.indexOf(',') + 1) : '',
        signedDate: '',
        unableReason: formData?.[`reason${index}`] ? formData?.[`reason${index}`] : '',
        unableToCollectYN: formData?.[`unwilling${index}`] ? (formData?.[`unwilling${index}`] === "Yes" ? "Y" : "N") : 'N',
        docSignaturePadId: serviceDocument?.docSignaturePadList[index - 1]?.id
    };

    if (sig !== undefined && extSigNeeded?.unableToCollectYN === "N") {
        extSig = {
            name: formData?.[`externalName${index}`],
            signature: sig.substring(sig.indexOf(',') + 1),
            signedDate: dateTimeZoneToString(getSignedDate())
        };
        extSig = {...extSigNeeded, ...extSig};
    } else {
        extSig = {...extSigNeeded};
    }

    if (callFrom === 'add') {
        signature.externalSignatures[index - 1] = extSig;
    } else {
        signature.externalSignatures.push(extSig);
    }

    return signature;
}

export const disableSaveButton = (serviceDocument, setSaveDisable, noOfSignaturePad) => {
    let extSign = serviceDocument?.signature?.externalSignatures;
    let saveEnabled = false;

    for (let index = 0; index < noOfSignaturePad; index++) {
        if (extSign[index]?.unableToCollectYN === "Y") {
            if (!extSign[index]?.unableReason) {
                saveEnabled = false;
                break;
            }
            saveEnabled = true;
        } else if (extSign[index]?.name && extSign[index]?.signature) {
            saveEnabled = true;
        }
    }
    setSaveDisable(!saveEnabled);
}