export const pushServiceDocument = (isHandheld, newDocument, history) => {
    if (isHandheld){
        if (newDocument?.docSignaturePadList) {
            history.push({
                pathname: '/dashboard/facesheet/documents/serviceDocumentExternalSignNeeded',
                state: { serviceDocument: newDocument}
            });
        }
        else if (!newDocument?.documentStatus) {
            history.push({
                pathname: '/dashboard/facesheet/documents/serviceDocumentPdf',
                state: { serviceDocument: newDocument}
            });
        } else {
            history.push({
                pathname: '/dashboard/facesheet/documents/serviceDocument',
                state: { serviceDocument: newDocument}
            });
        }
    } else {
        history.push({
            pathname: '/dashboard/facesheet',
            state: { serviceDocument: newDocument }
        });
    }
}