export const  setValue = (fieldDescriptor, newValue, dateValue) => {
    let result = {
        dateValue : dateValue,
        fieldDescriptor : fieldDescriptor
    };
    if(newValue) {
            fieldDescriptor.dateChanged = true;
            result.fieldDescriptor.dateValue = newValue;
            let date = `${newValue.toLocaleString('en-US', { year: 'numeric', month:'2-digit',day:'2-digit' })}`;
            result.dateValue = date;
    }
    return result;
 }
