import { DateTime } from 'luxon';
import React from 'react'
import PackedField from '../../../common/components/PackedField'
import { defaultDateAsString, defaultTimeAsString, formatActivity, getFormattedDuration } from '../../../common/utils/formatUtils'
import syncService from "../../../common/services/syncService";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";

export const VisitVerificationCommonDetails = (props) => {
  const activities = useSelector(syncService.activityService.getResults());
  const [activity, setActivity] = useState({}); 

  const currentVisit = props.currentVisit;
  let startDateTimeAsDate, endDateTimeAsDate;
  endDateTimeAsDate = DateTime.fromMillis(currentVisit.endDateTime);
  if (currentVisit.originalStartDate) {
      startDateTimeAsDate = DateTime.fromMillis(currentVisit.startDateTime);
  } else {
    startDateTimeAsDate = DateTime.fromMillis(currentVisit.appointment.startDateInstance.ts);
  }

  useEffect(() => {
            if(activities && activities.length > 0 ) {
                const objActivity = activities.find(a => a.activityId === currentVisit.appointment.activityId);
                setActivity(objActivity);
            }
            // eslint-disable-next-line
        }, [activities]);

  return (
    <div>
        <PackedField label='Activity:' value={formatActivity(activity)} paddingLeft="65px"/>
        <PackedField label='Date:' value={defaultDateAsString(startDateTimeAsDate) === defaultDateAsString(endDateTimeAsDate) ? defaultDateAsString(startDateTimeAsDate) : defaultDateAsString(startDateTimeAsDate) + " - " + defaultDateAsString(endDateTimeAsDate) } paddingLeft="88px" />
        <PackedField label='Start Time:' value={defaultTimeAsString(startDateTimeAsDate)} paddingLeft="45px" />
        <PackedField label='Stop Time:' value={defaultTimeAsString(endDateTimeAsDate)} paddingLeft="46px" />
        <PackedField label='Duration:' value={getFormattedDuration(startDateTimeAsDate, endDateTimeAsDate)} paddingLeft="57px" />
    </div>
  )
}
