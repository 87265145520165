export const getClientImage = (client) => {

    const avatarEmpty = "/images/iconAvatarEmpty.svg";
  
    if (client?.picture?.length > 0) {
      return "data:image/png;base64," + client.picture;
    }
  
    return avatarEmpty;
    
  };
  