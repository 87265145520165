import LoginTextField from "../../../common/components/LoginTextField";
import React from "react";
import FormField from "./FormField";
import StackedField from "../../../common/components/StackedField";
import { Grid } from "@material-ui/core";
import DisplayErrorMessages from "../common/DisplayErrorMessages";
import { assignDbSavedValue } from "../common/FormFieldsUtils";
import { getXSAndSMValueForField } from "../common/Util";

class Address extends FormField{
    setAddressFieldAttribute = (addressField, isRequired, fieldId) => {
        addressField.forEach((textField, index) => {
            textField.id = `${fieldId}-text${index}`;
            if(textField.name === "Street 1") {
                textField.required = isRequired;
            }
        })
    }

    getValue = (index) => {
        let dbValueArray = this.fieldDescriptor?.dbSavedValue;
        if (dbValueArray) {
            this.fieldDescriptor = assignDbSavedValue(this.fieldDescriptor, dbValueArray);
        }
        return this.fieldDescriptor.options[index].value;
    }

    textFieldTemplate = (props, textField, disabled, index) => {
        let breakPoints = getXSAndSMValueForField(props.isHandheld, props.orientation, 12, 6);
        return (
            <Grid  item = { true } xs={breakPoints.xsValue} sm={breakPoints.smValue}>
                <StackedField paddingBottom="6px">
                    <LoginTextField
                        {...props}
                        id={textField.id}
                        name={textField.name}
                        value={ this.getValue(index) }
                        onChange={(event) => { props.onChange(event, this.fieldDescriptor, props); } }
                        placeholder={disabled ? ' ': textField.placeholder}
                        required={textField.required}
                        maxCharacters={textField.maxCharacters} 
                        disabled = { disabled }
                    />
                </StackedField>
            </Grid>
        )
    }

    renderField(props, state){
        let isRequired = this.isMandatory();
        this.setAddressFieldAttribute(this.fieldDescriptor.options, isRequired, this.fieldDescriptor.id);

        props.fieldDescriptor = this.fieldDescriptor;
        return (
            <div>
                {this.fieldDescriptor?.businessRuleApplied !== "Hide" &&
                <div>
                {
                    this.fieldDescriptor.options.map((textField, index) => {
                        return (
                            <div key = {index}>
                                 {this.textFieldTemplate(props, textField, state.isReadOnlyDoc, index)}
                            </div>
                        )
                    })
                }
                {
                    <div>
                        <DisplayErrorMessages
                            {...props}
                        />
                    </div>
                }
                </div>
                }
            </div>
        )
    }
}

export default Address;