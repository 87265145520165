import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector, useStore} from "react-redux";
import {formatDateWithTime, getOrderByProperty} from "../../../common/utils/tableUtils";
import {sortWithDirection, uniqueScalarsByProperty} from "../../../common/utils/miscUtils";
import syncService from "../../../common/services/syncService";
import {useEffect, useState} from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import FilterBar from "../../../common/components/FilterBar";
import DataTable from "../../../common/components/DataTable";
import ColorStatusCell from "../../../common/components/ColorStatusCell";
import {
    colorForStatus,
    convertServiceDocumentsToClientDocuments,
    convertServiceDocuments,
    EMPTY_STATUS_FILTER,
    createNewDocument,
    hasMaxDocsOpened,
    tabDocAddAction
} from "../../../common/utils/documentUtils";
import EvvButton from "../../../common/components/EvvButton";
import {documentCache} from "../../../cache/slices/document/documentCache";
import DocumentChooserDialog from "../../forms/DocumentChooserDialog";
import KebabCell from "../KebabCell";
import ServiceDocumentWizardPage from "../ServiceDocumentWizardPage";
import {clientCache} from "../../../cache/slices/client/clientSlice";
import {appCache} from "../../../cache/slices/app/appSlice";
import { cloneDeep } from "lodash/lang";
import NewDocuments from "./NewDocuments";
import AlertDialog from "../../../common/components/AlertDialog";
import { MAX_OPEN_DOCUMENTS, PRIMARY_COLOR } from "../../../common/constants";
import ServiceDocumentWizardPagePdf from "../ServiceDocumentWizardPage/ServiceDocumentWizardPagePdf";
import { orderBy } from "lodash";
import { SERVICE_DOCUMENT_STATUS_SIGNED } from "../../forms/serviceDocumentUtils";
import ServiceDocumentWizardPageExternalSignNeeded from "../ServiceDocumentWizardPage/ServiceDocumentWizardPageExternalSignNeeded";

const useStyles = makeStyles(() => ({
    documentsTab: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column'
    },
    documentsHeader: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: '1px solid #c1c1c1'
    },
    newDocumentButton: {
        width: '220px',
        paddingTop: '16px',
        paddingRight: '17px',
        paddingBottom: '13px'
    },
    loadingSpinnerContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    loadingSpinner: {
        color: PRIMARY_COLOR
    },
    noDocumentsFoundContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    noDocumentsFound: {
        fontSize: '18px',
        fontWeight: "bold",
        fontStyle: "normal",
        color: "#000000",
        paddingTop: '56px'
    },
    serviceDocumentWizardPage: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    newDocumentsPage: {
        width: '100%',
        height: '100%',
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column'
    }
}));

const renderNameCell = (row) => {
    return (<div style={{fontWeight: 'bold'}}>{row.description}</div>);
}

const renderStatusCell = (row) => {
    return (<ColorStatusCell status={row.status} selectColor={colorForStatus} />);
}

const secondaryOrderByProperties = [
    (a) => a.status ? a.status : 0
]

const secondaryOrderByDirections = ['asc'];

function createFilterOptions(clientDocuments){
    return [EMPTY_STATUS_FILTER, ...uniqueScalarsByProperty(clientDocuments, 'status').sort()];
}

export default function DocumentsTab({forceHide, internalCall = false, selectedTab}) {
    const selectedDocTabGlobal = useSelector(documentCache.getSelectedDocTabGlobal);
    let docs = useSelector(syncService.documentService.getResults());
    const crosswalkedServiceDocuments = useSelector(documentCache.getCrosswalkedDocuments);
    const serviceDocuments = useSelector(syncService.serviceDocumentService.getResults());
    const [documents, setDocumentCollection] = useState([]);
    const[loadingDocuments, setLoadingDocuments] = useState(true);
    const isLoading = useSelector(syncService.serviceDocumentService.isLoading());
    const [clientDocuments, setClientDocuments] = useState([]);
    const [filteredDocuments, setFilteredDocuments] = useState(documents);
    const [statusFilter, setStatusFilter] = useState(EMPTY_STATUS_FILTER);
    const [showDocumentChooser, setShowDocumentChooser] = useState(false);
    // eslint-disable-next-line
    const [serviceDocument, setServiceDocument] = useState(null);
    const [clickedRow, setClickedRow] = useState(null);
    const dispatch = useDispatch();
    const styles = useStyles();
    const client = useSelector(clientCache.getSelectedClient);
    const store = useStore();
    const user = useSelector(appCache.getUser);
    const staff = user.staff;
    let newDocument = {};
    const appointmentDocument = useSelector(documentCache.getCurrentAptDocument);
    const [documentForWizard, setDocumentForWizard] = useState(null);
    const serviceDocTab = cloneDeep(useSelector(documentCache.getServiceDocumentsForTabs));
    const [alertDialogConfig, setAlertDialogConfig] = useState(null);

    useEffect(() => {
        if (clientDocuments && clientDocuments.length > 0) {
            syncService.auditService.saveAuditEvent(null, client, user, "EVV Client Documents", "View");
        }

        if(forceHide){
            handleHideDocument(true); 
        }
    }, [clientDocuments, client, user, forceHide]);

    useEffect(() => {
        if (serviceDocTab && serviceDocTab.length > 1 && serviceDocTab[1].clientId !== Number(client.clientId)) {
            dispatch(documentCache.setServiceDocumentsForTabs( [{description:'All Documents'}] ) );
        }
        // eslint-disable-next-line
    }, []);
    
    useEffect(() => {
        if (crosswalkedServiceDocuments && crosswalkedServiceDocuments.length === 1) {
            const cDocs = convertServiceDocumentsToClientDocuments(crosswalkedServiceDocuments);
            dispatch(documentCache.setCurrentDocument(cDocs[0]));
            dispatch(documentCache.setCurrentData({}));
            setServiceDocument(cDocs[0]);
            if (!documentForWizard && appointmentDocument && appointmentDocument.length > 0) {
                setDocumentForWizard(appointmentDocument[0]);
            }
            dispatch(documentCache.setCrosswalkedDocuments([]));
        }

        if(forceHide){
            handleHideDocument(false); 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [crosswalkedServiceDocuments, forceHide]);

    useEffect(() => {
        if (clientDocuments && clientDocuments.length > 0){
            setFilteredDocuments(clientDocuments.filter(doc => (statusFilter === EMPTY_STATUS_FILTER || doc.status === statusFilter) ));
        } else {
            setFilteredDocuments([]);
        }
    }, [statusFilter, clientDocuments, client]);

    useEffect(() => {
        setClientDocuments(convertServiceDocuments(documents));
    }, [documents]);

    useEffect(() => {
        if(staff && client) {
            setTimeout(() => {
                syncService.documentService.getDocsCollection(client).then((result) => {
                    let signedDocs = result.filter(res => staff.staffId === res.staffId && (!res.documentStatus || res.documentStatus === SERVICE_DOCUMENT_STATUS_SIGNED));
                    let orderedSignedDocs = orderBy(signedDocs, ['serviceDate'], ['desc']);
                    dispatch(documentCache.setSignedDocuments(orderedSignedDocs));
                    setDocumentCollection(result);
                    setLoadingDocuments(false);
                }).catch((error) => {
                    console.log('Error: ', error);
                });
            },1000)
        }
        // eslint-disable-next-line
    },[docs, selectedDocTabGlobal, client, staff]);

    const setClickedRowFn = (row) => {
        setClickedRow(row);
    }

    const renderKebabCell = (row) => {
        return (<KebabCell clientDocument={row} setClickedRowFn = { setClickedRowFn } clickedRow = { clickedRow } setDocumentForWizard = {setDocumentForWizard} />);
    }

    const columnDescriptors = [
        { id: 'description', width: '50%', label: 'Document Name', fieldName: 'description', contentRenderer: renderNameCell},
        { id: 'serviceDate', sort:"desc", width: '25%', label: 'Service Date', fieldName: 'serviceDate', contentRenderer: formatDateWithTime},
        { id: 'status', width: '20%', fieldName: 'status', label: 'Status', contentRenderer: renderStatusCell },
        { id: 'actions', width: '5%', label: 'Actions', disableSort: true, contentRenderer: renderKebabCell }
    ];

    const handleFilterChange = (status) => {
        setStatusFilter(status);
    };

    const handleSort = (records, columnDescriptor, orderByDirection) => {
        return sortWithDirection(
            records,
            [getOrderByProperty(columnDescriptor), ...secondaryOrderByProperties],
            [orderByDirection, ...secondaryOrderByDirections]
        );
    };

    const handleAlertDialogClose = () => {
        setAlertDialogConfig(null);
    }

    const handleAddRequest = () => {
        if (hasMaxDocsOpened(serviceDocTab, client.clientId)) {
            setAlertDialogConfig(MAX_OPEN_DOCUMENTS);
        } else if (serviceDocuments) {
            setShowDocumentChooser(true);
        }
    }

    const handleDocumentSelection = (document) => {
        setShowDocumentChooser(false);

        if (document){
            dispatch(documentCache.setCurrentDocument(document));
            dispatch(documentCache.setCurrentData({}));
            handleSaveNewDocument(document);
            setServiceDocument(document);
        }
    }

    const handleSaveNewDocument = (document) => {
        newDocument = createNewDocument(document, client, staff);
        syncService.documentService.saveNewDocument(newDocument, store, dispatch);
        setServiceDocument(document);
        setDocumentForWizard(newDocument);
        tabDocAddAction(serviceDocTab, newDocument, dispatch);
    }

    const renderDocumentsTable = () => {
        if (isLoading || loadingDocuments) {
            return (
                <div className={styles.loadingSpinnerContainer}>
                    <CircularProgress size={50} className={styles.loadingSpinner} />
                </div>
            );
        } else {
            return (
                <>
                    <div className={styles.documentsHeader}>
                        <FilterBar
                            filterByLabel='Filter By Status'
                            options={createFilterOptions(clientDocuments)}
                            onFilterChange={handleFilterChange}
                        />
                        <div className={styles.newDocumentButton} >
                        <EvvButton styleOverride={{height: '32px'}} className={styles.newDocumentButton} id='DocumentsTab_new_document_button' type='primary' onClick={handleAddRequest} >
                            Create New Document
                        </EvvButton>
                        </div>
                    </div>
                    <DataTable
                        columnDescriptors={columnDescriptors}
                        records={filteredDocuments}
                        handleSort={handleSort}
                    />

                    {(!filteredDocuments || filteredDocuments.length === 0) &&
                    <div className={styles.noDocumentsFoundContainer}>
                        <div className={styles.noDocumentsFound}>
                            No Documents Found
                        </div>
                    </div>
                    }
                </>
            );
        }
    }

    const handleCancel = () => {
        setServiceDocument(null);
    }

    const handleClose = () => {
        setServiceDocument(null);
    }

    const handleSave = (serviceDocumentData) => {
        console.log(serviceDocumentData);
    }

    const handleHideDocument = (validationSuccess) => {
        if(validationSuccess){
            setServiceDocument(null);
        }
    }

    const renderServiceDocumentWizard = () => {
        if (!documentForWizard && serviceDocTab.length > 0) {
            setDocumentForWizard(serviceDocTab[selectedTab]);
        }

        if (documentForWizard && documentForWizard?.docSignaturePadList) {
            return (
                <div className={styles.serviceDocumentWizardPage}>
                    <ServiceDocumentWizardPageExternalSignNeeded onHideDocument={handleHideDocument} />
                </div>
            );
        }
        else if (documentForWizard && !documentForWizard.documentStatus) {
            return (
                <div className={styles.serviceDocumentWizardPage}>
                    <ServiceDocumentWizardPagePdf />
                </div>
            );
        } else {
            return (
                <div className={styles.serviceDocumentWizardPage}>
                    <ServiceDocumentWizardPage onCancel={handleCancel} onSave={handleSave} onClose = { handleClose } documentForWizard={documentForWizard} selectedClient={client} onHideDocument={handleHideDocument}/>
                </div>
            );
        }
    }

    const renderTab = () => {
        if ( (!internalCall && serviceDocTab && serviceDocTab.length === 1) || (serviceDocTab && serviceDocTab.length > 1 && selectedTab === 0) ) {
            return renderDocumentsTable();
        } else if (selectedTab > 0){
            return renderServiceDocumentWizard();
        }
    }

    const renderInternalTabs = () => {
        return(<>
            {!internalCall && serviceDocTab && serviceDocTab.length > 1 && serviceDocTab[1]?.clientId === Number(client?.clientId) &&
                <div className={styles.newDocumentsPage}><NewDocuments /></div>}
        </>)
    }

    return (
        <>
            { renderInternalTabs() }
            {renderTab()}
            {showDocumentChooser &&
                <DocumentChooserDialog documents={crosswalkedServiceDocuments.length > 1 ? crosswalkedServiceDocuments : serviceDocuments} onClose={handleDocumentSelection}  call = "NewDocument" />
            }
            
            { alertDialogConfig &&
                <AlertDialog
                    open={true}
                    dialogTitle={alertDialogConfig.dialogTitle}
                    dialogMessage={alertDialogConfig.dialogMessage}
                    showOkButton={alertDialogConfig.showOkButton}
                    showCancelButton={alertDialogConfig.showCancelButton}
                    okButtonText={alertDialogConfig.okButtonText ? alertDialogConfig.okButtonText : 'Ok'}
                    handleClose={handleAlertDialogClose}
                />
            }
        </>
    );
}
