import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    packedField: {
        display: 'flex',
        paddingBottom: '7px'
    },
    packedFieldLabel: {
        fontSize: '16px',
        fontWeight: "bold",
        fontStyle: "normal",
        lineHeight: '20px',
        letterSpacing: 0,
        color: "#4f4f4f"
    },
    packedFieldValue: {
        fontSize: '16px',
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: '20px',
        letterSpacing: 0,
        color: "#4f4f4f",
        paddingLeft: '17px'
    },
}));

export default function PackedField({label, value, children, paddingLeft}) {
    const styles = useStyles();
    const fieldStyle = paddingLeft ? {paddingLeft: paddingLeft} : {};

    return (
        <div className={styles.packedField} >
            <div className={styles.packedFieldLabel} >
                {label}
            </div>
            <div style={fieldStyle} className={styles.packedFieldValue} >
                {value ? value : children}
            </div>
        </div>
    )
}
