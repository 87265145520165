import { FormControl, FormControlLabel, makeStyles } from "@material-ui/core";
import { TXPLANS_NO_ACTIVE_MSG, TXPLAN_SELECT } from "../../../common/constants";
import { useEffect, useState } from "react";
import { getAppointmentData, getDbFieldValue, getGaFieldById, getGaFieldValue, getTxPlanLabel, isFieldExists, sortTxPlans } from "../../../common/utils/goalsAddressedUtils";
import EvvCheckBox from "../../../common/components/EvvCheckBox";
import { DateTime } from "luxon";
import { dateToStringWithFormat } from "../../../common/utils/formatUtils";
import GAPgoi from "./GAPgoi";

/*
    Logic:
    We need to get all Treatment Plans which are related to particular client & are active - getClientTxPlans()
*/

const  useStyles = makeStyles((theme) => ({
    txPlanCheckbox: {
        fontSize: '16px',
        lineHeight: '14.1px',
        color: '#4F4F4F',
        marginTop: '-13px',
        marginLeft: '-4px'
    },
    selectLabel: {
        marginTop: '20px',
        fontWeight: 'bold',
        marginBottom: '20px'
    },
    noTxPlanMessage: {
        marginTop: '20px',
        fontWeight: "bold"
    },
    checkboxForTablet: {
        padding: "0px !important",
        alignItems: "flex-start !important",
        display: "flex",
        marginTop: "1px"
    },
    labelText: {
        paddingBottom: "20px",
        lineHeight: "1.5"
    }, 
    checkboxForHandheld: {
        alignItems: "flex-start !important",
        paddingBottom: "15px"
    }, 
}))

export default function GaTxPlans (props) {
    const styles = useStyles();
    let isHandheld = props?.documentController.dataProvider.isHandheld();
    const [formField, setFormField] = useState();
    // eslint-disable-next-line
    const [value, setValue] = useState([]);

    let gaTxPlans = props?.module?.gaTxPlans;
    let isReadOnly = props?.isReadOnly;

    useEffect(() => {
        createFormField();
    });

    useEffect(() => {
        getValue();
        // eslint-disable-next-line
    }, [formField]);

    const createFormField = () => {
        if (props?.module?.fields && !isFieldExists(props, "gaTxPlans")) {
            let GATxPlansField = {
                formId: props.module.id,
                id: "gaTxPlans",
                label: '',
                name: "gaTxPlans",
                type: "GA_Checkbox",
                value: [],
                isChanged: false,
                isUpdated: false
            };
            props.module.fields = [ ...props?.module?.fields, GATxPlansField ];
            setFormField(GATxPlansField);
        }
    }

    const getValue = () => {
        if (formField) {
            let fieldValue;
            let field = getGaFieldById(props, formField?.id);
            if (field) {
                if (field.isUpdated) {
                    fieldValue = getGaFieldValue(field, 'multiple');
                } else {
                    fieldValue = getDbFieldValue(field, props, 'multiple');
                }
                setValue(fieldValue);
            }
        }
    }

    const isChecked = (planId) => {
        let checked = false;
        if (formField) {
            let field = getGaFieldById(props, formField?.id);
            let fieldValue = field?.value;
            checked = fieldValue?.includes(planId);
        }
        return checked;
    }

    const renderPgoiMulti = (planList) => {
        let fieldTxPlans = props?.module?.fields?.find(field => field.id === "gaTxPlans");
        if (fieldTxPlans) {
            let selectedTxPlans = [];
            let selectedTxPlanIds = fieldTxPlans?.value;

            selectedTxPlanIds?.forEach((treatmentPlanId) => {
                let plan = gaTxPlans.find(txPlan => Number(txPlan.treatmentPlanId) === Number(treatmentPlanId));
                if (plan) {
                    selectedTxPlans.push(plan);
                }
            });

            if (selectedTxPlans && selectedTxPlans.length > 0) {
                selectedTxPlans = sortTxPlans(selectedTxPlans);

                selectedTxPlans?.forEach((plan, index) => {
                    if (plan) {
                        planList.push(<GAPgoi { ...props } gaTxPlan = { plan } />);
                        if ((index + 1) !== selectedTxPlans.length) {
                            planList.push(<hr key = {`hr-${index}`} style = {{ width:"100%", marginTop:'30px' }}></hr>);
                        }
                    }
                })
            }
        }
        return planList;
    }

    const renderPgoiUi = (planList, gaTxPlans) => {
        planList.push(<div key = { "txplan-main-" + gaTxPlans?.length } className={styles.selectLabel}>{ TXPLAN_SELECT }</div>);
        gaTxPlans?.forEach((plan, index) => {
            planList.push(
                <div key = { 'txplan-sub-' + index } className={ styles.txPlanCheckbox }>
                    <FormControl component = "fieldset" style={isHandheld ? {marginTop:"10px"} : {}}>
                        <FormControlLabel className={isHandheld ? styles.checkboxForHandheld : styles.checkboxForTablet}
                            control = {<EvvCheckBox  className={styles.checkboxForTablet}
                                id = { 'txplan-' + plan?.treatmentPlanId }
                                name = { 'txplan-' + plan?.treatmentPlanId }
                                value = { plan?.treatmentPlanId }
                                checked = { isChecked(plan?.treatmentPlanId.toString()) }
                                disabled = { isReadOnly }
                                onChange = {(event) => { props.onChange('txplan', event, props, formField, setValue); } }
                            />}
                        label = { isHandheld ? <div style={{alignItems:"flex-start"}}>{ getTxPlanLabel(plan) }</div> : <div className={styles.labelText}>{getTxPlanLabel(plan)}</div> }
                        />
                    </FormControl>
                </div>
            );
        });

        planList = renderPgoiMulti(planList);
        return planList;
    }

    const renderPlans = () => {
        let planList = [];
        if (gaTxPlans) {
            if (gaTxPlans?.length === 0) {
                let appointmentData = getAppointmentData(props?.documentController);
                let noActiveDate = appointmentData ? appointmentData?.startDateInstance : DateTime.now();
                let noActiveDateFormatted = dateToStringWithFormat(noActiveDate, 'LL/dd/yyyy');
                planList.push(<div key = { "txplan-main-0" } className={styles.noTxPlanMessage}>{ TXPLANS_NO_ACTIVE_MSG } [{ noActiveDateFormatted }].</div>);
            } else if (gaTxPlans?.length === 1) {
                planList.push(<GAPgoi { ...props } gaTxPlan = { gaTxPlans[0] }/>);
            } else if (gaTxPlans?.length > 1) {
                planList = renderPgoiUi(planList, gaTxPlans);
            }
        }

        return planList;
    }

    return (
        <div>{ renderPlans() }</div>
    );
}