import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector, useStore} from "react-redux";
import {documentCache} from "../../../cache/slices/document/documentCache";
import {cloneDeep} from "lodash/lang";
import ServiceDocumentWizard from "../../forms/ServiceDocumentWizard/ServiceDocumentWizard";
import DataProvider from "../../forms/DataProvider";
import { appCache } from "../../../cache/slices/app/appSlice";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {clientCache} from "../../../cache/slices/client/clientSlice";
import api from "../../../common/services/api";

const useStyles = makeStyles(() => ({
    serviceDocumentPage: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
        display: 'flex'
    }
}));

export default function ServiceDocumentWizardPage({onCancel, onClose, onSave, documentForWizard, selectedClient, onHideDocument}) {
    const serviceDocument = cloneDeep(useSelector(documentCache.getCurrentDocument));
    const currentDocumentId = cloneDeep(useSelector(documentCache.getCurrentDocumentId));
    let appointmentDocument = cloneDeep(useSelector(documentCache.getCurrentAptDocument));
    const serviceDocTab = cloneDeep(useSelector(documentCache.getServiceDocumentsForTabs));
    const evvDocuments = useSelector(documentCache.getEvvDocuments);
    const signedDocuments = useSelector(documentCache.getSignedDocuments);
    const user = useSelector(appCache.getUser);
    const staff = user.staff;
    const isConnectionLost = useSelector(appCache.isOffline);
    const isOffline = api.isOfflineAtLogin() || isConnectionLost;

    if(documentForWizard) {
        appointmentDocument = appointmentDocument?.filter(doc => doc?.id === documentForWizard.id);
    }
    const styles = useStyles();
    const store = useStore();
    const dispatch = useDispatch()
    const history = useHistory();
    const client = useSelector(clientCache.getSelectedClient);

    useEffect(() => {
        if (serviceDocument && serviceDocument.id !== undefined) {
            dispatch(appCache.toggleVisibleFlag(false));
        }
        // eslint-disable-next-line
    }, []);

    const documentController = {
        dataProvider: new DataProvider(store.getState()),
        onClose: onClose,
        onSave: onSave,
        onHideDocument: onHideDocument,
        appointmentDocument,
        client
    };

    return (
        <div id='serviceDocumentWizardPage' data-testid='serviceDocumentWizardPage' className={styles.serviceDocumentPage}>
            <ServiceDocumentWizard
                showNavigator={documentController.dataProvider.isHandheld() ? false : true}
                showHeader={documentController.dataProvider.isHandheld()}
                serviceDocument={serviceDocument}
                documentController={documentController}
                store={store}
                documentId={currentDocumentId}
                documentForWizard={documentForWizard}
                history={history}
                dispatch={dispatch}
                client={client}
                serviceDocTab={serviceDocTab}
                evvDocuments={evvDocuments}
                signedDocuments={signedDocuments}
                staff={staff}
                isOffline={isOffline}
            />
        </div>
    )
}
