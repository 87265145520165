import React, {useEffect, useRef} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useSwipeable} from "react-swipeable";
import {scheduleCache} from "../../../cache/slices/schedule/scheduleCache";
import {useDispatch, useSelector} from "react-redux";
import {boundaries, dayOfWeek} from "../../utils/formatUtils";
import {DateTime} from "luxon";
import { PRIMARY_COLOR } from "../../constants";

const dayOfMonth = {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: "normal",
    fontStyle: "normal",
    letterSpacing: 0,
    textAlign: "center",
    // backgroundColor: "#f6f6f6",
    color: "#515151",
    paddingTop: '5px',
    paddingBottom: '5px'
};

const currentDay = {
    position: 'absolute',
    width: '24px',
    height: '24px',
    background: PRIMARY_COLOR,
    borderRadius: '50%'
};

const useStyles = makeStyles((theme) => ({
    miniCalendar: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
        [theme.breakpoints.down('sm')]: {
            backgroundColor: "#f6f6f6",
            borderLeft: '1px solid #979797',
            borderRight: '1px solid #979797'
        },
        [theme.breakpoints.up(601)]: {
            height: '100%',
            backgroundColor: "#ffffff"
        }
    },
    dayHeader: {
        fontSize: '13px',
        fontWeight: "bold",
        fontStyle: "normal",
        textAlign: "center",
        color: "#868686"
    },
    dayOfMonthSelected: {
        ...currentDay
    },
    dayOfMonth: dayOfMonth,
    dayOfMonthAdjacent: {
        ...dayOfMonth,
        [theme.breakpoints.down('sm')]: {
            backgroundColor: "#e5e5e5"
        },
        [theme.breakpoints.up(601)]: {
            backgroundColor: "#f6f6f6"
        }
    },
    dayOfMonthOutOfBounds: {
        color: "#aaaaaa"
    },
    currentDayOfMonthSelected: {
        ...currentDay,
        color: 'white',
        paddingTop: '2px'
    },
}));

const DAYS_AS_INITIALS = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

export default function MiniCalendar({ viewDate, showMonth }){
    const selectedDate = useSelector(scheduleCache.getSelectedDate);
    const dispatch = useDispatch();
    const styles = useStyles();
    const boundaryDates = useRef(null);
    const miniCalendar = useRef();

    useEffect(() => {
        if (!showMonth){
            dispatch(scheduleCache.setViewDate(selectedDate));
        }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showMonth]);

    const handleDayClick = (evt, dayOfMonth) => {
        if (!isOutOfBounds(dayOfMonth)) {
            const now = DateTime.now();
            dispatch(scheduleCache.setSelectedDate(dayOfMonth.startOf('day').plus({hours: now.hour, minutes: now.minute})));
        }
    }

    const renderHeader = () => {
        return DAYS_AS_INITIALS.map((day, dayIndex) => renderDayHeader(day, dayIndex))
    }

    const renderDayHeader = (day, dayIndex) => {
        return (
            <div key={`header_${dayIndex}`} className={styles.dayHeader} >{day}</div>
        );
    };

    const renderCalendar = () => {
        if (showMonth){
            return renderMonth(viewDate);
        }else {
            return renderWeek(viewDate);
        }
    }

    const renderMonth = (anyDayOfTheMonth) => {
        let dayOfMonth = anyDayOfTheMonth.startOf('month');
        let weeks = [];

        while (dayOfMonth.month === anyDayOfTheMonth.month || dayOfWeek(dayOfMonth) > dayOfMonth.day){
            weeks = [...weeks, ...renderWeek(dayOfMonth)];
            dayOfMonth = dayOfMonth.plus({ days: 7 });
        }

        return weeks;
    }

    const renderSelectedDay = (dayOfMonth) => {
        return (
            <>
                <div className={styles.dayOfMonthSelected} />
                <div className={styles.currentDayOfMonthSelected}>{dayOfMonth.day}</div>
            </>
        );
    }

    const isOutOfBounds = (dayOfMonth) => {
        if (!boundaryDates.current){
            boundaryDates.current = boundaries();
        }
        return boundaryDates.current.isDateTimeOutOfBounds(dayOfMonth);
    }

    const renderDay = (dayOfMonth) => {
        let dayClassName = dayOfMonth.month === viewDate.month
            ? styles.dayOfMonth
            : styles.dayOfMonthAdjacent

        if (isOutOfBounds(dayOfMonth)){
            dayClassName += ' ' + styles.dayOfMonthOutOfBounds;
        }

        const isSelectedDate = dayOfMonth.hasSame(selectedDate, 'day');

        return (
            <div key={`day_${dayOfMonth.month}_${dayOfMonth.day}`} className={dayClassName} onClick={evt => handleDayClick(evt, dayOfMonth)} >
                {isSelectedDate ? renderSelectedDay(dayOfMonth) : dayOfMonth.day}
            </div>
        );
    }

    const renderWeek = (anyDayOfTheWeek) => {
        // luxon startOf week is monday so we account for that by considering Sunday (luxon day 7) as the first day of the week
        // and subtract a day after we call startOf so it is Sunday instead of Monday
        let dayOfMonth = anyDayOfTheWeek.weekday === 7 ? anyDayOfTheWeek : anyDayOfTheWeek.startOf('week').minus({ days: 1 });

        return Array.from({length: 7}, () => {
            const day = renderDay(dayOfMonth);
            dayOfMonth = dayOfMonth.plus({ days: 1 });
            return day;
        });
    };

    const config = {
        delta: 100,
        preventDefaultTouchmoveEvent: false,
        trackTouch: true,
        trackMouse: true
    };

    const isMenuSwipe = (eventData) => {
        return miniCalendar.current && eventData && eventData.event && eventData.event.target && miniCalendar.current.contains(eventData.event.target);
    }

    const handleShowNext = (eventData) => {
        if (isMenuSwipe(eventData)) {
            dispatch(scheduleCache.setViewDate(viewDate.plus(showMonth ? {month: 1} : {day: 7})));
        }
    };

    const handleShowPrevious = (eventData) => {
        if (isMenuSwipe(eventData)) {
            dispatch(scheduleCache.setViewDate(viewDate.minus(showMonth ? {month: 1} : {day: 7})));
        }
    };

    const handlers = useSwipeable({
        onSwipedLeft: handleShowNext,
        onSwipedRight: handleShowPrevious,
        ...config,
    });

    const swipeableRefPassThrough = (el) => {
        handlers.ref(el);
        miniCalendar.current = el;
    }

    return (
        <div {...handlers} ref={swipeableRefPassThrough} className={styles.miniCalendar}>
            {renderHeader()}
            {renderCalendar()}
        </div>
    )
}
