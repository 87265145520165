import {makeStyles} from "@material-ui/core/styles";
import MiniCalendar from "../../../common/components/MiniCalendar";
import ScheduleTitleBar from "../ScheduleTitleBar";

const useStyles = makeStyles(() => ({
    scheduleHeader: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: "#f6f6f6"
    },
}));

export default function ScheduleHeader({calendarView, viewDate, selectedDate}) {
    const styles = useStyles();

    return (
        <div className={styles.scheduleHeader}>
            <MiniCalendar viewDate={viewDate}  showMonth={calendarView === 'month'} />
            <ScheduleTitleBar selectedDate={selectedDate} />
        </div>
    )
}
