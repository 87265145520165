import {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import CaseloadCard from "../CaseloadCard";
import TabletCaseloadCard from "../CaseloadCard/TabletCaseloadCard";
import CircularProgress from "@material-ui/core/CircularProgress";
import {sortedByUpperAndLower} from "../../../common/utils/miscUtils";
import {useDispatch, useSelector} from "react-redux";
import {appCache} from "../../../cache/slices/app/appSlice";
import syncService from "../../../common/services/syncService";
import { PRIMARY_COLOR } from "../../../common/constants";
import * as msgConst from '../../../features/forms/common/Messages';

const useStyles = makeStyles((theme) => ({
    caseLoadPage: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '100%'
        },
        [theme.breakpoints.up(601)]: {
            ...theme.shadowedContainer,
        }
    },
    loadingSpinnerContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    loadingSpinner: {
        color: PRIMARY_COLOR
    }
}));

export default function CaseloadPage() {
    const caseloads = useSelector(syncService.caseloadService.getResults()) ;
    const caseloadsLoading = useSelector(syncService.caseloadService.isLoading());
    const clients = useSelector(appCache.getClients);
    const isHandheld = useSelector(appCache.isHandheld)
    const [clientCaseloads, setClientCaseloads] = useState([]);
    const dispatch = useDispatch();
    const styles = useStyles();
    const user = useSelector(appCache.getUser);

    useEffect(() => {
        if(clients && clients.length > 0) {
            syncService.auditService.saveAuditEvent(clients, null, user, "EVV Caseload", "View");
        }
        syncService.caseloadService.fetch({dispatch});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (caseloads && caseloads.length > 0 && clients && clients.length > 0) {
            const clientsForCaseload = [];

            caseloads.forEach(caseload => {
                if (caseload.belongs === true){
                    // eslint-disable-next-line eqeqeq
                    const client = clients.find(c => c.clientId == caseload.clientId);

                    if (client){
                        let clientCaseload = { ...client };
                        clientCaseload.dateLastActivity = caseload.dateLastActivity;
                        clientsForCaseload.push(clientCaseload);
                    }
                }
            });

            setClientCaseloads(sortedByUpperAndLower(clientsForCaseload, ['lastName', 'firstName']) || []);
        }
    }, [caseloads, clients]);

    const renderPage = () => {
        if (caseloadsLoading){
            return (
                <div className={styles.loadingSpinnerContainer}>
                    <CircularProgress size={isHandheld ? 50 : 100} className={styles.loadingSpinner} />
                </div>
            );
        } else {
            if (clientCaseloads && clientCaseloads.length > 0) {
                return clientCaseloads.map((client, index) => {
                    return isHandheld
                            ? <CaseloadCard key={`${client.clientId}_${index}`} client={client}/>
                            : <TabletCaseloadCard key={`${client.clientId}_${index}`} client={client}/>
                    }
                );
            } else {
                return (
                    <div className="noDataStyle">
                        {msgConst.noClientsFound}
                    </div>
                )
            }
        }
    }

    return (
        <div className={styles.caseLoadPage}>
            {renderPage()}
        </div>
    )
}
