import {evvRepository} from "./evv";
import {DateTime} from "luxon";

const isoTextToDateTimeTransformer = (sourceValue) => {
    return sourceValue ? DateTime.fromISO(sourceValue) : null;
}

const beginDateTransformer = {sourceProperty: 'beginDate', transform: isoTextToDateTimeTransformer};
const endDateTransformer = {sourceProperty: 'endDate', transform: isoTextToDateTimeTransformer};
const effectiveDateTransformer = {sourceProperty: 'effectiveDate', transform: isoTextToDateTimeTransformer};

const beginEndDateTransfomers = {
    beginDate: beginDateTransformer,
    endDate: endDateTransformer
}

const beginEndEffectiveDateTransfomers = {
    ...beginDateTransformer,
    effectiveDate: effectiveDateTransformer
}

const transform = (sourceObject, transformerMap) => {
    const targetObject = {};

    for (let sourceProperty in sourceObject){
        const transformer = transformerMap[sourceProperty];

        if (transformer){
            targetObject[transformer.targetProperty ? transformer.targetProperty : transformer.sourceProperty] = transformer.transform(sourceObject[sourceProperty]);
        } else {
            targetObject[sourceProperty] = sourceObject[sourceProperty];
        }
    }

    return targetObject;
};

const getTransformerMap = (targetTable) => {
    switch (targetTable) {
        case 'descriptor':
        case 'allergy':
        case 'medication':
            return beginEndDateTransfomers;
        case 'diagnosis':
            return beginEndEffectiveDateTransfomers;
        case 'appointment':
            return beginEndEffectiveDateTransfomers;
        default:
            break;
    }

    return {};
}

class LookupRepository{
    constructor(tableName){
        this.tableName = tableName;
    }

    get table(){
        return evvRepository.evvDb[this.tableName];
    }

    deleteByPrimaryKey(primaryKey){
        console.log(`Deleteing ${primaryKey} from table [${this.tableName}].`);

        return this.table.delete(primaryKey);
    }

    loadAll(){
        return this.table.toArray()
            .then(rows => this.afterLoad(rows));
    }

    loadAllByProperty(propertyName, propertyValue){
        return this.table.where({[propertyName]: propertyValue}).toArray()
            .then(rows => this.afterLoad(rows));
    }

    loadRecordByProperty(propertyName, propertyValue){
        return this.table.where({[propertyName]: propertyValue}).first()
            .then(rows => this.afterLoad(rows));
    }

    clearAndSave(rows){
        return this.table.clear()
            .then((deletedRecordCount) => {
                console.log("table cleared: " + deletedRecordCount);
                const rowsToSave = this.beforeSave(rows);
                return this.table.bulkPut(rowsToSave)
            })
    }

    saveAll(rows){
        const rowsToSave = this.beforeSave(rows);
        return this.table.bulkPut(rowsToSave);
    }

    saveRecord(record){
        return this.table.put(record)
    }

    afterLoad(rows){
        if (rows && rows.length > 0){
            if (this.tableName === 'appointment'){
                // rows = this.afterLoadAppointments(rows);
            } else {
                const transformerMap = getTransformerMap(this.tableName);
                if (transformerMap && Object.keys(transformerMap).length > 0) {
                    const transformedRows = rows.map(row => transform(row, transformerMap));

                    console.log('LookupRepository.afterLoad: original rows');
                    console.log(rows);
                    console.log('LookupRepository.afterLoad: transformedRows');
                    console.log(transformedRows);

                    rows = transformedRows;
                }
            }
        }

        return rows;
    }

    beforeSave(rows){
        return rows;
    }
}

export default LookupRepository;
