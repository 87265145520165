import {makeStyles} from "@material-ui/core/styles";
import ActiveSessions from "../ActiveSessions";

const useStyles = makeStyles((theme) => ({
    activeSessionsPage: {
        [theme.breakpoints.up(601)]: {
            ...theme.shadowedContainer,
        }
    }
}));

export default function ActiveSessionsPage() {
    const styles = useStyles();

    return (
        <div className={styles.activeSessionsPage}>
            <ActiveSessions />
        </div>
    )
}
