import { useEffect, useState } from "react";
import EvvButton from "../../../common/components/EvvButton";
import CarelogicMobileLogo from "../../../assets/images/carelogic-mobile-logo.png";
import InputForPassword from "../ResetPasswordDialog/InputForPassword";
import * as rdd from "react-device-detect";
import { getDevicePlatformType } from "../../../common/utils/systemUtils";
import LoginPagePingOneCallback from "../LoginPagePingOne/LoginPagePingOneCallback";
import StaffService from "../../../common/services/staffService";
import AlertDialog from "../../../common/components/AlertDialog";
import useStyles from "../../../common/stylesCarelogicMobile";

export default function OfflinePinPage() {
  /**
   * Constant field identifiers for PIN and definitions for 
   * validation error messages and PIN fields.
   */
  const PIN = "pin";
  const CONFIRM_PIN = "confirmPin";
  const MOBILE_PLATFORM = "Mobile";
  const ERROR_MSG_MISSING_PIN = "Please enter a PIN";
  const ERROR_MSG_MISSING_CONFIRM_PIN = "Please re-enter your PIN";
  const ERROR_MSG_DIGITS_REQUIRED_PIN = "PIN must be 4 digits";
  const ERROR_MSG_NOT_MATCHED_PIN = "PIN does not match";
  const ERROR_DIALOG_NETWORK_CONNECTION_ERROR = "ERROR_DIALOG_NETWORK_CONNECTION_ERROR";
  const ERROR_DIALOG_SERVICE_RESPONSE_ERROR = "ERROR_DIALOG_SERVICE_RESPONSE_ERROR";

  /**
   * Applying styles for Handheld device mode
   * based on the detected platform type.
   */
  const isHandheld = (getDevicePlatformType(rdd) === MOBILE_PLATFORM);

  /**
   * Applying styles for the Handheld type device mode,
   * for the other types of devices the original styles will be maintained.
   */
  const containerStyleHandheld = isHandheld ? { height:"643px", width: "375px", background: "none", boxShadow: "none"} : {};
  const headerStyleHandheld = isHandheld ? { paddingTop: "24px" } : {};
  const logoStyleHandheld = isHandheld ? { width: "190px", height: "50px" } : {};

  /**
   * State variables for managing PIN input and validation errors.
   */
  const [pin, setPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const [errors, setErrors] = useState({});
  const [isValidPin, setIsValidPin] = useState(false);
  const [isCompletedSavePin, setIsCompletedSavePin] = useState(false);
  const [alertDialogConfig, setAlertDialogConfig] = useState(null);

  /**
   * Apply styles defined using Material-UI's makeStyles hook.
   * The useStyles function is a custom hook created with Material-UI's 
   * makeStyles function. It generates a set of classes based on the 
   * styles defined in the useStyles hook.
   */
  const styles = useStyles();

  /**
   * useEffect hook for handling pin validation and saving pin offline.
   * This effect triggers when 'isValidPin' or 'pin' change.
   * If 'isValidPin' is true, it calls the 'savePinOffline' function.
   * 'savePinOffline' is responsible for saving the pin offline if it's valid.
   */
  useEffect(() => {
    if (isValidPin) {
      savePinOffline();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidPin, pin]);

  /**
   * This method checks if the device is online, 
   * then calls the staff service to save the pin.
   * 
   * If the pin is successfully saved and the response contains 
   * a valid 'pin' and 'staffId', sets 'isCompletedSavePin' to true.
   * 
   * If there is any error during the process, it logs 
   * the error and shows an appropriate error message to the user.
   */
  const savePinOffline = async () => {
    try {
      if (!window.navigator.onLine) {
        showNetworkConnectionError();
        return;
      }

      const staffService = new StaffService();
      const response = await staffService.callWritePinOffLine(pin);
      if (!response || !response.resources || response.resources.length === 0) {
        showServiceResponseError();
        return;
      }

      const body = response.resources[0];
      const isValid = body?.pin?.trim() && body.staffId !== null;

      if (isValid) {
        setIsCompletedSavePin(true);
      } else {
        showServiceResponseError();
      }
    } catch (error) {
      console.error("Error saving pin offline: ", error);
    }
  };
  
  /**
   * Sets up an alert dialog to inform the user about a network connection error.
   * The handleClose function is invoked when the dialog is closed.
   */
  const showNetworkConnectionError = () => {
    setAlertDialogConfig({
      dialogId: ERROR_DIALOG_NETWORK_CONNECTION_ERROR,
      dialogTitle: "Network connection error",
      dialogMessage: "The device is offline. Please check your network connection and try again.",
      okButtonText: "Close",
      showOkButton: true,
      handleClose: handleDialogClose
    });
  }

  /**
   * Sets up an alert dialog to inform the user about a service response error.
   * The handleClose function is invoked when the dialog is closed.
   */
  const showServiceResponseError = () => {
    setAlertDialogConfig({
      dialogId: ERROR_DIALOG_SERVICE_RESPONSE_ERROR,
      dialogTitle: "Service response error",
      dialogMessage: "A problem occurred with the service response. Please try again later.",
      okButtonText: "Close",
      showOkButton: true,
      handleClose: handleDialogClose
    });
  }

  /**
   * Handles the close event of the alert dialog.
   * This method is responsible for resetting the 'isValidPin' state to false
   * and clearing the 'alertDialogConfig', effectively closing the dialog.
   */
  const handleDialogClose = () => {
    setIsValidPin(false);
    setAlertDialogConfig(null);
  }

  /**
   * Validates the PIN and confirmation PIN fields
   *
   * This function performs validation checks on the provided PIN 
   * and confirmation PIN values.
   * It updates the `errors` state object with appropriate 
   * error messages if validation fails.
   *
   * @param {string} id - The identifier of the input field being 
   * validated (either 'pin' or 'confirmPin').
   * @param {string} value - The current value of the input field 
   * being validated.
   * @returns {boolean} - Returns true if all validations pass.
   */
  const validate = (id, value) => {
    const newErrors = { ...errors };
    if (id === PIN) {
      if (value.length === 0) {
        newErrors.pin = ERROR_MSG_MISSING_PIN;
      } else if (value.length < 4) {
        newErrors.pin = ERROR_MSG_DIGITS_REQUIRED_PIN;
      } else {
        delete newErrors.pin;
      }
    }

    if (id === CONFIRM_PIN) {
      if (value.length === 0) {
        newErrors.confirmPin = ERROR_MSG_MISSING_CONFIRM_PIN;
      } else if (value.length < 4) {
        newErrors.confirmPin = ERROR_MSG_DIGITS_REQUIRED_PIN;
      } else {
        delete newErrors.confirmPin;
      }
    }

    if (pin.length === 4 && confirmPin.length === 4 && pin !== confirmPin) {
      newErrors.match = ERROR_MSG_NOT_MATCHED_PIN;
    } else {
      delete newErrors.match;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  /**
   * Handles the change event for the PIN and confirmation PIN input fields
   *
   * This function is triggered whenever the value of the PIN 
   * or confirmation PIN input fields changes.
   * It updates the respective state variables and performs 
   * validation on the input values.
   *
   * @param {object} event - The event object from the input field change event.
   */
  const handleChange = (event) => {
    const { id, value } = event.target;

    // Ensure the input value consists of 0 to 4 digits only
    if (/^\d{0,4}$/.test(value)) {
      if (id === PIN) {
        setPin(value);
      } else if (id === CONFIRM_PIN) {
        setConfirmPin(value);
      }

      validate(id, value);
    }
  };

  /**
   * Handles the form submission for creating the offline PIN
   *
   * This function is triggered when the user submits the form to create an offline PIN.
   * It prevents the default form submission behavior, validates the PIN and confirmation
   * PIN fields, and updates the error state accordingly. If all validations pass, it 
   * proceeds with the next steps.
   *
   * @param {object} event - The event object from the form submission.
   */
  const handleCreatePinRequest = (event) => {
    event.preventDefault();

    const isPinValid = validate(PIN, pin);
    const isConfirmPinValid = validate(CONFIRM_PIN, confirmPin);

    if (pin.length === 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        pin: ERROR_MSG_MISSING_PIN,
      }));
    }

    if (confirmPin.length === 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmPin: ERROR_MSG_MISSING_CONFIRM_PIN,
      }));
    }

    if (isPinValid && isConfirmPinValid && (pin === confirmPin)) {
      setIsValidPin(true);
    }
  };

  return ( 
    <>
      { isCompletedSavePin ? <LoginPagePingOneCallback /> : 
        <div className={styles.tabletSetOfflinePin}>
          <div className={styles.offlinePageContainer} style={containerStyleHandheld}>
            <div className={styles.header} style={headerStyleHandheld}>
              <img className={styles.logo} src={CarelogicMobileLogo} alt="Carelogic Mobile" style={logoStyleHandheld} />
            </div>
            <div className={styles.content}>
              <div className={styles.text}>Create Offline PIN</div>
              <div className={styles.description}>Enter a 4-digit PIN to use when accessing Carelogic Mobile offline.</div>
              <form className={styles.formContainer} onSubmit={handleCreatePinRequest} noValidate autoComplete="off">
              <div className={styles.spaceBelow}>
                  <div className={styles.textWrapperLabel}>Offline PIN<span className={styles.mandatoryIndicator}>*</span></div>
                  <div className={styles.subline}>PIN must consist of 4 digits.</div>
                  <InputForPassword id="pin" value={pin} placeholder="Enter PIN" type="password" onChange={handleChange}/>
                  {errors.pin && <div className={styles.errorMessage}>{errors.pin}</div>}
                </div>
                <div className={styles.spaceBelow}>
                  <div className={styles.textWrapperLabel}>Confirm Offline PIN<span className={styles.mandatoryIndicator}>*</span></div>
                  <InputForPassword id="confirmPin" value={confirmPin} placeholder="Re-enter PIN" type="password" onChange={handleChange}/>
                  {errors.confirmPin && <div className={styles.errorMessage}>{errors.confirmPin}</div>}
                  {!errors.confirmPin && errors.match && <div className={styles.errorMessage}>{errors.match}</div>}
                </div>
                { isHandheld ? (
                    <div className={styles.spaceBelow} style={{ paddingTop: '90px' }}>
                      <EvvButton id="CreatePinButton" type="primary" onClick={handleCreatePinRequest}>Create PIN</EvvButton>
                    </div>
                ) : (
                      <EvvButton id="CreatePinButton" type="primary" onClick={handleCreatePinRequest}>Create PIN</EvvButton>
                )}
              </form>
            </div>
          </div>
        </div> 
      }
      { alertDialogConfig &&
        <AlertDialog
            open={true}
            dialogTitle={alertDialogConfig.dialogTitle}
            dialogMessage={alertDialogConfig.dialogMessage}
            showOkButton={alertDialogConfig.showOkButton}
            showCancelButton={alertDialogConfig.showCancelButton}
            okButtonText={alertDialogConfig.okButtonText || 'Ok'}
            cancelButtonText={alertDialogConfig.cancelButtonText || 'Cancel'}
            handleClose={alertDialogConfig.handleClose}
        />
      }
    </>
  );
  
}
