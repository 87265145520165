import {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import StackedField from '../../../common/components/StackedField'
import { VisitVerificationCommonDetails } from './VisitVerificationCommonDetails'
import { DateTime } from 'luxon';
import ActivityQualifiers from '../../../common/components/ActivityQualifiers';
import { getActivityQualifiersForActivity, getFilteredStatusesByStartDate, getInSessionVisits } from '../../../common/utils/appointmentUtils';
import { useDispatch, useSelector } from 'react-redux';
import syncService from '../../../common/services/syncService';
import EditTimesDialog from '../EditTimesDialog';
import { cloneVisit, getDuration, handleResumeDocument, hasOverlappingVisitAppointments, resumeDocumentation, setStopAlert, shouldResumeDocumentation, skipVerifySignature } from '../../../common/utils/visitUtils';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import EvvSelect from '../../../common/components/EvvSelect';
import EvvButton from '../../../common/components/EvvButton';
import AlertDialog from '../../../common/components/AlertDialog';
import { Grid } from "@material-ui/core";
import { appCache, setGlobalVisit } from '../../../cache/slices/app/appSlice';
import { getXSAndSMValueForField } from '../../forms/common/Util';
import { ERROR_MESSAGE_FOR_HIDE_VISIT_VERIFICATION_SIGNATURE, PRIMARY_COLOR, QUATERNARY_COLOR, ERROR_MESSAGE_FOR_OTHER_STATUS, HIDE_VISIT_VERIFICATION_SIGNATURE_STATUSES, ALERT_DIALOG_ERROR, MAX_OPEN_DOCUMENTS } from '../../../common/constants';
import { checkIfDocExistsInTab, hasAttachedDocument, hasMaxDocsOpened } from '../../../common/utils/documentUtils';
import { cloneDeep } from 'lodash';
import { documentCache } from '../../../cache/slices/document/documentCache';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const useStyles = makeStyles(() => ({
    dialogPaper: {
        margin: '30px 8px 30px 8px',
        width: '100%',
        height: '100%',
        maxWidth: '90%'
    },
    dialogTitleVV: {
        backgroundColor: PRIMARY_COLOR,
        maxWidth: '100%',
        maxHeight: '100%',
        height: '42px',
        width: 'auto',
        fontSize: '18px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: "#ffffff",
        paddingLeft: '21px',
        display: 'flex',
        alignItems: 'center'
    },
    dialogTitle: {
        backgroundColor: PRIMARY_COLOR,
        maxWidth: '100%',
        maxHeight: '100%',
        height: '42px',
        width: 'auto',
        fontSize: '16px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: "#ffffff",
        paddingLeft: '16px',
        display: 'flex',
        alignItems: 'center'
    },
    dialogContent: {
        padding: "26px 39px 38px 36px",
    },
    dialogContentHandheld: {
        padding: "26px 14px 30px 15px",
    },
    okCancelButtonGroup: {
        padding: "0px 26px 20px 26px",
        justifyContent: 'space-between'
    },
    okCancelButtonGroupHandheld: {
        padding: "0px 16px 26px 16px",
        justifyContent: 'space-between'
    },
    okButton: {
        padding: '0',
        minWidth: '125px'
    },
    cancelButton: {
        padding: '0'
    },
    visitVerificationContent: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    visitInfo: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    topPadding: {
        paddingTop: '3px'
    },
    filterSelect: {
        width: '100%',
        height: '27px',
        paddingTop: '10px',
        borderRadius: '2px',
        background: "transparent",
        borderStyle: "solid",
        borderWidth: '1px',
        borderColor: "#b6b6b6"
    },
    timesButton: {
        fontSize: '16px',
        fontWeight: "normal",
        fontStyle: "normal",
        letterSpacing: 0,
        color: QUATERNARY_COLOR,
        paddingLeft: '0px',
        background: 'transparent',
        border: 0
    },
    signatureContainer:{
        height: '20px',
        minHeight: '20px',
        maxHeight: '20px',
        background: 'transparent'
    },
    signaturePanel:{
        width: "100%",
        minHeight: '160px',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'space-between',
        borderRadius: "3px",
        backgroundColor: "#f6f6f6",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: "#dddddd",
        paddingLeft: '5px',
        paddingBottom: '5px'
    },
    signatureCanvas:{
        margin: '10px',
        borderRadius: "3px",
        backgroundColor: "#f6f6f6",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: "#dddddd"
    },
    clearSignatureButton:{
        width: '130px',
        borderRadius: "3px",
        backgroundColor: "#ebebeb",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: "#dddddd",
        fontSize: '11px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: PRIMARY_COLOR
    },
    relativeErrorMessage : {
        position: 'relative',
        bottom: '-5px',
        fontSize: '13px',
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: '16px',
        letterSpacing: 0,
        color: "#dc0707"
    }
}));

const VisitVerification = ({visit, reasonCodes, onClose, onvisitVerificationNextClick, updatedStatus, appointmentStatusChange, evvState, reasonCodeUi, call, selectedTab}) => {
    const [appointmentStatus, setAppointmentStatus] = useState(visit.appointmentStatus !== undefined ? visit.appointmentStatus : visit?.appointment?.status);
    const [activityDetailDescriptorList, setActivityDetailDescriptorList] = useState(visit.activityDetailDescriptorList ? [...visit.activityDetailDescriptorList] : []);
    const activityQualifiers = useSelector(syncService.descriptorCacheService.activityQualifiersCache.getResults()) || [];   
    const activities = useSelector(syncService.activityService.getResults());
    const [activity, setActivity] = useState({}); 
    const [activityQualifiersForActivity, setActivityQualifiersForActivity] = useState([]); 
    const [showConfirmVisitDialog, setShowConfirmVisitDialog] = useState(false);

    useEffect(() => {
                if(activities && activities.length > 0 ) {
                    const objActivity = activities.find(a => a.activityId === visit.appointment.activityId);
                    setActivity(objActivity);
                }
                // eslint-disable-next-line
            }, [activities]);

    useEffect(() => {
                if(activity) {            
                   const listAcQualifiers = getActivityQualifiersForActivity(activity, activityQualifiers)                    
                   setActivityQualifiersForActivity(listAcQualifiers);
                    
                }
                // eslint-disable-next-line
            }, [activity]);    

    const [open, setOpen] = useState(true);
    const [saveAttempted, setSaveAttempted] = useState(false);
    const [currentVisit, setCurrentVisit] = useState(visit);
    const [showEditTimesDialog, setShowEditTimesDialog] = useState(false);
    const [filteredStatuses, setFilteredStatuses] = useState([]);
    const [showCancelVisitDialog, setShowCancelVisitDialog] = useState(false);
    const [hardStopAlertDialog, setHardStopAlertDialog] = useState(null);
    const [alertDialogConfig, setAlertDialogConfig] = useState(null);
    const [openAlertDialog, setOpenAlertDialog] = useState(true);
    const appointments = useSelector(syncService.appointmentService.getResults());
    const user = useSelector(appCache.getUser);
    const staff = user?.staff;
    const [inSessionVisits, setInSessionVisits] = useState([]);
    const [openResumeAlertDialog, setOpenResumeAlertDialog] = useState(true);
    const [resumeAlertDialog, setResumeAlertDialog] = useState(null);
    const serviceDocTab = cloneDeep(useSelector(documentCache.getServiceDocumentsForTabs));
    const selectedDocTabGlobal = useSelector(documentCache.getSelectedDocTabGlobal);
    let activeTab = {}; 
    if(serviceDocTab && selectedDocTabGlobal) {
        activeTab = serviceDocTab[selectedDocTabGlobal];
    }
    const history = useHistory();
    const dispatch = useDispatch();

    const isHandheld = useSelector(appCache.isHandheld);
    let changedPortrait = window.matchMedia("(orientation: portrait)");
    let orientation = changedPortrait.matches ? "portrait" : "landscape";
    const styles = useStyles();

    const buttonText = skipVerifySignature(evvState, appointmentStatus, activity) ? 'Submit' : 'Next';
    const attachedDocument = useSelector(documentCache.getAttachedDocuments);
    const [maxAlertDialogConfig, setMaxAlertDialogConfig] = useState(null);

    useEffect(() => {
        hasAttachedDocument(visit?.appointment, dispatch);
        // eslint-disable-next-line
    }, [visit?.appointment, attachedDocument]);

    const handleShowEditTimesDialog = () => {
        setShowEditTimesDialog(true);
    }

    const handleEditTimesDialogClose = (editedVisit) => {
        setShowEditTimesDialog(false);

        if (editedVisit){
            setCurrentVisit(editedVisit);
        }
    }

    useEffect(() => {
        getInSessionVisits(setInSessionVisits);
    }, []);

    useEffect(() => {
        const visitCopy = cloneVisit(visit);
        if(updatedStatus) {
            setAppointmentStatus(updatedStatus);
        } else {
            setAppointmentStatus("Kept");
        }
        setCurrentVisit(visitCopy);
    }, [updatedStatus, visit]);

    useEffect(() => {
        const startDateTimeAsDate = DateTime.fromMillis(currentVisit.startDateTime);
        const statuses = getFilteredStatusesByStartDate(startDateTimeAsDate, currentVisit.appointment.organization, appointmentStatus, ['In Session'], 'VVD');

        setFilteredStatuses(statuses);
        /* eslint-disable react-hooks/exhaustive-deps */
    }, [currentVisit?.appointment, currentVisit?.startDateTime]);

    const handleStatusChange = (e) => {
        const newStatus = e.target.value;

        setAppointmentStatus(newStatus);
        setActivityDetailDescriptorList([]);
        appointmentStatusChange(newStatus);
        currentVisit.appointmentStatus = newStatus;
        currentVisit.activityDetailDescriptorList = activityDetailDescriptorList;
    }

    const handleActivityQualifierChange = (e, activityQualifier) => {
        const index = activityDetailDescriptorList.findIndex(aq => aq.descriptorId === activityQualifier.descriptorId);
        if (e.target.checked) {
            if (index === -1) {
                activityDetailDescriptorList.push({
                    descriptorId: activityQualifier.descriptorId,
                    descriptorType: 'Activity Qualifiers'
                })
            }
        } else {
            if (index !== -1){
                activityDetailDescriptorList.splice(index, 1);
            }
        }

        setActivityDetailDescriptorList([...activityDetailDescriptorList]);
    }

    const closeVisitVerificationDialog = (verifiedVisit) => {
        setOpen(false);
        if (onClose){
            onClose(verifiedVisit);
        }
    }

    const handleCancelRequest = () => {
        setShowCancelVisitDialog(true);
    }

    const handleCancelVisitDialogClose = (isOk) => {
        setShowCancelVisitDialog(false);
        if (isOk) {
            closeVisitVerificationDialog(null);
        }
    }

    const handleHardStopAlertDialogClose = () => {
        setHardStopAlertDialog(null);
    }

    const handleMaxAlertDialogClose = () => {
        setResumeAlertDialog(null);
        setMaxAlertDialogConfig(null);
    }

    const handleAlertDialogClose = async (okClicked) => {
        setAlertDialogConfig(null);
        if (okClicked && alertDialogConfig.dialogId !== ALERT_DIALOG_ERROR){
            if (shouldResumeDocumentation(call, activeTab, attachedDocument, selectedTab)) {
                await resumeDocumentation(setResumeAlertDialog);
                return;
            }
            closeVisitVerificationDialog(createVerifiedVisit());
            setOpenAlertDialog(false); 
        } else {
            setOpenAlertDialog(true);
        }
    }

    const handleResumeDialogClose = async (okClicked) => {
        setResumeAlertDialog(null);
        const savedDocument =  await syncService.documentService.findDocumentById(attachedDocument[0]?.id);
        if(okClicked){
            setShowConfirmVisitDialog(false);
            if (shouldResumeDocumentation(call, activeTab, attachedDocument, selectedTab)) {
                if (!checkIfDocExistsInTab(serviceDocTab, savedDocument) && hasMaxDocsOpened(serviceDocTab, visit?.appointment?.clientId)) {
                    setMaxAlertDialogConfig(MAX_OPEN_DOCUMENTS);
                } else {
                    closeVisitVerificationDialog(createVerifiedVisit());
                    setOpenResumeAlertDialog(false);
                    handleResumeDocument(attachedDocument, isHandheld, dispatch, history, serviceDocTab);
                }
            }
        } else {      
            closeVisitVerificationDialog(createVerifiedVisit());
            setOpenResumeAlertDialog(true);
        }
    }

    const handleSubmit = () => {
        setSaveAttempted(true);

        currentVisit.activityDetailDescriptorList = activityDetailDescriptorList;
        currentVisit.appointmentStatus = appointmentStatus;
        currentVisit.activity = activity;
        let duration = getDuration(currentVisit?.originalStartDate ? currentVisit?.startDateTime : currentVisit?.appointment?.startDateInstance?.ts, currentVisit.endDateTime);
         if(duration >= 24) {
             setHardStopAlertDialog(setStopAlert(currentVisit));
         } else {
            if(!skipVerifySignature(evvState, appointmentStatus, activity)) {
                onvisitVerificationNextClick(currentVisit);
            } 
            if(hasOverlappingVisitAppointments(currentVisit, appointments, setAlertDialogConfig, staff, inSessionVisits)){
                setSaveAttempted(false);
                setShowConfirmVisitDialog(false);
            } else {
                setShowConfirmVisitDialog(true);
            }
         }
    };

    const createVerifiedVisit = () => {
        const verifiedVisit = {...currentVisit};

        verifiedVisit.appointmentStatus = appointmentStatus;

        const gobalVisit = cloneDeep(verifiedVisit);
        dispatch(setGlobalVisit(gobalVisit));

        return verifiedVisit;
    }

    const handleConfirmVisitDialogClose = async(isOk) => {
        setShowConfirmVisitDialog(false);

        if (isOk) {
            if (shouldResumeDocumentation(call, activeTab, attachedDocument, selectedTab)) {
                await resumeDocumentation(setResumeAlertDialog);
                return;
            }
            closeVisitVerificationDialog(createVerifiedVisit());
        }
    }

    const renderContent = () => {
        let breakPoints = getXSAndSMValueForField(isHandheld, orientation, 12, 8);
        return (
            <div className={styles.visitVerificationContent}>
                <div className={styles.visitInfo}>
                    <VisitVerificationCommonDetails
                        currentVisit = {currentVisit}
                    />
                    <button className={styles.timesButton} onClick={handleShowEditTimesDialog}>Edit Times</button>
                </div>
                <div>
                    <StackedField mandatory={true} label='Appointment Status' paddingTop="15px">
                        <Grid container item = { true } xs = { breakPoints.xsValue } sm = { breakPoints.smValue } className={styles.topPadding}>
                                <EvvSelect id='appointmentStatus' value={appointmentStatus} className={styles.filterSelect} onChange={handleStatusChange}>
                                    {filteredStatuses.map((item, index) => <option key={`option_${index}`} value={item}>{item}</option>)}
                                </EvvSelect>
                                {saveAttempted && appointmentStatus.length === 0 &&
                                <div className={styles.relativeErrorMessage} >Status is a required field. Please select a value and resubmit.</div>
                                }
                        </Grid>
                    </StackedField>
                </div>
                <div>
                    {appointmentStatus === 'Kept' && activityQualifiersForActivity.length > 0 &&
                    <StackedField label='Activity Qualifiers' paddingTop="35px">
                        <ActivityQualifiers activityQualifiers={activityQualifiersForActivity}
                                            selectedActivities={activityDetailDescriptorList}
                                            onActivityQualifierChange={handleActivityQualifierChange}/>
                    </StackedField>
                    }
                </div>
            </div>
        )
    }

return (
    <div>
       <Dialog
            classes={{paper: styles.dialogPaper}}
            open={open}
            disableEscapeKeyDown={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle disableTypography={true} classes={{root: styles.dialogTitleVV}} id="alert-dialog-title">Visit Verification</DialogTitle>
            <DialogContent classes={{root: isHandheld ? styles.dialogContentHandheld : styles.dialogContent}}>
                {renderContent()}
            </DialogContent>
            <DialogActions classes={{root: isHandheld? styles.okCancelButtonGroupHandheld : styles.okCancelButtonGroup}}>
                <div className={styles.cancelButton}>
                    <EvvButton styleOverride={isHandheld ? {fontSize: '14px'} : {}} type='tertiary' onClick={handleCancelRequest} >Cancel</EvvButton>
                </div>
                <div className={styles.okButton}>
                    <EvvButton styleOverride={isHandheld ? {fontSize: '14px'} : {}} type='primary' onClick={handleSubmit} >
                        {buttonText}
                    </EvvButton>
                </div>
            </DialogActions>
        </Dialog>
        {showEditTimesDialog &&
            <EditTimesDialog
                open={true}
                visit={currentVisit}
                reasonCodes={reasonCodes}
                onClose={handleEditTimesDialogClose}
                reasonCodeUi = {reasonCodeUi}
            />
        }
        {showCancelVisitDialog &&
            <AlertDialog
                open={true}
                dialogTitle='Confirm Cancellation'
                dialogMessage='Are you sure you want to cancel? By canceling this, any changes will be deleted and the client session will remain active.'
                showOkButton={true}
                okButtonText='Yes'
                showCancelButton={true}
                cancelButtonText='No'
                handleClose={handleCancelVisitDialogClose}
            />
        }
        {showConfirmVisitDialog &&
            <AlertDialog
                open={true}
                dialogTitle='Confirmation'
                dialogMessage={ HIDE_VISIT_VERIFICATION_SIGNATURE_STATUSES.includes(appointmentStatus) ? ERROR_MESSAGE_FOR_HIDE_VISIT_VERIFICATION_SIGNATURE : ERROR_MESSAGE_FOR_OTHER_STATUS}
                showOkButton={true}
                okButtonText='Yes'
                showCancelButton={true}
                cancelButtonText='No'
                handleClose={handleConfirmVisitDialogClose}
            />
        }
        {hardStopAlertDialog &&
            <AlertDialog
                open={true}
                dialogTitle={hardStopAlertDialog.dialogTitle}
                dialogMessage={hardStopAlertDialog.dialogMessage}
                showOkButton={hardStopAlertDialog.showOkButton}
                cancelButtonText='Close'
                showCancelButton={hardStopAlertDialog.showCancelButton}
                handleClose={handleHardStopAlertDialogClose}
            />
        }
        {alertDialogConfig && 
            <AlertDialog
                open={openAlertDialog}
                dialogTitle={alertDialogConfig.dialogTitle}
                dialogMessage={alertDialogConfig.dialogMessage}
                showOkButton={alertDialogConfig.showOkButton}
                showCancelButton={alertDialogConfig.showCancelButton}
                okButtonText={alertDialogConfig.okButtonText || 'Ok'}
                cancelButtonText={alertDialogConfig.cancelButtonText || 'Cancel'}
                handleClose = {handleAlertDialogClose}
            />
        }
        {resumeAlertDialog &&
            <AlertDialog
                open={openResumeAlertDialog}
                dialogTitle={resumeAlertDialog.dialogTitle }
                dialogMessage={resumeAlertDialog.dialogMessage}
                showOkButton={resumeAlertDialog.showOkButton}
                showCancelButton={resumeAlertDialog.showCancelButton}
                okButtonText={resumeAlertDialog.okButtonText || 'Ok'}
                cancelButtonText={resumeAlertDialog.cancelButtonText || 'Cancel'}
                handleClose = {handleResumeDialogClose}
            />
        }
        {maxAlertDialogConfig &&
            <AlertDialog
                open={true}
                dialogTitle={maxAlertDialogConfig.dialogTitle}
                dialogMessage={maxAlertDialogConfig.dialogMessage}
                showOkButton={maxAlertDialogConfig.showOkButton}
                showCancelButton={maxAlertDialogConfig.showCancelButton}
                okButtonText={maxAlertDialogConfig.okButtonText ? maxAlertDialogConfig.okButtonText : 'Ok'}
                handleClose={handleMaxAlertDialogClose}
            />
        }
    </div>            
  )
}

export default VisitVerification