import { FormControl, FormControlLabel } from "@material-ui/core";
import React from "react";
import EvvCheckBox from "../../../common/components/EvvCheckBox"
import FormField from "./FormField";
import DisplayErrorMessages from "../common/DisplayErrorMessages";
import { sortElements } from '../common/Util';

class CheckBoxField extends FormField {
    constructor(props) {
        super(props);
        
        let description = "";
        
        description = this.getDescription();
        props.fieldDescriptor = this.fieldDescriptor;

        this.state = {
            description: description,
            data: props
        };
    }

    getDescription = () => {
        let description = "";
        let filteredObj = this.fieldDescriptor.attributes.filter((attribute) => attribute.attributeId === this.fieldDescriptor.id +"_description");
        description = filteredObj[0].value;
        return description;
    }

    isChecked = (value) => {
        let isChecked = (this.fieldDescriptor.dbSavedValue && this.fieldDescriptor?.dbSavedValue?.includes(value)) || (this.fieldDescriptor.selectedOption && this.fieldDescriptor.selectedOption.includes(value));
        return isChecked ? isChecked : false;
    }

    renderField(props, state){
        let options;

        if (this.fieldDescriptor.type === 'Descriptor CheckBox') {
            options = state.dbOptions[this.fieldDescriptor.formId + "-" + this.fieldDescriptor.id];
        } else {
            options = this.fieldDescriptor.options;
            sortElements(options, 'id', 'number');
        }

        return (
            <div>
                { this.fieldDescriptor?.businessRuleApplied !== "Hide" &&
                    <div>
                        <span>{ this.state.description }</span>
                         { options?.map(({ value, id, disabled }, index) => {
                            return (
                                <div key = { index }>
                                    <FormControl component = "fieldset">
                                        <FormControlLabel
                                            control = {<EvvCheckBox
                                                { ...props }
                                                id = { 'checkbox-' + id }
                                                name = { 'checkbox-' + id }
                                                value = {value}
                                                checked = { this.isChecked(value) }
                                                disabled = { state.isReadOnlyDoc }
                                                onChange = { (event) => { props.onChange(event, this.fieldDescriptor, props); } }
                                            />}
                                        label = { value }
                                        />
                                    </FormControl>
                                </div>
                            )
                        })
                    }
                    <div>
                        <DisplayErrorMessages
                            { ...this.state.data }
                        />
                    </div>
                </div>
                }
            </div>
        )
    }
}
export default CheckBoxField;
