import React from "react";
import FormField from "./FormField";
import { Divider } from "@material-ui/core";
import "./PhoneField.css";

class FormBreakField extends FormField {

    renderField(props, state){
        let fieldTitle = "";

        let filteredObj = this.fieldDescriptor.attributes.find((attribute) => attribute.attributeId === this.fieldDescriptor.id +"_title");
        if(Object.keys(filteredObj).length > 0) {
            fieldTitle = filteredObj?.value;
        }
        props.fieldDescriptor = this.fieldDescriptor;
        return (
            <div>
                {this.fieldDescriptor?.businessRuleApplied !== "Hide" &&
                <>
                    <div className="dialogTitle">
                        {fieldTitle}
                    </div>
                    <Divider/>
                </>
                }
            </div>
        )
    }
}

export default FormBreakField;