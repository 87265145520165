import LookupService from "./lookupService";
import {sort} from "../utils/miscUtils";
import { formatStaffName } from "../utils/formatUtils";
import { sortElements } from "../../features/forms/common/Util";

class OrganizationService extends LookupService{
    constructor(config) {
        super(config);
        this.organizationHierarchy = null;
    }

    getDocumentCrosswalksForOrganization(documentCrosswalks, organizationId){
        const organization = this.organizationHierarchy.orgById[organizationId];
        const organizations = [];

        this.getDescendants(organization, organizations, true);

        const organizationIds = organizations.map(org => org.organizationId);

        return documentCrosswalks.filter(dc => organizationIds.includes(dc.organizationId));
    }

    getStaffForOrganization(staff, organizationId){
        const organizations = [];
        let staffOfOrganizations = [];
        const organization = this.organizationHierarchy.orgById[organizationId];

        this.getAncestorsAndDescendants(organization, organizations);
        let organizationIds = organizations.map(org => org.organizationId);
        staffOfOrganizations = staff.filter(s => {
            let additionalOrganizationIds = [];
            if(s.additionalOrganizationIds) {
                additionalOrganizationIds = s.additionalOrganizationIds;
            }
            return organizationIds.includes(s.primaryOrganizationId) || (organizationIds.find(orgId => { return additionalOrganizationIds.includes(orgId)}));
        });
        let options = [];
        this.formatForStaffDD(staffOfOrganizations, options);
        return options.filter((item, pos) => options.indexOf(item) === pos);
    }

    formatForStaffDD = (dropDownList, options) => {
        dropDownList.forEach((value) => {
            const staffName = formatStaffName(value);
            options.push({'staffId': value.staffId, 'value': staffName});
        })
        sortElements(options, 'value', 'string');
        return options;
    }

    getRecordsForOrganization(records, organizationId, includeDescendants = true, includeAncestors = false, isArrayProperty = false){
        const organization = this.organizationHierarchy.orgById[organizationId];
        const organizations = [];

        if (includeDescendants){
            this.getDescendants(organization, organizations, true);
        } else {
            organizations.push(organization);
        }

        if (includeAncestors){
            this.getAncestors(organization, organizations);
        }

        const organizationIds = this.getUniqueOrganizations(organizations).map(org => org.organizationId);

        if (isArrayProperty){
            return records.filter(record => record.organizationIds.length === 0 || organizationIds.some(oid => record.organizationIds.includes(oid)));
        } else {
            return records.filter(record => organizationIds.includes(record.organizationId));
        }
    }

    getUniqueOrganizations(organizations){
        const uniqueOrganizationIds = [...new Set(organizations.map(org => org.organizationId))];
        return uniqueOrganizationIds.map(id => this.organizationHierarchy.orgById[id]);
    }

    sortAndDetachOrganizations(organizations){
        const uniqueOrganizations = this.getUniqueOrganizations(organizations);

        return sort(uniqueOrganizations, 'name').map(org => ({...org, parent: null, children: null}));
    }

    getOrganizationAndDescendants(organizationId){
        const organization = this.organizationHierarchy.orgById[organizationId];
        const organizations = [];

        this.getDescendants(organization, organizations, true);

        return this.sortAndDetachOrganizations(organizations);
    }

    getOrganizationAncestorsAndDescendants(organizationId){
        const organization = this.organizationHierarchy.orgById[organizationId];
        const organizations = [];
        
        this.getAncestorsAndDescendants(organization, organizations);

        return this.sortAndDetachOrganizations(organizations);
    }

    getAvailableOrganizations(primaryOrganizationId, staffSignatures){
        if (primaryOrganizationId){
            const additionalOrganizationIds = staffSignatures.flatMap(ss => ss.additionalOrganizationIds ?? [])
            const organization = this.organizationHierarchy.orgById[primaryOrganizationId];
            const organizations = [];

            this.getDescendants(organization, organizations, true);
            if (additionalOrganizationIds && additionalOrganizationIds.length > 0){
                additionalOrganizationIds.forEach(orgId => {
                    const additionalOrganization = this.organizationHierarchy.orgById[orgId];
                    if (additionalOrganization){
                        this.getDescendants(additionalOrganization, organizations, true);
                    }
                })
            }

            return this.sortAndDetachOrganizations(organizations);
        } else {
            return [];
        }
    }

    getAncestorsAndDescendants(entity, generations){
        this.getDescendants(entity, generations, true);
        this.getAncestors(entity, generations);
    }

    getDescendants(entity, descendants, includeParent){
        const children = entity.children;

        if (includeParent){
            descendants.push(entity);
        }

        for (let child of children){
            descendants.push(child);
            this.getDescendants(child, descendants, false);
        }
    }

    getAncestors(entity, ancestors){
        if (entity.parent) {
            ancestors.push(entity.parent);
            this.getAncestors(entity.parent, ancestors);
        }
    }

    afterLoad = (organizations, config) => {
        organizations = super.afterLoad(organizations, config);

        this.organizationHierarchy = this.buildOrgHierarcy(organizations);

        return organizations;
    }

    buildOrgHierarcy = (organizations) => {
        const orgsByParentId = {};
        const orgHierarchy = {};
        const orgsById = {};

        organizations.forEach(org => {
            const editableOrg = {...org};
            orgsById[org.organizationId] = editableOrg;
            if (!orgsByParentId[org.parentOrganizationId]){
                orgsByParentId[org.parentOrganizationId] = [];
            }

            orgsByParentId[org.parentOrganizationId].push(editableOrg);
        });

        orgHierarchy.orgById = orgsById;

        for (const orgId in orgsById) {
            const org = orgsById[orgId];
            org.children = orgsByParentId[org.organizationId] || [];
            if (org.parentOrganizationId){
                org.parent = orgsById[org.parentOrganizationId];
            } else {
                orgHierarchy.rootOrg = org;
            }
        }

        return orgHierarchy;
    };
}

export default OrganizationService;