import {makeStyles} from "@material-ui/core/styles";
import ActiveSessionCard from "../ActiveSessionCard";
import {appCache} from "../../../cache/slices/app/appSlice";
import {clientCache} from "../../../cache/slices/client/clientSlice";
import syncService from "../../../common/services/syncService";
import {useSelector} from "react-redux";
import {useEffect, useState} from 'react';


const useStyles = makeStyles((theme) => ({
    activeSessions: {
        display: 'flex',
        flexDirection: 'column'
    }
}));

export default function ActiveSessions({onActiveSessionSelected}) {
    const visits = useSelector(syncService.visitService.getResults());
    const hasActiveVisits = visits && visits.length > 0 && visits.filter((v) => v.complete === 0);
    const styles = useStyles();
    const client = useSelector(clientCache.getSelectedClient);
    const user = useSelector(appCache.getUser);
    const [firstRender, setFirstRender] = useState(true);

    useEffect(() => {
        try {
            if(firstRender) {
                if(hasActiveVisits && hasActiveVisits.length > 1) {
                    syncService.auditService.saveAuditEvent(null, null, user, "EVV Active Sessions", "View");
                } else {
                    syncService.auditService.saveAuditEvent(null, hasActiveVisits[0].client, user, "EVV Active Sessions", "View");
                }
                setFirstRender(false);
            }
        } catch(error) {
            console.log(error);
        }
    }, [hasActiveVisits, firstRender, client, user]);

    return (
        <>
        {hasActiveVisits && hasActiveVisits.length > 0 &&
        <div className={styles.activeSessions}>
            {hasActiveVisits.map((visit, index) => <ActiveSessionCard key={`${visit.clientId}_${index}`} visit={visit} onActiveSessionSelected={onActiveSessionSelected}/>)}
        </div>}
        </>
    )
}
