/* eslint-disable eqeqeq */
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from "@material-ui/core/styles";
import EvvButton from "../../../common/components/EvvButton";
import {useEffect, useMemo, useRef, useState} from "react";
import LoginTextField from "../LoginTextField";
import EvvSelect from "../EvvSelect";
import {formatServerAddress, states, transformServerResult} from "../../utils/addressUtils";
import {sort} from "../../utils/miscUtils";
import {DateTime} from "luxon";
import {dateToStringWithFormat, VISIT_DATE_TIME_FORMAT} from "../../utils/formatUtils";
import SearchField from "../SearchField";
import {useDispatch, useSelector} from "react-redux";
import {appCache} from "../../../cache/slices/app/appSlice";
import geocodingService from "../../services/geocodingService";
import {debounce} from "lodash/function";

const useStyles = makeStyles((theme) => ({
    dialogPaper: {
        margin: '30px 8px 30px 8px',
        maxWidth: '500px',
        width: '500px'
    },
    dialogPaperForhandheld: {
        margin: '30px 8px 30px 8px',
        maxWidth: '500px',
        width: 'calc(100% - 16px)'
    },
    dialogTitle: {
        height: '42px',
        fontSize: '16px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: "#ffffff",
        paddingLeft: '16px',
        display: 'flex',
        alignItems: 'center'
    },
    dialogContent: {
        padding: "19px 19px 0px 19px",
    },
    spaceBelow: {
        paddingBottom: '20px',
        position: 'relative'
    },
    okCancelButtonGroup: {
        padding: "0px 16px 15px 16px",
        justifyContent: 'space-between'
    },
    okButton: {
        padding: '0',
        minWidth: '125px'
    },
    cancelButton: {
        padding: '0'
    },
    filterSelect: {
        width: '100%',
        height: '27px',
        borderRadius: '2px',
        background: "transparent",
        borderStyle: "solid",
        borderWidth: '1px',
        borderColor: "#b6b6b6"
    },
    errorMessage : {
        position: 'absolute',
        bottom: '1px',
        fontSize: '13px',
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: '16px',
        letterSpacing: 0,
        color: "#dc0707"
    }
}));

const STREET_LENGTH_MAX = 100;
const APARTMENT_SUITE_MAX = 10;
const CITY_MAX = 60;
const ZIP_REGEX = /^\d{0,5}?$/;

const DEBOUNCE_INTERVAL = 500;
const DEBOUNCE_DISABLE_INTERVAL = 750;

export default function AddressDialog({address, onClose, appointment}){
    const addresses = useSelector(appCache.getAddresses);
    const [filteredAddresses, setFilteredAddresses] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [source, setSource] = useState(null);
    const [lat, setLat] = useState(null);
    const [lon, setLon] = useState(null);
    const [stateOptions, setStateOptions] = useState([]);
    const [street1, setStreet1] = useState('');
    const [street2, setStreet2] = useState('');
    const [aptSuite, setAptSuite] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [saveAttempted, setSaveAttempted] = useState(false);
    const dispatch = useDispatch();
    const styles = useStyles();
    const typeaheadController = useRef({disabled: false});
    const isHandheld = useSelector(appCache.isHandheld);

    const addressSetters = {
        street1: setStreet1,
        street2: setStreet2,
        aptSuite: setAptSuite,
        city: setCity,
        state: setState,
        zip: setZip
    };

    const disableAutocomplete = (durationMs) => {
        typeaheadController.current.disabled = true;
        setTimeout(() => {
            typeaheadController.current.disabled = false;
        }, durationMs);
    }

    const populateFormFromAddress = (address) => {
        disableAutocomplete(DEBOUNCE_DISABLE_INTERVAL);
        setSource(address.source);
        setStreet1(address.street1 || '');
        setStreet2(address.street2 || '');
        setAptSuite(address.aptSuite || '');
        setCity(address.city || '');
        setState(address.state || '');
        setZip(address.zip || '');
        setLat(address.latitude || '');
        setLon(address.longitude || '');
    }

    const populateAddressFromForm = () => {
        const newAddress = {...address};

        if (!newAddress.id) {
            let newAddressId = (-1 * Date.now())
            newAddress.id = newAddressId;
            appointment.newAddressId = newAddressId;
            
        }
        newAddress.source = source;
        newAddress.street1 = street1;
        newAddress.street2 = street2;
        newAddress.aptSuite = aptSuite;
        newAddress.city = city;
        newAddress.state = state;
        newAddress.zip = zip;
        newAddress.type = 'EVV';
        newAddress.latitude = lat;
        newAddress.longitude = lon;
        newAddress.audit = {
            createdDate: dateToStringWithFormat(DateTime.now(), VISIT_DATE_TIME_FORMAT)
        }

        return newAddress;
    }

    const transformSelection = (serverMatch) => {
        return transformServerResult(serverMatch);
    }

    useEffect(() => {
        setStateOptions(sort(states, 'name'));
    }, []);

    useEffect(() => {
        setFilteredAddresses(addresses);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addresses]);

    const autocompleteAddress = (addressText) => {
        if (!typeaheadController.current.disabled) {
            geocodingService.searchForAddresses(dispatch, addressText);
        }
    }

    const handleAddressTextChange = (e) => {
        if (!e){
            return;
        }

        const addressText = e.target.value || '';

        setStreet1(addressText);
        if (addressText.length >= 4){
            debouncedAutocompleteAddress(addressText);
        // } else if (addressText.length === 0){
        //     dispatch(appCache.fetchAddressesSuccess([]));
        }
    }

    const debouncedAutocompleteAddress = useMemo(
        () => debounce(autocompleteAddress, DEBOUNCE_INTERVAL)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , []);

    useEffect(() => {
        return () => {
            debouncedAutocompleteAddress.cancel();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        populateFormFromAddress(address || {});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address]);

    useEffect(() => {
        if (selectedAddress && selectedAddress.address) {
            populateFormFromAddress(transformSelection(selectedAddress));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAddress]);

    const handleCancel = () => {
        if (onClose){
            setSaveAttempted(false);
            onClose(null);
        }
    }

    const handleSave = () => {
        setSaveAttempted(true);

        if (isSaveDisabled()){
            return;
        }

        if (onClose){
            onClose(populateAddressFromForm());
        }
    }

    const isSaveDisabled = () => {
        return (
            street1.length <= 0 || street1.length > STREET_LENGTH_MAX ||
            city.length <= 0 || city.length > CITY_MAX ||
            state.length <= 0 || zip.length <= 0 ||
            !ZIP_REGEX.test(zip)
        );
    }

    const handleStateChange = (e) => {
        setState(e.target.value);
    }

    const handleZipChange = (e) => {
        const value = e.target.value;

        if (!ZIP_REGEX.test(value)){
            return false;
        }

        setZip(value);
    }

    const handleTextFieldChange = (e, maxLength) => {
        const value = e.target.value;

        if (maxLength > 0){
            if (value.length > maxLength){
                return false;
            }
        }

        addressSetters[e.target.id](value);
    };

    const handleAddressChange = (e, address, reason) => {
        setSelectedAddress(address);

        if (reason === 'clear'){
            dispatch(appCache.fetchAddressesSuccess([]));
        }
    }

    const renderContent = () => {
        return (
            <div>
                <div className={styles.spaceBelow}>
                    <SearchField
                        id='street1'
                        placeholder="Street 1"
                        options={filteredAddresses}
                        getOptionLabel={(result) => formatServerAddress(result)}
                        getOptionSelected={(address, value) => address.id === value.id}
                        value={selectedAddress}
                        onChange={handleAddressChange}
                        inputValue={street1}
                        onInputChange={handleAddressTextChange}
                        filterOptions={(options) => options}
                        defaultValue={selectedAddress}
                        autoComplete={true}
                        autoHighlight={false}
                        selectOnFocus={true}
                        clearOnBlur={true}
                        autoSelect={true}
                        freeSolo={true}
                    />
                    {saveAttempted && street1.length <= 0 && <div className={styles.errorMessage} >This is a required field, please enter a value.</div>}
                </div>
                <div className={styles.spaceBelow}>
                    <LoginTextField id='street2' placeholder='Street 2' onChange={e => handleTextFieldChange(e, STREET_LENGTH_MAX)}
                                    value={street2}/>
                </div>
                <div className={styles.spaceBelow}>
                    <LoginTextField id='aptSuite' placeholder='Apartment/Suite' onChange={e => handleTextFieldChange(e, APARTMENT_SUITE_MAX)}
                                    value={aptSuite}/>
                </div>
                <div className={styles.spaceBelow}>
                    <LoginTextField id='city' placeholder='City' onChange={e => handleTextFieldChange(e, CITY_MAX)} value={city}/>
                    {saveAttempted && city.length <= 0 && <div className={styles.errorMessage} >This is a required field, please enter a value.</div>}
                </div>
                <div className={styles.spaceBelow}>
                    <EvvSelect id='state' placeholder='State' onChange={handleStateChange} value={state} className={styles.filterSelect}>
                    <option key={`option_empty`} value={''}>Select State</option>
                        {stateOptions.map((stateOption) =>
                            <option key={stateOption.abbreviation} value={stateOption.abbreviation}>{stateOption.name}</option>)}
                    </EvvSelect>
                    {saveAttempted && state.length <= 0 && <div className={styles.errorMessage}>This is a required field, please enter a value.</div>}
                </div>
                <div className={styles.spaceBelow}>
                    <LoginTextField id='zip' placeholder='Zip Code' onChange={handleZipChange} value={zip}/>
                    {saveAttempted && zip.length <= 0 && <div className={styles.errorMessage} >This is a required field, please enter a value.</div>}
                    {saveAttempted && zip.length > 0 && saveAttempted && zip.length < 5 && <div className={styles.errorMessage} >Please enter a valid Zip Code</div>}
                </div>
            </div>
        );
    }

    return (
        <Dialog
            classes={{paper: (isHandheld ? styles.dialogPaperForhandheld : styles.dialogPaper)}}
            open={true}
            disableEscapeKeyDown={true}
        >
            <DialogTitle classes={{root: styles.dialogTitle}} id="alert-dialog-title">New Address</DialogTitle>
            <DialogContent classes={{root: styles.dialogContent}}>
                {renderContent()}
            </DialogContent>
            <DialogActions classes={{root: styles.okCancelButtonGroup}}>
                <div id='addressDialog_cancelButtonParent' className={styles.cancelButton}>
                    <EvvButton id='addressDialog_cancelButton' type='tertiary' onClick={handleCancel}>Cancel</EvvButton>
                </div>
                <div id='addressDialog_saveButtonParent' className={styles.okButton}>
                    <EvvButton id='addressDialog_saveButton' type='primary' disabled={false} onClick={handleSave}>Save</EvvButton>
                </div>
            </DialogActions>
        </Dialog>
    );
}
