import {makeStyles} from "@material-ui/core/styles";
import {useSelector} from "react-redux";
import DataTable from "../../../common/components/DataTable";
import {sortWithDirection} from "../../../common/utils/miscUtils";
import {formatDate, getOrderByProperty} from "../../../common/utils/tableUtils";
import {formatDiagnosis} from "../../../common/utils/formatUtils";
import CircularProgress from "@material-ui/core/CircularProgress";
import syncService from "../../../common/services/syncService";
import {useEffect} from "react";
import {clientCache} from "../../../cache/slices/client/clientSlice";
import {appCache} from "../../../cache/slices/app/appSlice";
import { PRIMARY_COLOR } from "../../../common/constants"

const useStyles = makeStyles(() => ({
    diagnosesTab: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column'
    },
    loadingSpinnerContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    loadingSpinner: {
        color: PRIMARY_COLOR
    },
    noDiagnosesFoundContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    noDiagnosesFound: {
        fontSize: '18px',
        fontWeight: "bold",
        fontStyle: "normal",
        color: "#000000",
        paddingTop: '56px'
    }
}));

const columnDescriptors = [
    { id: 'priority', bold: true, sort:"asc", fieldName: 'priority', label: 'Priority'},
    { id: 'iCD10Code', fieldName: 'iCD10Code', label: 'ICD-10 Code', formatter: formatDiagnosis},
    { id: 'diagnosis', fieldName: 'diagnosis', label: 'DSM-5 Description' },
    { id: 'effectiveDate', fieldName: 'effectiveDate', label: 'Effective Date', formatter: formatDate }
];

export default function DiagnosesTab() {
    const diagnoses = useSelector(syncService.diagnosisService.getResults());
    const isLoading = useSelector(syncService.diagnosisService.isLoading());
    const styles = useStyles();
    const client = useSelector(clientCache.getSelectedClient);
    const user = useSelector(appCache.getUser);

    useEffect(() => {
        if(diagnoses && diagnoses.length > 0) {
            syncService.auditService.saveAuditEvent(null, client, user, "EVV Client Diagnosis", "View");
        }
    }, [diagnoses, client, user]);

    const handleSort = (records, columnDescriptor, orderByDirection) => {
        return sortWithDirection(
                records,
                [getOrderByProperty(columnDescriptor)],
                [orderByDirection]
            );
    };

    const renderPage = () => {
        if (isLoading) {
            return (
                <div className={styles.loadingSpinnerContainer}>
                    <CircularProgress size={50} className={styles.loadingSpinner}/>
                </div>
            );
        } else {
            return (
                <>
                    <DataTable
                        columnDescriptors={columnDescriptors}
                        records={diagnoses}
                        handleSort={handleSort}
                    />

                    {(!diagnoses || diagnoses.length === 0) &&
                    <div className={styles.noDiagnosesFoundContainer}>
                        <div className={styles.noDiagnosesFound} >
                            No Diagnoses Found
                        </div>
                    </div>
                    }
                </>
            );
        }
    }

    return (
        <div className={styles.diagnosesTab}>
            {renderPage()}
        </div>
    );
}
