import {makeStyles, ThemeProvider} from "@material-ui/core/styles";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AuthenticatedApp from "../AuthenticatedApp";
import SplashPage from "../SplashPage"
import LoginPage from "../LoginPage";
import LoginPagePingOne from "../LoginPagePingOne/LoginPagePingOne";
import OfflinePinPageAuth from "../OfflinePinAuth/OfflinePinAuth";
import LoginPagePingOneCallback from "../LoginPagePingOne/LoginPagePingOneCallback";
import {theme as evvTheme} from "../../../common/services/theme";
import {useDispatch, useSelector} from "react-redux";
import {appCache} from "../../../cache/slices/app/appSlice";
import {useEffect, useState} from "react";
import { getDevicePlatformType } from "../../../common/utils/systemUtils";
import * as rdd from 'react-device-detect';
import { reloadApp } from "../orientationUtils";
import OfflinePinUpdatePage from "../OfflinePinUpdate/OfflinePinUpdate";
import LoginPagePingOneError from "../LoginPagePingOneError/LoginPagePingOneError";

const useStyles = makeStyles(() => ({
    application: {
        width: '100%',
        height: '100%'
    }
}));


export default function App() {
    let portrait = window.matchMedia("(orientation: portrait)");
    const hasSplashPageAppeared = useSelector(appCache.hasSplashPageAppeared);
    let devicePlatformType = getDevicePlatformType(rdd);
    const isHandheld = (devicePlatformType === "Mobile" ? true : false);
    const currentUser = useSelector(appCache.getUser)
    const orientation = portrait.matches ? "portrait" : "landscape";
    const [currentOrientation, setCurrentOrientation] = useState(orientation);
    const dispatch = useDispatch()
    const styles = useStyles();

    const handleOnline = (evt) => {
        console.log('App - handleOnline called');
        dispatch(appCache.toggleOnlineFlag(true));
    };

    const handleOrientationChange = (evt) => {
        console.log('App - handleOrientationChange called');
        setTimeout(() => reloadApp(isHandheld, setCurrentOrientation, dispatch, rdd), 100);
    };

    const handleOffline = (evt) => {
        console.log('App - handleOffline called');
        dispatch(appCache.toggleOnlineFlag(false));
    };

    const initializeApp = (evt) => {
        console.log("App.initializeApp - online/offline: " + window.navigator.onLine)
        dispatch(appCache.toggleOnlineFlag(window.navigator.onLine));
        window.addEventListener('offline', handleOffline);
        window.addEventListener('online', handleOnline);
        window.addEventListener("orientationchange" , handleOrientationChange);
    }

    const cleanupApp = (evt) => {
        window.removeEventListener('offline', handleOffline);
        window.removeEventListener('online', handleOnline);
        window.removeEventListener("orientationchange" , handleOrientationChange);
    };

    useEffect(() => {
        initializeApp();

        return cleanupApp;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        initializeApp();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orientation, currentOrientation]);



    useEffect(() => {
        dispatch(appCache.toggleHandheldFlag(isHandheld));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isHandheld]);

  const renderApplication = () => {
    let hostname = window.location.hostname;
    let currentPath = window.location.pathname;

    if (hasSplashPageAppeared) {
      if (currentUser) {
        return <AuthenticatedApp />;
      } else {
        if ((hostname.startsWith("mobile")) && currentPath === "/oidc/callback") {
          return <LoginPagePingOneCallback />;
        } else if (hostname.startsWith("mobile")) {
          return <LoginPagePingOne />;
        } else if (hostname.startsWith("evv")) {
          return <LoginPage />;
        }
      }
    }

    return <SplashPage />;
  };

  return (
    <ThemeProvider theme={evvTheme}>
      <div id='evvApp' data-testid='routerContainer' className={styles.application}>
        <Router>
          <Switch>
            <Route path="/loginPingOne" component={LoginPagePingOne} />
            <Route path="/loginOffline" component={OfflinePinPageAuth} />
            <Route path="/changeOfflinePin" component={OfflinePinUpdatePage} />
            <Route path="/loginFailed" component={LoginPagePingOneError} />
            <Route path="/" render={renderApplication} />
          </Switch>
        </Router>
      </div>
    </ThemeProvider>
  )
}