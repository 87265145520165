import FormField from "./FormField";
import { Grid } from "@material-ui/core";
import DisplayErrorMessages from "../common/DisplayErrorMessages";
import TimePickerField from "../../../common/components/TimePicker/TimePickerField";
import { databaseValue } from "../common/HandleChangedInputUtils";
import { getXSAndSMValueForField } from "../common/Util";

class TimeField extends FormField{
    constructor(props) {
        super(props);
        this.value = '';
        let description = "";

        let isRequired = this.isMandatory();
        props.fieldDescriptor = this.fieldDescriptor;

        let descObj = this.fieldDescriptor.attributes.find( (attribute) => attribute.attributeId === this.fieldDescriptor.id +"_description" );
        description = descObj?.value;

        this.state = {
            description: description,
            data: props,
            isRequired: isRequired,
        };
    }

    getTimeValue = () => {
        return this.fieldDescriptor?.dbSavedValue?.length > 0 ? databaseValue(this.fieldDescriptor?.dbSavedValue, this.fieldDescriptor) : this.fieldDescriptor.value ;
    }
    
    renderField(props, state){
        let breakPoints = getXSAndSMValueForField(props.isHandheld, props.orientation, 12, 6);
        props.fieldDescriptor = this.fieldDescriptor;

        return (
            <div>
                { this.fieldDescriptor?.businessRuleApplied !== "Hide" &&
                <>
                    <span>{this.state.description}</span><div>
                        <Grid container xs={breakPoints.xsValue} sm={breakPoints.smValue}>
                            <TimePickerField
                                {...props}
                                id={'time-' + this.fieldDescriptor.id}
                                value={ this.getTimeValue() }
                                type={this.state.datatype}
                                required={this.state.isRequired}
                                onChange={(event) => { props.onChange(event, this.fieldDescriptor, props); } } 
                                disabled = { state.isReadOnlyDoc }
                            />
                            <div>
                                <DisplayErrorMessages
                                    {...props} 
                                />
                            </div>
                        </Grid>
                    </div>
                </>
                }       
            </div>
        )
    }
}

export default TimeField;
