import dayjs from 'dayjs';
import { combineDateAndTime } from '../../../common/utils/dateTimeUtils';
import { convertToLuxonDate, stringToDate } from '../../../common/utils/formatUtils';
import * as msgConst from '../common/Messages';
import { validatePhone } from '../common/ValidateFieldUtils';
import * as constData from "../../../common/constants";

const alphabets = /^[a-zA-Z\s ]*$/; //alphabets
const number = /^[0-9.\s]*$/;//number
const specialChar= /^[a-zA-Z\s/!@#$%&*()><{}?¿=|°"¬¡.,;:_^`~\\[]+$/;//specialCharacters

export const validatePhoneInput = (input, phoneFieldName) => {
    let isValidInput = false;
    const phoneNumberRegex = /^\d{0,3}(-?)\d{0,4}$/;
    if(phoneFieldName === "phoneNumber") {
        if(input.length <= 8) {
            isValidInput = phoneNumberRegex.test(input);
        }
    } else if(phoneFieldName === "countryCode" || phoneFieldName === "ext") {
        isValidInput = constData.PHONE_REGEX.test(input);
    }
    return isValidInput;
}

export const handlePhoneInput = (event, field) => {
    const maxCharacters = event.target.maxLength;
    let phoneFieldsArr = [];
    let value = event.target.value;
    let phoneFieldName = event.target.name;

    if ((value === "" || validatePhoneInput(value, phoneFieldName))) {
        field.options.forEach((phoneField) => {
            if(event.target.id === phoneField.id) {
                if(value.length <= maxCharacters) {
                    if(phoneField.name === "phoneNumber" && value.length === 3) {
                        if(phoneField.value.length === 2) {
                            value = value  + "-";
                        } else if(phoneField.value.length === 4) {
                            //  if phoneField's length is 4 then the data is with - eg.(123-). If user tried to delete number
                            //  in that case we have to remove number not only "-"
                            value = value.slice(0, 2);
                        }
                    } else if(phoneField.name === "ext" && !constData.PHONE_REGEX.test(value)){
                        value = "";
                    }
                    value = handleDeleteOfCharForPhoneField(field, value, phoneFieldName);
                    phoneField.value = value;
                }
            }
            phoneFieldsArr.push(phoneField.value);
        })
    }

    delete field.onChangeError;

    let hadPhoneValue = phoneFieldsArr.filter( phone => phone !== '' );
    if (hadPhoneValue.length > 0) {
        validatePhone(phoneFieldsArr, field);
    }

    return field;
}
export const handleDeleteOfCharForPhoneField = (field, value, phoneFieldName) => {
    let oldValue = field.options[1].value;
    let valueLength = value.length;
    if(oldValue?.length > 0 && phoneFieldName === "phoneNumber") {
        if((value[3] !== oldValue[3]) && (valueLength >= 4)) {
            if(valueLength < oldValue?.length) {
                value = value.slice(0, 2) + value.slice(3);
            }
            let phoneInput = value.replace(/-/g,"");
            let pos = 3;
            value = phoneInput.substring(0, pos) + "-" + phoneInput.substring(pos, phoneInput.length);
        }
    }
    return value;
}

export const findMaxValue = (value, length, maximumValue) => {
    let str = "";
    let floatvalue = parseFloat(value);
    maximumValue = parseFloat(maximumValue);
    if(floatvalue <= maximumValue) {
        str = (value.substring(0,length)); 
    } else {
        str = (value.substring(0,length-1)); 
    }
    return str;
    
}
export const dataTypeAL = (field, eventValue, datatype) => {
    if(datatype === "AL"){
        if (eventValue === "" || alphabets.test(eventValue)) {
            field['value'] = eventValue;
        }

        if(field['value'] !== eventValue && !alphabets.test(eventValue)) {
            field['onChangeError']  = `${field.label} ${msgConst.alphabetic}`;
        } else {
            delete field.onChangeError;
        }
    }
}

export const dataTypeNumValue = (field, eventValue, maxvalue, maximumValue) => {
    let minvalue = "";
    let minvalueobj = field.attributes.find( (attribute) => attribute.attributeId === field.id +"_minvalue" );
    minvalue = minvalueobj?.value;

    let minimumValue = parseInt(minvalue);
    let minmaxerror = true;
    if (eventValue === "" || number.test(eventValue)) {
        const value = parseInt(eventValue);
        field['value'] = eventValue;
        if((value >= minimumValue || minvalue === '' ) && (value <= maximumValue || maxvalue === '')) {
            minmaxerror = false;
        } else if(isNaN(value)) {
            field['value'] = "";
        }
    }
    return minmaxerror;
}
export const minMaxError = (field, eventValue, minmaxerror ) => {
    if(minmaxerror && !number.test(eventValue)) {
        field['onChangeError']  = `${field.label} ${msgConst.number}`;
    } else {
        delete field.onChangeError;
    }
}
export const maxValue = (field, eventValue, maximumValue, length) => {
    if(parseFloat(eventValue) > maximumValue){
        const value = findMaxValue(eventValue, length, maximumValue);
        field['value'] = value;
        field['onChangeError'] = `The ${eventValue} ${msgConst.maxValuMessage} ${maximumValue}`;
    }
}
export const dataTypeNUM = (field, eventValue, datatype) => {
    let maxvalue = "";
    let maxvalueobj = field.attributes.find( (attribute) => attribute.attributeId === field.id +"_maxvalue" );
    maxvalue = maxvalueobj?.value;
    let length = maxvalue?.length;
    
    let maximumValue = parseFloat(maxvalue); 
    if(datatype === "NUM"){
        let minmaxerror = true;
        dataTypeNumValue(field, eventValue, maxvalue, maximumValue);
        minMaxError(field, eventValue, minmaxerror);
        maxValue(field, eventValue, maximumValue, length);
    }
}

export const dataTypeALSP = (field, eventValue, datatype) => {
    if(datatype === "AL+SP"){
        if (eventValue === "" || specialChar.test(eventValue)) {
            field['value'] = eventValue;
        }

        if(field['value'] !== eventValue && !specialChar.test(eventValue)) {
            field['onChangeError']  = `${field.label} ${msgConst.characterOrSymbols}`;
        } else {
            delete field.onChangeError;
        }
        
    }
}
export const dataTypeNONE = (field, eventValue, datatype) => {
    if(datatype === "NONE"){
        field['value'] = eventValue;
    }   
}

//for text area
export const handleChangedInputForTextArea = (field, event) => {
    field.dbSavedValue = '';
    let datatype = "";
    let datatypehobj = field.attributes.find( (attribute) => attribute.attributeId === field.id +"_datatype" );
    datatype = datatypehobj?.value;
    
    dataTypeAL(field, event, datatype);
    dataTypeNUM(field, event, datatype);
    dataTypeALSP(field, event, datatype);
    dataTypeNONE(field, event, datatype);
}

export const handleChangedInputForTextField = (field, event) => {
    field.dbSavedValue = '';
    let eventValue = event.target.value;
    let datatype = "";
    let datatypehobj = field.attributes.find( (attribute) => attribute.attributeId === field.id +"_datatype" );
    datatype = datatypehobj?.value;
    
    dataTypeAL(field, eventValue, datatype);
    dataTypeNUM(field, eventValue, datatype);
    dataTypeALSP(field, eventValue, datatype);
    dataTypeNONE(field, eventValue, datatype);
}

export const handleTimeInput = (event, field) => {
    field.dbSavedValue = '';
    field.value = event.target.value;
    return field;
}

export const findDataBaseValue = (field, ID) => {
    let valueArray = '';
    field.options.forEach((option) => {
        if(option[ID] === field.dbSavedValue){
            valueArray = option.value;
        }
    }) 
    return valueArray;
}

export const databaseValue = (value, field) => {
    const { type } = field;
    let valueArray = '';
    switch (type) {
        case "Time":
            valueArray = value[0] + ' '+ value[1];
        break;

        case "ReferralSourceDropDown":
            valueArray = findDataBaseValue(field, 'referralSourceId');
        break;

        case 'ProgramDropDown':
            valueArray = findDataBaseValue(field, 'programId');
        break;

        case 'StaffDropDown':
            valueArray = findDataBaseValue(field, 'staffId');
        break;
        default:
        break;
    }
    return valueArray;
}

export const handleDateTimeInput = (value, dateTimeField) => {
    dateTimeField.dbSavedValue = '';
    dateTimeField.value = getFormatedValue(value, dateTimeField);
    return dateTimeField;
}

export const convertToDayjs = (dbSavedValue) => {
    let dateTime = '';
    if(dbSavedValue && dbSavedValue.length > 0) {
        let datearray = dbSavedValue[0].split("-");
        let months = {'Jan': '01', 'Feb': '02', 'Mar': '03', 'Apr': '04', 'May': '05',
                    'Jun': '06', 'Jul': '07', 'Aug': '08', 'Sep': '09', 'Oct': '10', 'Nov': '11',
                    'Dec': '12'};
        let newdate = months[datearray[1]] + '/' + datearray[0] + '/' + datearray[2];
        dateTime = newdate + ' ' + dbSavedValue[1] + ' ' + dbSavedValue[2];
        dateTime = convertToLuxonDate(dateTime);
    }
    return dateTime;
}

 export const convertToDatejs = (dbSavedValue) => {
    let date = '';
    if(dbSavedValue) {
        let datearray = dbSavedValue.split("-");
        let months = {'Jan': '01', 'Feb': '02', 'Mar': '03', 'Apr': '04', 'May': '05',
                    'Jun': '06', 'Jul': '07', 'Aug': '08', 'Sep': '09', 'Oct': '10', 'Nov': '11',
                    'Dec': '12'};
        let newdate = months[datearray[1]] + '/' + datearray[0] + '/' + datearray[2];
        date = newdate;
        date = convertToLuxonDate(date);
    }
    return date;
 }

 export const handleDateInput = (value, dateField) => {
    dateField.dbSavedValue = '';
    dateField.value = getFormatedValue(value, dateField);
    return dateField;
 }

export const getFormatedValue = (evt, field) => {
    let value = evt?.target ? evt?.target?.value : evt;
    const { type } = field;
    switch (type) {
        case "Time":
            value = value.split(' ');
        break;

        case "DateTime":
            let dateTimeInstance = '';
            // In case of date or time not picked
            if(field.timeValue && field.dateValue) {
                let stringDate = '';
                if(field.dateChanged) {
                    stringDate = getDate(value);
                    dateTimeInstance = combineDateAndTime(stringToDate(stringDate).startOf('day'), field.timeValue);
                } else {
                    stringDate = getDate(field.dateValue);
                    dateTimeInstance = combineDateAndTime(stringToDate(stringDate).startOf('day'), value);
                }
                value = dayjs(dateTimeInstance).format('DD-MMM-YYYY hh:mm A');
                value = valueIntoArray(value);
            } else {
                value = undefined;
            }
        break;

        case "Date":
            if(value) {
                let date = `${value.toLocaleString('en-US', { year: 'numeric', month:'2-digit',day:'2-digit' })}`;
                value = dayjs(date).format('DD-MMM-YYYY');
            }
        break;

        case 'CheckBox':
        case 'Descriptor CheckBox':
            value = field.selectedOption;
        break;
        
        case "Text Area":
            value = evt?.target?.value.replaceAll('\n', '\r\n');
        break;

        default:
        break;
    }
    return value;
}

export const getDate = (dateTime) => {
    return `${dateTime.toLocaleString('en-US', { year: 'numeric', month:'2-digit',day:'2-digit' })}`;
}

export const valueIntoArray = (value) => {
    let valueArray = [];
    valueArray = value.split(' ');
    return valueArray;
}

export const handleChangedInputForCheckbox = (field, event) => {
    let prevSelectedOptions = [];
    field['oldValue'] = [];

    if (field['dbSavedValue'] && field['dbSavedValue'].length > 0) {
        prevSelectedOptions = [...field['dbSavedValue']];
    } else if(field['selectedOption'] && field['selectedOption'].length > 0) {
        prevSelectedOptions = [...field['selectedOption']];
    }

    field['oldValue'] = [...prevSelectedOptions];

    field.options.forEach(option => {
        if (event.target.checked && option.value === event.target.value) {
            prevSelectedOptions.push(event.target.value);
            field['selectedOption'] = prevSelectedOptions;
        } else if (!event.target.checked && option.value === event.target.value && prevSelectedOptions.includes(option.value)) {
            const foundIndex = prevSelectedOptions.indexOf(event.target.value);
            prevSelectedOptions.splice(foundIndex, 1);
            field['selectedOption'] = prevSelectedOptions;
        }
    });
    field.dbSavedValue = [];
}


export const handleSSNInput = (event, field) => {
    field.dbSavedValue = '';
    let value = event.target.value;
    validateSSN(field, value);
    const maxCharacters = event.target.maxLength
    
    let valueLength = value.length;
    if(valueLength <= maxCharacters) {
        value = modifyValue(field, valueLength, value);
        field.options[0].value = value
        field.value = value;
    }


    return field;
}

export const modifyValue = (field, valueLength, value) => {
    let inputArray = value.split('-');
    if(valueLength === 3 || valueLength === 6) {
        if((valueLength === 3 && field.value?.length !== 4) || (valueLength === 6 && inputArray.length < 3 && field.value?.length !== 7)) {
            value = value  + "-";
        } else if(field.value?.length === 4) {
            //  if SSNField's length is 4 then the data is with - eg.(123-). If user tried to delete number
            //  in that case we have to remove number not only "-"
            value = value.slice(0, 2);
        } else if(field.value?.length === 7) {
            //  if SSNField's length is 7 then the data is with - eg.(123-45-). If user tried to delete number
            //  in that case we have to remove number not only "-"
            value = value.slice(0, 5);
        }
    }
    value = handleDeleteOfChar(field, value, valueLength);
    return value;
}

export const handleDeleteOfChar = (field, value, valueLength) => {
    if(field.value?.length > 0) {
        if((value[3] !== field.value[3]) && (valueLength >= 4)) {
            if(valueLength < field.value?.length) {
                value = value.slice(0, 2) + value.slice(3);
            }
            let ssnInput = value.replace(/-/g,"");
            let pos = 3;
            value = ssnInput.substring(0, pos) + "-" + ssnInput.substring(pos, ssnInput.length);
        }
        if((value[6] !== field.value[6]) && (valueLength >= 7)) {
            if(valueLength < field.value?.length) {
                value = value.slice(0, 5) + value.slice(6);
            }
            let ssnInput = value.replace(/-/g,"");
            let pos = 6;
            value = ssnInput.substring(0, 3) + "-" + ssnInput.substring(3, ssnInput.length);
            value = value.substring(0, pos) + "-" + value.substring(pos, value.length);
        }
    }
    return value;
}

export const validateSSN = (field, value) => {
    const re = /[0-9\b]{3}-[0-9\b]{2}-[0-9\b]{4}/;
    // To show invalid SSN field message
    if((value?.length > 0) && (value?.length <= 11) && !(re.test(value))) {
        field['onChangeError'] = `${msgConst.validSSNFieldMsg}`;
    } else {
        delete field.onChangeError;
    }
    return field;
}