import { DateTime } from "luxon";
import syncService from "../../../common/services/syncService";
import {
    appointmentHasErrors,
    documentNotRequired,
    getFilteredServiceDocuments,
    getMobileAppointments
} from "../../../common/utils/appointmentUtils";
import { evvRepository } from "../../../db/evv";
import { SERVICE_DOCUMENT_STATUS_EXTERNAL_SIGN_NEEDED } from "../serviceDocumentUtils";
import { documentCache } from "../../../cache/slices/document/documentCache";

export const getTasks = async(appointment, documentCrosswalks, serviceDocuments, staff) => {
    let temp;
    let tempArr = [];

    const status = await isIncompleteTask(appointment, documentCrosswalks, serviceDocuments, staff);
    const openSession = await isOpenSession(appointment);

    if (status !== false) {
        temp = await getTaskDataForStatus(appointment, status);
        if (temp) {
            tempArr.push(temp);
        }
    }

    if (openSession !== false) {
        temp = await getTaskDataForStatus(appointment, openSession);
        if (temp) {
            tempArr.push(temp);
        }
    }

    return tempArr;
}

export const getTaskData = async (appointments, documentCrosswalks, serviceDocuments, setTaskDataArr, staff, activities, dispatch) => {
    appointments = getMobileAppointments(appointments, activities);
    if (appointments.length > 0) {
        dispatch(documentCache.setTasksLoadingStatus(true));
        let taskDataArr = [];

        await Promise.all(appointments?.map(async(appointment) => {
            let tempArr = await getTasks(appointment, documentCrosswalks, serviceDocuments, staff);
            if (tempArr && tempArr?.length > 0) {
                taskDataArr = taskDataArr.concat(tempArr);
            }
        }));
        setTaskDataArr(taskDataArr);
        dispatch(documentCache.setTasksLoadingStatus(false));
    }
}

export const getTaskDataForStatus = async(appointment, status) => {
    let taskData = {
        client : appointment?.client,
        startDateInstance : appointment?.startDateInstance,
        appointmentId : appointment?.appointmentId,
        status : status,
        appointment: appointment
    };
    return taskData;
}

export const organizationDb = () =>{
    return evvRepository?.evvDb?.organization?.toArray();
}

export const getOrganizations = async () => {
    let organizations = await organizationDb();
    return organizations;
}

export const isIncompleteTask = async(appointment, documentCrosswalks, serviceDocuments, staff) => {
    const oneminuteLater = appointment?.endDateInstance.plus({ minutes: 1 });
    let status;

    if (appointment?.status === "None" &&  DateTime.now() > oneminuteLater) {
        status = "";
    } else if (await isMissingDoc(appointment, documentCrosswalks, serviceDocuments, staff)) {
        status = "Missing Service Document";
    } else {
        return false;
    }

    return status;
}

export const isOpenSession = async(appointment) => {
    let status;
    if (appointment?.status === "In Session") {
        status = "Open Session"; 
    } else {
        return false;
    }
    return status;
}

export const getStatus = (appointment) => {
   const status = appointment?.status;
   return status;
}

export const isMissingDoc = async(appointment, documentCrosswalks, serviceDocuments, staff) => {
    const missingStatusArr = ["In Session", "Kept", "Co-Staff"];
    let docType = 'Missing';
    if ( (appointment?.hadDocument === "N") && missingStatusArr.includes(appointment.status)
        && await hasMulDocCrosswalked(appointment, documentCrosswalks, serviceDocuments, docType, staff)
        && !await hasAttachedDoc(appointment)
        ) 
    {
        return true;
    } else {
        return false;
    }
}

export const hasServiceDocCheck = async(appointment) => {
    let hasServiceDoc = await syncService.documentService.checkHasServiceDoc(appointment);
    return hasServiceDoc;
}

export const hasAttachedDoc = async(appointment) => {
    let doc = await syncService.documentService.checkHasAttachedDoc(appointment);
    return doc;
}

export const hasMulDocCrosswalked = async(appointment, documentCrosswalks, serviceDocuments, docType, staff) => {
    let hasMulDocCrosswalkedFlag = false;
    if (appointment && !appointmentHasErrors(appointment)){
        hasMulDocCrosswalkedFlag = await showDocumentsFromCrosswalk(appointment, documentCrosswalks, serviceDocuments, docType, staff)
        return hasMulDocCrosswalkedFlag;
    } else {
        return false;
    }
}

export const MissingCheck = (filteredServiceDocuments) => {
    //If single document is crosswalked && it is 'No Documentation Required' one, it will not be considered under missing document case
    return ( filteredServiceDocuments.length > 1 || (filteredServiceDocuments.length === 1 && !documentNotRequired(filteredServiceDocuments[0])) );
}

export const showDocumentsFromCrosswalk =  async (appointment, documentCrosswalks, serviceDocuments, docType, staff) => {
    const filteredServiceDocuments = await filterDocumentCrosswalksMissing(appointment, documentCrosswalks, serviceDocuments, staff);
    if( filteredServiceDocuments && ( (docType === 'Missing' && MissingCheck(filteredServiceDocuments)) || (docType === 'NewDocument' && filteredServiceDocuments.length > 1) ) ) {
        return true;
    }
    return false;
}

export const filterDocumentCrosswalksMissing = async (appointment, documentCrosswalks, serviceDocuments, staff) => {
    let filteredCrosswalks, filteredServiceDocuments;
    filteredCrosswalks = await syncService.organizationService.getRecordsForOrganization(documentCrosswalks, appointment.organizationId, false, true);

    if (filteredCrosswalks) {
        filteredServiceDocuments = await getFilteredServiceDocuments(appointment, filteredCrosswalks, serviceDocuments, staff);
    }

    return filteredServiceDocuments;
}

export const createOrganizationHierarchy = (documentCrosswalks, organizationId, organizations) => {
    let filteredCrosswalks;

    if (organizations) {
        let organizationHierarchy = syncService.organizationService.buildOrgHierarcy(organizations);
        if (organizationHierarchy.orgById && organizationHierarchy.orgById[organizationId]) {
            filteredCrosswalks = getFilteredCrosswalks(documentCrosswalks, organizationId, organizationHierarchy);
        }
    }

    return filteredCrosswalks;
}

export const getFilteredCrosswalks = (documentCrosswalks, organizationId, organizationHierarchy) => {
    const organization = organizationHierarchy.orgById[organizationId];
    const organizations = [];

    syncService.organizationService.getDescendants(organization, organizations, true);
    const organizationIds = organizations.map(org => org.organizationId);

    return documentCrosswalks.filter(dc => organizationIds.includes(dc.organizationId));
}

export const getTaskDataForExternalSign = (evvDocuments) => {
    if (evvDocuments?.length > 0) {
        let taskDataArr = evvDocuments.filter(doc => doc?.docSignaturePadList && doc?.docSignaturePadList?.length > 0)
            .map(doc => {
                const filteredList = doc?.docSignaturePadList?.filter(docSignObj =>
                    docSignObj?.signedDateTime === "" &&
                    docSignObj?.unableReason === "" &&
                    docSignObj?.unableToCollectYN === ""
                );

                if (filteredList.length > 0) {
                    return {
                        ...doc,
                        status: SERVICE_DOCUMENT_STATUS_EXTERNAL_SIGN_NEEDED
                    };
                }
                return doc;
            })
        return taskDataArr;
    }
    return [];
};