import {createSlice} from '@reduxjs/toolkit'
import { removePdfTabsFn } from '../../../common/utils/miscUtils';

const defaultServiceDocument = {
    status: 'Unsigned',
    validStaffSignature: '123456',
    signatureConfiguration: {
        numberOfStaffSignatures: 1,
        minimumNumberOfStaffSignatures: 1,
        multipleSignature: "N",
        numberOfExternalSignatures: 0
    }
};

const defaultData = {
};

export const documentSlice = createSlice({
    name: 'document',
    initialState: {
        crosswalkedDocuments: [],
        currentDocument: defaultServiceDocument,
        currentData: defaultData,
        currentDocumentId: undefined,
        currentAptDocument: null,
        serviceDocumentsForTabs: [{description:'All Documents'}],
        selectedDocTab: null,
        selectedDocTabGlobal: 0,
        evvDocuments: [],
        signedDocuments: [],
        attachedDoc: [],
        tasksLoadingStatus: false
    },
    reducers: {
        setCrosswalkedDocuments: (state, action) => {
            state.crosswalkedDocuments = action.payload;
        },
        setCurrentDocument: (state, action) => {
            state.currentDocument = action.payload;
        },
        setCurrentData: (state, action) => {
            state.currentData = action.payload;
        },
        setCurrentDocumentId: (state, action) => {
            state.currentDocumentId = action.payload;
        },
        setCurrentAptDocument: (state, action) => {
            state.currentAptDocument = action.payload;
        },
        setServiceDocumentsForTabs: (state, action) => {
            state.serviceDocumentsForTabs = action.payload;
        },
        setSelectedDocTab: (state, action) => {
            state.selectedDocTab = action.payload;
        },
        setSelectedDocTabGlobal: (state, action) => {
            state.selectedDocTabGlobal = action.payload;
        },
        setEvvDocuments: (state, action) => {
            state.evvDocuments = action.payload;
        },
        setSignedDocuments: (state, action) => {
            state.signedDocuments = action.payload;
        },
        removePdfTabs: (state, action) => {
            removePdfTabsFn(state, action);
        },
        setAttachedDoc: (state, action) => {
            state.attachedDoc = action.payload;
        },
        setTasksLoadingStatus: (state, action) => {
            state.tasksLoadingStatus = action.payload;
        }
    }
})

export const documentCache = {
    ...documentSlice.actions,

    getCurrentDocument: (state) => state.document.currentDocument,
    getCurrentData: (state) => state.document.currentData,
    getCrosswalkedDocuments: (state) => state.document.crosswalkedDocuments,
    getCurrentDocumentId: (state) => state.document.currentDocumentId,
    getCurrentAptDocument: (state) => state.document.currentAptDocument,
    getServiceDocumentsForTabs: (state) => state.document.serviceDocumentsForTabs,
    getSelectedDocTab: (state) => state.document.selectedDocTab,
    getSelectedDocTabGlobal: (state) => state.document.selectedDocTabGlobal,
    getEvvDocuments: (state) => state.document.evvDocuments,
    getSignedDocuments: (state) => state.document.signedDocuments,
    getAttachedDocuments: (state) => state.document.attachedDoc,
    getTasksLoadingStatus: (state) => state.document.tasksLoadingStatus
}

export default documentSlice.reducer;
