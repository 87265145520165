import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import {makeStyles} from "@material-ui/core/styles";
import {useState} from "react";
import { PRIMARY_COLOR } from '../../../common/constants';

const useStyles = makeStyles((theme) => ({
    accordion: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    accordionHeader: {
        display: 'flex',
        width: '100%',
        height: '22px',
        paddingTop: '2px',
        paddingBottom: '3px',
        backgroundColor: "#f6f6f6",
        alignItems: 'center'
    },
    upDownImage: {
        width: '48px',
        height: '48px'
    },
    expanded: {
        // transform: 'rotate(0deg)'
    },
    collapsed: {
        // transform: 'rotate(180deg)'
    },
    accordionTitle: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '15px',
            fontWeight: "bold",
            fontStyle: "normal",
            letterSpacing: 0,
            color: PRIMARY_COLOR
        },
        [theme.breakpoints.up(601)]: {
            fontSize: '21px',
            fontWeight: "bold",
            fontStyle: "normal",
            letterSpacing: 0,
            color: PRIMARY_COLOR
        }
    },
    accordionContentCollapsed: {
        display: 'none'
    },
    accordionContentExpanded: {
        display: 'block'
    }
}));

export default function DateAccordion({title, initialExpanded = false, children, onExpand, onCollapse, onChange}) {
    const [expanded, setExpanded] = useState(!!initialExpanded);
    const styles = useStyles();

    const handleChange = (e) => {
        if (onChange){
            onChange(expanded);
        }

        if (!expanded && onExpand){
            onExpand()
        }

        if (expanded && onCollapse){
            onCollapse()
        }

        setExpanded(!expanded);
    }

    return (
        <div className={styles.accordion}>
            <div className={styles.accordionHeader} >
                {title && <div data-testid='DateAccordion_title' className={styles.accordionTitle}>{title}</div>}
                <div className={expanded ? styles.expanded : styles.collapsed}>
                    {expanded
                        ? <ArrowDropUpIcon data-testid='DateAccordion_collapse' classes={{root: styles.upDownImage}} onClick={handleChange} />
                        : <ArrowDropDownIcon data-testid='DateAccordion_expand' classes={{root: styles.upDownImage}} onClick={handleChange} />
                    }
                </div>
            </div>
            <div className={expanded ? styles.accordionContentExpanded : styles.accordionContentCollapsed}>
                {children}
            </div>
        </div>
    );
}