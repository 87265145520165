import {makeStyles} from "@material-ui/core/styles";
import MiniCalendar from "../../../common/components/MiniCalendar";
import {useSelector} from "react-redux";
import {scheduleCache} from "../../../cache/slices/schedule/scheduleCache";
import CalendarViewNavigationBar from "../../schedule/ScheduleTitleBar/CalendarViewNavigationBar";

const useStyles = makeStyles((theme) => ({
    dashboardCalendar: {
        ...theme.shadowedContainer
    },
    calendar: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: "#f6f6f6"
    },
}));

export default function DashboardCalendar() {
    const viewDateForSchedule = useSelector(scheduleCache.getViewDate);
    const styles = useStyles();

    return (
        <div className={styles.dashboardCalendar} >
            <div className={styles.calendar} >
                <CalendarViewNavigationBar viewDate={viewDateForSchedule}/>
                <MiniCalendar viewDate={viewDateForSchedule} showMonth={true} />
            </div>
        </div>
    )
}
