import LoginTextField from "../../../common/components/LoginTextField";
import React from "react";
import FormField from "./FormField";
import DisplayErrorMessages from "../common/DisplayErrorMessages";
import "./PhoneField.css";
import { changeFocus } from "../common/ConfigurableFormsUtils";
import { assignDbSavedValue } from "../common/FormFieldsUtils";

class PhoneField extends FormField{
    getValue = (index) => {
        let phoneValue = this.fieldDescriptor?.dbSavedValue;
        if (phoneValue) {
            this.fieldDescriptor = assignDbSavedValue(this.fieldDescriptor, phoneValue);
        }
        return this.fieldDescriptor.options[index].value;
    }

    textFieldTemplate = (props, textField, index, width, disabled) => {
        return (
            <LoginTextField
                {...props}
                type="text"
                id={textField.id}
                name={textField.name}
                value={ this.getValue(index) }
                placeholder={disabled ? ' ': textField.placeholder}
                required={textField.required}
                maxCharacters={textField.maxCharacters}
                maxLength={textField.maxCharacters}
                phoneField={textField}
                width={width}
                onChange={(event) => { props.onChange(event, this.fieldDescriptor, props); changeFocus(this.fieldDescriptor, index); } }
                disabled = { disabled }
            />
        )
    }
    setPhoneFieldAttribute = (phoneField, isRequired, fieldId) => {
        phoneField.forEach((textField, index) => {
            textField.id = `${fieldId}-text${index}`;
            if(textField.name === "countryCode" || textField.name === "phoneNumber") {
                textField.required = isRequired;
            } 
        })
    }

    renderField(props, state){
        let isRequired = this.isMandatory();
        let description = "";
        let filteredObj = this.fieldDescriptor.attributes.find((attribute) => attribute.attributeId === this.fieldDescriptor.id +"_description");
        if(Object.keys(filteredObj).length > 0) {
            description = filteredObj.value;
        }
        this.setPhoneFieldAttribute(this.fieldDescriptor.options, isRequired, this.fieldDescriptor.id);
        props.fieldDescriptor = this.fieldDescriptor;

        return (
            <div>
                {this.fieldDescriptor?.businessRuleApplied !== "Hide" &&
                <>
                <div>
                    <span>{description}</span>
                </div>
                <div className="phoneField">
                    <div>
                        {this.textFieldTemplate(props, this.fieldDescriptor.options[0], 0, '57px', state.isReadOnlyDoc)}
                    </div>

                    <div className="dash">-</div>

                    <div>
                        {this.textFieldTemplate(props, this.fieldDescriptor.options[1], 1, '103px', state.isReadOnlyDoc)}
                    </div>

                    <div className="ext">Ext.</div>

                    <div>
                        {this.textFieldTemplate(props, this.fieldDescriptor.options[2], 2, '74px', state.isReadOnlyDoc)}
                    </div>
                </div>
                <div>
                    <DisplayErrorMessages
                        {...props} 
                    />
                </div>
                </>
                }
            </div>
        )
    }
}

export default PhoneField;