import api from "./api";
import { StaffEndpoints } from "../constants";

class StaffService {

  async callReadPinOffLine() {
    try {
      const parameters = { };

      return await api.post(StaffEndpoints.READ_PIN, { json: parameters });
    } catch (error) {
      console.error('An error occurred in callReadPinOffLine : ' + error);
      throw error;
    }
  }

  async callWritePinOffLine(pin) {
    try {
      const parameters = {
        resources: [
          { pin }
        ]
      };

      return await api.post(StaffEndpoints.WRITE_PIN, { json: parameters });
    } catch (error) {
      console.error('An error occurred in callWritePinOffLine : ' + error);
      throw error;
    }
  }

  async callReadSignature() {
    try {
      const parameters = { };

      return await api.post(StaffEndpoints.READ_SIGNATURE, { json: parameters });
    } catch (error) {
      console.error('An error occurred in callReadSignature : ' + error);
      throw error;
    }
  }

  async callReadSecurityRule() {
    try {
      const parameters = { };

      return await api.post(StaffEndpoints.READ_SECURITY_RULE, { json: parameters });
    } catch (error) {
      console.error('An error occurred in callReadSecurityRule : ' + error);
      throw error;
    }
  }

  async callWriteSignature(electronicSignature) {
    try {
      const parameters = {
        resources: [
          { electronicSignature }
        ]
      };

      return await api.post(StaffEndpoints.WRITE_SIGNATURE, { json: parameters });
    } catch (error) {
      console.error('An error occurred in callWriteSignature : ' + error);
      throw error;
    }
  }

}

export default StaffService;
