import {appCache} from "../../cache/slices/app/appSlice";
import api from "./api";
import {transformServerResult} from "../utils/addressUtils";
import {formatAddress} from "../utils/formatUtils";

const TOMTOM_SEARCH_GEOCODE = '/evv/search/';

export class GeocodingService {
    searchForAddresses(dispatch, addressQuery){
        dispatch(appCache.fetchAddressesStart());

        return api.get(TOMTOM_SEARCH_GEOCODE + encodeURIComponent(addressQuery) + ".json?countrySet=US&typeahead=true&view=Unified&limit=5")
            .then(results => {
                dispatch(appCache.fetchAddressesSuccess(results));
                return Promise.resolve(results);
            })
            .catch(error => {
                console.log(error);
                return Promise.resolve({ignore: true});
            });
    };

    findAddress(dispatch, address){
        const addressQuery = formatAddress(address, "Apt ");
        return api.get(TOMTOM_SEARCH_GEOCODE + encodeURIComponent(addressQuery) + ".json?countrySet=US&view=Unified&limit=5")
            .then(response => {
                let foundAddress = address;
                if (response.results && response.results.length > 0){
                    const foundResult = response.results.find(result => {
                        return (
                            result.address &&
                            result.type === 'Point Address' &&
                            (result.address.streetNumber === '' || address.street1?.indexOf(result.address.streetNumber) !== -1) &&
                            // result.address?.municipality?.toUpperCase() === address.city?.toUpperCase() &&
                            // result.address?.countrySubdivision?.toUpperCase() === address.state?.toUpperCase() &&
                            result.address?.postalCode === address.zip
                        );
                    });

                    if (foundResult){
                        foundAddress = transformServerResult(foundResult, address);
                    }
                }
                return Promise.resolve(foundAddress);
            })
            .catch(error => {
                console.log(error);
                return Promise.resolve(address);
            });
    };
}

const geocodingService = new GeocodingService();

export default geocodingService;

