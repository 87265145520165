export const  setDateTimeValue = (fieldDescriptor, newValue, dateValue, startTime, isDateChanged) => {
    let result = {
        dateValue : dateValue,
        startTime : startTime,
        fieldDescriptor : fieldDescriptor
    };
    if(newValue) {
        if(isDateChanged) {
            fieldDescriptor.dateChanged = true;
            result.fieldDescriptor.dateValue = newValue;
            let date = `${newValue.toLocaleString('en-US', { year: 'numeric', month:'2-digit',day:'2-digit' })}`;
            result.dateValue = date;
            dateValue = date;
        } else {
            fieldDescriptor.dateChanged = false;
            result.startTime = newValue;
            startTime = newValue;
            result.fieldDescriptor.timeValue = startTime;
        }
    }
    return result;
}