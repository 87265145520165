import { FormControl, FormControlLabel, Grid } from "@material-ui/core";
import TextAreaComponent from "../../features/forms/fields/TextAreaComponent";
import EvvRadio from "../components/EvvRadio";
import EvvSelect from "../components/EvvSelect";
import { IMPACT } from "../constants";
import impactService from "../services/impactService";
import CheckboxForImpact from "../../features/forms/Impact/CheckBoxForImpact";
import StackedField from "../components/StackedField";
import { orderBy } from "lodash";
import LoginTextField from "../components/LoginTextField";

export const isImpact = (module) => {
    return ( module?.type === IMPACT );
}

export const getImpactConfigData = async(module, setImpactConfig) => {
    if (module) {
        let impactConfig = await impactService.getImpactConfig(module?.moduleReferenceId);
        if (impactConfig) {
            setImpactConfig(impactConfig);
            module.impactConfig = { ...impactConfig };
        }
    }
}

export const getDbModuleData = (props) => {
    return (props?.documentController?.appointmentDocument && props?.documentController?.appointmentDocument?.length > 0) ? props?.documentController?.appointmentDocument[0]?.moduleData : [];
}

export const getDbAnswerValue = (question, props) => {
    let dbFieldValue = "";
    let dbModuleData = getDbModuleData(props);

    if (dbModuleData && dbModuleData.length > 0) {
        let dbModule = dbModuleData.find(mod => props?.module?.moduleId === mod.moduleId);
        let dbFieldData = dbModule?.fieldData;
        if (dbModule && dbFieldData && dbFieldData.length > 0) {
            let dbField = dbFieldData.find(field => field?.key === question?.id);
            if (dbField) {
                dbFieldValue = dbField.value;
                question.selectedAnswer = dbFieldValue;
            }
        }
    }

    return dbFieldValue;
}

export const getAnswerValue = (question, props) => {
    let answerValue = "";

    if(props?.module?.notAssessed === "N") {
        if (question?.isUpdated) {
            answerValue = question?.selectedAnswer ? question?.selectedAnswer : "";
        } else {
            answerValue = getDbAnswerValue(question, props);
        }
    }

    assignAnsKey(question, answerValue);

    return answerValue;
}

export const refreshUi = (setRefreshCount) => {
    if (setRefreshCount) {
        setRefreshCount((prevCount) => ({
            refreshCount: prevCount + 1
        }))
    }
}

export const isChecked = (key, question, props) => {
    let isChecked = false;

    if(props?.module?.notAssessed === "N") {
        if (question?.isUpdated) {
            isChecked = key === question?.selectedAnswer;
        } else {
            let answerValue = getDbAnswerValue(question, props);
            if (answerValue) {
                isChecked = answerValue.includes(key);
                if (isChecked) {
                    assignAnsKey(question, answerValue);
                }
            }
        }
    }

    return isChecked;
}

export const getAnswerComponent = (section, question, isHandheld, handleFieldChange, setRefreshCount, props) => {
    let answerComponent;
    let controlType = question?.controlType;
    let isReadOnly = props?.isReadOnly;
    let breakPoints = props?.breakPoints;
    let options = question?.values;
    if (options && options.length > 0) {
        options = orderBy(options, option => +option.sequence);
    }

    switch(controlType) {
        case "CHECKBOX":
            answerComponent = (<CheckboxForImpact isHandheld = { isHandheld } options = { options } section = { section } question = { question } setRefreshCount = { setRefreshCount } props = { props } />)
        break;

        case "TEXTAREA":
            answerComponent = (
                <>
                    <TextAreaComponent
                        id = {`impact-textArea-${ question?.id }`}
                        name = {`impact-textArea-${ question?.id }`}
                        maxCharacters = { 4000 }
                        rows = { 5 }
                        cols = { 5 }
                        value = { getAnswerValue(question, props) }
                        onChange = {(event) => { handleFieldChange(event, section, question, props) } }
                        disabled = { isReadOnly }
                    />
                    <span style = {{ width: '100%' }}>{`Max:${ 4000 } characters.`}</span>
                </>
            );
        break;

        case "DROPDOWN":
            answerComponent = (
                <EvvSelect
                    id = { `impact-dropDown-${ question?.id }` }
                    value = { getAnswerValue(question, props) }
                    onChange = {(event) => { handleFieldChange(event, section, question, props) } }
                    disabled = { isReadOnly }
                    >
                    {<option value = '0'>Select</option>}
                    {options && options?.length > 0 && 
                        options?.map((item, index) =>
                        <option key = { `dropdown-option-${index}` } value = { item.key } >{ item.text }</option>
                    )}
                </EvvSelect>
            );
        break;

        default:
            if (options && options?.length > 0) {
                //Radio Button
                answerComponent = (
                    <StackedField paddingTop="0px">
                        { options?.map(({id, text, key}, index) => {
                            return (
                                <div key = { index }>
                                    <FormControl component = "fieldset">
                                        <FormControlLabel
                                            control = {<EvvRadio
                                                id = { `impact-radio-${ question?.id }-${ id }` }
                                                name = { `impact-radio-${ question?.id }-${ id }` }
                                                value = { key }
                                                checked = { isChecked(key, question, props) }
                                                onChange = {(event) => { handleFieldChange(event, section, question, props) } }
                                                disabled = { isReadOnly }
                                            />}
                                            label = { text }
                                        />
                                    </FormControl>
                                </div>
                            )
                        }) }
                    </StackedField>
                );
            } else {
                //Text Box
                answerComponent = (
                    <LoginTextField
                        id = {`impact-textfield-${ question?.id }`}
                        name = {`impact-textfield-${ question?.id }`}
                        value = { getAnswerValue(question, props) }
                        onChange = {(event) => { handleFieldChange(event, section, question, props) } }
                        disabled = { isReadOnly }
                    />
                );
            }
        break;
    }

    if (isReadOnly && controlType === "CHECKBOX") {
        return <Grid container>{ answerComponent }</Grid>;
    } else {
        return <Grid container xs = { breakPoints.xsValue } sm = { breakPoints.smValue } >{ answerComponent }</Grid>;
    }
}

export const isScoringField = (question) => {
    let scoring = false;
    if (question) {
        if ( question?.controlType === "DROPDOWN" || (question?.controlType === "" && question?.values && question?.values?.length > 0) ) {
            scoring = true;
        }
    }
    return scoring;
}

export const assignAnsKey = (targetQuestion, answer) => {
    if (targetQuestion && answer && isScoringField(targetQuestion)) {
        targetQuestion.ansKey = Number(answer);
    }
}

export const assignAnsKeyMulti = (targetQuestion, selectedCheckbox) => {
    let totalKeyScore = 0;
    if (targetQuestion && selectedCheckbox && selectedCheckbox?.length > 0) {
        selectedCheckbox?.forEach((ele) => {
            totalKeyScore += Number(ele);
        });
        targetQuestion.ansKey = totalKeyScore;
    }
}

export const getIndividualScore = (sectSequence, queSequence, impactConfig) => {
    let score = 0;
    let sections = impactConfig?.sections;

    if (sections && sections?.length > 0) {
        let sectionData = impactConfig?.sections?.find(sec => sec?.sequence === sectSequence);
        if (sectionData) {
            let questionData = sectionData?.questions?.find(ques => ques?.sequence === queSequence);
            if (questionData?.ansKey) {
                score = questionData?.ansKey;
            }
        }
    }

    return score;
}

export const getSectQueScore = (exp, impactConfig, score) => {
    let queSequence = Number(exp?.substring(exp?.indexOf('q') + 1));
    let expSectStr = exp?.substring(0, exp?.indexOf('q'));
    let sectSequence = expSectStr?.split('sect')[1];

    score = getIndividualScore(sectSequence, queSequence, impactConfig);

    return score;
}

export const sectionQuestionOperations = (expression, impactConfig, splitBy, call) => {
    let score = 0;
    let operandsArr = [];

    let expressionArr = expression?.split(splitBy);
    if (expressionArr?.length > 0) {
        expressionArr?.forEach((exp) => {
            operandsArr.push(getSectQueScore(exp, impactConfig, score));
        });

        if (call === "addition") {
            score = operandsArr.reduce((a, b) => a + b, 0);
        } else if (call === "maximum") {
            score = Math.max(...operandsArr);
        } else {
            //average
            let sum = operandsArr.reduce((a, b) => a + b, 0);
            score = (sum / operandsArr?.length) || 0;
        }
    }

    return score;
}

export const subtotal = (expression, impactConfig) => {
    let score = 0;
    let expressionArr = [];
    let operator;

    if (expression.includes('/')) {
        //Format: subtotal1/11
        operator = "/";
        expressionArr = expression.split("/");
    } else if (expression.includes('*')) {
        //Format: subtotal2*10
        operator = "*";
        expressionArr = expression.split("*");
    }

    if (expressionArr?.length > 0) {
        let subTotals = impactConfig?.subTotals;
        let sequenceParam = Number(expressionArr[0].split('subtotal')[1]);
        let actionByParam = Number(expressionArr[1]);

        if (subTotals && subTotals.length > 0) {
            let subTotal = subTotals?.find(st => st?.sequence === sequenceParam);
            if (subTotal && subTotal?.calculatedScore) {
                let actionOnParam = subTotal?.calculatedScore;
                if (operator === "/") {
                    score = actionOnParam / actionByParam;
                } else {
                    score = actionOnParam * actionByParam;
                }
            }
        }
    }

    return score;
}

export const assignSubtotal = (moduleSubtotal, subTotal, calculatedScore) => {
    if (moduleSubtotal) {
        let moduleSubtotalObj = {key: subTotal?.id, value: calculatedScore };
        let subtotalEntry = moduleSubtotal?.find(st => st?.key === subTotal?.id);
        if (subtotalEntry) {
            const foundIndex = moduleSubtotal?.indexOf(subtotalEntry);
            moduleSubtotal[foundIndex] = moduleSubtotalObj;
        } else {
            moduleSubtotal.push(moduleSubtotalObj);
        }
    }
}

export const assignTotalScore = (module) => {
    let totalScore = 0;
    let expression = module?.impactConfig?.expression?.toLowerCase();

    if (module?.impactConfig && expression) {
            if(expression?.startsWith('(') && expression?.endsWith(')')) {
                expression = expression?.slice(1, -1);
            }

            let startsWithSect = expression?.startsWith('sect');
            let startsWithSubtotal = expression?.startsWith('subtotal');
            let containsComplexMath = expression?.includes('/');
    
            if (startsWithSect) {
                //Format: sect4q21 + sect4q22 + sect4q23...
                if (expression) {
                    expression = expression?.replace(" + ", "+");
                }
                totalScore = sectionQuestionOperations(expression, module?.impactConfig, "+", 'addition');
            } else if (startsWithSubtotal) {
                //Format: subtotal2*10
                totalScore = subtotal(expression, module?.impactConfig);
            } else if (containsComplexMath) {
                //Format: (((sect1q1*186047)+(sect1q2*261202))/1000000)
                totalScore = evaluateSectQueExpression(expression, module?.impactConfig);  
            }

            module.totalScore = roundToTwoDecimalPlaces(totalScore);
    }
}

export const maximum = (expression, impactConfig) => {
    //The score is determine by which is the higher value
    let sectQExp = expression?.substring(expression?.indexOf('(') + 1, expression?.indexOf(')'));
    let score = sectionQuestionOperations(sectQExp, impactConfig, ",", 'maximum');
    return score;
}

export const meanExist = (expression, impactConfig) => {
    //The score is determine by adding the values for the Section/question and then determining the average value
    let sectQExp = expression?.substring(expression?.indexOf('(') + 1, expression?.indexOf(')'));
    let score = sectionQuestionOperations(sectQExp, impactConfig, ",", 'average');
    return score;
}

export const roundToTwoDecimalPlaces = (value) => {
    let roundedValue = Math.round(value * 100) / 100;
    
    if (Number.isInteger(roundedValue)) {
        return roundedValue;
    }
    return parseFloat(roundedValue.toFixed(2));
};

export const calculateScoreFn = (impactConfig, module) => {
    let calculatedScore = 0;
    let subTotals = impactConfig?.subTotals;

    if (impactConfig && subTotals && subTotals?.length > 0) {
        subTotals?.forEach((subTotal) => {
            let expression = subTotal?.expression ? subTotal?.expression?.toLowerCase() : subTotal?.expression;
            let startsWithSect = expression?.startsWith('sect');
            let startsWithSubtotal = expression?.startsWith('subtotal');
            let startsWithMaximum = expression?.startsWith('maximum');
            let startsWithMeanExist = expression?.startsWith('meanexist');
            let moduleSubtotal = module?.subTotal;
            let containsComplexMath = expression?.includes('/');
    
            if (startsWithSect) {
                //Format: sect4q21 + sect4q22 + sect4q23...
                if (expression) {
                    expression = expression.replace(" + ", "+");
                }
                calculatedScore = sectionQuestionOperations(expression, impactConfig, "+", 'addition');
            } else if (startsWithSubtotal) {
                //Format: subtotal1/11, subtotal2*10
                calculatedScore = subtotal(expression, impactConfig);
            } else if (startsWithMaximum) {
                //Format: maximum(sect1q1,sect1q2,sect1q3)
                calculatedScore = maximum(expression, impactConfig);
            } else if (startsWithMeanExist) {
                //Format: meanExist(sect1q1,sect1q2,sect1q3)
                calculatedScore = meanExist(expression, impactConfig);
            } else if (containsComplexMath) {
                //Format: (((sect1q1*186047)+(sect1q2*261202))/1000000)
                calculatedScore = evaluateSectQueExpression(expression, impactConfig);
            }

            subTotal.calculatedScore = roundToTwoDecimalPlaces(calculatedScore);
            assignSubtotal(moduleSubtotal, subTotal, subTotal.calculatedScore);
        });
    }
}

export const evaluateSectQueExpression = (expression, impactConfig) => {
    let totalScore = 0;
    let operandsArr = [];
    let divisor = 1;
    
    // Split by "/" to separate expression and divisor
    expression = expression?.split('/');
    let newExpression = expression[0];
    divisor = Number(expression[1].replace(")", ""));
    // Remove the enclosing parentheses
    let startIndex = newExpression.indexOf('(') + 1;
    let endIndex = newExpression.lastIndexOf(')');
    let expWithoutBrackets = newExpression.slice(startIndex, endIndex);  // e.g. "((sect1q1186047)+(sect1q2261202))"

    // Split by "+" to handle addition
    let terms = expWithoutBrackets.split('+');

    terms.forEach(term => {

        while (term.startsWith('(') && term.endsWith(')')) {
            term = term.slice(1, -1);
        }

        // Split the term by "*" to get section-question and multiplier
        let expressionArr = term.split('*');

        let sectQue = expressionArr[0]?.trim(); // e.g., "sect1q1"
        let multiplier = Number(expressionArr[1]?.trim()); // e.g., 186047

        let baseScore = getSectQueScore(sectQue, impactConfig, 0);

        // Multiply the base score by the multiplier and add to operandsArr
        let termScore = baseScore * multiplier;
        operandsArr.push(termScore);
    });

    // Sum up all term scores in operandsArr
    totalScore = operandsArr.reduce((a, b) => a + b, 0);
    const finalScore = totalScore / divisor;

    return finalScore;
};

export const onChangeUpdates = (question, props) => {
    let fieldData = props?.module?.fieldData;
    let value = question?.selectedAnswer;
    let fieldDataObj = { key: question?.id, value };

    question.isChanged = true;
    question.isUpdated = true;

    if (fieldData) {
        let fieldDataExists = fieldData?.find(ele => ele?.key === question?.id);
        if (fieldDataExists) {
            const foundIndex = fieldData?.indexOf(fieldDataExists);
            fieldData[foundIndex] = fieldDataObj;
        } else {
            fieldData.push(fieldDataObj);
        }
    }
}

export const checkUpdatedFieldImpact = (module) => {
    let updated  = false;

    module?.impactConfig?.sections?.forEach((sec) => {
        if (!updated) {
            let isQuesChanged = sec?.questions?.filter(ques => ques.isChanged === true);
            if (isQuesChanged && isQuesChanged.length > 0) {
                updated = true;
            }
        }
    });

    return updated;
}

export const getSelectedOptions = (question, props) => {
    let selectedOptions = [];

    if(props?.module?.notAssessed === "N") {
        if (question?.isUpdated) {
            selectedOptions = question?.selectedAnswer ? question?.selectedAnswer : [];
        } else {
            let answerValue = getDbAnswerValue(question, props);
            selectedOptions = answerValue ? answerValue : [];
        }
    }

    assignAnsKeyMulti(question, selectedOptions);

    return selectedOptions;
}

export const assignInitialData = (props) => {
    let fieldData = [];
    let subTotal = [];
    let score = 0;
    let notAssessed = "N";

    let dbWholeModuleData = getDbModuleData(props);
    if (dbWholeModuleData) {
        let dbModuleData = dbWholeModuleData?.find(mod => props?.module?.moduleId === mod.moduleId);
        if (dbModuleData) {
            if (dbModuleData?.fieldData) {
                fieldData = dbModuleData?.fieldData;
            }
            if (dbModuleData?.notAssessed) {
                notAssessed = dbModuleData?.notAssessed;
            }
        }
    }

    props.module.fieldData = fieldData;
    props.module.subTotal = subTotal;
    props.module.totalScore = score;
    props.module.notAssessed = notAssessed;
}

export const assignInitialTotalScore = (module) => {
    if (module) {
        assignTotalScore(module);
    }
}

export const assignNotAssessed = (updateNotAssessed, props) => {
    let update = "N";

    if (!updateNotAssessed) {
        let dbModuleData = getDbModuleData(props);
        if (dbModuleData && dbModuleData.length > 0) {
            let dbModule = dbModuleData.find(mod => props?.module?.moduleId === mod.moduleId);
            let dbNotAssessed = dbModule?.notAssessed;
            if(dbModule && dbNotAssessed) {
                update = dbNotAssessed;
            }
        }
    }
    return update;
}

export const isCurrentModule = (currentPage, module) => {
    return currentPage?.moduleId === module?.moduleId;
}