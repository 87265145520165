import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './react-big-calendar.css';
import App from './features/app/App';
import store from './cache/store'
import {Provider} from 'react-redux';
import {register} from './swr';
import {getSystemInformation} from "../src/common/utils/systemUtils";
import { homeScreenRotationForMobile, webRotationForMobile } from './features/app/orientationUtils';
import OIDCProvider from './features/app/AuthProvider/OIDCProvider';

setNewRelicFile();

const systemInformation = getSystemInformation();
const isIOs = systemInformation.osName === 'macOS' || systemInformation.osName === 'iOS';
const isFullScreen = window.navigator.standalone; // This is IOS (Web && Home Screen). Android will require other approach
let deviceType = systemInformation.platformType;
let portrait = window.matchMedia("(orientation: portrait)");
let landscape = window.matchMedia("(orientation: landscape)");
let hostname = window.location.hostname;

if (deviceType === 'Tablet' && portrait.matches && !isFullScreen) {
    document.body.classList.add('rotateScreen');
}

if(deviceType === 'Mobile' && landscape.matches) {
    document.body.classList.add('portrait');
}

window.addEventListener("orientationchange" , function(event) {
    if (deviceType === 'Tablet') {
        let portraitRotated = window.matchMedia("(orientation: portrait)");
        if (isIOs) {
            // This is for IOs devices
            if (isFullScreen || systemInformation.browserName === 'Chrome') {
                homeScreenRotation(portraitRotated);
            }
            else {
                if (systemInformation.browserName === 'Safari'){
                    webRotation(portrait);
                }
            }
        }
        else {
            // This is for Andriod devices
            webRotation(portrait);
        }
    } else if(deviceType === 'Mobile'){
        let landscapeRotated = window.matchMedia("(orientation: landscape)");
        if (isIOs) {
            // This is for IOs devices
            if (isFullScreen || systemInformation.browserName === 'Chrome') {
                homeScreenRotationForMobile(landscapeRotated);
            }
            else {
                if (systemInformation.browserName === 'Safari'){
                    webRotationForMobile(landscape);
                }
            }
        }
        else {
            // This is for Andriod devices
            webRotationForMobile(landscape);
        }
    }
});

function webRotation(portrait) {
    // This validations section is web
    if (portrait.matches) {
        document.body.classList.remove('rotateScreen');
    } else {
        document.body.classList.add('rotateScreen');
    }
}

function homeScreenRotation(portraitRotated) {
    // This validations section is for Home Screen
    if (portraitRotated.matches) {
        document.body.classList.add('rotateScreen');
    } else {
        document.body.classList.remove('rotateScreen');
    }
}

function setNewRelicFile() {
    let elem = document.createElement("script");
    let rootWeb = document.location.host;
    
    switch(rootWeb) {
      case 'evv-mobile-dev.qualifacts.org':
        elem.src = "./newrelic/nragent_mobile_dev.js";
        break;  
      case 'evv-mobile-kony-uat.qualifacts.org':
        elem.src = "./newrelic/nragent_kony_uat.js";
        break;
      case 'evv-dev.qualifacts.org/':
        elem.src = "./newrelic/nragent_dev.js";
        break;      
      case 'evv-qareg.qualifacts.org':
        elem.src = "./newrelic/nragent_qareg.js";
        break;  
      case 'evv-cert.qualifacts.org':
        elem.src = "./newrelic/nragent_cert.js";
        break;
      case 'evv-perf.qualifacts.org':
        elem.src = "./newrelic/nragent_perf.js";
        break;
      case 'evv.qualifacts.org':
        elem.src = "./newrelic/nragent_prod.js";
        break;  
      default:
        elem.src = "./newrelic/nragent_kony_uat.js";
    }
    document.body.appendChild(elem);
}

if (hostname.startsWith("mobile")) {
    ReactDOM.render(
        <Provider store={store}>
            <OIDCProvider>
                <App />
            </OIDCProvider>
        </Provider>,
        document.getElementById("root")
    );
} else {
    ReactDOM.render(
        <Provider store={store}>
            <App />
        </Provider>,
        document.getElementById("root")
    );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
