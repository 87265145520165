import StackedField from "../../../common/components/StackedField";
import LoginTextField from "../../../common/components/LoginTextField";
import React from "react";
import { Grid } from "@material-ui/core";
import DisplayErrorMessages from "../common/DisplayErrorMessages";
import { getXSAndSMValueForField } from "../common/Util";
import { getLatestCopyFwdDoc, getLatestSignedDoc, getValueByDoc, getValueByForm } from "../../../common/utils/documentUtils";

const SUPPORTED_PROPS = [
    {
        name: 'key'
    },
    {
        name: 'onChange'
    },
    {
        name: 'module'
    },
    {
        name: 'selectedClient'
    }
];

export default class FormField{
    constructor(descriptor, index){
        this.fieldDescriptor = descriptor;
        this.attributes = this.populateAttributes(descriptor);
        this.stacked = true;
        this.index = index;
        this.hasCopyForward();
    }

    populateAttributes(descriptor){
        const attributes = {};

        if (descriptor.attributes){
            descriptor.attributes.forEach(a => {
                if (a.attribute){
                    attributes[a.attribute] = a;
                }
            })
        }

        return attributes;
    }

    getId(){
        return this.fieldDescriptor.id;
    }

    getType(){
        return this.fieldDescriptor.type;
    }

    getName(){
        return this.fieldDescriptor.name;
    }

    getLabel(){
        let label = "";
        // If a business rule Action indicates that the field must be hidden, then hide label completely
        if(this.fieldDescriptor?.businessRuleApplied !== "Hide") {
            label = this.attributes.label;
            if (!label){
                label = this.fieldDescriptor.label;
            } else {
                label = label.value;
            }
        }
        return label;
    }

    getOrder(){
        return this.fieldDescriptor.order;
    }

    getIndex(){
        return this.index;
    }

    isMandatory(){
        let isMandatory = false;

        // If a business rule indicates that the field must be hidden it will not be the mandatory field
        if (this.fieldDescriptor?.businessRuleApplied === "Hide") {
            return false;
        }

        if ( (this.fieldDescriptor.businessRuleApplied && this.fieldDescriptor.businessRuleApplied === "Require") || (this.attributes.required && this.attributes.required.value === "true") ) {
            isMandatory = true;
        }

        return isMandatory;
    }

    getValue(){
        return '';
    }

    getCopyForwardValue = async(obj) => {
        let { selectedClientId, evvDocuments, formId, moduleId, staffId, signedDocuments, isOffline } = this.fieldDescriptor;
        let serviceDocName = this.fieldDescriptor.serviceDocument.description;
        let fieldId = this.fieldDescriptor.id;

        if (obj.type === "EnableCopyForward") {
            let copyForwardDocArr = [];
            let latestSignedDocArr = await getLatestSignedDoc(staffId, selectedClientId, serviceDocName, evvDocuments);
            let latestSignedDBDocArr = await getLatestSignedDoc(staffId, selectedClientId, serviceDocName, signedDocuments);

            if (isOffline) {
                copyForwardDocArr = [...latestSignedDBDocArr];
            } else {
                copyForwardDocArr = getLatestCopyFwdDoc(latestSignedDocArr, latestSignedDBDocArr);
            }

            if (copyForwardDocArr.length > 0) {
                await getValueByDoc(copyForwardDocArr, formId, moduleId, fieldId, this.fieldDescriptor);
            }
        } else {
            //enableCopyForwardAll case
            await getValueByForm(staffId, selectedClientId, formId, moduleId, fieldId, evvDocuments, this.fieldDescriptor, signedDocuments, isOffline);
        }
    }

    hasCopyForward = async() => {
        let enableCopyForwardObj = { enable: false };
        let enableCopyForward = this.attributes.EnableCopyForward;
        let enableCopyForwardAll = this.attributes.EnableCopyForwardAll;

        let copyForwardType = enableCopyForwardAll ? enableCopyForwardAll : enableCopyForward ? enableCopyForward : undefined;
        if (copyForwardType) {
            let attributeName = copyForwardType.attribute;
            let attributeValue = copyForwardType.value;

            if (attributeValue === "Y") {
                enableCopyForwardObj = { enable: true, type: attributeName };
            }
        }

        if (enableCopyForwardObj.enable) {
            await this.getCopyForwardValue(enableCopyForwardObj);
        }
    }

    handleChange = (evt, onChange, props) => {
        if (onChange){
            onChange(evt, this.fieldDescriptor, props);
        }
    }

    renderStackedContainer(props, state){
        let isHandheld = props.documentController?.dataProvider?.isHandheld();
        let changedPortrait = window.matchMedia("(orientation: portrait)");
        let changedOrientation = changedPortrait.matches ? "portrait" : "landscape";
        const {key, ...restOfProps} = props;
        let paddingTop = '26px';
        const configuredFieldProps = this.configureFieldProps(restOfProps);
        configuredFieldProps.isHandheld = isHandheld;
        configuredFieldProps.orientation = changedOrientation;
        let label = this.getLabel();

        if(this.fieldDescriptor?.businessRuleApplied === "Hide") {
            paddingTop = '0px';
        }

        if(this.fieldDescriptor.type === 'ReadOnlyText' || this.fieldDescriptor.type === 'Form Break') {
            label = undefined;
        }
        return (
            <div key={key}>
                <StackedField  mandatory={this.isMandatory()} label={label} paddingTop={paddingTop}>
                    {this.renderField(configuredFieldProps, state)}
                </StackedField>
            </div>
        );
    }

    renderField(props, state){
        let breakPoints = getXSAndSMValueForField(props.isHandheld, props.orientation, 12, 6);
        let isRequired = this.isMandatory();
        props.fieldDescriptor = this.fieldDescriptor;
        return (
            <div>
                <Grid container item={true} xs={breakPoints.xsValue} sm={breakPoints.smValue}>
                    <LoginTextField
                        {...props}
                        id={'textField-' + this.fieldDescriptor.id}
                        name={'textField-' + this.fieldDescriptor.id}
                        value={this.fieldDescriptor.value}
                        required={isRequired}
                        onChange={(event) => { props.onChange(event, this.fieldDescriptor, props); } }
                    />
                    <div>
                        <DisplayErrorMessages
                            {...props}
                        />
                    </div>
                </Grid>
            </div>
        );
    }

    getSupportedProps(){
        return [...SUPPORTED_PROPS];
    }

    configureFieldProps(props){
        const supportedProps = this.getSupportedProps();
        const configuredProps = {};

        supportedProps.forEach(supportedProp => {
            let propValue = props[supportedProp.name];
            let propName = supportedProp.propertyName || supportedProp.name;

            if (!propValue){
                const attribute = this.attributes[supportedProp.name];

                if (attribute && attribute.value){
                    propValue = attribute.value;
                }
            }

            if (!propValue){
                if (supportedProp.defaultValue){
                    propValue = supportedProp.defaultValue;
                }
            }

            if (propValue){
                configuredProps[propName] = propValue;
            }
        })

        return configuredProps;
    }

    render(props, state){
        const externalChangeHandler = props.onChange;
        props.onChange = (evt) => this.handleChange(evt, externalChangeHandler, props);

        return this.renderStackedContainer(props, state);
    }
}