import { CONVERTDD } from "../../../common/constants";
import { formatBirthDate } from "../../../common/utils/formatUtils";

export const renderParagraph = (readonlyText) => {
    readonlyText = readonlyText.replace(/(?:\r\n|\r|\n)/g, '<br>');

    let text = readonlyText.split('<br>').map((text, index) => {
        return <p key={ index }>{ text }</p>
    });
    return text;
}


export const assignDbSavedValue = (fieldDescriptor, dbValue) => {
    switch (fieldDescriptor.type) {
        case 'SSN':
            fieldDescriptor.value = dbValue;
            fieldDescriptor.options[0].value = dbValue;
            break;

        case 'Phone':
        case 'Address':
            if(dbValue?.length > 0) {
                fieldDescriptor.options.forEach((option, index) => {
                    option.value = dbValue[index];
                })
            }
            break;

        default:
            break;
    }
    return fieldDescriptor;
}

export const getColumnName = (column) => {
    let splitStr = column.split("_");
    let dbStr = '';

    splitStr.forEach( (str, index) => {
        str = str.toLowerCase();
        if (index !== 0) {
            str = str.charAt(0).toUpperCase() + str.slice(1);
        }

        dbStr = dbStr.concat(str);
    });

    return dbStr;
}

export const getDscidColumnValue = (dsc, selectedClient, descriptorData) => {
    let columnValue = '';

    if (selectedClient[dsc] !== '') {
        //Check in descriptor table
        let descriptorId = selectedClient[dsc];
        if (descriptorData && descriptorData.length > 0) {
            let descriptor = descriptorData.find( desc => desc.descriptorId ===  descriptorId);
            if (descriptor) {
                columnValue = descriptor?.name;
            }
        }
    }

    return columnValue;
}

export const getPhysAddrToDisplay = (columnData, call) => {
    let columnText = '';
    let columnArray = [];
    const addrColumns = ['street1', 'street2', 'aptSuite', 'city', 'state', 'zip', 'zipPlusFour'];

    addrColumns.forEach( (col) => {
        if (columnData && columnData[col] !== undefined && columnData[col] !== '') {
            columnText += columnData[col] + '<br>';
            if(call === 'DbValue') {
                columnArray.push(columnData[col]);
            }
        }
        if (call === 'DbValue' && columnData && !columnData[col]) {
            columnArray.push('');
        }
    });

    let columnValue = columnText?.split('<br>').map((text, index) => {
        return <div key = { index }> { text } </div>
    });

    return call === 'DbValue' ? columnArray : columnValue;
}

export const columnValueCases = (columnName, selectedClient, call) => {
    let columnValue = '';

    switch(columnName) {
        case 'clientNumber':
            columnValue = selectedClient['idNumber'];
        break;

        case 'physicalAddress':
            let columnData = selectedClient['addresses'].find( addr => addr.type === 'PHYS');
            columnValue = getPhysAddrToDisplay(columnData, call);
        break;

        case 'mailingAddress':
        // code block
        break;

        case 'workAddress':
        // code block
        break;

        default:
    }

    return columnValue;
}

export const getColumnValue = (columnName, selectedClient, descriptorData, call) => {
    let columnValue = '';
    let dsc = columnName.concat('DscId');

    if (selectedClient[columnName] !== undefined) { //It's a normal column
        if(columnName === "birthDate") {
            columnValue = formatBirthDate(selectedClient[columnName]);
        } else {
            columnValue = selectedClient[columnName];
        }
    } else if (selectedClient[dsc] !== undefined) { //It's a DSCID column
        columnValue = getDscidColumnValue(dsc, selectedClient, descriptorData);
    } else {
        columnValue = columnValueCases(columnName, selectedClient, call);
    }

    return columnValue;
}

export const renderLookUpField = (column, selectedClient, descriptorData, call) => {
    if (column !== '') {
        let columnName = getColumnName(column);
        let columnValue = getColumnValue(columnName, selectedClient, descriptorData, call);
        return columnValue;
    }
}

export const getFormattedValue = (descriptor, value) => {
    let formattedValue;
    switch(descriptor.type) {
        case 'Address':
        case 'Phone':
            let temp = [];
            descriptor.options.forEach((option) => {
                let tempVal = (option.value) ? option.value : '';
                temp.push(tempVal);
            });
            formattedValue = temp;
            break;

        default:
            formattedValue = value;
    }
    return formattedValue;
}

export const setCopyForwardValue = (fieldDescriptor, formattedVal, doc) => {
    fieldDescriptor.copyForwarded = false;

    if ( fieldDescriptor.dbSavedValue === undefined ) {
        fieldDescriptor.copyForwarded = true;
        fieldDescriptor.copyForwardedValue = formattedVal;
        fieldDescriptor.copyForwardedDocId = doc.id;
        fieldDescriptor.dbSavedValue = formattedVal;
        console.log(`Copy Forwarded ${fieldDescriptor?.type} Field From DocumentId: ${doc?.id}`);
    }
}

export const formatLatestVal = (fieldDescriptor, value, doc) => {
    let { type } = fieldDescriptor;
    let formattedVal = '';

    switch(type) {
        case 'Time':
        case 'DateTime':
        case 'CheckBox':
        case 'Descriptor CheckBox':
        case 'Address':
        case 'Phone':
            formattedVal = value;
            setCopyForwardValue(fieldDescriptor, formattedVal, doc);
        break;

        default:
            if (CONVERTDD.includes(type)) {
                formattedVal = Number(value[0]);
            } else {
                formattedVal = value[0];
            }
            setCopyForwardValue(fieldDescriptor, formattedVal, doc);
        break;
    }
}