import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './style.css';

class PickerColumn extends Component {
    static propTypes = {
        options: PropTypes.array.isRequired,
        name: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired,
        itemHeight: PropTypes.number.isRequired,
        columnHeight: PropTypes.number.isRequired,
        startFindIndex: PropTypes.number.isRequired,
        optionsPerRange: PropTypes.number.isRequired,
        onChange: PropTypes.func.isRequired,
        onClick: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            isMoving: false,
            startTouchY: 0,
            startScrollerTranslate: 0,
            ...this.computeTranslate(props)
        };

        this.findIndex = props.startFindIndex;
        this.optionsPerRange = props.optionsPerRange;
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.isMoving) {
            return;
        }
        this.setState(this.computeTranslate(nextProps));
    }

    computeTranslate = (props) => {
        const {options, value, itemHeight, columnHeight} = props;
        let selectedIndex = this.findIndex > 0 ? options.indexOf(value, this.findIndex) : options.indexOf(value);
        if (selectedIndex < 0) {
            // throw new ReferenceError();
            console.warn('Warning: "' + this.props.name+ '" doesn\'t contain an option of "' + value + '".');
            this.onValueSelected(this.findIndex > 0 ? options[this.findIndex] : options[0]);
            selectedIndex = this.findIndex > 0 ? this.findIndex : 0;
        }
        return {
            scrollerTranslate: columnHeight / 2 - itemHeight / 2 - selectedIndex * itemHeight,
            minTranslate: columnHeight / 2 - itemHeight * options.length + itemHeight / 2,
            maxTranslate: columnHeight / 2 - itemHeight / 2
        };
    };

    onValueSelected = (newValue) => {
        this.props.onChange(this.props.name, newValue);
    };

    handleTouchStart = (event) => {
        const startTouchY = event.targetTouches[0].pageY;
        this.setState(({scrollerTranslate}) => ({
            startTouchY,
            startScrollerTranslate: scrollerTranslate
        }));
    };

    safePreventDefault = (event) =>{
        const passiveEvents = ['onTouchStart', 'onTouchMove', 'onWheel'];
        if(!passiveEvents.includes(event._reactName)) {
            event.preventDefault();
        }
    }

    handleTouchMove = (event) => {
        this.safePreventDefault(event);
        const touchY = event.targetTouches[0].pageY;
        this.setState(({isMoving, startTouchY, startScrollerTranslate, minTranslate, maxTranslate}) => {
            if (!isMoving) {
                return {
                    isMoving: true
                }
            }

            let nextScrollerTranslate = startScrollerTranslate + touchY - startTouchY;
            if (nextScrollerTranslate < minTranslate) {
                nextScrollerTranslate = minTranslate - Math.pow(minTranslate - nextScrollerTranslate, 0.8);
            } else if (nextScrollerTranslate > maxTranslate) {
                nextScrollerTranslate = maxTranslate + Math.pow(nextScrollerTranslate - maxTranslate, 0.8);
            }
            return {
                scrollerTranslate: nextScrollerTranslate
            };
        });
    };

    handleTouchEnd = (event) => {
        if (!this.state.isMoving) {
            return;
        }
        this.setState({
            isMoving: false,
            startTouchY: 0,
            startScrollerTranslate: 0
        });
        setTimeout(() => {
            const {options, itemHeight} = this.props;
            const {scrollerTranslate, minTranslate, maxTranslate} = this.state;
            let activeIndex;
            if (scrollerTranslate > maxTranslate) {
                activeIndex = 0;
            } else if (scrollerTranslate < minTranslate) {
                activeIndex = options.length - 1;
            } else {
                activeIndex = - Math.floor((scrollerTranslate - maxTranslate) / itemHeight);
                this.findIndex = Math.floor(activeIndex/this.optionsPerRange) * this.optionsPerRange;
            }
            this.onValueSelected(options[activeIndex]);
        }, 0);
    };

    handleTouchCancel = (event) => {
        if (!this.state.isMoving) {
            return;
        }
        this.setState((startScrollerTranslate) => ({
            isMoving: false,
            startTouchY: 0,
            startScrollerTranslate: 0,
            scrollerTranslate: startScrollerTranslate
        }));
    };

    handleItemClick = (option) => {
        if (option !== this.props.value) {
            this.onValueSelected(option);
        } else {
            this.props.onClick(this.props.name, this.props.value);
        }
    };

    renderItems() {
        const {options, itemHeight, value} = this.props;
        return options.map((option, index) => {
            const style = {
                height: itemHeight + 'px',
                lineHeight: itemHeight + 'px'
            };
            const className = `picker-item${option === value ? ' picker-item-selected' : ''}`;
            return (
                <div
                    key={index}
                    className={className}
                    style={style}
                    onClick={() => this.handleItemClick(option)}>{option}</div>
            );
        });
    }

    render() {
        const translateString = `translate3d(0, ${this.state.scrollerTranslate}px, 0)`;
        const style = {
            MsTransform: translateString,
            MozTransform: translateString,
            OTransform: translateString,
            WebkitTransform: translateString,
            transform: translateString
        };
        if (this.state.isMoving) {
            style.transitionDuration = '0ms';
        }
        return(
            <div className="picker-column">
                <div
                    className="picker-scroller"
                    style={style}
                    onTouchStart={this.handleTouchStart}
                    onTouchMove={this.handleTouchMove}
                    onTouchEnd={this.handleTouchEnd}
                    onTouchCancel={this.handleTouchCancel}>
                    {this.renderItems()}
                </div>
            </div>
        )
    }
}

export default class Picker extends Component {
    static propTyps = {
        optionGroups: PropTypes.object.isRequired,
        valueGroups: PropTypes.object.isRequired,
        optionRanges: PropTypes.array.isRequired,
        onChange: PropTypes.func.isRequired,
        onClick: PropTypes.func,
        itemHeight: PropTypes.number,
        height: PropTypes.number,
        startIndex: PropTypes.number,
        rangeCount: PropTypes.number
    };

    static defaultProps = {
        onClick: () => {},
        itemHeight: 36,
        height: 216,
        optionRanges: []
    };

    optionRangeByIndex(optionRanges, index) {
        let optionRange = {};

        if (optionRanges.length > index){
            optionRange = optionRanges[index];
        }

        return optionRange;
    }

    renderInner() {
        const {optionGroups, valueGroups, optionRanges, itemHeight, height, onChange, onClick} = this.props;
        const highlightStyle = {
            height: itemHeight,
            marginTop: -(itemHeight / 2)
        };
        const columnNodes = [];
        let index = 0;
        for (let name in optionGroups) {
            let optionRange = this.optionRangeByIndex(optionRanges, index);
            columnNodes.push(
                <PickerColumn
                    key={name}
                    name={name}
                    options={optionGroups[name]}
                    value={valueGroups[name]}
                    itemHeight={itemHeight}
                    columnHeight={height}
                    startFindIndex={optionRange.startFindIndex ? optionRange.startFindIndex : 0}
                    optionsPerRange={optionRange.optionsPerRange ? optionRange.optionsPerRange : 0}
                    onChange={onChange}
                    onClick={onClick} />
            );
            index++;
        }
        return (
            <div className="picker-inner">
                {columnNodes}
                <div className="picker-highlight" style={highlightStyle} />
            </div>
        );
    }

    render() {
        const style = {
            height: this.props.height
        };

        return (
            <div data-testid='MobilePicker' className="picker-container" style={style}>
                {this.renderInner()}
            </div>
        );
    }
}
