import {makeStyles} from "@material-ui/core/styles";
import {
    formatBirthDate,
    formatClientId,
    formatGender,
    formatLastSeenDate,
    formatUserName
} from "../../../common/utils/formatUtils";
import {useHistory} from "react-router-dom";
import {clientCache} from "../../../cache/slices/client/clientSlice";
import {useDispatch} from "react-redux";
import {getClientImage} from "../../../common/utils/imageUtils";
import clientService from "../../../common/services/clientService";
import {DateTime} from "luxon";
import { PRIMARY_COLOR } from "../../../common/constants";

const useStyles = makeStyles(() => ({
    caseloadCardContainer: {
        width: '100%',
        display: 'grid',
        rowGap: '14px',
        gridTemplateColumns: '12% 33% 29% 26%',
        padding: '14px 20px',
        "&:nth-child(odd)": {
            backgroundColor: "#ffffff",
        },
        "&:nth-child(even)": {
            backgroundColor: "#f4f4f4",
        }
    },
    imageColumn: {
        alignSelf: 'center'
    },
    image: {
        width: '60px',
        height: '60px',
        borderRadius: '50%'
    },
    clientNameColumn: {
        fontSize: '18px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: PRIMARY_COLOR,
        alignSelf: 'center'
    },
    clientInfoColumn: {
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'column'
    },
    clientInfoTopLine: {
        paddingBottom: '3px'
    },
    clientInfoLabel: {
        fontSize: '16px',
        fontWeight: "bold",
        fontStyle: "normal",
        lineHeight: '20px',
        letterSpacing: 0,
        paddingRight: '8px',
        color: "#4f4f4f"
    },
    clientInfoValue: {
        fontSize: '16px',
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: '20px',
        letterSpacing: 0,
        color: "#4f4f4f"
    }
}));

export default function TabletCaseloadCard({client}) {
    const dispatch = useDispatch()
    const history = useHistory();
    const styles = useStyles();

    const handleCaseloadClick = () => {
        clientService.visitClient(client, DateTime.now().toMillis());
        dispatch(clientCache.selectClient(client));
        history.push('/caseload/facesheet');
    }

    return (
        <div data-testid='TabletCaseloadCard_caseload' className={styles.caseloadCardContainer} onClick={handleCaseloadClick}>
            <div className={styles.imageColumn}>
                <img className={styles.image} src={getClientImage(client)} alt='client' />
            </div>
            <div className={styles.clientNameColumn}>
                {formatUserName(client)}
            </div>
            <div className={styles.clientInfoColumn}>
                <div className={styles.clientInfoTopLine}>
                    <span className={styles.clientInfoLabel}>DOB:</span>
                    <span className={styles.clientInfoValue}>{formatBirthDate(client.birthDate)}</span>
                </div>
                <div>
                    <span className={styles.clientInfoLabel}>ID:</span>
                    <span className={styles.clientInfoValue}>{formatClientId(client)}</span>
                </div>
            </div>
            <div className={styles.clientInfoColumn}>
                <div className={styles.clientInfoTopLine}>
                    <span className={styles.clientInfoLabel}>Gender:</span>
                    <span className={styles.clientInfoValue}>{formatGender(client.gender)}</span>
                </div>
                <div>
                    <span className={styles.clientInfoLabel}>Last Seen:</span>
                    <span className={styles.clientInfoValue}>{formatLastSeenDate(client.dateLastActivity)}</span>
                </div>
            </div>
        </div>
    )
}
