import clientRepository from "../../db/clientRepository";
import {appCache} from "../../cache/slices/app/appSlice";
import {DateTime} from "luxon";
import api from "./api";
import {getDispatchFromConfig, sortClients} from "../utils/miscUtils";
import { evvRepository } from "../../db/evv";

const EVV_READ_CLIENT = '/evv/read/client';
const EVV_READ_PICTURE = '/evv/read/picture';
const EVV_READ_EMAIL = '/evv/read/email';
const EVV_READ_TELEPHONE = '/evv/read/telephone';
const EVV_READ_ADDRESS = '/evv/read/address';

const fetchClients = (dispatch) => {
    dispatch(appCache.fetchClientsStart());
    if (api.isOfflineAtLogin()) {
        return load(dispatch);
    } else {
        const parameter = {};
        return Promise.all([
            api.post(EVV_READ_CLIENT, {json: parameter}),
            api.post(EVV_READ_PICTURE, {json: parameter}),
            api.post(EVV_READ_EMAIL, {json: parameter}),
            api.post(EVV_READ_TELEPHONE, {json: parameter}),
            api.post(EVV_READ_ADDRESS, {json: parameter}),
        ])
            .then(responses => {
                console.log("Fetch clients responses:");
                console.log(responses);

                return processClientResponses(responses, true)
                    .then((clients) => {
                        dispatch(appCache.fetchClientsSuccess(clients));
                    });
            })
            .catch(error => {
                console.log("Error fetching clients - attempting to load from db: ");
                console.log(error);

                return load(dispatch);
            });
    }
}

const load = (dispatch) => {
    return clientRepository.loadClients()
        .then((clients) => {
            console.log("Success loading clients...");
            console.log(clients);
            dispatch(appCache.fetchClientsSuccess(clients));
        })
        .catch((error) => {
            console.log("Error loading clients...");
            console.log(error);
            dispatch(appCache.fetchClientsSuccess([]));
        });
};

const getSearchableClients = (caseloads, appointments, clients) => {
    const searchableClients = [];
    const clientIds = new Set();

    if (clients && clients.length > 0) {
        if (caseloads) {
            caseloads.forEach(caseload => clientIds.add(caseload.clientId + ''));
        }
        if (appointments) {
            appointments.forEach(appointment => clientIds.add(appointment.clientId + ''));
        }

        clientIds.forEach(clientId => {
            // eslint-disable-next-line eqeqeq
            const client = clients.find(c => c.clientId == clientId);
            if (client) {
                searchableClients.push(client);
            }
        })
    }

    return sortClients(searchableClients);
}

const beforeClientSave = (key, client) => {
    let changedDateText = client.changedDate;

    client.clientId = '' + key;

    if (!changedDateText || changedDateText.length === 0) {
        if (client.audit && client.audit.changedDate && client.audit.changedDate.length > 0) {
            changedDateText = client.audit.changedDate;
        }
    }

    if (changedDateText && changedDateText.length > 0){
        const changedDate = new Date(changedDateText);
        client.changedDateMillis = DateTime.fromJSDate(changedDate).toMillis();
    }
}

const isErrorResponse = (response) => {
    if (response instanceof Response || response.errors) {
        if (response.errors && response.errors.length > 0) {
            return true;
        }
    }

    return false;
}

const fetchAddresses = (config, clients, setClientData) => {
    const clientById = createClientByIdMap(clients);
    const clientsToSave = [];

    api.post(EVV_READ_ADDRESS, {json: {}})
        .then(result => {
            processAddressResponse(result, clientById);

            Object.entries(clientById).forEach(([key, value]) => {
                beforeClientSave(key, value);
                clientsToSave.push(value);
            });

            if(setClientData) {
                setClientData(clientsToSave);
            }
            
            clientRepository.clearAndSaveClients(clientsToSave);

            const dispatch = getDispatchFromConfig(config);
            dispatch(appCache.fetchClientsSuccess(clientsToSave));
            return Promise.resolve(clientsToSave);
    });
};

const createClientByIdMap = (clients) => {
    const clientById = {};

    clients.forEach(clientFromServer => {
        const existingClient = clientById[clientFromServer.id] || {};

        clientById[clientFromServer.clientId] = {...existingClient, ...clientFromServer, addresses: []};
    });

    return clientById;
}

const processAddressResponse = (addressResponse, clientById) => {
    if (addressResponse.resources) {
        addressResponse.resources.forEach(addressFromServer => {
            const existingClient = clientById[addressFromServer.clientId] || {};

            if (!existingClient.addresses) {
                existingClient.addresses = [];
            }

            existingClient.addresses.push(addressFromServer);
        })
    } else if (isErrorResponse(addressResponse)) {
        console.log("Error fetching client addresses: " + addressResponse.errors[0].message);
    }
}

const processClientResponses = (responses, fullSyncFlag) => {
    const clientById = {};
    const clients = [];

    const clientResponse = responses[0];
    if (clientResponse.resources) {
        clientResponse.resources.forEach(clientFromServer => {
            const existingClient = clientById[clientFromServer.id] || {};

            clientById[clientFromServer.id] = {...existingClient, ...clientFromServer};
        })
    } else if (isErrorResponse(clientResponse)){
        console.log("Error fetching clients: " + clientResponse.errors[0].message);
    }

    const pictureResponse = responses[1];
    if (pictureResponse.resources) {
        pictureResponse.resources.forEach(pictureFromServer => {
            const existingClient = clientById[pictureFromServer.clientId] || {};

            clientById[pictureFromServer.clientId] = {...existingClient, ...pictureFromServer};
        })
    } else if (isErrorResponse(pictureResponse)){
        console.log("Error fetching client pictures: " + pictureResponse.errors[0].message);
    }

    const emailResponse = responses[2];
    if (emailResponse.resources) {
        emailResponse.resources.forEach(emailFromServer => {
            const existingClient = clientById[emailFromServer.clientId] || {};

            if (!existingClient.emails){
                existingClient.emails = [];
            }

            existingClient.emails.push(emailFromServer);
        })
    } else if (isErrorResponse(emailResponse)){
        console.log("Error fetching client emails: " + emailResponse.errors[0].message);
    }

    const phoneResponse = responses[3];
    if (phoneResponse.resources) {
        phoneResponse.resources.forEach(phoneFromServer => {
            const existingClient = clientById[phoneFromServer.clientId] || {};

            if (!existingClient.phones){
                existingClient.phones = [];
            }

            existingClient.phones.push(phoneFromServer);
        })
    } else if (isErrorResponse(phoneResponse)){
        console.log("Error fetching client phones: " + phoneResponse.errors[0].message);
    }

    const addressResponse = responses[4];
    if (addressResponse.resources) {
        addressResponse.resources.forEach(addressFromServer => {
            const existingClient = clientById[addressFromServer.clientId] || {};

            if (!existingClient.addresses){
                existingClient.addresses = [];
            }

            existingClient.addresses.push(addressFromServer);
        })
    } else if (isErrorResponse(addressResponse)){
        console.log("Error fetching client addresses: " + addressResponse.errors[0].message);
    }

    Object.entries(clientById).forEach(([key, value]) => {
        beforeClientSave(key, value);
        clients.push(value);
    });

    if (fullSyncFlag) {
        return clientRepository.clearAndSaveClients(clients);
    }else{
        return clientRepository.saveClients(clients);
    }
}

const visitClient = (client, lastVisitedMillis) => {
    return clientRepository.updateRecentClient(client, lastVisitedMillis);
}

const loadRecentClients = (dispatch, clients) => {
    dispatch(appCache.loadRecentClientsStart());
    clientRepository.loadRecentClients()
        .then(recentClientsFromDb => {
            let recentClients = [];
            if (clients){
                recentClientsFromDb.forEach(recentClient => {
                    const rc = clients.find(client => client.clientId === recentClient.clientId);
                    if (rc){
                        recentClients.push(rc);
                    }
                });

                for (let client of clients) {
                    if (recentClients.length < 4) {
                        const rc = recentClients.find(recentClient => recentClient.clientId === client.clientId);
                        if (!rc) {
                            recentClients.push(client);
                        }
                    }
                }
            }
            dispatch(appCache.loadRecentClientsSuccess(recentClients));
        })
        .catch(error => {
            console.log("Error fetching clients - attempting to load from db");
            dispatch(appCache.loadRecentClientsSuccess(clients.slice(0, 4)));
        });
}

const findClientById = (id) => {
    return evvRepository?.evvDb?.client.where({clientId: id}).toArray();
}

const findClientProgram = (id) => {
    return evvRepository?.evvDb?.clientProgram.where({clientProgramId: id}).toArray();
}

const findProgram = (programId, organizationId) => {
    return evvRepository?.evvDb?.program.where({programId: programId, organizationId: organizationId}).toArray();
}

const clientService = {
    fetchAddresses,
    fetchClients,
    visitClient,
    loadRecentClients,
    getSearchableClients,
    processClientResponses,
    findClientById,
    findClientProgram,
    findProgram
};

export default clientService;
