import React from "react";
import FormField from "./FormField";
import DisplayErrorMessages from "../common/DisplayErrorMessages";
import "./PhoneField.css";
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Stack from '@mui/material/Stack';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import TextField from '@mui/material/TextField';
import TimePickerField from "../../../common/components/TimePicker/TimePickerField";
import { defaultDateAsString, defaultTimeAsString } from "../../../common/utils/formatUtils";
import { convertToDayjs } from "../common/HandleChangedInputUtils";
import { setDateTimeValue } from "./DateTimeFieldUtils";
import { styled } from "@material-ui/styles";
const CancelText=styled('span')({
    textTransform:'initial'
});
const OkText=styled('span')({
    textTransform:'initial'
});

class DateTimeField extends FormField{

    constructor(props) {
        super(props);
        this.dateValue = '';
        this.startTime = '';
    }

    setDateValue = (newValue, isDateChanged) => {
        setDateTimeValue(this.fieldDescriptor, newValue, this.dateValue, this.startTime, isDateChanged)
    }

    handleEffectiveDateChange = (date) => {
        this.dateValue = `${date?.toLocaleString('en-US', { year: 'numeric', month:'2-digit',day:'2-digit' })}`;
    }

    getDateTimeValue = (call) => {
        let dateTimeInstance = convertToDayjs(this.fieldDescriptor?.dbSavedValue);
        if(dateTimeInstance) {
            this.dateValue = defaultDateAsString(dateTimeInstance);
            this.startTime = defaultTimeAsString(dateTimeInstance);
        }

        if (call === "date") {
            return this.dateValue;
        } else {
            return this.startTime;
        }
        
    }

    renderAppointmentDate = (props, isRequired, disabled) => {
        return (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack>
                    <MobileDatePicker
                        autoOK={true}
                        value={ this.getDateTimeValue('date') }
                        showToolbar={false}
                        onAccept={this.handleEffectiveDateChange}
                        onChange={(newValue) => {
                            this.setDateValue(newValue, true); props.onChange(newValue, this.fieldDescriptor, props);
                        }}
                        disabled = { disabled }
                        okText={<OkText>Ok</OkText>}
                        cancelText={<CancelText>Cancel</CancelText>}
                        renderInput={(params) =>
                            <TextField
                                id={`dateTime-${this.fieldDescriptor.id}`}
                                sx={{
                                    "& .MuiInputBase-input": {
                                      height: "10px",
                                      backgroundColor: "#ffffff",
                                      borderStyle: "none",
                                      borderWidth: 0,
                                      borderColor: "#ffffff",
                                      fontSize: '16px',
                                      fontWeight: "normal",
                                      fontStyle: "normal",
                                      letterSpacing: '.21px',
                                      color: "#888888",
                                    },
                                    "& .MuiInputBase-input:disabled": {
                                        height: "25px"
                                      },
                                }}
                                {...params}
                                error={false}
                                required={isRequired}
                                focused={false}
                            />}
                        />
                </Stack>
            </LocalizationProvider>
        );
    };

    renderAppointmentTime = (props, isRequired, disabled) => {
        return (
            <TimePickerField 
                id={`time- ${this.fieldDescriptor.id}`}
                required={isRequired}
                value={ this.getDateTimeValue('time') }
                onChange={(event) => { this.setDateValue(event.target.value, false); props.onChange(event, this.fieldDescriptor, props);}}
                disabled = { disabled }
            />
        );
    };

    renderField(props, state){
        let isRequired = this.isMandatory();
        let description = "";
        let filteredObj = this.fieldDescriptor.attributes.find((attribute) => attribute.attributeId === this.fieldDescriptor.id +"_description");
        if(Object.keys(filteredObj).length > 0) {
            description = filteredObj.value;
        }
        props.fieldDescriptor = this.fieldDescriptor;

        return (
            <div>
                {this.fieldDescriptor?.businessRuleApplied !== "Hide" &&
                <>
                    <div>
                        <span>{description}</span>
                    </div>
                    <div>
                        <div className="dateTimeField">
                            {this.renderAppointmentDate(props, isRequired, state.isReadOnlyDoc)}
                            {this.renderAppointmentTime(props, isRequired, state.isReadOnlyDoc)}
                        </div>
                    </div>
                    <div>
                        <DisplayErrorMessages
                            {...props} 
                        />
                    </div>
                </>
                }
            </div>
        )
    }
}

export default DateTimeField;