import { Accordion, makeStyles, AccordionSummary, AccordionDetails, FormControlLabel, Grid} from "@material-ui/core";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from "react";
import { getAccordionSummary, getCommentsValue, getConfigurations, getGAEle, getInterventionMapping, getPgoIntMapData, getPgoMapData, getPgoiData, getProblemMapping, getSelectedStatus, getSubHeader, getTxPlanDbData, getTxPlanName, initializePgoi, isChecked, updateElement, expandCollapse } from "../../../common/utils/goalsAddressedUtils";
import EvvCheckBox from "../../../common/components/EvvCheckBox";
import EvvSelect from "../../../common/components/EvvSelect";
import { ACCORDIANFONT, GACOMMENTSLIMIT } from "../../../common/constants";
import { getXSAndSMValueForField } from "../common/Util";
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';

/*
    Logic:
        1. First we need to get all problems, goals, objectives & interventions associated with the selected treatment plan - getPgoiData()
        2. Then we need to get the problems, goals & objectives mapping - getPgoMapData()
        3. Get interventions mapping - getPgoIntMapData()
        4. Then we can separate problems, goals, objectives & interventions - getGAEle()
        5. Render Problems - renderProblems()
        6. Render Goals associated with each problem - renderGoals()
        7. Render Objectives associated with each problem's goal - renderObjectives()
        8. Render linked & unlinked interventions - renderInterventions() & renderUnlinkedInterventionsUi()
            - When the Intervention is linked to an Objective then: goaladdressed-txplan/linkIntervention = 'Y'
        9. Fetch PGOI configurations - getConfigurations()
            - svcdoc/Goals Addressed module/configurations(rendered module configurations object)
            - svcdoc/Problems/Goals/Objectives module/configurations:
                - After selecting a treatment plan(checkbox checked), look for 'serviceDocSetupId' in 'goalAddressedTxPlan' table
                - Then search for the record with 'serviceDocSetupId'('goalAddressedTxPlan' table) = 'setupId'('ServiceDocument' table)
*/

const  useStyles = makeStyles((theme) => ({
    problemAccordion:{
        marginTop: '14px',
        borderStyle: 'solid',
        borderColor: '#D5D5D5',
        borderWidth: '1px',
        borderRadius: '5px',
        '& .accordion': {
            backgroundColor: '#DEDEDE',
            boxShadow: 'none'
        }
    },
    priority:{
        display: 'flex',
        padding: '0px 16px 20px'
    },
    subHeading: {
        fontWeight: 'bold',
        color: '#717171'
    },
    planHeadingCollapsed: {
        fontSize: '18px',
        fontWeight: 'Bold',
        color: '#4F4F4F',
        textOverflow: 'ellipsis',
        maxWidth: '700px',
        overflow: 'hidden',
        display: '-webkit-box',
        boxOrient: 'vertical',
        lineClamp: '1'
    },
    planHeadingExpanded: {
        fontSize: '18px',
        fontWeight: 'Bold',
        color: '#4F4F4F'
    },
    treatmentPlanHeadings: {
        fontSize: '18px',
        fontWeight: 'Bold',
        marginTop: '25px',
        color: 'var(--app-color)',
        display: 'flex'
    },
    headingsProblem:{
        marginTop: '28px',
        fontWeight: 'bold',
        marginBottom: '-4px'
    },
    message:{
        padding: '0px 16px 16px'
    },
    emptyPriorityDsc: {
        padding : '0px'
    },
    goalAccordion:{
        marginTop: '14px',
        borderStyle: 'solid',
        borderColor: '#D5D5D5',
        borderWidth: '1px',
        borderRadius: '5px',
        marginLeft: '26px',
        '& .accordion': {
            backgroundColor: '#EDEDED'
        }
    },
    objectiveAccordion:{
        marginTop: '14px',
        borderStyle: 'solid',
        borderColor: '#D5D5D5',
        borderWidth: '1px',
        borderRadius: '5px',
        marginLeft: '46px',
        '& .accordion': {
            backgroundColor: '#F5F5F5'
        }
    },
    container:{
        width: '100%',
        paddingLeft: '18px',
        paddingRight: '18px',
        fontWeight: 'bold',
        marginTop: '25px',
        marginBottom: '12px',
        color: '#4F4F4F'
    },
    checkboxContainer:{
        height: '12px',
        width: '12px',
        backgroundColor: '#ffffff',
        marginLeft: '8px'
    },
    checkbox:{
        fontSize: '16px',
        lineHeight: '14.1px',
        color: '#4F4F4F',
        marginTop: '0px',
        marginLeft: '8px'
    },
    status:{
        marginTop: '6px',
        marginLeft:'-3px'
    },
    interventionAccordion:{
        marginTop: '14px',
        borderStyle: 'solid',
        borderColor: '#D5D5D5',
        borderWidth: '1px',
        borderRadius: '5px',
        marginLeft: '70px',
        '& .accordion': {
            backgroundColor: '#FCFCFC'
        }
    },
    intervention:{
        marginTop: '8px',
        borderStyle: 'solid',
        borderColor: '#D5D5D5',
        borderWidth: '1px'
    },
    interventionContainer:{
        width: '100%',
        paddingLeft: '18px',
        paddingRight: '12%',
        fontWeight: 'bold',
        marginTop: '24px',
        marginBottom: '38px',
        color: '#4F4F4F'
    },
    interventionsHeading: {
        fontSize: '18px',
        fontWeight: 'Bold',
        color: '#4F4F4F'
    },
    unlinkedInterventions: {
        backgroundColor:'#FCFCFC'
    },
    input:{
        width: '100%',
        height: '20vh',
        marginTop: '6px',
        borderStyle: 'solid',
        borderColor: '#AFAFB3',
        borderWidth: '1px',
        outline: 'none',
        caretColor: '#888888'
    },
    mandatoryIndicator: {
        paddingLeft: '4px',
        fontSize: '18px',
        color: '#FF3B30',
    },
    icons: {
        marginRight: '10px',
        color: '#FC5211',
        display: 'flex',
        fontSize: '21px !important'
    },
    errorMessage : {
        top: (theme.textFieldHeightRaw + 6) + 'px',
        fontSize: '12px',
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: '16px',
        letterSpacing: '0.18px',
        color: "#dc0707"
    }
}))

const AccordionSummaryOverride = styled((props) => (
    <AccordionSummary
      expandIcon={<ArrowRightIcon sx={{fontSize: '3rem'}} style = {props?.isHandheld ? {height: '30px'}: {}}/>}
      {...props}
    />
  ))(() => ({
    '& .MuiAccordionSummary-expandIcon': {
        padding: '0'
      },
    '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
      transform: 'rotate(90deg)',
    }
  }));

export default function GAPgoi (props) {
    const styles = useStyles();

    let [pgoiData, setPgoiData] = useState([]);
    let [problems, setProblems] = useState([]);
    let [goals, setGoals] = useState([]);
    let [pgoMapData, setPgoMapData] = useState([]);
    let [objectives, setObjectives] = useState([]);
    let [interventions, setInterventions] = useState([]);
    let [pgoIntMapData, setPgoIntMapData] = useState([]);
    let [problemsConfig, setProblemsConfig] = useState();
    let [goalsConfig, setGoalsConfig] = useState();
    let [objectivesConfig, setObjectivesConfig] = useState();
    let [interventionsConfig, setInterventionsConfig] = useState();
    let [subHeader, setSubHeader] = useState("");
    let [changeCount, setChangeCount] = useState(0);
    let [txPlanDbData, setTxPlanDbData] = useState();
    let [globalConfig, setGlobalConfig] = useState([]);
    let [expandIcon, setExpandIcon] = useState(true);

    let gaTxPlan = props?.gaTxPlan;
    let module = props?.module;
    let linkIntervention = gaTxPlan?.linkIntervention;
    let isReadOnly = props?.isReadOnly;
    let statusOptions = props?.statusOptions;
    let document = props?.document;
    let treatmentPlanId = gaTxPlan?.treatmentPlanId;
    let isHandheld = props?.isHandheld;
    let breakPoints = getXSAndSMValueForField(props?.isHandheld, props?.orientation, 12, 6);

    useEffect(() => {
        resetStates();
        initializePgoi(module, treatmentPlanId);
        getConfigurations(gaTxPlan, module, setProblemsConfig, setGoalsConfig, setObjectivesConfig, setInterventionsConfig, setGlobalConfig);
        getPgoiData(gaTxPlan, setPgoiData);
        getPgoMapData(gaTxPlan, setPgoMapData);
        getPgoIntMapData(gaTxPlan, setPgoIntMapData);
        getTxPlanDbData(props, gaTxPlan, setTxPlanDbData);
        // eslint-disable-next-line
    }, [gaTxPlan]);

    useEffect(() => {
        if (pgoiData && pgoiData.length > 0) {
            let problemEle = getGAEle(pgoiData, "P");
            setProblems(problemEle);
            let goalEle = getGAEle(pgoiData, "G");
            setGoals(goalEle);
            let objectiveEle = getGAEle(pgoiData, "O");
            setObjectives(objectiveEle);
            let interventionEle = getGAEle(pgoiData, "I");
            setInterventions(interventionEle);
        }
    }, [pgoiData]);

    useEffect(() => {
        getSubHeader({setSubHeader, problemsConfig, goalsConfig, objectivesConfig, interventionsConfig, linkIntervention, problems, interventions});
        validationSettings(module, 'config');
        // eslint-disable-next-line
    }, [problemsConfig, goalsConfig, objectivesConfig, interventionsConfig, linkIntervention, problems, interventions]);

    useEffect(() => {
        validationSettings(module);
        // eslint-disable-next-line
    }, [globalConfig, goals, objectives, interventions, pgoMapData, pgoIntMapData]);

    const resetStates = () => {
        setProblems([]);
        setGoals([]);
        setObjectives([]);
        setInterventions([]);
        setExpandIcon(true);
    }

    const validationSettings = (module, call) => {
        let txPlanObj = module?.pgoi?.find(ele => ele.treatmentPlanId === treatmentPlanId);
            if (txPlanObj) {
                if (call === 'config') {
                    txPlanObj.goalsConfig = goalsConfig;
                    txPlanObj.objectivesConfig = objectivesConfig;
                    txPlanObj.interventionsConfig = interventionsConfig;
                } else {
                    txPlanObj.goals = goals;
                    txPlanObj.objectives = objectives;
                    txPlanObj.interventions = interventions;
                    txPlanObj.globalConfig = globalConfig;
                    txPlanObj.pgoMapData = pgoMapData;
                    txPlanObj.pgoIntMapData = pgoIntMapData;
                }
            }
    }
   
    const expandAll = () => {
        setExpandIcon(false);
        expandCollapse(pgoMapData, pgoIntMapData, problems, interventions, true);
    };
  
    const collapseAll = () => {
        setExpandIcon(true);
        expandCollapse(pgoMapData, pgoIntMapData, problems, interventions, false);
    };

    const shouldExcludeElement = (element) => {
        if (element?.completedDate !== "" && globalConfig?.includeCompleted === false && element?.completedDate < document?.serviceDate) {
            return true;
        }
    };

    const AccordionComponent = (type, element, config, styleConfig, uniqueMapping, customClassName) => {
        updateElement(element, txPlanDbData, uniqueMapping);
        let eleName = `is${element?.type}Expanded`;
        return (<div className={ customClassName ? styles[customClassName] : styles[type]} style = {{ marginLeft: styleConfig?.marginLeft + "px" }}>
            <Accordion className={'accordion'}
                expanded = { uniqueMapping[eleName] }
                onChange = {(e,expanded) => {
                    if (uniqueMapping) {
                        if(expanded){
                            uniqueMapping[eleName] = true;
                        } else {
                            uniqueMapping[eleName] = false;
                        }
                    }
                }}>                    
                <AccordionSummaryOverride
                    isHandheld = {isHandheld}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style ={isHandheld && !uniqueMapping[eleName] ? {height:'30px', minHeight:'0px'}: {}}
                >
                    { getAccordionSummary(element, styles, config, uniqueMapping, isHandheld) }
                </AccordionSummaryOverride>
                {handleData(element, type, config, uniqueMapping)}
            </Accordion>
        </div>)
    }

    const updateChangeCount = () => {
        setChangeCount(changeCount + 1);
    }

    const handleData = (element, type, config, uniqueMapping) => {
        let uniqueId = (uniqueMapping?.type) ? uniqueMapping?.pgoiId + "-" + treatmentPlanId : uniqueMapping?.pgoMapId + "-" + element?.pgoiId + "-" + treatmentPlanId;
        return(
            <div>
                <AccordionDetails className={element?.priorityDsc ? styles.message : styles.emptyPriorityDsc} style = {isHandheld ? {fontSize: ACCORDIANFONT}: {}}>
                    {element?.priorityDsc && <div><span className={styles.subHeading}>{ config?.priorityLabel ? config?.priorityLabel : "Priority" }:</span>&nbsp;{element?.priorityDsc}</div>}
                </AccordionDetails>

                {(type !== 'problemAccordion' && config?.allowSelection) && <>
                    <FormControlLabel
                        label={<span style = {isHandheld ? {fontSize: ACCORDIANFONT}: {}}>{'Addressed'}</span>}
                        control={
                            <span style={{marginRight:'9px'}}>
                                <EvvCheckBox
                                    className = {styles.checkboxContainer}
                                    id = { 'addressed-' + uniqueId }
                                    name = { 'addressed-' + uniqueId }
                                    value = { uniqueId }
                                    checked = { isChecked(element) }
                                    disabled = { isReadOnly }
                                    onChange = {(event) => { props.onChange("addressed", event, props, element); } }
                                />
                            </span>
                        }
                        className={styles.checkbox}
                    />
                    { (globalConfig?.showHideStatus === "S" || globalConfig?.showHideStatus === "R") &&
                    <div className={styles.container} style = {isHandheld ? {fontSize: ACCORDIANFONT}: {}}>{ globalConfig?.statusLabel }
                     { isChecked(element) && globalConfig?.showHideStatus === "R" ? <span className={styles.mandatoryIndicator}>*</span> : "" }
                        <div className={styles.status}>
                        <Grid container item = { true } xs = { breakPoints.xsValue } sm = { breakPoints.smValue }>
                            <EvvSelect
                                id = { 'status-' + uniqueId }
                                value = { getSelectedStatus(element) }
                                disabled = { isReadOnly }
                                onChange = {(event) => { props.onChange("status", event, props, element); updateChangeCount() } }
                            >
                                <option value = "">Select</option>
                                {statusOptions?.map((status, index) =>
                                <option key = { `option_${index}` } value = { status?.descriptorId }> { status?.name } </option>)}
                            </EvvSelect>
                        </Grid>
                            {element?.gaError && element?.statusErrorMsg && <div className={styles.errorMessage}> { element?.statusErrorMsg } </div>}
                        </div>
                    </div> }
                    { (globalConfig?.showHideComment === "S" || globalConfig?.showHideComment === "R") &&
                    <div className={styles.container} style = {isHandheld ? {fontSize: ACCORDIANFONT}: {}}>{ globalConfig?.commentLabel }
                     { isChecked(element) && globalConfig?.showHideComment === "R" ? <span className={styles.mandatoryIndicator}>*</span> : "" }
                        <textarea
                            className={styles.input}
                            id = { 'comments-' + uniqueId }
                            name = { 'comments-' + uniqueId }
                            value = { getCommentsValue(element) }
                            disabled = { isReadOnly }
                            onChange = {(event) => { props.onChange("comments", event, props, element); updateChangeCount() }}
                            maxLength = { 4000 }
                            rows = { 5 }
                            cols = { 80 }
                        />
                         <span style={{fontWeight: 'normal'}}>{ GACOMMENTSLIMIT }</span>
                         {element?.gaError && element?.commentErrorMsg && <div className={styles.errorMessage}> { element?.commentErrorMsg } </div>}
                    </div> }
                </> }
            </div>
        )
    }

    const renderProblems = () => {
        //Render Problems
        let problemsUi = [];
        let styleOwnConfig = {"marginLeft": 0};
        let styleNextConfig = {"marginLeft": 0};
        if(problems && problems.length > 0) {
            problems?.forEach((problem, i) => {
                if (problemsConfig?.show && !shouldExcludeElement(problem)) {
                    problemsUi.push(AccordionComponent('problemAccordion', problem, problemsConfig, styleOwnConfig, problem));
                    if (i === 0) {
                        styleNextConfig.marginLeft += 26;
                    }
                }
                let problemMapping = getProblemMapping(problem, pgoMapData);
                if (problemMapping && problemMapping.length > 0) {
                    problemsUi = renderGoals('goalAccordion', problemMapping, problemsUi, styleNextConfig);
                }
            });
        } else {
            problemsUi = renderGoals('goalAccordion', pgoMapData, problemsUi, styleNextConfig);
        }

        return problemsUi;
    }

    const renderGoals = (type, problemMapping, problemsUi, styleOwnConfig) => {
        let i = 0;
        let styleNextConfig = {...styleOwnConfig};

        goals?.forEach((goal) => {
            let isMapped = problemMapping.find(map => map.goalId === goal.pgoiId);
            if (isMapped) {
                if (goalsConfig?.show && !shouldExcludeElement(goal)) {
                    problemsUi.push(AccordionComponent(type, goal, goalsConfig, styleOwnConfig, isMapped));
                    if (i === 0) {
                        styleNextConfig.marginLeft += 26;
                    }
                }
                problemsUi = renderObjectives('objectiveAccordion', problemMapping, problemsUi, goal, styleNextConfig);
                i++;
            }
        });
        return problemsUi;
    }

    const renderObjectives = (type, problemMapping, problemsUi, goal, styleOwnConfig) => {
        //Render Objectives
        let i = 0;
        let styleNextConfig = {...styleOwnConfig};

        objectives?.forEach((objective) => {
            let isMapped = problemMapping.find(map => map.objectiveId === objective.pgoiId && map?.goalId === goal?.pgoiId);
            if (isMapped) {
                if (objectivesConfig?.show && !shouldExcludeElement(objective)) {
                    problemsUi.push(AccordionComponent(type, objective, objectivesConfig, styleOwnConfig, isMapped));
                    if (i === 0) {
                        styleNextConfig.marginLeft += 26;
                    }
                }

                if (interventionsConfig?.show && linkIntervention === "Y") {
                    let interventionMapping = getInterventionMapping(objective, problemMapping, pgoIntMapData, goal);
                    if (interventionMapping && interventionMapping.length > 0) {
                        problemsUi = renderInterventions('interventionAccordion', interventionMapping, problemsUi, styleNextConfig);
                    }
                }
                i++;
            }
        });
        return problemsUi;
    }

    const renderInterventions = (type, interventionMapping, problemsUi, styleOwnConfig) => {
        //Render linked Interventions
        interventions?.forEach((intervention) => {
            let isMapped = interventionMapping.find(map => map.interventionId === intervention.pgoiId);
            if (isMapped && !shouldExcludeElement(intervention)) {
                problemsUi.push(AccordionComponent(type, intervention, interventionsConfig, styleOwnConfig, isMapped));
            }
        });
        return problemsUi;
    }

    const renderUnlinkedInterventionsUi = () => {
        //Render unlinked Interventions
        let styleOwnConfig = {"marginLeft": 0};

        if (interventionsConfig?.show && linkIntervention !== "Y" && interventions && interventions.length > 0) {
            let interventionsUi = [];

            interventionsUi.push(<div style={{marginTop:'38px'}} className={styles.interventionsHeading}>{ interventionsConfig?.layerLabel }</div>);
            interventions?.forEach((intervention) => {
                if(!shouldExcludeElement(intervention))
                    interventionsUi.push(<div className={styles.intervention}>{AccordionComponent('interventionAccordion', intervention, interventionsConfig, styleOwnConfig, intervention, "unlinkedInterventions") }</div>);
            });

            return interventionsUi;
        }
    }

    return (
        <>
            <div>
                <div className={styles.treatmentPlanHeadings}>
                    {expandIcon ? (<AddBoxOutlinedIcon onClick={expandAll} className={styles.icons}/>) : (<IndeterminateCheckBoxOutlinedIcon onClick={collapseAll} className={styles.icons}/>)}
                    {getTxPlanName(gaTxPlan)}
                </div>
                <div className={styles.headingsProblem}> { subHeader }</div>
                { renderProblems() }
                { renderUnlinkedInterventionsUi() }
            </div>
        </>
    )
}
