import {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import EvvButton from "../EvvButton";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        height: '42px',
        width: 'auto',
        fontSize: '16px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: "#ffffff",
        paddingLeft: '16px',
        display: 'flex',
        alignItems: 'center',
        textTransform: 'capitalize'
    },
    dialogContent: {
        padding: "10px 16px",
    },
    dialogChildren: {
        display: 'flex',
        flexDirection: 'column'
    },
    dialogChild: {
        paddingBottom: '20px'
    },
    dialogText: {
        fontSize: '16px',
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: "20px",
        letterSpacing: 0,
        color: "#747474"
    },
    okButtonGroup: {
        padding: "0px 16px 8px 16px",
    },
    okCancelButtonGroup: {
        padding: "0px 16px 8px 16px",
        justifyContent: 'space-between'
    },
    okButton: {
        padding: '0',
        minWidth: '125px'
    },
    cancelButton: {
        padding: '0'
    },
    label: {
        flexDirection: 'column',
        textTransform: "none",
        color: '#888888'
    }
}));

export default function AlertDialog(props){
    const {id, dialogTitle, dialogMessage,
        showOkButton = true, okButtonText = 'OK',
        showCancelButton = false, cancelButtonText = 'Cancel',
        handleClose, children, renderChildren} = props;
    const styles = useStyles();
    const [open, setOpen] = useState(true);

    const handleOk = (e) => {
        let closeDialog = true;

        if (handleClose){
            closeDialog = handleClose(true);
        }

        if (closeDialog === false) {
            return;
        }

        setOpen(false);
    };

    const handleCancel = (e) => {
        setOpen(false);

        if (handleClose){
            handleClose(false);
        }
    };

    const renderContent = () => {
        if (dialogMessage) {
            return (
                <DialogContentText classes={{root: styles.dialogText}} id="alert-dialog-description">
                    {dialogMessage}
                </DialogContentText>
            );
        } else if (renderChildren) {
            return renderChildren();
        } else if (children) {
            return (
                <div className={styles.dialogChildren}>
                    {children.map((child, index) => <div key={'child_' + index} className={styles.dialogText + ' ' + styles.dialogChild}>{child}</div>)}
                </div>
            );
        }

        return null;
    }

    return (
        <div data-testid={`AlertDialog${id}`}>
            <Dialog
                open={open}
                disableEscapeKeyDown={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle classes={{root: styles.dialogTitle}} id="alert-dialog-title">{dialogTitle}</DialogTitle>
                <DialogContent classes={{root: styles.dialogContent}}>
                    {renderContent()}
                </DialogContent>
                <DialogActions classes={{root: showOkButton && showCancelButton ? styles.okCancelButtonGroup : styles.okButtonGroup}}>
                    {showCancelButton &&
                    <div className={styles.cancelButton}>
                        <EvvButton id='AlertDialog_cancelButton' type='tertiary' onClick={handleCancel} > {cancelButtonText} </EvvButton>
                    </div>
                    }
                    {showOkButton &&
                    <div className={styles.okButton}>
                        <EvvButton id='AlertDialog_okButton' type='primary' onClick={handleOk} > {okButtonText} </EvvButton>
                    </div>
                    }
                </DialogActions>
            </Dialog>
        </div>
    );
}
