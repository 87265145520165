import LookupService from "./lookupService";
import {appCache} from "../../cache/slices/app/appSlice";
import api from "./api";
import {DateTime} from "luxon";
import {
    booleanToString,
    dateToStringWithFormat,
    DEFAULT_TIME_FORMAT,
    DEFAULT_DATE_TIME_FORMAT,
    defaultTimeAsString,
    formatSignatureForWrite,
    stringToDateWithFormat,
    APPOINTMENT_START_STOP_DATE_TIME_FORMAT
} from "../utils/formatUtils";
import syncService from "./syncService";
import activityLogRepository from "../../db/activityLogRepository";
import {evvRepository} from "../../db/evv";
import {getDispatchFromConfig} from "../utils/miscUtils";

const VISIT_PENDING = -1;
const VISIT_STARTED = 0;
const VISIT_COMPLETE = 1;
const VISIT_SYNCSTATE_DIRTY = "dirty";

class VisitService extends LookupService{
    loadFromRepository = (config) => {
        return this.repository.loadAllByProperty('complete', VISIT_STARTED);
    }

    saveVisit = (visit, config) => {
        if (visit) {
            visit.isRecordOnline = !this.isInformationOffline(config);
            const visits = [visit];

            visit.syncState = VISIT_SYNCSTATE_DIRTY;

            return this.save(visits, config)
                .then(() => {
                    return this.writeVisitsToServer(visits, config)
                        .then(savedVisits => {
                            let savedVisit = null;

                            if (savedVisits && savedVisits.length > 0){
                                const dispatch = getDispatchFromConfig(config);
                                savedVisit = savedVisits[0];

                                if (visit.visitStatus === 'start') {
                                    dispatch(this.operationAdd(savedVisit));
                                }else if (visit.visitStatus !== 'pending') {
                                    if (visit.complete === VISIT_COMPLETE){
                                        syncService.visitService.load(config);
                                        dispatch(this.operationRemove(savedVisit));                                        
                                    } else {
                                        dispatch(this.operationReplace(savedVisit));
                                    }
                                }
                            }

                            return savedVisit;
                        })
                })
        }
    }

    createInitialVisit = (appointment) => {
        return {
            evvVisitId: appointment.evvVisitId ? appointment.evvVisitId : -1,
            deviceVisitId: Date.now(),
            appointmentId: appointment.appointmentId,
            appointment: appointment,
            client: appointment.client,
            clientId: appointment.client.clientId,
            visitStatus: 'pending',
            complete: VISIT_PENDING
        };
    }

    updateVisitFromAppointment = (visit , appointment) => {
        const visitToUpdate = visit ? visit : this.createInitialVisit(appointment);

        return {
            ...visitToUpdate,
            actualStartDateInstance: appointment.startDateInstance,
            actualEndDateInstance: appointment.endDateInstance,
            beginActualDateTime: dateToStringWithFormat(appointment.startDateInstance, APPOINTMENT_START_STOP_DATE_TIME_FORMAT),
            endActualDateTime: dateToStringWithFormat(appointment.endDateInstance, APPOINTMENT_START_STOP_DATE_TIME_FORMAT),
            startDateTime: appointment.startDateInstance.toMillis(),
            endDateTime: appointment.endDateInstance.toMillis(),
            beginDateTime: appointment.startDateInstance.toMillis(),
        };
    }

    saveVisitForAppointment = (appointment, config) => {
        return this.loadVisitByAppointmentId(appointment.appointmentId, config)
            .then(visit => {
                console.log('visit loaded for appointment');
                console.log(visit);
                const visitToSave = this.updateVisitFromAppointment(visit, appointment);
                if (this.isInformationOffline(config)) {
                    visitToSave.beginEndDateTimeOffline = true;
                }
                console.log('updated visit from appointment');
                console.log(visitToSave);
                return this.saveVisit(visitToSave, config);
            });
    }

    beforeSave = (visits, config) => {
        return visits;
    }

    afterLoad = (visits, config) => {
        if (visits && visits[0] !== undefined && visits.length > 0){
            const state = config.store.getState();
            const clients = appCache.getClients(state);
            const appointments = syncService.getAppointments(state);

            for (const visit of visits) {
                const appointment = appointments && appointments.find(a => a.appointmentId === visit.appointmentId);

                if (appointment) {
                    visit.appointment = appointment;
                }

                // eslint-disable-next-line eqeqeq
                const client = clients && clients.find(c => c.clientId == visit.clientId);
                visit.client = client ? client : appointment.client;

                visit.actualStartDateInstance = stringToDateWithFormat(visit.beginActualDateTime, APPOINTMENT_START_STOP_DATE_TIME_FORMAT);
                visit.actualEndDateInstance = stringToDateWithFormat(visit.endActualDateTime, APPOINTMENT_START_STOP_DATE_TIME_FORMAT);
            }
        }

        return visits;
    }

    afterLoadAsync = async (visits, config) => {
        return this.afterLoad(visits, config);
    }

    createWriteVisit = (visit) => {
        const appointment = visit.appointment;
        let evvVisitId = visit.evvVisitId;

        if (appointment.evvVisitId && appointment.evvVisitId >= 0 && appointment.evvVisitId !== visit.evvVisitId){
            evvVisitId = appointment.evvVisitId;
        }

        if (evvVisitId === "" || evvVisitId < 0){
            return null;
        }

        const writeVisit = {
            evvVisitId: evvVisitId,
            activityLogId: appointment.appointmentId,
        };
        
        if (writeVisit.activityLogId <= 0) {
            writeVisit.activityLogId = visit.appointmentId;
        }

        if (visit.visitStatus === 'pending') {
            writeVisit.beginDateTime = dateToStringWithFormat(DateTime.fromMillis(visit.startDateTime), APPOINTMENT_START_STOP_DATE_TIME_FORMAT);
            writeVisit.endDateTime = dateToStringWithFormat(DateTime.fromMillis(visit.endDateTime), APPOINTMENT_START_STOP_DATE_TIME_FORMAT);
        } else if (visit.visitStatus === 'start') {
            writeVisit.organizationId = appointment.organizationId;
            writeVisit.beginActualDateTime = visit.beginActualDateTime;
            writeVisit.startLatitude = visit.startLatitude;
            writeVisit.startLongitude = visit.startLongitude;
            if (typeof visit.beginEndDateTimeOffline === "boolean" && visit.beginEndDateTimeOffline) {
                writeVisit.beginDateTime = dateToStringWithFormat(DateTime.fromMillis(visit.beginDateTime), APPOINTMENT_START_STOP_DATE_TIME_FORMAT);
                writeVisit.endDateTime = dateToStringWithFormat(DateTime.fromMillis(visit.endOriginalDateTime), APPOINTMENT_START_STOP_DATE_TIME_FORMAT);
            }
        } else {
            writeVisit.organizationId = appointment.organizationId;
            writeVisit.endLatitude = visit.endLatitude;
            writeVisit.endLongitude = visit.endLongitude;
        }

        if (visit.visitStatus === 'verify') {
            writeVisit.endActualDateTime = visit.endActualDateTime;
            if (visit.isUpdatedBeginAdjustedDateTime)
                writeVisit.beginAdjustedDateTime = visit.beginAdjustedDateTime;
            if (visit.isUpdatedEndAdjustedDateTime)
                writeVisit.endAdjustedDateTime = visit.endAdjustedDateTime;
            writeVisit.servicesVerified = booleanToString(visit.servicesVerified);
            writeVisit.timesVerified = booleanToString(visit.timesVerified);
            writeVisit.clientSig = formatSignatureForWrite(visit.signature);
            writeVisit.hasSig = booleanToString(visit.hasSignature);
            if (typeof visit.isPendSendStartSessInfo === "boolean" && visit.isPendSendStartSessInfo) {
                writeVisit.beginActualDateTime = visit.beginActualDateTime;
                writeVisit.startLatitude = visit.startLatitude;
                writeVisit.startLongitude = visit.startLongitude;
                writeVisit.beginDateTime = dateToStringWithFormat(DateTime.fromMillis(visit.beginDateTime), APPOINTMENT_START_STOP_DATE_TIME_FORMAT);
                writeVisit.endDateTime = dateToStringWithFormat(DateTime.fromMillis(visit.endOriginalDateTime), APPOINTMENT_START_STOP_DATE_TIME_FORMAT);
            }
            
            if (visit.editTimesReason){
                writeVisit.editTimeEventType = "CHANGETIME";
                writeVisit.editTimeEventAttr = visit.editTimesReason;
                writeVisit.editTimeEventValue = visit.editTimesComments;
            }

            if (visit.signatureMissingReason){
                writeVisit.clientSigMissingEventType = "CHANGESIGN";
                writeVisit.clientSigMissingEventAttr = visit.signatureMissingReason;
                writeVisit.clientSigMissingEventValue = visit.signatureMissingComments;
            }

            if (visit.verificationDeclinedReason){
                writeVisit.clientVerificationDeclinedEventType = "CHANGEVERIFICATION";
                writeVisit.clientVerificationDeclinedEventAttr = visit.verificationDeclinedReason;
                writeVisit.clientVerificationDeclinedEventValue = visit.verificationDeclinedComments;
            }

            if (visit.addressDistanceReason){
                writeVisit.addressDistanceEventType = "CHANGEDISTANCE";
                writeVisit.addressDistanceEventAttr = visit.addressDistanceReason;
                writeVisit.addressDistanceEventValue = visit.addressDistanceComments;
            }
        }

        return writeVisit;
    }

    writeVisitsToServer = async (nakedVisits, config) => {
        const visits = await this.afterLoadAsync(nakedVisits, config);
        const writeVisits = visits.map(visit => this.createWriteVisit(visit)).filter(visit => visit != null);

        if (writeVisits.length === 0){
            return Promise.resolve(visits);
        }

        const writeVisitsRequest = {
            resources: writeVisits
        };

        console.log("Writing visits to server: ");
        console.log(writeVisitsRequest);

        return api.post(this.writeUrl, this.getFetchConfig(config, writeVisitsRequest))
            .then(writeVisitsResponse => {
                const visitsToSave = [];
                console.log("Succes writing visits to server: ");
                console.log(writeVisitsResponse);
                if (writeVisitsResponse.errors && writeVisitsResponse.errors.length > 0) {
                    visits.forEach((visit, index) => {
                        const visitToSave = {...visit};
                        visitToSave.errors = writeVisitsResponse.errors;
                        visitToSave.syncState = 'error';
                        visitsToSave.push(visitToSave);
                    });
                } else if (writeVisitsResponse.resources && writeVisitsResponse.resources.length > 0) {
                    writeVisitsResponse.resources.forEach((visitFromServer, index) => {
                        const originalVisit = visits[index];
                        const visitToSave = {...originalVisit};

                        if (visitFromServer.errors && visitFromServer.errors.length > 0){
                            visitToSave.errors = visitFromServer.errors;
                            visitToSave.syncState = 'error';
                        } else {
                            visitToSave.syncState = 'clean';
                        }

                        visitsToSave.push(visitToSave);
                    });
                }

                if (visitsToSave.length > 0) {
                    return this.save(visitsToSave, {store: config.store, doNotClear: true})
                        .then(() => visitsToSave);
                } else {
                    return Promise.resolve(visitsToSave);
                }
            })
            .catch(error => {
                const connectionLost = api.isConnectionLost(error);

                if (connectionLost){
                    return Promise.resolve(visits);
                } else {
                    console.log("Error thrown while writing visits to server: ");
                    console.log(error);
                    return Promise.reject(error);
                }
            });
    }

    dependentWriteToServer = (config) => {
        return this.repository.loadAllByProperty('syncState', 'dirty')
            .then(visits => {
                if (visits && visits.length > 0){
                    return this.writeVisitsToServer(visits, config);
                } else {
                    return Promise.resolve(visits);
                }
            })
    }

    startClientVisit = (store, appointment, user, startTimer, handleVisitStarted) => {
        return (position) => {
            this.loadVisitByAppointmentId(appointment.appointmentId, {store})
                .then((activeVisit) => {
                    const now = DateTime.now();
                    const actualStartDateInstance = now.startOf('day').plus({hours: now.hour, minutes: now.minute, seconds: now.second});
                    const diffInMinutes = appointment.endDateInstance.diff(appointment.startDateInstance, ['minutes']);
                    const actualEndDateInstance = actualStartDateInstance.plus(diffInMinutes);

                    startTimer(actualStartDateInstance);

                    const initialVisit = activeVisit ? activeVisit : this.createInitialVisit(appointment);

                    const visit = {
                        ...initialVisit,
                        startLatitude: position.coords.latitude,
                        startLongitude: position.coords.longitude,
                        startDateTime: actualStartDateInstance.toMillis(),
                        endOriginalDateTime: initialVisit.endDateTime ? initialVisit.endDateTime : actualEndDateInstance.toMillis(),
                        actualStartDateInstance,
                        actualEndDateInstance,
                        beginActualDateTime: dateToStringWithFormat(actualStartDateInstance, APPOINTMENT_START_STOP_DATE_TIME_FORMAT),
                        endActualDateTime: dateToStringWithFormat(actualEndDateInstance, APPOINTMENT_START_STOP_DATE_TIME_FORMAT),
                        visitStatus: 'start',
                        complete: VISIT_STARTED
                    }

                    if (visit.beginDateTime === undefined && visit.endDateTime === undefined) {
                          visit.beginDateTime = actualStartDateInstance.toMillis();
                          visit.endDateTime = actualEndDateInstance.toMillis();
                    }

                    this.saveVisit(visit, {store, doNotClear: true})
                        .then(savedVisit => {
                            const appointmentToSave = {...appointment};
                            appointmentToSave.status = 'In Session';
                            appointmentToSave.appointmentDate = actualStartDateInstance.toISO({ suppressSeconds: true, suppressMilliseconds: true, includeOffset: false });
                            appointmentToSave.startDateInstance = actualStartDateInstance;
                            appointmentToSave.startTime = dateToStringWithFormat(actualStartDateInstance, DEFAULT_TIME_FORMAT);
                            appointmentToSave.endDateInstance = actualEndDateInstance;
                            appointmentToSave.endTime = dateToStringWithFormat(actualEndDateInstance, DEFAULT_TIME_FORMAT);

                            activityLogRepository.saveActivityLog(user, 'Session Started', savedVisit);

                            if (handleVisitStarted) {
                                handleVisitStarted(savedVisit, appointment);
                            }
                        });
                });
        }
    }

    endClientVisit = (store, user, client, endTimer, handleVisitEnded, visit) => {
        return (position) => {
            this.loadActiveClientVisit(client, {store})
                .then((activeVisit) => {
                    const now = DateTime.now();
                    const actualEndDateInstance = now.startOf('day').plus({hours: now.hour, minutes: now.minute, seconds: now.second});

                    if(endTimer) {
                        endTimer(actualEndDateInstance);
                    }
                    if(activeVisit === undefined && visit !== null) {
                        activeVisit = visit;
                    }
                    const visitToSave = {
                        ...activeVisit,
                        endLatitude: position.coords.latitude,
                        endLongitude: position.coords.longitude,
                        endDateTime: actualEndDateInstance.toMillis(),
                        endActualDateTime: dateToStringWithFormat(actualEndDateInstance, APPOINTMENT_START_STOP_DATE_TIME_FORMAT),
                        actualEndDateInstance,
                        visitStatus: 'end',
                    };

                    this.save([visitToSave], {store, doNotClear: true})
                        .then(() => {
                            store.dispatch(this.operationReplace(visitToSave));

                            activityLogRepository.saveActivityLog(user, 'Session Ended', visitToSave);

                            if (handleVisitEnded) {
                                handleVisitEnded(visitToSave);
                            }
                        });

                })
                .catch((ex) => {
                    console.log("Unable to save ended visit:");
                    console.log(ex);
                });
        }
    }

    cleanupVisit = (visit) => {
        const cleanVisit = {...visit};

        delete cleanVisit.endLatitude;
        delete cleanVisit.endLongitude;
        delete cleanVisit.endDateTime;
        delete cleanVisit.endAdjustedDateTime;
        delete cleanVisit.actualEndDateInstance;
        delete cleanVisit.verificationDeclinedReason;
        delete cleanVisit.verificationDeclinedReason;
        delete cleanVisit.verificationDeclinedComments;
        delete cleanVisit.signatureMissingReason;
        delete cleanVisit.signatureMissingComments;
        delete cleanVisit.addressDistanceReason;
        delete cleanVisit.addressDistanceComments;
        delete cleanVisit.editTimesReason;
        delete cleanVisit.editTimesComments;
        delete cleanVisit.editTimesEventType;
        delete cleanVisit.signature;
        delete cleanVisit.hasSignature;
        delete cleanVisit.timesVerified;
        delete cleanVisit.servicesVerified;

        return cleanVisit;
    }

    cancelVerifyClientVisit = (store, user, client) => {
        this.loadActiveClientVisit(client, {store})
            .then((activeVisit) => {
                if(activeVisit) {
                    const visitToSave = {
                        ...activeVisit,
                        visitStatus: 'start'
                    };
                    delete visitToSave.endLatitude;
                    delete visitToSave.endLongitude;
                    delete visitToSave.endAdjustedDateTime;
                    delete visitToSave.endActualDateTime;
                    delete visitToSave.actualEndDateInstance;

                    this.save([visitToSave], {store, doNotClear: true})
                        .then(() => {
                            store.dispatch(this.operationReplace(visitToSave));

                            activityLogRepository.saveActivityLog(user, 'Session Verify Cancelled', visitToSave);
                        });
                }
            })
            .catch((ex) => {
                console.log("Unable to cancel visit verification:");
                console.log(ex);
            });
    }

    verifyClientVisit = (store, user, client, verifiedVisit) => {
        console.log("verifyClientVisit");
        this.loadActiveClientVisit(client, {store})
            .then((activeVisit) => {
                console.log("activeVisit:");
                console.log(activeVisit);
                console.log("verifiedVisit:");
                console.log(verifiedVisit);
                const startDateTimeFromEditTime = dateToStringWithFormat(DateTime.fromMillis(verifiedVisit.startDateTime), DEFAULT_DATE_TIME_FORMAT);
                const endDateTimeFromEditTime = dateToStringWithFormat(DateTime.fromMillis(verifiedVisit.endDateTime), DEFAULT_DATE_TIME_FORMAT);
                const startDateTimeFromVisitVerification = dateToStringWithFormat(DateTime.fromMillis(activeVisit.startDateTime), DEFAULT_DATE_TIME_FORMAT);
                const endDateTimeFromVisitVerification = dateToStringWithFormat(DateTime.fromMillis(activeVisit.endDateTime), DEFAULT_DATE_TIME_FORMAT);
                const visit = {
                    ...this.cleanupVisit(activeVisit),
                    ...verifiedVisit,
                    isUpdatedBeginAdjustedDateTime : false,
                    isUpdatedEndAdjustedDateTime : false,
                    endActualDateTime: activeVisit.endActualDateTime,
                    beginActualDateTime: activeVisit.beginActualDateTime,
                    visitStatus: 'verify',
                    complete: VISIT_COMPLETE
                };
                if(startDateTimeFromEditTime !== startDateTimeFromVisitVerification) {
                    visit.beginAdjustedDateTime = dateToStringWithFormat(DateTime.fromMillis(verifiedVisit.startDateTime), APPOINTMENT_START_STOP_DATE_TIME_FORMAT);
                    visit.isUpdatedBeginAdjustedDateTime = true;
                }
                if(endDateTimeFromEditTime !== endDateTimeFromVisitVerification) {
                    visit.endAdjustedDateTime = dateToStringWithFormat(DateTime.fromMillis(verifiedVisit.endDateTime), APPOINTMENT_START_STOP_DATE_TIME_FORMAT);
                    visit.isUpdatedEndAdjustedDateTime = true;
                }

                console.log("visitToSaveLocal:");
                console.log(visit);

                const appointmentToSave = {...visit.appointment};
                appointmentToSave.status = verifiedVisit.appointmentStatus;
                appointmentToSave.startDateInstance = DateTime.fromMillis(verifiedVisit.startDateTime);
                appointmentToSave.endDateInstance = DateTime.fromMillis(verifiedVisit.endDateTime);
                appointmentToSave.appointmentDate = appointmentToSave.startDateInstance.toISO({ suppressSeconds: true, suppressMilliseconds: true, includeOffset: false });
                appointmentToSave.startTime = defaultTimeAsString(appointmentToSave.startDateInstance);
                appointmentToSave.endTime = defaultTimeAsString(appointmentToSave.endDateInstance);
                appointmentToSave.actualStartTime = appointmentToSave.startTime;
                appointmentToSave.actualEndTime = appointmentToSave.endTime;
                if (verifiedVisit.appointmentStatus === 'Kept' && verifiedVisit.activityDetailDescriptorList) {
                    appointmentToSave.activityDetailDescriptorList = verifiedVisit.activityDetailDescriptorList;
                }

                console.log("appointmentSave");
                console.log(appointmentToSave);

                syncService.appointmentService.saveAppointment(appointmentToSave, {store, doNotClear: true, ignoreVisitSave: true})
                    .then(appAfterSave => {
                    //-- Verify exits previous information. Send the previous information from internal DB to write/visit
                    this.loadOfflineVisit().then((visitArray) => {
                        console.log("Result : "+visitArray.length);
                        if(visitArray && visitArray.length > 0){
                            visitArray.forEach((visitDB) => {
                                if (visitDB.appointmentId === appAfterSave.appointmentId && visitDB.syncState === VISIT_SYNCSTATE_DIRTY && visitDB.isRecordOnline === false) {
                                    console.log("Exits a visit in offline that need to send previous to stop session.");
                                    visit.evvVisitId = appAfterSave.evvVisitId;
                                    visit.appointmentId =appAfterSave.appointmentId;
                                    visit.appointment = appAfterSave;
                                    visit.isPendSendStartSessInfo = true;
                                }
                            });
                            this.saveVisit(visit, {store, doNotClear: true})
                            .then(savedVisit => {
                                console.log("savedVisit - after write to server:");
                                console.log(savedVisit);
                                store.dispatch(this.operationRemove(visit));

                                activityLogRepository.saveActivityLog(user, 'Session Ended', savedVisit);
                            })
                            .catch(err => {
                                console.log("Error attempting saveVisit() call");
                                console.log(err);
                                this.cancelVerifyClientVisit(store, user, client);
                            });
                        }
                    }).catch((error) => {
                        console.log('Error: ', error);
                    });
                });
            })
            .catch((ex) => {
                console.log("Unable to save verified visit:");
                console.log(ex);
                this.cancelVerifyClientVisit(store, user, client);
            });
    }

    isInformationOffline = (config) => {
        const state = config.store.getState();
        return api.isOfflineAtLogin() || appCache.isOffline(state);
    }

    loadActiveClientVisit = (client, config) => {
        return evvRepository.evvDb.visit.where({clientId: client.clientId, complete: VISIT_STARTED}).first()
            .then((activeVisit) => this.afterLoad([activeVisit], config)[0]);
    }

    loadOfflineVisit = () =>{
        return evvRepository.evvDb.visit.toArray();
    }

    loadVisitByAppointmentId = (appointmentId, config) => {
        console.log('loadVisitByAppointmentId');
        console.log(appointmentId);
        return evvRepository.evvDb.visit.where({appointmentId: appointmentId}).first()
            .then((visit) => visit ? this.afterLoad([visit], config)[0] : null);
    }

    isSyncRequired = () => {
        return this.repository.loadAllByProperty('syncState', 'dirty')
            .then(visits => {
                return Promise.resolve(visits.length > 0);
            });
    }

    updateVisit = async (originalAppointment, appointmentToSave) => {
        evvRepository.evvDb.visit
            .where("evvVisitId").equals(originalAppointment.appointmentId)
            .modify({
                "evvVisitId": appointmentToSave.evvVisitId,
                "appointmentId": appointmentToSave.appointmentId,
                "appointment": appointmentToSave
            });
    }

    getVisit = async (originalAppointmentId) => {
        return evvRepository.evvDb.visit
            .where("evvVisitId").equals(originalAppointmentId)
            .toArray();
    }

    getInSessionVisit = async() => {
        return evvRepository.evvDb.visit
            .where({visitStatus: 'start'})
            .toArray();
    }
}

export default VisitService;
