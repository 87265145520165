import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { clientCache } from "../../../cache/slices/client/clientSlice";
import { getBp, getFormattedDateTime } from "../../../common/utils/vitalsUtils";
 
const useStyles = makeStyles((theme) => ({
    sectionContainer: {
        width: '100%',
        height: '60px',
        backgroundColor: "#ffffff",
        fontWeight: 'normal',
        paddingBottom: '8px',
        paddingLeft: '16px',
        paddingRight: '8px', 
    },
    sectionContainerDate: {
        width: '100%',
        height: '30px',
        backgroundColor: "#ffffff",
        fontWeight: 'normal',
        paddingBottom: '15px',
        paddingLeft: '16px',
        paddingRight: '8px', 
    },
    fieldHeading: {
        color:'#696B6D',
        paddingTop:'20px',
        lineHeight: "23px"
    },
    dateHeader: {
        color:'#3b276a',
        fontWeight:'bold', 
        paddingTop:'14px', 
        paddingBottom:'10px'
    },
    fieldValue: {
        lineHeight: "23px",
        color: "#28292B"
    }
}));
 
export default function VitalsInfo() {
    const styles = useStyles();
    const vital = useSelector(clientCache.getSelectedVital);

    const renderElement = (heading, value, unit = "") => {
        return (
            <div className = {styles.sectionContainer}>
                <div className = {styles.fieldHeading}>{ heading }<br/></div>
                <div className = {styles.fieldValue}>{ value ? value : "-" }{ value ? unit : "" }</div>
            </div>
        );
    }

    return (
        <div>        
            <div className = {styles.sectionContainerDate}>
                <div className = {styles.dateHeader}>{getFormattedDateTime(vital?.dateRecorded)}</div>
            </div>
            { renderElement("Blood Pressure", getBp(vital)) }
            { renderElement("Heart Rate", vital?.heartRate) }
            { renderElement("Respiratory Rate", vital?.respiratoryRate) }
            { renderElement("Temperature", vital?.temperature, "°F") }
            { renderElement("Height", vital?.height, " in") }
            { renderElement("Weight", vital?.weight, " lbs") }
            { renderElement("BMI", vital.bmi) }
            { renderElement("Pain", vital.pain) }
            { renderElement("Pulse Oximetry", vital.pulseOximetry, "%") }
            { renderElement("Inhaled Oxygen Concentration", vital.inhaledOxygenConcentration, "%") }
        </div>
    );
};