import {makeStyles} from "@material-ui/core/styles";
import {DateTime} from 'luxon';
import {dateToStringWithFormat} from "../../../common/utils/formatUtils";
import {useDispatch} from "react-redux";
import {scheduleCache} from "../../../cache/slices/schedule/scheduleCache";
import { PRIMARY_COLOR } from "../../../common/constants";

const useStyles = makeStyles(() => ({
    scheduleTitleBar: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: "#f6f6f6"
    },
    scheduleTitle: {
        fontSize: '18px',
        fontWeight: "normal",
        fontStyle: "normal",
        letterSpacing: 0,
        textAlign: "center",
        backgroundColor: PRIMARY_COLOR,
        color: "#ffffff",
        paddingTop: '12px',
        paddingBottom: '12px'
    }
}));

export default function ScheduleTitleBar({selectedDate}) {
    const dispatch = useDispatch();
    const styles = useStyles();

    const syncViewToSelectedDate = () => {
        if (selectedDate) {
            const now = DateTime.now();
            dispatch(scheduleCache.setViewDate(selectedDate.startOf('day').plus({hours: now.hour, minutes: now.minute})));
        }
    }

    return (
        <div id='scheduleTitleBar' className={styles.scheduleTitleBar} onClick={syncViewToSelectedDate}>
            <div id='scheduleTitle' className={styles.scheduleTitle} >
                {dateToStringWithFormat(selectedDate, 'EEEE, MMMM d, yyyy')}
            </div>
        </div>
    )
}
