import {evvRepository} from '../../db/evv';
import {DateTime} from "luxon";
import api from "./api";

export const DEFAULT_LOCKOUT_INTERVAL = 3;

export const LOGIN_ERRORS = {
    FIRST_TIME_LOGIN_OFFLINE: {
        title: 'First Time Login',
        message: 'User has not logged in while connected to the internet.  Please connect to the internet and try again.'
    },
    FIRST_TIME_USER_LOGIN_OFFLINE: {
        title: 'Network Error',
        message: 'Please connect the device to the internet to validate your credentials in the CareLogic system.'
    },
    ERROR_ATTEMPTING_DEVICE_LOGIN_OFFLINE: {
        title: 'Offline Device Login',
        message: 'Offline login to device failed, please check account, username and password fields and try again.'
    },
    // invalid password on device, wrong user id - password combination
    ERROR_ATTEMPTING_DEVICE_LOGIN: {
        title: 'Device Login',
        message: 'Login to device failed, please check account, username and password fields and try again.'
    },
    NOT_PREVIOUS_USER_LOGIN_OFFLINE: {
        title: 'Not Last User',
        message: 'A previous user of this device has data that was not synced. This data needs to be synced before a new user can log in.  Please connect to the internet and try again.'
    },
    SYNCHRONIZATION_REQUIRED: {
        title: 'Synchronization Required',
        message: 'A previous user of this device has data that was not synced. This data needs to be synced before a new user can log in. This process could take a while. Do you want to continue?'
    },
    RULES_REQUIRED: {
        title: 'Security Management Rules',
        message: 'No Security Management Rules can be found. Please contact your system administrator.'
    },
    PASSWORD_MISMATCH: {
        title: 'Login',
        message: 'Login failed, please check account, username and password fields and try again'
    },
    PRIVILEGE_REQUIRED_EVV: {
        title: 'Privilege to Access Required',
        message: 'Please note: You do not have privileges to access the CareLogic EVV application. Please contact your system administrator for access.'
    },
    ACCOUNT_PERMISSION_REQUIRED_EVV: {
        title: 'Permission to Access Required',
        message: 'The account does not have permission to access CareLogic EVV.  Please contact your system administrator for access.'
    },
    MAX_LOGIN_ATTEMPTS: {
        title: 'Maximum Login Attempts',
        processor: (user) => `Your login will be disabled for the next [${user.securityRule.lockoutInterval || DEFAULT_LOCKOUT_INTERVAL}] minutes. Please verify your username and password and try again. Thank you for your patience with this security measure. If you continue to have problems logging in, please contact your system administrator.`
    },
    MAX_LOGIN_ATTEMPTS_LOCAL: {
        title: 'Login Disabled',
        processor: () => `Your login will be disabled for the next ${localStorage.getItem('lockoutInterval') || DEFAULT_LOCKOUT_INTERVAL} minutes. Please verify your username and password and try again.`
    }
};

const saveUser = (user) => {
    return evvRepository.evvDb.user.put(user);
}

const loadExistingUserFromDb = () => {
    return evvRepository.evvDb.user.orderBy('lastLoginDate').last();
}

const fetchUser = (config) => {
    return api.login(config);
}

const EVV_AUTHORIZATION_CONTENT_CHANGEPASSWORD = '/evv/authorization/content/changePassword/';

const changePassword = (account, parameters) => {
    return api.post(EVV_AUTHORIZATION_CONTENT_CHANGEPASSWORD + account, {json: parameters});
}

const daysUntilPasswordExpires = (user) => {
    const beginDatePassword = DateTime.fromISO(user.beginDatePassword);
    const expireDate = beginDatePassword.plus({days: user.securityRule.expirationDays});
    const expireDateDiff = expireDate.diff(DateTime.now(), "days");

    return Math.floor(expireDateDiff.days);
}

const userService = {
    loadExistingUserFromDb,
    fetchUser,
    saveUser,
    changePassword,
    daysUntilPasswordExpires
};

export default userService;
