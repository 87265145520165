import { FormControl, FormControlLabel } from "@material-ui/core";
import EvvRadio from "../../../common/components/EvvRadio";
import StackedField from "../../../common/components/StackedField";
import DisplayErrorMessages from "../common/DisplayErrorMessages";
import FormField from "./FormField";

const mOrFOptions = [
    { value: 'Male', id: 'm', defaultValue: 'M' },
    { value: 'Female', id: 'f', defaultValue: 'F' }
];

class GenderField extends FormField{
    constructor(props) {
        super(props);
        
        let description = "";
        
        let descObj = this.fieldDescriptor.attributes.find( (attribute) => attribute.attributeId === this.fieldDescriptor.id +"_description" );
        if(Object.keys(descObj).length > 0) {
            description = descObj.value;
        }

        props.fieldDescriptor = this.fieldDescriptor;

        this.state = {
            description: description,
            options: mOrFOptions,
            data: props
        };
    }

    isChecked = (defaultValue) => {
        let isChecked = this.fieldDescriptor?.dbSavedValue === defaultValue || defaultValue === this.fieldDescriptor.selectedOption;
        return isChecked ? isChecked : false;
    }

    renderField(props, state) {
        return (
            <div>
                { this.fieldDescriptor?.businessRuleApplied !== "Hide" &&
                <StackedField paddingTop = "0px">
                    <span>{ this.state.description }</span>
                    {this.state.options.map(({ value, id, defaultValue }, index) => {
                        return (
                            <div key = { index }>
                                <FormControl component = "fieldset">
                                    <FormControlLabel
                                        control = {<EvvRadio
                                            { ...props }
                                            id = { `gender-${this.fieldDescriptor.id}-${id}` }
                                            name = { `gender-${this.fieldDescriptor.id}` }
                                            value = { defaultValue.toString() }
                                            checked = { this.isChecked(defaultValue) }
                                            onChange = { (event) => { props.onChange(event, this.fieldDescriptor, props); } }
                                            disabled = { state.isReadOnlyDoc }
                                        />}
                                        label = { value }
                                    />
                                </FormControl>
                            </div>
                        )
                    })
                    }
                    <div>
                        <DisplayErrorMessages
                            { ...this.state.data }
                        />
                    </div>
                </StackedField>
                }
            </div>
        )
    }
}

export default GenderField;