
import {makeStyles} from "@material-ui/core/styles";
import SectionButton from "../../caseload/SectionButton";
import ClientDetails from "../../caseload/ClientDetails";
import {useLocation} from "react-router-dom";
import FacesheetClientCard from "./FacesheetClientCard";
import {useSelector, useStore, useDispatch} from "react-redux";
import {clientCache} from "../../../cache/slices/client/clientSlice";
import VisitStopwatch from "../VisitStopwatch";
import {useEffect, useState} from "react";
import syncService from "../../../common/services/syncService";
import {appCache} from "../../../cache/slices/app/appSlice";
import clientService from "../../../common/services/clientService";
import { labelForMessages } from "../../../common/constants";

const useStyles = makeStyles(() => ({
    facesheetPage: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column'
    },
    sessionContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: "#e7e7e7",
        borderStyle: "solid",
        borderWidth: 0.5,
        borderColor: "#c5c5c5",
        padding: '9px 17px 8px 16px',
    },
    sessionButtonContainer: {
        width: '100%',
        maxWidth: '236px'
    },
    durationLabel: {
        fontSize: '28px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: "#4f4f4f",
        paddingRight: '16px'
   },
    clientDetailsContainer: {
        width: '100%',
        height: '100%',
        // overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        //flexGrow: 1,
        padding: '8px 16px 30px 16px'
    },
    sectionButtonsContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: "#e7e7e7",
        borderWidth: '0px'
    }
}));

export default function FacesheetPage() {
    const client = useSelector(clientCache.getSelectedClient);
    const visits = useSelector(syncService.visitService.getResults());
    const location = useLocation();
    const styles = useStyles();
    const store = useStore();
    const user = useSelector(appCache.getUser);
    const [firstRender, setFirstRender] = useState(true);
    const dispatch = useDispatch();
    const messages = useSelector(syncService.messageService.getResults());

    useEffect(() => {
        if(firstRender) {
            syncService.auditService.saveAuditEvent(null, client, user, "EVV Client Facesheet", "View");
            setFirstRender(false);
            dispatch(clientCache.selectClient(client));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firstRender, client, user]);

    useEffect(() => {
        if (client) {
            clientService.fetchAddresses({store}, appCache.getClients(store.getState()));
            syncService.syncFacesheetData({store}, client);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client]);

    return (
        <div className={styles.facesheetPage}>
            <VisitStopwatch client={client} visits={visits}/>
            <FacesheetClientCard client={client} call='FacesheetPage'/>
            <div className={styles.clientDetailsContainer} >
                <ClientDetails client={client} />
            </div>
            <div className={styles.sectionButtonsContainer}>
                <SectionButton destination={`${location.pathname}/documents`} label='Documents' imageSrc='../images/iconIcoDocuments@2x.png' />
                <SectionButton destination={messages?.length === 0 ? `${location.pathname}` :`${location.pathname}/messages`} style={{paddingTop:"12px"}} label={labelForMessages} imageSrc={( messages?.length > 0) ? '../images/messageBoardIcon.png' : '../images/noMessage.png' }></SectionButton>
                <SectionButton destination={`${location.pathname}/vitals`} label='Vitals' imageSrc='../images/vitalsTabPage.png' />
                <SectionButton destination={`${location.pathname}/allergies`} label='Allergies' imageSrc='../images/iconIcoAllergies@2x.png' />
                <SectionButton destination={`${location.pathname}/medications`} label='Medications' imageSrc='../images/iconIcoMedications@2x.png' />
                <SectionButton destination={`${location.pathname}/diagnoses`} label='Diagnosis' imageSrc='../images/iconIcoDiagnosis.svg' />
            </div>
        </div>
    )
}
