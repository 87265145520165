// import {appCache} from "../../cache/slices/app/appSlice";
import LookupRepository from "../../db/lookupRepository";
import LookupCache from "../../cache/slices/sync/lookupCache";
import api from "./api";
import {evvRepository} from "../../db/evv";
import {DateTime} from "luxon";
import {getDispatchFromConfig} from "../utils/miscUtils";
import {
    stringToDateWithFormat,
    APPOINTMENT_START_STOP_DATE_TIME_FORMAT
} from "../utils/formatUtils";
import { combineDateAndTime } from "../utils/dateTimeUtils";
import { documentCache } from "../../cache/slices/document/documentCache";
import { orderBy } from "lodash";


class LookupService {
    constructor(config) {
        this.tableName = config.tableName;
        this.readUrl = config.readUrl;
        this.writeUrl = config.writeUrl;
        this.primaryKey = config.primaryKey;
        this.cache = new LookupCache(config.tableName, config.primaryKey, config.syncService);
        this.repository = new LookupRepository(config.tableName);
        this.syncService = config.syncService;
        this.ignoreLoad = config.ignoreLoad;
        this.paginated = config.paginated ?? false;
        this.pageSize = config.pageSize ?? 100
    }

    operationStart = () => {return this.cache.actions.operationStart()};
    operationSuccess = (results) => {return this.cache.actions.operationSuccess(results)};
    operationError = (error) => {return this.cache.actions.operationError(error)};
    operationAdd = (record) => {return this.cache.actions.operationAdd(record)};
    operationReplace = (record) => {return this.cache.actions.operationReplace(record)};
    operationRemove = (record) => {return this.cache.actions.operationRemove(record)};
    applyGlobalFilter = (filterByName, filterByValue) => {return this.cache.actions.applyGlobalFilter({filterByName, filterByValue})};
    filterByOrganization = (parameters) => {return this.cache.actions.filterByOrganization(parameters)};

    getReducer = () => {
        return this.cache.slice.reducer;
    };

    isLoading = () => {
        return this.cache.isLoading();
    };

    getStatus = () => {
        return this.cache.getStatus();
    };

    getResults = () => {
        return this.cache.getResults();
    };

    filterResults = (state, filterByName, filterByValue) => {
        return this.cache.filterResults()(state, filterByName, filterByValue);
    };

    getError = () => {
        return this.cache.getError();
    };

    getFetchConfig = (config, json) => {
        return {
            dispatch: config.dispatch,
            store: config.store,
            json: json
        };
    };

    getFetchParameters = (parameters, lastSyncMillis) => {
        const json = parameters ? {...parameters} : {};

        if (lastSyncMillis > 0){
            json.lastSync = new Date(lastSyncMillis).toISOString();
        }

        return json;
    };

    sync = (config) => {
        return this.writeToServer(config)
            .then(() => {
                return this.readFromServer(config);
            });
    }

    readFromServer = (config) => {
        if (this.shouldIgnoreReadFromServer(config)){
            console.log('lookupService.readFromServer: ignoring readFromServer');
            console.log(config);
            return Promise.resolve([]);
        }

        return evvRepository.getSyncInfo(this.tableName)
            .then(syncInfo => {
                let lastSyncMillis = -1;

                if (syncInfo){
                   lastSyncMillis = syncInfo.lastSyncMillis;
                   if (config.ignoreLastSync !== true) {
                       config.doNotClear = true;
                   }
                }

                config.fetchConfig = this.getFetchConfig(
                    config,
                    this.getFetchParameters(
                        config.parameters,
                        config.ignoreLastSync ? -1 : lastSyncMillis
                    ));

                let retrievePromise;
                if (this.paginated){
                    retrievePromise = this.retrievePaginated([], config, this.pageSize, 1);
                } else {
                    retrievePromise = api.post(this.readUrl, config.fetchConfig);
                }

                return retrievePromise.then(results => {
                    evvRepository.saveLastSync(this.tableName, DateTime.now().toMillis());
                    return Promise.resolve(results);
                });
            });
    }

    retrievePaginated = (resources, config, limit, offset) => {
        const fetchConfig = this.getFetchConfig(
            config,
            {limit, offset}
        );

        return api.post(this.readUrl, fetchConfig)
            .then(result => {
                resources.push(...result.resources);
                if (result.hasMoreRecords) {
                    return this.retrievePaginated(resources, config, limit, offset + 1);
                } else {
                    return Promise.resolve({resources});
                }
            });
    }

    shouldIgnoreFetch = (config) => {
        return !!api.isOfflineAtLogin();
    }

    shouldIgnoreReadFromServer = (config) => {
        return !this.readUrl || config.ignoreReadFromServer === true;
    }

    fetch = (config) => {
        config = {...config};
        const dispatch = getDispatchFromConfig(config);
        dispatch(this.cache.actions.operationStart());
        if (this.shouldIgnoreFetch(config)) {
            return this.load(config);
        }else {
            return this.sync(config)
                .then(results => {
                    api.throwIfErrorResponse(results);

                    console.log("operation success - results from server:");
                    console.log(results);

                    if (this.tableName === 'document') {
                        let evvDocuments = [...results.resources];
                        let orderedEvvDocuments = orderBy(evvDocuments, ['serviceDate'], ['desc']);
                        dispatch(documentCache.setEvvDocuments(orderedEvvDocuments));
                    }

                    return this.save(results.resources, config)
                        .then(() => {
                            console.log("save successful - attempting to load from db");
                            console.log(results.resources)
                            return this.load(config);
                        })
                        .catch(error => {
                            console.log("Error saving entities - attempting to load from db anyway: ");
                            console.log(error);

                            return this.load(config);
                        })
                })
                .catch(error => {
                    console.log("Error fetching entities - attempting to load from db: ");
                    console.log(error);

                    return this.load(config);
                });
        }
    };

    beforeSave = (resourcesFromServer, config) => {
        return resourcesFromServer;
    }

    documentDb = () =>{
        return evvRepository.evvDb.document.toArray();
    }

    hasDocToDelete = (res, record) => {
        return (record.clientId === res.clientId && record.documentName === res.documentName && record.id !== res.id && (res?.documentStatus !== 'Incomplete' && res?.documentStatus !== 'Unsigned'));
    }

    hasDocToUpdate = (res, record) => {
        return (record.id === res.id);
    }

    deleteOldDocument = (document, dispatch) => {
        return this.deleteByPrimaryKey(document.id)
            .then(() => {
                dispatch(this.operationRemove(document));
            })
    }

    checkIfDocExists = async (recordsToSave, config) => {
        const dispatch = getDispatchFromConfig(config);
        let uniqueRecordsToSave = [];

        let uniqueRecords = [...recordsToSave];

        await this.documentDb().then((result) => {
            if (result.length > 0) {
                uniqueRecords?.forEach(async record => {
                    let docToDeleteFromDb = result.filter( res => ( this.hasDocToDelete(res, record)));   //latest PDF document case
                    let docToUpdateFromDb = result.find( res => ( this.hasDocToUpdate(res, record)));   //Document with pdfUrl case

                    if (docToDeleteFromDb && docToDeleteFromDb.length > 0) {
                        dispatch(documentCache.removePdfTabs(record));
                        uniqueRecordsToSave.push(record);
                        docToDeleteFromDb.forEach( doc => {
                            this.deleteOldDocument(doc, dispatch);
                            dispatch(documentCache.removePdfTabs(doc));
                        });
                    } else if (docToUpdateFromDb && Object.keys(docToUpdateFromDb).length > 0) {
                        let pdfUrl = docToUpdateFromDb?.pdfUrl;
                        if (pdfUrl) {
                            let tempDoc = {...record, pdfUrl};
                            uniqueRecordsToSave.push(tempDoc);
                            await this.deleteOldDocument(docToUpdateFromDb, dispatch);
                        } else {
                            //In case of signed document that will be automatically replaced with the latest pdf document from the server after executing this.repository.saveAll
                            uniqueRecordsToSave.push(record);
                        }
                    } else {
                        uniqueRecordsToSave.push(record);
                    }
                });
            } else {
                uniqueRecordsToSave = uniqueRecords;
            }
            this.repository.saveAll(uniqueRecordsToSave);
            return this.load(config);
        }).catch((error) => {
          console.log('Error: ', error);
        });
    }

    processDocTableData = (config, recordsToSave) => {
        if (config?.saveDocument === true) {
            return this.repository.saveAll(recordsToSave);
        } else {
            //If its a 'document' table & call is on refresh then we need to check if same document entry exists in db & if yes, need to remove it
            this.checkIfDocExists(recordsToSave, config);
            return Promise.resolve([]);
        }
    }

    save = (records, config) => {
        console.log('LookupService.save - tableName:');
        console.log(this.tableName);
        console.log('LookupService.save - config:');
        console.log(config);
        if (records && records.length > 0) {
            const recordsToSave = this.beforeSave(records, config);
            console.log("records to save:");
            console.log(recordsToSave);
            if (this.tableName === 'visit') {
                recordsToSave.forEach((visit) => {
                    visit.actualStartDateInstance = stringToDateWithFormat(visit.beginActualDateTime, APPOINTMENT_START_STOP_DATE_TIME_FORMAT);
                    visit.actualEndDateInstance = stringToDateWithFormat(visit.endActualDateTime, APPOINTMENT_START_STOP_DATE_TIME_FORMAT);
                    visit.startDateTime = visit.actualStartDateInstance.ts;
                    visit.beginDateTime = typeof visit.beginDateTime === "string" ? stringToDateWithFormat(visit.beginDateTime, APPOINTMENT_START_STOP_DATE_TIME_FORMAT).ts : visit.beginDateTime;
                    visit.endDateTime = typeof visit.endDateTime === "string" ? stringToDateWithFormat(visit.endDateTime, APPOINTMENT_START_STOP_DATE_TIME_FORMAT).ts : visit.endDateTime;
                    if (typeof visit.client.clientId === "undefined") {
                        visit.client.clientId = visit.clientId;
                        const appointmentDateInstance = (visit.appointment.appointmentDate ? DateTime.fromISO(visit.appointment.appointmentDate.substring(0, 10)) : DateTime.now()).startOf('day');
                        const startDateInstance = combineDateAndTime(appointmentDateInstance, visit.appointment.startTime);
                        const start = startDateInstance.toJSDate();
                        visit.appointment.start = start;
                        visit.appointment.startDateInstance = DateTime.fromJSDate(start);
                    }
                });
            }
            if (config.parameters || config.doNotClear) {
                console.log("LookupService.save - has parameters: saveAll but don't clear");
                if (this.tableName === 'document') {
                    return this.processDocTableData(config, recordsToSave);
                }
                return this.repository.saveAll(recordsToSave);
            }
            console.log("LookupService.save - no parameters: clearAndSave");
            return this.repository.clearAndSave(recordsToSave);
        } 

        if (records.length === 0 && this.tableName === 'visit') {
            return this.repository.clearAndSave();
        }

        console.log('LookupService.save - nothing to save:');
        return Promise.resolve([]);
    }

    deleteByPrimaryKey = (primaryKey) => {
        console.log("record to delete:");
        console.log(primaryKey);

        return this.repository.deleteByPrimaryKey(primaryKey)
    }

    loadByPrimaryKey = (primaryKey) => {
        console.log("loading record by primary key from table: " + this.tableName);
        console.log(primaryKey);

        return this.repository.loadRecordByProperty(this.primaryKey, primaryKey);
    }

    loadFromRepository = (config) => {
        return this.repository.loadAll();
    }

    load = (config) => {
        if (this.ignoreLoad === true || config.ignoreLoad === true){
            return [];
        }

        const dispatch = getDispatchFromConfig(config);
        return this.loadFromRepository(config)
            .then((entities) => {
                console.log("Loaded entities: ");
                console.log(entities);
                const processedEntities = this.afterLoad(entities, config);
                dispatch(this.cache.actions.operationSuccess(processedEntities));
                return entities;
            })
            .catch((error) => {
                console.log("Error loading entities...");
                console.log(error);
                dispatch(this.cache.actions.operationSuccess([]));
                return [];
            });
    };

    afterLoad(entities, config){
        return entities;
    }

    writeToServer(config){
        return Promise.resolve();
    }
}

export default LookupService;
