/* eslint-disable eqeqeq */
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import EvvButton from "../../../common/components/EvvButton";
import StackedField from "../../../common/components/StackedField";
import {useSelector, useStore} from "react-redux";
import {appCache} from "../../../cache/slices/app/appSlice";
import {defaultDateAsString, defaultTimeAsString, formatAddress, formatClientName} from "../../../common/utils/formatUtils";
import {
    DEFAULT_STATUS,
    getActivityQualifiersForActivity,
    getFilteredStatusesByStartDate,
    getInitialActivity,
    getInitialOrganization,
    getInitialProgram,
    getServiceLocation,
    isStatusEditable,
    getActivityQualifiersForActivityReadOnly,
    getStartAddressFromAppointment,
    getEndAddressFromAppointment,
    isEvvActivity,
    isAddressRequiredForOrganization,
} from "../../../common/utils/appointmentUtils";
import syncService from "../../../common/services/syncService";
import ActivityQualifiers from "../../../common/components/ActivityQualifiers";
import {useState, useEffect} from "react";
import EvvSelect from '../../../common/components/EvvSelect';
import AlertDialog from '../../../common/components/AlertDialog';
import { PRIMARY_COLOR } from '../../../common/constants';


const useStyles = makeStyles((theme) => ({
    dialogPaper: {
        margin: '30px 8px 30px 8px',
        maxWidth: '660px',
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 16px)'
        },
        [theme.breakpoints.up(601)]: {
            width: '660px'
        }
    },
    dialogTitle: {
        backgroundColor: PRIMARY_COLOR,
        height: '42px',
        fontSize: '16px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: "#ffffff",
        paddingLeft: '16px',
        display: 'flex',
        alignItems: 'center'
    },
    dialogContent: {
        padding: "19px 19px 25px 19px",
    },
    dateRow: {
        [theme.breakpoints.down('sm')]: {
            width: '125px'
        },
        [theme.breakpoints.up(601)]: {
            width: '225px'
        }
    },
    timeRow: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: ' 103px 103px',
        columnGap: '15px',
        alignItems: 'flex-end'
    },
    dateTimeRow: {
        width: '100%',
        display: 'grid',
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '125px 103px 103px',
        },
        [theme.breakpoints.up(601)]: {
            gridTemplateColumns: '225px 103px 103px',
        },
        columnGap: '10px',
        alignItems: 'flex-end'
    },
    closeButtonGroup: {
        padding: "0px 16px 15px 16px",
        justifyContent: 'flex-end'
    },
    okCancelButtonGroup: {
        padding: "0px 16px 15px 16px",
        justifyContent: 'space-between'
    },
    okButton: {
        padding: '0',
        minWidth: '125px'
    },
    cancelButton: {
        padding: '0'
    },
    label: {
        flexDirection: 'column',
        textTransform: "none",
        color: '#888888'
    },
    filterSelect: {
        width: '100%',
        height: '27px',
        borderRadius: '2px',
        background: "transparent",
        borderStyle: "solid",
        borderWidth: '1px',
        borderColor: "#b6b6b6"
    },
    errorMessage : {
        position: 'relative',
        bottom: '-5px',
        fontSize: '13px',
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: '16px',
        letterSpacing: 0,
        color: "#dc0707"
    }
}));


const ALERT_DIALOG_ERROR = 'error';
const ALERT_DIALOG_OVERNIGHT = 'overnight';

export default function ViewAppointmentDialog({appointment, showStatus = true, onClose, stopwatchActive, visitCollection }){
    const activityQualifiers = useSelector(syncService.descriptorCacheService.activityQualifiersCache.getResults()) || [];
    // eslint-disable-next-line no-unused-vars
    const [activityQualifiersForActivity, setActivityQualifiersForActivity] = useState(appointment.activity ? getActivityQualifiersForActivity(appointment.activity, activityQualifiers) : []);
    const [activityDetailDescriptorList, setActivityDetailDescriptorList] = useState(appointment.activityDetailDescriptorList ? [...appointment.activityDetailDescriptorList] : []);
    const programs = useSelector(syncService.programService.getResults());
    const activities = useSelector(syncService.activityService.getResults());
    const serviceLocations = useSelector(syncService.serviceLocationService.getResults());
    const organizations = useSelector(syncService.organizationService.getResults());
    const user = useSelector(appCache.getUser);
    const clients = useSelector(appCache.getClients);
    const evvState = appointment?.organization?.evvState;
    let activity = appointment?.activity;

    let client = appointment.client;

    if(!client && clients && clients.length > 0){
         client = clients.find(c => Number(c.clientId) === Number(appointment.clientId));
    }   

    const [status, setStatus] = useState(appointment.status || DEFAULT_STATUS);
    const [statusEditable] = useState(appointment.status ? isStatusEditable(appointment, visitCollection) : false);
    const [alertDialogConfig, setAlertDialogConfig] = useState(null);
    const [saveInProgress, setSaveInProgress] = useState(false);
    const [openAlertDialog, setOpenAlertDialog] = useState(true);
    const store = useStore();

    const styles = useStyles();
    useEffect(() => {
        syncService.auditService.saveAuditEvent(null, client, user, "EVV Appointment Details", "View");
    }, [client, user]);

    const handleCancel = () => {
        if (onClose){
            onClose(null);
        }
    }

    const renderDateTime = () => {
        return (
            <>
                <div className={styles.dateRow}>
                    {renderAppointmentDate()}
                </div>
                <div className={styles.timeRow}>
                    {renderAppointmentTime()}
                </div>
            </>
        );
    };

    const renderAppointmentDate = () => {
        return (
            <StackedField label='Date' paddingTop="17px">
                <div>{defaultDateAsString(appointment.startDateInstance)}</div>
            </StackedField>
        );
    };

    const renderAppointmentTime = () => {
        return (
            <>
                <StackedField label='Start Time' paddingTop="17px">
                    <div>{defaultTimeAsString(appointment.startDateInstance)}</div>
                </StackedField>
                <StackedField label='End Time' paddingTop="17px">
                    <div>{defaultTimeAsString(appointment.endDateInstance)}</div>
                </StackedField>
            </>
        );
    };

    const renderSaveButton = () => {
        if (saveInProgress) {
            return (
                <div className={styles.loadingSpinnerContainer}>
                    <CircularProgress size={40} className={styles.loadingSpinner} />
                </div>
            );
        } else {
            return (
                <div className={styles.okButton}>
                    <EvvButton
                        type='primary'
                        onClick={handleSave}
                    >
                        Save
                    </EvvButton>
                </div>
            );
        }
    }

    const handleSave = async () => {
        if (saveInProgress){
            return;
        }
        setSaveInProgress(true);
        appointment.status = status;
        if (status === 'Kept'){
            appointment.activityDetailDescriptorList = activityDetailDescriptorList.filter(desc => Number.isInteger(desc.descriptorId) && desc.descriptorId > 0);
        }
        saveAppointment(appointment);
    };

    const saveAppointment = (appointmentToSave) => {
        setSaveInProgress(true);
        syncService.appointmentService.saveAppointment(appointmentToSave, {store, doNotClear: true, ignoreVisitSave: true})
            .then(savedAppointment => {
                onClose(savedAppointment);
                setSaveInProgress(false);
            })
            .catch(error => {
                setSaveInProgress(false);
                if (error.message) {
                    setAlertDialogConfig({
                        dialogId: ALERT_DIALOG_ERROR,
                        dialogTitle: 'Cannot save the appointment',
                        dialogMessage: formatErrorMessage(error.message)
                    });
                } else {
                    setAlertDialogConfig({
                        dialogId: ALERT_DIALOG_ERROR,
                        dialogTitle: 'Cannot save the appointment',
                        dialogMessage: error.toString()
                    });
                }
            });
    }

    const formatErrorMessage = (message) => {
        return message.replace('ERROR/~/', '');
    }

    const handleAlertDialogClose = async (okClicked) => {
        setAlertDialogConfig(null);
        if (!okClicked && alertDialogConfig.dialogId === ALERT_DIALOG_OVERNIGHT){
            setOpenAlertDialog(false);
            return;
        }
    }

    const handleActivityQualifierChange = (e, activityQualifier) => {
        const activityDetailDescriptorChange = [];

        if (e.target.checked) {
            activityDetailDescriptorList.push({descriptorId: activityQualifier.descriptorId, descriptorType: 'Activity Qualifiers'})
        } else {
            const index = activityDetailDescriptorList.findIndex(aq => aq.descriptorId === activityQualifier.descriptorId);
            if (index !== -1){
                activityDetailDescriptorList.splice(index, 1);
            }
        }

        activityDetailDescriptorChange.push(activityDetailDescriptorList);
        setActivityDetailDescriptorList(activityDetailDescriptorChange);
    }

    const getAddress = (call) =>{
        let address;
        
        if(call == 'start'){
            let startAddress = getStartAddressFromAppointment(appointment, client);
            address = startAddress ? formatAddress(startAddress, evvState) : null;
        } else {
            let endAddress = getEndAddressFromAppointment(appointment, client);
            address = endAddress ? formatAddress(endAddress, evvState) : null;
        }

        return address;
    }

    const renderContent = () => {
        return (
            <div>
                <div>
                    <StackedField label='Client Name' paddingTop="0px" >
                        <div>{formatClientName(client)}</div>
                    </StackedField>
                </div>
                {renderDateTime()}
                <div>
                    <StackedField label='Organization' paddingTop="17px" >
                        <div>{getInitialOrganization(user, appointment, organizations).name}</div>
                    </StackedField>
                </div>
                <div>
                    <StackedField label='Program' paddingTop="17px" >
                        <div>{getInitialProgram(appointment, programs).name}</div>
                    </StackedField>
                </div>                
                <div>
                    <StackedField label='Activity' paddingTop="17px" >
                        <div>{getInitialActivity(appointment, activities).description}</div>
                    </StackedField>
                </div>
                {isEvvActivity(activity) && isAddressRequiredForOrganization(appointment?.organization) &&
                <div>
                    <StackedField label='Start Address' paddingTop="17px" >                    
                        <div>{getAddress('start')}</div>
                    </StackedField>
                </div>
                }
                {isEvvActivity(activity) && isAddressRequiredForOrganization(appointment?.organization) &&
                <div>
                    <StackedField label='End Address' paddingTop="17px" >
                        <div>{getAddress('end')}</div>
                    </StackedField>
                </div>
                }
                <div>
                    <StackedField label='Service Location' paddingTop="17px" >
                        <div>{getServiceLocation(appointment, serviceLocations).placeOfService}</div>
                    </StackedField>
                </div>
                {/* In case of session is ended and status is "Kept" || "Co-staff" */}
                {showStatus && (!statusEditable) &&
                <div>
                    <StackedField label='Status' paddingTop="17px">
                        <div>{appointment.status}</div>
                    </StackedField>
                </div>
                }
                {showStatus && statusEditable &&
                <div>
                <div>
                    <StackedField label='Status' paddingTop="17px">
                        <EvvSelect id='status' value={status} className={styles.filterSelect}
                                   onChange={(e) => setStatus(e.target.value)}>
                            {getFilteredStatusesByStartDate(appointment.startDateInstance, appointment.organization, appointment.status, [], 'VVD').map((item, index) =>
                                <option key={`option_${index}`} data-testid={`status_option_${index}`} value={item}>{item}</option>)}
                        </EvvSelect>
                    </StackedField>
                </div>
                {status === 'Kept' && activityQualifiersForActivity.length > 0 &&
                <div>
                    <StackedField label='Activity Qualifiers' paddingTop="17px">
                        <ActivityQualifiers activityQualifiers={activityQualifiersForActivity}
                                            selectedActivities={activityDetailDescriptorList}
                                            onActivityQualifierChange={handleActivityQualifierChange}/>
                    </StackedField>
                </div>
                }
                </div>
                }

                {getActivityQualifiersForActivityReadOnly(appointment, activityQualifiersForActivity)}

                {alertDialogConfig &&
                <AlertDialog
                    open={openAlertDialog}
                    dialogTitle={alertDialogConfig.dialogTitle}
                    dialogMessage={alertDialogConfig.dialogMessage}
                    showOkButton={alertDialogConfig.showOkButton}
                    showCancelButton={alertDialogConfig.showCancelButton}
                    okButtonText={alertDialogConfig.okButtonText || 'Ok'}
                    cancelButtonText={alertDialogConfig.cancelButtonText || 'Cancel'}
                    handleClose={handleAlertDialogClose}
                />
                }
            </div>
        );
    }

    return (
        <Dialog
            classes={{paper: styles.dialogPaper}}
            open={true}
            disableEscapeKeyDown={true}
        >
            <DialogTitle classes={{root: styles.dialogTitle}} id="alert-dialog-title">Appointment</DialogTitle>
            <DialogContent classes={{root: styles.dialogContent}}>
                {renderContent()}
            </DialogContent>
            {(!statusEditable) &&
                <DialogActions classes={{root: styles.closeButtonGroup}}>
                    <div className={styles.okButton}>
                        <EvvButton
                            type='primary'
                            onClick={handleCancel}
                        >
                            Close
                        </EvvButton>
                    </div>
                </DialogActions>
            }
            {statusEditable &&
                <DialogActions classes={{root: styles.okCancelButtonGroup}}>
                    <div className={styles.cancelButton}>
                        <EvvButton type='tertiary' onClick={handleCancel} >Cancel</EvvButton>
                    </div>
                    {renderSaveButton()}
                </DialogActions>
            }
        </Dialog>
    );
}