import { useEffect, useState } from "react";
import EvvButton from "../../../common/components/EvvButton";
import InputForPassword from "../ResetPasswordDialog/InputForPassword";
import * as rdd from "react-device-detect";
import { getDevicePlatformType } from "../../../common/utils/systemUtils";
import useStyles from "../../../common/stylesCarelogicMobile";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import AlertDialog from "../../../common/components/AlertDialog";
import StaffService from "../../../common/services/staffService";
import LoginPagePingOneCallback from "../LoginPagePingOne/LoginPagePingOneCallback";
import { useAuth } from "react-oidc-context";

export default function ESignaturePage() {

  const SIGNATURE = "signature";
  const CONFIRM_SIGNATURE = "confirmSignature";
  const MOBILE_PLATFORM = "Mobile";
  const ERROR_MSG_MISSING_SIGNATURE = "Please enter a Signature";
  const ERROR_MSG_MISSING_CONFIRM_SIGNATURE = "Please re-enter your Signature";
  const ERROR_MSG_NOT_MATCHED_SIGNATURE = "Error: The signature values do not match. Please try again.";
  const ERROR_MSG_NOT_VALID_REQUIREMENTS = "Error: The signature entered does not meet the requirements.  Please check the rules and try again.";

  const DIALOG_SIGNATURE_REQUIREMENTS = "DIALOG_SIGNATURE_REQUIREMENTS";
  const ERROR_DIALOG_SERVICE_RESPONSE_ERROR = "ERROR_DIALOG_SERVICE_RESPONSE_ERROR";
  const ERROR_DIALOG_NETWORK_CONNECTION_ERROR = "ERROR_DIALOG_NETWORK_CONNECTION_ERROR";

  const SIGNATURE_MAX_LENGTH = 20;

  const isHandheld = (getDevicePlatformType(rdd) === MOBILE_PLATFORM);
  const containerStyleHandheld = isHandheld ? { height:"643px", width: "375px", background: "none", boxShadow: "none"} : {};
  const headerStyleHandheld = isHandheld ? { paddingTop: "24px" } : {};
  const logoStyleHandheld = isHandheld ? { width: "190px", height: "50px" } : {};

  const [signature, setSignature] = useState("");
  const [confirmSignature, setConfirmSignature] = useState("");
  const [errors, setErrors] = useState({});
  const [isValidSignature, setIsValidSignature] = useState(false);
  const [isCompletedSaveSignature, setIsCompletedSaveSignature] = useState(false);
  const [alertDialogConfig, setAlertDialogConfig] = useState(null);
  const [ruleMinLength, setRuleMinLength] = useState(null);
  const [ruleMinNumbers, setRuleMinNumbers] = useState(null);
  const [ruleMinSymbols, setRuleMinSymbols] = useState(null);

  const auth = useAuth();
  const userName = auth.user?.profile?.userName;
  const styles = useStyles();
  const newErrors = { ...errors };

  useEffect(() => {
    getSecurityRule();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isValidSignature) saveSignature();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidSignature]);

  const saveSignature = async () => {
    try {
      if (!window.navigator.onLine) {
        showNetworkConnectionError();
        return;
      }

      const staffService = new StaffService();
      const response = await staffService.callWriteSignature(signature);
      if (!response || !response.resources || response.resources.length === 0) {
        showServiceResponseError();
        return;
      }

      const body = response.resources[0];
      const isValid = body?.electronicSignature?.trim() && body.staffId !== null;
      isValid ? setIsCompletedSaveSignature(true) : showServiceResponseError();

    } catch (error) {
      console.error("Error saving Staff Signature: ", error);
    }
  };

  const getSecurityRule = async () => {
    try {
      const staffService = new StaffService();
      const response = await staffService.callReadSecurityRule();
      if (!response || !response.resources || response.resources.length === 0) {
        showServiceResponseError();
        return;
      }

      const body = response.resources[0];
      setRuleMinLength(body?.minLength != null ? body.minLength : null);
      setRuleMinNumbers(body?.minNumbers != null ? body.minNumbers : null);
      setRuleMinSymbols(body?.minSymbols != null ? body.minSymbols : null);

    } catch (error) {
      console.error("Error read Security Rules: ", error);
    }
  };

  const isValidRequirements = () => {
    const hasValidLength = signature.length >= ruleMinLength && confirmSignature.length >= ruleMinLength;
  
    const countNumbers = (str) => (str.match(/\d/g) || []).length;
    const countSymbols = (str) => (str.match(/[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/g) || []).length;
  
    const hasValidNumbers = ruleMinNumbers === 0 || (countNumbers(signature) >= ruleMinNumbers && countNumbers(confirmSignature) >= ruleMinNumbers);
    const hasValidSymbols = ruleMinSymbols === 0 || (countSymbols(signature) >= ruleMinSymbols && countSymbols(confirmSignature) >= ruleMinSymbols);
    const containsUserName = signature.includes(userName) || confirmSignature.includes(userName);
  
    if (!hasValidLength || !hasValidNumbers || !hasValidSymbols || containsUserName) {
      newErrors.requirements = ERROR_MSG_NOT_VALID_REQUIREMENTS;
    } else {
      delete newErrors.requirements;
    }
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleCreateSignature = (event) => {
    event.preventDefault();
    const isSignatureValid = validateInput(SIGNATURE, signature);
    const isConfirmSignatureValid = validateInput(CONFIRM_SIGNATURE, confirmSignature);
    
    if (isSignatureValid && isConfirmSignatureValid && isValidRequirements()) {
      setIsValidSignature(true)
    }
  };

  const handleChange = (event) => {
    const { id, value } = event.target;

    if (value.length <= SIGNATURE_MAX_LENGTH) {
      if (id === SIGNATURE) {
        setSignature(value);
      } else if (id === CONFIRM_SIGNATURE) {
        setConfirmSignature(value);
      }
    }

    validateInput(id, value);
  };

  const validateInput = (id, value) => {
    delete newErrors.requirements;

    if (id === SIGNATURE) {
      value.length === 0 ? newErrors.signature = ERROR_MSG_MISSING_SIGNATURE : delete newErrors.signature;
    } else if (id === CONFIRM_SIGNATURE) {
      value.length === 0 ? newErrors.confirmSignature = ERROR_MSG_MISSING_CONFIRM_SIGNATURE : delete newErrors.confirmSignature;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    if (signature && confirmSignature && signature !== confirmSignature) {
      newErrors.match = ERROR_MSG_NOT_MATCHED_SIGNATURE;
    } else {
      delete newErrors.match;
    }

    setErrors(newErrors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signature, confirmSignature]);

  const showSignatureRequirements = () => {
    const getPluralizedText = (value, singularText, pluralText) => {
      return value === 1 ? singularText : pluralText;
    };

    setAlertDialogConfig({
      dialogId: DIALOG_SIGNATURE_REQUIREMENTS,
      dialogTitle: "Signature requirements",
      dialogMessage:
    (
      <div className={styles.modalSignatureRequirements}>
        <ol>
          <li>Signature must have a minimum length of {ruleMinLength} {getPluralizedText(ruleMinLength, "character", "characters")}.</li>
          {ruleMinNumbers > 0 && <li>Signature must have a minimum length of {ruleMinNumbers} {getPluralizedText(ruleMinNumbers, "number", "numbers")}.</li>}
          {ruleMinSymbols > 0 && <li>Signature must have a minimum length of {ruleMinSymbols} {getPluralizedText(ruleMinSymbols, "symbol", "symbols")}.</li>}
          <li>Signature cannot match your username.</li>
          <li>Signature is case sensitive.</li>
        </ol>
      </div>
    ),
      okButtonText: "Close",
      showOkButton: true,
      handleClose: handleDialogClose
    });
  }

  const showNetworkConnectionError = () => {
    setAlertDialogConfig({
      dialogId: ERROR_DIALOG_NETWORK_CONNECTION_ERROR,
      dialogTitle: "Network connection error",
      dialogMessage: "The device is offline. Please check your network connection and try again.",
      okButtonText: "Close",
      showOkButton: true,
      handleClose: handleDialogClose
    });
  }

  const showServiceResponseError = () => {
    setAlertDialogConfig({
      dialogId: ERROR_DIALOG_SERVICE_RESPONSE_ERROR,
      dialogTitle: "Service response error",
      dialogMessage: "A problem occurred with the service response. Please try again later.",
      okButtonText: "Close",
      showOkButton: true,
      handleClose: handleDialogClose
    });
  }

  const handleDialogClose = () => {
    setAlertDialogConfig(null);
  }

  return (
    <>
      { isCompletedSaveSignature ? <LoginPagePingOneCallback /> : 
        <div className={styles.tabletSetOfflinePin}>
          <div className={styles.offlinePageContainer} style={containerStyleHandheld}>
            <div className={styles.header} style={headerStyleHandheld}>
              <img className={styles.logo} src={`${process.env.PUBLIC_URL}/images/carelogic-mobile-logo.png`} alt="Carelogic Mobile" style={logoStyleHandheld} />
            </div>
            <div className={styles.content}>
              <div className={styles.text}>Create Signature</div>
              <div className={styles.descriptionSignature}>Enter a signature password to use when signing a service document or other actions where verification is needed.</div>
              <div className={styles.textWrapperLabelInfo}>
                <span className={styles.textWrapperLabelInfo} onClick={showSignatureRequirements}>View signature requirements
                  <span className={styles.iconInfoIndicator}><FontAwesomeIcon icon={faCircleQuestion} size="1x" /></span>
                </span>
              </div>
              <form className={styles.formContainer} onSubmit={handleCreateSignature} noValidate autoComplete="off" onChange={handleChange} >
                <div className={styles.spaceBelow}>
                  <div className={styles.textWrapperLabel}>New Signature<span className={styles.mandatoryIndicator}>*</span></div>
                  <InputForPassword id="signature" value={signature} placeholder="Enter Signature" onChange={handleChange} />
                  {errors.signature && <div className={styles.errorMessage}>{errors.signature}</div>}
                </div>
                <div className={styles.spaceBelow}>
                  <div className={styles.textWrapperLabel}>Confirm Signature<span className={styles.mandatoryIndicator}>*</span></div>
                  <InputForPassword id="confirmSignature" value={confirmSignature} placeholder="Re-enter Signature" type="password" onChange={handleChange} />
                  {errors.confirmSignature && <div className={styles.errorMessage}>{errors.confirmSignature}</div>}
                  {(signature.length > 0 && confirmSignature.length > 0) && errors.match && <div className={styles.errorMessage}>{errors.match}</div>}
                  {errors.requirements && <div className={styles.errorMessage}>{errors.requirements}</div>}
                </div>
                {isHandheld ? (
                  <div className={styles.spaceBelow} styleOverride={{ paddingTop: '90px' }}>
                    <EvvButton id="createSignature" type="primary" onClick={handleCreateSignature} styleOverride={{ marginTop: '15px' }}>Create Signature</EvvButton>
                  </div>
                ) : (
                  <EvvButton id="createSignature" type="primary" onClick={handleCreateSignature} styleOverride={{ marginTop: '15px' }}>Create Signature</EvvButton>
                )}
              </form>
            </div>
          </div>
        </div>
      }
      { alertDialogConfig &&
        <AlertDialog
            open={true}
            dialogTitle={alertDialogConfig.dialogTitle}
            dialogMessage={alertDialogConfig.dialogMessage}
            showOkButton={alertDialogConfig.showOkButton}
            showCancelButton={alertDialogConfig.showCancelButton}
            okButtonText={alertDialogConfig.okButtonText || 'Ok'}
            cancelButtonText={alertDialogConfig.cancelButtonText || 'Cancel'}
            handleClose={alertDialogConfig.handleClose}
        />
      }
    </>
  );
  
}
