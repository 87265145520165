import {useEffect, useRef, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from "@material-ui/core/styles";
import EvvButton from "../../../common/components/EvvButton";
import StackedField from "../../../common/components/StackedField";
import SignaturePad from "react-signature-canvas";
import Switch from "../../../common/components/Switch";
import AlertDialog from "../../../common/components/AlertDialog";
import {useDispatch, useSelector} from "react-redux";
import SignatureMissingDialog from "../SignatureMissingDialog";
import VerificationReasonDialog from "../VerificationReasonDialog";
import syncService from "../../../common/services/syncService";
import {
    getEndAddressFromAppointment,
    getInSessionVisits,
    getStartAddressFromAppointment,
    isAddressRequiredForOrganization
} from "../../../common/utils/appointmentUtils";
import AddressDistanceDialog from "../AddressDistanceDialog";
import {getDistanceInMiles} from "../../../common/utils/systemUtils";
import {isValidCoordinate} from "geolib";
import {appCache, setGlobalVisit} from "../../../cache/slices/app/appSlice";
import { VisitVerificationCommonDetails } from './VisitVerificationCommonDetails';
import { getVisitAddress, hasOverlappingVisitAppointments, handleResumeDocument, resumeDocumentation, shouldResumeDocumentation } from '../../../common/utils/visitUtils';
import { DISTANCE_MESSAGE_DEFAULT, DISTANCE_MESSAGE_FOR_NEWJERSEY, EVV_STATE, PRIMARY_COLOR, QUATERNARY_COLOR, ALERT_DIALOG_ERROR, MAX_OPEN_DOCUMENTS  } from '../../../common/constants';
import { checkIfDocExistsInTab, hasAttachedDocument, hasMaxDocsOpened } from '../../../common/utils/documentUtils';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { documentCache } from '../../../cache/slices/document/documentCache';
import { cloneDeep } from 'lodash';

const useStyles = makeStyles(() => ({
    dialogPaper: {
        margin: '30px 8px 30px 8px',
        width: '100%',
        height: '100%',
        maxWidth: '90%'
    },
    dialogTitleVVD: {
        backgroundColor: PRIMARY_COLOR,
        maxWidth: '100%',
        maxHeight: '100%',
        height: '42px',
        width: 'auto',
        fontSize: '18px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: "#ffffff",
        paddingLeft: '21px',
        display: 'flex',
        alignItems: 'center'
    },
    dialogTitle: {
        backgroundColor: PRIMARY_COLOR,
        maxWidth: '100%',
        maxHeight: '100%',
        height: '42px',
        width: 'auto',
        fontSize: '16px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: "#ffffff",
        paddingLeft: '16px',
        display: 'flex',
        alignItems: 'center'
    },
    dialogContent: {
        padding: "26px 39px 38px 36px",
    },
    dialogContentHandheld: {
        padding: "26px 14px 30px 15px",
    },
    visitVerificationContent: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    visitInfo: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    timesButton: {
        fontSize: '16px',
        fontWeight: "normal",
        fontStyle: "normal",
        letterSpacing: 0,
        color: QUATERNARY_COLOR,
        paddingLeft: '0px',
        background: 'transparent',
        border: 0
    },
    okCancelButtonGroup: {
        padding: "0px 39px 20px 36px",
        justifyContent: 'space-between'
    },
    okCancelButtonGroupHandheld: {
        padding: "10px 16px 26px 16px",
        justifyContent: 'space-between'
    },
    okButton: {
        padding: '0',
        minWidth: '164px',
        paddingLeft: '17px'
    },
    okButtonMobile: {
        padding: '0',
        minWidth: '100px',
        paddingLeft: '17px'
    },
    navigationButtons: {
        height: '100%',
        display: 'flex'
    },
    cancelButton: {
        padding: '0'
    },
    label: {
        flexDirection: 'column',
        textTransform: "none",
        color: '#888888'
    },
    signatureContainer:{
        height: '20px',
        minHeight: '20px',
        maxHeight: '20px',
        background: 'transparent'
    },
    innerContainer:{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'space-between',
        borderRadius: "3px",
        backgroundColor: "#f6f6f6",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: "#dddddd",
    },
    signaturePanel:{
        width: "100%",
        minHeight: '245px',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'space-between',
        borderRadius: "3px",
        backgroundColor: "#f6f6f6",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: "#dddddd",
        paddingLeft: '5px',
        padding:'10px 20px 0px 20px'
    },
    signaturePanelHandheld:{
        width: "100%",
        minHeight: '160px',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'space-between',
        borderRadius: "3px",
        backgroundColor: "#f6f6f6",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: "#dddddd",
        paddingLeft: '5px',
        padding:'10px 20px 0px 20px'
    },
    signatureCanvas:{
        margin: '10px',
        borderRadius: "3px",
        backgroundColor: "#f6f6f6",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: "#dddddd"
    },
    clearSignatureButton:{
        width: '130px',
        borderRadius: "3px",
        backgroundColor: "#ebebeb",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: "#dddddd",
        fontSize: '11px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: PRIMARY_COLOR,
        margin:'6px'
    },
    errorMessage : {
        fontSize: '13px',
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: '16px',
        letterSpacing: 0,
        color: "#dc0707",
        paddingBottom: '20px'
    },
    relativeErrorMessage : {
        position: 'relative',
        bottom: '-5px',
        fontSize: '13px',
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: '16px',
        letterSpacing: 0,
        color: "#dc0707"
    },
    topPadding: {
        paddingTop: '5px'
    },
    bottomPaddingHandheld: {
        paddingBottom: '20px'
    },
    durationPadding: {
        padding: '5px 0px 20px 0px'
    },
    canvaPadding: {
        padding: '10px 0px 20px 0px'
    }
}));

const NO_SIGNATURE_BUT_ACTIVITY_VERIFIED_MESSAGE = 'Please capture a signature or select "No" to the verification of services and resubmit.';
const NO_SIGNATURE_BUT_DURATION_VERIFIED_MESSAGE = 'Please capture a signature or select "No" to the verification of duration and resubmit.';

export default function VisitVerificationDialog({visit, reasonCodes, onClose, onhandlePrevious, setupSignaturePadTest, call, selectedTab}){
    const [showConfirmVisitDialog, setShowConfirmVisitDialog] = useState(false);
    const [showCancelVisitDialog, setShowCancelVisitDialog] = useState(false);
    const [showVerificationReasonDialog, setShowVerificationReasonDialog] = useState(false);
    const [showSignatureMissingDialog, setShowSignatureMissingDialog] = useState(false);
    const [showAddressDistanceDialog, setShowAddressDistanceDialog] = useState(false);
    const [canvasDimensions, setCanvasDimensions] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [appointmentStatus, setAppointmentStatus] = useState(visit.appointmentStatus || visit?.appointment?.status);
    const [currentVisit, setCurrentVisit] = useState(visit);
    const [activityVerified, setActivityVerified] = useState(currentVisit.activityVerified !== undefined ? currentVisit.activityVerified : null);
    const [durationVerified, setDurationVerified] = useState(currentVisit.durationVerified !== undefined ? currentVisit.durationVerified : null);
    const [saveAttempted, setSaveAttempted] = useState(false);
    const [signed, setSigned] = useState(false);
    const [open, setOpen] = useState(true);
    const styles = useStyles();
    const user = useSelector(appCache.getUser);
    const [firstRender, setFirstRender] = useState(true);
    const isHandheld = useSelector(appCache.isHandheld);
    const [distanceMessage, setDistanceMessage] = useState(DISTANCE_MESSAGE_DEFAULT);
    const organization = currentVisit?.appointment?.organization;

    const signatureMissingError = useRef();
    const signaturePadCanvas = useRef();
    const [alertDialogConfig, setAlertDialogConfig] = useState(null);
    const [openAlertDialog, setOpenAlertDialog] = useState(true);
    const [openResumeAlertDialog, setOpenResumeAlertDialog] = useState(true);
    const [resumeAlertDialog, setResumeAlertDialog] = useState(null);
    const appointments = useSelector(syncService.appointmentService.getResults());
    const staff = user?.staff;
    const [inSessionVisits, setInSessionVisits] = useState([]);
    const serviceDocTab = cloneDeep(useSelector(documentCache.getServiceDocumentsForTabs));
    const selectedDocTabGlobal = useSelector(documentCache.getSelectedDocTabGlobal);
    let activeTab = {}; 
    if(serviceDocTab && selectedDocTabGlobal) {
        activeTab = serviceDocTab[selectedDocTabGlobal];
    }
    const history = useHistory();
    const dispatch = useDispatch();
    const attachedDocument = useSelector(documentCache.getAttachedDocuments);
    const [maxAlertDialogConfig, setMaxAlertDialogConfig] = useState(null);

    useEffect(() => {
        getInSessionVisits(setInSessionVisits);
    }, []);

    useEffect(() => {
        if (setupSignaturePadTest){
            setupSignaturePadTest(setupSignaturePad);
        }
        /* eslint-disable react-hooks/exhaustive-deps */
    }, []);

    useEffect(() => {
        if(firstRender) {
            syncService.auditService.saveAuditEvent(null, currentVisit.client, user, "EVV Visit Verification", "View");
            setFirstRender(false);
        }
    }, [firstRender, currentVisit, user]);

    useEffect(() => {
        hasAttachedDocument(visit?.appointment, dispatch);
    }, [visit?.appointment, attachedDocument]);


    const sizeCanvasToParent = (canvas) => {
        const parent = canvas.parentNode;

        if (!parent) {
            return;
        }

        const width = canvasDimensions ? canvasDimensions.width : parent.clientWidth;
        const height = canvasDimensions ? canvasDimensions.height : parent.clientHeight - 16;

        if (canvas.width !== width || canvas.height !== height) {
            canvas.width = width;
            canvas.height = height;
            canvas.style.display = 'block';

            setCanvasDimensions({width, height});
        }
    }

    const handleMaxAlertDialogClose = () => {
        setResumeAlertDialog(null);
        setMaxAlertDialogConfig(null);
    }

    const setupSignaturePad = (sp) => {
        signaturePadCanvas.current = sp;
        if (sp) {
            sizeCanvasToParent(sp.getCanvas());
            if(currentVisit.signature && signaturePadCanvas.current && !signed && !saveAttempted) {
                signaturePadCanvas.current.fromDataURL(currentVisit.signature, {ratio: 1});
                setSigned(true);
            }
        }
    }

    useEffect(() => {
        if (signatureMissingError.current && signatureMissingError.current.scrollIntoView) {
            signatureMissingError.current.scrollIntoView(false);
        }
    }, [activityVerified, durationVerified, saveAttempted, signed]);

    const handleVerifyActivityChange = (onFlag) => {
        setActivityVerified(onFlag);
        currentVisit.activityVerified = onFlag;
    }

    const handleVerifyDurationChange = (onFlag) => {
        setDurationVerified(onFlag);
        currentVisit.durationVerified = onFlag;
    }

    const clearSignaturePad = () => {
        signaturePadCanvas.current.clear();
        currentVisit.signature = null;
        setSigned(false);
    }

    const saveSignature = () => {
        return signaturePadCanvas.current.getCanvas().toDataURL("image/png");
    };

    const isAddressOutOfRange = () => {
        let startVisitAddress = getStartAddressFromAppointment(currentVisit.appointment, currentVisit.client);
        let endVisitAddress = getEndAddressFromAppointment(currentVisit.appointment, currentVisit.client);
        let startAddress = getVisitAddress(startVisitAddress);
        let endAddress = getVisitAddress(endVisitAddress);
        let outOfRange = false;
                
        console.log('isAddressOutOfRange - startAddress: ');
        console.log(startAddress);
        if ((startAddress && startAddress.latitude && startAddress.longitude) && (endAddress && endAddress.latitude && endAddress.longitude)){
            console.log('isAddressOutOfRange - currentVisit: ');
            console.log(currentVisit);
            const startSession = {latitude: currentVisit.startLatitude, longitude: currentVisit.startLongitude};
            const endSession = {latitude: currentVisit.endLatitude, longitude: currentVisit.endLongitude};
            const startDistance = isValidCoordinate(startAddress) && isValidCoordinate(startSession) ? getDistanceInMiles(startAddress, startSession) : 1;
            const endDistance = isValidCoordinate(endAddress) && isValidCoordinate(endSession) ? getDistanceInMiles(endAddress, endSession) : 1;
            console.log('Distance in miles - start address -> start session: ' + startDistance);
            console.log('Distance in miles - start address -> end session: ' + endDistance);
            
            if (organization?.evvState === "New Jersey") {
                if((startDistance >= 0.0568182) || (endDistance >= 0.0568182)) {
                    setDistanceMessage(DISTANCE_MESSAGE_FOR_NEWJERSEY);
                    outOfRange = true;
                }
            } else {
                if (startDistance >= .25 || endDistance >= .25) {
                    outOfRange = true;
                }
            }
        } else {
            outOfRange = true;
        }

        return outOfRange;
    }

    const handleAlertDialogClose = async (okClicked) => {
        setAlertDialogConfig(null);
        if (okClicked && alertDialogConfig.dialogId !== ALERT_DIALOG_ERROR){
            if (shouldResumeDocumentation(call, activeTab, attachedDocument, selectedTab)) {
                await resumeDocumentation(setResumeAlertDialog);
                return;
            }
            closeVisitVerificationDialog(createVerifiedVisit());
            setOpenAlertDialog(false); 
        } else {
            setOpenAlertDialog(true);
        }
    }

    const handleResumeDialogClose = async(okClicked) => {
        setResumeAlertDialog(null);
        const savedDocument =  await syncService.documentService.findDocumentById(attachedDocument[0]?.id);
        if(okClicked){
            setShowConfirmVisitDialog(false);
            if (shouldResumeDocumentation(call, activeTab, attachedDocument, selectedTab)) {
                if (!checkIfDocExistsInTab(serviceDocTab, savedDocument) && hasMaxDocsOpened(serviceDocTab, visit?.appointment?.clientId)) {
                    setMaxAlertDialogConfig(MAX_OPEN_DOCUMENTS);
                } else {
                    closeVisitVerificationDialog(createVerifiedVisit());
                    setOpenResumeAlertDialog(false);
                    handleResumeDocument(attachedDocument, isHandheld, dispatch, history, serviceDocTab);
                }
            }
        } else {      
            closeVisitVerificationDialog(createVerifiedVisit());
            setOpenResumeAlertDialog(true);
        }
    }

    const handleSubmit = () => {
        setSaveAttempted(true);

        if (appointmentStatus.length === 0 || activityVerified === null || durationVerified === null) {
            return;
        }

        if (activityVerified === false || durationVerified === false){
            if (((reasonCodes && reasonCodes.length > 0) && !currentVisit.verificationDeclinedReason) || !currentVisit.verificationDeclinedComments) {
                setShowVerificationReasonDialog(true);
                return;
            }
        }

        if (signaturePadCanvas.current && signaturePadCanvas.current.isEmpty()) {
            if (((reasonCodes && reasonCodes.length > 0) && !currentVisit.signatureMissingReason) || !currentVisit.signatureMissingComments) {
                setShowSignatureMissingDialog(true);
                return;
            }
        }

        if (isAddressRequiredForOrganization(currentVisit?.appointment?.organization) && !EVV_STATE.includes(currentVisit?.appointment?.organization?.evvState)){
            if (isAddressOutOfRange()) {
                if (((reasonCodes && reasonCodes.length > 0) && !currentVisit.addressDistanceReason) || !currentVisit.addressDistanceComments) {
                    setShowAddressDistanceDialog(true);
                    return;
                }
            }
        }

        if(hasOverlappingVisitAppointments(currentVisit, appointments, setAlertDialogConfig, staff, inSessionVisits)){
            setSaveAttempted(false);
            setShowConfirmVisitDialog(false);
        } else {
            setShowConfirmVisitDialog(true);
        } 
    };

    const createVerifiedVisit = () => {
        const verifiedVisit = {...currentVisit};

        verifiedVisit.appointmentStatus = appointmentStatus;

        verifiedVisit.signature = signed ? saveSignature() : '';
        verifiedVisit.hasSignature = signed;

        verifiedVisit.timesVerified = durationVerified;
        verifiedVisit.servicesVerified = activityVerified;

        const gobalVisit = cloneDeep(verifiedVisit);
        dispatch(setGlobalVisit(gobalVisit));

        return verifiedVisit;
    }

    const closeVisitVerificationDialog = (verifiedVisit) => {
        setOpen(false);

        if (onClose){
            onClose(verifiedVisit);
        }
    }

    const handleConfirmVisitDialogClose = async(isOk) => {
        setShowConfirmVisitDialog(false);

        if (isOk) {
            if (shouldResumeDocumentation(call, activeTab, attachedDocument, selectedTab)) {
                await resumeDocumentation(setResumeAlertDialog);
                return;
            }
            closeVisitVerificationDialog(createVerifiedVisit());
        }
    }

    const handleCancelRequest = () => {
        setShowCancelVisitDialog(true);
    }

    const handlePrevious = () => {
        onhandlePrevious(currentVisit);
    }

    const handleCancelVisitDialogClose = (isOk) => {
        setShowCancelVisitDialog(false);

        if (isOk) {
            closeVisitVerificationDialog(null);
        }
    }

    const handleVerificationReasonDialogClose = (editedVisit) => {
        setShowVerificationReasonDialog(false);

        if (editedVisit){
            setCurrentVisit(editedVisit);
        }
    }

    const handleSignatureMissingDialogClose = (editedVisit) => {
        setShowSignatureMissingDialog(false);

        if (editedVisit){
            setCurrentVisit(editedVisit);
        }
    }

    const handleAddressDistanceDialogClose = (editedVisit) => {
        setShowAddressDistanceDialog(false);

        if (editedVisit){
            setCurrentVisit(editedVisit);
        }
    }

    const handleEndSigning = () => {
        currentVisit.signature = saveSignature();
        setSigned(true);
    }

    const renderSignaturePadErrorMessage = () => {
        if (saveAttempted && !signed){
            if (activityVerified){
                return (<div ref={signatureMissingError} className={styles.errorMessage} >{NO_SIGNATURE_BUT_ACTIVITY_VERIFIED_MESSAGE}</div>);
            } else if (durationVerified){
                return (<div ref={signatureMissingError} className={styles.errorMessage} >{NO_SIGNATURE_BUT_DURATION_VERIFIED_MESSAGE}</div>);
            }
        }

        return null;
    }

    const renderContent = () => {
        return (
            <><div className={styles.visitVerificationContent}>
                <div className={styles.visitInfo}>
                    <VisitVerificationCommonDetails
                        currentVisit={currentVisit} />
                </div>
                <StackedField mandatory={true} label='I verify that the activity performed during the visit is correct.' boldLabel={false} paddingTop={isHandheld ? "31px" : "41px"}>
                    <div className={styles.topPadding}>
                        <Switch id='activityVerified' onText='YES' offText='NO' nullable={true} value={activityVerified} onChange={handleVerifyActivityChange} />
                        {saveAttempted && activityVerified === null &&
                            <div className={styles.relativeErrorMessage}>Verification of service is a required field. Please make a selection and resubmit</div>}
                        {saveAttempted && activityVerified && !signed &&
                            <div className={styles.relativeErrorMessage}>{NO_SIGNATURE_BUT_ACTIVITY_VERIFIED_MESSAGE}</div>}
                    </div>
                </StackedField>
                <StackedField mandatory={true} label='I verify that the duration of the visit is correct.' boldLabel={false}>
                    <div className={styles.durationPadding}>
                        <Switch id='durationVerified' onText='YES' offText='NO' nullable={true} value={durationVerified} onChange={handleVerifyDurationChange} />
                        {saveAttempted && durationVerified === null &&
                            <div className={styles.relativeErrorMessage}>Verification of duration is a required field. Please make a selection and resubmit</div>}
                        {saveAttempted && durationVerified && !signed &&
                            <div className={styles.relativeErrorMessage}>{NO_SIGNATURE_BUT_DURATION_VERIFIED_MESSAGE}</div>}
                    </div>
                </StackedField>
                <div className={isHandheld? styles.bottomPaddingHandheld : styles.canvaPadding} >
                    <div className={styles.innerContainer}>
                        <div className={isHandheld ? styles.signaturePanelHandheld : styles.signaturePanel}>
                            <SignaturePad
                                ref={setupSignaturePad}
                                throttle={0}
                                onEnd={handleEndSigning}
                                clearOnResize={false}
                                canvasProps={{
                                    className: "signatureCanvas",
                                    style: { display: 'none' },
                                    width: '1px',
                                    height: '1px'
                                }} />
                            <button data-testid= "clearSignVV" className={styles.clearSignatureButton} onClick={clearSignaturePad}>CLEAR SIGNATURE</button>
                        </div>
                    </div>
                    {renderSignaturePadErrorMessage()}
                </div>
            </div>
            </>
        );
    }

    return (
        <div>
            <Dialog
                classes={{paper: styles.dialogPaper}}
                open={open}
                disableEscapeKeyDown={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle disableTypography={true} classes={{root: styles.dialogTitleVVD}} id="alert-dialog-title">Visit Verification - Signature</DialogTitle>
                <DialogContent classes={{root: isHandheld ? styles.dialogContentHandheld : styles.dialogContent}}>
                    {renderContent()}
                </DialogContent>
                <DialogActions classes={{root: isHandheld ? styles.okCancelButtonGroupHandheld : styles.okCancelButtonGroup}}>
                    <div className={styles.cancelButton}>
                        <EvvButton styleOverride={isHandheld ? {fontSize: '14px'} : {}} type='tertiary' onClick={handleCancelRequest} >Cancel</EvvButton>
                    </div>
                    <div className={styles.navigationButtons}>
                        <div className={isHandheld ? styles.okButtonMobile : styles.okButton}>
                            <EvvButton styleOverride={isHandheld ? {fontSize: '14px'} : {}} type="secondary" onClick={handlePrevious} >Previous</EvvButton>
                        </div>
                        <div className={isHandheld ? styles.okButtonMobile : styles.okButton}>
                            <EvvButton styleOverride={isHandheld ? {fontSize: '14px'} : {}} type='primary' onClick={handleSubmit} >Submit</EvvButton>
                        </div>
                    </div>
                </DialogActions>
            </Dialog>
            {showCancelVisitDialog &&
            <AlertDialog
                open={true}
                dialogTitle='Confirm Cancellation'
                dialogMessage='Are you sure you want to cancel? By canceling this, any changes will be deleted and the client session will remain active.'
                showOkButton={true}
                okButtonText='Yes'
                showCancelButton={true}
                cancelButtonText='No'
                handleClose={handleCancelVisitDialogClose}
            />
            }
            {showConfirmVisitDialog &&
            <AlertDialog
                open={true}
                dialogTitle='Confirmation'
                dialogMessage="Are you sure you want to save the client's Visit Verification?"
                showOkButton={true}
                okButtonText='Yes'
                showCancelButton={true}
                cancelButtonText='No'
                handleClose={handleConfirmVisitDialogClose}
            />
            }
            {showVerificationReasonDialog &&
            <VerificationReasonDialog
                open={true}
                visit={currentVisit}
                reasonCodes={reasonCodes}
                onClose={handleVerificationReasonDialogClose}
            />
            }
            {showSignatureMissingDialog &&
            <SignatureMissingDialog
                open={true}
                visit={currentVisit}
                reasonCodes={reasonCodes}
                onClose={handleSignatureMissingDialogClose}
            />
            }
            {showAddressDistanceDialog &&
            <AddressDistanceDialog
                open={true}
                visit={currentVisit}
                reasonCodes={reasonCodes}
                onClose={handleAddressDistanceDialogClose}
                message={distanceMessage}
            />
            }
            {alertDialogConfig && 
            <AlertDialog
                open={openAlertDialog}
                dialogTitle={alertDialogConfig.dialogTitle}
                dialogMessage={alertDialogConfig.dialogMessage}
                showOkButton={alertDialogConfig.showOkButton}
                showCancelButton={alertDialogConfig.showCancelButton}
                okButtonText={alertDialogConfig.okButtonText || 'Ok'}
                cancelButtonText={alertDialogConfig.cancelButtonText || 'Cancel123'}
                handleClose = {handleAlertDialogClose}
            />
            }
            {resumeAlertDialog &&
            <AlertDialog
                open={openResumeAlertDialog}
                dialogTitle={resumeAlertDialog.dialogTitle }
                dialogMessage={resumeAlertDialog.dialogMessage}
                showOkButton={resumeAlertDialog.showOkButton}
                showCancelButton={resumeAlertDialog.showCancelButton}
                okButtonText={resumeAlertDialog.okButtonText || 'Ok'}
                cancelButtonText={resumeAlertDialog.cancelButtonText || 'Cancel'}
                handleClose = {handleResumeDialogClose}
            />
            }
            {maxAlertDialogConfig &&
            <AlertDialog
                open={true}
                dialogTitle={maxAlertDialogConfig.dialogTitle}
                dialogMessage={maxAlertDialogConfig.dialogMessage}
                showOkButton={maxAlertDialogConfig.showOkButton}
                showCancelButton={maxAlertDialogConfig.showCancelButton}
                okButtonText={maxAlertDialogConfig.okButtonText ? maxAlertDialogConfig.okButtonText : 'Ok'}
                handleClose={handleMaxAlertDialogClose}
            />
            }
        </div>
    );
}
