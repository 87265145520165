import {useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from "@material-ui/core/styles";
import {DateTime, Interval} from "luxon";
import EvvButton from "../../../common/components/EvvButton";
import {formatSecondsToEnglish} from "../../../common/utils/formatUtils";

const useStyles = makeStyles(() => ({
    dialogTitle: {
        height: '42px',
        width: 'auto',
        fontSize: '16px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: "#ffffff",
        paddingLeft: '16px',
        display: 'flex',
        alignItems: 'center',
        textTransform: 'capitalize'
},
    dialogContent: {
        padding: "10px 16px",
    },
    dialogText: {
        fontSize: '16px',
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: "20px",
        letterSpacing: 0,
        color: "#747474"
    },
    okButtonGroup: {
        padding: "0px 16px 8px 16px",
    },
    okCancelButtonGroup: {
        padding: "0px 16px 8px 16px",
        justifyContent: 'space-between'
    },
    okButton: {
        padding: '0',
        minWidth: '125px'
    },
    cancelButton: {
        padding: '0'
    },
    label: {
        flexDirection: 'column',
        textTransform: "none",
        color: '#888888'
    }
}));

const calculateFormattedDuration = (startDateTime, resumeInterval) => {
    return formatSecondsToEnglish(resumeInterval - Math.floor(Interval.fromDateTimes(startDateTime, DateTime.now()).length('seconds')));
}

export default function InactivityWarningDialog({resumeInterval, startDateTime, onResume, onLogoff}){
    const [secondsUntilLogoff, setSecondsUntilLogoff] = useState(resumeInterval);
    const [formattedDurationUntilLogoff, setFormattedDurationUntilLogoff] = useState(calculateFormattedDuration(startDateTime, resumeInterval));
    const [open, setOpen] = useState(true);
    const styles = useStyles();

    const handleResume = () => {
        if (onResume){
            onResume();
        }else{
            setOpen(false);
        }
    };

    const updateDuration = () => {
        setSecondsUntilLogoff(resumeInterval - Math.floor(Interval.fromDateTimes(startDateTime, DateTime.now()).length('seconds')));
        setFormattedDurationUntilLogoff(calculateFormattedDuration(startDateTime, resumeInterval));
    }

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        let interval = null;
        if (secondsUntilLogoff > 0) {
            interval = setInterval(updateDuration, 500);
        } else {
            clearInterval(interval);
            if (onLogoff){
                onLogoff();
            }
        }
        return () => clearInterval(interval);
    }, [secondsUntilLogoff, startDateTime, onLogoff]);

    return (
        <div>
            <Dialog
                open={open}
                disableEscapeKeyDown={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle classes={{root: styles.dialogTitle}} id="alert-dialog-title">Inactivity Warning</DialogTitle>
                <DialogContent classes={{root: styles.dialogContent}}>
                    <DialogContentText classes={{root: styles.dialogText}} id="alert-dialog-description">
                        {`The session will log off in ${formattedDurationUntilLogoff} due to inactivity.`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions classes={{root: styles.okButtonGroup}}>
                    <div className={styles.okButton}>
                        <EvvButton type='primary' onClick={handleResume} >Resume</EvvButton>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
}
