import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import StackedField from '../../../common/components/StackedField';
import { appCache } from '../../../cache/slices/app/appSlice';
import { useSelector } from 'react-redux';
 
const UnwillingField = ({index, unwillingYesNo, handleChange, handleUnwillingClick}) => {
    const isHandheld = useSelector(appCache.isHandheld);

    const buttonStyle = (unwillingYesNo = 'No', value = 'No') => ({
        backgroundColor: unwillingYesNo === value ? '#3b276a' : '#ffffff',
        color: unwillingYesNo === value ? '#ffffff' : '#3b276a',
        width: isHandheld ? '45px' : '61px' ,
        height: isHandheld ? '35px' : '41px',
        textTransform: 'capitalize'
    });

    return (
        <>
        <StackedField label='Unwilling/Unable to Collect Signature?'>
            <ToggleButtonGroup
                id={`unwilling${index}`}
                value={unwillingYesNo?.[`unwilling${index}`] || ''}
                exclusive
                onChange={(event) => handleChange(event, `unwilling${index}`, index)}
                aria-label="Platform"
            >
                <ToggleButton
                    id={`unwillingYesBtn${index}`}
                    style={buttonStyle(unwillingYesNo?.[`unwilling${index}`], 'Yes')}
                    value="Yes"
                    onClick={() => handleUnwillingClick(index, true)}
                    disabled = {unwillingYesNo?.[`unwilling${index}`] === 'Yes' ? true :  false}
                >
                    Yes
                </ToggleButton>
                <ToggleButton
                    id={`unwillingNoBtn${index}`}
                    style={buttonStyle(unwillingYesNo?.[`unwilling${index}`], 'No')}
                    value="No"
                    onClick={() => handleUnwillingClick(index, false)}
                    disabled = {unwillingYesNo?.[`unwilling${index}`] === 'No' ? true :  false}
                >
                    No
                </ToggleButton>
            </ToggleButtonGroup>
        </StackedField>
        </>
    );
};
 
export default UnwillingField;