import {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import SortBar from "../../../common/components/SortBar";
import SortDrawer from "../../../common/components/SortDrawer";
import {sortWithDirection} from "../../../common/utils/miscUtils";
import {useDispatch, useSelector} from "react-redux";
import {clientCache} from "../../../cache/slices/client/clientSlice";
import MedicationCard from "../MedicationCard";
import CircularProgress from "@material-ui/core/CircularProgress";
import syncService from "../../../common/services/syncService";
import {appCache} from "../../../cache/slices/app/appSlice";
import { PRIMARY_COLOR } from "../../../common/constants"

const useStyles = makeStyles(() => ({
    medicationsPage: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column'
    },
    loadingSpinnerContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    loadingSpinner: {
        color: PRIMARY_COLOR
    },
    noMedications: {
        fontSize: "18px",
        fontWeight: "bold",
        fontStyle: "normal",
        lineHeight: "28px",
        letterSpacing: "-0.16",
        textAlign: "center",
        color: "#000000",
        paddingTop: '53px'
    }
}));

const columnDescriptors = [
    {
        name: 'status',
        title: 'Status'
    },
    {
        name: 'medication',
        title: 'Name'
    }
];

export default function MedicationsPage() {
    const client = useSelector(clientCache.getSelectedClient);
    const medications = useSelector(syncService.medicationService.getResults());
    const isLoading = useSelector(syncService.medicationService.isLoading());
    const sortDescriptor = useSelector(clientCache.getMedicationSort);
    const [sortedMedications, setSortedMedications] = useState([]);
    const [drawerOpened, setDrawerOpened] = useState(false);
    const dispatch = useDispatch();
    const styles = useStyles();
    const user = useSelector(appCache.getUser);

    const sortedColumn = sortDescriptor ? sortDescriptor.sortDescriptor : columnDescriptors[0];
    const sortDirection = sortDescriptor ? sortDescriptor.sortDirection : 'asc';

    useEffect(() => {
        if(medications && medications.length > 0) {
            syncService.auditService.saveAuditEvent(null, client, user, "EVV Client Medications", "View");
        }
    }, [medications, client, user]);

    useEffect(() => {
        const sortPropertyNames = [sortedColumn.name];
        const sortDirections = [sortDirection];

        if (sortedColumn.name !== 'medication'){
            sortPropertyNames.push('medication');
            sortDirections.push('asc');
        }

        setSortedMedications(sortWithDirection(
            medications,
            sortPropertyNames,
            sortDirections
        ));

    }, [sortedColumn, sortDirection, medications]);

    const handleSortRequest = () => {
        toggleDrawer();
    }

    const handleSortBy = (columnDescriptor) => {
        let newSortDirection = 'asc';

        if (columnDescriptor.name === sortedColumn.name){
            if (sortDirection === 'asc'){
                newSortDirection = 'desc';
            }
        } else {
            if (columnDescriptor.sortDirection){
                newSortDirection = columnDescriptor.sortDirection
            }
        }

        setDrawerOpened(false);

        dispatch(clientCache.sortMedications({
            sortDescriptor: columnDescriptor,
            sortDirection: newSortDirection
        }));
    }

    const toggleDrawer = () => {
        setDrawerOpened(!drawerOpened);
    }

    const renderPage = () => {
        if (isLoading){
            return (
                <div className={styles.loadingSpinnerContainer}>
                    <CircularProgress size={50} className={styles.loadingSpinner} />
                </div>
            );
        } else {
            return renderPageContent();
        }
    }

    const renderPageContent = () => {
        if (sortedMedications && sortedMedications.length > 0){
            return (
                <>
                    <SortBar columnDescriptors={columnDescriptors}
                             sortedColumn={sortedColumn}
                             handleSortRequest={handleSortRequest}/>
                    {
                        sortedMedications.map((medication, index) => <MedicationCard key={`${medication.medicationId}_${index}`} client={client} medication={medication} />)
                    }
                    <SortDrawer
                        anchor='bottom'
                        open={drawerOpened}
                        columnDescriptors={columnDescriptors}
                        showCancel={true}
                        handleCancel={toggleDrawer}
                        handleSortBy={handleSortBy}
                    />
                </>
            );
        } else {
            return (<div className={styles.noMedications} >No Medications Found</div>)
        }
    }

    return (
        <div className={styles.medicationsPage}>
            {renderPage()}
        </div>
    );
}
