import {Calendar, Views} from "react-big-calendar";
import React, { useCallback } from "react";
import {useSelector} from "react-redux";
import {scheduleCache} from "../../../cache/slices/schedule/scheduleCache";
import AppointmentEvent from "../AppointmentEvent";
import {DateTime} from "luxon";
import {MILITARY_TIME_FORMAT} from "../../../common/utils/formatUtils";
import {appCache} from "../../../cache/slices/app/appSlice";
import syncService from "../../../common/services/syncService";
import { isNonMobileActivity } from "../../../common/utils/appointmentUtils";
import { ASYNC_OPERATION_STATUS } from "../../../cache/asyncHandler";

const MAX_EVENTS_TO_SHOW_HANDHELD = 4;
const MAX_EVENTS_TO_SHOW_TABLET = 8;

export default function DayScheduler({ localizer, events, props}){
    const selectedDate = useSelector(scheduleCache.getSelectedDate);
    const selectedDateJS = selectedDate.toJSDate();
    const isHandheld = useSelector(appCache.isHandheld)
    const staffSignatures = useSelector(syncService.staffSignatureService.getResults());
    const activities = useSelector(syncService.activityService.getResults());
    const activityServiceStatus = useSelector(syncService.activityService.getStatus());

    function convertTo24HourFormat(timeString) { 
        const [time, period] = timeString.split(' '); 
        const [hour, minute] = time.split(':'); 
        let formattedHour = parseInt(hour); 
      
        if (period === 'PM') { 
            formattedHour += 12; 
        }
      
        return `${formattedHour.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false})}:${minute.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false})}`; 
    } 

    const getSlotProp = (date) => {
        const slotDate = DateTime.fromJSDate(date);
        let day = slotDate.weekday + 1;
        if(day === 8) {
            day = 1;
        }
        let staffShift = staffSignatures[0]?.staffShifts.find((shifts) => shifts.days.includes(day))

        const startSlotDate = slotDate.startOf('day');
        const startShiftTime = DateTime.fromFormat(staffShift?.startTime ? convertTo24HourFormat(staffShift?.startTime) : "00:00", MILITARY_TIME_FORMAT);
        const endShiftTime = DateTime.fromFormat(staffShift?.endTime ? convertTo24HourFormat(staffShift.endTime) : "00:00", MILITARY_TIME_FORMAT);
        const startShiftDate = startSlotDate.plus({hours: startShiftTime.hour, minutes: startShiftTime.minute});
        const endShiftDate = startSlotDate.plus({hours: endShiftTime.hour, minutes: endShiftTime.minute});

        if (slotDate < startShiftDate || slotDate >= endShiftDate){
            return {className: 'rbc-off-shift'};
        }else {
            return {};
        }
    }

    const eventPropGetter = useCallback(
        (event, start, end, isSelected) => {
            const greyBlock = ['Error', 'DNS', 'CBC', 'CBT'];
            let style = {
                borderColor: '#006CA7',
                borderLeft: '4px solid #006CA7',
                color: '#ffffff'
            }
            if (activityServiceStatus === ASYNC_OPERATION_STATUS.SUCCESS) {
                if (greyBlock.includes(event.status)) {
                    style.borderColor = '#787878';
                    style.borderLeft = '4px solid #787878';
                    style.backgroundColor = '#A9A9A9';
                    style.color = '#ffffff';
                }
                if (isNonMobileActivity(event, activities)) {
                    style.borderColor = '#FAFAFA';
                    style.borderLeft = 'none';
                    style.backgroundImage = 'url("../images/Grey_hash.png")';
                    style.color = '#000000';
                }
            }

            return {
                style: style
            };
        },
        [activityServiceStatus, activities]
    )

    const CustomHeader = ({ label }) => {
        // Split the label into day and date
        const [date, day] = label.split(' ');
        return (
          <div className = "day">
             <div>{day} {parseInt(date, 10)}</div>
          </div>
        );
      };

    return (
        <div className= {props?.className ? props.className : ' '}>
            <Calendar
                step={15}
                timeslots={4}
                scrollToTime={props?.scrollToTime ? props.scrollToTime : selectedDateJS}
                toolbar={false}
                events={events}
                view={props?.view ? props.view : [Views.DAY]}
                date={props?.scrollToTime ? props.scrollToTime : selectedDateJS}
                // dayLayoutAlgorithm='no-overlap'
                onNavigate={() => console.log('navigating')}
                defaultDate={props?.defaultDate ? props.defaultDate : selectedDateJS}
                slotPropGetter={getSlotProp}
                showMultiDayTimes={true}
                formats={{timeGutterFormat: 'h a'}}
                maxEventsToShow={isHandheld ? MAX_EVENTS_TO_SHOW_HANDHELD : MAX_EVENTS_TO_SHOW_TABLET}
                components={{
                    event: AppointmentEvent,
                    week: props ? {
                        header: CustomHeader,
                    } : {},
                }}
                localizer={localizer}
                eventPropGetter = { eventPropGetter }
                className= {props?.className ? props.className : ' '}
            />
        </div>
    );
}
