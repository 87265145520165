import {makeStyles} from "@material-ui/core/styles";
import LoginTextField from "../../../common/components/LoginTextField";
import {Checkbox, Divider, FormControlLabel, TextField, DialogContent, Dialog, DialogActions, DialogTitle} from "@material-ui/core";
import {useSelector} from "react-redux";
import StackedField from "../../../common/components/StackedField";
import React, {useEffect, useRef, useState} from "react";
import SignaturePad from "react-signature-canvas";
import {clientCache} from "../../../cache/slices/client/clientSlice";
import './SignaturePage.css';
import Typography from "@material-ui/core/Typography";
import { appCache } from "../../../cache/slices/app/appSlice";
import '../ServiceDocumentWizard/ServiceDocumentWizard.css';
import "../ServiceDocName.css";
import { PRIMARY_COLOR, unwillingSignatureMessage } from "../../../common/constants";
import EvvButton from "../../../common/components/EvvButton";
import UnwillingField from "./UnwillingField";
import { disableSaveButton, extSignatureFun, updateSignatures } from "./SignaturePageUtil";
import { validateSignaturesPDF } from "../common/ValidateFields";

const useStyles = makeStyles(() => ({
    signaturePage: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        padding: '17px 14px'
    },
    externalSignatureBlockForTablet: {
        width: '100%',
        borderStyle: "solid", 
        borderWidth: '1px',
        borderColor: '#c5c7ca',
        color: '#c5c7ca', 
        padding: '16px', 
        marginTop: '16px',
        marginBottom: '16px',
        borderRadius: '5px'
    },
    externalSignatureBlockForMobile: {
        width: '100%',
        color: '#c5c7ca', 
        marginTop: '16px',
        marginBottom: '16px',
        borderRadius: '5px'
    },
    checkBoxContainer:{
        height: '60px',
        paddingTop:'10px',
    },
    signaturePanel:{
        minHeight: '160px',
        maxHeight: '160px',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'space-between',
        padding:'10px 20px 0px 20px'
    },
    innerContainer:{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'space-between',
        borderRadius: "3px",
        backgroundColor: "#f6f6f6",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: "#dddddd",
    },
    signatureCanvas:{
        margin: '10px',
        borderRadius: "3px",
        backgroundColor: "#f6f6f6",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: "#dddddd",
        height:'100px',
        width:'100px',
    },
    clearSignatureButton:{
        width: '130px',
        borderRadius: "3px",
        backgroundColor: "#ebebeb",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: "#dddddd",
        fontSize: '11px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: PRIMARY_COLOR,
        margin:'6px',
        display: 'block'
    },
    arial:{
        fontSize: '16px',
        color: "#4f4f4f"
    },
    widthSignaturePage: {
        width: "100%"
    },
    hide: {
        display: 'none'
    },
    okCancelButtonGroup: {
        width: '100%'
    },
    yesNobuttonForMobile: {
        width: '50%'
    },
    yesNobuttonForTablet: {
        width: '20%'
    },
    signature: {
        fontSize: '20px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: '#000000'
    },
    errorMessage : {
        position: 'relative',
        fontSize: '13px',
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: '16px',
        letterSpacing: 0,
        color: "#dc0707"
    }
})
)

function SignaturePageWithPdf({serviceDocument, hide, renderFormName, setupSignaturePadTest, documentController, setSaveDisable, errors, setErrors}){
    const [signatureFormData, setSignatureFormData] = useState({});
    const styles = useStyles();
    const client = useSelector(clientCache.getSelectedClient);
    const isHandheld = useSelector(appCache.isHandheld);
    const user = documentController?.dataProvider?.getUser();
    const signaturePadCanvas = useRef([]);
    const [confirmationAlert, setConfirmationAlert] = useState({});
    const [unwillingSubmit, setUnwillingSubmit] = useState({});
    const [unwillingYesNo, setUnwillingYesNo] = useState({});

    let externalSignArray = [];
    let noOfSignaturePad = serviceDocument?.docSignaturePadList?.length;
    const widthOfSignatureField = isHandheld ? {width:'100%'}: {width: '50%'};
    const call = "add";
    let externalSignatures = serviceDocument?.signature?.externalSignatures;

    useEffect(() => {
        if (setupSignaturePadTest){
            setupSignaturePadTest(setupSignaturePad);
        }
        /* eslint-disable react-hooks/exhaustive-deps */
    }, []);

    useEffect(() => {
        if (externalSignatures) {
            setTimeout(() => {
                signaturePadCanvas.current.forEach((canvas, index) => {
                    if (canvas && canvas.fromDataURL && externalSignatures[index-1] && externalSignatures[index-1]?.signature) {
                        canvas.fromDataURL(`data:image/png;base64,${externalSignatures[index-1]?.signature}`, {ratio: 1});
                    }
                })
            },500)
        }
    })

    const sizeCanvasToParent = (canvas) => {
        const parent = canvas.parentNode;

        if (!parent) {
            return;
        }

        const width = parent.clientWidth;
        const height = parent.clientHeight;  

        if (canvas.width !== width || canvas.height !== height) {
            canvas.width = width;
            canvas.height = height;
            canvas.style.display = 'block';
        }
    }

    const setupSignaturePad = (sp, index) => {
        index = index === undefined ? 1 : index ;
        if (sp) {
            signaturePadCanvas.current[index] = sp;
        }

        if (sp) {
            sizeCanvasToParent(sp.getCanvas());
        }
    }

    const handleOnChange = (event, fieldName, index, isCheckBox) => {
        const formStateData = {...signatureFormData};
        const value = isCheckBox ? event?.target?.checked : event?.target?.value;
        const alignmentCopy = {...unwillingYesNo};

        formStateData[fieldName] = value;
  
        if (fieldName === `extSignNameCheckBox${index}` && !value) {
            formStateData[`externalName${index}`] = '';
        } else if(isCheckBox){
            formStateData[`externalName${index}`] = `${client.lastName}, ${client.firstName} (Self)` 
        }

        if (fieldName === `unwilling${index}`) { 
            alignmentCopy[fieldName] = value;
            if (value === "No") {
                formStateData[`reason${index}`] = '';
            }
        }

        if (fieldName === `reason${index}`) { 
            formStateData[`reason${index}`] = value;
        }

        setUnwillingYesNo(alignmentCopy); 
        setSignatureFormData(formStateData);
        updateSignatures({serviceDocument, formData: formStateData, signaturePadCanvas, noOfSignaturePad, user, documentController, call, staffSignDate: extSignatureFun(), clientProgram: null, extSigNeededDoc: true});
        disableSaveButton(serviceDocument, setSaveDisable, noOfSignaturePad);
        validateSignaturesPDF(serviceDocument?.signature?.externalSignatures, setErrors);
    }
    
    const handleEndSigning = (fieldName) => {
        const formStateData = {...signatureFormData};
        formStateData[fieldName] = true;
        setSignatureFormData(formStateData);
        updateSignatures({serviceDocument, formData: formStateData, signaturePadCanvas, noOfSignaturePad, user, documentController, call, staffSignDate: extSignatureFun(), clientProgram: null, extSigNeededDoc: true});
        disableSaveButton(serviceDocument, setSaveDisable, noOfSignaturePad);
        validateSignaturesPDF(serviceDocument?.signature?.externalSignatures, setErrors);
    }

    const clearSignaturePad = (index) => {
        signaturePadCanvas.current[index].clear();
        let updatedSignatureFormData = { ...signatureFormData };
        updatedSignatureFormData[`sign${index}`] = false;
        setSignatureFormData(updatedSignatureFormData);
        updateSignatures({serviceDocument, formData: updatedSignatureFormData, signaturePadCanvas, noOfSignaturePad, user, documentController, call: "clear", staffSignDate: extSignatureFun(), signPadIndex: index, externalSignatures: serviceDocument?.signature?.externalSignatures, clientProgram: null, extSigNeededDoc: true});
        disableSaveButton(serviceDocument, setSaveDisable, noOfSignaturePad);
        validateSignaturesPDF(serviceDocument?.signature?.externalSignatures, setErrors);
    }

    const externalSignaturePad = (index) => {
        externalSignArray.push(
            <div className= {isHandheld ? styles.externalSignatureBlockForMobile : styles.externalSignatureBlockForTablet} key={index} > 
                <div className = {styles.signature} style = {isHandheld ? {paddingBottom: '12px'} : {}}>{`Signature ${index}`}</div>
                <div>{<UnwillingField index ={index} unwillingYesNo={unwillingYesNo} handleChange={handleOnChange} handleUnwillingClick={handleUnwillingClick}/>}</div>
                {unwillingSubmit?.[`unwillingSubmit${index}`] === true && unwillingYesNo?.[`unwilling${index}`] === 'Yes'  && <div>
                    <StackedField mandatory={true} label='Please provide a reason.' paddingTop="22px" >
                        <TextField
                            id={`reason${index}`}
                            multiline={true}
                            minRows={5}
                            focused={false}
                            placeholder = { "Please provide a reason" }
                            onChange={(event) => handleOnChange(event, `reason${index}`, index)}
                            variant="outlined"
                            fullWidth={true}
                            inputProps={{
                                maxLength: 4000
                            }}
                            style = {widthOfSignatureField}
                        />
                        <div style = {{color: '#696b6d'}}>Maximum 4000 characters</div>
                        {errors?.unableReasons[index - 1] && <div className={styles.errorMessage}>{`Please provide a reason ${errors?.unableReasons[index - 1]}`}</div>}
                    </StackedField>
                </div>}
                {(unwillingYesNo?.[`unwilling${index}`] === 'No' || unwillingYesNo?.[`unwilling${index}`] === undefined) && 
                <><StackedField mandatory={true} label='External Signature Name' className = {styles.yesNobuttonForMobile}>
                    <div style = {widthOfSignatureField}>
                        <LoginTextField
                            id={`name${index}`}
                            onChange={(event) => handleOnChange(event, `externalName${index}`, index)}
                            value={signatureFormData?.[`externalName${index}`] || ''}
                            maxCharacters={50}
                            placeholder = { "External Name" }          
                        />
                    </div>
                    {errors?.names[index - 1] && <div className={styles.errorMessage}>{`External Signature Name ${errors?.names[index - 1]}`}</div>}
                </StackedField>
                <div className={styles.checkBoxContainer}>
                    <FormControlLabel 
                            label={<Typography className={styles.arial}>External Signature Name Same as Client</Typography>}
                            control={
                                <Checkbox
                                    id={`checkBoxIndex${index}`}
                                    checked={!!signatureFormData?.[`extSignNameCheckBox${index}`]}
                                    onChange={(event) => handleOnChange(event, `extSignNameCheckBox${index}`, index, true)}
                                    color='primary' 
                                />
                            }
                        />
                </div> 
                <StackedField mandatory={true} label='External Signature' paddingTop={"10px"} >
                    <div className={styles.innerContainer} style = {widthOfSignatureField}>
                        <div className={styles.signaturePanel} >
                            <SignaturePad
                                id={`sign${index}`}
                                ref={(ref) => setupSignaturePad(ref, index)}
                                throttle={0}
                                onEnd = {(ref) => handleEndSigning(`sign${index}`)}
                                clearOnResize={false}
                                canvasProps={{
                                className: "signatureCanvas",
                                style:{display: 'none'},
                                width: '1px',
                                height: '1px'
                            }}
                            />
                        </div>
                        <button className={styles.clearSignatureButton} onClick={() => clearSignaturePad(index)}>CLEAR SIGNATURE</button>
                     </div>
                     {errors?.signatures[index - 1] && <div className={styles.errorMessage}>{`External Signature ${errors?.signatures[index - 1]}`}</div>}
                </StackedField>
                </>
                }
                {confirmationAlert?.[`unwillingAlert${index}`] === true && 
                <Dialog
                    classes={{paper: styles.dialogPaper}}
                    open={true}
                    disableEscapeKeyDown={true}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle classes={{root: styles.dialogTitle}} style={{color: "#ffffff"}} id="alert-dialog-title">Confirm Selection</DialogTitle>
                    <DialogContent classes={{root: styles.dialogContent}}>
                            {unwillingSignatureMessage}
                    </DialogContent>
                    <DialogActions classes={{root: styles.okCancelButtonGroup}}>
                        <div className={isHandheld ? styles.yesNobuttonForMobile : styles.yesNobuttonForTablet}>
                            <EvvButton type='secondary' id = {`unwilligSubmitNo${index}`} value = {unwillingSubmit?.[`unwilligSubmit${index}`] || ''} onClick = {() => {handleUnwillingYesNo(index, 'No')}}>No</EvvButton>
                        </div>
                        <div className={isHandheld ? styles.yesNobuttonForMobile : styles.yesNobuttonForTablet}>
                            <EvvButton type='primary' id = {`unwillingSubmitYes${index}`} value = {unwillingSubmit?.[`unwilligSubmit${index}`] || ''} onClick = {() => {handleUnwillingYesNo(index, 'Yes')}}>Yes</EvvButton>
                        </div>
                    </DialogActions>
                </Dialog>
                }
                {isHandheld ? <div style = {{paddingTop: '30px',paddingBottom: '16px'}}><Divider/></div>: ''}
            </div>
        )
    }

    const renderSignaturePad = () => {
        for (let index = 1; index <= noOfSignaturePad; index++) {
            externalSignaturePad(index);
        }
        return externalSignArray;
    }

    const renderSignatureForm = () => {
        return (
            <div data-testid='SignaturePage_EditForm'>
                {renderSignaturePad()}
            </div>
        );
    };

    const handleUnwillingClick = (index, value) => {
        const alertCopy = {...confirmationAlert}; 
        alertCopy[`unwillingAlert${index}`] = value;
        setConfirmationAlert(alertCopy);
    }

    const handleUnwillingYesNo = (index, value) => {
        const submitCopy = {...unwillingSubmit}; 
        const unwillingCopy = {...unwillingYesNo}; 
        const alertCopy = {...confirmationAlert}; 
        const formStateData = {...signatureFormData};

        alertCopy[`unwillingAlert${index}`] = false;
        unwillingCopy[`unwilling${index}`] = value;

        formStateData[`unwilling${index}`] = value;
        setSignatureFormData(formStateData);
        
        if(value === 'Yes'){ 
            submitCopy[`unwillingSubmit${index}`] = true;
        } else if( value === 'No'){
            submitCopy[`unwillingSubmit${index}`] = false;
        }

        setUnwillingYesNo(unwillingCopy);
        setUnwillingSubmit(submitCopy);
        setConfirmationAlert(alertCopy);
        updateSignatures({serviceDocument, formData: formStateData, signaturePadCanvas, noOfSignaturePad, user, documentController, call, staffSignDate: extSignatureFun(), clientProgram: null, extSigNeededDoc: true});
        disableSaveButton(serviceDocument, setSaveDisable, noOfSignaturePad);
        validateSignaturesPDF(serviceDocument?.signature?.externalSignatures, setErrors);
    }

    return (
        <div id='box' data-testid='signaturePage' className={styles.signaturePage + ' ' + (hide ? styles.hide : '')}>
            <div className = "serviceDocName">{ renderFormName() }</div><Divider />
            <div className={styles.widthSignaturePage}>
                {renderSignatureForm()}
            </div>
        </div>
    );
}

export default SignaturePageWithPdf;