import useStyles from "../../../common/stylesCarelogicMobile";
import EvvButton from "../../../common/components/EvvButton";
import CarelogicMobileLogo from "../../../assets/images/carelogic-mobile-logo.png";
import { getDevicePlatformType } from "../../../common/utils/systemUtils";
import * as rdd from "react-device-detect";
import { useAuth } from "react-oidc-context";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { appCache } from "../../../cache/slices/app/appSlice";

export default function LoginPagePingOneError() {
    const location = useLocation();
    const { typeError } = location.state || {};

    const MOBILE_PLATFORM = "Mobile";
    const isHandheld = (getDevicePlatformType(rdd) === MOBILE_PLATFORM);
    const containerStyleHandheld = isHandheld ? { height: "643px", width: "375px", background: "none", boxShadow: "none" } : {};
    const headerStyleHandheld = isHandheld ? { paddingTop: "24px" } : {};
    const logoStyleHandheld = isHandheld ? { width: "190px", height: "50px" } : {};
    const styles = useStyles();
    const auth = useAuth();
    const dispatch = useDispatch();

    const errorMapping = {
        "access_denied": {
            errorTitle: "Privilege Required",
            errorMessage: "You do not have privileges to access CareLogic Mobile. Please contact your system administrator."
        }
    };

    const { errorTitle, errorMessage } = errorMapping[typeError] || {
        errorTitle: "Login Failed",
        errorMessage: "Login failed. Please try again."
    };

    const handleReturnToHome = () => {
        auth.signoutRedirect().then(() => {
            appCache.logout(dispatch);
        }).catch((error) => {
            console.error("Error during sign-out:", error);
        });
    };

    return (
        <div className={styles.tabletSetLoginPageError}>
            <div className={styles.loginPageErrorContainer} style={containerStyleHandheld}>
                <div className={styles.header} style={headerStyleHandheld}>
                    <img className={styles.logo} src={CarelogicMobileLogo} alt="Carelogic Mobile" style={logoStyleHandheld} />
                </div>
                <div className={styles.content}>
                    <div className={styles.text}>{errorTitle}</div>
                    <div className={styles.description}>{errorMessage}</div>
                    <div className={styles.spaceBelowButton}>
                        <EvvButton id="ReturnToHomeButton" type="primary" onClick={handleReturnToHome}>Return to Home</EvvButton>
                    </div>
                </div>
            </div>
        </div>
    )

}
