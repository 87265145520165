import CustomButton from "../../../common/components/Button";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {makeStyles} from "@material-ui/core/styles";
import EvvLogo from '../../../assets/images/CareLogic-Mobile.png';
import {useEffect, useState} from "react";
import {
    dateToStringWithFormat,
    formatClientName,
    formatOrganization,
    formatUserName
} from "../../../common/utils/formatUtils";
import SearchField from "../../../common/components/SearchField";
import {useHistory, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {clientCache} from "../../../cache/slices/client/clientSlice";
import {appCache} from "../../../cache/slices/app/appSlice";
import {scheduleCache} from "../../../cache/slices/schedule/scheduleCache";
import DateAccordion from "../DateAccordion";
import ScheduleHeader from "../../schedule/ScheduleHeader";
import ActiveSessionsDialog from "../../facesheet/ActiveSessionsDialog";
import CloudOffIcon from '@mui/icons-material/CloudOff';
import AlertDialog from "../../../common/components/AlertDialog";
import clientService from "../../../common/services/clientService";
import {evvRepository} from "../../../db/evv";
import api from "../../../common/services/api";
import syncService from "../../../common/services/syncService";
import {DateTime} from "luxon";
import FacesheetClientCard from "../../facesheet/FacesheetPage/FacesheetClientCard";
import Organizations from "../../../common/components/Organizations";
import EvvButton from "../../../common/components/EvvButton";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { PRIMARY_COLOR, QUATERNARY_COLOR } from "../../../common/constants";
import { useAuth } from "react-oidc-context";

const handheldHeaderContent = {
    width: '100%',
    // maxHeight: '136px',
    backgroundColor: '#f6f6f6',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#979797',
    padding: '24px 16px 0px 16px',
    marginBottom: '2px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.5)',
    alignItems: 'center'
};

const useStyles = makeStyles((theme) => ({
    tabletHeader: {
        width: '100%',
        height: '110px',
        backgroundColor: '#f2f2f2',
        padding: '25px 14px 24px 19px',
        display: 'grid',
        rowGap: '14px',
        gridTemplateColumns: '292px 1fr 0fr .5fr',
        alignItems: 'center'
    },
    handheldHeader: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    handheldHeaderContent: handheldHeaderContent,
    handheldHeaderExtendedContent: {
        ...handheldHeaderContent,
        boxShadow: 'unset',
        borderBottom: 'unset',
        marginBottom: 'unset'
    },
    handheldTitleRow: {
        width: '100%',
        display: 'flex',
        paddingBottom: '8px',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    navigationBackContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    navigationBackButton: {
        backgroundColor: 'inherit',
        borderWidth: 0,
    },
    pageTitle: {
        fontSize: '18px',
        fontWeight: 'bold',
        fontStyle: "normal",
        letterSpacing: 0,
        color: PRIMARY_COLOR
    },
    logoContainer: {
        display: 'flex'
    },
    logoImage: {
        width: '232px',
        height: '60px'
    },
    searchFieldContainer: {
        height: '38px',
        maxWidth: '300px'
    },
    searchFieldContainerForHandheld: {
        width: '100%',
        paddingBottom: '12px'
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    offlineIndicatorImage: {
        [theme.breakpoints.down('sm')]: {
            height: '18px',
        },
        [theme.breakpoints.up(601)]: {
            width: '42px',
            height: '34px'
        }
    },
    activeSessionsButton: {
        width: '170px',
        height: '32px',
        fontSize: '14px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        textAlign: "center",
        color: PRIMARY_COLOR,
        backgroundColor: '#ffffff',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderRadius: '5px',
        borderColor: PRIMARY_COLOR,
        marginLeft: '26px'
    },
    activeSessionsButtonForHandheld: {
            height: '22px',
            fontSize: '12px',
            fontWeight: 'bold',
            fontStyle: 'normal',
            letterSpacing: 0,
            textAlign: "center",
            color: PRIMARY_COLOR,
            backgroundColor: '#ffffff',
            borderStyle: 'solid',
            borderWidth: '1px',
            borderRadius: '5px',
            borderColor: PRIMARY_COLOR,
            marginLeft: '15px'
    },
    userInfoContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        paddingLeft:'30px'
    },
    userNameLabel: {
        fontWeight: "bold",
        color: "#4f4f4f"
    },
    organizationLabel: {
        maxWidth: '160px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'normal',
        fontSize: '14px',
        fontWeight: "normal",
        fontStyle: "normal",
        letterSpacing: 0,
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        color: QUATERNARY_COLOR,
        paddingTop: '9px'
    },
    dialogChildren: {
        display: 'flex',
        flexDirection: 'column'
    },
    dialogChild: {
        paddingBottom: '20px'
    },
    dialogText: {
        fontSize: '16px',
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: "20px",
        letterSpacing: 0,
        color: "#747474"
    },
    dialogTextBold: {
        fontWeight: 'bold'
    },
    dialogPaper: {
        width: '400px'
    },
    dialogTitle: {
        maxWidth: '660px',
        maxHeight: '800px',
        height: '42px',
        width: 'auto',
        fontSize: '16px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: "#ffffff",
        paddingLeft: '16px',
        display: 'flex',
        alignItems: 'center'
    },
    dialogContent: {
        padding: "19px 19px 25px 19px",
    },
    okCancelButtonGroup: {
        padding: "0px 16px 15px 16px",
        justifyContent: 'space-between'
    },
    okButton: {
        padding: '0',
        minWidth: '125px'
    },
    cancelButton: {
        padding: '0'
    },
}));

export const PAGE_HEADER = [
    '',
    'Documents',
    'Allergies',
    'Medications',
    'Diagnosis',
    'Diagnosis Details',
    'Medication Details',
    'Allergy Details',
    'Message Board',
    'Vitals',
    'Vitals Details'
]

export default function AppHeader({pageTitle, isChildPage, handheldSearchEnabled}) {
    const organizations = useSelector(syncService.organizationService.getResults());
    const caseloads = useSelector(syncService.caseloadService.getResults()) ;
    const [searchableClients, setSearchableClients] = useState([]);
    const [showActiveSessionsDialog, setShowActiveSessionsDialog] = useState(false);
    const [alertDialogConfig, setAlertDialogConfig] = useState(null);
    const globalDialogConfig = useSelector(appCache.getGlobalDialogConfig);
    const appointments = useSelector(syncService.appointmentService.getResults());
    const visits = useSelector(syncService.visitService.getResults());
    const hasActiveVisits = visits && visits.length > 0 && visits.find((v) => v.complete === 0);
    const selectedClient = useSelector(clientCache.getSelectedClient);
    const clients = useSelector(appCache.getClients);
    const user = useSelector(appCache.getUser);
    const calendarView = useSelector(scheduleCache.getCalendarView);
    const selectedDateForSchedule = useSelector(scheduleCache.getSelectedDate);
    const viewDateForSchedule = useSelector(scheduleCache.getViewDate);
    const isConnectionLost = useSelector(appCache.isOffline)
    const isHandheld = useSelector(appCache.isHandheld);
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const styles = useStyles();
    const [inputValue, setInputValue] = useState(formatClientName(selectedClient));
    const isOffline = api.isOfflineAtLogin() || isConnectionLost;
    const [showOrganizationChooser, setOrganizationChooser] = useState(false);
    const [selectableOrganizations, setSelectableOrganizations] = useState([]);
    const [selectedOrganization, setSelectedOrganization] = useState(null);
    const [availableOrganizations, setAvailableOrganizations] = useState([]);
    const staffSignatures = useSelector(syncService.staffSignatureService.getResults());
    const [currentOrganization, setCurrentOrganization] = useState(null);
    const call = 'AppHeader';
    const organizationServiceStatus = useSelector(syncService.organizationService.getStatus());
    const auth = useAuth();
    let hostname = window.location.hostname;

    useEffect(() => {
        if (hostname.startsWith("mobile")) {
            if (auth?.isAuthenticated && auth?.user?.profile) {
                const accessToken = auth.user.access_token;
                const refreshToken = auth.user.refresh_token;
                const expiresAt = auth.user.expires_at;

                if (accessToken && refreshToken && expiresAt) {
                    evvRepository.accessToken = accessToken;
                    evvRepository.refreshToken = refreshToken;
                    evvRepository.expiresAt = expiresAt;
                    evvRepository.writingProcess = "false";
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth?.isAuthenticated, auth?.user]);

    useEffect(() => {
        if(staffSignatures.length > 0 && organizationServiceStatus === 2) {
            const primaryOrganizationId = user.primaryOrganizationId;
            const staffId = user?.staffId;
            const filteredStaffSignatures = staffSignatures.filter(ss => ss.staffId === staffId);
            const organizations = syncService.organizationService.getAvailableOrganizations(primaryOrganizationId, filteredStaffSignatures);
            setCurrentOrganization(organizations ? organizations.find(o => o.organizationId === user.currentOrganizationId) : null);
            setAvailableOrganizations(organizations.filter(o => o.evv === true));
            setSelectableOrganizations(organizations.filter(o => o.evv === true));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.primaryOrganizationId, staffSignatures, user.currentOrganizationId, organizationServiceStatus]);

    useEffect(() => {
        setSearchableClients(clientService.getSearchableClients(caseloads, appointments, clients));
    }, [caseloads, appointments, clients]);

    useEffect(() => {
        if ((history.location.pathname.split('/').length === 2 && selectedClient != null)){
            setInputValue('');
            if((history.location.pathname !== "/schedule") && (history.location.pathname !== "/dashboard")) {
                dispatch(clientCache.selectClient(null));
            }
        } else {
            setInputValue(formatClientName(selectedClient));
        }
        return () =>{
            handleActiveSessionsDialogClose()
        }
    }, [pageTitle, history.location.pathname, selectedClient, dispatch]);

    useEffect(() => {
        dispatch(appCache.toggleHandheldFlag(isHandheld));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isHandheld]);

    const handleClientChange = (evt, value, reason) => {
        if (value) {
            clientService.visitClient(value, DateTime.now().toMillis());
            dispatch(clientCache.selectClient(value));
            history.push('/caseload/facesheet');
        }
    };

    const handleBack = () => {
        const locationPaths = location.pathname.split('/');

        locationPaths.pop();

        history.push(locationPaths.join("/"));
    };

    const handleScheduleDateClick = (isCurrentlyExpanded) => {
        dispatch(scheduleCache.setCalendarView(calendarView === 'week' ? 'month' : 'week'));
    }

    const isSchedulePage = () => {
        return pageTitle === 'Schedule';
    }

    const isActiveSessionsPage = () => {
        return pageTitle === 'Active Sessions';
    }

    const renderTitle = () => {
        if (isSchedulePage()){
            const dateTitle = dateToStringWithFormat(viewDateForSchedule, 'MMMM yyyy');
            return (<DateAccordion title={dateTitle} initialExpanded={false} onChange={handleScheduleDateClick} />);
        } else {
            return <div className={styles.pageTitle} >{pageTitle}</div>
        }
    }

    const showActiveSessionsOnHandheld = () => {
        history.push(location.pathname + '/activeSessions');
    }

    const showActiveSessionsOnTablet = (event) => {
        setShowActiveSessionsDialog(true)
    }

    const handleActiveSessionsDialogClose = () => {
        setShowActiveSessionsDialog(false)
    }

    const handleOpenAlertDialog = () => {
        const token = evvRepository.accessToken;

        if (token && token.length > 0){
            setAlertDialogConfig({
                dialogTitle: 'Offline mode',
                dialogMessage: 'You are currently offline. Please check your network connection.',
                renderChildren: null,
                children: null,
                showOkButton: true,
                showCancelButton: false
            });
        } else {
            setAlertDialogConfig({
                dialogTitle: 'Offline mode',
                dialogMessage: null,
                renderChildren: () => {
                  return (
                      <div className={styles.dialogChildren}>
                          <div className={styles.dialogText + ' ' + styles.dialogChild}>You logged in without connectivity. In order to sync your changes, please log back in once connected via WiFi or cellular data. <span className={styles.dialogTextBold}>Warning: any unsaved data will be lost.</span></div>
                          <div className={styles.dialogText + ' ' + styles.dialogChild}>Do you want to log in now?</div>
                      </div>
                  );
                },
                children: null,
                showOkButton: true,
                okButtonText: 'Continue offline',
                showCancelButton: true,
                cancelButtonText: 'Log In'
            });
        }
    }

    const handleCloseAlertDialog = (okClicked) => {
        setAlertDialogConfig(null);

        if (!okClicked){
            appCache.logout(dispatch);
        }
    }

    const handleCloseGlobalAlertDialog = (okClicked, customHandleClose) => {
        if (customHandleClose){
            customHandleClose(okClicked, dispatch);
        }

        dispatch(appCache.showGlobalAlertDialog(null));
        if (!okClicked) {
            appCache.logout(dispatch);
        }
    }

    const handleOnInputChange = (event) => {
        const clientNameText = event?.target?.value || '';
        setInputValue(clientNameText);
    }

    const renderSearchField = () => {
        return (
            <div className={ isHandheld ? styles.searchFieldContainerForHandheld : styles.searchFieldContainer} >
                <SearchField
                    id='clientSearchField'
                    placeholder="Search Client Name/ID"
                    inputValue={inputValue}
                    freeSolo={true}
                    options={searchableClients}
                    getOptionLabel={(client) => formatClientName(client)}
                    getOptionSelected={(option, value) => option.clientId === value.clientId}
                    onChange={handleClientChange}
                    onInputChange={handleOnInputChange}
                    defaultValue={selectedClient}
                    value={selectedClient}
                />
            </div>
        );
    }

    const renderButtonContainer = () => {
        return (
            <div className={styles.buttonContainer}>
                {isOffline &&
                    <CloudOffIcon className={styles.offlineIndicatorImage} onClick={handleOpenAlertDialog} htmlColor='#c41717'/>
                }
                {hasActiveVisits && !isActiveSessionsPage() &&
                    <button className={ isHandheld ? styles.activeSessionsButtonForHandheld : styles.activeSessionsButton} onClick={isHandheld ? showActiveSessionsOnHandheld : showActiveSessionsOnTablet}>Active Sessions</button>
                }
            </div>
        );
    }

    const renderAlertDialog = () => {
        if (alertDialogConfig) {
            return (
                <AlertDialog
                    open={true}
                    dialogTitle={alertDialogConfig.dialogTitle}
                    dialogMessage={alertDialogConfig.dialogMessage}
                    children={alertDialogConfig.children}
                    renderChildren={alertDialogConfig.renderChildren}
                    showOkButton={alertDialogConfig.showOkButton}
                    okButtonText={alertDialogConfig.okButtonText}
                    showCancelButton={alertDialogConfig.showCancelButton}
                    cancelButtonText={alertDialogConfig.cancelButtonText}
                    handleClose={handleCloseAlertDialog}
                />
            );
        }

        if (globalDialogConfig) {
            let finalGlobalDialogConfig = {...globalDialogConfig};
            if (globalDialogConfig.dialogId === "REFRESH_TOKEN_FAILED") {
                finalGlobalDialogConfig = {
                    ...globalDialogConfig,
                    dialogTitle: 'Offline mode',
                    dialogMessage: null,
                    renderChildren: () => {
                        return (
                            <div className={styles.dialogChildren}>
                                <div className={styles.dialogText + ' ' + styles.dialogChild}>
                                    Your session has expired. In order to sync new changes, please log back in while
                                    connected via WiFi or cellular data.
                                </div>
                                <div className={styles.dialogText + ' ' + styles.dialogChild}>
                                    Do you want to log in now?
                                </div>
                            </div>
                        );
                    },
                    children: null,
                    showOkButton: true,
                    okButtonText: 'Continue offline',
                    showCancelButton: true,
                    cancelButtonText: 'Log In',
                    handleClose: globalDialogConfig.handleClose
                };
            }
            
            return (
                <AlertDialog
                    open={true}
                    dialogTitle={finalGlobalDialogConfig.dialogTitle}
                    dialogMessage={finalGlobalDialogConfig.dialogMessage}
                    children={finalGlobalDialogConfig.children}
                    renderChildren={finalGlobalDialogConfig.renderChildren}
                    showOkButton={finalGlobalDialogConfig.showOkButton}
                    okButtonText={finalGlobalDialogConfig.okButtonText}
                    showCancelButton={finalGlobalDialogConfig.showCancelButton}
                    cancelButtonText={finalGlobalDialogConfig.cancelButtonText}
                    handleClose={(okClicked) => handleCloseGlobalAlertDialog(okClicked, finalGlobalDialogConfig.handleClose)}
                />
            );
        }
        
        return null;
    }
    const handleOrganizationSelection = (newOrganization) => {
        setSelectedOrganization(newOrganization);
    }

    const handleOrganizationChange = () => {
        setOrganizationChooser(false);
        setSelectableOrganizations(availableOrganizations.filter(o => o.evv === true));
        setCurrentOrganization(selectedOrganization);
        syncService.changeCurrentOrganization({dispatch}, selectedOrganization);
        history.push('/dashboard');
    }

    const handleOrganizationClick = () => {
        setOrganizationChooser(true);
        setSelectedOrganization(currentOrganization);
    }

    const handleCancelClick = () => {
        setOrganizationChooser(false);
        setSelectedOrganization(currentOrganization);
    }

    const isPageTitleFlag = (call === 'FacesheetPage');


    const handheldHeaderData = () => {
        return (
            <div id='handheldHeader' className={styles.handheldHeader}>
                <div className={isSchedulePage() ? styles.handheldHeaderExtendedContent : styles.handheldHeaderContent}>
                    <div className={styles.handheldTitleRow}>
                        <div className={styles.navigationBackContainer}>
                            {isChildPage &&
                                <CustomButton onClick={handleBack}><ArrowBackIosIcon htmlColor="#3b276a"/></CustomButton>}
                            {renderTitle()}
                        </div>
                        {renderButtonContainer()}
                    </div>
                    {handheldSearchEnabled && renderSearchField()}
                </div>
                {isSchedulePage() &&
                    <ScheduleHeader calendarView={calendarView} viewDate={viewDateForSchedule}
                    selectedDate={selectedDateForSchedule}/>
                }
                {renderAlertDialog()}
            </div>
        )
    }

    const renderHandheldHeader = () => {
        if(PAGE_HEADER.includes(pageTitle)) {
            return(
                <>
                    <div id='handheldHeader' className={styles.handheldHeader}>
                        <div className={isSchedulePage() ? styles.handheldHeaderExtendedContent : styles.handheldHeaderContent} style = {isPageTitleFlag ? {paddingLeft: '' } : {padding: '9px 16px 0px 16px'}}>
                        <FacesheetClientCard client={selectedClient} call='AppHeader'/>
                            <div className={styles.handheldTitleRow} style={pageTitle === "Documents" ? {paddingBottam: "6px"} : {}}>
                                <div className={styles.navigationBackContainer}>
                                    {isChildPage &&
                                        <CustomButton onClick={handleBack}><ArrowBackIosIcon htmlColor="#3b276a"/></CustomButton>}
                                    {renderTitle()}
                                </div>
                                {renderButtonContainer()}
                            </div>
                        </div>
                    </div>
                </>
            )
        }
        return (handheldHeaderData())
    }

     const renderOrganization = () => {
        return (
            <DialogContent classes={{root: styles.dialogContent}}>
                <div>
                        {selectableOrganizations && selectableOrganizations.length > 0 &&
                            <div className={styles.organizationsContainer}>
                                <Organizations
                                    organizations={selectableOrganizations}
                                    currentOrganization={selectedOrganization}
                                    handleOrganizationChange={handleOrganizationSelection}
                                    call={call}
                                />
                            </div>
                        }
                </div>
            </DialogContent>
        );
    }

    const renderHandleSelectCancel = () => {
        return (
            <DialogActions classes={{root: styles.okCancelButtonGroup}}>
                <div className={styles.cancelButton}>
                    <EvvButton type='tertiary' onClick={handleCancelClick}>Cancel</EvvButton>
                </div>
                <div className={styles.okButton}>
                    <EvvButton onClick={handleOrganizationChange} type='primary'>Select</EvvButton>
                </div>
            </DialogActions> 
        );
    }

    const renderOrganizationChooser = () => {
        return(
            <Dialog
                classes={{paper: styles.dialogPaper}}
                open={true}
                disableEscapeKeyDown={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle classes={{root: styles.dialogTitle}} id="documentChooserDialog">Change Organization</DialogTitle>
                    {renderOrganization()}
                    {renderHandleSelectCancel()}
                </Dialog>
        )
    }

    const renderTabletHeader = () => {
        return (
            <div className={styles.tabletHeader}>
                <div className={styles.logoContainer} >
                    <img className={styles.logoImage} src={EvvLogo} alt="logo" />
                </div>
                {renderSearchField()}
                {renderButtonContainer()}
                <div className={styles.userInfoContainer} >
                    <div className={styles.userNameLabel}>{formatUserName(user)}</div>
                    <div data-testid="Organization" className={styles.organizationLabel} onClick={handleOrganizationClick}>{organizations ? formatOrganization(organizations.find(o => o.organizationId === user.currentOrganizationId)) : ''}</div>
                    {showOrganizationChooser  && 
                        <div>
                            {renderOrganizationChooser()}
                        </div>
                    }
                </div>
                {showActiveSessionsDialog &&
                    <ActiveSessionsDialog handleClose={handleActiveSessionsDialogClose}/>
                }
                {renderAlertDialog()}
            </div>
        );
    }

    return (
        isHandheld
            ? renderHandheldHeader()
            : renderTabletHeader()
    );
}
