import {makeStyles} from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";
import {clientCache} from "../../../cache/slices/client/clientSlice";
import {useDispatch} from "react-redux";
import {getClientImage} from "../../../common/utils/imageUtils";
import clientService from "../../../common/services/clientService";
import {DateTime} from "luxon";
import { useSelector } from "react-redux";
import { appCache } from "../../../cache/slices/app/appSlice";
import { PRIMARY_COLOR } from "../../../common/constants";
import * as msgConst from '../../../features/forms/common/Messages';

const useStyles = makeStyles((theme) => ({
    clientStrip: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: "#ffffff",
        paddingBottom: '13px',
        [theme.breakpoints.down('sm')]: {
            paddingTop: '8px'
        },
        [theme.breakpoints.up(601)]: {
            paddingTop: '12px'
        }
    },
    clientStripTitle: {
        [theme.breakpoints.down('sm')]: {
            fontSize: "18px",
            fontWeight: "bold",
            fontStyle: "normal",
            letterSpacing: 0,
            color: PRIMARY_COLOR,
            paddingBottom: '8px'
        },
        [theme.breakpoints.up(601)]: {
            fontSize: '21px',
            fontWeight: "bold",
            fontStyle: "normal",
            letterSpacing: 0,
            color: PRIMARY_COLOR,
            paddingBottom: '8px'
        }
    },
    clientsContainer: {
        width: '100%',
        display: 'grid',
        columnGap: '18px',
        gridTemplateColumns: 'repeat(4, 1fr)',
        paddingBottom: '5px'
    },
    clientContainer: {
        width: '17vw',
        height: '14vh',
        display: 'flex',
        flexDirection: 'column',   
    },
    clientContainerForTablet: {
        width: '52px',
        height: '82px',
        display: 'flex',
        flexDirection: 'column',   
    },
    image: {
        width: '48px',
        height: '48px',
        borderRadius: '50%',
        margin: 'auto',
        display: 'block'
    },
    imageSelected: {
        width: '48px',
        height: '48px',
        borderRadius: '50%',
        border: '3px solid #3b276a',
        margin: 'auto',
        display: 'block'
    },
    clientNameTable: {
        paddingTop: '2px',
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
    },
    clientNameLabel: {
        display: 'block',
        margin: 'auto',
        verticalAlign: 'middle',
        fontSize: '14px',
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: '16px',
        letterSpacing: 0,
        textAlign: "center",
        color: "#515151",
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    }
}));

function ClientImage({client, handleClientClick, showFullName, isSelected}) {
    const styles = useStyles();
    const isHandheld = useSelector(appCache.isHandheld);
    const handleClick = () => {
        handleClientClick(client);
    }

    function truncateString(str) {
        if (str.length > 6) {
          return str.slice(0, 6) + "...";
        } else {
          return str;
        }
      }

    return (
        <div data-testid={client.clientId} key={client.clientId} className={isHandheld ? styles.clientContainer : styles.clientContainerForTablet} onClick={handleClick}>
            <div>
                <img className={isSelected ? styles.imageSelected : styles.image} src={getClientImage(client)} alt='client' />
            </div>
            <div className={styles.clientNameTable}>
                <p className={styles.clientNameLabel}>{truncateString(client.lastName) + ','}</p>
                <p className={styles.clientNameLabel}>{truncateString(client.firstName)}</p>
            </div>
        </div>
    );
}

export default function ClientStrip({title, clients, clientSelectable = false, showFullName = true, selectedClient, handleClick}) {
    const dispatch = useDispatch();
    const history = useHistory();
    const styles = useStyles();

    const handleClientClick = (client) => {
        let selectClientFlag = true;

        if (handleClick){
            selectClientFlag = handleClick(client);
        }

        if (selectClientFlag){
            clientService.visitClient(client, DateTime.now().toMillis());
            dispatch(clientCache.selectClient(client));
            history.push('/dashboard/facesheet');
        }
    }

    const isSelected = (client, index) => {
        if (clientSelectable === true) {
            if (!selectedClient && index === 0) {
                return true;
            }

            return selectedClient && client.clientId === selectedClient.clientId;
        }else {
            return false;
        }
    }

    const renderContent = () => {
        if (clients && clients?.length > 0) {
            return (
                <div className={styles.clientStrip}>
                    <div className={styles.clientStripTitle}>
                        {title}
                    </div>
                    <div className={styles.clientsContainer}>
                        { clients.map((client, index) =>
                            <ClientImage
                                key={client.clientId}
                                client={client}
                                showFullName={showFullName}
                                isSelected={isSelected(client, index)}
                                handleClientClick={handleClientClick}/>
                        ) }
                    </div>
                </div>
            )
        } else {
            return (
                <div className={styles.clientStrip} style={{ height:"100%"}}>
                    <div className={styles.clientStripTitle}>
                        {title}
                    </div>
                    <div className="noDataStyle">
                        {msgConst.noClientsFound}
                    </div>
                </div>
            )
        }
    }

    return (
        <> { renderContent() } </>
    )
}
