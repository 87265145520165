import {withStyles} from "@material-ui/core/styles";
import React from "react";
import { validateFields } from "../common/ValidateFields";
import FormFieldFactory from "../fields/FormFieldFactory";
import {
    handleChangedInput,
    findFirstErrorField,
    getFieldValues
} from "../common/ConfigurableFormsUtils";
import { assignOptionsToDropDown, getDescriptorList } from "../common/DropDownFieldUtil";
import { assignOptionsToCheckBox } from '../common/DescriptorCheckboxUtil';
import {sortElements} from '../common/Util';
import { getFormatedValue } from "../common/HandleChangedInputUtils";
import { Divider } from "@material-ui/core";
import "../ServiceDocName.css";
import { SERVICE_DOCUMENT_STATUS_SIGNED } from "../../forms/serviceDocumentUtils";
import { getFormattedValue } from "../common/FormFieldsUtils";

const styles = () => ({
    configurableForm: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '17px',
        paddingLeft: '15px',
        paddingRight: '15px'
    },
    hide: {
        display: 'none'
    },
    filterSelect: {
        width: '100%',
        height: '27px',
        borderRadius: '2px',
        background: "transparent",
        borderStyle: "solid",
        borderWidth: '1px',
        borderColor: "#b6b6b6"
    },
});

class ConfigurableForm extends React.Component{
    constructor(props) {
        super(props);

        this.module = props.module;
        this.serviceDocument = props.serviceDocument;
        this.documentController = props.documentController;
        this.formFields = [];
        this.IsNextButtonClick = false;
        this.formRules = [];
        this.actionsOnField = [];
        this.conditionsOnField = [];
        this.fields = [];
        this.forceHandleSave = props.forceHandleSave;
        this.selectedClient = props.selectedClient;
        this.isReadOnlyDoc = ( props.serviceDocument.status === SERVICE_DOCUMENT_STATUS_SIGNED ) ? true : false;
        this.evvDocuments = props.evvDocuments;
        this.signedDocuments = props.signedDocuments;
        this.staff = props.staff;
        this.isOffline = props.isOffline;
    };

    componentDidMount() {
        this.setState({loaded: true});
        this.setState({ dbOptions: [] });
        this.setState({ descriptorData: [] });
        this.setState({ isReadOnlyDoc: this.isReadOnlyDoc });
        this.applyEventHandlers();
        this.getDescriptorTableData();
        this.createFormFields();
        this.applyBR();

        const appointmentDocumentArr = this.documentController.appointmentDocument;

        if(appointmentDocumentArr && appointmentDocumentArr[0]) {
            const {moduleData: moduleDataArray} = appointmentDocumentArr[0];
            if(moduleDataArray) {
                const additionalFields = ["loaded", "dbOptions", "nextClicked"];
                const moduleCurrentData = moduleDataArray.find( m => m.moduleId === this.module.moduleId);
                if(moduleCurrentData && moduleCurrentData.fieldData) {
                    const currentFormData = moduleCurrentData.fieldData.filter( d => !additionalFields.includes(d.key) );
                    currentFormData.forEach( d => {
                        if(!this.state || !this.state[d.key]) {
                            this.setState({[d.key]: d.value});
                        }
                    } )
                }
            }
        }
    }

    applyBR = () => {
        if (this.formFields.length > 0) {
            setTimeout(() => {
                validateFields(this.module, true, this.selectedClient, this.state?.descriptorData);
                this.setState({nextClicked: true},() => {this.handleSave()});
            }, 3000);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if (prevProps.module.moduleId !== this.props.module.moduleId){
            this.applyEventHandlers();
            this.createFormFields();
        }
    }

    applyEventHandlers = () =>   {
        this.module.onValidate = this.handleValidate;
        this.module.onPageChange = this.handlePageChange;
    }

    getOptions = async (fieldDescriptor, dbDdOptionsCheck) => {
        let options = [];
        if (fieldDescriptor?.options?.length > 0) {
            options = fieldDescriptor?.options;
        } else {
            if (dbDdOptionsCheck.includes(fieldDescriptor?.type)) {
                options = await assignOptionsToDropDown(fieldDescriptor, this.selectedClient, this.documentController);
                if(fieldDescriptor?.type !== "Descriptor DropDown") {
                    sortElements(options, 'value', 'string');
                }
            } else {
                options = await assignOptionsToCheckBox(fieldDescriptor, this.documentController);
                sortElements(options, 'id', 'number');
            }
        }
        if (options.length > 0 && this.state !== null) {
            let { dbOptions } = this.state;
            dbOptions[fieldDescriptor?.formId + "-" + fieldDescriptor?.id] = options;
            this.setState({ dbOptions: dbOptions });
        }
    }

    getDescriptorTableData = async () => {
        const orgId = this.documentController?.dataProvider?.getUser()?.currentOrganizationId;
        let descriptorList = await getDescriptorList(orgId);
        if (descriptorList.length > 0) {
            this.setState({ descriptorData: descriptorList });
        }
    }

    createFormFields = () =>   {
        this.formFields = this.module.configurableForm.fields.map((fieldDescriptor, index) => {
            const dbDdOptionsCheck = ['StaffDropDown', 'ProgramDropDown', 'ActiveMedicationDropDown', 'Descriptor DropDown', 'ReferralSourceDropDown'];
            const dbChkOptionsCheck = ['Descriptor CheckBox'];
            if (dbDdOptionsCheck.includes(fieldDescriptor?.type) || dbChkOptionsCheck.includes(fieldDescriptor?.type)) {
                this.getOptions(fieldDescriptor, dbDdOptionsCheck);
            }
            fieldDescriptor.moduleId = this.module.configurableForm.moduleId;
            fieldDescriptor.serviceDocument = { id: this.serviceDocument.id, description: this.serviceDocument.description };
            fieldDescriptor.evvDocuments = this.evvDocuments;
            fieldDescriptor.selectedClientId = Number(this.selectedClient?.clientId);
            fieldDescriptor.signedDocuments = this.signedDocuments;
            fieldDescriptor.staffId = this.staff.staffId;
            fieldDescriptor.isOffline = this.isOffline;
            return FormFieldFactory.createInstance(fieldDescriptor, index++);
        });
    }

    handleValidate = (currentPage, props) => {
        // validation of fields is done in validateFields() function.
        // second parameter is to varify, if validateFields is called after clicking on next.
        let validationResult = validateFields(this.module, true, this.selectedClient, this.state?.descriptorData);
        // scrolls the form to the position of the first field with an existing error message
        findFirstErrorField(this.module);
        if(currentPage && validationResult?.showFloatingErrorMsg) {
            this.documentController.showFloatingErrorMessage();
        }
        // Set floating error message using parent callback function(setFloatingErrorMsg)
        this.props?.setFloatingErrorMsg(validationResult?.showFloatingErrorMsg);
        this.setState({nextClicked: true},() => {this.handleSave()}) 
        return validationResult.isValid;
    }

    handlePageChange = (sourcePage, targetPage, changeType) => {
        this.handleSave();
    }

    handleSave = () => {
        const ignoreSave = ['descriptorData', 'isReadOnlyDoc'];
        const moduleData = {
            moduleId: this.module.moduleId,
            moduleType: 'cf',
            fieldData: Object.keys(this.state).map(key => ({key: key, value: this.state[key]})).filter(e => isNaN(+e.key) && (!ignoreSave.includes(e.key))),
        };

        const fieldValues = getFieldValues(this.module?.configurableForm?.fields, this?.selectedClient, this?.state?.descriptorData);
        moduleData.fieldData = [ ...fieldValues, ...moduleData.fieldData ];

        if (!this.documentController.moduleData){
            this.documentController.moduleData = {};
        }

        this.documentController.moduleData[this.module.moduleId] = moduleData;
    }

    updateServiceDocValue = (descriptor, props, fieldValue) => {
        let value = getFormattedValue(descriptor, fieldValue);

        if (value !== undefined) {
            let moduleToUpdate = props?.serviceDocument?.modules.find(mod => descriptor.moduleId === mod.moduleId);
            if (moduleToUpdate && moduleToUpdate.fieldData){
                let fieldDataToUpdate = moduleToUpdate.fieldData.find(data => descriptor.id === data.key);

                if (value === null) {
                    value = '';
                }

                if (fieldDataToUpdate) {
                    fieldDataToUpdate.value = value;
                } else {
                    moduleToUpdate.fieldData.push({'key': descriptor.id, 'value': value});
                }
            }
        }
    }

    handleFieldChange = (evt, descriptor, props) => {
        handleChangedInput(descriptor, evt, this.module);
        let value = getFormatedValue(evt, descriptor)
        this.setState({[descriptor.id]: value});

        if(props) {
            validateFields(this.module, false, this.selectedClient, this.state?.descriptorData);

            if(this.forceHandleSave) {
                this.handleSave();
            }
        }

        //Need to update service document module field data state value on field change
        this.updateServiceDocValue(descriptor, props, value);
    }

    render() {
        let isHandheld = this.documentController.dataProvider.isHandheld();
        const { classes, hide } = this.props;
        const className = classes.configurableForm + ' ' + (hide ? classes.hide : '');
        const id = `fields${this.module.moduleId}`;
        return (
            <div id={id} data-testid='configurableForm' className={className}>
                <div className="serviceDocumentName">
                {!isHandheld && <><div className="serviceDocName" data-testid='serviceDocName'>{this.serviceDocument?.description}</div><Divider /></>}</div>
                {isHandheld && <><div>{this.props.renderFormName()}</div><Divider /></>}
                {this.formFields.map((formField) => {
                    const key = `${formField.getName()}_${formField.getId()}`;
                    return formField.render({...this.props, key: key, onChange: this.handleFieldChange}, this.state);
                })}
            </div>
        );
    }
}

export default withStyles(styles)(ConfigurableForm);