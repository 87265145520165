import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";
import {InputAdornment, Paper} from "@material-ui/core";
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete from "@material-ui/lab/Autocomplete";
import {createFilterOptions} from "@material-ui/lab";

const useStyles = makeStyles(() => ({
    searchTextField: {
        borderRadius: '5px',
        backgroundColor: "#ffffff",
        borderStyle: "solid",
        borderWidth: '1px',
        borderColor: "#acacac"
    },
    searchInput: {
        fontSize: "16px",
        fontStyle: "normal",
        letterSpacing: 0,
        color: "#a0a0a0",
        height:'28px',
    },
    searchAdornment: {
        marginRight: "0px",
        padding: '4px 3px 4px 5px',
        width: '42px',
        height: '38px'
    },
    searchIcon: {
        width: '100%',
        height: '100%',
        opacity: 0.43
    },
    searchPaper: {
        backgroundColor: "#f5f5f5",
        margin: '0px',
        borderStyle: "solid",
        borderWidth: '1px',
        borderColor: "#aaa9a9",
        fontSize: '16px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: "#5e5e5e"
    },
    searchList: {
        margin: 0,
        padding: 0
    },
    searchOption: {
        height: '40px',
        borderBottom: '1px solid #aaa9a9'
    }
}));

const CustomPaper = (props) => {
    return <Paper square={true} elevation={8} {...props} />;
};

const filterOptionsLimit = createFilterOptions({
    limit: 5
});

export default function SearchField({
                                        id,
                                        placeholder,
                                        noOptionsText,
                                        options,
                                        freeSolo = false,
                                        getOptionLabel,
                                        getOptionSelected,
                                        filterOptions,
                                        onChange,
                                        value,
                                        onInputChange,
                                        inputValue,
                                        selectOnFocus = true,
                                        clearOnEscape = true,
                                        clearOnBlur = true,
                                        blurOnSelect = true,
                                        forcePopupIcon = false,
                                        disableClearer = false,
                                        autoComplete = true,
                                        autoHighlight = true,
                                        autoSelect = false
                                    }) {
    const styles = useStyles();

    return (
        <Autocomplete
            id={id}
            value={value}
            onChange={onChange}
            freeSolo={freeSolo}
            disableClearable={disableClearer}
            autoComplete={autoComplete}
            autoHighlight={autoHighlight}
            autoSelect={autoSelect}
            noOptionsText={noOptionsText || 'No results found'}
            filterOptions={filterOptions || filterOptionsLimit}
            PaperComponent={CustomPaper}
            classes={{
                input: styles.searchInput,
                paper: styles.searchPaper,
                listbox: styles.searchList,
                option: styles.searchOption
            }}
            selectOnFocus={selectOnFocus}
            clearOnEscape={clearOnEscape}
            clearOnBlur={clearOnBlur}
            blurOnSelect={blurOnSelect}
            forcePopupIcon={forcePopupIcon}
            inputValue={inputValue}
            onInputChange={onInputChange}
            options={options || []}
            getOptionLabel={getOptionLabel}
            getOptionSelected={getOptionSelected}
            renderInput={(params) => (
                <TextField {...params} label=""
                           classes={{root: styles.searchTextField}}
                           placeholder={placeholder}
                           InputProps={{
                               ...params.InputProps,
                               startAdornment: (
                                   <InputAdornment position='start' classes={{positionStart: styles.searchAdornment}}>
                                       <SearchIcon className={styles.searchIcon}  />
                                   </InputAdornment>
                               ),
                               disableUnderline: true
                           }} />
            )}
        />
    );
}
