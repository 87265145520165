/* eslint-disable eqeqeq */
import {DateTime} from "luxon";
import {sort, sortWithDirection} from "./miscUtils";
import syncService from "../services/syncService";
import {stringToDateWithFormat} from "./formatUtils";
import StackedField from "../components/StackedField/StackedField";
import ActivityQualifiers from "../components/ActivityQualifiers";
import { noDocRequired } from "../constants";
import { evvRepository } from "../../db/evv";
import { orderBy } from "lodash";
import { appCache } from "../../cache/slices/app/appSlice";
import activityLogRepository from "../../db/activityLogRepository";

export const EMPTY_PROGRAM =  {programId: -1, name: 'Select Program'};
export const EMPTY_ORGANIZATION = {organizationId: -1, name: 'Select Organization'};
export const EMPTY_ACTIVITY = {activityId: -1, description: 'Select Activity', dataAggregator: "Y"};
export const EMPTY_SERVICE_LOCATION = {serviceLocationId: -1, placeOfService: 'Select Service Location'};

export const NON_EDITABLE_APPOINTMENT_STATUS_OPTIONS = [
    'Kept',
    'Co-Staff',
    'Error'
];

export const EDITABLE_APPOINTMENT_STATUS_OPTIONS = [
    'Checked In',
    'CBC',
    'CBT',
    'DNS',
    'None'
];

export const APPOINTMENT_STATUS_OPTIONS = [
    '',
    'In Session',
    'Kept',
    'Co-Staff',
    'Error',
    ...EDITABLE_APPOINTMENT_STATUS_OPTIONS
];

export const SORTED_STATUS_OPTIONS_AD = [
    'None',
    'CBC',
    'CBT',
    'Checked In',
    'Co-Staff',
    'DNS',
    'Error',
    'In Session',
    'Kept'
];

export const SORTED_STATUS_OPTIONS_VVD = [
    'CBC',
    'CBT',
    'Checked In',
    'Co-Staff',
    'DNS',
    'Error',
    'In Session',
    'Kept'
];

export const SORTED_STATUS_OPTIONS_FOR_EDITAPD = [
    'None',
    'CBC',
    'CBT',
    'Checked In',
    'Co-Staff',
    'DNS',
    'Error',
    'Kept'
];


export const DEFAULT_STATUS = APPOINTMENT_STATUS_OPTIONS[1];

export const createNewAppointment = (startDate, status, client) => {
    const now = DateTime.now();
    const startDateInstance = startDate.startOf('day').plus({hours: now.hour, minutes: now.minute});
    const endDateInstance = startDateInstance.plus({hours: 1});

    return {
        startDateInstance,
        endDateInstance,
        status: status && status.length > 0 ? status : 'None',
        client: client
    };
}

export const isoTextToDateTimeTransformer = (sourceValue) => {
    return sourceValue ? DateTime.fromISO(sourceValue) : null;
}

export const isNowWithinRange = (beginDate, endDate) => {
    const now = DateTime.now();

    if (beginDate && beginDate <= now){
        return !endDate || endDate > now;
    }

    return false;
}

export const isDescriptorExpired = (endDate) => {
    const now = DateTime.now();

    if ( endDate && isoTextToDateTimeTransformer(endDate) < now) {
        return true;
    }

    return false;
}

export const getInSessionVisits = async(setInSessionVisits) => {
    let visits = await syncService.visitService.getInSessionVisit();
    if (visits && visits?.length > 0) {
        setInSessionVisits(visits);
    }
}

export const checkIfAptInSession = (appointment, visitsInSession) => {
    let inSession = false;
    let entry = visitsInSession?.filter(visit => visit?.appointmentId === appointment?.appointmentId);
    if (entry && entry?.length > 0) {
        inSession = true;
    }
    return inSession;
}

export const dateRangeOverlaps = (call, appointment, visitsInSession, staff, newBeginDate, newEndDate) => {
    let overlap = false;
    let beginDate = appointment?.startDateInstance;
    let endDate = appointment?.endDateInstance;

    let isAptInSession = checkIfAptInSession(appointment, visitsInSession);

    //If any appointment is already in session & multibook is not allowed
    if (call === "startSession" && isAptInSession && !staff?.isMultiBookSchedule) {
        return true;
    }
    if (!isAptInSession && (newBeginDate?.month !== beginDate?.month || newBeginDate?.day !== beginDate?.day)){
        return overlap;
    }
    if ( overlapWithApptStatus(call, beginDate, endDate, newBeginDate, isAptInSession) || overlapWithoutApptStatus(beginDate, newBeginDate, endDate, newEndDate) ){
        overlap = true;
    }

    return overlap;
}

const IGNORE_OVERLAP_STATUSES = ['Error', 'DNS', 'CBT', 'CBC'];
export const appointmentOverlaps = (visitsInSession = [], staff, appointmentToCheck, appointments, call = "appointment") => {
    const newBeginDate = call === 'stopSession' ? appointmentToCheck?.actualStartDateInstance: appointmentToCheck.startDateInstance;
    const newEndDate = call === 'stopSession' ? appointmentToCheck?.actualEndDateInstance: appointmentToCheck.endDateInstance;
        for (let appointment of appointments){
            if (appointmentToCheck.appointmentId === appointment.appointmentId){
                continue;
            }
            if (dateRangeOverlaps(call, appointment, visitsInSession, staff, newBeginDate, newEndDate)){
                if (!IGNORE_OVERLAP_STATUSES.includes(appointment.status)){
                    return true;
                }
            }
        }
    return false;
}

export const isAppointmentEditable = (appointment, visits) => {
    const visit = visits && visits.find((v) => v.appointmentId === appointment.appointmentId);
    if(visit) {
        if(visit.complete === 1 || visit.complete === 0) {
            return false;
        } else if(visit.complete === -1) {
            return true;
        }
    } else {
        return (appointment && EDITABLE_APPOINTMENT_STATUS_OPTIONS.includes(appointment.status));
    }
}

export const getInitialProgram = (appointment, programs) => {
    if (appointment && appointment.programId){
        const program = programs.find(program => program.programId === appointment.programId);
        if (program){
            return program;
        }
    }

    if (programs && programs.length <= 2) {
        return programs[programs && programs.length - 1];
    }

    return EMPTY_PROGRAM;
};

export const getInitialOrganization = (user, appointment, organizations) => {
    let organization = null;

    if (appointment && appointment.organizationId){
        // eslint-disable-next-line eqeqeq
        organization = organizations.find(o => o.organizationId == appointment.organizationId);
        if (organization){
            return organization;
        }
    }

    if (organizations) {
        // eslint-disable-next-line eqeqeq
        organization = organizations.find(o => o.organizationId == user.currentOrganizationId);
    }

    return organization ? organization : EMPTY_ORGANIZATION;
};

export const getInitialActivity = (appointment, activities) => {
    if (appointment && appointment.activityId){
        const activity = activities.find(activity => activity.activityId === appointment.activityId);
        if (activity){
            return activity;
        }
    }

    if (activities && activities.length <= 2) {
        return activities[activities && activities.length - 1];
    }

    return EMPTY_ACTIVITY;
};

export const getServiceLocation = (appointment, serviceLocations) => {
    if (appointment && appointment.serviceLocationId){
        const serviceLocation = serviceLocations.find(sl => sl.serviceLocationId === appointment.serviceLocationId);
        if (serviceLocation){
            return serviceLocation;
        }
    }

    return EMPTY_SERVICE_LOCATION;
};

export const sortAppointments = (appointments) => {
    return sortWithDirection(appointments, [(a) => a && a.startDateInstance ? a.startDateInstance.toMillis() : 0], ['asc']);
}

export const sortOrganizations = (organizations, includeEmptyOrganization) => {
    const sortedOrganizations = sort(organizations, ['name']);

    return includeEmptyOrganization ? [EMPTY_ORGANIZATION, ...sortedOrganizations] : sortedOrganizations;
};

export const sortPrograms = (programs) => {
    return [EMPTY_PROGRAM, ...sort(programs, ['name'])];
};

export const sortActivities = (activities) => {
    return [EMPTY_ACTIVITY, ...sort(activities, ['description'])];
};

export const sortServiceLocations = (serviceLocations) => {
    return [EMPTY_SERVICE_LOCATION, ...sort(serviceLocations, ['code', 'placeOfService'])];
};

export const getClientProgramFromAppointment = (appointment, clientPrograms) => {

    let organizationIds = []
    const clientProgramFilter = clientPrograms.filter(cp => cp.programId === appointment.programId && cp.clientId === appointment.clientId)
    const clientProgramOrganizations = clientProgramFilter.map(cp => cp.organizationId)
    
    clientProgramOrganizations.forEach(orgId => {
        const ancestorAndDescendants = syncService.organizationService.getOrganizationAncestorsAndDescendants(orgId)
        ancestorAndDescendants.forEach(org => organizationIds.push(org.organizationId))
    })

    return clientPrograms.find(cp => cp.programId === appointment.programId && cp.clientId === appointment.clientId && organizationIds.includes(appointment.organizationId));
};

export const getFilteredStatuses = (appointment, showEditAppointmentDialog) => {
    return getFilteredStatusesByStartDate(appointment.startDateInstance, appointment.organization, appointment.status, 'AD','',showEditAppointmentDialog);
}

export const getFilteredStatusesByStartDate = (startDateInstance, organization, status, requiredStatuses = [], dialog, showEditAppointmentDialog) => {
    const appointmentDateInstance = startDateInstance.startOf('day');
    const now = DateTime.now();

    let SORTED_STATUS_OPTIONS = null;

    if(dialog != '' && dialog == 'VVD') {
        SORTED_STATUS_OPTIONS = SORTED_STATUS_OPTIONS_VVD;
    } else if(showEditAppointmentDialog === true) {
        SORTED_STATUS_OPTIONS = SORTED_STATUS_OPTIONS_FOR_EDITAPD;
    } else {
        SORTED_STATUS_OPTIONS = SORTED_STATUS_OPTIONS_AD;
    }
    return SORTED_STATUS_OPTIONS
        .filter(item => {
            if (item === status || requiredStatuses.includes(item)){
                return true;
            }
            if (item === 'Checked In'){
                return now >= appointmentDateInstance && organization && organization.useCheckedIn;
            } else if (item === 'In Session' || item === 'Kept'){
                return now >= startDateInstance;
            } else if (item === 'Co-Staff'){
                return now >= startDateInstance && organization && organization.useCoStaff;
            }

            return true;
        });
}

export const validateRangeDate = (appointmentDate) =>{
    const initialAppointmentRange = new Date();        
    initialAppointmentRange.setDate(initialAppointmentRange.getDate() - 8);
    const appointmentDateMinusSevenDays = initialAppointmentRange.toLocaleString('en-US', { year: 'numeric', month:'2-digit',day:'2-digit' })+ ' 11:59 PM';
    
    const finalAppointmentRange = new Date();        
    finalAppointmentRange.setDate(finalAppointmentRange.getDate() + 31);
    const appointmentDatePlusThirdtyDays = finalAppointmentRange.toLocaleString('en-US', { year: 'numeric', month:'2-digit',day:'2-digit' })+ ' 00:00 AM';

    const currentAppointmentDate = new Date(appointmentDate);
    const currentAppointmentDateFormat = currentAppointmentDate.toLocaleString('en-US', { year: 'numeric', month:'2-digit',day:'2-digit' })+ ' 01:00 AM';
    
    let appointmentOutRange='false';
    if(Date.parse(currentAppointmentDateFormat) <= Date.parse(appointmentDateMinusSevenDays) || Date.parse(currentAppointmentDateFormat) >= Date.parse(appointmentDatePlusThirdtyDays)){
        appointmentOutRange='true';
    }
    return appointmentOutRange;
}

export const validDateForEditTimes = (appointmentDate) => {
    const initialAppointmentRange = new Date();
    initialAppointmentRange.setDate(initialAppointmentRange.getDate() - 8);
    const appointmentDateMinusSevenDays = initialAppointmentRange.toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }) + ' 11:59 PM';
    const currentAppointmentDate = new Date(appointmentDate);
    const currentAppointmentDateFormat = currentAppointmentDate.toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }) + ' 01:00 AM';
    let appointmentOutRange = 'false';
    if (Date.parse(currentAppointmentDateFormat) <= Date.parse(appointmentDateMinusSevenDays)) {
        appointmentOutRange = 'true';
    }
    return appointmentOutRange;
}

export const formatEndDate = (endTime) => {
    let endDate = '';
    if(!(endTime instanceof Date)){
        endDate = new Date().toLocaleString('en-US', { year: 'numeric', month:'2-digit',day:'2-digit' })+' '+endTime;
    } else {
        endDate = endTime;
    }
    return endDate;
}

export const formatStartTime = (startTime) => {
    let startDate = '';
    if(!(startTime instanceof Date)){
        startDate = new Date().toLocaleString('en-US', { year: 'numeric', month:'2-digit',day:'2-digit' })+' '+startTime;
    } else {
        startDate = startTime;
    }
    return startDate;
}

export const validateRangeTimePicker = (startTime, endTime) =>{
    let formatedEndDate = formatEndDate(endTime);
    let formatedStartTime = formatStartTime(startTime);

    if(endTime instanceof Date && startTime instanceof Date){
        return endTime <= startTime
    }
    return Date.parse(formatedEndDate) <= Date.parse(formatedStartTime);
}

export const getActivityQualifiersForActivity = (activity, activityQualifiers) => {
    const activityQualifiersForActivity = [];

    if (activity && activity.activityDescriptorList){
        activity.activityDescriptorList.forEach(ad => {
            const found = activityQualifiers.find(aq => aq.descriptorId === ad.descriptorId);
            if (found){
                activityQualifiersForActivity.push(found);
            }
        });
    }
    return activityQualifiersForActivity;
}

export const getActivityQualifiersForActivityReadOnly = (appointment, activityQualifiersForActivity) => {
    if ((appointment?.status === 'Kept' && activityQualifiersForActivity?.length > 0) && (appointment?.activityDetailDescriptorList?.length === 0 || (appointment?.activityDetailDescriptorList?.length > 0 && appointment?.activityDetailDescriptorList[0]?.descriptorId === "" ))) {
        return (
            <div>
                <StackedField label='Activity Qualifiers' paddingTop="17px">None</StackedField>
            </div>
        )
    } else if (appointment?.status === 'Kept' && activityQualifiersForActivity?.length > 0 && appointment?.activityDetailDescriptorList?.length > 0 && appointment?.activityDetailDescriptorList[0]?.descriptorId !== "") {
        return (
            <div>
                <StackedField label='Activity Qualifiers' paddingTop="17px">
                    <ActivityQualifiers
                        activityQualifiers={activityQualifiersForActivity}
                        selectedActivities={appointment.activityDetailDescriptorList}
                        readOnly={true}
                        viewAppointmentDialog={true}
                    />
                </StackedField>
            </div>
        )
    }
}

export const serviceDocumentDb = () => {
    return evvRepository?.evvDb?.serviceDocument?.toArray();
}

export const uniqueRecords = (filterDocuments) => {
    const uniqueKey = 'serviceDocumentId';

    let uniqueRecords = filterDocuments.filter((obj, index, arr) =>
      arr.findIndex(item => item[uniqueKey] === obj[uniqueKey]) === index
    );
    return uniqueRecords;
}

export const getFilteredServiceDocuments = async(appointment, filteredCrosswalks, serviceDocuments, staff) => {
    const endOfToday = DateTime.now().endOf('day');

    await serviceDocumentDb().then((result) => {
        serviceDocuments = [...result, noDocRequired];
    }).catch((error) => {
        console.log('Error: ', error);
    });

    const filteredServiceDocuments = filteredCrosswalks
        .filter(cw => {
            const beginDate = stringToDateWithFormat(cw.beginDate);

            if (beginDate > endOfToday) {
                return false;
            }

            if (cw.endDate) {
                const endDate = stringToDateWithFormat(cw.endDate);
                return endDate > endOfToday;
            }

            return true;
        })
        .filter(cw => cw.activityId === appointment.activityId)
        .filter(cw => !cw.programId || cw.programId === appointment.programId)
        .filter(cw => {
            if (cw.minAgeRange || cw.maxAgeRange){
                const clientAge = parseInt(appointment?.client?.age);
                let minAgeRange = cw.minAgeRange ? parseInt(cw.minAgeRange) : -1;
                let maxAgeRange = cw.maxAgeRange ? parseInt(cw.maxAgeRange) : 1000;

                if (isNaN(clientAge)){
                    return false;
                }

                if (isNaN(minAgeRange)){
                    minAgeRange = -1;
                }
                if (isNaN(maxAgeRange)){
                    maxAgeRange = 1000;
                }

                return clientAge >= minAgeRange && clientAge <= maxAgeRange;
            } else {
                return true;
            }
        })

        let filterDocuments = filteredServiceDocuments.filter(cw => (cw?.licensureId === staff?.billingCategory?.licensureId));
        if(filterDocuments.length === 0 ) {
            filterDocuments = filteredServiceDocuments.filter(cw =>  cw?.licensureId === "");
        }

        let result = uniqueRecords(filterDocuments).map(cw => serviceDocuments.find(sd => sd?.id === cw?.serviceDocumentId));
        result = orderBy(result,['id'],['desc']);

    return result.map(sd => ({...sd, appointment}));
}

export const filterDocumentCrosswalks = async(appointment, documentCrosswalks, serviceDocuments, staff) => {
    let filteredCrosswalks, filteredServiceDocuments;
    filteredCrosswalks = syncService.organizationService.getRecordsForOrganization(documentCrosswalks, appointment.organizationId, false, true);

    filteredServiceDocuments = await getFilteredServiceDocuments(appointment, filteredCrosswalks, serviceDocuments, staff);
    return filteredServiceDocuments;
}

export const appointmentHasErrors = (appointment) => {
    return appointment && (appointment.error || appointment.syncState === 'error');
}

export const isAddressRequiredForOrganization = (organization) => {
    return (organization && organization?.evvAddresses === true);
}

export const findAddress = (addresses, addressId, newAddressId) => {
    return addresses.find(address => address.personAddressId == addressId || address.id == addressId || address.id == newAddressId);
}

export const getStartAddressFromAppointment = (appointment, relatedClient) => {
    const client = relatedClient ?? appointment.client;
    const newAddressId = appointment?.newAddressId;
    let startVisitAddress = null;

    if (appointment.startVisitAddressId) {
        const startVisitAddressId = parseInt(appointment.startVisitAddressId);
        startVisitAddress = findAddress(client.addresses, startVisitAddressId, newAddressId);
    } else if (appointment.startVisitAddress) {
        startVisitAddress = findAddress(client.addresses, appointment.startVisitAddress.visitAddressId, newAddressId);
        if (!startVisitAddress) {
            startVisitAddress = appointment.startVisitAddress.id ? appointment.startVisitAddress : {
                ...appointment.startVisitAddress,
                id: appointment.startVisitAddress.visitAddressId
            }
        }
    }

    return startVisitAddress;
}

export const getEndAddressFromAppointment = (appointment, relatedClient) => {
    const client = relatedClient ?? appointment.client;
    const newAddressId = appointment?.newAddressId;
    let endVisitAddress = null;

    if (appointment.endVisitAddressId) {
        const endVisitAddressId = parseInt(appointment.endVisitAddressId);
        endVisitAddress = findAddress(client.addresses, endVisitAddressId, newAddressId);
    } else if (appointment.endVisitAddress) {
        endVisitAddress = findAddress(client.addresses, appointment.endVisitAddress.visitAddressId, newAddressId);
        if (!endVisitAddress) {
            endVisitAddress = appointment.endVisitAddress.id ? appointment.endVisitAddress : {
                ...appointment.endVisitAddress,
                id: appointment.endVisitAddress.visitAddressId
            }
        }
    }

    return endVisitAddress;
}

export const isStatusEditable = (appointment, visitCollection) => {
    const visit = visitCollection && visitCollection.find((v) => v.appointmentId === appointment.appointmentId);
    let statusEditable = true;
    if(NON_EDITABLE_APPOINTMENT_STATUS_OPTIONS.includes(appointment.status)) {
        statusEditable = false;
    } else if( appointment.status === "In Session") {
        if(visit) {
            if(visit.complete === 0) {
                statusEditable = false;
            }
        }
    }
    return statusEditable;
}

export const isActiveSession = (appointment, visitCollection) => {
    const visit = visitCollection && visitCollection.find((v) => v.appointmentId === appointment.appointmentId);
    let activeSession = false;
   if(appointment.status === "In Session") {
        if(visit) {
            if(visit.complete === 0) {
                activeSession = true;
            }
        }
    }
    return activeSession;
}

export const getAddresses = (clientAddresses, newAddresses) => {
    let allAddresses = clientAddresses;
        if(newAddresses !== null) {
            allAddresses = [...allAddresses, newAddresses]
        }
    return allAddresses;
}

export const getAllAddresses = (clientAddresses, newAddress, addressType) => {
    if(newAddress) {
        return getAddresses(replaceAddress(clientAddresses, addressType), newAddress);
    }
    return getAddresses(clientAddresses, newAddress);
}

export const replaceAddress = (clientAddresses, addressType) => {
    let addresses = [];
    addresses = clientAddresses.filter((address) => {
        if (address.addressType === addressType){
            return (address.id > 0);
        } else {
            return address;
        }
    });
    return addresses;
}

export const documentNotRequired = (document) => {
    return document.code === "NONE" && document.id === 1;
}

export const apiAddresses = (address, clientAddressData) => {
    let clientAdd;
    if(clientAddressData && clientAddressData.length > 0) {
        clientAddressData.forEach((clientAddress) => {
            if(clientAddress?.street1 === address?.street1 && clientAddress?.street2 === address?.street2 && clientAddress?.aptSuite === address?.aptSuite && 
                clientAddress?.city === address?.city && clientAddress?.state === address?.state && clientAddress?.zip === address?.zip){
                clientAdd = clientAddress;
            }
        });
    }
    return clientAdd;
}

export const isEvvActivity = (activity) => {
    let isEvvActivity = false;
    let sendActivitytoDataAggregator = activity?.dataAggregator;
    if (sendActivitytoDataAggregator && sendActivitytoDataAggregator === "Y") {
        isEvvActivity = true;
    }
    return isEvvActivity;
}

export const checkIfDisabled = (status, selectedActivity) => {
    let isDisabled = false;
    if (status === "In Session" && isEvvActivity(selectedActivity)) {
        isDisabled = true;
    }
    return isDisabled;
}
    
export const isNonMobileActivity = (apt, activities) => {
    let appointment = {...apt};
    const appointmentType = appointment?.appointmentType;

    if (!appointment?.activity) {
        appointment.activity = activities && activities.find(a => a.activityId === appointment.activityId);
    }

    const activityCarelogicEvvYn = appointment?.activity?.carelogicEvvYn;
    const organizationEvv = appointment?.organization?.evv;
    const programCarelogicEvvYn = appointment?.program?.carelogicEvvYn;

    switch (appointmentType) {
        case "G":
        case "S":
            return true;
        case "C":
            return !(organizationEvv && activityCarelogicEvvYn && programCarelogicEvvYn) ;
        default:
            return false;
    }
};
 
export const getMobileAppointments = (appointments, activities) => {
    return appointments?.filter(appointment => !isNonMobileActivity(appointment, activities));
};

export const overlapWithApptStatus = (call, beginDate, endDate, newBeginDate, isAptInSession) => {
    if (isAptInSession) {
        if (call === "appointment") {
            if (newBeginDate?.ts > endDate?.ts || newBeginDate?.ts < beginDate?.ts){
                return false;
            } else {
                return true;
            }
        } else {
            return (newBeginDate.ts >= beginDate.ts || newBeginDate > beginDate);
        }
    }
}

export const overlapWithoutApptStatus = (beginDate, newBeginDate, endDate, newEndDate) => {
    return ( (newBeginDate?.ts === beginDate?.ts || newEndDate?.ts === endDate?.ts) || (newBeginDate > beginDate && newBeginDate < endDate) || (newEndDate && newEndDate > beginDate && newEndDate < endDate) || (beginDate > newBeginDate && beginDate < newEndDate) );
}

export const loginData = (isHandheld, loggedInUserRef, dispatch) => {
    dispatch(appCache.toggleHandheldFlag(isHandheld));
    dispatch(appCache.login(loggedInUserRef?.current));
    activityLogRepository.saveActivityLog(loggedInUserRef?.current, 'Ignore Password Warning');
    activityLogRepository.saveActivityLog(loggedInUserRef?.current, 'Login');
}
