import Button from '@material-ui/core/Button';
import {makeStyles} from "@material-ui/core/styles";
import { PRIMARY_COLOR } from '../../constants';

const useStyles = makeStyles((theme) => ({
    button: {
        padding: '0 !important',
        minWidth: '24px !important',
        minHeight: '27px !important'
    },
    label: {
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            textTransform: "none",
            fontSize: '12px',
            fontWeight: "normal",
            fontStyle: "normal",
            letterSpacing: 0,
            textAlign: "center",
            color: "#888888"
        },
        [theme.breakpoints.up(601)]: {
            flexDirection: 'column',
            textTransform: "none",
            fontSize: '16px',
            fontWeight: "bold",
            fontStyle: "normal",
            letterSpacing: 0,
            textAlign: "center",
            color: "#888888"
        }
    }
}));

const CustomButton = ({ children, label, onClick, selected }) => {
    const styles = useStyles();

    return (<Button
        classes={{ root: styles.button, label: styles.label }}
        color="primary"
        disableRipple={true}
        onClick={onClick}
    >
        {children}
        <div className={styles.label} style={selected ? {color: PRIMARY_COLOR} : {}} >{label}</div>
    </Button>);
}

export default CustomButton;
