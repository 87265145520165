import { useEffect, useState } from 'react';
import EvvLogo from '../../../assets/images/CareLogic-Mobile.png';
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { appCache } from "../../../cache/slices/app/appSlice";
import { PRIMARY_COLOR } from '../../../common/constants';
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  splashPageContainer: {
    width: '100%',
    height: '100%',
    paddingLeft: theme.appPaddingX + 'px',
    paddingRight: theme.appPaddingX + 'px',
    background: "#F9F9F9",
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start'
    },
    [theme.breakpoints.up(601)]: {
      alignItems: 'center'
    }
  },
  logoContainer: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '33vh',
      maxWidth: '320px'
    },
    [theme.breakpoints.up(601)]: {
      maxWidth: '630px'
    }
  },
  logo: {
    width: '100%',
    height: '100%'
  },
  loadingContainer: {
    position: 'absolute',
    top: 'calc(70vh + 100px)',
    left: '50.5%',
    transform: 'translateX(-50%)',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up(601)]: {
      top: 'calc(70% + 100px)',
    }
  },
  loadingText: {
    color: PRIMARY_COLOR,
    fontSize: '1.2rem',
    marginLeft: '10px',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem'
    }
  },
  blinkingDots: {
    display: 'inline-block',
  },
  dot1: {
    animation: '$blink 1.4s infinite',
  },
  dot2: {
    animation: '$blink 1.4s infinite 0.2s',
  },
  dot3: {
    animation: '$blink 1.4s infinite 0.4s',
  },
  '@keyframes blink': {
    '0%, 100%': { opacity: 0 },
    '50%': { opacity: 1 },
  },
  loadingSpinner: {
    color: PRIMARY_COLOR,
    [theme.breakpoints.down('sm')]: {
      width: '20px !important',
      height: '20px !important'
    }
  }
}));

const SplashPage = () => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      setShowLoading(true);
    }, 2000);

    const splashTimeout = setTimeout(() => {
      dispatch(appCache.hideSplashPage());
    }, 2000);

    return () => {
      clearTimeout(loadingTimeout);
      clearTimeout(splashTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.splashPageContainer}>
      <div className={styles.logoContainer}>
        <img
          className={styles.logo}
          src={EvvLogo}
          alt="carelogic evv logo"
        />
      </div>
      {showLoading && (
        <div className={styles.loadingContainer}>
          <CircularProgress size={30} className={styles.loadingSpinner} />
          <p className={styles.loadingText}>
            Initializing the application
            <span className={styles.blinkingDots}>
              <span className={styles.dot1}>.</span>
              <span className={styles.dot2}>.</span>
              <span className={styles.dot3}>.</span>
            </span>
          </p>
        </div>
      )}
    </div>
  );
};

export default SplashPage;