import { FormControl, FormControlLabel } from "@material-ui/core";
import EvvRadio from "../../../common/components/EvvRadio";
import StackedField from "../../../common/components/StackedField";
import DisplayErrorMessages from "../common/DisplayErrorMessages";
import FormField from "./FormField";
import {sortElements} from '../common/Util';

class RadioButton extends FormField{
    isChecked = (value) => {
        let isChecked = this.fieldDescriptor?.dbSavedValue === value || this.fieldDescriptor.selectedOption === value;
        return isChecked ? isChecked : false;
    }

    renderField(props, state) {
        let description = "";
        let filteredObj = this.fieldDescriptor.attributes.find((attribute) => attribute.attributeId === this.fieldDescriptor.id +"_description");
        if(Object.keys(filteredObj).length > 0) {
            description = filteredObj.value;
        }
        props.fieldDescriptor = this.fieldDescriptor;
        let options = this.fieldDescriptor.options;
        // Sorting of Radio buttons
        sortElements(options, 'id', 'number');
        return (
            <div id={this.fieldDescriptor.id}>
                {/* If a business rule Action indicates that the RadioButton type field must be hidden,
                 then the system hides all the radio button options, description */}
                {this.fieldDescriptor?.businessRuleApplied !== "Hide" &&
                <StackedField paddingTop="0px">
                    <span>{description}</span>
                    {this.fieldDescriptor.options.map(({ value, id, disabled }, index) => {
                        return (
                            <div key = {index}>
                                <FormControl component="fieldset">
                                    <FormControlLabel
                                        control={<EvvRadio
                                            {...props}
                                            id={`radio-${this.fieldDescriptor.id}-${id}`}
                                            name={`radio-${this.fieldDescriptor.id}`}
                                            value={value}
                                            checked={ this.isChecked(value) }
                                            disabled = { state.isReadOnlyDoc }
                                            onChange={(event) => { props.onChange(event, this.fieldDescriptor, props); } }
                                        />}
                                    label={value}
                                    />
                                </FormControl>
                            </div>
                        )
                    })
                    }
                    <div>
                        <DisplayErrorMessages
                            {...props}
                        />
                    </div>
                </StackedField>
                }
            </div>
        )
    }
}

export default RadioButton;
