import {makeStyles} from "@material-ui/core/styles";
import CircleIcon from '@mui/icons-material/FiberManualRecord';

const useStyles = makeStyles(() => ({
    colorStatusCell: {
        display: "flex",
        alignItems: 'center',
        height: '20px'
    },
    statusIndicator: {
        width: '24px',
        height: '24px'
    },
    statusLabel: {
        fontSize: '16px',
        fontWeight: "normal",
        fontStyle: "normal",
        color: "#424242",
        paddingLeft: '4px'
    }
}));

const getColorForStatus = (status) => {
    if (status === 'Active'){
        return '#9cbb34';
    } else {
        return '#e16b5a';
    }
}

export default function ColorStatusCell({status, selectColor}) {
    const styles = useStyles();

    return (
        <div className={styles.colorStatusCell}>
            <div className={styles.statusIndicator}>
                <CircleIcon classes={{root: styles.statusIndicator}} htmlColor={selectColor ? selectColor(status) : getColorForStatus(status)} />
            </div>
            <div className={styles.statusLabel}>{status}</div>
        </div>
    );
}
