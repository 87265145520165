import {makeStyles} from "@material-ui/core/styles";
import {formatClientId, formatUserName, getPhoneInfo} from "../../../common/utils/formatUtils";
import {showMapApplication, showPhoneApplication} from "../../../common/utils/systemUtils";
import {getClientImage} from "../../../common/utils/imageUtils";
import {useSelector} from "react-redux";
import syncService from "../../../common/services/syncService";
import { FILTER_COLOR, PRIMARY_COLOR } from "../../../common/constants";

const useStyles = makeStyles(() => ({
    facesheetClientCard: {
        width: '100%',
        display: 'grid',
        rowGap: '14px',
        gridTemplateColumns: '48px 1fr 52px 38px',
        backgroundColor: "#ffffff",
        padding: '18px 0px 0px 16px'
    },
    DocumentsClientCard: {
        width: '100%',
        display: 'grid',
        rowGap: '14px',
    },
    imageColumn: {
        alignSelf: 'center'
    },
    image: {
        width: '48px',
        height: '48px',
        borderRadius: '50%'
    },
    clientColumn: {
        alignSelf: 'center',
        display: 'flex',
        paddingTop:'4px',
        paddingLeft:'2px'
    },
    clientNameLabel: {
        fontSize: '18px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: PRIMARY_COLOR,
        paddingBottom: '4px'
    },
    clientIdContainer: {
        paddingLeft: '3px',
        paddingBottom:'9px'
    },
    clientIdLabel: {
        fontSize: '14px',
        fontWeight: "bold",
        fontStyle: "normal",
        lineHeight: '20px',
        letterSpacing: 0,
        color: "#4f4f4f"
    },
    clientIdValue: {
        fontSize: '14px',
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: '20px',
        letterSpacing: 0,
        color: "#4f4f4f",
        paddingLeft: '2px',
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden"

    },
    iconColumn: {
        alignSelf: 'center',
        filter: FILTER_COLOR
    },
    clientRow: {
        flexDirection: '', 
        paddingTop: "0px",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden"
    },
    clientLabel: {
        fontSize:"15px",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden"
    },
    clientId: {
        paddingBottom:"0px",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden"
    }
}));

export default function FacesheetClientCard({client, call, pageTitle}) {
    const phoneTypes = useSelector(syncService.descriptorCacheService.phoneContactType.getResults());
    const styles = useStyles();
    const isPageTitleFlag = (call === 'FacesheetPage');

    const handlePhoneClick = () => {
        const phoneInfo = getPhoneInfo(client.phones, phoneTypes);

        if (phoneInfo && phoneInfo.primaryPhoneNumber){
            showPhoneApplication(phoneInfo.primaryPhoneNumber);
        }
    }

    const handleMapClick = () => {
        showMapApplication(client);
    }

    return (
        <div className={call === 'FacesheetPage' ? styles.facesheetClientCard: styles.DocumentsClientCard} style={pageTitle === '' ? {display: 'flex'} : {justifyContent:"center"}}>
            {isPageTitleFlag && 
            <div className={styles.imageColumn}>
                <img className={styles.image} src={getClientImage(client)} alt='client' />
            </div>}
 
            <div className={isPageTitleFlag ? `${styles.clientColumn}` : `${styles.clientColumn} ${styles.clientRow}`} style = {isPageTitleFlag ? {flexDirection: 'column'} : {}}>
                <div className={isPageTitleFlag ? `${styles.clientNameLabel}` : `${styles.clientNameLabel} ${styles.clientLabel}`} style = {isPageTitleFlag ? {paddingLeft: '11.6px' } : {}}>
                    {formatUserName(client)}
                </div>
                <div className={isPageTitleFlag ? `${styles.clientIdContainer}`: `${styles.clientIdContainer} ${styles.clientId}`} style = {isPageTitleFlag ? {paddingBottom:"9px"} : {}}>
                    <span style = {isPageTitleFlag ? {paddingLeft: '8px'} : {color:"#4f4f4f"}}>{ isPageTitleFlag ? "" : "("}<span className={styles.clientIdLabel} style = {isPageTitleFlag ? {fontWeight:""} : {fontWeight:"lighter", color:"#4f4f4f"}}>{"Client ID:"}</span></span>
                    <span className={styles.clientIdValue}>{isPageTitleFlag ? formatClientId(client) : formatClientId(client) + ")"}</span> 
                </div>
            </div>
            {call === 'FacesheetPage' &&
            <>
            <div data-testid='FacesheetClientCard_address' className={styles.iconColumn} onClick={handleMapClick}>
                <img src='../images/iconIcoLocation@2x.png' alt='map' />
            </div>
            <div data-testid='FacesheetClientCard_phone' className={styles.iconColumn} onClick={handlePhoneClick}>
                <img src='../images/iconIcoCall@2x.png' alt='phone' />
            </div>
            </>
            }
        </div>
    )
}
