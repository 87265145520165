import SortIcon from '@mui/icons-material/Sort';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    sortBar: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #d0d0d0'
    },
    sortIcon: {
        paddingLeft: '16px',
        paddingRight: '8px',
    },
    sortBarTitle: {
        fontSize: '16px',
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: '20px',
        letterSpacing: 0,
        color: "#4f4f4f",
        paddingTop: '5px',
        paddingBottom: '5px'
    }
}));

export default function SortBar({columnDescriptors, sortedColumn, handleSortRequest}) {
    const styles = useStyles();

    return (
        <div data-testid='SortBar' className={styles.sortBar} onClick={handleSortRequest}>
            <div className={styles.sortIcon}>
                <SortIcon/>
            </div>
            {sortedColumn && sortedColumn.title &&
            <div className={styles.sortBarTitle}>{sortedColumn.title}</div>
            }
        </div>
    );
}