import { FormControl, FormControlLabel } from "@material-ui/core";
import EvvRadio from "../../../common/components/EvvRadio";
import StackedField from "../../../common/components/StackedField";
import DisplayErrorMessages from "../common/DisplayErrorMessages";
import FormField from "./FormField";

const yesOrNoOptions = [
    { value: 'Yes', id: 'yes', dafault: 'Y' },
    { value: 'No', id: 'no', dafault: 'N' }
];

class YesOrNoField extends FormField{
    constructor(props) {
        super(props);
        
        let description = "";
        
        let descObj = this.fieldDescriptor.attributes.find( (attribute) => attribute.attributeId === this.fieldDescriptor.id +"_description" );
        description = descObj?.value;

        let defObj = this.fieldDescriptor.attributes.find( (attribute) => attribute.attributeId === this.fieldDescriptor.id +"_default" );

        if (defObj?.value) {
            this.fieldDescriptor.selectedOption = defObj.value;
        }

        props.fieldDescriptor = this.fieldDescriptor;

        this.state = {
            description: description,
            options: yesOrNoOptions,
            data: props
        };
    }

    isChecked = (dafault) => {
        let isChecked = this.fieldDescriptor?.dbSavedValue === dafault || dafault === this.fieldDescriptor.selectedOption;
        return isChecked ? isChecked : false;
    }

    renderField(props, state) {
        return (
            <div>
                { this.fieldDescriptor?.businessRuleApplied !== "Hide" &&
                <StackedField paddingTop = "0px">
                    <span>{ this.state.description }</span>
                    {this.state.options.map(({ value, id, dafault }, index) => {
                        return (
                            <div key = { index }>
                                <FormControl component = "fieldset">
                                    <FormControlLabel
                                        control = {<EvvRadio
                                            { ...props }
                                            id = { `yes-or-no-${this.fieldDescriptor.id}-${id}` }
                                            name = { `yes-or-no-${this.fieldDescriptor.id}` }
                                            value = { dafault }
                                            checked = { this.isChecked(dafault) }
                                            onChange = { (event) => { props.onChange(event, this.fieldDescriptor, props); } }
                                            disabled = { state.isReadOnlyDoc }
                                        />}
                                        label = { value }
                                    />
                                </FormControl>
                            </div>
                        )
                    })
                    }
                    <div>
                        <DisplayErrorMessages
                            { ...this.state.data }
                        />
                    </div>
                </StackedField>
                }
            </div>
        )
    }
}

export default YesOrNoField;
