export const menuStyle = {
    backgroundColor: "#f8f8f8",
    borderStyle: "solid",
    borderWidth: '1px',
    borderColor: "#979797",
    zIndex: 1200
};
export const SERVICE_NO_DOCUMENTATION_REQUIRED_ID = "1";
export const OFFLINE = 'offLine';
export const PENDING = 'pending';
export const MENU_ITEM_ID_SESSION = 'SESSION';
export const EDIT_DOCUMENT = 'Edit Document';
export const FACESHEET = 'Facesheet';
export const START_SESSION = 'Start Session';
export const STOP_SESSION = 'Stop Session';
export const VIEW_APPOINTMENT = 'View Appointment';
export const HIDE_VISIT_MENU_ITEM_STATUSES = ["Kept", "Co-Staff", "CBC", "CBT", "DNS", "Error"];
export const SELECTION_FORM_ITEMS = ['Gender', 'RadioButton', 'Yes or No', 'CheckBox', 'Descriptor CheckBox', 'DropDown', 'Descriptor DropDown', 'ProgramDropDown', 'StaffDropDown', 'ActiveMedicationDropDown'];
export const ALPHABETS_REGEX = /^[a-zA-Z\r\n ]*$/;    //alphabets
export const NUMBER_REGEX = /^[0-9.\s]*$/;  //number
export const SPECIAL_CHARS_REGEX = /^[a-zA-Z\s/!@#$%&*()><{}?¿=|°"¬¡.,;:_^`~\\[]+$/;    //specialCharacters
export const IS_ARR_OBJ = ['array', 'object'];
export const PHONE_REGEX = /^[0-9\b]+$/ //digits
export const NEW_DOCUMENT = 'New Document';
export const APPOINTMENT_STATUSES_EDIT_NEW_DOC = ["In Session", "Kept", "Co-Staff"];
export const VIEW_DOCUMENT = 'View Document';
export const MAX_OPEN_DOCUMENTS = {
    dialogTitle: 'Max Open Documents',
    dialogMessage: 'You have reached the maximum number of open documents, which is four. Please close one document before attempting to reopen another.',
    showOkButton: true,
    okButtonText: 'Ok',
    showCancelButton: false
};
export const NO_DOCUMENT_REQUIRED = {
    dialogTitle: 'Document Not Required',
    dialogMessage: 'Service Documentation is not required for this activity.',
    showOkButton: true,
    okButtonText: 'Ok',
    showCancelButton: false
};
export const noDocRequired = {
    'id': 1,
    'code': 'NONE',
    'description': 'No Documentation Required',
    'canAddDocumentInErc': true,
    'setupId': ''
};

export const PDF_ONLINE_ERROR = {
    showOkButton: true,
    okButtonText: 'Ok',
    showCancelButton: false
};

export const PDF_OFFLINE_TITLE = "Cannot find PDF";

export const PDF_SERVER_TITLE = "PDF Server Error";

export const PDF_UPDATE_TITLE = "Updating PDF document";

export const PDF_OFFLINE_MSG = "Please log back in when the device has connection and try again";

export const PDF_SERVICE_DOC = {
    'id': 0,
    'code': 'PDF',
    'description': 'Pdf Service Doc',
    'canAddDocumentInErc': false,
    'setupId': ''
};


// State constants for Synchronization processes
export const SYNC_STATE_DIRTY = "dirty";
export const SYNC_STATE_CLEAN = "clean";
export const SYNC_STATE_ERROR = "error";

export const DAY = 'Day';
export const WEEK = 'Week';
export const APPFONT = 'Roboto !important';
export const ACCORDIANFONT = '14px';

export const CONVERTDD = ["ReferralSourceDropDown", "ProgramDropDown", "StaffDropDown"];
export const PRIMARY_COLOR = '#3b276a';
export const TERTIARY_COLOR = '#8B89BF !important';
export const QUATERNARY_COLOR = '#6054a3 !important';
export const QUINARY_COLOR = '#F25729 !important';

export const FILTER_COLOR = 'brightness(0) saturate(100%) invert(15%) sepia(61%) saturate(1584%) hue-rotate(235deg) brightness(93%) contrast(95%)'

export const DISTANCE_MESSAGE_DEFAULT = "The start and/or end visit GPS location is 0.25 or more miles away from the patient address.";
export const DISTANCE_MESSAGE_FOR_NEWJERSEY = "The start and/or end visit GPS location is 300 feet or more away from the patient address.";

//Goals Addressed
export const GANAME = "Goals Addressed";
export const GATYPE = "goaladdressed";
export const GACOMMENTS = "Goals Addressed Comments";
export const GACOMMENTSLIMIT = "Max 4000 characters";
export const TXPLAN_SELECT = "Select Treatment Plan";
export const TXPLANS_NO_ACTIVE_MSG = "No active treatment plans were found on the date of service ";

export const HIDE_VISIT_VERIFICATION_SIGNATURE_STATUSES = ["CBC", "CBT", "DNS", "Error"];
export const ERROR_MESSAGE_FOR_HIDE_VISIT_VERIFICATION_SIGNATURE = "Status is Error, CBT, CBC, or DNS. This visit will not be sent for Visit Verification. Do you want to continue?";
export const EVV_STATE = ["Pennsylvania", "Alaska", "Colorado"];
export const IMPACT = "IMPACT";
export const ALERT_DIALOG_OVERNIGHT = 'overnight';
export const ALERT_DIALOG_OUT_OF_RANGE = 'out_of_range';
export const ALERT_DIALOG_ERROR = 'error';
export const ALERT_DIALOG_OVERLAP = 'overlap';
export const START_DATE_ERROR_MESSAGE = "Start Date cannot be a future date.";
export const START_TIME_ERROR_MESSAGE = "Start Time cannot be a future time.";
export const SKIPSIGNATUREPAGESTATES = ["Pennsylvania"];
export const SHOWSIGNATUREPAGESTATES = ["Georgia"];
export const ERROR_MESSAGE_FOR_OTHER_STATUS = "Are you sure you want to complete the visit?";
export const IMPACTTYPE = "impact";
export const labelForMessages ='Message Board'
export const labelForAllergy ='Allergies'
export const labelForDocument ='Documents'
export const StaffEndpoints = {
    READ_PIN: "/evv/read/staffOfflinePin",
    WRITE_PIN: "/evv/write/staffOfflinePin",
    READ_SIGNATURE: "/evv/read/staffsignature",
    READ_SECURITY_RULE: "/evv/read/qsisecurityrule",
    WRITE_SIGNATURE: "/evv/write/staffsignature"
};
export const unwillingSignatureMessage = 'Selecting this will mark this signature as unable to collect. When all signatures have been captured or marked as unable to collect, this document will be removed from your mobile device during the next sync and send it back to CareLogic Web. Do you continue? ';
export const labelForVitals ='Vitals'
