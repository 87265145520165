import { DateTime } from "luxon";
import { clientCache } from "../../../cache/slices/client/clientSlice";
import { documentCache } from "../../../cache/slices/document/documentCache";
import clientService from "../../../common/services/clientService";
import { pushServiceDocument } from "../../schedule/AppointmentEvent/NewDocumentUtils";
import { APPOINTMENT_STATUSES_EDIT_NEW_DOC } from "../../../common/constants";
import { SERVICE_DOCUMENT_STATUS_COMPLETE, SERVICE_DOCUMENT_STATUS_INCOMPLETE, SERVICE_DOCUMENT_STATUS_SIGNED, SERVICE_DOCUMENT_STATUS_UNSIGNED } from "../../forms/serviceDocumentUtils";
import { tabDocEditAction } from "../../../common/utils/documentUtils";

export const editDocument = (attachedDoc, aptDocument, client, isHandheld, dispatch, history, serviceDocTab) => {
    if (attachedDoc && attachedDoc.length > 0) {
        dispatch(documentCache.setCurrentDocument(aptDocument[0]));
        dispatch(documentCache.setCurrentData({}));
        dispatch(documentCache.setCurrentAptDocument(attachedDoc));
        tabDocEditAction(serviceDocTab, attachedDoc[0], dispatch);
        clientService.visitClient(client, DateTime.now().toMillis());
        dispatch(clientCache.selectClient(client));
        pushServiceDocument(isHandheld, attachedDoc[0], history);
        dispatch(documentCache.setCrosswalkedDocuments(aptDocument));
    }
}

export const showEditOptionCheck = (event, attachedDoc) => {
    return APPOINTMENT_STATUSES_EDIT_NEW_DOC.includes(event.status) && hasEditDoc(attachedDoc);
}

export const hasEditDoc = (attachedDoc) => {
    const editDocStatusArr = [SERVICE_DOCUMENT_STATUS_INCOMPLETE, SERVICE_DOCUMENT_STATUS_COMPLETE, SERVICE_DOCUMENT_STATUS_UNSIGNED];
    if (attachedDoc && attachedDoc.length > 0 && editDocStatusArr.includes(attachedDoc[0].documentStatus)) {
       return true
    }
    return false;
}

export const showNewOptionCheck = (appointment, filterDocument, attachedDoc) => {
    return ((appointment?.hadDocument === "N") && (filterDocument && filterDocument.length >= 1) && (APPOINTMENT_STATUSES_EDIT_NEW_DOC.includes(appointment.status)) && attachedDoc.length === 0 ) ;
}

export const showViewOptionCheck = (appointment, attachedDoc) => {
    return ( APPOINTMENT_STATUSES_EDIT_NEW_DOC.includes(appointment.status) && hasPdfDoc(attachedDoc) );
}

export const hasPdfDoc = (attachedDoc) => {
    return (attachedDoc.length > 0 && (attachedDoc[0]?.documentStatus === SERVICE_DOCUMENT_STATUS_SIGNED || !attachedDoc[0]?.documentStatus) );
}

export const tabChangeSetting = (document, serviceDoc, dispatch) => {
    dispatch(documentCache.setCrosswalkedDocuments(serviceDoc));
    dispatch(documentCache.setCurrentDocument(serviceDoc[0]));
    dispatch(documentCache.setCurrentData({}));
    dispatch(documentCache.setCurrentAptDocument([document]));
}