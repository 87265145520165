import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { AuthProvider } from 'react-oidc-context';

export default function OIDCProvider({ children }) {

    const [oidcConfig, setOidcConfig] = useState(null);

    useEffect(() => {
        const loadConfig = async () => {
            try {
                const response = await axios.get('/config/oidc/oidc-config.json');
                setOidcConfig(response.data);
            } catch (error) {
                console.error('Error loading provider config:', error);
            }
        };

        loadConfig();
    }, []);

    return (
        oidcConfig ? (
            <AuthProvider {...oidcConfig}>
                {children}
            </AuthProvider>
        ) : (
            <div>Loading configurations...</div>
        )
    );

};