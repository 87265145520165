import {makeStyles} from "@material-ui/core/styles";
import {formatUserName, getFormattedAppointmentDuration, isToday} from "../../../common/utils/formatUtils";
import {useEffect, useRef, useState} from "react";
import {DateTime} from "luxon";
import {IconButton, Menu, MenuItem} from "@material-ui/core";
import VisitStopwatch from "../../facesheet/VisitStopwatch";
import {useDispatch, useSelector, useStore} from "react-redux";
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import {clientCache} from "../../../cache/slices/client/clientSlice";
import {useHistory} from "react-router-dom";
import clientService from "../../../common/services/clientService";
import AppointmentDialog from "../../facesheet/AppointmentDialog";
import {documentNotRequired, isActiveSession, isAppointmentEditable} from "../../../common/utils/appointmentUtils";
import ViewAppointmentDialog from "../../facesheet/ViewAppointmentDialog";
import syncService from "../../../common/services/syncService";
import { evvRepository } from "../../../db/evv";
import { isSessionEnded } from "../../../common/utils/visitUtils";
import AlertDialog from "../../../common/components/AlertDialog";
import { appCache } from "../../../cache/slices/app/appSlice";
import {menuStyle, 
    MENU_ITEM_ID_SESSION, 
    EDIT_DOCUMENT, 
    FACESHEET,
    START_SESSION,
    STOP_SESSION,
    VIEW_APPOINTMENT,
    HIDE_VISIT_MENU_ITEM_STATUSES,
    NEW_DOCUMENT,
    VIEW_DOCUMENT,
    MAX_OPEN_DOCUMENTS,
    NO_DOCUMENT_REQUIRED,
    PDF_ONLINE_ERROR,
    PDF_SERVICE_DOC,
    PRIMARY_COLOR
} from "../../../common/constants";
import { editDocument, showEditOptionCheck, showViewOptionCheck, showNewOptionCheck } from "./EditDocumentUtils";
import { filterDocumentCrosswalksMissing, hasAttachedDoc } from "../../forms/common/IncompleteTasksUtils";
import { pushServiceDocument } from "../../schedule/AppointmentEvent/NewDocumentUtils";
import { checkIfDocExistsInTab, chekDocPdf, createNewDocument, handleSaveNoDocument, hasMaxDocsOpened, tabDocAddAction } from "../../../common/utils/documentUtils";
import DocumentChooserDialog from "../../forms/DocumentChooserDialog";
import { cloneDeep } from "lodash/lang";
import { documentCache } from "../../../cache/slices/document/documentCache";

const wrapText = {
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
    wordWrap: 'initial',
    lineHeight: 'initial',
    fontSize: '13px',
    fontStyle: "normal",
    letterSpacing: 0,
    color: "#ffffff"
};

const useStyles = makeStyles((theme) => ({
    appointmentCard: {
        width: '100%',
        backgroundColor: "#ffffff",
        display: 'flex',
        justifyContent: 'space-between',
        "&:not(:last-child)": {
            borderBottom: '1px solid #d0d0d0'
        },
        [theme.breakpoints.down('sm')]: {
            paddingTop: '14px',
            paddingBottom: '14px',
        },
        [theme.breakpoints.up(601)]: {
            paddingTop: '10px',
            paddingBottom: '10px',
        }
    },
    leftContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    rightContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    clientName: {
        fontSize: '16px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: PRIMARY_COLOR,
        paddingBottom: '4px'
    },
    status: {
        display: 'flex'
    },
    statusLabel: {
        fontSize: '16px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: "#4f4f4f",
        paddingRight: '5px'
    },
    statusValue: {
        fontSize: '16px',
        fontWeight: "normal",
        fontStyle: "normal",
        letterSpacing: 0,
        color: "#4f4f4f"
    },
    startTime: {
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        textAlign: "right",
        color: "#4f4f4f",
        paddingBottom: '4px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
        },
        [theme.breakpoints.up(601)]: {
            fontSize: '16px',
        }
    },
    duration: {
        fontSize: '16px',
        fontWeight: "normal",
        fontStyle: "normal",
        letterSpacing: 0,
        textAlign: "right",
        color: "#4f4f4f",
    },

    appointmentEvent: {
        width: '100%',
        height: '100%',
        display: 'flex'
    },
    appointmentColumn: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1
    },
    appointmentTitle: {
        ...wrapText,
        fontWeight: "bold",
        paddingBottom: '3px'
    },
    appointmentTitleNowrap: {
        ...wrapText,
        fontWeight: "bold"
    },
    appointmentActivity: {
        ...wrapText,
        fontWeight: "normal"
    },
    appointmentVisit: {
        display: 'none'
    },
    menuColumn: {
        width: '24px',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    iconButton: {
        padding: '0px'
    },
    menuItemRoot: {
        padding: '0px',
        marginLeft: '16px',
        marginRight: '16px',
        "&:not(:last-child)": {
            borderBottom: '2px solid #979797'
        },
    },
    menuItem: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        paddingTop: '14px',
        paddingBottom: '14px',
    },
    imageContainer: {
        width: '24px',
        height: '24px'
    },
    image: {
        height: '100%',
        width: '100%'
    },
    menuItemLabel: {
        fontSize: '14px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: -0.06,
        color: "#434041",
        paddingLeft: '18px'
    }
}));

export default function AppointmentCard({appointment, call = ''}) {
    const [alertDialogConfig, setAlertDialogConfig] = useState(null);
    const eventStartDate = DateTime.fromJSDate(appointment.start);
    const visits = useSelector(syncService.visitService.getResults());
    const [showViewAppointmentDialog, setShowViewAppointmentDialog] = useState(false);
    const [showEditAppointmentDialog, setShowEditAppointmentDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useDispatch();
    const history = useHistory();
    const styles = useStyles();
    const stopWatchContext = useRef({stopwatchActive: false});
    const [visitCollection, setVisitCollection] = useState(null);
    const activeSession = isActiveSession(appointment,visitCollection);
    const serviceDocuments = useSelector(syncService.serviceDocumentService.getResults());
    const isHandheld = useSelector(appCache.isHandheld)

    const clients = useSelector(appCache.getClients);
    const [client, setClient] = useState({});
	const serviceDocTab = cloneDeep(useSelector(documentCache.getServiceDocumentsForTabs));
    const documentCrosswalks = useSelector(syncService.documentCrosswalkService.getResults());
    const organizations = useSelector(syncService.organizationService.getResults());
    const [filterDocument, setfilterDocument] = useState([]);
    const [showDocumentChooser, setShowDocumentChooser] = useState(false);
    const [attachedDoc, setAttachedDoc] = useState([]);
    let newDocument = {};
    const store = useStore();
    const user = useSelector(appCache.getUser);
    const staff = user.staff;
    const [contextMenu, setContextMenu] = useState(null);
    let changedPortrait = window.matchMedia("(orientation: portrait)");
    let orientation = changedPortrait.matches ? "portrait" : "landscape";
    const currentDocumentId = useSelector(documentCache.getCurrentDocumentId);
    const organizationServiceStatus = useSelector(syncService.organizationService.getStatus());
    const [clientData, setClientData] = useState([]);

    useEffect(() => {
        if(clients && clients.length > 0 && appointment) {
            const objClient = clients.find(c => Number(c.clientId) === Number(appointment.clientId));
            setClient(objClient);
         }
         // eslint-disable-next-line
    }, [clients]);

    useEffect(() => {
        if (organizationServiceStatus === 2) {
            fetchInitialData();
        }
        // eslint-disable-next-line
    }, [organizations, documentCrosswalks, serviceDocuments, organizationServiceStatus]);
   
    useEffect(() => {
        if(currentDocumentId && organizationServiceStatus === 2){
            fetchInitialData();
        }
            // eslint-disable-next-line
        }, [currentDocumentId, organizationServiceStatus]);

    const fetchInitialData = (()=>{
        fetchFilterData();
        hasAttachedDocument(appointment);
    })

    const fetchFilterData = async() => {
        let filterData = await filterDocumentCrosswalksMissing(appointment, documentCrosswalks, serviceDocuments, staff);
        if (filterData && filterData.length > 0) {
            setfilterDocument(filterData);
        }
        return filterData;
    }

    const hasAttachedDocument = async(event) => {
        await hasAttachedDoc(event).then((doc) => {
            if (doc && doc.length > 0) {
                setAttachedDoc(doc);
            }
        }).catch((error) => {
            console.log('Error: ', error);
        });
    }

    const getVisitCollection = async () =>{
        return evvRepository.evvDb.visit.toArray();
    }

    const getVisits = async() => {
        await getVisitCollection().then((result) => {
            setVisitCollection(result);
        }).catch((error) => {
            console.log('Error: ', error);
        });
    }

    const openAppointmentDialog = () => {
        getVisits();
        clientService.fetchAddresses({store}, appCache.getClients(store.getState()), setClientData);
        if (isAppointmentEditable(appointment, visitCollection)) {
            setShowEditAppointmentDialog(true);
        } else {
            setShowViewAppointmentDialog(true);
        }
    }

    const handleAppointmentDialogClose = () => {
        setShowEditAppointmentDialog(false);
    }

    const handleViewAppointmentDialogClose = () => {
        setShowViewAppointmentDialog(false);
    }

    const showContextMenu = (event) => {
        let xValue = (isHandheld && (orientation === "landscape")) ? (event.clientY / 2) : event.clientX ;
        let yValue = (isHandheld && (orientation === "landscape")) ? event.clientX : event.clientY;
        setContextMenu(
            {
                mouseX: xValue,
                mouseY: yValue,
            }
        );
        getVisits();
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const closeContextMenu = () => {
        setAnchorEl(null);
    };

    const processPdfDoc = async (savedDocument) => {
        let docPdfStatus = await chekDocPdf(savedDocument, setAttachedDoc);

        if (docPdfStatus.error) {
            let pdfErrorDialogContent = {...PDF_ONLINE_ERROR, dialogMessage: docPdfStatus.errorMsg, dialogTitle: docPdfStatus.errorTitle};
            setAlertDialogConfig(pdfErrorDialogContent);
        } else {
            editDocument(attachedDoc, [PDF_SERVICE_DOC], appointment.client, isHandheld, dispatch, history, serviceDocTab);
        }
    }

    const handleEditViewDocument = async () => {
    	if (!checkIfDocExistsInTab(serviceDocTab, attachedDoc[0]) && hasMaxDocsOpened(serviceDocTab, appointment.clientId)) {
            setAlertDialogConfig(MAX_OPEN_DOCUMENTS);
        } else if (attachedDoc && attachedDoc.length > 0) {
            if (!attachedDoc[0]?.documentStatus) {
                //PDF Document
                await processPdfDoc(attachedDoc[0]);
            } else {
                let serviceDoc = serviceDocuments.filter(doc => doc.setupId === attachedDoc[0].serviceDocumentId);
                editDocument(attachedDoc, serviceDoc, appointment.client, isHandheld, dispatch, history, serviceDocTab);
            }
        }
    }

    const handleNewDocument = async() => {
        let filterData = await fetchFilterData();

    	if (hasMaxDocsOpened(serviceDocTab, appointment.clientId)) {
            setAlertDialogConfig(MAX_OPEN_DOCUMENTS);
        } else {
	        if (filterData && filterData.length > 0) {
                if(filterData.length === 1) {
                    chooseServiceDocument(filterData[0] );
                } else {
                    setShowDocumentChooser(true);
                }
            }
        }
    }

    const chooseServiceDocument = (serviceDocument) => {
        setShowDocumentChooser(false);
        if (serviceDocument) {
            if (documentNotRequired(serviceDocument)) {
                handleSaveNoDocument(serviceDocument, appointment.client, staff, appointment, store, dispatch);
                setAlertDialogConfig(NO_DOCUMENT_REQUIRED);
            } else {
                dispatch(documentCache.setCrosswalkedDocuments([serviceDocument]));
                dispatch(documentCache.setCurrentDocument(serviceDocument));
                dispatch(documentCache.setCurrentData({}));
                handleSaveNewDocument(serviceDocument);
                clientService.visitClient(appointment.client, DateTime.now().toMillis());
                dispatch(clientCache.selectClient(appointment.client));
                pushServiceDocument(isHandheld, newDocument, history ) 
            }
        }
    }

    const handleSaveNewDocument = (document) => {
        newDocument = createNewDocument(document, appointment?.client, staff, appointment);
        syncService.documentService.saveNewDocument(newDocument, store, dispatch);
        dispatch(documentCache.setCurrentAptDocument([newDocument]));
        tabDocAddAction(serviceDocTab, newDocument, dispatch);
    }
    const handleFacesheet = () => {
        clientService.visitClient(client, DateTime.now().toMillis());
        dispatch(clientCache.selectClient(client));
        history.push('/dashboard/facesheet');
    }

    const handleToggleSession = () => {
        success(stopWatchContext);
    }

    const success = (context) => {
        if (context.current.toggleSession){
            context.current.toggleSession();
        }
    }

    const handleAlertDialogClose = () => {
        setAlertDialogConfig(null);
    }

    const handleViewAppointment = () => {
        openAppointmentDialog();
    }

    const menuItems = [
        {
            id: 'EDIT_DOCUMENT',
            label: EDIT_DOCUMENT,
            image: `${process.env.PUBLIC_URL}/images/iconsFlyoutDocumentV15.svg`,
            clickHandler: handleEditViewDocument

        },
        {
            id: 'NEW_DOCUMENT',
            label: NEW_DOCUMENT,
            image: `${process.env.PUBLIC_URL}/images/iconsFlyoutDocumentV15.svg`,
            clickHandler: handleNewDocument
        },
        {
            id: 'VIEW_DOCUMENT',
            label: VIEW_DOCUMENT,
            image: `${process.env.PUBLIC_URL}/images/iconsFlyoutDocumentV15.svg`,
            clickHandler: handleEditViewDocument
        },
        {
            id: 'FACESHEET',
            label: FACESHEET,
            image: `${process.env.PUBLIC_URL}/images/iconsFlyoutFacesheetV15.svg`,
            clickHandler: handleFacesheet
        },
        {
            id: MENU_ITEM_ID_SESSION,
            image: PlayCircleOutlineIcon,
        },
        {
            id: 'VIEW_APPOINTMENT',
            label: VIEW_APPOINTMENT,
            image: `${process.env.PUBLIC_URL}/images/iconsFlyoutDocumentV15.svg`,
            clickHandler: handleViewAppointment
        }
    ];

    const renderSessionToggleComponent = (stopwatchActive, toggleSession) => {
        stopWatchContext.current.stopwatchActive = stopwatchActive;
        stopWatchContext.current.toggleSession = toggleSession;

        return (
            <div onClick={toggleSession}>
                <IconButton className={styles.image} >
                    <PlayCircleOutlineIcon htmlColor='#000000' />
                </IconButton>
            </div>
        );
    }

    const renderMenuItem = (menuItem) => {
        if (menuItem.id === "EDIT_DOCUMENT") {
            const showEditOption = showEditOptionCheck(appointment, attachedDoc);
            if (!showEditOption) {
                return;
            }
        } else if (menuItem.id === "NEW_DOCUMENT") {
            const showNewOption = showNewOptionCheck(appointment, filterDocument, attachedDoc);
            if (!showNewOption) {
                return;
            }
        } else if (menuItem.id === "VIEW_DOCUMENT") {
            const showViewOption = showViewOptionCheck(appointment, attachedDoc);
            if (!showViewOption) {
                return;
            }
        }

        if (call === "FacesheetPage" && menuItem.id === "FACESHEET") {
            return;
        }
        const menuItemContent = renderMenuItemContent(menuItem);

        return menuItemContent
            ? (
                <MenuItem key={menuItem.id} className={styles.menuItemRoot} onClick={closeContextMenu}>
                    {menuItemContent}
                </MenuItem>
            )
            : null;
    };

    const showVisitMenuItem = () => {
        let showVisitMenuItemEle = false;

        if (isToday(eventStartDate)){
            showVisitMenuItemEle = HIDE_VISIT_MENU_ITEM_STATUSES.indexOf(appointment.status) === -1;
        }

        if(isSessionEnded(appointment, visitCollection)) {
            showVisitMenuItemEle = false;
        }
        return showVisitMenuItemEle;
    }

    const renderMenuItemContent = (menuItem) => {
        if (menuItem.id === MENU_ITEM_ID_SESSION){
            if (showVisitMenuItem()) {
                return (
                    <div className={styles.menuItem} onClick={handleToggleSession}>
                        <div className={styles.imageContainer}>
                            <IconButton className={styles.image}>
                                <PlayCircleOutlineIcon htmlColor='#000000'/>
                            </IconButton>
                        </div>
                        <div className={styles.menuItemLabel}>
                            {activeSession ? STOP_SESSION : START_SESSION}
                        </div>
                    </div>
                );
            }
        } else {
            return (
                <div className={styles.menuItem} onClick={menuItem.clickHandler ? menuItem.clickHandler : () => console.log(menuItem.label)}>
                    <div className={styles.imageContainer}>
                        <img className={styles.image} src={menuItem.image} alt={menuItem.label} />
                    </div>
                    <div className={styles.menuItemLabel}>{menuItem.label}</div>
                </div>
            );
        }

        return null;
    };

    return (
        <>
            {client && client.clientId ?
                <div>
                    <div data-testid='AppointmentCard' className={styles.appointmentCard} onClick={showContextMenu}>
                        <div className={styles.leftContainer}>
                            <div className={styles.clientName}>
                                {formatUserName(client)}
                            </div>
                            <div className={styles.status}>
                                <div className={styles.statusLabel}>Status:</div>
                                <div className={styles.statusValue}>{appointment.status}</div>
                            </div>
                        </div>
                        <div className={styles.rightContainer}>
                            <div className={styles.startTime}>
                                {appointment.startTime}
                            </div>
                            <div className={styles.duration}>
                                {getFormattedAppointmentDuration(appointment)}
                            </div>
                        </div>

                        {anchorEl &&
                            <Menu
                                id="AppointmentCardMenu"
                                anchorEl={anchorEl}
                                keepMounted={false}
                                open={true}
                                onClose={closeContextMenu}
                                anchorReference="anchorPosition"
                                anchorPosition={{ top: contextMenu.mouseY, left: contextMenu.mouseX }}
                                PaperProps={{
                                    style: menuStyle
                                }}
                            >
                                {menuItems.map((menuItem) => renderMenuItem(menuItem))}
                            </Menu>
                        }
                    </div>

                    {showVisitMenuItem() &&
                        <div className={styles.appointmentVisit}>
                            <VisitStopwatch
                                embedded={true}
                                hideDuration={true}
                                durationSize='tiny'
                                client={appointment.client}
                                visits={visits}
                                appointment={appointment}
                                renderSessionToggleComponent={renderSessionToggleComponent}
                            />
                        </div>
                    }

                    {showEditAppointmentDialog &&
                        <AppointmentDialog
                            onClose={handleAppointmentDialogClose}
                            appointment={{ ...appointment }}
                            showEditAppointmentDialog = {showEditAppointmentDialog}
                            clientData = {clientData}
                        />
                    }

                    {showViewAppointmentDialog &&
                        <ViewAppointmentDialog
                            onClose={handleViewAppointmentDialogClose}
                            appointment={{ ...appointment }}
                            stopwatchActive={stopWatchContext.current.stopwatchActive}
                            visitCollection={visitCollection}
                        />
                    }

                    {alertDialogConfig &&
                        <AlertDialog
                            open={true}
                            dialogTitle={alertDialogConfig.dialogTitle}
                            dialogMessage={alertDialogConfig.dialogMessage}
                            showOkButton={alertDialogConfig.showOkButton}
                            showCancelButton={alertDialogConfig.showCancelButton}
                            okButtonText={alertDialogConfig.okButtonText ? alertDialogConfig.okButtonText : 'Ok'}
                            handleClose={handleAlertDialogClose}
                        />
                    }
                    {showDocumentChooser && filterDocument.length > 1 &&
                        <DocumentChooserDialog documents={filterDocument} onClose={chooseServiceDocument} />
                    }
                </div>
                : ''
            }
        </>
    )
}