import {useEffect, useRef, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {DateTime, Interval} from "luxon";
import {IconButton, Menu, MenuItem} from "@material-ui/core";
import VisitStopwatch from "../../facesheet/VisitStopwatch";
import {useDispatch, useSelector, useStore} from "react-redux";
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import {DEFAULT_TIME_FORMAT, isToday} from "../../../common/utils/formatUtils";
import {clientCache} from "../../../cache/slices/client/clientSlice";
import {useHistory} from "react-router-dom";
import clientService from "../../../common/services/clientService";
import AppointmentDialog from "../../facesheet/AppointmentDialog";
import {documentNotRequired, isActiveSession, isAppointmentEditable, isNonMobileActivity} from "../../../common/utils/appointmentUtils";
import ViewAppointmentDialog from "../../facesheet/ViewAppointmentDialog";
import syncService from "../../../common/services/syncService";
import {appCache} from "../../../cache/slices/app/appSlice";
import { evvRepository } from "../../../db/evv";
import { isSessionEnded } from "../../../common/utils/visitUtils";
import AlertDialog from "../../../common/components/AlertDialog";
import {documentCache} from "../../../cache/slices/document/documentCache";
import {scheduleCache} from "../../../cache/slices/schedule/scheduleCache";
import {menuStyle, 
    MENU_ITEM_ID_SESSION, 
    EDIT_DOCUMENT, 
    FACESHEET,
    START_SESSION,
    STOP_SESSION,
    VIEW_APPOINTMENT,
    HIDE_VISIT_MENU_ITEM_STATUSES,
    NEW_DOCUMENT,
    VIEW_DOCUMENT,
	MAX_OPEN_DOCUMENTS,
    NO_DOCUMENT_REQUIRED,
    PDF_ONLINE_ERROR,
    PDF_SERVICE_DOC,
    WEEK
} from "../../../common/constants";
import { filterDocumentCrosswalksMissing, hasAttachedDoc} from "../../forms/common/IncompleteTasksUtils";
import DocumentChooserDialog from "../../forms/DocumentChooserDialog";
import { pushServiceDocument } from "./NewDocumentUtils";
import { createNewDocument, hasMaxDocsOpened, checkIfDocExistsInTab, tabDocAddAction, handleSaveNoDocument, chekDocPdf } from "../../../common/utils/documentUtils";
import { showEditOptionCheck, showNewOptionCheck, showViewOptionCheck, editDocument } from "../../dashboard/AppointmentCard/EditDocumentUtils";
import { cloneDeep } from "lodash/lang";
import { ASYNC_OPERATION_STATUS } from "../../../cache/asyncHandler";

const wrapText = {
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
    wordWrap: 'initial',
    lineHeight: 'initial',
    fontSize: '13px',
    fontStyle: "normal",
    letterSpacing: 0,
    color: "#ffffff",
    overflowY: 'hidden',
};

const useStyles = makeStyles(() => ({
    appointmentEvent: {
        width: '100%',
        height: '100%',
        display: 'flex'
    },
    appointmentColumn: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        textOverflow:'ellipsis',
        overflow: 'hidden',
        whiteSpace:'nowrap'
    },
    appointmentTitle: {
        ...wrapText,
        fontWeight: "bold",
        paddingBottom: '3px'
    },
    appointmentTitleNowrap: {
        ...wrapText,
        fontWeight: "bold"
    },

    appointmentTitleNowrapForNonMobile: {
        ...wrapText,
        fontWeight: "bold",
        color: "#000000",
    },
    appointmentTitleForNonMobile: {
        ...wrapText,
        fontWeight: "bold",
        paddingBottom: '3px',
        color: "#000000",
    },
    appointmentActivity: {
        ...wrapText,
        fontWeight: "normal"
    },
    appointmentNonMobileActivity: {
        ...wrapText,
        color: "#000000",
    },
    appointmentVisit: {
        display: 'none'
    },
    menuColumn: {
        width: '24px',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    iconButton: {
        padding: '0px'
    },
    menuItemRoot: {
        padding: '0px',
        marginLeft: '16px',
        marginRight: '16px',
        "&:not(:last-child)": {
            borderBottom: '2px solid #979797'
        },
    },
    menuItem: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        paddingTop: '14px',
        paddingBottom: '14px',
    },
    imageContainer: {
        width: '24px',
        height: '24px'
    },
    image: {
        height: '100%',
        width: '100%'
    },
    menuItemLabel: {
        fontSize: '14px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: -0.06,
        color: "#434041",
        paddingLeft: '18px'
    }
}));

export default function AppointmentEvent({event, title}) {
    const activities = useSelector(syncService.activityService.getResults());
    const [activity, setActivity] = useState({});
    
    const serviceDocuments = useSelector(syncService.serviceDocumentService.getResults());
    
    const [alertDialogConfig, setAlertDialogConfig] = useState(null);
    const eventStartDate = DateTime.fromJSDate(event.start);
    const eventEndDate = DateTime.fromJSDate(event.end);
    const eventInterval = Interval.fromDateTimes(eventStartDate, eventEndDate);
    const visits = useSelector(syncService.visitService.getResults());
    const isHandheld = useSelector(appCache.isHandheld)
    const [showViewAppointmentDialog, setShowViewAppointmentDialog] = useState(false);
    const [showEditAppointmentDialog, setShowEditAppointmentDialog] = useState(false);
    const [eventCount, setEventCount] = useState(1);
    const [visitCollection, setVisitCollection] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useDispatch();
    const history = useHistory();
    const styles = useStyles();

    const appointmentEventElement = useRef(null);
    const stopWatchContext = useRef({stopwatchActive: false});
    const activeSession = isActiveSession(event,visitCollection);
    const documentCrosswalks = useSelector(syncService.documentCrosswalkService.getResults());
    const organizations = useSelector(syncService.organizationService.getResults());
    const [showDocumentChooser, setShowDocumentChooser] = useState(false);
    const [filterDocument, setfilterDocument] = useState([]);
    const [contextMenu, setContextMenu] = useState(null);
    let changedPortrait = window.matchMedia("(orientation: portrait)");
    let orientation = changedPortrait.matches ? "portrait" : "landscape";
    const [attachedDoc, setAttachedDoc] = useState([]);
    const store = useStore();
    const user = useSelector(appCache.getUser);
    const staff = user.staff;
    let newDocument = {};
	const serviceDocTab = cloneDeep(useSelector(documentCache.getServiceDocumentsForTabs));
    const currentDocumentId = useSelector(documentCache.getCurrentDocumentId);
    const calendarView = useSelector(scheduleCache.getCalendarView);
    const organizationServiceStatus = useSelector(syncService.organizationService.getStatus());
    const [clientData, setClientData] = useState([]);

    useEffect(() => {
                if(activities && activities.length > 0 ) {
                    const objActivity = activities.find(a => a.activityId === event.activityId);
                    setActivity(objActivity);
                }
                // eslint-disable-next-line
            }, [activities]);

    useEffect(() => {
        if (organizationServiceStatus === 2) {
            fetchInitialData();
        }
        // eslint-disable-next-line
    }, [organizations, documentCrosswalks, serviceDocuments, organizationServiceStatus, event]);

    useEffect(() => {
    if(currentDocumentId && organizationServiceStatus === ASYNC_OPERATION_STATUS.SUCCESS){
        fetchInitialData();
    }
        // eslint-disable-next-line
    }, [currentDocumentId, organizationServiceStatus]);

    const fetchInitialData = (()=>{
        fetchFilterData();
        hasAttachedDocument(event);
    })

    const fetchFilterData = async() => {
        let filterData = await filterDocumentCrosswalksMissing(event, documentCrosswalks, serviceDocuments, staff);
        if (filterData && filterData.length > 0) {
            setfilterDocument(filterData);
        }
        return filterData;
    }

    const hasAttachedDocument = async(event) => {
        await hasAttachedDoc(event).then((doc) => {
            if (doc && doc.length > 0) {
                setAttachedDoc(doc);
            }
        }).catch((error) => {
            console.log('Error: ', error);
        });
    }

     /* eslint-disable react-hooks/exhaustive-deps */
    const getVisitCollection = async () =>{
        return evvRepository.evvDb.visit.toArray();
    }

    const getVisits = async() => {
        await getVisitCollection().then((result) => {
            setVisitCollection(result);
        }).catch((error) => {
            console.log('Error: ', error);
        });
    }

    const openAppointmentDialog = () => {
        getVisits();
        clientService.fetchAddresses({store}, appCache.getClients(store.getState()), setClientData);
        if (isAppointmentEditable(event, visitCollection)) {
            setShowEditAppointmentDialog(true);
        } else {
            setShowViewAppointmentDialog(true);
        }
    }

    const handleAppointmentDialogClose = () => {
        setShowEditAppointmentDialog(false);
    }

    const handleViewAppointmentDialogClose = () => {
        setShowViewAppointmentDialog(false);
    }

    const showContextMenu = (event) => {
        let xValue = (isHandheld && (orientation === "landscape")) ? (event.clientY / 2) : event.clientX ;
        let yValue = (isHandheld && (orientation === "landscape")) ? event.clientX : event.clientY;
        setContextMenu(
            {
                mouseX: xValue,
                mouseY: yValue,
            }
        );
        getVisits();
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const closeContextMenu = () => {
        setAnchorEl(null);
    };

    const processPdfDoc = async (savedDocument) => {
        let docPdfStatus = await chekDocPdf(savedDocument, setAttachedDoc);

        if (docPdfStatus.error) {
            let pdfErrorDialogContent = {...PDF_ONLINE_ERROR, dialogMessage: docPdfStatus.errorMsg, dialogTitle: docPdfStatus.errorTitle};
            setAlertDialogConfig(pdfErrorDialogContent);
        } else {
            editDocument(attachedDoc, [PDF_SERVICE_DOC], event.client, isHandheld, dispatch, history, serviceDocTab);
        }
    }

    const handleEditViewDocument = async () => {
    	if (!checkIfDocExistsInTab(serviceDocTab, attachedDoc[0]) && hasMaxDocsOpened(serviceDocTab, event.client.clientId)) {
            setAlertDialogConfig(MAX_OPEN_DOCUMENTS);
        } else if (attachedDoc && attachedDoc.length > 0) {
            if (!attachedDoc[0]?.documentStatus) {
                //PDF Document
                await processPdfDoc(attachedDoc[0]);
            } else {
                let serviceDoc = serviceDocuments.filter(doc => doc.setupId === attachedDoc[0].serviceDocumentId);
                editDocument(attachedDoc, serviceDoc, event.client, isHandheld, dispatch, history, serviceDocTab);
            }
        }
    }

    const handleNewDocument = async() => {
        let filterData = await fetchFilterData();
    	if (hasMaxDocsOpened(serviceDocTab, event.client.clientId)) {
            setAlertDialogConfig(MAX_OPEN_DOCUMENTS);
        } else {
	        if (filterData && filterData.length > 0) {
                if(filterData.length === 1) {
                    chooseServiceDocument(filterData[0] );
                } else {
                    setShowDocumentChooser(true);
                }
            }
        }
    }

    const chooseServiceDocument = (serviceDocument) => {
        setShowDocumentChooser(false);
        if (serviceDocument) {
            if (documentNotRequired(serviceDocument)) {
                handleSaveNoDocument(serviceDocument, event.client, staff, event, store, dispatch);
                setAlertDialogConfig(NO_DOCUMENT_REQUIRED);
            } else {
                dispatch(documentCache.setCrosswalkedDocuments([serviceDocument]));
                dispatch(documentCache.setCurrentDocument(serviceDocument));
                dispatch(documentCache.setCurrentData({}));
                handleSaveNewDocument(serviceDocument);
                clientService.visitClient(event.client, DateTime.now().toMillis());
                dispatch(clientCache.selectClient(event.client));
                pushServiceDocument(isHandheld, newDocument, history )
            } 
        }
    }

    const handleSaveNewDocument = (document) => {
        newDocument = createNewDocument(document, event?.client, staff, event);
        syncService.documentService.saveNewDocument(newDocument, store, dispatch);
        let serviceDocs = [...serviceDocTab, newDocument];
        dispatch(documentCache.setServiceDocumentsForTabs(serviceDocs));
        dispatch(documentCache.setCurrentAptDocument([newDocument]));
        tabDocAddAction(serviceDocTab, newDocument, dispatch);
    }

    const handleFacesheet = () => {
        clientService.visitClient(event.client, DateTime.now().toMillis());
        dispatch(clientCache.selectClient(event.client));
        history.push('/dashboard/facesheet');
    }

    const handleEventToggle = () => {
        successEvent(stopWatchContext);
    }

    const successEvent = (context) => {
        if (context.current.toggleSession){
            context.current.toggleSession();
        }
    }

    const handleAlertDialogClose = () => {
        setAlertDialogConfig(null);
    }

    const handleViewAppointment = () => {
        openAppointmentDialog();
    }

    const menuItems = [
        {
            id: 'EDIT_DOCUMENT',
            label: EDIT_DOCUMENT,
            image: `${process.env.PUBLIC_URL}/images/iconsFlyoutDocumentV15.svg`,
            clickHandler: handleEditViewDocument
        },
        {
            id: 'NEW_DOCUMENT',
            label: NEW_DOCUMENT,
            image: `${process.env.PUBLIC_URL}/images/iconsFlyoutDocumentV15.svg`,
            clickHandler: handleNewDocument
        },
        {
            id: 'VIEW_DOCUMENT',
            label: VIEW_DOCUMENT,
            image: `${process.env.PUBLIC_URL}/images/iconsFlyoutDocumentV15.svg`,
            clickHandler: handleEditViewDocument
        },
        {
            id: 'FACESHEET',
            label: FACESHEET,
            image: `${process.env.PUBLIC_URL}/images/iconsFlyoutFacesheetV15.svg`,
            clickHandler: handleFacesheet
        },
        {
            id: MENU_ITEM_ID_SESSION,
            image: PlayCircleOutlineIcon,
        },
        {
            id: 'VIEW_APPOINTMENT',
            label: VIEW_APPOINTMENT,
            image: `${process.env.PUBLIC_URL}/images/iconsFlyoutDocumentV15.svg`,
            clickHandler: handleViewAppointment
        }
    ];

    const renderSessionToggleComponent = (stopwatchActive, toggleSession) => {
        stopWatchContext.current.stopwatchActive = stopwatchActive;
        stopWatchContext.current.toggleSession = toggleSession;

        return (
            <div onClick={toggleSession}>
                <IconButton className={styles.image} >
                    <PlayCircleOutlineIcon htmlColor='#000000' />
                </IconButton>
            </div>
        );
    }
    
    const renderMenuItem = (menuItem) => {
        if (menuItem.id === "EDIT_DOCUMENT") {
            const showEditOption = showEditOptionCheck(event, attachedDoc);
            if (!showEditOption) {
                return;
            } 
        } 
        else if (menuItem.id === "NEW_DOCUMENT") {
            const showNewOption = showNewOptionCheck(event, filterDocument, attachedDoc);
            if (!showNewOption) {
                return;
            } 
        }
        else if (menuItem.id === "VIEW_DOCUMENT") {
            const showViewOption = showViewOptionCheck(event, attachedDoc);
            if (!showViewOption) {
                return;
            }
        }

        const menuItemContent = renderMenuItemContent(menuItem);

        return menuItemContent
            ? (
                <MenuItem key={menuItem.id} className={styles.menuItemRoot} onClick={closeContextMenu}>
                    {menuItemContent}
                </MenuItem>
            )
            : null;
    };

    const showVisitMenuItem = () => {
        let showVisitMenuItem = false;

        if (isToday(eventStartDate)){
            showVisitMenuItem = HIDE_VISIT_MENU_ITEM_STATUSES.indexOf(event.status) === -1;
        }

        if(isSessionEnded(event, visitCollection)) {
            showVisitMenuItem = false;
        }
        return showVisitMenuItem;
    }

    const renderMenuItemContent = (menuItem) => {
        if (menuItem.id === MENU_ITEM_ID_SESSION){
            if (showVisitMenuItem()) {
                return (
                    <div className={styles.menuItem} onClick={handleEventToggle}>
                        <div className={styles.imageContainer}>
                            <IconButton className={styles.image}>
                                <PlayCircleOutlineIcon htmlColor='#000000'/>
                            </IconButton>
                        </div>
                        <div className={styles.menuItemLabel}>
                            {activeSession ? STOP_SESSION : START_SESSION}
                        </div>
                    </div>
                );
            }
        } else {
            return (
                <div className={styles.menuItem} onClick={menuItem.clickHandler ? menuItem.clickHandler : () => alert(menuItem.label)}>
                    <div className={styles.imageContainer}>
                        <img className={styles.image} src={menuItem.image} alt={menuItem.label} />
                    </div>
                    <div className={styles.menuItemLabel}>{menuItem.label}</div>
                </div>
            );
        }

        return null;
    };

    const renderAppointmentInterval = () => {
        return `${eventInterval.toFormat(DEFAULT_TIME_FORMAT)} (${eventInterval.length("minutes")} mins)`;
    }

    const calculateEventCount = (element) => {
        const eventElement = element.parentElement.parentElement;
        const scheduleWidth = eventElement.parentElement.clientWidth;
        const eventWidth = eventElement.clientWidth;

        const percentWidth = (eventWidth / scheduleWidth) * 100;

        return Math.floor(100 / percentWidth);
    }

    const setAppointmentEventElement = (element) => {
        appointmentEventElement.current = element;
        setEventCount(element ? calculateEventCount(element) : 1);
    }    
    const showAppointmentInterval = () => {
        return !isHandheld && (eventCount <= 6 || eventInterval.length('hours') >= 2);
    }

    const showAppointmentActivity = () => {
        return isHandheld || (eventCount <= 4 || eventInterval.length('hours') >= 2);
    }
    return (
        <div className={styles.appointmentEvent} ref={setAppointmentEventElement}>
            <div data-testid='AppointmentEvent' className={styles.appointmentColumn} onClick={!isNonMobileActivity(event, activities) ? showContextMenu: null}>
                <div className={!isNonMobileActivity(event, activities) ? (eventInterval.length('minutes') <= 15 ? styles.appointmentTitleNowrap : styles.appointmentTitle) : (eventInterval.length('minutes') <= 15 ? styles.appointmentTitleNowrapForNonMobile : styles.appointmentTitleForNonMobile)}>
                    {!isNonMobileActivity(event, activities) ? `${title} (${event?.client?.idNumber || event?.clientId})` : 'Non Mobile Activity'}
                </div>
                {eventInterval.length('hours') >= 1 &&
                <>
                    {calendarView !== WEEK && showAppointmentInterval() &&
                    <div className={!isNonMobileActivity(event, activities) ? styles.appointmentActivity : styles.appointmentNonMobileActivity} >
                        {renderAppointmentInterval()}
                    </div>
                    }
                    {showAppointmentActivity() &&
                    <div className={styles.appointmentActivity} >
                         { !isNonMobileActivity(event, activities) ? activity?.description : '' }
                    </div>
                    }
                </>
                }
                {anchorEl &&
                <Menu
                    id="AppointmentEventMenu"
                    anchorEl={anchorEl}
                    keepMounted={false}
                    open={true}
                    onClose={closeContextMenu}
                    anchorReference="anchorPosition"
                    anchorPosition={{ top: contextMenu.mouseY, left: contextMenu.mouseX }}
                    PaperProps={{
                        style: menuStyle
                    }}
                >
                    {menuItems.map((menuItem) => renderMenuItem(menuItem))}
                </Menu>
                }
            </div>
            {showVisitMenuItem() &&
            <div className={styles.appointmentVisit}>
                <VisitStopwatch
                    embedded={true}
                    hideDuration={true}
                    durationSize='tiny'
                    client={event.client}
                    visits={visits}
                    appointment={event}
                    renderSessionToggleComponent={renderSessionToggleComponent}
                />
            </div>
            }
            {showEditAppointmentDialog &&
            <AppointmentDialog
                onClose={handleAppointmentDialogClose}
                appointment={{...event}}
                showEditAppointmentDialog = {showEditAppointmentDialog}
                clientData = {clientData}
            />
            }
            {showViewAppointmentDialog &&
            <ViewAppointmentDialog
                onClose={handleViewAppointmentDialogClose}
                appointment={{...event}}
                stopwatchActive={stopWatchContext.current.stopwatchActive}
                visitCollection = {visitCollection}
            />
            }
            {alertDialogConfig &&
            <AlertDialog
                open={true}
                dialogTitle={alertDialogConfig.dialogTitle}
                dialogMessage={alertDialogConfig.dialogMessage}
                showOkButton={alertDialogConfig.showOkButton}
                showCancelButton={alertDialogConfig.showCancelButton}
                okButtonText={alertDialogConfig.okButtonText ? alertDialogConfig.okButtonText : 'Ok'}
                handleClose={handleAlertDialogClose}
            />
            }
            {showDocumentChooser && filterDocument.length > 1 &&
                <DocumentChooserDialog documents={filterDocument} onClose={chooseServiceDocument} />
            }
        </div>
    )
}
