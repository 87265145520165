import {makeStyles} from "@material-ui/core/styles";
import {formatUserName} from "../../../common/utils/formatUtils";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import VisitStopwatch from "../VisitStopwatch";
import {getClientImage} from "../../../common/utils/imageUtils";
import syncService from "../../../common/services/syncService";
import StopCircleIcon from '@mui/icons-material/StopCircle';
import clientService from "../../../common/services/clientService";
import {DateTime} from "luxon";
import {clientCache} from "../../../cache/slices/client/clientSlice";
import { PRIMARY_COLOR } from "../../../common/constants";

const useStyles = makeStyles(() => ({
    activeSessionCard: {
        width: '100%',
        display: 'grid',
        rowGap: '14px',
        gridTemplateColumns: '76px 1fr 135px', // 'minmax(12%,48px) 64% 12% 12%',
        backgroundColor: "#ffffff",
        borderBottom: '1px solid #d0d0d0',
        padding: '12px 0px 8px 0px'
    },
    imageColumn: {
        width: '48px',
        height: '48px',
        alignSelf: 'center',
        justifySelf: 'center'
    },
    image: {
        height: '100%',
        width: '100%'
    },
    clientColumn: {
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'column'
    },
    clientNameLabel: {
        fontSize: '16px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: PRIMARY_COLOR,
        paddingBottom: '4px'
    },
    clientIdContainer: {
    },
    clientIdLabel: {
        fontSize: '16px',
        fontWeight: "bold",
        fontStyle: "normal",
        lineHeight: '20px',
        letterSpacing: 0,
        color: "#4f4f4f"
    },
    clientIdValue: {
        fontSize: '16px',
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: '20px',
        letterSpacing: 0,
        color: "#4f4f4f",
        paddingLeft: '6px'
    },
    stopwatchColumn: {
        alignSelf: 'center'
    },
    iconColumn: {
        alignSelf: 'center',
        paddingLeft: '12px',
        paddingRight: '17px',
        '& .MuiSvgIcon-root': {
            width: '32px',
            height: '32px'
        }
    },
    stopIcon: {
        width: '32px',
        height: '32px'
    }
}));

export default function ActiveSessionCard({visit, onActiveSessionSelected}) {
    const visits = useSelector(syncService.visitService.getResults());
    const dispatch = useDispatch();
    const history = useHistory();
    const styles = useStyles();

    const handleActiveSessionClick = () => {
        if (onActiveSessionSelected){
            if (onActiveSessionSelected(visit) === false){
                return;
            }
        }
        clientService.visitClient(visit.client, DateTime.now().toMillis());
        dispatch(clientCache.selectClient(visit.client));
        history.push('/caseload/facesheet');
    }

    const handleToggleSession = (stopwatchActive, toggleSession) => {
        if (stopwatchActive){
            toggleSession();
        }
    }

    const renderStopSessionButton = (stopwatchActive, toggleSession) => {
        return (
            <div data-testid='ActiveSessionCard_visit_stopwatch' className={styles.iconColumn} onClick={() => handleToggleSession(stopwatchActive, toggleSession)}>
                <StopCircleIcon className={styles.stopIcon}  htmlColor='#E23E00'/>
            </div>
        );
    }

    return (
        <div className={styles.activeSessionCard}>
            <div className={styles.imageColumn} onClick={handleActiveSessionClick}>
                <img className={styles.image} src={getClientImage(visit.client)} alt='client' />
            </div>
            <div data-testid='ActiveSessionCard_client' className={styles.clientColumn} onClick={handleActiveSessionClick}>
                <div className={styles.clientNameLabel}>
                    {formatUserName(visit.client)}
                </div>
                <div className={styles.clientIdContainer}>
                    <span className={styles.clientIdLabel}>ID:</span>
                    <span className={styles.clientIdValue}>{visit?.client?.idNumber || visit.clientId}</span>
                </div>
            </div>
            <div className={styles.stopwatchColumn} >
                <VisitStopwatch embedded={true} durationSize='tiny' client={visit.client} visits={visits} renderSessionToggleComponent={renderStopSessionButton}/>
            </div>
        </div>
    )
}
