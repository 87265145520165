import {makeStyles} from "@material-ui/core/styles";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {defaultDateAsString, formatMedication} from "../../../common/utils/formatUtils";
import {clientCache} from "../../../cache/slices/client/clientSlice";
import MedicationStatusCell from "../MedicationStatusCell";
import StackedField from "../../../common/components/StackedField";
import { PRIMARY_COLOR } from "../../../common/constants";

const useStyles = makeStyles(() => ({
    medicationPage: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '17px',
        paddingLeft: '16px',
        paddingRight: '16px'
    },
    medicationName: {
        fontSize: '18px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: PRIMARY_COLOR
    }
}));

export default function MedicationPage() {
    const medication = useSelector(clientCache.getSelectedMedication);
    const history = useHistory();
    const styles = useStyles();

    if (!medication){
        history.push("/");
        return null;
    }

    return (
        <div data-testid='medicationPage' className={styles.medicationPage}>
            <div className={styles.medicationName} >
                {formatMedication(medication)}
            </div>
            <StackedField label='Dosage/Sig' value={medication.frequency} />
            <StackedField label='Amount/Refills' value={`${medication.amount}/${medication.refills}`} />
            <StackedField label='Prescriber' value={medication.prescriber} />
            <StackedField label='Status' >
                <MedicationStatusCell medication={medication} />
            </StackedField>
            <StackedField label='Begin Date' value={defaultDateAsString(medication.beginDate)} />
            <StackedField label='End Date' value={defaultDateAsString(medication.endDate)} />
            <StackedField label='Comments' value={medication.comments} />
        </div>
    );
}
