import SignaturePage from "./SignaturePage";
import {cloneDeep} from "lodash/lang";
import { getFieldValues } from "./common/ConfigurableFormsUtils";
import clientService from "../../common/services/clientService";
import { checkUpdatedFieldGa, getClientTxPlans, isGA } from "../../common/utils/goalsAddressedUtils";
import { isImpact } from "../../common/utils/impactUtils";

export const SERVICE_DOCUMENT_STATUS_UNSIGNED = 'Unsigned';
export const SERVICE_DOCUMENT_STATUS_SIGNED = 'Signed';
export const SERVICE_DOCUMENT_STATUS_INCOMPLETE = 'Incomplete';
export const SERVICE_DOCUMENT_STATUS_COMPLETE = 'Complete';
export const SERVICE_DOCUMENT_STATUS_MISSING = 'Missing Service Document';
export const SERVICE_DOCUMENT_STATUS_EXTERNAL_SIGN_NEEDED = 'External Signature Needed';

const createSignaturesModule = (documentForWizard) => {
    return {
        name: 'Signatures',
        renderPage: (props) => {
            documentForWizard = {...documentForWizard, ...props.documentForWizard}
            return (<SignaturePage {...props} documentForWizard = { documentForWizard } />);
        }
    };
};

export const configureConfigurableForms = (documentController, serviceDocument, configurableForms, appointmentDocument) => {
    if (serviceDocument && serviceDocument.modules){
        let gaTxPlans = getClientTxPlans(documentController);
        serviceDocument.modules.forEach(documentModule => {
            if (isGA(documentModule) || isImpact(documentModule)) {
                if (isGA(documentModule)) {
                    documentModule.gaTxPlans = gaTxPlans;
                }

                if (appointmentDocument && appointmentDocument.length > 0) {
                    let moduleData = appointmentDocument[0]?.moduleData?.find(module => module.moduleId === documentModule.moduleId);
                    if (moduleData) {
                        documentModule.formValidated = moduleData.formValidated;
                    }
                }
            } else {
                let moduleData;
                const configurableForm = configurableForms.find(configurableForm => documentModule.moduleId === configurableForm.moduleId);
                if (configurableForm && appointmentDocument && appointmentDocument.length > 0) {
                    moduleData = appointmentDocument[0]?.moduleData?.find(module => module.moduleId === configurableForm.moduleId);
                }

                if (configurableForm){
                    documentModule.configurableForm = cloneDeep(configurableForm);
                    addDbSavedData(documentModule, moduleData);
                }
            }
        });

        // sorting of modules based on order attribute.
        serviceDocument.modules.sort((m1, m2) => m1.order - m2.order);
    }
};

export const addDbSavedData = (documentModule, moduleData) => {
    let fieldData;
    if (documentModule.configurableForm.fields){
        if (moduleData) {
            documentModule.configurableForm.formValidated = moduleData.formValidated;
            documentModule.configurableForm.fields.forEach((field) => {
                // eslint-disable-next-line
                fieldData = moduleData.fieldData.find(data => data.key == field.id);
                if (fieldData) {
                    field.dbSavedValue = fieldData.value;
                }
            });
        }
        documentModule.configurableForm.fields.sort((f1, f2) => f1.order - f2.order);
    }
    return documentModule;
}

export const configureModules = (documentController, serviceDocument, configurableForms, appointmentDocument, documentForWizard) => {
    configureConfigurableForms(documentController, serviceDocument, configurableForms, appointmentDocument);
    let filteredModules = serviceDocument?.modules?.filter((module) => isGA(module) || isImpact(module) || module?.configurableForm);

    return [
        ...filteredModules,
        createSignaturesModule(documentForWizard)
    ]
};

export const checkUpdatedField = (currentModule, module, modules, currentPageIndex) => {
    let updated = false;
    let currentFieldData = currentModule?.fieldData?.filter((o,i) => !isNaN(o.key));
    let fieldData = [];
    fieldData = module?.fieldData?.filter((o,i) => !isNaN(o.key));

    let fieldsArray = modules.filter((moduleEle) => moduleEle.moduleId === module.moduleId);
    fieldData.forEach((field) => {
        updated = eleValueChanged(currentFieldData, field, currentPageIndex, modules, updated);

        if(!updated) {
             // eslint-disable-next-line
            fieldsArray[0].configurableForm.fields.forEach((fieldEle) => {
                if(!updated && fieldEle.oldValue && fieldEle.oldValue.length > 0 && !fieldEle.nextClicked) {
                    if(fieldEle.oldValue !== field.value) {
                        updated = true;
                    }
                }
            }); 
        }
    });
    return updated
}

export const eleValueChanged = (currentFieldData, field, currentPageIndex, modules, updated) => {
    let fd = currentFieldData.find((o,i) => o.key.toString() === field.key.toString());

    if((currentPageIndex !== undefined) && (currentPageIndex !== (modules.length - 1))) {
        if (fd && field && (fd.value !== undefined && fd.value !== null ? fd.value.toString() : '') !== (field.value !== undefined && field.value !== null ? field.value.toString() : '')) {
            updated = true;
        }
    } else {
        if(!fd || ( fd.value && field.value && fd?.value.toString() !== field?.value.toString() )) {
            updated = true;
        }
    }
    return updated;
}

const checkAllNext = (modulesToSave, modules) => {
    //For a new form modulesToSave is empty or not equal to all modules except signature page
    if(modulesToSave.length !== modules.length-1) {
        return false
    } else {
        return !modulesToSave?.filter(module => module.formValidated === false).length > 0
    }
}

export const setStatusByValidateResult = (state, documentController, pageIndex, isReadOnlyDoc, modulesToSave, descriptorData) => {
    let docStatus = state.status;
    let fieldValueChanged = isFieldValueChanged(state, documentController, pageIndex, descriptorData);
    let isAllNextClicked = checkAllNext(modulesToSave, state.serviceDocument.modules);
    let minimumNumberOfStaffSignatures = state?.serviceDocument.signatureConfiguration.minimumNumberOfStaffSignatures;

    if(fieldValueChanged && modulesToSave.length >= pageIndex && modulesToSave[pageIndex]) {
        modulesToSave[pageIndex].formValidated = false
    }

    if (isReadOnlyDoc === true || state.status === SERVICE_DOCUMENT_STATUS_SIGNED) {
        docStatus = SERVICE_DOCUMENT_STATUS_SIGNED;
    } else {
        if(!fieldValueChanged && isAllNextClicked) {
            docStatus = minimumNumberOfStaffSignatures === 0 ? SERVICE_DOCUMENT_STATUS_COMPLETE : SERVICE_DOCUMENT_STATUS_UNSIGNED;
        } else {
            docStatus = SERVICE_DOCUMENT_STATUS_INCOMPLETE;
        }
    }

    state.serviceDocument.status = docStatus;
    state.status = docStatus;
    return docStatus;
}

export const pageByIndex = (pageIndex, serviceDocument) => {
    if (pageIndex >= 0 && pageIndex < serviceDocument?.modules?.length){
        return serviceDocument?.modules[pageIndex];
    }
    return null;
};

export const isFieldValueChanged = (state, documentController, pageIndex, descriptorData) => {
    let fieldValueChanged = false;
    const currentPage = pageByIndex(pageIndex, state?.serviceDocument);
    state.serviceDocument.modules.forEach((module) => {
        if(!fieldValueChanged) {
            let moduleId = module.moduleId;
            if(module?.configurableForm?.fields && documentController?.moduleData && documentController?.moduleData[moduleId]) {
                module.fieldData= [];
                const fieldValues = getFieldValues(module?.configurableForm?.fields, documentController?.client, descriptorData);
                module.fieldData = [ ...fieldValues, ...module.fieldData ];
                let fieldChanged = checkUpdatedField(module, documentController?.moduleData[moduleId], state.serviceDocument.modules, pageIndex);
                if(fieldChanged) {
                    fieldValueChanged = true;
                }
            } else if (isGA(currentPage)) {
                fieldValueChanged = checkUpdatedFieldGa(currentPage);
            }
        }
    })

    return fieldValueChanged;
}

export const updateSignaturesOnServDoc = (documentController, documentForWizard, signatureDb) => {
    let signatures = getSignaturesFromServDoc(documentController.serviceDocument, signatureDb);
    if (documentController?.appointmentDocument && documentController?.appointmentDocument.length > 0) {
        documentController.appointmentDocument[0] = {...documentController?.appointmentDocument[0], signature: signatures};
    } else {
        if(documentForWizard) {
            documentForWizard = {...documentForWizard, signature: signatures};
        }
    }
    return signatures;
}

export const getStaffId= (documentForWizard, documentController) => {
    return documentForWizard?.staffId ? documentForWizard.staffId : documentController?.dataProvider?.getStaff().staffId;
}

export const getSignaturesFromServDoc = (serviceDocument, signatureDb) => {
    let signatures = {};
    if(serviceDocument?.signature) {
        signatures = serviceDocument?.signature;
    } else {
        signatures = signatureDb;
    }
    return signatures;
}

export const getProgramName = async(cpId) => {
    let clientProgram;
    let program;

    if(cpId) {
        clientProgram = await clientService.findClientProgram(cpId);
        program = await clientService.findProgram(clientProgram[0]?.programId, clientProgram[0]?.organizationId);
    }
    return program;
}