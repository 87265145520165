import {makeStyles} from "@material-ui/core/styles";
import {DateTime} from 'luxon';
import {dateToStringWithFormat} from "../../../common/utils/formatUtils";
import {useDispatch} from "react-redux";
import {scheduleCache} from "../../../cache/slices/schedule/scheduleCache";
import {IconButton} from "@material-ui/core";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { PRIMARY_COLOR } from "../../../common/constants";

const useStyles = makeStyles(() => ({
    calendarViewNavigationBar: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: "#ffffff",
        padding: '16px 12px 18px 12px'
    },
    imageColumn: {
        alignSelf: 'center'
    },
    imageContainer: {
        width: '32px',
        height: '32px',
        padding: '0px'
    },
    image: {
        height: '100%',
        width: '100%'
    },
    viewDateTitle: {
        fontSize: '22px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        textAlign: "center",
        color: PRIMARY_COLOR
    }
}));

export default function CalendarViewNavigationBar({viewDate}) {
    const dispatch = useDispatch();
    const styles = useStyles();

    const changeViewDate = (newViewDate) => {
        const now = DateTime.now();
        dispatch(scheduleCache.setViewDate(newViewDate.startOf('day').plus({hours: now.hour, minutes: now.minute})));
    }

    const handleCurrentDate = () => {
        changeViewDate(DateTime.now());
    }

    const handlePrevious = () => {
        changeViewDate(viewDate.minus({months: 1}));
    }

    const handleNext = () => {
        changeViewDate(viewDate.plus({months: 1}));
    }

    return (
        <div id='calendarViewNavigationBar' className={styles.calendarViewNavigationBar}>
            <div className={styles.imageColumn}>
                <IconButton
                    id='CalendarViewNavigationBar_previousButton'
                    type="button"
                    className={styles.imageContainer}
                    aria-label="previous"
                    onClick={handlePrevious}
                >
                    <ChevronLeft className={styles.image} htmlColor="#3b276a" alt='previous'/>
                </IconButton>
            </div>
            <div id='viewDateColumn' className={styles.viewDateTitle} onClick={handleCurrentDate}>
                {dateToStringWithFormat(viewDate, 'MMMM yyyy')}
            </div>
            <div className={styles.imageColumn}>
                <IconButton
                    id='CalendarViewNavigationBar_nextButton'
                    type="button"
                    className={styles.imageContainer}
                    aria-label="next"
                    onClick={handleNext}
                >
                    <ChevronRight className={styles.image} htmlColor="#3b276a" alt='next'/>
                </IconButton>
            </div>
        </div>
    )
}
