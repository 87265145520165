import {makeStyles} from "@material-ui/core/styles";
import {formatUserName, getFormattedStartDate, getFormattedStartTime} from "../../../common/utils/formatUtils";
import {useEffect,useRef, useState} from "react";
import AppointmentDialog from "../../facesheet/AppointmentDialog";
import { getDiscriptors, getVisitObject, isSessionEnded, removePropertiesVisit, showVerifySignature } from "../../../common/utils/visitUtils";
import syncService from "../../../common/services/syncService";
import { useDispatch, useSelector, useStore } from "react-redux";
import {useHistory} from "react-router-dom";
import { appCache } from "../../../cache/slices/app/appSlice";
import AlertDialog from "../../../common/components/AlertDialog";
import { documentNotRequired, getInitialOrganization } from "../../../common/utils/appointmentUtils";
import VisitVerification from "../../facesheet/VisitVerificationDialog/VisitVerification";
import ViewAppointmentDialog from "../../facesheet/ViewAppointmentDialog";
import VisitVerificationDialog from "../../facesheet/VisitVerificationDialog/VisitVerificationDialog";
import { getSystemInformation } from "../../../common/utils/systemUtils";
import { MAX_OPEN_DOCUMENTS, NO_DOCUMENT_REQUIRED, PDF_ONLINE_ERROR, PDF_SERVICE_DOC, PRIMARY_COLOR, START_SESSION, STOP_SESSION } from "../../../common/constants";
import { SERVICE_DOCUMENT_STATUS_EXTERNAL_SIGN_NEEDED, SERVICE_DOCUMENT_STATUS_INCOMPLETE, SERVICE_DOCUMENT_STATUS_MISSING, SERVICE_DOCUMENT_STATUS_UNSIGNED } from "../../forms/serviceDocumentUtils";
import DocumentChooserDialog from "../../forms/DocumentChooserDialog";
import { pushServiceDocument } from "../../schedule/AppointmentEvent/NewDocumentUtils";
import { filterDocumentCrosswalksMissing } from "../../forms/common/IncompleteTasksUtils";
import { checkIfDocExistsInTab, chekDocPdf, createNewDocument, handleSaveNoDocument, hasAttachedDocument, hasMaxDocsOpened, tabDocAddAction, tabDocEditAction } from "../../../common/utils/documentUtils";
import { cloneDeep } from "lodash/lang";
import { documentCache } from "../../../cache/slices/document/documentCache";
import clientService from "../../../common/services/clientService";
import { DateTime } from "luxon";
import { clientCache } from "../../../cache/slices/client/clientSlice";
import { editDocument } from "../AppointmentCard/EditDocumentUtils";

const useStyles = makeStyles((theme) => ({
    taskCard: {
        width: '100%',
        backgroundColor: "#ffffff",
        display: 'flex',
        cursor: 'pointer',
        justifyContent: 'space-between',
        "&:not(:first-child)": {
            [theme.breakpoints.down('sm')]: {
                paddingTop: '14px'
            },
            [theme.breakpoints.up(601)]: {
                paddingTop: '10px'
            }
        },
        "&:not(:last-child)": {
            borderBottom: '1px solid #d0d0d0'
        },
        [theme.breakpoints.down('sm')]: {
            paddingBottom: '14px',
        },
        [theme.breakpoints.up(601)]: {
            paddingBottom: '10px',
        }
    },
    leftContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    rightContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    clientName: {
        fontSize: '16px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: PRIMARY_COLOR,
        paddingBottom: '4px'
    },
    statusText: {
        fontSize: '16px',
        fontWeight: "normal",
        fontStyle: "normal",
        letterSpacing: 0,
        color: "#4f4f4f"
    },
    noStatus: {
        display: 'flex'
    },
    appointmentText: {
        fontSize: '16px',
        fontStyle: "normal",
        letterSpacing: 0,
        color: "#4f4f4f",
        paddingRight: '3px'
    },
    startTime: {
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        textAlign: "right",
        color: "#4f4f4f",
        paddingBottom: '4px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        },
        [theme.breakpoints.up(601)]: {
            fontSize: '16px',
        }
    },
    duration: {
        fontSize: '14px',
        fontWeight: "normal",
        fontStyle: "normal",
        letterSpacing: 0,
        textAlign: "right",
        color: "#4f4f4f"
    },
    documentName: {
        fontSize: '16px',
        fontWeight: "normal",
        fontStyle: "normal",
        letterSpacing: 0,
        color: "#4f4f4f",
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        maxWidth: "160px"
    }
}));

let stateCurrentPosition = '';

export default function TaskCard({task, visitCollection}) {

    const organizations = useSelector(syncService.organizationService.getResults());
    const styles = useStyles();
    const [showEditAppointmentDialog, setShowEditAppointmentDialog] = useState(false);
    const [currentVisit, setCurrentVisit] = useState(null);
    const [updatedStatus, setUpdatedStatus] = useState(null);
    const [showVisitVerificationDialog, setShowVisitVerificationDialog] = useState(false);
    const [showVisitVerificationSignature, setShowVisitVerificationSignature] = useState(false);
    const store = useStore();
    const user = useSelector(appCache.getUser);
    const [alertDialogConfig, setAlertDialogConfig] = useState(null);
    const [showViewAppointmentDialog, setShowViewAppointmentDialog] = useState(false);
    const stopWatchContext = useRef({stopwatchActive: false});
    const isHandheld = useSelector(appCache.isHandheld)
    const dispatch = useDispatch();
    const history = useHistory();

    const clients = useSelector(appCache.getClients);
    const [client, setClient] = useState({});
    const [filterDocument, setfilterDocument] = useState([]);
    const documentCrosswalks = useSelector(syncService.documentCrosswalkService.getResults());
    const serviceDocuments = useSelector(syncService.serviceDocumentService.getResults());
    const [showDocumentChooser, setShowDocumentChooser] = useState(false);
    const [reasonCodes, setReasonCodes] = useState([]);
    let newDocument = {};
    const staff = user.staff;
    const serviceDocTab = cloneDeep(useSelector(documentCache.getServiceDocumentsForTabs));
    const evvState = currentVisit?.appointment?.organization?.evvState;
    const [appointmentStatus, setAppointmentStatus] = useState('');
    const orgId = user?.currentOrganizationId;

    useEffect(() => {
        if(clients && clients.length > 0 && task && task.appointment) {
            const objClient = clients.find(c => Number(c.clientId)=== Number(task?.appointment?.clientId));
            setClient(objClient);
         }
         // eslint-disable-next-line
    }, [clients]);

    useEffect(() => {
        if(evvState && orgId) {
            getDiscriptors(orgId, evvState, setReasonCodes);
        }
    }, [evvState, orgId]);

    const fetchFilterData = async() => {
        let filterData = await filterDocumentCrosswalksMissing(task.appointment, documentCrosswalks, serviceDocuments, staff);
        if (filterData && filterData.length > 0) {
            setfilterDocument(filterData);
        }
        return filterData;
    }

    const handleMissingDocument = async() => {
        let filterData = await fetchFilterData();

        if (hasMaxDocsOpened(serviceDocTab, task.appointment.client.clientId)) {
            setAlertDialogConfig(MAX_OPEN_DOCUMENTS);
        } else {
            if (filterData && filterData.length > 0) {
                if(filterData.length === 1) {
                    chooseServiceDocument(filterData[0] );
                } else {
                    setShowDocumentChooser(true);
                }
            }
        }
    }

    const chooseServiceDocument = (serviceDocument) => {
        setShowDocumentChooser(false);
        if (serviceDocument) {
            if (documentNotRequired(serviceDocument)) {
                handleSaveNoDocument(serviceDocument, client, staff, task.appointment, store, dispatch);
                setAlertDialogConfig(NO_DOCUMENT_REQUIRED);
                dispatch(documentCache.setCurrentData({}));
            } else {
                dispatch(documentCache.setCrosswalkedDocuments([serviceDocument]));
                dispatch(documentCache.setCurrentDocument(serviceDocument));
                dispatch(documentCache.setCurrentData({}));
                handleSaveNewDocument(serviceDocument);
                clientService.visitClient(task.appointment.client, DateTime.now().toMillis());
                dispatch(clientCache.selectClient(task.appointment.client));
                pushServiceDocument(isHandheld, newDocument, history ) 
            }
        }
    }

    const handleSaveNewDocument = (document) => {
        newDocument = createNewDocument(document, task.appointment?.client, staff, task.appointment);
        syncService.documentService.saveNewDocument(newDocument, store, dispatch);
        dispatch(documentCache.setCurrentAptDocument([newDocument]));
        tabDocAddAction(serviceDocTab, newDocument, dispatch);
    }

    const renderStatus = () => {
        if (task.status){
            let status = task.status;
            if(status === SERVICE_DOCUMENT_STATUS_UNSIGNED) {
                status = 'Unsigned Service Document'
            } else if(status === SERVICE_DOCUMENT_STATUS_INCOMPLETE) {
                status = 'Incomplete Service Document';
            } else if(status === SERVICE_DOCUMENT_STATUS_MISSING) {
                status = 'Missing Service Document';
            }
            return (
                <div className={styles.statusText}>
                    {status}
                </div>
            );
        } else {
            return (
                <div className={styles.noStatus}>
                    <div className={styles.appointmentText} >Appointment:</div>
                    <div className={styles.statusText} >No Status</div>
                </div>
            )
        }
    }

    const handleIncompletTaskOfDocument = async(task, isHandheld, dispatch, history) => {
        const savedDocument = await syncService.documentService.findDocumentById(task.id);
        const aptDocCollection = await syncService.documentService.getServiceDoc(task.serviceDocumentId);
        const clientOfTask = await clientService.findClientById(task.clientId.toString());
        if (!checkIfDocExistsInTab(serviceDocTab, savedDocument) && hasMaxDocsOpened(serviceDocTab, task.clientId)) {
            setAlertDialogConfig(MAX_OPEN_DOCUMENTS);
        } else if ((aptDocCollection && aptDocCollection.length > 0) && (clientOfTask && clientOfTask.length > 0)) {
            dispatch(documentCache.setCrosswalkedDocuments(aptDocCollection));
            dispatch(documentCache.setCurrentDocument(aptDocCollection[0]));
            dispatch(documentCache.setCurrentData({}));
            dispatch(clientCache.selectClient(clientOfTask[0]));
            dispatch(documentCache.setCurrentAptDocument([savedDocument]));
            pushServiceDocument(isHandheld, savedDocument, history);
            tabDocEditAction(serviceDocTab, savedDocument, dispatch, task?.client);
        }
    }

    const externalSignNeeded = async(task) => {
        if (!checkIfDocExistsInTab(serviceDocTab, task) && hasMaxDocsOpened(serviceDocTab, task?.clientId)) {
            setAlertDialogConfig(MAX_OPEN_DOCUMENTS);
        } else {
            let docPdfStatus = await chekDocPdf(task);
            if (docPdfStatus.error) {
                let pdfErrorDialogContent = {...PDF_ONLINE_ERROR, dialogMessage: docPdfStatus.errorMsg, dialogTitle: docPdfStatus.errorTitle};
                setAlertDialogConfig(pdfErrorDialogContent);
            } else {
                task = {...task, pdfUrl: docPdfStatus?.pdfUrl};
                let client = await clientService.findClientById(task?.clientId?.toString());
                if (client && client?.length > 0) {
                    editDocument([task], [PDF_SERVICE_DOC], client[0], isHandheld, dispatch, history, serviceDocTab);
                }
            }
        }
    }

    const handleIncompleteTaskClick = () => {
        if(task?.status === 'Open Session') {
            hasAttachedDocument(task?.appointment, dispatch);
            let visitVerificationComplete = false;
            let organization = getInitialOrganization(user, task.appointment, organizations);
            let visit = getVisitObject(task.appointment, organization);
                visitVerificationComplete = isSessionEnded(task.appointment, visitCollection);
                if(visitVerificationComplete === true){
                    viewAppointmentDialog();
                } else {
                    setCurrentVisit(visit);
                    handleStopRequest(visit);
                }
        } else if (task?.appointment?.status === "None") {
            openAppointmentDialog();
        } else if (task?.status === SERVICE_DOCUMENT_STATUS_INCOMPLETE || task?.status === SERVICE_DOCUMENT_STATUS_UNSIGNED) {
            handleIncompletTaskOfDocument(task, isHandheld, dispatch, history);
        } else if (task?.status === SERVICE_DOCUMENT_STATUS_MISSING) {
            handleMissingDocument();
        } else if (task?.status === SERVICE_DOCUMENT_STATUS_EXTERNAL_SIGN_NEEDED) {
            externalSignNeeded(task);
        }
    }

    const openAppointmentDialog = () => {
        setShowEditAppointmentDialog(true);
    }
    const handleAppointmentDialogClose = () => {
        setShowEditAppointmentDialog(false);
    }

    const viewAppointmentDialog = () => {
        setShowViewAppointmentDialog(true);
    }

    const handleViewAppointmentDialogClose = () => {
        setShowViewAppointmentDialog(false);
    }

    const handleStopRequest = (visit) => {
        const systemInformation = getSystemInformation();
        const optionsGeolocation = { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 };
        stateCurrentPosition = 'timeout';

        if ((systemInformation.platformType === 'Mobile' || systemInformation.platformType === 'Tablet')
            && (systemInformation.osName === 'macOS' || systemInformation.osName === 'iOS')
            && systemInformation.browserName === 'Safari') {
            const currentPosition = new Promise((resolve) => {
                setTimeout(resolve, 1000, navigator.geolocation.getCurrentPosition(
                    syncService.visitService.endClientVisit(store, user, client, undefined, handleVisitEnded, visit),
                    handleSessionEndFailure,
                    optionsGeolocation));
            });

            const timeOutGeolocation = new Promise(() => {
                setTimeout(() => handleGeoLocationNotResponse(), 3000);
            });

            Promise.race([currentPosition, timeOutGeolocation]);

        } else {
            navigator.permissions.query({ name: 'geolocation' }).then((result) => {
                navigator.geolocation.getCurrentPosition(
                    syncService.visitService.endClientVisit(store, user, client, undefined, handleVisitEnded, visit),
                    handleSessionEndFailure,
                    optionsGeolocation);
            });
        }
    }


    const handleVisitEnded = (visit) => {
        stateCurrentPosition = 'success';
        setAlertDialogConfig(null);
        setCurrentVisit(visit);
        setShowVisitVerificationDialog(true);
    }

    const visitVerificationNextClick = (updatedVisit) => {
        setCurrentVisit(updatedVisit);
        setShowVisitVerificationDialog(false);
        setShowVisitVerificationSignature(true);

    }

    const handlePrevious = (updatedVisit) => {
        setCurrentVisit(updatedVisit);
        setUpdatedStatus(updatedVisit.appointmentStatus);
        setShowVisitVerificationSignature(false);
        setShowVisitVerificationDialog(true);
    }

    function handleGeoLocationNotResponse() {
        if (stateCurrentPosition === 'timeout') {
            failVisit(stateCurrentPosition);
        }
    }

    const handleSessionEndFailure = () => {
        stateCurrentPosition = 'failure';
        failVisit(stateCurrentPosition);
    }

    const failVisit = (stateCurrentPosition) => {
        const actionType = stopWatchContext ? STOP_SESSION : START_SESSION;
        let dialogMessage = '';

        if (stateCurrentPosition === 'failure') {
            dialogMessage = `Cannot ${actionType} for a Client with location services turned off. Please turn on location services for your browser and try again.`
        } else if (stateCurrentPosition === 'timeout') {
            dialogMessage = `Cannot ${actionType}. Please set Location Services on your device to Allow for your device and browser.`
        }

        if (stateCurrentPosition === 'failure' || stateCurrentPosition === 'timeout') {
            setAlertDialogConfig({
                dialogTitle: 'GPS Error',
                dialogMessage: dialogMessage,
                showOkButton: true,
                showCancelButton: false
            });
        }
    }

    const handleAlertDialogClose = () => {
        setAlertDialogConfig(null);
    }

    const handleVisitVerificationDialogClose = (verifiedVisit) => {
        setShowVisitVerificationDialog(false);
        if (verifiedVisit){
            verifiedVisit =  {...removePropertiesVisit(verifiedVisit)};
            syncService.visitService.verifyClientVisit(store, user, client, verifiedVisit);
        } else {
            syncService.visitService.cancelVerifyClientVisit(store, user, client);
        }
    }

    const handleAppointmentStatusChange = (newState) => {
        setAppointmentStatus(newState);
    };
    
    return (
        <>
        <div className={styles.taskCard} data-testid="taskCardDiv" onClick={() => handleIncompleteTaskClick()}>
            <div className={styles.leftContainer}>
                <div className={styles.clientName}>
                    {formatUserName(task.client)}
                </div>
                {renderStatus()}
                <div className={styles.documentName}>
                    {task?.status === SERVICE_DOCUMENT_STATUS_EXTERNAL_SIGN_NEEDED &&
                        task?.documentName
                    }
                </div>
            </div>
            <div className={styles.rightContainer}>
                <div className={styles.startTime}>
                    { (task.formattedStartDate) ? task.formattedStartDate :  getFormattedStartDate(task.startDateInstance) }
                </div>
                <div className={styles.duration}>
                    { (task.formattedStartTime) ? task.formattedStartTime : getFormattedStartTime(task.startDateInstance) }
                </div>
            </div>
        </div>
        <div>
            {showEditAppointmentDialog &&
            <AppointmentDialog
                onClose={handleAppointmentDialogClose}
                appointment={{ ...task.appointment }}
                showEditAppointmentDialog = {showEditAppointmentDialog}
            />
            }
        </div>
        <div>
            {showVisitVerificationDialog &&
            <VisitVerification
                visit={currentVisit}
                reasonCodes={reasonCodes}
                onClose={handleVisitVerificationDialogClose}
                onvisitVerificationNextClick={visitVerificationNextClick}
                updatedStatus={updatedStatus}
                appointmentStatusChange={handleAppointmentStatusChange}
                evvState={evvState}
            />
            }
        </div>
        <div>
            {showVisitVerificationSignature && showVerifySignature(evvState, appointmentStatus, currentVisit?.appointment) &&
            <VisitVerificationDialog
                visit={currentVisit}
                reasonCodes={reasonCodes}
                onClose={handleVisitVerificationDialogClose}
                onhandlePrevious={handlePrevious}
            />
        }
        </div>
        <div>
        {alertDialogConfig &&
            <AlertDialog
                open={true}
                dialogTitle={alertDialogConfig.dialogTitle}
                dialogMessage={alertDialogConfig.dialogMessage}
                showOkButton={alertDialogConfig.showOkButton}
                showCancelButton={alertDialogConfig.showCancelButton}
                okButtonText={alertDialogConfig.okButtonText ? alertDialogConfig.okButtonText : 'Ok'}
                handleClose={handleAlertDialogClose}
            />
            }
        </div>
        <div>
        {showViewAppointmentDialog &&
            <ViewAppointmentDialog
                onClose={handleViewAppointmentDialogClose}
                appointment={{...task.appointment}}
                stopwatchActive={stopWatchContext.current.stopwatchActive}
            />
        }
        {showDocumentChooser && filterDocument.length > 1 &&
            <DocumentChooserDialog documents={filterDocument} onClose={chooseServiceDocument} />
        }
        </div>
        </>
    )
}
