/* eslint-disable eqeqeq */
import {DateTime} from "luxon";
import {useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from "@material-ui/core/styles";
import EvvButton from "../../../common/components/EvvButton";
import StackedField from "../../../common/components/StackedField";
import EvvSelect from "../../../common/components/EvvSelect";
import {useDispatch, useSelector, useStore} from "react-redux";
import {appCache} from "../../../cache/slices/app/appSlice";
import {
    defaultDateAsString,
    defaultTimeAsString,
    formatAddress,
    formatClientName,
    formatStaffName,
    getFormattedDurationAsCountdown,
    stringToDate,
    stringToDateWithFormat,
    VISIT_DATE_TIME_FORMAT
} from "../../../common/utils/formatUtils";
import {
    appointmentOverlaps,
    DEFAULT_STATUS,
    EMPTY_ACTIVITY,
    EMPTY_ORGANIZATION,
    EMPTY_PROGRAM,
    EMPTY_SERVICE_LOCATION,
    getActivityQualifiersForActivity,
    getFilteredStatuses,
    getInitialActivity,
    getInitialOrganization,
    getInitialProgram,
    getServiceLocation, isAddressRequiredForOrganization,
    sortActivities,
    sortPrograms,
    sortServiceLocations,
    validateRangeDate,
    validateRangeTimePicker,
    createNewAppointment,
    getAddresses,
    getAllAddresses,
    apiAddresses,
    checkIfDisabled,
    isEvvActivity,
    getInSessionVisits
} from "../../../common/utils/appointmentUtils";
import LoginTextField from "../../../common/components/LoginTextField";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import SearchField from "../../../common/components/SearchField";
import clientService from "../../../common/services/clientService";
import syncService from "../../../common/services/syncService";
import AlertDialog from "../../../common/components/AlertDialog";
import {combineDateAndTime, getHoursTime, validateCurrentDate, validateCurrentTime} from "../../../common/utils/dateTimeUtils";
import CircularProgress from "@material-ui/core/CircularProgress";
import ActivityQualifiers from "../../../common/components/ActivityQualifiers";
import TimePickerField from "../../../common/components/TimePicker/TimePickerField";

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Stack from '@mui/material/Stack';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import TextField from '@mui/material/TextField';
import AddressDialog from "../../../common/components/AddressDialog";
import {sortWithDirection} from "../../../common/utils/miscUtils";
import geocodingService from "../../../common/services/geocodingService";
import { styled } from "@mui/material";
import { PRIMARY_COLOR, START_DATE_ERROR_MESSAGE, START_TIME_ERROR_MESSAGE } from "../../../common/constants";

const useStyles = makeStyles((theme) => ({
    dialogPaper: {
        margin: '30px 8px 30px 8px',
        maxWidth: '660px',
        width: '660px'
    },
    dialogPaperForHandHeld: {
        margin: '30px 8px 30px 8px',
        maxWidth: '660px',
        width: 'calc(100% - 16px)'
    },
    dialogTitle: {
        backgroundColor: PRIMARY_COLOR,
        height: '51px',
        fontSize: '18px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: "#ffffff",
        paddingLeft: '16px',
        display: 'flex',
        alignItems: 'center'
    },
    dialogContent: {
        padding: "19px 19px 25px 19px",
        overflowX: 'hidden'
    },
    dateRow: {
        [theme.breakpoints.down('sm')]: {
            width: '125px'
        },
        [theme.breakpoints.up(601)]: {
            width: '225px'
        }
    },
    timeRow: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: ' 103px 103px',
        columnGap: '15px'
    },
    dateTimeRow: {
        width: '100%',
        display: 'grid',
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '112px 112px 112px',
            columnGap: '10.6px',
        },
        [theme.breakpoints.up(601)]: {
            gridTemplateColumns: '226px 104px 103px',
            columnGap: '25px',
        },
    },
    okCancelButtonGroup: {
        padding: "0px 16px 15px 16px",
        justifyContent: 'space-between'
    },
    okButton: {
        padding: '0',
        minWidth: '125px'
    },
    cancelButton: {
        padding: '0'
    },
    loadingSpinnerContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: '125px'
    },
    loadingSpinner: {
        color: PRIMARY_COLOR
    },
    label: {
        flexDirection: 'column',
        textTransform: "none",
        color: '#888888'
    },
    newAddressButton: {
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        display: 'inline',
        margin: '0px',
        padding: '12px 0px 0px 0px',
        fontSize: '16px',
        fontWeight: "bold",
        fontStyle: "normal",
        color: PRIMARY_COLOR,
        '&:disabled':{
            color:'#e0e0e0'
        }
    },
    searchFieldContainerForHandheld: {
        width: '100%',
        paddingBottom: '12px'
    },
    filterSelect: {
        width: '100%',
        height: '27px',
        borderRadius: '2px',
        background: "transparent",
        borderStyle: "solid",
        borderWidth: '1px',
        borderColor: "#b6b6b6"
    },
    errorMessage : {
        position: 'relative',
        bottom: '-5px',
        fontSize: '13px',
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: '16px',
        letterSpacing: 0,
        color: "#dc0707"
    },
    optionValue : {
        fontSize: '16px',
        fontWeight: "normal",
        fontStyle: "normal",
        color: "#a0a0a0"
    },
    selectLabel: {
        fontSize: '16px',
        fontWeight: "bold",
        fontStyle: "normal",
        color: "#000000",
        paddingBottom: '4px'
    },
}));

const ALERT_DIALOG_ERROR = 'error';
const ALERT_DIALOG_OVERLAP = 'overlap';
const ALERT_DIALOG_OVERNIGHT = 'overnight';
const ALERT_DIALOG_OUT_OF_RANGE = 'out_of_range';

export default function AppointmentDialog({appointment, showStatus = true, disableStatus = false, onClose, showEditAppointmentDialog, clientData, call = "appointment" }){
    const activityQualifiers = useSelector(syncService.descriptorCacheService.activityQualifiersCache.getResults()) || [];
    const clientPrograms = useSelector(syncService.clientProgramService.getResults());
    const programs = useSelector(syncService.programService.getResults());
    const activityPrograms = useSelector(syncService.activityProgramService.getResults());
    const activities = useSelector(syncService.activityService.getResults());
    const serviceLocationOrganizations = useSelector(syncService.serviceLocationOrgService.getResults());
    const serviceLocations = useSelector(syncService.serviceLocationService.getResults());
    const appointments = useSelector(syncService.appointmentService.getResults());
    const caseloads = useSelector(syncService.caseloadService.getResults()) ;
    const clients = useSelector(appCache.getClients);
    const user = useSelector(appCache.getUser);
    const staff = user.staff;

    let client = appointment.client;

    if(!client && clients && clients.length > 0){
         client = clients.find(c => Number(c.clientId) === Number(appointment.clientId));
    }   

    const store = useStore();

    const [searchableClients, setSearchableClients] = useState([]);

    const [selectedClient, setSelectedClient] = useState(client);
    const [sortedPrograms, setSortedPrograms] = useState([]);
    const [selectedProgram, setSelectedProgram] = useState(EMPTY_PROGRAM);
    const [sortedOrganizations, setSortedOrganizations] = useState([]);
    const [selectedOrganization, setSelectedOrganization] = useState(EMPTY_ORGANIZATION);
    const [sortedActivities, setSortedActivities] = useState([]);
    const [selectedActivity, setSelectedActivity] = useState(EMPTY_ACTIVITY);
    const [sortedServiceLocations, setSortedServiceLocations] = useState([]);
    const [serviceLocation, setServiceLocation] = useState(EMPTY_SERVICE_LOCATION);
    const [newAddresses, setNewAddresses] = useState(null);
    const [startAddress, setStartAddress] = useState('');
    const [newStartAddress, setNewStartAddress] = useState(null);
    const [endAddress, setEndAddress] = useState('');
    const [newEndAddress, setNewEndAddress] = useState(null);
    const [addressToEdit, setAddressToEdit] = useState(null);

    const [status, setStatus] = useState(appointment.status || DEFAULT_STATUS);
    const [activityDetailDescriptorList, setActivityDetailDescriptorList] = useState(appointment.activityDetailDescriptorList ? [...appointment.activityDetailDescriptorList] : []);
    const [activityQualifiersForActivity, setActivityQualifiersForActivity] = useState([]);

    const [appointmentDate, setAppointmentDate] = useState(defaultDateAsString(appointment.startDateInstance));
    const appointmentTime = createNewAppointment(DateTime.now());
    const startTimeInstance = appointment.startDateInstance;
    const endTimeInstance = appointment.endDateInstance;
    const startTimeEndTimeDuration = endTimeInstance - startTimeInstance;
    const currentEndTimeInstance = appointmentTime.startDateInstance.plus({millisecond: startTimeEndTimeDuration});

    const [startTime, setStartTime] = useState(disableStatus ? defaultTimeAsString(appointmentTime.startDateInstance) : defaultTimeAsString(appointment.startDateInstance));
    const [endTime, setEndTime] = useState(disableStatus ? defaultTimeAsString(currentEndTimeInstance) : defaultTimeAsString(appointment.endDateInstance));
    // eslint-disable-next-line
    const [isEndTimeChanged, setIsEndTimeChanged] = useState(false);
    const [useForEndAddress, setUseForEndAddress] = useState(true);
    const [alertDialogConfig, setAlertDialogConfig] = useState(null);

    const isHandheld = useSelector(appCache.isHandheld);
    const separateDateTimeRows = isHandheld;
    const [saveInProgress, setSaveInProgress] = useState(false);
    const styles = useStyles();
    const [overlapsAndOtherScheduled, setOverlapsAndOtherScheduled] = useState(false);
    const [overnight, setOvernight] = useState(false);
    const [openAlertDialog, setOpenAlertDialog] = useState(true);
    const dispatch = useDispatch();
    const evvState = selectedOrganization?.evvState;
    const [clientAddressData, setClientAddressData] = useState([]);
    let currentTime = DateTime.now();
    let currentTimeFormat = getHoursTime(defaultTimeAsString(currentTime));
    const [inSessionVisits, setInSessionVisits] = useState([]);

    const fieldToSetterMap = {
        status: setStatus,
        appointmentDate: setAppointmentDate,
        startTime: setStartTime,
        endTime: setEndTime
    };

    useEffect(() => {
        getInSessionVisits(setInSessionVisits);
    }, []);

 useEffect(() => {
        if (clientData && clientData.length > 0) {
            clientData.forEach((client) => {
                if(client?.clientId === appointment?.client?.clientId){
                    setClientAddressData(client.addresses);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientData]);

    useEffect(() => {
        if (client){
            syncService.auditService.saveAuditEvent(null, client, user, "EVV Appointment Details", "View");
        } else {
            syncService.auditService.saveAuditEvent(clients, null, user, "EVV Appointment Details", "View");
        }
    }, [client, clients, user]);

    useEffect(() => {
        const organization = appointment.organization;        
        let startAddressId;
        let endAddressId;

        if (client && organization && isAddressRequiredForOrganization(organization) ){
            if (appointment?.startVisitAddress?.visitAddressId) {
                const startVisitAddressId = parseInt(appointment?.startVisitAddress?.visitAddressId);
                let foundStartAddress = findAddressById(clientAddressData, startVisitAddressId);
                setStartAddress(foundStartAddress);
                startAddressId = startVisitAddressId;
            } else if (appointment.startVisitAddress){
                const addressByPersonId = findAddressById(clientAddressData, appointment.startVisitAddress.visitAddressId);
                if (addressByPersonId){
                    setStartAddress(addressByPersonId);
                    startAddressId = appointment.startVisitAddress.visitAddressId;
                } else {
                    appointment.startVisitAddress = appointment.startVisitAddress.id ? appointment.startVisitAddress : {...appointment.startVisitAddress, id: appointment.startVisitAddress.visitAddressId}
                    setNewStartAddress(appointment.startVisitAddress);
                    setStartAddress(appointment.startVisitAddress);
                    startAddressId = appointment.startVisitAddress.id;
                }
            }

            if (appointment.endVisitAddressId) {
                const endVisitAddressId = parseInt(appointment.endVisitAddressId);
                const foundEndAddress = findAddressById(clientAddressData, endVisitAddressId);
                setEndAddress(foundEndAddress);
                endAddressId = endVisitAddressId;
            } else if (appointment.endVisitAddress){
                const addressByPersonId = findAddressById(clientAddressData, appointment.endVisitAddress.visitAddressId);
                if (addressByPersonId){
                    setEndAddress(addressByPersonId);
                    endAddressId = appointment.endVisitAddress.visitAddressId;
                } else {
                    if(appointment.endVisitAddress.city && appointment.endVisitAddress.street1 && appointment.endVisitAddress.city) {
                        appointment.endVisitAddress = appointment.endVisitAddress.id ? appointment.endVisitAddress : {...appointment.endVisitAddress, id: appointment.endVisitAddress.visitAddressId}
                        setNewEndAddress(appointment.endVisitAddress);
                        setEndAddress(appointment.endVisitAddress);
                        endAddressId = appointment.endVisitAddress.id;
                    }
                }
            }

            if (startAddressId && endAddressId) {
                setUseForEndAddress(startAddressId == endAddressId);
            } else {
                setUseForEndAddress(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientAddressData]);

    useEffect(() => {
        const searchableClientsFromService = clientService.getSearchableClients(caseloads, appointments, clients);

        setSearchableClients(searchableClientsFromService);

        if (searchableClientsFromService && selectedClient){
            setSelectedClient(searchableClientsFromService.find(c => c.clientId === selectedClient.clientId))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [caseloads, appointments, clients]);

    useEffect(() => {
        const availableOrganizations = syncService.organizationService.getOrganizationAndDescendants(user.currentOrganizationId);

        setSortedOrganizations(availableOrganizations.filter(o => o.evv === true));

        if (selectedOrganization?.organizationId === EMPTY_ORGANIZATION.organizationId || !availableOrganizations.find(o => o.organizationId === selectedOrganization.organizationId)) {
            const initialOrganization = getInitialOrganization(user, appointment, availableOrganizations);
            setSelectedOrganization(initialOrganization);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.currentOrganizationId]);

    useEffect(() => {
        const apptDateInstance = stringToDate(appointmentDate).startOf('day');
        const apptStartDateInstance = combineDateAndTime(apptDateInstance, startTime);

        const validClientPrograms = clientPrograms.filter(cp => {
           const cpBeginDateInstance = DateTime.fromISO(cp.beginDate, {zone: 'US/Central'});
           const cpEndDateInstance = cp.endDate ? DateTime.fromISO(cp.endDate, {zone: 'US/Central'}) : DateTime.now().plus({years: 1});
           return (apptStartDateInstance >= cpBeginDateInstance && apptStartDateInstance <= cpEndDateInstance) || 
           (appointment.appointmentId && cp.programId == appointment.programId);
        }).map(cp => cp);

        const programsByClient = validClientPrograms.filter(cp => cp.clientId == selectedClient?.clientId).map(cp => programs.find(p => p.programId === cp.programId)).filter(p => p);
        const programIds = programsByClient.map(prog => prog.programId);
        const programsFiltered = programs.filter(prog => programIds.includes(prog.programId));
        const programsByOrganization = programsByClient.length > 0 &&
            selectedOrganization && selectedOrganization.organizationId !== EMPTY_ORGANIZATION.organizationId
            ? syncService.organizationService.getRecordsForOrganization(programsFiltered, selectedOrganization.organizationId, false)
            : [];
        const sortedPrograms = sortPrograms(programsByOrganization);

        setSortedPrograms(sortedPrograms);

        if (selectedProgram.programId === EMPTY_PROGRAM.programId || !sortedPrograms.find(p => p.programId === selectedProgram.programId)) {
            setSelectedProgram(getInitialProgram(appointment, sortedPrograms));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedClient?.clientId, selectedOrganization, appointment, clientPrograms, programs, appointmentDate, startTime]);

    useEffect(() => {
        const activitiesByOrganization = selectedOrganization && selectedOrganization.organizationId !== EMPTY_ORGANIZATION.organizationId
            ? syncService.organizationService.getRecordsForOrganization(activities, selectedOrganization.organizationId)
            : [];
        const filteredActivities = activityPrograms.filter(ap => ap.programId == selectedProgram?.programId).map(ap => activitiesByOrganization.find(a => ap.activityId === a.activityId)).filter(a => a) || [];
        let sortedActivities = sortActivities(filteredActivities);

        setSortedActivities(sortedActivities);

        if (selectedActivity?.activityId === EMPTY_ACTIVITY.activityId || !activities.find(a => a.activityId === selectedActivity.activityId)) {
            setSelectedActivity(getInitialActivity(appointment, sortedActivities));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProgram, appointment, selectedOrganization, activityPrograms, activities]);

    useEffect(() => {
        const filteredServiceLocations = serviceLocationOrganizations.filter(slo => slo.organizationId == selectedOrganization?.organizationId).map(slo => serviceLocations.find(sl => sl.serviceLocationId === slo.serviceLocationId)).filter(sl => sl) || [];
        const sortedServiceLocations = sortServiceLocations(filteredServiceLocations);

        setSortedServiceLocations(sortedServiceLocations);

        if (serviceLocation?.serviceLocationId === EMPTY_SERVICE_LOCATION.serviceLocationId || !serviceLocations.find(sl => sl.serviceLocationId === serviceLocation.serviceLocationId)){
            setServiceLocation(getServiceLocation(appointment, sortedServiceLocations));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOrganization, appointment, serviceLocationOrganizations, serviceLocations]);

    useEffect(() => {
        if (isAddressRequired() && selectedClient && selectedClient.addresses){
            const filteredAddresses = selectedClient.addresses
                .filter(a => a.type === 'PHYS' || a.type === 'EVV');

            if (!startAddress){
                if (filteredAddresses.length === 1){
                    setStartAddress(filteredAddresses[0]);
                }
            }

            if (!endAddress){
                if (filteredAddresses.length === 1){
                    setEndAddress(filteredAddresses[0]);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOrganization, selectedClient]);

    const resetAddresses = () => {
        setUseForEndAddress(true);
        setStartAddress(null);
        setEndAddress(null);
        setNewStartAddress(null);
        setNewEndAddress(null);
        setAddressToEdit(null);
    };

    const handleFieldChange = (evt) => {
        fieldToSetterMap[evt.target.id](evt.target.value);
        if(evt.target.id === "endTime") {
            setIsEndTimeChanged(true);
        }
    };

    const handleClientChange = (evt, value) => {
        setSelectedClient(value);
        resetAddresses();
        setSelectedActivity(EMPTY_ACTIVITY);
    }

    const showStartAddress = () => {
        setAddressToEdit({source: 'user', addressType: 'start'});
    }

    const showEndAddress = () => {
        setAddressToEdit({source: 'user', addressType: 'end'});
    }

    const resetEndAddress = (evt) => {
        let value = startAddress ? startAddress.id : '';
        evt.target.value = value;
        if(evt.target.checked) {
            setUseForEndAddress(true);
            handleStartAddressChange(evt, true);
        } else {
            let allAddresses = getAddresses(selectedClient.addresses, newAddresses)
            if(allAddresses.length > 1) {
                setEndAddress(null);
            }
        }
    }

    const handleAddressDialogClose = (address) => {
        setAddressToEdit(null);
        
        if (address) {
            const matchingAddress = findExistingAddress(selectedClient.addresses, address);
            const addressToSet = matchingAddress ?? address;
            setNewAddresses(address);
            if (address.addressType === 'start'){
                let newAddress = matchingAddress ? null : address;
                let allAddresses = getAllAddresses(selectedClient.addresses, newAddress, address.addressType);
                setStartAddress(addressToSet);
                setNewStartAddress(newAddress);
                setSelectedClient({...selectedClient, addresses: allAddresses});
                if (useForEndAddress){
                    setEndAddress(addressToSet);
                }
            } else {
                let newAddress = matchingAddress ? null : address;
                setEndAddress(addressToSet);
                setNewEndAddress(newAddress);
                let allAddresses = getAllAddresses(selectedClient.addresses, newAddress, address.addressType);
                setSelectedClient({...selectedClient, addresses: allAddresses});
                if (useForEndAddress){
                    setStartAddress(addressToSet);
                }
            }
        }
    }

    const findExistingAddress = (addresses, address) => {
        const formattedAddress = formatAddress(address);
        let foundAddress = null;
        
        if (addresses && addresses.length > 0){
            foundAddress = addresses.find(a => formattedAddress === formatAddress(a));
        }

        return foundAddress;
    }

    const findAddressById = (addresses, addressId, includeNewAddresses = true) => {
        let foundAddress = addresses?.find(address => address.personAddressId == addressId || address.id == addressId);
        if (includeNewAddresses) {
            if (!foundAddress) {
                if (newStartAddress && newStartAddress.id == addressId) {
                    foundAddress = newStartAddress;
                }
            }

            if (!foundAddress) {
                if (newEndAddress && newEndAddress.id == addressId) {
                    foundAddress = newEndAddress;
                }
            }
        }

        return foundAddress;
    }

    const handleStartAddressChange = (evt, resetEndAddress) => {
        setStartAddress(findAddressById(selectedClient.addresses, evt.target.value));
        if (useForEndAddress || resetEndAddress){
            setEndAddress(findAddressById(selectedClient.addresses, evt.target.value));
        }
    };

    const handleUseForEndAddressChange = (evt) => {
        setUseForEndAddress(evt.target.checked);
    }

    const handleEndAddressChange = (evt) => {
        setEndAddress(findAddressById(selectedClient.addresses, evt.target.value));
    };

    const handleProgramChange = (evt) => {
        setSelectedProgram(sortedPrograms.find(program => program.programId == evt.target.value));
        setSelectedActivity(EMPTY_ACTIVITY);
    };

    const handleOrganizationChange = (evt) => {
        setSelectedOrganization(sortedOrganizations.find(org => org.organizationId == evt.target.value));
        setSelectedActivity(EMPTY_ACTIVITY);
    };

    const handleDateOrTimeChange = (evt) => {
        const appointmentDateInstance = stringToDate(evt.target.id === 'appointmentDate' ? evt.target.value : appointmentDate).startOf('day');

        if (appointmentDateInstance.isValid){
            const startDateInstance = combineDateAndTime(appointmentDateInstance, evt.target.id === 'startTime' ? evt.target.value : startTime);
            if (startDateInstance && startDateInstance.isValid){
                appointment.startDateInstance = startDateInstance;
            }

            const endDateInstance = combineDateAndTime(appointmentDateInstance, evt.target.id === 'endTime' ? evt.target.value : endTime);
            if (endDateInstance && endDateInstance.isValid){
                appointment.endDateInstance = endDateInstance;
            }
        }

        handleFieldChange(evt);
    }

    const handleActivityChange = (evt) => {
        const activity = sortedActivities.find(activity => activity.activityId == evt.target.value);

        if(status === 'In Session'){
            setAppointmentDate(new Date().toLocaleString('en-US', { year: 'numeric', month:'2-digit', day:'2-digit' }));
            setStartTime(currentTimeFormat);
        }
        setSelectedActivity(activity);
        setActivityQualifiersForActivity(getActivityQualifiersForActivity(activity, activityQualifiers));
        setActivityDetailDescriptorList([]);
    };

    const handleServiceLocationChange = (evt) => {
        setServiceLocation(sortedServiceLocations.find(serviceLocation => serviceLocation.serviceLocationId == evt.target.value));
    };

    /*istanbul ignore next*/
    const handleActivityQualifierChange = (e, activityQualifier) => {
        if (e.target.checked) {
            activityDetailDescriptorList.push({descriptorId: activityQualifier.descriptorId, descriptorType: 'Activity Qualifiers'})
        } else {
            const index = activityDetailDescriptorList.findIndex(aq => aq.descriptorId === activityQualifier.descriptorId);
            if (index !== -1){
                activityDetailDescriptorList.splice(index, 1);
            }
        }

        const activityDetailDescriptorListParam = activityDetailDescriptorList;
        setActivityDetailDescriptorList(activityDetailDescriptorListParam);
    }

    const lookupGeocodes = (address) => {
        if (!address.latitude || !address.longitude) {
            return geocodingService.findAddress(dispatch, address);
        } else {
            return Promise.resolve(address);
        }
    }

    const handleSave = async () => {
        console.log('Handle save....');
        if (saveInProgress){
            console.log('SAVE ALREADY IN PROGRESS');
            return;
        }

        setSaveInProgress(true);
        const appointmentToSave = await createAppointmentToSave();

        if (validateRangeTimePicker(startTime,endTime)) {
            setOvernight(true);
            setAlertDialogConfig({
                dialogId: ALERT_DIALOG_OVERNIGHT,
                dialogTitle: 'Overnight Appointment',
                dialogMessage: 'You have entered a Start Time and End Time that will result in an activity that spans 2 days. If this is correct, click OK, otherwise click Cancel to correct.',
                showOkButton: true,
                okButtonText: 'Ok',
                showCancelButton: true,
                cancelButtonText: 'Cancel'
            });
            setSaveInProgress(false);
            return;
        }

        if(overlapsAndOtherScheduled && hasOutOfRangeAppointments(appointmentToSave)){
            setOverlapsAndOtherScheduled(false);
            setSaveInProgress(false);
            return;
        }
        else {
            if (hasOutOfRangeAppointments(appointmentToSave)) {
                if (hasOverlappingAppointments(appointmentToSave)) {
                    setOverlapsAndOtherScheduled(true);
                    setSaveInProgress(false);
                    return;
                }
                setSaveInProgress(false);
                return;
            }

            if (hasOverlappingAppointments(appointmentToSave)) {
                setSaveInProgress(false);
                return;
            }
        }

        saveAppointment(appointmentToSave);
    };

    const hasOverlappingAppointments = (appointmentToCheck) => {
        const overlaps = appointmentOverlaps(inSessionVisits, staff, appointmentToCheck, appointments, call);
        if (overlaps){
            if (staff?.isMultiBookSchedule) {
                setAlertDialogConfig({
                    dialogId: ALERT_DIALOG_OVERLAP,
                    dialogTitle: 'Schedule Overlap',
                    dialogMessage: `The activity you are attempting to schedule overlaps with an existing entry for this staff. Do you want to continue?`,
                    showOkButton: true,
                    okButtonText: 'Yes',
                    showCancelButton: true,
                    cancelButtonText: 'No'
                });
            } else {
                setAlertDialogConfig({
                    dialogId: ALERT_DIALOG_ERROR,
                    dialogTitle: 'Schedule Overlap',
                    dialogMessage: `${formatStaffName(staff)} is not authorized to multibook appointments and the scheduled activity on this service date overlaps with an existing entry.`
                });
            }
        }

        return overlaps;
    }

    const hasOutOfRangeAppointments = (appointmentToCheck) => {
        const outOfRange=(appointmentToCheck.appointmentOutRange === 'true');
        if (outOfRange){
            setAlertDialogConfig({
                dialogId: ALERT_DIALOG_OUT_OF_RANGE,
                dialogTitle: 'Confirm appointment date',
                dialogMessage: `This appointment will not be visible within the EVV application because it is out of the date range limit. Do you want to save and continue?`,
                showOkButton: true,
                okButtonText: 'Yes',
                showCancelButton: true,
                cancelButtonText: 'No'
            });
        }
        return outOfRange;
    }

    const handleAlertDialogClose = async (okClicked) => {
        setAlertDialogConfig(null);
        if (!okClicked && alertDialogConfig.dialogId === ALERT_DIALOG_OVERNIGHT){
            setOpenAlertDialog(false);
            return;
        }
        if (okClicked && alertDialogConfig.dialogId !== ALERT_DIALOG_ERROR){
                if(overlapsAndOtherScheduled===false){
                    saveAppointment(await createAppointmentToSave());
                } else {
                    setTimeout(()=>{ dispatch(handleSave); },500);
                }
        } else {
            setOpenAlertDialog(false);
        }
    }

    const createAppointmentAddress = (address) => {
        console.log('Creating appointment address from source: ' + address.source);
        console.log(address);
        const appointmentAddress = {};

        if (address.personAddressId) {
            appointmentAddress.visitAddressId = address.personAddressId;
        } else {
            appointmentAddress.street1 = address.street1;
            appointmentAddress.street2 = address.street2;
            appointmentAddress.aptSuite = address.aptSuite;
            appointmentAddress.city = address.city;
            appointmentAddress.state = address.state;
            appointmentAddress.zip = address.zip;
        }

        appointmentAddress.addressLatitude = address.latitude;
        appointmentAddress.addressLongitude = address.longitude;

        return appointmentAddress;
    }

    const createAppointmentToSave = async () => {
        let formatStartTime;
        let formatEndTime;
        if(status === 'In Session') {
            const currentAppointmentTime = createNewAppointment(DateTime.now());
            let currentTime = defaultTimeAsString(currentAppointmentTime.startDateInstance);
            formatStartTime = currentTime instanceof Date ? currentTime.toLocaleTimeString('en-US', { hour: '2-digit', minute:'2-digit', hour12: true }) : currentTime;
        } else {
            formatStartTime = startTime instanceof Date ? startTime.toLocaleTimeString('en-US', { hour: '2-digit', minute:'2-digit', hour12: true }) : startTime;
        }

        const appointmentDateInstance = stringToDate(appointmentDate).startOf('day');
        const startDateInstance = combineDateAndTime(appointmentDateInstance, formatStartTime);

        if(isEndTimeChanged === false && status === 'In Session') {
            const endCurrentDateInstance = startDateInstance.plus({millisecond: startTimeEndTimeDuration});
            formatEndTime = defaultTimeAsString(endCurrentDateInstance);
        } else {
            formatEndTime = endTime instanceof Date ? endTime.toLocaleTimeString('en-US', { hour: '2-digit', minute:'2-digit', hour12: true }) : endTime;
        }

        let newEndDateInstance;
        if (overnight) {
            const appointmentEndDateInstance = appointmentDateInstance.plus({day : 1 });
            newEndDateInstance = combineDateAndTime(appointmentEndDateInstance, formatEndTime);
        } else {
            newEndDateInstance = combineDateAndTime(appointmentDateInstance, formatEndTime);
        }
        const endDateInstance = newEndDateInstance;
        const appointmentOutRange = validateRangeDate(appointmentDate);
        const appointmentToSave = {
            ...appointment,
            activityId: selectedActivity ? selectedActivity.activityId : '',
            activity: selectedActivity,
            appointmentDate: startDateInstance.toISO({ suppressSeconds: true, suppressMilliseconds: true, includeOffset: false }),
            appointmentType: 'C',
            clientId: parseInt(selectedClient.clientId),
            client: selectedClient,
            duration: getFormattedDurationAsCountdown(startDateInstance, endDateInstance),
            endTime: formatEndTime,
            hadDocument: 'N',
            incidentBillToStaff: '',
            incidentBillToStaffId: 1,
            nonBillableMinutes: 0,
            organizationId: selectedOrganization ? selectedOrganization.organizationId : '',
            organization: selectedOrganization,
            programId: selectedProgram ? selectedProgram.programId : '',
            program: selectedProgram,
            serviceLocationId: serviceLocation.serviceLocationId,
            serviceLocation: serviceLocation,
            serviceDocumentId: '',
            staffId: user.staffId,
            startTime: formatStartTime,
            status: status,
            startDateInstance,
            endDateInstance,
            appointmentOutRange : appointmentOutRange
        };

        delete appointmentToSave.startVisitAddressId;
        delete appointmentToSave.endVisitAddressId;

        if (isAddressRequired()) {
            appointmentToSave.sameAsEndAddress = useForEndAddress;
            if (useForEndAddress){
                if (startAddress) {
                    const startAddressResult = await lookupGeocodes(startAddress);
                    const startAddResult = startAddressResult ? startAddressResult : apiAddresses(startAddress, clientAddressData);
                    appointmentToSave.startVisitAddress = createAppointmentAddress(startAddResult ?? startAddress);
                    delete appointmentToSave.endVisitAddress;
                }
            } else {
                if (startAddress && endAddress) {
                    if (formatAddress(startAddress) === formatAddress(endAddress)){
                        const startAddressResult = await lookupGeocodes(startAddress);
                        const startAddResult = startAddressResult ? startAddressResult : apiAddresses(startAddress, clientAddressData);
                        appointmentToSave.startVisitAddress = createAppointmentAddress(startAddResult ?? startAddress);
                        appointmentToSave.sameAsEndAddress = true;
                    } else {
                        const [startAddressResult, endAddressResult] = await Promise.all([lookupGeocodes(startAddress), lookupGeocodes(endAddress)]);
                        appointmentToSave.startVisitAddress = createAppointmentAddress(startAddressResult ?? startAddress);
                        appointmentToSave.endVisitAddress = createAppointmentAddress(endAddressResult ?? endAddress);
                    }
                }
            }
        }

        if (status === 'Kept'){
            appointmentToSave.activityDetailDescriptorList = activityDetailDescriptorList.filter(desc => Number.isInteger(desc.descriptorId) && desc.descriptorId > 0);
        }

        return appointmentToSave;
    }

    const formatErrorMessage = (message) => {
        return message.replace('ERROR/~/', '');
    }

    const saveAppointment = (appointmentToSave) => {
        setSaveInProgress(true);

        syncService.appointmentService.saveAppointment(appointmentToSave, {store, doNotClear: true, ignoreVisitSave: disableStatus})
            .then(savedAppointment => {
                onClose(savedAppointment);
                setSaveInProgress(false);
            })
            .catch(error => {
                setSaveInProgress(false);
                if (error.message) {
                    setAlertDialogConfig({
                        dialogId: ALERT_DIALOG_ERROR,
                        dialogTitle: 'Cannot save the appointment',
                        dialogMessage: formatErrorMessage(error.message)
                    });
                } else {
                    setAlertDialogConfig({
                        dialogId: ALERT_DIALOG_ERROR,
                        dialogTitle: 'Cannot save the appointment',
                        dialogMessage: error.toString()
                    });
                }
            });
    }

    const handleCancel = () => {
        if (onClose){
            onClose(null);
        }
    }

    const renderClientField = () => {
        if (client){
            return (
                <LoginTextField id='selectedClient' disabled={true} value={formatClientName(client)} />
            );
        } else {
            return (
                <div className={ isHandheld ? styles.searchFieldContainerForHandheld : ''} >
                    <SearchField
                        id='selectedClient'
                        placeholder="Search Client Name/ID"
                        options={searchableClients}
                        getOptionLabel={(c) => formatClientName(c)}
                        getOptionSelected={(option, value) => option.clientId === value.clientId}
                        onChange={handleClientChange}
                        value={selectedClient}
                    />
                </div>
            );
        }
    }

    const renderDateTime = () => {
        if (separateDateTimeRows){
            return (
                <>
                    <div className={styles.dateRow}>
                        {renderAppointmentDate()}
                    </div>
                    <div className={styles.timeRow}>
                        {renderAppointmentTime()}
                    </div>
                </>
            );
        } else {
            return (
                <div className={styles.dateTimeRow}>
                    {renderAppointmentDate()}
                    {renderAppointmentTime()}
                </div>
            );
        }
    };
    const CancelText=styled('span')({
        textTransform:'initial'
    });
    const OkText=styled('span')({
        textTransform:'initial'
    });

    const renderAppointmentDate = () => {
        let startDateStatus = checkIfDisabled(status, selectedActivity);
        return (
            <StackedField label='Start Date' mandatory={true} paddingTop="19px" className = 'startDatePicker' selectLabel ={styles.selectLabel}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack>
                        <MobileDatePicker
                        value={startDateStatus ? DateTime.now() : appointmentDate}
                        showToolbar={false}
                        disabled = { startDateStatus }
                        onChange={(newValue) => {
                            setAppointmentDate(`${newValue.toLocaleString('en-US', { year: 'numeric', month:'2-digit',day:'2-digit' })}`);
                        }}
                        okText={<OkText>Ok</OkText>}
                        cancelText={<CancelText>Cancel</CancelText>}
                        renderInput={(params) => <TextField disabled={false}
                            id="appointmentDate"
                            onChange={handleDateOrTimeChange}
                            {...params}
                            classes={{root: styles.selectLabel}}
                            focused={false}
                        />}
                        />
                    </Stack>
                    { status === 'In Session' && validateCurrentDate(appointmentDate) &&
                        <div className={styles.errorMessage}>{START_DATE_ERROR_MESSAGE}</div>
                    }
                </LocalizationProvider>
            </StackedField>
        );
    };

    const renderAppointmentTime = () => {
        let startTimeStatus = checkIfDisabled(status, selectedActivity);
        return (
            <>
                 <div>
                    <StackedField mandatory={true} label='Start Time' paddingTop="17px" selectLabel ={styles.selectLabel}>
                        <TimePickerField id='startTime' disabled={startTimeStatus} value={startTimeStatus ? currentTimeFormat : startTime} onChange={handleDateOrTimeChange} classes={ styles.selectLabel}/>
                    </StackedField>
                    { status === 'In Session' && validateCurrentTime(startTime, appointmentDate) &&
                        <div className={styles.errorMessage}>{START_TIME_ERROR_MESSAGE}</div>
                    }
                </div>
                <StackedField mandatory={true} label='End Time' paddingTop="17px" selectLabel ={styles.selectLabel}>
                    <TimePickerField id='endTime' value={endTime} onChange={handleDateOrTimeChange}/>
                </StackedField>
            </>
        );
    };

    const renderSaveButton = () => {
        if (saveInProgress) {
            return (
                <div className={styles.loadingSpinnerContainer}>
                    <CircularProgress size={40} className={styles.loadingSpinner} />
                </div>
            );
        } else {
            return (
                <div className={styles.okButton}>
                    <EvvButton
                        type='primary'
                        disabled={isSaveDisabled()}
                        onClick={handleSave}
                    >
                        Save
                    </EvvButton>
                </div>
            );
        }
    }

    const isSaveDisabled = () => {
        if (isEvvActivity(selectedActivity) && isAddressRequired()){
            if (startAddress){
                if (!useForEndAddress && !endAddress){
                    return true;
                }
            } else {
                return true;
            }
        }

        return selectedProgram?.programId === EMPTY_PROGRAM.programId || selectedOrganization?.organizationId === EMPTY_ORGANIZATION.organizationId || selectedActivity?.activityId === EMPTY_ACTIVITY.activityId || serviceLocation?.serviceLocationId === EMPTY_SERVICE_LOCATION.serviceLocationId || !status || ( status === 'In Session' && (validateCurrentDate(appointmentDate) || validateCurrentTime(startTime, appointmentDate)) );
    }

    const renderAddressOptions = (addressName) => {
        const addressOptions = [];

        if (selectedClient && selectedClient.addresses && selectedClient.addresses.length > 0){
            const addressesToSort = selectedClient.addresses
                .filter(a => (a.zip !== "" && a.city !== "" && a.street1 !== "") && (a.type === 'PHYS' || a.type === 'EVV'))
                .map(a => ({...a, createdDateMillis:stringToDateWithFormat(a?.audit?.createdDate, VISIT_DATE_TIME_FORMAT) ?? 0}));
            if (newStartAddress && !findAddressById(selectedClient.addresses, newStartAddress.id, false)){
                addressesToSort.push(newStartAddress);
            }
            if (newEndAddress && !findAddressById(selectedClient.addresses, newEndAddress.id, false)){
                addressesToSort.push(newEndAddress);
            }

            const sortedAddresses = sortWithDirection(addressesToSort, "createdDateMillis", "desc");

            if (sortedAddresses.length > 1){
                addressOptions.push(<option key={`${addressName}_selectAddress`}
                                            value={''}>Select Address</option>);
            }

            sortedAddresses.forEach((address, index) => {
                addressOptions.push(<option key={`${addressName}_${index}`}
                                            value={address.id}>{formatAddress(address, evvState)}</option>);
            });
        } else {
            addressOptions.push(<option key={`${addressName}_selectAddress`}
                                        value={''}>Select Address</option>);
        }

        return addressOptions;
    };

    const isAddressRequired = () => {
        return isAddressRequiredForOrganization(selectedOrganization);
    }

    const renderAddresses = () => {
        const addressComponents = [];

        if (isEvvActivity(selectedActivity) && isAddressRequired()){
            addressComponents.push((
                <div key='startAddressSelect'>
                    <StackedField mandatory={true} label='Start Address' paddingTop="17px" selectLabel ={styles.selectLabel}>
                        <EvvSelect id='startAddress'
                                   value={startAddress ? startAddress.id : ''}
                                   className={styles.filterSelect}
                                   onChange={handleStartAddressChange}>
                            {renderAddressOptions('startAddress')}
                        </EvvSelect>
                    </StackedField>
                </div>
            ));

            addressComponents.push((
                <div key='startAddressNew'><button className={styles.newAddressButton} onClick={showStartAddress} >Enter New Start Address</button></div>
            ));

            addressComponents.push((
                <div key='useForEndAddress'>
                    <FormControlLabel
                        label='Use for End Address'
                        control={
                            <Checkbox
                                data-testid={`checkbox_useForEndAddress`}
                                checked={!!useForEndAddress}
                                onChange={handleUseForEndAddressChange}
                                color='default'
                                name='useForEndAddress'
                                id='useForEndAddress'
                                onClick={resetEndAddress}
                            />
                        }
                    />
                </div>
            ));

            if (!useForEndAddress) {
                addressComponents.push((
                    <div key='endAddressSelect'>
                        <StackedField mandatory={true} label='End Address' paddingTop="17px">
                            <EvvSelect id='endAddress'
                                       value={endAddress ? endAddress.id : ''}
                                       className={styles.filterSelect}
                                       onChange={handleEndAddressChange}>
                                {renderAddressOptions('endAddress')}
                            </EvvSelect>
                        </StackedField>
                    </div>
                ));

                addressComponents.push((
                    <div key='endAddressNew'>
                        <button className={styles.newAddressButton}
                                onClick={showEndAddress}>Enter New End Address
                        </button>
                    </div>
                ));
            }
        }

        return addressComponents;
    };

    const renderContent = () => {
        return (
            <div data-testid={'AppointmentDialog_content'}>
                <div>
                    <StackedField mandatory={!client} label='Client Name' paddingTop="0px" selectLabel ={styles.selectLabel}>
                        {renderClientField()}
                    </StackedField>
                </div>
                {renderDateTime()}
                <div>
                    <StackedField mandatory={true} label='Organization' paddingTop="17px" selectLabel ={styles.selectLabel}>
                        <EvvSelect id='selectedOrganization' value={selectedOrganization ? selectedOrganization.organizationId : EMPTY_ORGANIZATION.organizationId} className={styles.filterSelect} onChange={handleOrganizationChange}>
                            {sortedOrganizations.map((item, index) =>
                                <option key={`organization_${index}`} value={item.organizationId} className={styles.optionValue}>{item.name}</option>)}
                        </EvvSelect>
                    </StackedField>
                </div>
                <div style={{ width: "100%", margin: "auto" }}>
                    <StackedField mandatory={true} label='Program' paddingTop='17px' selectLabel ={styles.selectLabel}>
                        <EvvSelect id='selectedProgram' fullWidth value={selectedProgram ? selectedProgram.programId : EMPTY_PROGRAM.programId} className={styles.filterSelect} onChange={handleProgramChange}
                         SelectProps={{
                            OptionProps: {
                              anchorOrigin: {
                                vertical: "center",
                                horizontal: "center"
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "center"
                              }
                            }
                          }}
                        >

                            {sortedPrograms.map((item, index) =>
                                <option key={`program_${index}`} value={item.programId} className={styles.optionValue}>{item.name + (item.code ? ` (${item.code})` : '') }</option>)
                            }
                        </EvvSelect>
                    </StackedField>
                </div>               
                <div>
                    <StackedField mandatory={true} label='Activity' paddingTop="17px" selectLabel ={styles.selectLabel}>
                        <EvvSelect id='selectedActivity' value={selectedActivity ? selectedActivity.activityId : EMPTY_ACTIVITY.activityId} className={styles.filterSelect} onChange={handleActivityChange}>
                            {sortedActivities.map((item, index) =>
                                <option key={`activity_${index}`} value={item.activityId} className={styles.optionValue}>{item.description + (item.code ? ` (${item.code})` : '')}</option>)}
                        </EvvSelect>
                    </StackedField>
                </div>
                {renderAddresses()}
                <div>
                    <StackedField mandatory={true} label='Service Location' paddingTop="17px" selectLabel ={styles.selectLabel}>
                        <EvvSelect id='serviceLocation' value={serviceLocation ? serviceLocation.serviceLocationId : EMPTY_SERVICE_LOCATION.serviceLocationId} className={styles.filterSelect} onChange={handleServiceLocationChange}>
                            {sortedServiceLocations.map((item, index) =>
                                <option key={`option_${index}`} value={item.serviceLocationId} className={styles.optionValue}>{(item.code ? `${item.code} - ` : '') + item.placeOfService}</option>)}
                        </EvvSelect>
                    </StackedField>
                </div>
                {showStatus &&
                <div>
                    <StackedField mandatory={true} label='Status' paddingTop="17px" selectLabel ={styles.selectLabel}>
                        <EvvSelect id='status' disabled={disableStatus} value={status} className={styles.filterSelect}
                                   onChange={(e) => setStatus(e.target.value)}>
                            {getFilteredStatuses(appointment, showEditAppointmentDialog).map((item, index) =>
                                <option key={`option_${index}`} value={item} className={styles.optionValue}>{item} </option>)}
                        </EvvSelect>
                    </StackedField>
                </div>
                }
                {status === 'Kept' && activityQualifiersForActivity.length > 0 &&
                <div>
                    <StackedField label='Activity Qualifiers' paddingTop="17px" selectLabel ={styles.selectLabel}>
                        <ActivityQualifiers activityQualifiers={activityQualifiersForActivity}
                                            selectedActivities={activityDetailDescriptorList}
                                            onActivityQualifierChange={handleActivityQualifierChange}/>
                    </StackedField>
                </div>
                }
                {alertDialogConfig &&
                <AlertDialog
                    open={openAlertDialog}
                    dialogTitle={alertDialogConfig.dialogTitle}
                    dialogMessage={alertDialogConfig.dialogMessage}
                    showOkButton={alertDialogConfig.showOkButton}
                    showCancelButton={alertDialogConfig.showCancelButton}
                    okButtonText={alertDialogConfig.okButtonText || 'Ok'}
                    cancelButtonText={alertDialogConfig.cancelButtonText || 'Cancel'}
                    handleClose={handleAlertDialogClose}
                />
                }
                {addressToEdit && 
                    <AddressDialog address={addressToEdit} appointment={appointment} onClose={handleAddressDialogClose} />
                }
            </div>
        );
    }    

    return (
            <Dialog
                disableEnforceFocus={true}
                classes={{paper: (isHandheld ? styles.dialogPaperForHandHeld : styles.dialogPaper)}}
                open={true}
                disableEscapeKeyDown={true}
            >
                <DialogTitle disableTypography={true} classes={{root: styles.dialogTitle}} id="alert-dialog-title">Appointment</DialogTitle>
                <DialogContent classes={{root: styles.dialogContent}}>
                    {renderContent()}
                </DialogContent>
                <DialogActions classes={{root: styles.okCancelButtonGroup}}>
                    <div className={styles.cancelButton}>
                        <EvvButton type='tertiary' onClick={handleCancel} >Cancel</EvvButton>
                    </div>
                    {renderSaveButton()}
                </DialogActions>
            </Dialog>
    );
}
