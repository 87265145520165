import {createSlice} from '@reduxjs/toolkit'

export const clientSlice = createSlice({
    name: 'client',
    initialState: {
        selectedClient: null,
        selectedRecentClient: null,
        allergySort: null,
        selectedAllergy: null,
        medicationSort: null,
        selectedMedication: null,
        diagnosesSort: null,
        selectedDiagnosis: null,
        selectedVital: null,
        vitalsSort: null
    },
    reducers: {
        selectClient: (state, action) => {
            const client = action.payload;
            state.selectedClient = client;
            state.selectedRecentClient = client;
            state.allergySort = null;
            state.medicationSort = null;
            state.diagnosesSort = null;
            state.vitalsSort = null;
        },
        sortAllergies: (state, action) => {
            state.allergySort = action.payload;
        },
        selectAllergy: (state, action) => {
            state.selectedAllergy = action.payload;
        },
        sortMedications: (state, action) => {
            state.medicationSort = action.payload;
        },
        selectMedication: (state, action) => {
            state.selectedMedication = action.payload;
        },
        sortDiagnoses: (state, action) => {
            state.diagnosesSort = action.payload;
        },
        selectDiagnosis: (state, action) => {
            state.selectedDiagnosis = action.payload;
        },
        selectRecentClient: (state, action) => {
            state.selectedRecentClient = action.payload;
        },
        selectVital: (state, action) => {
            state.selectedVital = action.payload;
        },
        sortVitals: (state, action) => {
            state.vitalsSort = action.payload;
        }
    }
})

export const clientCache = {
    ...clientSlice.actions,

    getSelectedClient: (state) => state.client.selectedClient,
    getSelectedRecentClient: (state) => state.client.selectedRecentClient,
    getAllergySort: (state) => state.client.allergySort,
    getSelectedAllergy: (state) => state.client.selectedAllergy,
    getMedicationSort: (state) => state.client.medicationSort,
    getSelectedMedication: (state) => state.client.selectedMedication,
    getDiagnosesSort: (state) => state.client.diagnosesSort,
    getSelectedDiagnosis: (state) => state.client.selectedDiagnosis,
    getSelectedVital: (state) => state.client.selectedVital,
    getVitalsSort: (state) => state.client.vitalsSort,
}

export default clientSlice.reducer;
