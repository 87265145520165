import { evvRepository } from "../../db/evv";

const getImpactConfig = (moduleReferenceId) => {
    return evvRepository?.evvDb?.impactConfig.get({id: moduleReferenceId});
}

const impactService = {
    getImpactConfig
};

export default impactService;