
import '../../../src/index.css';
import { appCache } from '../../cache/slices/app/appSlice';

export const webRotationForMobile = (landscape) => {
    // This validations section is web
    if (landscape.matches) {
        document.body.classList.remove('portrait');
    } else {
        document.body.classList.add('portrait');
    }
}

export const webRotationForMobileSafari = (landscape) => {
    if (landscape.matches) {
        document.body.classList.remove('portraitForSafari');
    } else {
        document.body.classList.add('portraitForSafari');
    }
}

export const homeScreenRotationForMobile = (landscapeRotated) => {
    // This validations section is for Home Screen
    if (landscapeRotated.matches) {
        document.body.classList.add('portrait');
    } else {
        document.body.classList.remove('portrait');
    }
}

export const reloadApp = (isHandheld, setCurrentOrientation, dispatch, rdd) => {
    let changedPortrait = window.matchMedia("(orientation: portrait)");
    let changedOrientation = changedPortrait.matches ? "portrait" : "landscape";

    if(isHandheld){
        webRotationForMobile(changedPortrait);
        if(rdd.isIOS && rdd.isSafari) {
            webRotationForMobileSafari(changedPortrait);
        }
    }
    dispatch(appCache.toggleOrientationChange(changedOrientation));
    setCurrentOrientation(changedOrientation);
}