import { evvRepository } from "../../../db/evv";
import { isDescriptorExpired } from "../../../common/utils/appointmentUtils";

//Descriptor API for Descriptor Checkbox
export const descriptorDb = (currentOrganizationId) => {
    return evvRepository.evvDb.descriptor.where('organizationId').anyOf('', currentOrganizationId).toArray();
}

//For Descriptor Checkbox Assign Selected Options
export const checkSelectedOptionAssign = (field) => {
    let temp = true;
    field.options.forEach((option) => {
        if(option.selectedOption){
            temp = false;
        }
    })
    return temp;
}

export const assignOptionsToCheckBox = async (field, documentController) => {
    const currentOrganizationId = documentController?.dataProvider?.getUser()?.currentOrganizationId;
    switch (field.type) {
        case "Descriptor CheckBox":
            await descriptorDb(currentOrganizationId).then((result) => {
                // assign options from table.
                field.options = changeTheFormatAsPerRequirement(field, result);
            return result;
            }).catch((error) => {
               console.log('Error: ', error);
            });
        break;
        default:
        break;
    } 
    return field.options;
};

export const changeTheFormatAsPerRequirement = (field, checkBoxList) => {
    let filteredObj = field.attributes.filter((attribute) => attribute.attributeId === field.id +"_type");
    let attributeType = filteredObj[0].value;
    let attributeFieldId = filteredObj[0].fieldId;
    let options = [];
    
    checkBoxList.forEach((option) => {
        if(option.type === attributeType && !isDescriptorExpired(option?.endDate)) {
            options.push({'id':option.descriptorId,'fieldId' :attributeFieldId,'value': option.name});
        }
    })

    return options;
}