import { Typography } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {makeStyles} from "@material-ui/core/styles";
import EvvCheckBox from "../EvvCheckBox"

const useStyles = makeStyles((theme) => ({
    activityQualifiers: {
        [theme.breakpoints.down('sm')]: {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
        },
        [theme.breakpoints.up(601)]: {
            gridTemplateColumns: '1fr 1fr 1fr',
        },
    },
    activityQualifiersAppointmentDialog: {
        display: 'grid',
        rowGap: '10px',
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '1fr 1fr',
        },
        [theme.breakpoints.up(601)]: {
            gridTemplateColumns: '1fr 1fr 1fr',
        },
    },
    arial:{
        fontSize: '16px',
        color: "#4f4f4f"
    },
}));

export default function ActivityQualifiers({activityQualifiers, selectedActivities = [], onActivityQualifierChange, readOnly = false, viewAppointmentDialog = false}) {
    const styles = useStyles();
    const handleActivityQualifierChange = (e, descriptor) => {
        if (onActivityQualifierChange && !readOnly){
            onActivityQualifierChange(e, descriptor);
        }
    }

    return activityQualifiers && activityQualifiers.length > 0 ? (
        <FormControl component="fieldset" className={viewAppointmentDialog ? styles.activityQualifiersAppointmentDialog : styles.activityQualifiers}>
            {activityQualifiers.map((aq, index) =>
                <FormControlLabel
                    key={`${aq.descriptorId}_${index}`}
                    label={<Typography className={styles.arial}>{aq.name}</Typography>}
                    control={
                        <EvvCheckBox
                            data-testid={`checkbox_${aq.descriptorId}`}
                            checked={selectedActivities.findIndex(selectedActivity => selectedActivity.descriptorId === aq.descriptorId) !== -1}
                            onChange={(e) => handleActivityQualifierChange(e, aq)}
                            color='default'
                            disabled={readOnly}
                            name={aq.name}
                            id={aq.name}
                        />
                    }
                />
            )}
        </FormControl>
    ) : null;
}