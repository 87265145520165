import sortBy from 'lodash/sortBy';
import { caseInsensitiveSort} from '../../features/forms/common/Util';
import { orderBy } from 'lodash';

export const sort = (list, propertyName) => {
    if (propertyName) {
        return sortBy(list, propertyName);
    } else {
        return list ? list.sort() : [];
    }
}
export const sortedByUpperAndLower = (list, propertyName) => (
    list ? orderBy(list, (Array.isArray(propertyName) ? propertyName : [propertyName]).map(prop => item => item[prop]?.toLowerCase()), 'asc') : []
  );

export const sortWithDirection = (list, propertyName, direction, stickyFilter) =>{
   let sortEle = [];

    if (stickyFilter){
        const sticky = list.filter(stickyFilter);
        const sortable = list.filter((record) => !stickyFilter(record));
        sortEle = caseInsensitiveSort(sortable, propertyName, direction);
        return [...sticky, ...sortEle];
    } else {
        sortEle = caseInsensitiveSort(list, propertyName, direction);
        return sortEle;
    }
}

export const sortClients = (clients) => {
    return sortedByUpperAndLower(clients, ['lastName', 'firstName']);
};

export function getRandomInteger(min = 1, max = 10) {
    const randomBuffer = new Uint32Array(1);
    crypto.getRandomValues(randomBuffer);

    let randomNumber = randomBuffer[0] / (0xffffffff + 1);

    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(randomNumber * (max - min + 1)) + min;
}

export function uniqueScalarsByProperty(list, propertyName){
    return list ? [...new Set(list.filter(item => item[propertyName] != null && item[propertyName] !== '').map(item => item[propertyName]))] : [];
}

export function getDispatchFromConfig(config){
    if (config.dispatch){
        return config.dispatch;
    }

    if (config.store){
        return config.store.dispatch;
    }

    throw new Error("Unable to dispatch from config.");
}

export const removePdfTabsFn = (state, action) => {
    let activeTab; 
    const updatedSeriveDocTab = state.serviceDocumentsForTabs.find((item) => item?.id === action?.payload?.id);
    if(updatedSeriveDocTab){
        activeTab = state.serviceDocumentsForTabs[state.selectedDocTabGlobal];
    }
    //Remove signed document from the tab as it gets converted into PDF
    if(updatedSeriveDocTab && updatedSeriveDocTab?.documentStatus === 'Signed') {
        const updatedSeriveDocTabIndex = state.serviceDocumentsForTabs.indexOf(updatedSeriveDocTab);
        state.serviceDocumentsForTabs.splice(updatedSeriveDocTabIndex, 1);
    }

    //We also need to remove duplicate signed documents
    if(updatedSeriveDocTab) {
        const duplicateDocs = state.serviceDocumentsForTabs.filter(item => item?.clientId === updatedSeriveDocTab?.clientId && item?.documentName === updatedSeriveDocTab?.documentName && item?.staffId === updatedSeriveDocTab?.staffId && (item?.documentStatus === 'Signed' || !item?.documentStatus));
        duplicateDocs?.forEach(doc => {
            const index = state.serviceDocumentsForTabs.indexOf(doc);
            state.serviceDocumentsForTabs.splice(index, 1);
        });

        //Set respective active document tab
        const filteredActiveTab = state.serviceDocumentsForTabs.find(item => item?.id === activeTab?.id);
        if(filteredActiveTab?.documentStatus === 'Signed' || !filteredActiveTab?.documentStatus) {
            state.selectedDocTabGlobal = 0;
        } else {
            const filteredActiveTabIndex = state.serviceDocumentsForTabs.indexOf(filteredActiveTab);
            state.selectedDocTabGlobal = filteredActiveTabIndex;
        }
    }
}
