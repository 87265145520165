import {makeStyles} from "@material-ui/core/styles";
import {useSelector} from "react-redux";
import DataTable from "../../../common/components/DataTable";
import {sortWithDirection, uniqueScalarsByProperty} from "../../../common/utils/miscUtils";
import FilterBar from "../../../common/components/FilterBar";
import {useEffect, useState} from "react";
import MedicationInfoCell from "../MedicationInfoCell";
import MedicationStatusCell from "../MedicationStatusCell";
import {formatDate, getOrderByProperty} from "../../../common/utils/tableUtils";
import CircularProgress from "@material-ui/core/CircularProgress";
import syncService from "../../../common/services/syncService";
import {clientCache} from "../../../cache/slices/client/clientSlice";
import {appCache} from "../../../cache/slices/app/appSlice";
import { PRIMARY_COLOR } from "../../../common/constants"

const useStyles = makeStyles(() => ({
    medicationsTab: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column'
    },
    medicationsHeader: {
        width: '100%',
        display: 'flex',
        borderBottom: '1px solid #c1c1c1'
    },
    loadingSpinnerContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    loadingSpinner: {
        color: PRIMARY_COLOR
    },
    noMedicationsFoundContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    noMedicationsFound: {
        fontSize: '18px',
        fontWeight: "bold",
        fontStyle: "normal",
        color: "#000000",
        paddingTop: '56px'
    }
}));

const EMPTY_STATUS_FILTER = 'Show All';

const renderMedicationInfoCell = (row) => {
    return (<MedicationInfoCell medication={row} />);
}

const renderStatusCell = (row) => {
    return (<MedicationStatusCell medication={row} />);
}

const formatMedicationAmount = (row) => {
    return `${row.amount}/${row.refills}`;
}

const columnDescriptors = [
    { id: 'medication', width: '36%', label: 'Medication / Dosage / Sig', contentRenderer: renderMedicationInfoCell },
    { id: 'amount', width: '12%', label: 'Amount / Refills', formatter: formatMedicationAmount },
    { id: 'prescriber', width: '16%', label: 'Prescriber', fieldName: 'prescriber'},
    { id: 'status', sort:"asc", width: '12%', label: 'Status', contentRenderer: renderStatusCell },
    { id: 'beginDate', fieldName: 'beginDate', width: '12%', label: 'Begin Date', formatter: formatDate },
    { id: 'endDate', fieldName: 'endDate', width: '12%', label: 'End Date', formatter: formatDate },
];

const secondaryOrderByProperties = [
    (a) => a.beginDate ? a.beginDate.toMillis() : 0,
    'medication'
]
const secondaryOrderByDirections = ['desc', 'asc'];

function createFilterOptions(medications){
    return [EMPTY_STATUS_FILTER, ...uniqueScalarsByProperty(medications, 'status').sort()];
}

export default function MedicationsTab() {
    const medications = useSelector(syncService.medicationService.getResults());
    const isLoading = useSelector(syncService.medicationService.isLoading());
    const [filteredMedications, setFilteredMedications] = useState(medications);
    const [statusFilter, setStatusFilter] = useState(EMPTY_STATUS_FILTER);
    const styles = useStyles();
    const client = useSelector(clientCache.getSelectedClient);
    const user = useSelector(appCache.getUser);

    useEffect(() => {
        if(medications && medications.length > 0) {
            syncService.auditService.saveAuditEvent(null, client, user, "EVV Client Medications", "View");
        }
    }, [medications, client, user]);

    useEffect(() => {
        if (medications && medications.length > 0) {
            setFilteredMedications(statusFilter === EMPTY_STATUS_FILTER ? [...medications] : medications.filter((medication) => medication.status === statusFilter));
        } else {
            setFilteredMedications([]);
        }
    }, [statusFilter, medications]);

    const handleFilterChange = (status) => {
        setStatusFilter(status);
    };

    const handleSort = (records, columnDescriptor, orderByDirection) => {
        return sortWithDirection(
                records,
                [getOrderByProperty(columnDescriptor), ...secondaryOrderByProperties],
                [orderByDirection, ...secondaryOrderByDirections]
        );
    };

    const renderPage = () => {
        if (isLoading) {
            return (
                <div className={styles.loadingSpinnerContainer}>
                    <CircularProgress size={50} className={styles.loadingSpinner} />
                </div>
            );
        } else {
            return (
                <>
                    <div className={styles.medicationsHeader}>
                        <FilterBar
                            filterByLabel='Filter By Status'
                            options={createFilterOptions(medications)}
                            onFilterChange={handleFilterChange}
                        />
                    </div>
                    <DataTable
                        columnDescriptors={columnDescriptors}
                        records={filteredMedications}
                        handleSort={handleSort}
                    />

                    {(!medications || medications.length === 0) &&
                    <div className={styles.noMedicationsFoundContainer}>
                        <div className={styles.noMedicationsFound}>
                            No Medications Found
                        </div>
                    </div>
                    }
                </>
            );
        }
    }

    return (
        <div className={styles.medicationsTab}>
            {renderPage()}
        </div>
    );
}
