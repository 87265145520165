import { orderBy } from "lodash";
import "../ServiceDocumentWizard/ServiceDocumentWizard.css"
import { isGA } from "../../../common/utils/goalsAddressedUtils";
//It is used to sort elements of the array by key(param)
export const sortElements = (data, param, type) => {
  data.sort(function(check1, check2) {
    let value1 = check1[param];
    let value2 = check2[param];
    let keyA;
    let keyB;
    if(type === 'string') {
      keyA = value1.toString().toLowerCase();
      keyB = value2.toString().toLowerCase();
    } else{
      keyA = (+value1);
      keyB = (+value2);
    }
    if (keyA < keyB) return -1;
    if (keyA > keyB) return 1;
      return 0;
  });
}

export const sortElementsDesc = (data, param, type) => {
  data.sort(function(check1, check2) {
    let value1 = check1[param];
    let value2 = check2[param];
    let keyA;
    let keyB;
    if(type === 'string') {
      keyA = value1?.toString().toLowerCase();
      keyB = value2?.toString().toLowerCase();
    } else{
      keyA = value1;
      keyB = value2;
    }
    if (keyA > keyB) return -1;
    if (keyA < keyB) return 1;
      return 0;
  });
}

export const caseInsensitiveSort = (list, propertyName, direction) => {
  const propertyNameForSorting = ['allergyName', 'medication'];
  let data = [...list];
  
  if(propertyNameForSorting.includes(propertyName[0])) {
    if(direction[0] === 'asc') {
      sortElements(data, propertyName[0], 'string');
    } else {
      sortElementsDesc(data, propertyName[0], 'string')
    }
    return data;
  } else {
    return orderBy(data, propertyName, direction);
  }
}

export const renderName = (pageDescriptor, pageIndex, currentPageIndex ) => {
  const isSelected = (pageIndex === currentPageIndex);

  if ( isGA(pageDescriptor) && isSelected ) {
    return <><div className='configurableFormName'>{pageDescriptor.name}</div></>
  } else {
    if(pageDescriptor?.moduleId === pageDescriptor?.configurableForm?.moduleId && isSelected){
      return <><div className='configurableFormName'>{pageDescriptor.name}</div></>
    }
  }
  
}

export const getXSAndSMValueForField = (isHandheld, orientation, defaultXSValue, defaultSMValue) => {
  let breakPoints = {
    xsValue: defaultXSValue,
    smValue: defaultSMValue,
  };

  if(isHandheld && orientation === "landscape") {
    breakPoints.xsValue = defaultSMValue;
    breakPoints.smValue = defaultXSValue;
  }
  return breakPoints;
}

export const renderReasonCodesOptions = (reasonCodes) => {
  let reasonCodesOptions = [];
  let sortByOrder = [];
  let sortByName = [];

  if (reasonCodes && reasonCodes.length > 0){
      reasonCodes.forEach(reasonCode => {
        if(reasonCode.order !== "") {
          sortByOrder = [...sortByOrder, reasonCode];
        } else {
          sortByName = [...sortByName, reasonCode];
        }
      })
      sortElements(sortByOrder, 'order', 'number'); //Sort reason codes by order
      sortElements(sortByName, 'name', 'string'); //Sort reason codes by name
      reasonCodes = [...sortByOrder, ...sortByName];

      reasonCodesOptions.push(<option key={`option_default`} value={''}>Select One</option>);
      reasonCodes.forEach((item, index) => {
          reasonCodesOptions.push(<option key={`option_${index}`} value={item.mappedValue}>{item.name}</option>);
      });
  } else {
    reasonCodesOptions.push(<option key={`option_default`} value={''}>Select One</option>);
  }
  return reasonCodesOptions;
};

export const isCarelogicMobile = () => {
  const hostname = window.location.hostname;
  return hostname.startsWith('mobile');
}
 
export const isOnline = () => {
  return window.navigator.onLine;
}