import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Table, TableBody, TableCell, TableContainer, TableRow} from "@material-ui/core";
import DataTableHead from "./DataTableHead";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    rootScrolling: {
        width: '100%',
        overflow: 'auto'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        // minWidth: 750,
        '& > tbody > :nth-of-type(odd)':{
            backgroundColor: '#f4f4f4',
        },
    },
    tableBodyHidden: {
        visibility: 'collapse'
    },
    tableRow: {
        paddingLeft: '11px',
        paddingRight: '20px'
    },
    tableCell: {
        ...theme.cell,
        color: "#424242",
    },
    tableCellBold: {
        ...theme.cell,
        fontWeight: "bold",
        color: "#424242",
    },
    colSpanMessage: {
        fontSize: '16px',
        fontWeight: "bold",
        fontStyle: "normal",
        color: "#000000"
    },
    customTableContainer: {
        overflowX: "initial"
    }
}));

export default function DataTable({columnDescriptors, records, handleSort, shouldSpanColumns}) {
    const [sortedRecords, setSortedRecords] = useState(records);
    const [orderByProperty, setOrderByProperty] = useState('');
    const [orderByDirection, setOrderByDirection] = useState('');
    const styles = useStyles();

    useEffect(() => {
        const columnDescriptor = columnDescriptors.find(cd => cd.sort != null && cd.sort.length > 0);
        if (columnDescriptor){
            sort(columnDescriptor, columnDescriptor.sort);
        }
        /* eslint-disable react-hooks/exhaustive-deps */
    }, [records]);

    const sort = (columnDescriptor, sortDirection) => {
        if (handleSort){
            const results = handleSort(records, columnDescriptor, sortDirection);

            if (results) {
                setOrderByDirection(sortDirection);
                setOrderByProperty(columnDescriptor.id);
                setSortedRecords(results);
            }
        }
    };

    const handleSortRequest = (columnDescriptor) => {
        let sortDirection = 'asc';

        if (columnDescriptor.id === orderByProperty && orderByDirection === 'asc'){
            sortDirection = 'desc';
        }

        sort(columnDescriptor, sortDirection);
    };

    const renderCellContent = (row, cd) => {
        if (cd.contentRenderer){
            return cd.contentRenderer(row, cd);
        }

        if (cd.formatter){
            return cd.formatter(row, cd);
        }

        if (cd.fieldName) {
            return row[cd.fieldName];
        }

        return 'NO CONTENT!!';
    };

    const renderCell = (row, cd, rowIndex, columnIndex) => {
        const padding = cd.padding ? cd.padding : 'normal';

        return (
            <TableCell
                key={`${cd.id}_${rowIndex}_${columnIndex}`}
                className={cd.bold ? styles.tableCellBold : styles.tableCell}
                align="left"
                padding={padding}
            >
                {renderCellContent(row, cd)}
            </TableCell>
        );
    };

    const renderRow = (row, rowIndex) => {
        return (
            <TableRow key={`row_${rowIndex}`} className={styles.tableRow} tabIndex={-1} >
                {columnDescriptors.map( (cd, columnIndex) => {
                    return renderCell(row, cd, rowIndex, columnIndex);
                })}
            </TableRow>
        );
    };

    return (
        <>
            <div className={styles.root}>
                <TableContainer classes={{ root: styles.customTableContainer }}>
                    <Table stickyHeader
                        className={styles.table}
                        size={'medium'}
                    >
                        <DataTableHead
                            classes={styles}
                            orderByDirection={orderByDirection}
                            orderByProperty={orderByProperty}
                            handleSortRequest={handleSortRequest}
                            columnDescriptors={columnDescriptors}
                            isHidden={false}
                        />
                        <TableBody>
                            {sortedRecords?.map((row, rowIndex) => {
                                const columnSpanMessage = shouldSpanColumns && shouldSpanColumns(row);
                                if (columnSpanMessage) {
                                    return (
                                        <TableRow key={`row_${rowIndex}`} className={styles.tableRow} tabIndex={-1}>
                                            <TableCell className={styles.tableCell} align="center"
                                                       colSpan={columnDescriptors.length}>
                                                <div className={styles.colSpanMessage}>{columnSpanMessage}</div>
                                            </TableCell>
                                        </TableRow>
                                    )
                                } else {
                                    return renderRow(row, rowIndex);
                                }
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </>
    );
}
