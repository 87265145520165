import {makeStyles} from "@material-ui/core/styles";
import {Drawer} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    sortDrawer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        padding: '8px 16px 36px 16px',
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    menuItem: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '20px',
        "&:not(:last-child)": {
            borderBottom: '1px solid #d0d0d0'
        },
        "&:last-child": {
            borderRadius: '13px 13px 13px 13px',
        }
    },
    imageContainer: {
        width: '24px',
        height: '24px'
    },
    image: {
        height: '100%',
        width: '100%'
    },
    sortByColumnButtons: {
        width: '100%',
        borderRadius: '13px',
        backgroundColor: "#f5f5f5",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        // padding: '0px 20px 0px 20px'
    },
    sortByColumnButton: {
        width: '100%',
        height: '51px',
        borderRadius: '13px 13px 0px 0px',
        paddingLeft: '20px',
        borderWidth: '0px',
        backgroundColor: "#f5f5f5",
        fontSize: '18px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: -0.08,
        textAlign: 'left',
        color: "#424242",
        "&:not(:last-child)": {
            borderBottom: '1px solid #d0d0d0'
        },
        "&:last-child": {
            borderRadius: '13px 13px 13px 13px',
        }
    },
    cancelButtonContainer: {
        width: '100%',
        height: '51px',
        borderRadius: '13px',
        backgroundColor: "#f5f5f5",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginTop: '10px'
    },
    cancelButton: {
        width: '100%',
        borderWidth: '0px',
        backgroundColor: "#f5f5f5",
        fontSize: '18px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: -0.08,
        color: "#007aff"
    }
}));

export default function SortDrawer({anchor, open, columnDescriptors, showCancel, handleSortBy, handleCancel, labelPrefix='Sort by'}) {
    const styles = useStyles();

    const renderSortByButton = (columnDescriptor, index) => {
        if (columnDescriptor.image){
            return (
                <div key={`${columnDescriptor.name}_${index}`} className={styles.menuItem}>
                    <div className={styles.imageContainer}>
                        <img className={styles.image} src={columnDescriptor.image} alt={columnDescriptor.title}/>
                    </div>
                    <button id='sortDrawer_sortByButton' key={`${columnDescriptor.name}_${index}`} onClick={() => handleSortBy(columnDescriptor)}
                            className={styles.sortByColumnButton}>{`${labelPrefix} ${columnDescriptor.title}`}</button>
                </div>
            );

        } else {
            return (
                <button id='sortDrawer_sortByButton' key={`${columnDescriptor.name}_${index}`} onClick={() => handleSortBy(columnDescriptor)}
                        className={styles.sortByColumnButton}>{`${labelPrefix} ${columnDescriptor.title}`}</button>
            );
        }
    }

    return (
        <Drawer anchor={anchor} open={open} >
            <div data-testid='SortDrawer' className={styles.sortDrawer}>
                <div className={styles.sortByColumnButtons}>
                    {columnDescriptors && columnDescriptors.map((columnDescriptor, index) => renderSortByButton(columnDescriptor, index))}
                </div>
                {showCancel &&
                <div className={styles.cancelButtonContainer} >
                    <button id='sortDrawer_cancelButton'  onClick={handleCancel} className={styles.cancelButton}>Cancel</button>
                </div>
                }
            </div>
        </Drawer>
    );
}