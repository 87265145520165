import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import MenuIcon from '@mui/icons-material/Menu';
import CircleIcon from "@mui/icons-material/FiberManualRecord";
import {Drawer} from "@material-ui/core";
import EvvButton from "../../../common/components/EvvButton";
import AlertDialog from "../../../common/components/AlertDialog";
import {
    configureModules,
    SERVICE_DOCUMENT_STATUS_SIGNED,
    SERVICE_DOCUMENT_STATUS_INCOMPLETE,
    checkUpdatedField,
    setStatusByValidateResult,
    SERVICE_DOCUMENT_STATUS_UNSIGNED,
    SERVICE_DOCUMENT_STATUS_COMPLETE,
    getSignaturesFromServDoc,
    updateSignaturesOnServDoc,
    getStaffId,
} from "../serviceDocumentUtils";
import ConfigurableForm from "../ConfigurableForm/ConfigurableForm";
import './ServiceDocumentWizard.css';
import syncService from "../../../common/services/syncService";
import {getFieldValues} from "../../forms/common/ConfigurableFormsUtils";
import {clientCache} from "../../../cache/slices/client/clientSlice";
import clientService from "../../../common/services/clientService";
import {DateTime} from "luxon";
import {isoDateToString} from "../../../common/utils/formatUtils";
import { renderName } from '../common/Util';
import { documentCache } from "../../../cache/slices/document/documentCache";
import { GATYPE, IMPACTTYPE, PRIMARY_COLOR, TERTIARY_COLOR } from '../../../common/constants';
import GoalsAddressed from '../GoalsAddressed/GoalsAddressed';
import { checkUpdatedFieldGa, isGA } from '../../../common/utils/goalsAddressedUtils';
import { checkUpdatedFieldImpact, isImpact } from '../../../common/utils/impactUtils';
import Impact from '../Impact/Impact';
import { getDescriptorList } from '../common/DropDownFieldUtil';


const menuItem = {
    minWidth: '242px',
    height: 'auto',
    borderRadius: '3px',
    backgroundColor: '#f2f2f2',
    fontSize: '14px',
    fontWeight: "bold",
    fontStyle: "normal",
    color: "#4f4f4f",
    paddingLeft: '11px',
    paddingTop: '12px',
    paddingBottom: '10px',
    marginBottom: '8px'
};

const styles = (theme) => ({
    wizard: {
        width: '100%',
        height: '100%',
        display: 'flex'
    },
    wizardNavigator: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: PRIMARY_COLOR
    },
    wizardNavigator2: {
        height: 'fit-content',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: PRIMARY_COLOR
    },
    navigatorDrawer: {
        top: '114px',
        backgroundColor: PRIMARY_COLOR
    },
    wizardHeader: {
        width: '100%',
        display: 'flex',
        height: '43px',
        backgroundColor: '#f7f7f7',
        borderStyle: "solid",
        borderWidth: '1px',
        borderColor: '#c5c5c5'
    },
    menuAndStatus: {
        width: '100%',
        display: 'flex',
        paddingLeft: '7px',
        paddingTop: '7px',
        paddingBottom: '4px',
        alignItems: 'center'
    },
    hamburgerMenu: {
        width: '32px',
        height: '32px',
        padding: '8px 4px',
        marginRight: '16px'
    },
    hamburgerIconNavigator: {
        fill: 'white',
        marginRight: '16px'
    },
    hamburgerIconHeader: {
        fill: 'black',
        marginRight: '16px'
    },
    wizardBody: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    navigationPanel: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0px 8px',
        backgroundColor: PRIMARY_COLOR
    },
    wizardMenuItem: {
        ...menuItem,
        backgroundColor: TERTIARY_COLOR,
        color: "#ffffff"
    },
    selectedWizardMenuItem: {
        ...menuItem,
        backgroundColor: '#f2f2f2',
        color: "#4f4f4f"
    },
    wizardPage: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
        display: 'flex'
    },
    wizardFooter: {
        width: '100%',
        // height: '86px',
        padding: "15px 16px",
        justifyContent: 'space-between'
    },
    navigationButtonsforhandheld: {
        height: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    navigationButtonsfortablet: {
        height: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        marginLeft:'75%'
    },
    status: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '10px'
    },
    navigatorStatus: {
        color: "#ffffff"
    },
    statusIndicator: {
    },
    statusIndicatorIcon: {
        width: '18px',
        height: '18px'
    },
    statusTitle: {
        fontSize: '17px',
        fontWeight: "bold",
        fontStyle: "normal"
    },
    statusValue: {
        fontSize: '17px',
        fontWeight: "normal",
        fontStyle: "normal",
        paddingLeft: '6px',
    },
    saveButton: {
        padding: '0',
        minWidth: '125px',
        paddingLeft: '17px',
        marginLeft:'auto'
    },
    PreviousButton:{            
        padding: '0',
        minWidth:'125px',
        paddingRight:'17px',
        marginRight:'auto',
    },
});

class ServiceDocumentWizard extends React.Component{
    constructor(props) {
        super(props);

        this.documentController = props.documentController;
        this.store = props.store;
        this.serviceDocumentData = {};
        this.documentForWizard = props.documentForWizard;
        this.history = props.history;
        this.dispatch = props.dispatch;
        this.client = props.client;
        this.serviceDocTab = props.serviceDocTab;
        this.evvDocuments = props.evvDocuments;
        this.signedDocuments = props.signedDocuments;
        this.staff = props.staff;
        this.isOffline = props.isOffline;

        if (this.documentController?.appointmentDocument && this.documentController?.appointmentDocument?.length > 0) {
            this.isReadOnlyDoc = this.documentController?.appointmentDocument[0]?.documentStatus === SERVICE_DOCUMENT_STATUS_SIGNED ? true : false;
            this.signatureDb = (this.documentController?.appointmentDocument[0]?.signature) || {};
        } else {
            this.isReadOnlyDoc = this.documentForWizard?.documentStatus === SERVICE_DOCUMENT_STATUS_SIGNED ? true : false;
            this.signatureDb = (this.documentForWizard?.signature) || {};
        }

        this.state = {
            showAlert: false,
            showFloatingErrorMsg: false,
            currentPageIndex: 0,
            serviceDocument: {
                ...props.serviceDocument,
                modules: configureModules(this.documentController, props.serviceDocument, this.documentController.dataProvider.getConfigurableForms(), this.documentController.appointmentDocument, this.documentForWizard)
            },
            isNavigatorVisible: props.showNavigator,
            isHeaderVisible: props.showHeader,
            alertDialogConfig: null,
            status: this.isReadOnlyDoc ? SERVICE_DOCUMENT_STATUS_SIGNED : this.documentController.appointmentDocument?.length > 0 ? this.documentController?.appointmentDocument[0]?.documentStatus : SERVICE_DOCUMENT_STATUS_INCOMPLETE,
            saveDisable: true,
            allSigAvailable: false
        }

        this.isSaveButtonDisable= false;
        
        if (!this.documentController.getStatus){
            this.documentController.getStatus = this.getStatus;
        }

        if (!this.documentController.setStatus){
            this.documentController.setStatus = this.setStatus;
        }

        this.documentController.setSaveDisable = this.setSaveDisable;
        this.documentController.wizardNextPage = this.nextPage;
        this.documentController.wizardPreviousPage = this.previousPage;
        this.documentController.wizardSelectPage = this.selectPage;
        this.documentController.wizardSave = this.handleSave;
        this.documentController.showFloatingErrorMessage = this.showFloatingErrorMessage.bind(this);
        this.documentController.wizardClose = this.handleClose;
        this.documentController.setAllSigAvailable = this.setAllSigAvailable;

        if(!this.modulesToSave) {
            if(props.modulesToSave && props.modulesToSave.length > 0) {
                this.modulesToSave = props.modulesToSave;
            } else if(this.documentController.appointmentDocument && this.documentController.appointmentDocument[0]) {
                const objAppointmentDocument = this.documentController.appointmentDocument[0];
                this.modulesToSave = objAppointmentDocument.moduleData ? objAppointmentDocument.moduleData : [];
                const modulesList = [...this.modulesToSave];
                const docControllerModules  = modulesList.reduce((obj, item) => {
                    return {
                      ...obj,
                      [item["moduleId"]]: item,
                    };
                  }, {}); 

                this.documentController.moduleData = docControllerModules;
            } else {
                this.modulesToSave = [];
            }
        }

    };

    componentDidMount() {
        this.setState({ descriptorData: [] });
        this.getDescriptorTableData();
    }

    componentWillUnmount() {
        this.saveCurrentServiceDocument();
    }

    getDescriptorTableData = async () => {
        const orgId = this.documentController?.dataProvider?.getUser()?.currentOrganizationId;
        let descriptorList = await getDescriptorList(orgId);
        if (descriptorList?.length > 0) {
            this.setState({ descriptorData: descriptorList });
        }
    }

    createModuleData = (module, moduleType = 'cf') => {
        return {
            formValidated: false,
            moduleId: module?.moduleId,
            moduleType,
            fieldData: module?.fieldData
        };
    }

    getModuleDataToSave = (moduleDataToSave, moduleDataDb, module, call = 'cf') => {
        let tempModuleData;

        let dbModuleData = moduleDataDb.find(mod => module.moduleId === mod.moduleId);
        if (dbModuleData) {
            if (call === 'cf') {
                let defaultKeys = ["loaded", "dbOptions", "nextClicked"];
                dbModuleData.fieldData = [...dbModuleData?.fieldData.filter(field => defaultKeys.includes(field.key)), ...module.fieldData];
            } else {
                dbModuleData.fieldData = [...module.fieldData];
                if(module?.type === "IMPACT") {
                    dbModuleData.notAssessed = module.notAssessed;
                }
            }
            moduleDataToSave.push(dbModuleData);
        } else {
            tempModuleData = this.createModuleData(module, call);
            moduleDataToSave.push(tempModuleData);
        }
        return moduleDataToSave;
    }

    getDocId = () => {
        let docId;

        if (this.documentForWizard) {
            docId = this.documentForWizard?.id;
        } else if (this.documentController?.appointmentDocument && this.documentController?.appointmentDocument.length > 0) {
            docId = this.documentController?.appointmentDocument[0]?.id;
        }

        return docId;
    }

    createModuleDataToSave = (moduleDataDb) => {
        let moduleDataToSave = [];
        let tempModuleData;

        this.state?.serviceDocument?.modules?.forEach((module) => {
            if (isGA(module) || isImpact(module)) {
                let moduleType = isGA(module) ? GATYPE : IMPACTTYPE;
                moduleDataToSave = this.getModuleDataToSave(moduleDataToSave, moduleDataDb, module, moduleType);
            } else {
                if (!module.fieldData && module.name !== "Signatures") {//Document create & close case
                    const fieldValues = getFieldValues(module?.configurableForm?.fields, this.client, this.state?.descriptorData);
                    module.fieldData = [ ...fieldValues ];
                    tempModuleData = this.createModuleData(module);
                    moduleDataToSave.push(tempModuleData);
                } else if (module?.fieldData && module?.fieldData.length > 0) {                    
                    moduleDataToSave = this.getModuleDataToSave(moduleDataToSave, moduleDataDb, module);
                }
            }
        });

        return moduleDataToSave;
    }

    saveCurrentServiceDocument = async() => {
        let moduleDataDb = [];
        let moduleDataToSave = [];
        let currentDoc;

        let docId = this.getDocId();
        if (docId) {
            currentDoc = await syncService.documentService.findDocumentById(docId);
        }

        if (currentDoc && currentDoc?.documentStatus && currentDoc?.documentStatus !== 'Signed') {
            if (currentDoc?.moduleData) {
                moduleDataDb = currentDoc?.moduleData;
            }

            moduleDataToSave = this.createModuleDataToSave(moduleDataDb);

            if (moduleDataToSave) {
                let clientProgramId = this.documentController?.serviceDocument?.clientProgramId;
                let signatures = getSignaturesFromServDoc(this.documentController?.serviceDocument, this.signatureDb);
                await setStatusByValidateResult(this.state, this.documentController, this.state.currentPageIndex, this.isReadOnlyDoc, moduleDataToSave, this.state?.descriptorData);
                await syncService.documentService.saveDoc(currentDoc.id, moduleDataToSave, this.state.status, signatures, clientProgramId);
                let latestDocData = {...currentDoc};
                latestDocData.moduleData = moduleDataToSave;
                latestDocData.signature = signatures;
                latestDocData.clientProgramId = clientProgramId;
                this.dispatch(documentCache.setCurrentAptDocument([latestDocData]));
            }
        }
    }

    setFloatingErrorMsg = (flag) => {
        this.setState({ showFloatingErrorMsg: flag });
    }

    setSaveDisable = (value) => {
        this.setState({
            saveDisable: value
        });
    }

    setAllSigAvailable = (value) => {
        this.setState({
            allSigAvailable: value
        });
    }

    getStatus = () => {
        return this.state.status;
    }

    setStatus = (newStatus) => {
        this.setState({
            status: newStatus
        });
    }

    pageByIndex = (pageIndex) => {
        if (pageIndex >= 0 && pageIndex < this.state.serviceDocument.modules.length){
            return this.state.serviceDocument.modules[pageIndex];
        }

        return null;
    };

    requestPageValidation = (currentPageIndex) => {
        const currentPage = this.pageByIndex(currentPageIndex);
        let isValid = true;

        if (this.documentController.onValidate){
            isValid = this.documentController.onValidate(currentPage, this.props);
            if (!(isValid === false)){
                isValid = true;
            }
        }

        if (isValid && currentPage.onValidate){
            isValid = currentPage.onValidate(currentPage, this.props);
            if (!(isValid === false)){
                isValid = true;
            }
        }

        return isValid;
    };

    getIdForDocument = () => {
        const id = this.documentForWizard ? this.documentForWizard.id : this.history.location.state.serviceDocument.id;
        return id;
    }

    gAFieldsChangeReset = () => {
        setTimeout(() => {
            const prevPage = this.pageByIndex(this.state.currentPageIndex - 1);
            if (prevPage && isGA(prevPage)) {
                prevPage?.fields?.map(field => field.isChanged = false);
            }
        }, 100);
    }

    getClientProgramId = () => {
        return (this.documentController?.appointmentDocument?.length > 0 ? this.documentController?.appointmentDocument[0]?.clientProgramId : this.documentController?.serviceDocument?.clientProgramId);
    }

    nextPage = () => {
        setTimeout(()=> {
            const nextPageIndex = this.state.currentPageIndex + 1;
            let signatures = getSignaturesFromServDoc(this.documentController?.serviceDocument, this.signatureDb);

            if (this.requestPageValidation(this.state.currentPageIndex)) {
                this.createModulesOnNext();
                setStatusByValidateResult(this.state, this.documentController, nextPageIndex, this.isReadOnlyDoc, this.modulesToSave, this.state?.descriptorData);
                syncService.documentService.updateDocument(this.getIdForDocument(), this.createModulesOnNext(), signatures, this.state.status, this.getClientProgramId());
                this.changePage(this.state.currentPageIndex, nextPageIndex, 'next');
                this.gAFieldsChangeReset();
            }
        },200)
    };

    previousPage = () => {
        let signatures = updateSignaturesOnServDoc(this.documentController, this.documentForWizard, this.signatureDb);

        const previousPageIndex = this.state.currentPageIndex - 1;
        setStatusByValidateResult(this.state, this.documentController, previousPageIndex, this.isReadOnlyDoc, this.modulesToSave, this.state?.descriptorData);
        syncService.documentService.updateDocument(this.getIdForDocument(), this.createModulesOnPrevious(), signatures, this.state.status, this.getClientProgramId());
        this.changePage(this.state.currentPageIndex, previousPageIndex, 'previous');
    };

    createModulesOnNext = () => {
        let data = this.documentController.moduleData ? 
                    Object.keys(this.documentController.moduleData)
                    .map(key => this.documentController.moduleData[key]) : {};
        if(Array.isArray(data)) {
            const moduleId = this.pageByIndex(this.state.currentPageIndex).moduleId;
            data.forEach(element => {
                if(moduleId === element.moduleId) {
                    element.formValidated = true;
                    if(!this.modulesToSave.length) { // array is empty
                        this.modulesToSave.push(element);
                    } else {
                        let index = this.modulesToSave.findIndex(m => m.moduleId === moduleId);
                        if(index > -1) {
                            this.modulesToSave.splice(index, 1);
                        }
                        this.modulesToSave.push(element);
                    }
                }
            });
            this.modulesToSave.sort(function(a,b) {
                return a.moduleId - b.moduleId;
            });
        }
        this.documentController.modulesToSave = this.modulesToSave;
        return this.modulesToSave;
    }

    getModuleType = (currentPage) => {
        let moduleType;

        if (isGA(currentPage)) {
            moduleType = GATYPE;
        } else if (isImpact(currentPage)) {
            moduleType = IMPACTTYPE;
        } else {
            moduleType = "cf";
        }

        return moduleType;
    }

    createModulesOnPrevious = () => {
        if (this.state.currentPageIndex !== this.state.serviceDocument.modules.length - 1) {
            this.setState({showAlert: false});
            const currentPage = this.pageByIndex(this.state.currentPageIndex);
            let moduleType = this.getModuleType(currentPage);
            const module = {
                moduleId: currentPage.moduleId,
                moduleType: moduleType,
                fieldData: [
                    { key: 'loaded', value: true},
                    { key: 'dbOptions', value: []},
                    { key: 'nextClicked', value: true}
                ],
                formValidated: false
            };

            if (isImpact(currentPage)) {
                module.measureId = currentPage?.impactConfig?.id;
                module.notAssessed = currentPage?.notAssessed;
            }

            if (isGA(currentPage) || isImpact(currentPage)) {
                module.fieldData = [...currentPage?.fieldData];
            } else {
                const fieldValues = getFieldValues(currentPage?.configurableForm?.fields, this.client, this.state?.descriptorData);
                module.fieldData = [ ...fieldValues, ...module.fieldData ];
            }

            this.checkFieldsUpdated(module, currentPage);

            this.modulesToSave.sort(function(a,b) {
                return a.moduleId - b.moduleId;
            });
        }
        this.documentController.modulesToSave = this.modulesToSave;
        return this.modulesToSave;
    }

    checkUpdatedFieldFn = (currentPage, currentModule, module, modules) => {
        let updated = false;

        if (isGA(currentPage)) {
            updated = checkUpdatedFieldGa(currentPage)
        } else if (isImpact(currentPage)) {
            updated = checkUpdatedFieldImpact(currentPage)
        } else {
            updated = checkUpdatedField(currentModule, module, modules)
        }

        return updated;
    }

    checkFieldsUpdated = (module, currentPage) => {
        let index = this.modulesToSave.findIndex(m => m.moduleId === module.moduleId);
        if(index > -1) {
            let currentModule = this.modulesToSave[index];
            let modules = this.state.serviceDocument.modules;
            let formValidatedValue = this.checkUpdatedFieldFn(currentPage, currentModule, module, modules);

            this.modulesToSave.splice(index, 1);
            module.formValidated = formValidatedValue ? false : currentModule.formValidated;
        }
        this.modulesToSave.push(module);
    }

    selectPage = (selectedPageIndex) => {
        this.changePage(this.state.currentPageIndex, selectedPageIndex, 'select');
    };

    handlePageClick = (selectedPageIndex) => {
        setTimeout(()=> {
            let signatures = updateSignaturesOnServDoc(this.documentController, this.documentForWizard, this.signatureDb);

            this.setState({showAlert: false});
            setStatusByValidateResult(this.state, this.documentController, selectedPageIndex, this.isReadOnlyDoc, this.modulesToSave, this.state?.descriptorData);
            syncService.documentService.updateDocument(this.getIdForDocument(), this.createModulesOnPrevious(), signatures, this.state.status, this.getClientProgramId());
            this.changePage(this.state.currentPageIndex, selectedPageIndex, 'select');
        }, 200)
    };
    
    requestPageChange = (sourcePage, targetPage, changeType) => {
        let allowPageChange = true;

        if (this.documentController.onPageChange){
            allowPageChange = this.documentController.onPageChange(sourcePage, targetPage, changeType);
            if (!(allowPageChange === false)){
                allowPageChange = true;
            }
        }

        if (allowPageChange && sourcePage.onPageChange){
            allowPageChange = sourcePage.onPageChange(sourcePage, targetPage, changeType);
            if (!(allowPageChange === false)){
                allowPageChange = true;
            }
        }

        return allowPageChange;
    };

    changePage = (fromPageIndex, toPageIndex, changeType) => {
        const sourcePage = this.pageByIndex(fromPageIndex);
        const targetPage = this.pageByIndex(toPageIndex);

        if (sourcePage && targetPage) {
            if (this.requestPageChange(sourcePage, targetPage, changeType)) {
                this.setState({
                    currentPageIndex: toPageIndex,
                    isNavigatorVisible: this.documentController.dataProvider.isHandheld() ? false : this.state.isNavigatorVisible
                });
            }
        }
    };

    handleMenuButtonClick = () => {
        this.setState({isNavigatorVisible: !this.state.isNavigatorVisible});
    };

    handleClose = () => {
        if (this.documentController.onClose) {
            this.updateServiceDocTabData();
            this.documentController.onClose();
        } else {
            this.redirectPage();
        }
    };

    handleAlertDialogClose = (okClicked) => {
        this.setState({alertDialogConfig: null})

        if (okClicked){
            this.changePage(this.state.currentPageIndex, 0);
        }
    };

    createServiceDocumentData = () => {
        let clientID = this.documentForWizard ? this.documentForWizard.clientId : this.history.location.state.serviceDocument.clientId;
        let clientProgram = this.documentController.dataProvider.getClientPrograms().find(cp => cp.clientId === clientID && cp.programId === this.documentController?.program?.programId);
        const serviceDocumentData = {
            clientId: clientID,
            clientProgramId: clientProgram ? clientProgram?.clientProgramId : null,
            documentId: this.getIdForDocument(),
            serviceDocumentId: this.state.serviceDocument.setupId,
            appointmentId: this.documentController?.appointmentDocument ? this.documentController?.appointmentDocument[0]?.appointmentId : null,
            staffId: getStaffId(this.documentForWizard, this.documentController),
            organizationId: this.documentController.dataProvider.getCurrentOrganizationId(),
            fullySigned: this.getStatus() === SERVICE_DOCUMENT_STATUS_SIGNED,
            serviceDate: this.documentForWizard ? this.documentForWizard.serviceDate : isoDateToString(this.history.location.state.serviceDocument.serviceDate),
            moduleData: this.modulesToSave,
            status: this.getStatus(),
        };

        return serviceDocumentData;
    }

    handleSave = () => {
        const currentPage = this.pageByIndex(this.state.currentPageIndex);
        const allowToSave = this.handleSaveRequest(currentPage);

        if (allowToSave){
            if (this.state.currentPageIndex === this.state.serviceDocument.modules.length - 1){
                this.setServiceDocument();
                let signatures = getSignaturesFromServDoc(this.serviceDocumentData, this.signatureDb);
                let filteredExtSig = signatures?.externalSignatures?.filter(item => item !== null);
                signatures.externalSignatures = filteredExtSig;
                syncService.documentService.updateDocument(this.getIdForDocument(), this.modulesToSave, signatures, "Signed", this.serviceDocumentData.clientProgramId);
                const docId = this.getIdForDocument();
                syncService.documentService.writeDocument(this.serviceDocumentData, this.modulesToSave).then(response => {
                    syncService.documentService.findDocumentById(docId).then(docById => {
                        const newDoc = {...docById}

                        const store = this.store;
                        syncService.documentService.deleteDocument(docById, {store, doNotClear: true})
                        newDoc.id = response.resources[0].id;
                        newDoc.syncState = newDoc.id > 0 ? "clean": "dirty";
                        newDoc.clientProgramId = this.serviceDocumentData.clientProgramId;
                        newDoc.organizationId = this.serviceDocumentData.organizationId; 
                        syncService.documentService.saveDocument(newDoc, {store, doNotClear: true})
                        this.setStatus(SERVICE_DOCUMENT_STATUS_SIGNED);
                    });
                });
                this.onHideDocument(allowToSave);
                this.redirectPage();
            }
        }else{
            this.onHideDocument(allowToSave);
        }
    };

    onHideDocument = (allowToSave) => {
        if(!this.state.isHeaderVisible){
            this.documentController.onHideDocument(allowToSave);
        }
    }

    updateServiceDocTabData = () => {
        if (this.serviceDocTab) {
            const updatedServiceDocTab = this.serviceDocTab.filter((item) => item.id !== this.documentForWizard?.id);
            this.dispatch(documentCache.setServiceDocumentsForTabs(updatedServiceDocTab));
            this.dispatch(documentCache.setSelectedDocTab({description:'All Documents'}));
        }
    }

    redirectPage = () => {
        if (this.state.isHeaderVisible) {
            this.history.push("/facesheet/documents")
        } else {
            this.updateServiceDocTabData();
            clientService.visitClient(this.client, DateTime.now().toMillis());
            this.dispatch(clientCache.selectClient(this.client));
            this.history.push("/caseload/facesheet");
        }
    };

    setServiceDocument = () => {
        let nextStaff = this.state?.serviceDocument?.signature?.nextStaffId?.value;
        if(!nextStaff) {
            this.serviceDocumentData.fullySigned = true;
        } else {
            this.serviceDocumentData.fullySigned = false;
        }
        this.serviceDocumentData['signature'] = this.documentController.serviceDocument.signature;
    };

    handleSaveRequest = (currentPage) => {
        this.serviceDocumentData = {};
        const serviceDocumentData = this.createServiceDocumentData();
        let allowSave = true;

        if (this.documentController.onSave){
            allowSave = this.documentController.onSave(serviceDocumentData, currentPage);
            if (!(allowSave === false)){
                allowSave = true;
            }
        }

        if (allowSave && currentPage.onSave){
            allowSave = currentPage.onSave(serviceDocumentData);
            if (!(allowSave === false)){
                allowSave = true;
            }
        }

        if(allowSave) {
            this.serviceDocumentData = this.createServiceDocumentData();
        }

        return allowSave;
    };

    renderNavigationItem = (pageDescriptor, pageIndex) => {
        const { classes } = this.props;

        const isSelected = pageIndex === this.state.currentPageIndex;
        const className = isSelected ? classes.selectedWizardMenuItem : classes.wizardMenuItem;

        return (<div data-testid={`WizardMenuItem_${pageDescriptor.name}`} key={`navigationItem_${pageIndex}`} className={className} onClick={() => this.handlePageClick(pageIndex)} >{pageDescriptor.name} </div>);
    }

    getColorForStatus = () => {
        if (this.state.status === SERVICE_DOCUMENT_STATUS_SIGNED){
            return '#9CBB34';
        } else if (this.state.status === SERVICE_DOCUMENT_STATUS_UNSIGNED){
            return '#facd64';
        }else if (this.state.status === SERVICE_DOCUMENT_STATUS_COMPLETE){
            return '#9cbb34';
        }  else {
            return '#e16b5a';
        }
    }

    renderStatusIndicator = (isNavigator) => {
        const { classes } = this.props;

        let statusClassName = classes.status;
        let iconClassName = isNavigator ? classes.hamburgerIconNavigator : classes.hamburgerIconHeader;

        if (isNavigator){
            statusClassName += (' ' + classes.navigatorStatus);
        }
        setStatusByValidateResult(this.state, this.documentController, this.state.currentPageIndex, this.isReadOnlyDoc, this.modulesToSave, this.state?.descriptorData);
        return (
            <div className={classes.menuAndStatus}>
                {this.documentController.dataProvider.isHandheld() &&
                <MenuIcon className={iconClassName} onClick={this.handleMenuButtonClick}/>
                }
                <div className={classes.statusIndicator}>
                    <CircleIcon classes={{root: classes.statusIndicatorIcon}} htmlColor={this.getColorForStatus()} />
                </div>
                <div className={statusClassName}>
                    <div className={classes.statusTitle} >Status:</div>
                    <div className={classes.statusValue} >{this.state.status}</div>
                </div>
            </div>
        )
    };

    renderWizardNavigator = () => {
        if (this.documentController.dataProvider.isHandheld()){
            return this.renderDrawerNavigator();
        } else {
            if (this.state.isNavigatorVisible) {
                return this.renderNavigator();
            } else {
                return null;
            }
        }
    };

    handleDrawerClose = (event, reason) => {
        if (reason === 'backdropClick' || (reason === 'navigator' && event.target.id === 'navigator')){
            if (this.documentController.dataProvider.isHandheld()) {
                this.setState({isNavigatorVisible: false});
            }
        }
    }

    renderDrawerNavigator = () => {
        const { classes } = this.props;


        return (
            <Drawer classes={{paper: classes.navigatorDrawer}} anchor="left" open={this.state.isNavigatorVisible} onClose={this.handleDrawerClose}>
                {this.renderNavigator()}
            </Drawer>
        );
    };

    renderNavigator = () => {
        const { classes } = this.props;
        const modulesCount = this.state.serviceDocument.modules.length;
        let className = "";
        if(this.documentController.dataProvider.isHandheld()) {
            className = classes.wizardNavigator;
        } else {
            className = (modulesCount > 8) ? classes.wizardNavigator2 : classes.wizardNavigator;
        }

        return (
            <div id='navigator' className={className} onClick={e => this.handleDrawerClose(e, "navigator")}>
                {this.renderStatusIndicator(true)}
                <div className={classes.navigationPanel}>
                    {this.state.serviceDocument.modules.map((pageDescriptor, index) => this.renderNavigationItem(pageDescriptor, index))}
                </div>
            </div>
        )
    };

    renderWizardHeader = () => {
        return this.state.isHeaderVisible ? this.renderStatusIndicator() : null;
    };

    handlePageCreationRequest = (module, props) => {
        let allowCreate = true;

        if (this.documentController.onPageCreate){
            allowCreate = this.documentController.onPageCreate(module, props);
            if (!(allowCreate === false)){
                allowCreate = true;
            }
        }

        return allowCreate;
    };

    renderWizardPages = () => {
        const wizardPages = [];
        let signatures = getSignaturesFromServDoc(this.documentController?.serviceDocument, this.signatureDb);
        if(this.documentForWizard) {
            this.documentForWizard = {...this.documentForWizard, signature: signatures};
        }

        this.state.serviceDocument.modules.forEach((module, index) => {
            const key = `module_${module.name}`;
            const props = {
                key: key,
                module: module,
                serviceDocument: this.state.serviceDocument,
                documentController: this.documentController,
                hide: this.state.currentPageIndex !== index,
                changePage: this.changePage,
                selectedClient: this.client,
                history: this.history,
                dispatch: this.dispatch,
                currentPageIndex: this.state.currentPageIndex,
                documentForWizard: this.documentForWizard,
                evvDocuments: this.evvDocuments,
                signedDocuments: this.signedDocuments,
                staff: this.staff,
                isOffline: this.isOffline,
                state: this.state,
                nextPage: this.nextPage,
                currentPage: this.pageByIndex(this.state.currentPageIndex)
            };

            if (this.handlePageCreationRequest(module, props)) {
                if (module.renderPage) {
                    wizardPages.push(module.renderPage(props));
                }  else if (isGA(module)) {
                    wizardPages.push(<GoalsAddressed {...props} setFloatingErrorMsg = { this.setFloatingErrorMsg } renderFormName = {this.renderFormName} />);
                } else if (isImpact(module)) {
                    wizardPages.push(<Impact {...props} setFloatingErrorMsg = { this.setFloatingErrorMsg }/>);
                } else if (module.configurableForm) {
                    // 2. Send the callback function to the ConfigurableForm as a prop.
                    wizardPages.push(<ConfigurableForm {...props} setFloatingErrorMsg = { this.setFloatingErrorMsg } renderFormName = {this.renderFormName}/>);
                }
            }
        });

        return wizardPages;
    };

    showFloatingErrorMessage() {
        this.setState({showAlert: true});
        // to hide an floating error message after 3 seconds.
        setTimeout(() => {
            this.setState({
                showAlert: false
            });
        }, 3000);
    }

    renderWizardFooter = () => {
        const { classes } = this.props;
        let currentPage = this.pageByIndex(this.state.currentPageIndex);

        return (
            <>
            { (this.state.showFloatingErrorMsg) &&
                <div id="floatingErrorMessage"
                     className={`floatingErrorMessage ${this.state.showAlert ? 'fade-in': 'fade-out'} `}
                    > {currentPage?.floatingErrorMessage}
                </div>
            }
            <div className={classes.wizardFooter}>
                <div className={this.documentController.dataProvider.isHandheld() ? classes.navigationButtonsforhandheld : classes.navigationButtonsfortablet}>
                    {this.state.currentPageIndex > 0 &&
                    <div className={this.documentController.dataProvider.isHandheld() ? classes.PreviousButton : classes.saveButton}>
                        <EvvButton type='secondary' disabled={false} onClick={this.previousPage}>Previous</EvvButton>
                    </div>
                    }
                    {this.state.currentPageIndex < this.state.serviceDocument.modules.length - 1 &&
                    <div className={classes.saveButton}>
                        <EvvButton type='primary' disabled={false} onClick={this.nextPage}>Next</EvvButton>
                    </div>
                    }
                    {this.state.currentPageIndex === this.state.serviceDocument.modules.length - 1 && ( !this.isReadOnlyDoc || (this.isReadOnlyDoc && !this.state.allSigAvailable) ) &&
                    <div className={classes.saveButton}>
                        <EvvButton type='primary' disabled={this.state.saveDisable} onClick={this.handleSave}>Save</EvvButton>
                    </div>
                    }
                    {this.state.currentPageIndex === this.state.serviceDocument.modules.length - 1 && this.isReadOnlyDoc && this.state.allSigAvailable &&
                    <div className={classes.saveButton}>
                        <EvvButton type='primary' onClick={this.handleClose}>Close</EvvButton>
                    </div>
                    }
                </div>
            </div>
            </>
        );
    };

    renderFormName = () => {
        let currentPageIndex = this.state.currentPageIndex;
        return (
            <div className='configurableFormName'>
                {this.state.serviceDocument.modules.map((pageDescriptor, index) => renderName(pageDescriptor, index, currentPageIndex))}
            </div>
        )
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.wizard}>
                {this.renderWizardNavigator()}
                <div className={classes.wizardBody}>
                    {this.documentController.dataProvider.isHandheld() &&
                     <><div className='serviceDocNamefForHandheld'>{this.state.serviceDocument.description}</div>
                    <div className={classes.wizardHeader}>
                            {this.renderWizardHeader()}
                        </div></>
                    }
                    <div className={classes.wizardPage}>
                        {this.renderWizardPages()}
                    </div>
                    {this.renderWizardFooter()}
                </div>
                {this.state.alertDialogConfig &&
                <AlertDialog
                    open={true}
                    dialogTitle={this.state.alertDialogConfig.dialogTitle}
                    dialogMessage={this.state.alertDialogConfig.dialogMessage}
                    showOkButton={this.state.alertDialogConfig.showOkButton}
                    showCancelButton={this.state.alertDialogConfig.showCancelButton}
                    okButtonText={this.state.alertDialogConfig.okButtonText || 'Ok'}
                    cancelButtonText={this.state.alertDialogConfig.cancelButtonText || 'Cancel'}
                    handleClose={this.handleAlertDialogClose}
                />
                }
            </div>
        );
    }
}

export default withStyles(styles)(ServiceDocumentWizard);