import {defaultUTCDateAsString, stringToDateWithTime} from "./formatUtils";

const NOT_SET = 'Not Set'

export const getOrderByProperty = (columnDescriptor) => {
    if (columnDescriptor.orderBy){
        return columnDescriptor.orderBy;
    }

    if (columnDescriptor.formatter){
        return (row) => columnDescriptor.formatter(row, columnDescriptor);
    }

    if (columnDescriptor.fieldName){
        return columnDescriptor.fieldName;
    }

    return columnDescriptor.id;
};

export const formatDate = (row, columnDescriptor) => {
    const value = row[columnDescriptor.fieldName];
        
    if (value){
        return defaultUTCDateAsString(value);
    }else {
        return NOT_SET;
    }
}

export const formatDateWithTime = (row, columnDescriptor) => {
    const value = row[columnDescriptor.fieldName];

    if (value){
        return stringToDateWithTime(value);
    }else {
        return NOT_SET;
    }
}
