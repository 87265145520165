import HomeIcon from "@mui/icons-material/Home";
import ScheduleIcon from '@mui/icons-material/DateRange';
import CaseloadIcon from '@mui/icons-material/SupervisorAccount';
import SettingsIcon from '@mui/icons-material/Settings';

export const PageDescriptors = {
    dashboard: {
        href: '/dashboard',
        title: 'Dashboard',
        renderIcon: (style, classes) => <HomeIcon style={style} classes={classes} />,
        handheldSearchEnabled: true
    },
    schedule: {
        href: '/schedule',
        title: 'Schedule',
        renderIcon: (style, classes) => <ScheduleIcon style={style} classes={classes} />,
        extendedByPage: true
    },
    caseload: {
        href: '/caseload',
        title: 'Caseload',
        renderIcon: (style, classes) => <CaseloadIcon style={style} classes={classes} />,
        handheldSearchEnabled: true
    },
    settings: {
        href: '/settings',
        title: 'Settings',
        renderIcon: (style, classes) => <SettingsIcon style={style} classes={classes} />,
    },

    facesheet: {title: 'Facesheet', isChildPage: true},
    allergies: {title: 'Allergies', isChildPage: true},
    allergy: {title: 'Allergy Details', isChildPage: true},
    medications: {title: 'Medications', isChildPage: true},
    medication: {title: 'Medication Details', isChildPage: true},
    diagnoses: {title: 'Diagnosis', isChildPage: true},
    diagnosis: {title: 'Diagnosis Details', isChildPage: true},
    activeSessions: {title: 'Active Sessions', isChildPage: true},
    documents: {title: 'Documents', isChildPage: true},
    messages: {title: 'Message Board', isChildPage: true},
    vitals: {title: 'Vitals', isChildPage: true},
    vitalsInfo: {title: 'Vitals Details', isChildPage: true},
    serviceDocument: {title: 'Documents', isChildPage: true},
    serviceDocumentPdf: {title: 'Documents', isChildPage: true},
    serviceDocumentExternalSignNeeded: {title: 'Documents', isChildPage: true}
};

export const DEFAULT_PAGE_NAME = 'dashboard';
export const DEFAULT_PAGE_DESCRIPTOR = PageDescriptors[DEFAULT_PAGE_NAME];

export const getPageDescriptor = (pageName, returnDefault) => {
    let pageDescriptor = PageDescriptors[pageName];

    if (!pageDescriptor && returnDefault){
        return DEFAULT_PAGE_DESCRIPTOR;
    }

    return pageDescriptor;
}
