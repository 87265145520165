import {makeStyles} from "@material-ui/core/styles";
import React, {useEffect, useState} from "react";
import {getFormattedStartTime} from "../../utils/formatUtils";
import {getTimeInstanceFromString} from "../../utils/dateTimeUtils";
import {DateTime} from "luxon";
import MobilePicker from "../MobilePicker";

const useStyles = makeStyles(() => ({
    timePicker: {
        display: 'flex',
        flexDirection: 'column',
        width: '250px',
        height: '220px',
        borderRadius: '5px',
        paddingLeft: '18px',
        paddingRight: '18px'
    },

    input: {
        '&:disabled':{
            color: "#888888"
        },
        '&::placeholder': {
            color: "#888888",
        }
    },
}));

export const HOUR_RANGE_COUNT = 20;
export const HOUR_OPTIONS_PER_RANGE = 12;
export const MINUTE_RANGE_COUNT = 20;
export const MINUTE_OPTIONS_PER_RANGE = 60;

export const minuteAsText = (minute) => {
    return minute < 10 ? '0' + minute : minute + '';
}

export const hoursOptions = (count) => {
    let options = [];
    let hours;

    for (let index = 0; index < count; index++) {
        hours = Array.from({length: HOUR_OPTIONS_PER_RANGE}).map((_, index) => (index + 1) + '');
        options = [...options, ...hours];
    }

    return options;
}

export const minutesOptions = (count) => {
    let options = [];
    let minutes;

    for (let index = 0; index < count; index++) {
        minutes = Array.from({length: MINUTE_OPTIONS_PER_RANGE}).map((_, index) => minuteAsText(index));
        options = [...options, ...minutes];
    }

    return options;
}

export const defaultTimeOptionGroups = {
    hour: hoursOptions(HOUR_RANGE_COUNT),
    minute: minutesOptions(MINUTE_RANGE_COUNT),
    meridian: ['AM', 'PM']
};

export const timeOptionRanges = [
    {startFindIndex: Math.floor(HOUR_RANGE_COUNT/2) * HOUR_OPTIONS_PER_RANGE, optionsPerRange: HOUR_OPTIONS_PER_RANGE},
    {startFindIndex: Math.floor(MINUTE_RANGE_COUNT/2) * MINUTE_OPTIONS_PER_RANGE, optionsPerRange: MINUTE_OPTIONS_PER_RANGE},
    {startFindIndex: 0}
];

export const transformTextToValueGroups = (textToTransform) => {
    let timeInstance = getTimeInstanceFromString(textToTransform);

    return transformDateToValueGroups(timeInstance);
};

export const transformDateToValueGroups = (dateToTransform) => {
    const timeInstance = dateToTransform && dateToTransform.isValid ? dateToTransform : DateTime.now();
    let hour = timeInstance.hour;

    if (hour === 0){
        hour = 12;
    }

    return {
        hour: '' + (hour <= 12 ? hour : hour - 12),
        minute: minuteAsText(timeInstance.minute),
        meridian: timeInstance.hour < 12 ? 'AM' : 'PM'
    }
};

export const transformValueGroupsToText = (valueGroups) => {
    let hour = valueGroups.hour ? parseInt(valueGroups.hour) : 0;

    if (hour === 12){
        hour = 0;
    }

    const dateAsObject = {
        hour: valueGroups.meridian === 'PM' ? hour + 12 : hour,
        minute: valueGroups.minute ? parseInt(valueGroups.minute) : 0
    };

    const timeInstance = DateTime.fromObject(dateAsObject);

    return timeInstance && timeInstance.isValid ? getFormattedStartTime(timeInstance) : null;
};

const TimePicker = ({ value, onChange }) => {
    const [timeValueGroups, setTimeValueGroups] = useState(transformTextToValueGroups(value));
    const [timeOptionGroups] = useState(defaultTimeOptionGroups);
    const styles = useStyles();

    useEffect(() => {
        setTimeValueGroups(transformTextToValueGroups(value));
    }, [value]);

    const handleTimeChange = (name, value) => {
        const changedTimeValueGroups = {
            ...timeValueGroups,
            [name]: value
        };

        setTimeValueGroups(changedTimeValueGroups);

        const timeAsText = transformValueGroupsToText(changedTimeValueGroups);
        if (timeAsText && onChange){
            onChange(timeAsText);
        }
    };

    return (
        <div className={styles.timePicker}>
            <MobilePicker
                optionGroups={timeOptionGroups}
                valueGroups={timeValueGroups}
                optionRanges={timeOptionRanges}
                onChange={handleTimeChange}
            />
        </div>
    )
}

export default TimePicker;
