import LookupCache from "../../cache/slices/sync/lookupCache";
import { evvRepository } from "../../db/evv";
import {isNowWithinRange} from "../utils/appointmentUtils";
import {getDispatchFromConfig, sort} from "../utils/miscUtils";
import api from "./api";

const DESCRIPTOR_EVV_REASON_CODES = 'EVV Reason Codes';
const DESCRIPTOR_CREDIT_CARD = 'Credit Card';
const DESCRIPTOR_ALLERGY_TYPE = 'Allergy Type';
const DESCRIPTOR_ACTIVITY_QUALIFIERS_TYPE = 'Activity Qualifiers';
const DESCRIPTOR_PHONE_CONTACT_TYPE = 'Phone Contact Type';

class DescriptorCacheService {
    constructor(descriptorService) {
        this.descriptorService = descriptorService;
        this.descriptorCache = new LookupCache('descriptor');
        this.reasonCodeCache = new LookupCache('reasonCodes');
        this.creditCardCache = new LookupCache('creditCard');
        this.allergyTypeCache = new LookupCache('allergyType');
        this.activityQualifiersCache = new LookupCache('activityQualifiers');
        this.phoneContactType = new LookupCache('phoneContactType');
        this.count = 0;
    };

    getReducers = () => {
        return {
            reasonCodes: this.reasonCodeCache.slice.reducer,
            creditCard: this.creditCardCache.slice.reducer,
            allergyType: this.allergyTypeCache.slice.reducer,
            activityQualifiers: this.activityQualifiersCache.slice.reducer,
            phoneContactType: this.phoneContactType.slice.reducer
        }
    };

    fetch = (config) => {
        if (api.isOfflineAtLogin()) {
            return this.load(config);
        } else {
            this.descriptorService.fetch(config)
                .then(() => this.load(config));
        }
    };

    load = (config) => {
        const state = config.store.getState();
        let currentOrgId = state?.app?.user?.currentOrganizationId;

        const dispatch = getDispatchFromConfig(config);
        dispatch(this.descriptorCache.actions.operationStart());
        this.loadByType(currentOrgId, dispatch, this.reasonCodeCache, DESCRIPTOR_EVV_REASON_CODES);
        this.loadByType(currentOrgId, dispatch, this.creditCardCache, DESCRIPTOR_CREDIT_CARD);
        this.loadByType(currentOrgId, dispatch, this.allergyTypeCache, DESCRIPTOR_ALLERGY_TYPE);
        this.loadByType(currentOrgId, dispatch, this.activityQualifiersCache, DESCRIPTOR_ACTIVITY_QUALIFIERS_TYPE, true);
        this.loadByType(currentOrgId, dispatch, this.phoneContactType, DESCRIPTOR_PHONE_CONTACT_TYPE, true);
    };

    createEmptyDescriptor = () => {
        return {
            name: 'Select One',
            priority: 0,
            mappedValue: ''
        };
    };

    filterAndSort = (descriptors, ignoreEmptyDescriptor) => {
        const emptyDescriptor = this.createEmptyDescriptor();

        if (descriptors.length <= 1){
            return ignoreEmptyDescriptor ? descriptors : [emptyDescriptor, ...descriptors];
        }

        const filteredDescriptors = descriptors.filter(descriptor => isNowWithinRange(descriptor.beginDate, descriptor.endDate));
        const sortedDescriptors = sort(filteredDescriptors, ['order', 'name']);

        return ignoreEmptyDescriptor ? sortedDescriptors : [emptyDescriptor, ...sortedDescriptors];
    };

    loadByType = (orgId, dispatch, cache, type, ignoreEmptyDescriptor) => {
        dispatch(cache.actions.operationStart());
        this.descriptorService.repository.loadAllByProperty('type', type)
            .then((descriptors) => {
                descriptors = descriptors.filter(des => des?.organizationId === '' || des?.organizationId === orgId)
                this.count++;
                console.log("Loaded descriptors by type: " + type);
                console.log(descriptors);
                dispatch(cache.actions.operationSuccess(this.filterAndSort(descriptors, ignoreEmptyDescriptor)));
                if(this.count === 5){
                    dispatch(this.descriptorCache.actions.operationSuccess([]))
                }
            })
            .catch((error) => {
                this.count++;
                console.log("Error loading entities by type: " + type);
                console.log(error);
                dispatch(cache.actions.operationSuccess(ignoreEmptyDescriptor ? [] : [this.createEmptyDescriptor()]));
                if(this.count === 5){
                    dispatch(this.descriptorCache.actions.operationSuccess([]))
                }
            });
    };

    getDescriptorsByStateAndType = async(evvState, orgId) => {
        return evvRepository.evvDb.descriptor.where('[type+subType+organizationId]').anyOf([DESCRIPTOR_EVV_REASON_CODES, evvState, ''], [DESCRIPTOR_EVV_REASON_CODES, evvState, orgId]).toArray();
    }
}

export default DescriptorCacheService;

