import {useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from "@material-ui/core/styles";
import EvvButton from "../../../common/components/EvvButton";
import EvvSelect from "../../../common/components/EvvSelect";
import syncService from "../../../common/services/syncService";
import { SERVICE_NO_DOCUMENTATION_REQUIRED_ID } from "../../../common/constants";

const useStyles = makeStyles(() => ({
    dialogPaper: {
        width: '400px'
    },
    dialogTitle: {
        maxWidth: '660px',
        maxHeight: '800px',
        height: '42px',
        width: 'auto',
        fontSize: '16px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: "#ffffff",
        paddingLeft: '16px',
        display: 'flex',
        alignItems: 'center'
    },
    dialogContent: {
        padding: "19px 19px 25px 19px",
    },
    timesGrid: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        columnGap: '26px'
    },
    okCancelButtonGroup: {
        padding: "0px 16px 15px 16px",
        justifyContent: 'space-between'
    },
    okButton: {
        padding: '0',
        minWidth: '125px'
    },
    cancelButton: {
        padding: '0'
    },
    label: {
        flexDirection: 'column',
        textTransform: "none",
        color: '#888888'
    },
    filterSelect: {
        width: '100%',
        height: '27px',
        borderRadius: '2px',
        background: "transparent",
        borderStyle: "solid",
        borderWidth: '1px',
        borderColor: "#b6b6b6"
    },
    errorMessage: {
        position: 'relative',
        bottom: '-5px',
        fontSize: '13px',
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: '16px',
        letterSpacing: 0,
        color: "#dc0707"
    }
}));

export default function DocumentChooserDialog({documents, defaultDocument,  onClose, call}) {
    const [visibleDocuments, setVisibleDocuments] = useState([]);
    const [selectedDocument, setSelectedDocument] = useState(defaultDocument);
    const styles = useStyles();
    const NEGATIVE_ID = -1;

    useEffect(() => {
        if (documents) {
            setVisibleDocuments(documents.filter(document => call === "NewDocument" ? document?.canAddDocumentInErc === true && document?.isIncludeCarelogicEvv === true : document?.isIncludeCarelogicEvv === true));
        }

        if (defaultDocument) {
            setSelectedDocument(defaultDocument);
        }
        // eslint-disable-next-line
    }, [documents, defaultDocument]);

    const fieldToSetterMap = {
        selectedDocument: setSelectedDocument
    };

    const handleTextFieldChange = (evt) => {
        fieldToSetterMap[evt.target.id](evt.target.value);
    };

    const handleSave = () => {
        if (onClose) {
            const documentSelected = documents.find(doc => doc.id + "" === selectedDocument);            
            if (selectedDocument===SERVICE_NO_DOCUMENTATION_REQUIRED_ID){
                const serviceDocument = {
                    serviceDocumentId: selectedDocument,
                    appointmentId: documentSelected.appointment.activityDetailId,
                    clientId: NEGATIVE_ID,
                    clientProgramId: NEGATIVE_ID,
                    staffId: NEGATIVE_ID,
                    organizationId: NEGATIVE_ID,
                    serviceDate: "",
                    fullySigned: false,
                    moduleData: [],
                    signature: {}
                }    
                syncService.documentService.writeDocument(serviceDocument,[]);
            }            
            onClose(documents.find(doc => doc.id + "" === selectedDocument));
        }
    };

    const handleCancel = () => {
        if (onClose) {
            onClose(null);
        }
    }

    return (
        <div>
            <Dialog
                classes={{paper: styles.dialogPaper}}
                open={true}
                disableEscapeKeyDown={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle classes={{root: styles.dialogTitle}} id="documentChooserDialog">Document Chooser</DialogTitle>
                <DialogContent classes={{root: styles.dialogContent}}>
                    <div>
                        <EvvSelect id='selectedDocument' value={selectedDocument} className={styles.filterSelect} onChange={handleTextFieldChange}>
                            <option key={`option_empty`} value={''}>Select Document</option>
                            {visibleDocuments.map((document, index) =>
                                <option key={`option_${index}`} value={document.id}>{document.description}</option>)}
                        </EvvSelect>
                    </div>
                </DialogContent>
                <DialogActions classes={{root: styles.okCancelButtonGroup}}>
                    <div className={styles.cancelButton}>
                        <EvvButton type='tertiary' onClick={handleCancel}>Cancel</EvvButton>
                    </div>
                    <div className={styles.okButton}>
                        <EvvButton disabled={!selectedDocument || document.length === 0} type='primary' onClick={handleSave}>Select</EvvButton>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
}
