import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import {makeStyles} from "@material-ui/core/styles";
import {useState} from "react";
import { PRIMARY_COLOR } from "../../constants";

const useStyles = makeStyles((theme) => ({
    accordion: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        paddingBottom: '2px'
    },
    accordionHeader: {
        display: 'flex',
        width: '100%',
        height: '22px',
        paddingTop: '2px',
        paddingBottom: '3px',
        backgroundColor: PRIMARY_COLOR,
        alignItems: 'center'
    },
    expanded: {
        transform: 'rotate(90deg)'
    },
    collapsed: {
        transform: 'rotate(0deg)'
    },
    hideIcon: {
        visibility: 'hidden'
    },
    accordionTitle: {
        fontSize: '16px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: "#ffffff"
    },
    accordionContentCollapsed: {
        display: 'none'
    },
    accordionContentExpanded: {
        display: 'block'
    }
}));

export default function Accordion({title, initialExpanded = false, children, onExpand, onCollapse, onChange, titleStyle, hideIcon = false}) {
    const [expanded, setExpanded] = useState(!!initialExpanded);
    const styles = useStyles();

    const handleChange = (e) => {
        if (onChange){
            onChange(expanded);
        }

        if (!expanded && onExpand){
            onExpand()
        }

        if (expanded && onCollapse){
            onCollapse()
        }

        setExpanded(!expanded);
    }

    let iconClassName = expanded ? styles.expanded : styles.collapsed;

    if (hideIcon){
        iconClassName += ' ' + styles.hideIcon;
    }

    return (
        <div className={styles.accordion}>
            <div className={styles.accordionHeader} >
                <div className={iconClassName}>
                    <ArrowRightIcon data-testid='Accordion_icon' htmlColor='#ffffff' onClick={handleChange}/>
                </div>
                {title && <div data-testid='Accordion_title' style={titleStyle} className={styles.accordionTitle}>{title}</div>}
            </div>
            <div className={expanded ? styles.accordionContentExpanded : styles.accordionContentCollapsed}>
                {children}
            </div>
        </div>
    );
}