import {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Tab, Tabs} from "@material-ui/core";
import ClientSnapshot from "../ClientSnapshot";
import {useSelector, useStore} from "react-redux";
import {clientCache} from "../../../cache/slices/client/clientSlice";
import TasksTab from "../TasksTab";
import AllergiesTab from "../AllergiesTab";
import MedicationsTab from "../MedicationsTab";
import DiagnosesTab from "../DiagnosesTab";
import syncService from "../../../common/services/syncService";
import DocumentsTab from "../DocumentsTab";
import {documentCache} from "../../../cache/slices/document/documentCache";
import clientService from "../../../common/services/clientService";
import {appCache} from "../../../cache/slices/app/appSlice";
import { PRIMARY_COLOR } from "../../../common/constants";
import {MessageBoard} from "../MessageBoard/MessageBoard";
import CircleIcon from '@mui/icons-material/Circle';
import { VitalsTab } from "../Vitals/VitalsTab";

const useStyles = makeStyles((theme) => ({
    tabletFacesheetPage: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column'
    },
    tabBar: {
        ...theme.shadowedContainer,
        height: 'auto',
        overflow: 'unset',
        display: 'flex',
        flexDirection: 'column'
    },
    tabs: {
        height: '48px'
    },
    tabIndicator: {
        height: '3px',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        '& > span': {
            maxWidth: '120px',
            width: '60%',
            backgroundColor: "#6b6b6b"
        },
        marginBottom: '1px'
    },
    tab:{
        fontSize: '18px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: PRIMARY_COLOR,
        textTransform: 'none',
        opacity: 1,
        minWidth: '111px'
    },
    selectedTab: {
        opacity: '1 !important',
        color: '#6b6b6b !important'
    },
    tab0: {
        width: '100%',
        height: '100%',
        overflow: 'auto'
    },
    tabx: {
        ...theme.shadowedContainer
    },
    tasksContainer: {
        ...theme.shadowedContainer
    },
    activitiesContainer: {
        ...theme.shadowedContainer
    },
    iconLabelWrapper2: {
        flexDirection: "row-reverse",
        paddingLeft: '14%'
    },
    messagesTab:{
        fontSize: '18px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: PRIMARY_COLOR,
        textTransform: 'none',
        opacity: 1,
        minHeight: '40px',
        minWidth: '148px'
    }
}));

const TABS = ['Tasks', 'Documents', 'Messages', 'Vitals', 'Allergies', 'Medications', 'Diagnosis'];

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    const styles = useStyles();

    if (value === index){
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`Tab_${TABS[index]}`}
                aria-labelledby={`Tab_${TABS[index]}`}
                className={styles[`tab${index === 0 ? 0 : 'x'}`]}
                {...other}
            >
                {children}
            </div>
        );
    } else {
        return null;
    }
}

export default function TabletFacesheetPage() {
    const crosswalkedDocuments = useSelector(documentCache.getCrosswalkedDocuments);
    const [selectedTab, setSelectedTab] = useState(0);
    const client = useSelector(clientCache.getSelectedClient);
    const styles = useStyles();
    const store = useStore();
    const user = useSelector(appCache.getUser);
    const tasks = useSelector(appCache.getTasks);
    const [firstRender, setFirstRender] = useState(true);
    const messages = useSelector(syncService.messageService.getResults());

    useEffect(() => {
        if(firstRender) {
            syncService.auditService.saveAuditEvent(null, client, user, "EVV Client Facesheet", "View");
            if(selectedTab === 0 && (tasks && tasks.length > 0)) {
                syncService.auditService.saveAuditEvent(null, client, user, "EVV Client Tasks", "View");
            }
            setFirstRender(false)
        }
    }, [firstRender, selectedTab, tasks, client, user]);

    useEffect(() => {
        if (client) {
            if (crosswalkedDocuments && crosswalkedDocuments.length === 0) {
                setSelectedTab((0))
            }
            clientService.fetchAddresses({store}, appCache.getClients(store.getState()));
            syncService.syncFacesheetData({store}, client);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client]);

    useEffect(() => {
        if (crosswalkedDocuments && crosswalkedDocuments.length > 0) {
            setSelectedTab((1))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [crosswalkedDocuments]);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
        if(newValue === 0 && (tasks && tasks.length > 0)) {
            syncService.auditService.saveAuditEvent(null, client, user, "EVV Client Tasks", "View");
        }
    };

    const isMsgTabWithMsgs = (messages, tabLabel) => {
        return tabLabel === "Messages" && messages && messages?.length > 0;
    }

    return (
        <div className={styles.tabletFacesheetPage}>
            <ClientSnapshot client={client} call = "TabletFacesheetPage" selectedTab={selectedTab}/>
            <div className={styles.tabBar}>
                <Tabs
                    variant="fullWidth"
                    value={selectedTab}
                    onChange={handleTabChange}
                    TabIndicatorProps={{ children: <span /> }}
                    classes={{root: styles.tabs, indicator: styles.tabIndicator}}
                >
                    {TABS.map((tabLabel) => <Tab key={tabLabel} classes={{root: isMsgTabWithMsgs(messages, tabLabel) ? styles.messagesTab : styles.tab, selected: styles.selectedTab, wrapper: isMsgTabWithMsgs(messages, tabLabel) ? styles.iconLabelWrapper2 : ''}} label={tabLabel} icon={isMsgTabWithMsgs(messages, tabLabel) ? <CircleIcon style={{ color: "#B23815", fontSize: '16px', marginLeft: '5px', marginTop: '6px' }} /> : ''}/>)}
                </Tabs>
            </div>
            <TabPanel index={0} value={selectedTab}>
                <TasksTab />
            </TabPanel>
            <TabPanel index={1} value={selectedTab}>
                <DocumentsTab />
            </TabPanel>
            <TabPanel index={2} value={selectedTab}>
                <MessageBoard />
            </TabPanel>
            <TabPanel index={3} value={selectedTab}>
                <VitalsTab />
            </TabPanel>
            <TabPanel index={4} value={selectedTab}>
                <AllergiesTab />
            </TabPanel>
            <TabPanel index={5} value={selectedTab}>
                <MedicationsTab />
            </TabPanel>
            <TabPanel index={6} value={selectedTab}>
                <DiagnosesTab />
            </TabPanel>
        </div>
    )
}
