import {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from "@material-ui/core/styles";
import EvvButton from "../../../common/components/EvvButton";
import StackedField from "../../../common/components/StackedField";
import InfoPopper from "../../../common/components/InfoPopper";
import userService from "../../../common/services/userService";
import {evvRepository} from "../../../db/evv";
import InputForPassword from './InputForPassword';

const useStyles = makeStyles(() => ({
    dialogPaper: {
        width: '400px',
        maxWidth: '400px'
    },
    dialogTitle: {
        maxWidth: '660px',
        maxHeight: '800px',
        height: '42px',
        width: 'auto',
        fontSize: '16px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: "#ffffff",
        paddingLeft: '16px',
        display: 'flex',
        alignItems: 'center'
    },
    dialogContent: {
        padding: "19px 19px 25px 19px",
    },
    okCancelButtonGroup: {
        padding: "0px 16px 15px 16px",
        justifyContent: 'space-between'
    },
    okButton: {
        padding: '0',
        minWidth: '80px'
    },
    cancelButton: {
        padding: '0'
    },
    errorMessage: {
        // position: 'relative',
        // bottom: '-5px',
        fontSize: '13px',
        fontWeight: "normal",
        fontStyle: "normal",
        letterSpacing: 0,
        color: "#dc0707",
        paddingTop: '5px',
        paddingBottom: '5px'
    },
    hiddenErrorMessage: {
        visibility: 'hidden',
        fontSize: '13px',
        fontWeight: "normal",
        fontStyle: "normal",
        letterSpacing: 0,
        color: "#dc0707",
        paddingTop: '5px',
        paddingBottom: '5px',
        minHeight: '32px'
    }
}));

const EVV_APPLICATION = 'Carelogic Enterprise';
const INFO_ROWS_PASSWORD_RULES = [
    '1. Must comply with the existing rules for minimum length / numbers / symbols.',
    '2. Password and Electronic Signature are case sensitive.',
    '3. Password and Electronic Signature cannot match.',
    '4. You cannot use your login name.',
];

export default function ResetPasswordDialog({user, accountName, userName, daysUntilPasswordExpires, onClose}){
    const [unknownError, setUnknownError] = useState('');
    const [existingPassword, setExistingPassword] = useState('');
    const [existingPasswordError, setExistingPasswordError] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordError, setNewPasswordError] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [confirmNewPasswordError, setConfirmNewPasswordError] = useState('');
    const [newSignature, setNewSignature] = useState('');
    const [newSignatureError, setNewSignatureError] = useState('');
    const [confirmNewSignature, setConfirmNewSignature] = useState('');
    const [confirmNewSignatureError, setConfirmNewSignatureError] = useState('');
    const styles = useStyles();

    const fieldToSetterMap = {
        existingPassword: setExistingPassword,
        newPassword: setNewPassword,
        confirmNewPassword: setConfirmNewPassword,
        newSignature: setNewSignature,
        confirmNewSignature: setConfirmNewSignature
    };

    const handleTextFieldChange = (evt) => {
        fieldToSetterMap[evt.target.id](evt.target.value);
    };

    const getEmptyValueErrorMessage = (fieldName) => {
        return `${fieldName} is a required field. Please enter a value and resubmit.`;
    };

    const handleSubmit = () => {
        let isValid = true;

        if (!existingPassword){
            setExistingPasswordError(getEmptyValueErrorMessage('Existing Password'));
            isValid = false;
        } else {
            setExistingPasswordError('');
        }

        if (!newPassword){
            setNewPasswordError(getEmptyValueErrorMessage('New Password'));
            isValid = false;
        } else {
            setNewPasswordError('');
        }

        if (!confirmNewPassword){
            setConfirmNewPasswordError(getEmptyValueErrorMessage('Confirm New Password'));
            isValid = false;
        } else {
            if (newPassword && newPassword !== confirmNewPassword){
                setConfirmNewPasswordError('The new password entered does not match the confirmed password entered.')
                isValid = false;
            } else {
                setConfirmNewPasswordError('');
            }
        }

        if (!newSignature){
            setNewSignatureError(getEmptyValueErrorMessage('New Signature'));
            isValid = false;
        } else {
            setNewSignatureError('');
        }

        if (!confirmNewSignature){
            setConfirmNewSignatureError(getEmptyValueErrorMessage('Confirm New Signature'));
            isValid = false;
        } else {
            if (newSignature && newSignature !== confirmNewSignature){
                setConfirmNewSignatureError('The new electronic signature entered does not match the confirmed signature entered.')
                isValid = false;
            } else {
                setConfirmNewSignatureError('');
            }
        }

        if (isValid) {
            changePassword();
        }
    };

    const changePassword = () => {
        const changePasswordParameters = {
            username: userName,
            oldPassword: existingPassword,
            newPassword: newPassword,
            newSignature: newSignature,
            application: EVV_APPLICATION
        };

        setExistingPasswordError('');
        setNewPasswordError('');
        setConfirmNewPasswordError('');
        setNewSignatureError('');
        setConfirmNewSignatureError('');
        setUnknownError('');

        userService.changePassword(accountName, changePasswordParameters)
            .then(response => {
                if (response.result) {
                    handleOk();
                } else {
                    if (response.errorMessage){
                        if (response.errorMessage.code && response.errorMessage.code.startsWith('password')) {
                            setNewPasswordError(response.errorMessage.message);
                        } else if (response.errorMessage.code === 'incorrect.password'){
                            setExistingPasswordError(response.errorMessage.message);
                            return;
                        } else if (response.errorMessage.code === 'previous.password'){
                            setNewPasswordError(response.errorMessage.message);
                            return;
                        } else if (response.errorMessage.code === 'matching.password.signature'){
                            setNewSignatureError(response.errorMessage.message);
                            return;
                        } else {
                            setUnknownError(response.errorMessage.message);
                            return;
                        }
                    }

                    setUnknownError('Unable to change password.');
                }
            })
            .catch(err => {
                console.log(err);
                handleClose('ok')
            });
    }

    const resetPassword = (userName, newPassword, loggedInUser) => {
        evvRepository.changeUser(userName, newPassword)
            .then(() => {
                userService.saveUser(loggedInUser)
                    .then(() => {
                        handleClose('ok')
                    })
                    .catch(err => {
                        handleClose('ok')
                    });
            })
            .catch(err => {
                handleClose('ok')
            });
    }

    const handleOk = () => {
        resetPassword(userName, newPassword, user);
    }

    const handleCancel = () => {
        handleClose('cancel')
    }

    const handleRemindMeLater = () => {
        handleClose('remind')
    }

    const handleClose = (reason) => {
        if (onClose){
            onClose(reason);
        }
    }

    const renderContent = () => {
        return (
            <div>
                <div><InfoPopper infoTitle='Rules' infoRows={INFO_ROWS_PASSWORD_RULES} /></div>
                <div>
                    {
                        daysUntilPasswordExpires < 0
                            ? 'Your password is expired and must be changed before you can login.'
                            : `Your password will expire in ${daysUntilPasswordExpires} day(s). You can change it now, or click 'Remind Me Later' to be reminded at next login.`
                    }
                </div>
                {unknownError &&
                    <div className={styles.errorMessage} >
                        {unknownError}
                    </div>
                }
                <div>
                    <StackedField mandatory={true} label='Existing Password' paddingTop="22px" >
                        <InputForPassword id='existingPassword' value={existingPassword} placeholder='Please enter your existing password' onChange={handleTextFieldChange}/>
                    </StackedField>
                    <div className={existingPasswordError ? styles.errorMessage : styles.hiddenErrorMessage} >
                        {existingPasswordError}
                    </div>
                </div>
                <div>
                    <StackedField mandatory={true} label='New Password' paddingTop="0px" >
                        <InputForPassword id='newPassword' value={newPassword} placeholder='Please enter your new password' onChange={handleTextFieldChange}/>
                        <div className={newPasswordError ? styles.errorMessage : styles.hiddenErrorMessage} >
                            {newPasswordError}
                        </div>
                    </StackedField>
                </div>
                <div>
                    <StackedField mandatory={true} label='Confirm New Password' paddingTop="0px" >
                        <InputForPassword id='confirmNewPassword' value={confirmNewPassword} placeholder='Please confirm your new password' onChange={handleTextFieldChange}/>
                        <div className={confirmNewPasswordError ? styles.errorMessage : styles.hiddenErrorMessage} >
                            {confirmNewPasswordError}
                        </div>
                    </StackedField>
                </div>
                <div>
                    <StackedField mandatory={true} label='New Signature' paddingTop="0px" >
                        <InputForPassword id='newSignature' value={newSignature} placeholder='Please enter your new signature' onChange={handleTextFieldChange}/>
                        <div className={newSignatureError ? styles.errorMessage : styles.hiddenErrorMessage} >
                            {newSignatureError}
                        </div>
                    </StackedField>
                </div>
                <div>
                    <StackedField mandatory={true} label='Confirm New Signature' paddingTop="0px" >
                        <InputForPassword id='confirmNewSignature' value={confirmNewSignature} placeholder='Please confirm your new signature' onChange={handleTextFieldChange}/>
                        <div className={confirmNewSignatureError ? styles.errorMessage : styles.hiddenErrorMessage} >
                            {confirmNewSignatureError}
                        </div>
                    </StackedField>
                </div>
            </div>
        );
    }

    return (
        <div>
            <Dialog
                open={true}
                disableEscapeKeyDown={true}
                classes={{paper: styles.dialogPaper}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle classes={{root: styles.dialogTitle}} id="alert-dialog-title">Set Password/Signature</DialogTitle>
                <DialogContent classes={{root: styles.dialogContent}}>
                    {renderContent()}
                </DialogContent>
                <DialogActions classes={{root: styles.okCancelButtonGroup}}>
                    <div className={styles.cancelButton}>
                        <EvvButton id='ResetPasswordDialog_cancel' type='tertiary' onClick={handleCancel} >Cancel</EvvButton>
                    </div>
                    {daysUntilPasswordExpires >= 0 &&
                        <div className={styles.cancelButton}>
                            <EvvButton styleOverride={{height: '62px', width: '60px', paddingLeft: '3px', fontSize: '16px'}} id='ResetPasswordDialog_remind' type='tertiary' onClick={handleRemindMeLater}>Remind Me Later</EvvButton>
                        </div>
                    }
                    <div className={styles.okButton}>
                        <EvvButton id='ResetPasswordDialog_submit' type='primary' onClick={handleSubmit} >Submit</EvvButton>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
}
