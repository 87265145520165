import LoginTextField from "../../../common/components/LoginTextField";
import React from "react";
import FormField from "./FormField";
import DisplayErrorMessages from "../common/DisplayErrorMessages";
import { assignDbSavedValue } from "../common/FormFieldsUtils";

class SSNField extends FormField{
    getValue = () => {
        let ssnValue = this.fieldDescriptor?.dbSavedValue;
        if (ssnValue) {
            this.fieldDescriptor = assignDbSavedValue(this.fieldDescriptor, ssnValue);
        }
        return this.fieldDescriptor.options[0].value;
    }

    textFieldTemplate = (props, textField, isRequired, width, disabled) => {
        return (
            <LoginTextField
                {...props}
                type="text"
                id={textField.id}
                name={textField.name}
                value={ this.getValue() }
                placeholder={disabled ? ' ': "XXX-XX-XXXX"}
                required={isRequired}
                maxCharacters={11}
                maxLength={11}
                phoneField={textField}
                width={width}
                onChange={(event) => { props.onChange(event, this.fieldDescriptor, props); } }
                disabled = { disabled }
            />
        )
    }

    setSSNFieldAttribute = (ssnField, id) => {
        ssnField.forEach((textField) => {
            textField.id = `SSN-${id}`;
            if(textField.value === undefined) {
                textField.value = "";
            }
        })
    }

    renderField(props, state){
        let isRequired = this.isMandatory();
        let description = "";
        let filteredObj = this.fieldDescriptor.attributes.find((attribute) => attribute.attributeId === this.fieldDescriptor.id +"_description");
        if(Object.keys(filteredObj).length > 0) {
            description = filteredObj.value;
        }
        this.setSSNFieldAttribute(this.fieldDescriptor.options, this.fieldDescriptor.id);
        props.fieldDescriptor = this.fieldDescriptor;

        return (
            <div>
                {this.fieldDescriptor?.businessRuleApplied !== "Hide" &&
                <>
                <div>
                  <span>{description}</span>
                </div>
                <div className="phoneField">
                    {this.textFieldTemplate(props, this.fieldDescriptor.options[0], isRequired, '128px', state.isReadOnlyDoc)}
                </div>
                <div>
                    <DisplayErrorMessages
                        {...props}
                    />
                </div>
                </>
                }
            </div>
        )
    }
}

export default SSNField;