import {createSlice} from '@reduxjs/toolkit'
import {DateTime} from "luxon";

const INITIAL_DATE = DateTime.now();

export const scheduleSlice = createSlice({
    name: 'schedule',
    initialState: {
        selectedDate: INITIAL_DATE,
        viewDate: INITIAL_DATE,
        calendarView: 'Day',
        selectedWeekDate: INITIAL_DATE,  
    },
    reducers: {
        setSelectedDate: (state, action) => {
            state.selectedDate = action.payload;
        },
        setViewDate: (state, action) => {
            state.viewDate = action.payload;
        },
        setCalendarView: (state, action) => {
            state.calendarView = action.payload;
        },
        setSelectedWeekDate: (state, action) => {
            state.selectedWeekDate = action.payload;
        }
    }
})

export const scheduleCache = {
    ...scheduleSlice.actions,

    getSelectedDate: (state) => state.schedule.selectedDate,
    getViewDate: (state) => state.schedule.viewDate,
    getCalendarView: (state) => state.schedule.calendarView,
    getSelectedWeekDate: (state) => state.schedule.selectedWeekDate
}

export default scheduleSlice.reducer;
