import { getIndividualScore } from '../../../common/utils/impactUtils';
import * as msgConst from '../../forms/common/Messages';
import { focusOnElement } from '../common/FindFirstErrorFieldUtils';

export const impactValidationWithFocus = (module, hideErrors) => {
    let validateResult = validateImpactFields(module, hideErrors);
    focusOnElement(module);
    return validateResult;
}

export const validateImpactFields = (module, hideErrors) => {
    let count = 0;
    module.floatingErrorMessage = "";

    let validateResult = {
        isValid: true,
        showFloatingErrorMsg: false
    }

    validateResult.showFloatingErrorMsg = false;

    let sections = module?.impactConfig?.sections;
    sections?.forEach((sec) => {
        let secHideFlag = checkSecBusinessRules(module, sec);
        sec?.questions?.forEach((que) => {
            que.impactError = false;
            que['errorMessage'] = "";
            resetDataOnClickOfNotAssessed(module, que);

            if (!secHideFlag) {
                if(module?.notAssessed === "N") {
                    let isRequired = checkQuesBusinessRules(que, module);
                    if (!hideErrors && isRequired) {
                        count = requiredErrorFun(module?.impactConfig, que, count);
                    }
                }
            }
        })
    })

    if(count > 0) {
        validateResult = setFloatingErrorMsgForImpact(validateResult, module, count);
    }
    
    return validateResult;
}

export const setFloatingErrorMsgForImpact = (validateResult, module, count) => {
    let floatingErrorMessage = "";

    if(count > 1) {
        validateResult.showFloatingErrorMsg = true;
        floatingErrorMessage = `${count} ${msgConst.floatingErrorMessageString}`;
    }

    module.floatingErrorMessage = floatingErrorMessage;
    validateResult.isValid = false;

    return validateResult;
}

export const requiredErrorFun = (impactConfig, que, count) => {
    que.impactError = true;
    if(impactConfig?.newLogical === "N" || !impactConfig?.newLogical) {
        if(que?.nonScoring === "N"){
            count = selectedAnswerFun(que, count);
        } else {
            que.impactError = false;
        }
    } else {
        if(impactConfig?.newLogical === "Y") {
           count = selectedAnswerFun(que, count);
        } else {
            que.impactError = false;
        }
    }
    return count; 
}

export const selectedAnswerFun = (que, count) => {
    if(que?.controlType === "CHECKBOX") {
        if(!que?.selectedAnswer || (que?.selectedAnswer && que?.selectedAnswer.length === 0)) {
            que['errorMessage'] = `${que.questionText} ${msgConst.errorMessageString}`;
            count++;
        } else {
            que.impactError = false;
        }
    } else if(que?.controlType === "DROPDOWN") {
        if(que?.selectedAnswer === "0" || !que?.selectedAnswer) {
            que['errorMessage'] = `${que.questionText} ${msgConst.errorMessageString}`;
            count++;
        } else {
            que.impactError = false;
        }
    } else {
        if(!que?.selectedAnswer) {
            que['errorMessage'] = `${que.questionText} ${msgConst.errorMessageString}`;
            count++;
        } else {
            que.impactError = false;
        }
    }
    return count;
}

export const resetDataOnClickOfNotAssessed = (module, que) => {
    if(module?.notAssessed === "Y") {
        module.fieldData = [];
        que.ansKey = "";
        if(que?.controlType !== "CHECKBOX") {
            que.selectedAnswer = "";
        } else {
            que.selectedAnswer = [];
        }
    }
}

export const checkQuesBusinessRules = (que, module) =>{
    let isRequired = true;
    let hideFlag = false;
    let businessRule = que?.businessRule;

    if (businessRule) {
        hideFlag = businessRuleOptions(que, module);
        if (hideFlag) {
            isRequired = false;
        }
    }

    return isRequired;
}

export const businessRuleOptions = (que, module) => {
    let satisfied = true;
    let businessRule = que?.businessRule;
    let action = que?.action;
    let defaultAction = que?.defaultAction;
    let hideFlag = (defaultAction === "HIDE") ? true : false;

    businessRule = businessRule?.replace(/\s+/g, '');

    if (businessRule?.includes("&&")) {
        //sect2q6==1 && sect2q7==4
        let splitRuleArr = businessRule?.split("&&");
        satisfied = loopThroughRuleOne(splitRuleArr, module, satisfied);
    } else if (businessRule?.includes("indexOf")) {
        //sect2q7.indexOf('4')>-1
        let splitRuleArr = businessRule?.split(".indexOf");
        if (splitRuleArr?.length > 0) {
            let operand = splitRuleArr[0];
            let expectedVal = splitRuleArr[1].substring(1, splitRuleArr[1].indexOf(')')).slice(1, -1);
            let modifiedRule = `${operand}==${expectedVal}`;
            satisfied = ruleCheck(module, modifiedRule, satisfied);
        }
    } else if (businessRule?.includes("==")) {
        //sect3q9==1
        satisfied = ruleCheck(module, businessRule, satisfied);
    }

    if (satisfied) {
        hideFlag = (action === "HIDE") ? true : false;
    }

    if (hideFlag) {
        resetAnswer(que);
    }

    que.hideFlag = hideFlag;

    return hideFlag;
}

export const resetAnswer = (que) => {
    que.ansKey = "";
    if(que?.controlType !== "CHECKBOX") {
        que.selectedAnswer = "";
    } else {
        que.selectedAnswer = [];
    }
}

export const loopThroughRuleOne = (splitRuleArr, module, satisfied) => {
    splitRuleArr?.forEach((rule) => {
        satisfied = ruleCheck(module, rule, satisfied);
    });

    return satisfied;
}

export const ruleCheck = (module, rule, satisfied) => {
    let queSequenceArr = rule?.substring(rule?.indexOf('q') + 1).split("==");

    if (queSequenceArr && queSequenceArr.length > 0) {
        let queSequence = Number(queSequenceArr[0]);
        let queCompValue = Number(queSequenceArr[1]);
        let expSectStr = rule?.substring(0, rule?.indexOf('q'));
        let sectSequence = expSectStr?.split('sect')[1];
        let score = getIndividualScore(sectSequence, queSequence, module?.impactConfig);
        if (score !== queCompValue) {
            satisfied = false;
        }
    }

    return satisfied;
}

export const checkSecBusinessRules = (module, sec) => {
    let satisfied = true;
    let businessRule = sec?.businessRule;
    let action = sec?.action;
    let defaultAction = sec?.defaultAction;
    let hideFlag = (defaultAction === "HIDE") ? true : false;

    businessRule = businessRule?.replace(/\s+/g, '');

    if (businessRule?.includes("getYears")) {
        satisfied = checkSecRuleOne(module, businessRule, satisfied);
    }

    if (satisfied) {
        hideFlag = (action === "HIDE") ? true : false;
    }

    sec.hideFlag = hideFlag;

    return hideFlag;
}

export const checkSecRuleOne = (module, businessRule, satisfied) => {
    let year, month, operator;
    let splitRuleArr = businessRule?.split("?true:");
    if (splitRuleArr?.length > 0) {
        let yearWithOperator = splitRuleArr[0].replace("getYears()", "");
        let monthWithOperator = splitRuleArr[1].replace("getMonths()", "");
        let yearMatch = yearWithOperator.match(/(\d+)/);
        let monthMatch = monthWithOperator.match(/(\d+)/);

        if (yearMatch) {
            year = yearMatch[0];
            operator = yearWithOperator.substring(0, yearMatch['index']);
        }

        if (monthMatch) {
            month = monthMatch[0];
        }

        if (year && month && operator) {
            let yearMonth = Number(`${year}.${month}`);
            if (yearMonth) {
                if (!allowedClientAge(module, yearMonth, operator)) {
                    satisfied = false;
                }
            }
        }
    }
    return satisfied;
}

export const allowedClientAge = (module, yearMonth, operator) => {
    let clientAge = Number(module?.client?.age);
    switch(operator) {
        case "<": return clientAge < yearMonth;
        case "<=": return clientAge <= yearMonth;
        case ">": return clientAge > yearMonth;
        case ">=": return clientAge >= yearMonth;
        default: return clientAge === yearMonth;
    }
}