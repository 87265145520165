import { combineDateAndTime } from "./dateTimeUtils";
import { dateToStringWithFormat, defaultDateAsString, defaultTimeAsString, stringToDate, APPOINTMENT_START_STOP_DATE_TIME_FORMAT, stringToDateWithFormat, formatActivity, formatStaffName } from "./formatUtils";
import {getDispatchFromConfig} from "../utils/miscUtils";
import syncService from "../services/syncService";
import { DateTime } from "luxon";
import { HIDE_VISIT_VERIFICATION_SIGNATURE_STATUSES, SHOWSIGNATUREPAGESTATES, SKIPSIGNATUREPAGESTATES, ALERT_DIALOG_ERROR, ALERT_DIALOG_OVERLAP} from "../constants";
import { isEvvActivity, appointmentOverlaps } from "./appointmentUtils";
import { pushServiceDocument } from "../../features/schedule/AppointmentEvent/NewDocumentUtils";
import { tabDocEditAction } from "./documentUtils";
import clientService from "../services/clientService";
import { documentCache } from "../../cache/slices/document/documentCache";
import { clientCache } from "../../cache/slices/client/clientSlice";

export const getVisitObject = (appointment, organization) => {
    const appointmentDate = defaultDateAsString(appointment?.startDateInstance);
    const startTime = defaultTimeAsString(appointment?.startDateInstance);
    const endTime = defaultTimeAsString(appointment?.endDateInstance);
    const appointmentDateInstance = stringToDate(appointmentDate).startOf('day');
    const formatStartTime = startTime;
    const startDateInstance = combineDateAndTime(appointmentDateInstance, formatStartTime);
    const formatEndTime = endTime;
    let newEndDateInstance = combineDateAndTime(appointmentDateInstance, formatEndTime);
    const endDateInstance = newEndDateInstance;

    let visit = {};
    visit.evvVisitId = appointment?.evvVisitId;
    visit.deviceVisitId = Date.now();
    visit.actualEndDateInstance = endDateInstance;
    visit.actualStartDateInstance = startDateInstance;
    visit.appointment = appointment;
    visit.appointmentId = appointment?.appointmentId;
    visit.beginAdjustedDateTime = dateToStringWithFormat(appointment?.startDateInstance, APPOINTMENT_START_STOP_DATE_TIME_FORMAT);
    visit.beginActualDateTime = dateToStringWithFormat(appointment?.startDateInstance, APPOINTMENT_START_STOP_DATE_TIME_FORMAT);
    visit.client = appointment?.client;
    visit.clientId = appointment?.client?.clientId;
    visit.complete = 0;
    visit.endAdjustedDateTime = dateToStringWithFormat(appointment?.endDateInstance, APPOINTMENT_START_STOP_DATE_TIME_FORMAT);
    visit.endActualDateTime = dateToStringWithFormat(appointment?.endDateInstance, APPOINTMENT_START_STOP_DATE_TIME_FORMAT);
    visit.startDateTime = appointment?.startDateInstance.toMillis();
    visit.endDateTime = appointment?.endDateInstance.toMillis();
    visit.beginDateTime = appointment?.startDateInstance.toMillis();
    visit = setOrganization(visit, organization);
    return visit;
}

export const setOrganization = (visit, organization) => {
    const appointmentToEnd = {...visit.appointment};
    appointmentToEnd.organization = organization;
    visit.appointment = appointmentToEnd;
    return visit;
}

export const isSessionEnded = (appointment, visitCollection) => {
    let sessionEnded = false;
    const visit = visitCollection && visitCollection.find((v) => v.appointmentId === appointment.appointmentId);
    if(visit) {
        if(visit.complete === 1) {
            sessionEnded = true;
        }
    }
    return sessionEnded;
}

export const cloneVisit = (visit) => {
    const visitCopy = {...visit};

    delete visitCopy.verificationDeclinedReason;
    delete visitCopy.verificationDeclinedComments;
    delete visitCopy.signatureMissingReason;
    delete visitCopy.signatureMissingComments;
    delete visitCopy.addressDistanceReason;
    delete visitCopy.addressDistanceComments;
    delete visitCopy.editTimesReason;
    delete visitCopy.editTimesComments;
    delete visitCopy.editTimesEventType;
    delete visitCopy.timesVerified;
    delete visitCopy.servicesVerified;

    return visitCopy;
}

export const removePropertiesVisit = (visit) => {
    const visitCopy = {...visit};

    if(visitCopy.hasSignature) {
        delete visitCopy.signatureMissingReason;
        delete visitCopy.signatureMissingComments;
    }
    if(visitCopy.timesVerified && visitCopy.servicesVerified) {
        delete visitCopy.verificationDeclinedReason;
        delete visitCopy.verificationDeclinedComments;
    }
    delete visitCopy.savedSignatureMissingReason;
    delete visitCopy.savedSignatureMissingComments;
    delete visitCopy.savedAddressDistanceReason;
    delete visitCopy.savedAddressDistanceComments;
    delete visitCopy.savedVerificationDeclinedReason;
    delete visitCopy.savedVerificationDeclinedComments;
    delete visitCopy.savedEditTimesReason;
    delete visitCopy.savedEditTimesComments;

    return visitCopy;
}

export const getDiscriptors = (orgId, state, setReasonCodes, setReasonCodeUi) => {
    syncService.descriptorCacheService.getDescriptorsByStateAndType(state, orgId).then((result) => {
        setReasonCodes(result);
        if(result) {
            setReasonCodeUi(true);
        }
    }).catch((error) => {
        console.log('Error: ', error);
    });
}

export const findVisitAndUpdate = (config, appointmentFromServer, originalAppointment, appointmentToSave) => {
    return syncService.visitService.getVisit(originalAppointment.appointmentId)
        .then((foundVisit) => {
            if (foundVisit.length > 0 && foundVisit[0].appointmentId < 0) {
                foundVisitOps(config, foundVisit, appointmentFromServer, appointmentToSave, originalAppointment);
            }
        })
        .catch((error) => {
            console.log("Error finding visit...");
            console.log(error);
        });
}

export const foundVisitOps = (config, foundVisit, appointmentFromServer, appointmentToSave, originalAppointment) => {
    console.log("Success finding visit...");
    console.log(foundVisit);
    let visitToSave = null;
    const dispatch = getDispatchFromConfig(config);
    visitToSave = {...foundVisit[0]};
    visitToSave.appointmentId = appointmentFromServer.activityLogId;
    visitToSave.appointment = appointmentToSave;
    visitToSave.actualStartDateInstance = stringToDateWithFormat(visitToSave.beginActualDateTime, APPOINTMENT_START_STOP_DATE_TIME_FORMAT);
    visitToSave.actualEndDateInstance = stringToDateWithFormat(visitToSave.endActualDateTime, APPOINTMENT_START_STOP_DATE_TIME_FORMAT);
    dispatch(syncService.visitService.operationReplace(visitToSave));
    syncService.visitService.updateVisit(originalAppointment, appointmentToSave);
}

export const findVisitAndRemove = async(config, originalAppointment) => {
    const dispatch = getDispatchFromConfig(config);
    dispatch(syncService.visitService.operationRemove(originalAppointment.client));
    syncService.visitService.deleteByPrimaryKey(originalAppointment.clientId);
}

export const getDuration = (startDateTime, endDateTime) => {
    let hrs = 0;
    let diffInMs = endDateTime - startDateTime;
    const seconds = diffInMs / 1000;
    const minutes = seconds / 60;
    hrs = Math.floor(minutes / 60);
    return hrs;
}

export const setStopAlert = (currentVisit) => {
    let startDateTimeAsDate, endDateTimeAsDate;
    endDateTimeAsDate = DateTime.fromMillis(currentVisit.endDateTime);
    if (currentVisit.originalStartDate) {
        startDateTimeAsDate = DateTime.fromMillis(currentVisit.startDateTime);
    } else {
        startDateTimeAsDate = DateTime.fromMillis(currentVisit.appointment.startDateInstance.ts);
    }
   let alertContent = {
        dialogTitle : 'Cannot Save Appointment',
        dialogMessage : `The activity you are attempting to schedule exceeds 24 hours: ${defaultDateAsString(startDateTimeAsDate)} ${defaultTimeAsString(startDateTimeAsDate)} -
          ${defaultDateAsString(endDateTimeAsDate)} ${defaultTimeAsString(endDateTimeAsDate)} ${formatActivity(currentVisit?.activity)}. Please Edit Times. `,
        showOkButton : false,
        showCancelButton: true
    }
    return alertContent;
}

export const getVisitAddress = (address) => {
    let visitAddress = null;
    if (address) {
        if (address.addressLatitude){
            visitAddress = {
                latitude: address.addressLatitude,
                longitude: address.addressLongitude
            };
        } else {
            visitAddress = {
                latitude: address.latitude,
                longitude: address.longitude
            };
        }
    }
    return visitAddress; 
}

export const skipVerifySignature = (evvState, appointmentStatus, activity) => {
    return !isEvvActivity(activity) || SKIPSIGNATUREPAGESTATES.includes(evvState) || (!SHOWSIGNATUREPAGESTATES.includes(evvState) && HIDE_VISIT_VERIFICATION_SIGNATURE_STATUSES.includes(appointmentStatus));
} 

export const hasOverlappingVisitAppointments = (visit, appointments, setAlertDialogConfig, staff, inSessionVisits) => {
    const overlaps = appointmentOverlaps(inSessionVisits, staff, visit, appointments, 'stopSession');
    if (overlaps){
        if (staff?.isMultiBookSchedule) {
            setAlertDialogConfig({
                dialogId: ALERT_DIALOG_OVERLAP,
                dialogTitle: 'Schedule Overlap',
                dialogMessage: `The activity you are attempting to schedule overlaps with an existing entry for this staff. Do you want to continue?`,
                showOkButton: true,
                okButtonText: 'Yes',
                showCancelButton: true,
                cancelButtonText: 'No'
            });
        } else {
            setAlertDialogConfig({  
                dialogId: ALERT_DIALOG_ERROR,                
                dialogTitle: 'Schedule Overlap',
                dialogMessage: `${formatStaffName(staff)} is not authorized to multibook appointments and the scheduled activity on this service date overlaps with an existing entry.`
            });
        }
    }
    return overlaps;
}

export const showVerifySignature = (evvState, appointmentStatus, appointment, activity) => {
    let currentActivity = activity ? activity : appointment?.activity;
    return isEvvActivity(currentActivity) && ( SHOWSIGNATUREPAGESTATES.includes(evvState) || (!SKIPSIGNATUREPAGESTATES.includes(evvState) && !HIDE_VISIT_VERIFICATION_SIGNATURE_STATUSES.includes(appointmentStatus)) );
}

export const getActivity = async(currentVisit, setActivity) => {
    let activity = await syncService.appointmentService.getAppointmentActivity(currentVisit?.appointment?.activityId);
    if (activity && activity?.length > 0) {
        setActivity(activity[0]);
    }
}

export const resumeDocumentation = async(setResumeAlertDialog) => {
    setResumeAlertDialog({
        dialogId: 'Resume Documentation',
        dialogTitle: 'Resume Documentation',
        dialogMessage: 'This session has an incomplete or unsigned Service Document. Would you like to resume documentation?',
        showOkButton: true,
        okButtonText: 'Yes',
        showCancelButton: true,
        cancelButtonText: 'No'
    });
};

export const shouldResumeDocumentation = (call, activeTab, attachedDocument, selectedTab) => { 
    return (attachedDocument && attachedDocument.length > 0 && 
    hasIncompleteOrUnsignedDocs(attachedDocument) && 
    ((call === "TabletFacesheetPage" && selectedTab === 1 && activeTab?.id !== attachedDocument[0]?.id) || call !== "TabletFacesheetPage" || (call === "TabletFacesheetPage" && selectedTab !== 1)));
}

export const hasIncompleteOrUnsignedDocs = (docs) => {
    return (docs && docs.find(doc => doc?.documentStatus === "Incomplete" || doc?.documentStatus === "Unsigned"));
}

export const handleResumeDocument = async (attachedDocument, isHandheld, dispatch, history, serviceDocTab) => {
    const savedDocument =  await syncService.documentService.findDocumentById(attachedDocument[0]?.id);
    const aptDocCollection = await  syncService.documentService.getServiceDoc(attachedDocument[0]?.serviceDocumentId);
    const clientOfTask = await clientService.findClientById(attachedDocument[0]?.clientId?.toString());
    dispatch(documentCache.setCurrentDocument(aptDocCollection[0]));
    dispatch(documentCache.setCurrentData({}));
    dispatch(clientCache.selectClient(clientOfTask[0]));
    dispatch(documentCache.setCurrentAptDocument([savedDocument]));
    pushServiceDocument(isHandheld, attachedDocument[0], history);
    tabDocEditAction(serviceDocTab, savedDocument, dispatch);
    dispatch(documentCache.setCrosswalkedDocuments(aptDocCollection));
}