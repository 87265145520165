import {makeStyles} from '@material-ui/core/styles';
import {Redirect, Route, Switch, useLocation} from "react-router-dom";
import AppHeader from "../AppHeader";
import NavigationBar from "../NavigationBar";
import SettingsPage from "../../settings/SettingsPage";
import {getPageDescriptor} from "../../../common/utils/pageUtils";
import DashboardPage from "../../dashboard/DashboardPage";
import CaseloadPage from "../../caseload/CaseloadPage";
import FacesheetPage from "../../facesheet/FacesheetPage";
import AllergiesPage from "../../facesheet/AllergiesPage";
import AllergyPage from "../../facesheet/AllergyPage";
import TabletFacesheetPage from "../../facesheet/TabletFacesheetPage";
import SchedulePage from "../../schedule/SchedulePage";
import MedicationsPage from "../../facesheet/MedicationsPage";
import MedicationPage from "../../facesheet/MedicationPage";
import ActiveSessionsPage from "../../facesheet/ActiveSessionsPage";
import {useDispatch, useSelector, useStore} from "react-redux";
import {useEffect, useState, useRef} from "react";
import DiagnosesPage from "../../facesheet/DiagnosesPage";
import DiagnosisPage from "../../facesheet/DiagnosisPage";
import TabletSchedulePage from "../../schedule/SchedulePage/TabletSchedulePage";
import DashboardPageTablet from "../../dashboard/DashboardPage/DashboardPageTablet";
import {appCache} from "../../../cache/slices/app/appSlice";
import InactivityWarningDialog from "../InactivityWarningDialog";
import {DateTime} from "luxon";
import syncService from "../../../common/services/syncService";
import clientService from "../../../common/services/clientService";
import DocumentsPage from "../../facesheet/DocumentsPage";
import ServiceDocumentWizardPage from "../../facesheet/ServiceDocumentWizardPage";
import OrganizationInitializer from "../OrganizationInitializer";
import * as rdd from 'react-device-detect';
import { getDevicePlatformType } from '../../../common/utils/systemUtils';
import ServiceDocumentWizardPagePdf from '../../facesheet/ServiceDocumentWizardPage/ServiceDocumentWizardPagePdf';
import {MessageBoardPage} from '../../facesheet/MessageBoard/MessageBoardPage';
import ServiceDocumentWizardPageExternalSignNeeded from '../../facesheet/ServiceDocumentWizardPage/ServiceDocumentWizardPageExternalSignNeeded';
import { VitalsTabPage } from '../../facesheet/Vitals/VitalsTabPage';
import VitalsInfo from '../../facesheet/Vitals/VitalsInfo';
import { useAuth } from "react-oidc-context";
import { isCarelogicMobile, isOnline } from "../../forms/common/Util";

const useStyles = makeStyles((theme) => ({
    authenticatedApp: {
        height: '100%',
        width: '100%',
        backgroundColor: "#f2f2f2",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    appContent: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            flexGrow: 1,
            overflow: 'auto',
            borderRadius: '4px',
            backgroundColor: "inherit",
            // boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.35)',
            // margin: '7px 7px 14px 7px'
    },
    appContentForHandHeld: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flexGrow: 1,
        overflow: 'auto',
        backgroundColor: "#ffffff",
    },
    tabletContent: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'flex-end',
        flexGrow: 1,
        overflow: 'auto'
    }
}));

const DEFAULT_AUTO_LOGOFF_INTERVAL_MINUTES = 30;
const DEFAULT_RESUME_INTERVAL_SECONDS = 120;

export default function AuthenticatedApp({initialdateLastActive, initialShowInactivityWarning}) {
    const [showInactivityWarningDialog, setShowInactivityWarningDialog] = useState(initialShowInactivityWarning ? initialShowInactivityWarning :false);
    const showInactivityWarningDialogRef = useRef(initialShowInactivityWarning ? initialShowInactivityWarning : false);
    const [orgInitialized, setOrgInitialized] = useState(false);
    let devicePlatformType = getDevicePlatformType(rdd);
    const isHandheld = (devicePlatformType === "Mobile" ? true : false);
    const user = useSelector(appCache.getUser);
    const location = useLocation();
    const dispatch = useDispatch();
    const styles = useStyles();
    const store = useStore();
    const [resumeIntervalApp, setResumeIntervalApp] = useState(isNaN(user.securityRule.resumeInterval) ? DEFAULT_RESUME_INTERVAL_SECONDS : user.securityRule.resumeInterval);
    const [viewVisibilityState, setViewVisibilityState] = useState('');
    const idleEvents = ['click', 'scroll','load','keydown'];
    let idleTimer = undefined;
    const [idleTimerView, setIdleTimerView] = useState(undefined);
    const [dateLastActive, setDateLastActive] = useState(initialdateLastActive ? initialdateLastActive : DateTime.now());
    const [dateWarningDialog, setDateWarningDialog] = useState(DateTime.now());
    const autoLogoffIntervalApp = isNaN(user.securityRule.autoLogoffInterval) ? DEFAULT_AUTO_LOGOFF_INTERVAL_MINUTES : user.securityRule.autoLogoffInterval;
    const idleTimeOutInterval = 1000 * 60 * autoLogoffIntervalApp;

    const pageDescriptor = getPageDescriptor(location.pathname.split('/').pop(), true);
    const auth = useAuth();

    const setUserStaff = (allStaff) => {
        if (allStaff && allStaff.length > 0){
            for (let staff of allStaff){
                if (staff.staffId === user.staffId){
                    dispatch(appCache.setUserStaff(staff));
                    return;
                }
            }
        }

        console.log('No staff record found for user.');
    };

    const initializeApp = () => {
        const config = {store};
        syncService.auditService.saveAuditEvent(null, null, user, "EVV Dashboard - Login", "View");
        syncService.staffService.fetch(config)
            .then((allStaff) => {
                setUserStaff(allStaff);
            });
        clientService.fetchClients(dispatch)
            .then(() => {
                syncService.syncApplicationData(config, user);
            });

            document.addEventListener('visibilitychange', handleVisibilitychange);
            addIdleEvents();

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilitychange);
            removeIdleEvents();
            clearTimeout(idleTimer);
        };


    };

    /* eslint-disable react-hooks/exhaustive-deps */

    const handleVisibilitychange = () => {
        setViewVisibilityState(document.visibilityState);
    }

    const handleOrgInitialized = () => {
        setOrgInitialized(true);
    }

    const handleOnIdle = () => {
        const dateNow = DateTime.now();
        const milisecondsDiff = dateNow.diff(dateLastActive).toMillis();
        const secondsIdle = Math.trunc(milisecondsDiff / 1000);
        const secondsIntervalLogoff = autoLogoffIntervalApp * 60;
        const totalIntervalSeconds = secondsIntervalLogoff + resumeIntervalApp;

        if(secondsIdle >= totalIntervalSeconds) {
            handleLogoff();
        } else if(secondsIdle >= secondsIntervalLogoff) {
            const remainingSeconds = totalIntervalSeconds - secondsIdle;
            setResumeIntervalApp(remainingSeconds);
            setShowInactivityWarningDialog(true);
            showInactivityWarningDialogRef.current = true;
            setDateWarningDialog(dateNow);
            removeIdleEvents();
        } else {
            setResumeIntervalApp(isNaN(user.securityRule.resumeInterval) ? DEFAULT_RESUME_INTERVAL_SECONDS : user.securityRule.resumeInterval);
        }
    }

    const idleEventHandler =(eventType)=>{
        if(showInactivityWarningDialogRef.current === false) {
            setDateLastActive(DateTime.now());
            if(idleTimer){
                startIdleTimer();
            }
        }
    };

    const addIdleEvents=()=>{
        idleEvents.forEach(eventName=>{
            document.addEventListener(eventName,idleEventHandler);
        })
        startIdleTimer();
    }
    
    const removeIdleEvents=()=>{
        idleEvents.forEach(eventName=>{
            document.removeEventListener(eventName,idleEventHandler);
        })
    };

    const startIdleTimer=()=>{
        if(idleTimer){
            clearTimeout(idleTimer);
        }
            idleTimer=setTimeout(()=>{
                const dateNowSetTimeout = DateTime.now();
                const diffLastActive = dateNowSetTimeout.diff(dateLastActive).toMillis();
    
                if(diffLastActive < idleTimeOutInterval){
                    startIdleTimer();
                }else{
                    setShowInactivityWarningDialog(true);
                    showInactivityWarningDialogRef.current = true;
                    setDateWarningDialog(dateNowSetTimeout);
                    removeIdleEvents();
                }
                
            },idleTimeOutInterval);

            setIdleTimerView(idleTimer);
    }

    const handleLogoff = () => {
        setShowInactivityWarningDialog(false);
        showInactivityWarningDialogRef.current = false;
        
        if (isCarelogicMobile()) {
            if (isOnline()) {
                appCache.clearCache(dispatch);
                auth.signoutRedirect().catch(error => console.error("Error during sign-out:", error));
            } else {
                appCache.logout(dispatch);
            }     
        } else {
            appCache.logout(dispatch);
        }
    }

    const handleResume = () => {
        setShowInactivityWarningDialog(false);
        showInactivityWarningDialogRef.current = false;
        addIdleEvents();
        setResumeIntervalApp(isNaN(user.securityRule.resumeInterval) ? DEFAULT_RESUME_INTERVAL_SECONDS : user.securityRule.resumeInterval);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(initializeApp, []);

    useEffect(() => {
        if(showInactivityWarningDialog === false) {
            if (viewVisibilityState === 'hidden') {
                const newDateLastActive = DateTime.now();
                setDateLastActive(newDateLastActive);
                removeIdleEvents();
                clearTimeout(idleTimerView);
            } else {
                handleOnIdle();
            } 
            
          }  else {
                const dateNow = DateTime.now();
                const milisecondsDiff = dateNow.diff(dateWarningDialog).toMillis();
                const secondsAfter = Math.trunc(milisecondsDiff / 1000);
                setResumeIntervalApp(resumeIntervalApp => resumeIntervalApp - secondsAfter);
                setDateWarningDialog(dateNow);
            }
      }, [viewVisibilityState]);

    const renderApp = () => {
        if (isHandheld){
            return (
                <>
                    {renderContent()}
                    <NavigationBar />
                </>
            )
        }else{
            return (
                <div className={styles.tabletContent} >
                    {renderContent()}
                    <NavigationBar />
                </div>
            );
        }
    }

    const renderContent = () => {
        return (
            <div className={isHandheld ? styles.appContentForHandHeld : styles.appContent}>
                <Switch>
                    <Route path="/:parent*/activeSessions">
                        <ActiveSessionsPage />
                    </Route>
                    <Route path="/:root*/facesheet/documents/serviceDocument">
                        <ServiceDocumentWizardPage />
                    </Route>
                    <Route path="/:root*/facesheet/documents/serviceDocumentPdf">
                        <ServiceDocumentWizardPagePdf />
                    </Route>
                    <Route path="/:root*/facesheet/documents/serviceDocumentExternalSignNeeded">
                        <ServiceDocumentWizardPageExternalSignNeeded />
                    </Route>
                    <Route path="/:root*/facesheet/documents">
                        <DocumentsPage />
                    </Route>
                    <Route path="/:root*/facesheet/messages">
                        <MessageBoardPage />
                    </Route>
                    <Route path="/:root*/facesheet/vitals/vitalsInfo">
                        <VitalsInfo />
                    </Route>
                    <Route path="/:root*/facesheet/vitals">
                        <VitalsTabPage />
                    </Route>
                    <Route path="/:root*/facesheet/diagnoses/diagnosis">
                        <DiagnosisPage />
                    </Route>
                    <Route path="/:root*/facesheet/diagnoses">
                        <DiagnosesPage />
                    </Route>
                    <Route path="/:root*/facesheet/medications/medication">
                        <MedicationPage />
                    </Route>
                    <Route path="/:root*/facesheet/medications">
                        <MedicationsPage />
                    </Route>
                    <Route path="/:root*/facesheet/allergies/allergy">
                        <AllergyPage />
                    </Route>
                    <Route path="/:root*/facesheet/allergies">
                        <AllergiesPage />
                    </Route>
                    <Route path="/:parent*/facesheet">
                        {isHandheld ? <FacesheetPage /> : <TabletFacesheetPage />}
                    </Route>
                    <Route path="/dashboard">
                        {isHandheld ? <DashboardPage /> : <DashboardPageTablet />}
                    </Route>
                    <Route path="/schedule">
                        {isHandheld ? <SchedulePage /> : <TabletSchedulePage />}
                    </Route>
                    <Route path="/caseload">
                        <CaseloadPage />
                    </Route>
                    <Route path="/settings">
                        <SettingsPage />
                    </Route>
                    <Redirect to="/dashboard" />
                </Switch>
            </div>
        );
    }

    return (
        <div className={styles.authenticatedApp}>
            <AppHeader pageTitle={pageDescriptor.title} handheldSearchEnabled={pageDescriptor.handheldSearchEnabled} isChildPage={pageDescriptor.isChildPage}/>
            {renderApp()}
            {!orgInitialized &&
            <OrganizationInitializer user={user} onInitialized={handleOrgInitialized}/>
            }
            {showInactivityWarningDialog &&
            <InactivityWarningDialog
                resumeInterval={resumeIntervalApp}
                startDateTime={DateTime.now()}
                onResume={handleResume}
                onLogoff={handleLogoff}
            />
            }
        </div>
    );
}
