import {createSlice} from '@reduxjs/toolkit'
import userService from "../../../common/services/userService";
import {sortClients} from "../../../common/utils/miscUtils";
import {ASYNC_OPERATION_STATUS, createAsyncOperation} from "../../asyncHandler";

const loadRecentClientsDescriptor = {
    stateName: 'recentClients',
    reducerNames: {
        startHandler: 'loadRecentClientsStart',
        successHandler: 'loadRecentClientsSuccess',
        errorHandler: 'loadRecentClientsError'
    }
};

const loadRecentClientsHandler = createAsyncOperation(loadRecentClientsDescriptor);

export const LOGOUT_ACTION = "LOGOUT";

export const appSlice = createSlice({
    name: 'app',
    initialState: {
        user: null,
        tasks: [],
        currentOrganizationId: null,
        globalVisit: null,
        incompleteTaskSortId: 'sort-1',
        splashPageAppeared: false,
        showScreenSaverPage: false,
        onlineFlag: true,
        failedLoginAttempts: 0,
        dialogConfig: null,
        handheldFlag: false,
        orientation: 'portrait',
        visible: true,
        addresses: {
            status: ASYNC_OPERATION_STATUS.INITIAL,
            results: [],
            error: null
        },
        clients: {
            status: ASYNC_OPERATION_STATUS.INITIAL,
            results: [],
            error: null
        },
        [loadRecentClientsHandler.stateName]: loadRecentClientsHandler.state,
    },
    reducers: {
        hideSplashPage: (state) => {
            state.splashPageAppeared = true;
        },
        login: (state, action) => {
            state.user = loginOnline(action.payload);
            state.failedLoginAttempts = 0;
        },
        showGlobalAlertDialog: (state, action) => {
            state.globalDialogConfig = action.payload;
        },
        selectOrganization: (state, action) => {
            state.user.currentOrganizationId = action.payload.organizationId;
        },
        selectIncompleteTaskSort: (state, action) => {
            state.incompleteTaskSortId = action.payload;
        },
        setUserStaff: (state, action) => {
            state.user.staff = action.payload;
        },
        toggleOnlineFlag: (state, action) => {
            state.onlineFlag = action.payload;
        },
        toggleOrientationChange: (state, action) => {
            state.orientation = action.payload;
        },
        toggleHandheldFlag: (state, action) => {
            state.handheldFlag = action.payload;
        },
        toggleVisibleFlag: (state, action) => {
            state.visible = action.payload;
        },
        setFailedLoginAttempts: (state, action) => {
            state.failedLoginAttempts = action.payload;
        },
        setTasks: (state, action) => {
            state.tasks = action.payload;
        },
        fetchClientsStart: (state) => {
            state.clients.status = ASYNC_OPERATION_STATUS.START;
        },
        fetchClientsSuccess: (state, action) => {
            state.clients.status = ASYNC_OPERATION_STATUS.SUCCESS;
            state.clients.results = sortClients(action.payload);
        },
        fetchClientsError: (state, action) => {
            state.clients.status = ASYNC_OPERATION_STATUS.ERROR;
            state.clients.error = action.payload;
        },
        fetchAddressesStart: (state) => {
            state.addresses.status = ASYNC_OPERATION_STATUS.START;
        },
        fetchAddressesSuccess: (state, action) => {
            state.addresses.status = ASYNC_OPERATION_STATUS.SUCCESS;
            state.addresses.results = action.payload.results;
        },
        fetchAddressesError: (state, action) => {
            state.addresses.status = ASYNC_OPERATION_STATUS.ERROR;
            state.addresses.error = action.payload;
        },
        ...loadRecentClientsHandler.reducers,
        setGlobalVisit: (state, action) => {
            state.globalVisit = action.payload;
        }
    }
})

export const { setGlobalVisit } = appSlice.actions;

export const appCache = {
    ...appSlice.actions,

    getUser: (state) => state.app.user,
    getGlobalVisit: (state) => state.app.globalVisit,
    getClients: (state) => state.app.clients.results,
    getIncompleteTaskSortId: (state) => state.app.incompleteTaskSortId,
    getAddresses: (state) => state.app.addresses.results,
    getGlobalDialogConfig: (state) => state.app.globalDialogConfig,
    clientsLoading: (state) => state.app.clients.status <= ASYNC_OPERATION_STATUS.START,
    getRecentClients: (state) => state.app.recentClients.results,
    recentClientsLoading: (state) => state.app.recentClients.status <= ASYNC_OPERATION_STATUS.START,
    getTasks: (state) => state.app.tasks,
    getHolidays: (state) => state.app.holidays,
    hasSplashPageAppeared: (state) => state.app.splashPageAppeared,
    isOffline: (state) => !state.app.onlineFlag,
    isHandheld: (state) => state.app.handheldFlag,
    getOrientation: (state) => state.app.orientation,
    visible:(state) => state.app.visible,
    getFailedLoginAttempts: (state) => state.app.failedLoginAttempts,
    clearCache: () => {return {type: LOGOUT_ACTION, payload: {}}},
    logout: (dispatch) => {
        dispatch(appCache.clearCache());
        dispatch(appCache.hideSplashPage());
    }
}

export default appSlice.reducer

const loginOnline = (user) => {
    const userToSave = {...user};

    userToSave.lastLoginDate = new Date();
    userToSave.currentOrganizationId = userToSave.primaryOrganizationId;

    userService.saveUser(userToSave);

    return userToSave;
};
