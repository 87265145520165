import React, { useEffect, useState } from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import EvvButton from "../../../common/components/EvvButton";
import { makeStyles } from "@material-ui/core/styles";
import { Input } from "@material-ui/core";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import EvvCheckBox from "../../../common/components/EvvCheckBox";
import { validateImpactFields } from "./ValidateImpactFields";
import { assignAnsKeyMulti, getSelectedOptions, onChangeUpdates, refreshUi } from "../../../common/utils/impactUtils";
 
const useStyles = makeStyles((theme) => ({
    dialogPaper: {
        width: '400px'
    },
    dialogTitle: {
        maxWidth: '660px',
        maxHeight: '800px',
        height: '50px',
        width: 'auto',
        fontSize: '16px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: "#ffffff",
        paddingLeft: '16px',
        display: 'flex',
        justifyContent: 'center',
        '& .MuiTypography-root': {
            margin: "-7px !important" 
        }
    },
    dialogContent: {
        padding: "19px 19px 25px 19px"
    },
    filterSelect: {
        width: '100%',
        height: '30px',
        borderRadius: '2px',
        background: "transparent",
        borderStyle: "solid",
        borderWidth: '1px',
        borderColor: "#b6b6b6",
        accentColor: "#3b276a"
    },
    saveCancelButtonGroup: {
        padding: "6px 16px 15px 16px",
        justifyContent: 'space-between'
    }, 
    globalButton: {
        padding: '0',
        minWidth: '125px'
    },
    cancelButton: {
        padding: '0',
        minWidth: '75px',
        width: '75px'
    },
    inputLabel: {
        width: "25px",
        height: "25px",
        position: "relative",
        border: "none"
    },
    forHandheld: {
        width: "20px",
        height: "20px",
        position: "relative"
    },
    responsive: {
        width: "100%",
        justifyContent: "space-between",
        flexDirection: "row",
        display: "flex",
        padding: "6px"
    },
    inputBox: { 
        width: '100%', 
        height: "40px", 
        borderRadius: '4px',
        borderStyle: "solid", 
        borderWidth: '1px', 
        borderColor: "#aaa9a9", 
        bottomBorder: "none", 
        padding: "0em 33px 0em 1em",
        position: "relative"
    },
    checkboxArrowIcon: { 
        height: "37px !important", 
        width: "29px !important", 
        position: "absolute", 
        color: "#aaa9a9 !important",
        top: "50%",
        transform: "translateY(-50%)" 
    },
    errorMessage : {
        position: 'absolute',
        bottom: '64px',
        fontSize: '13px',
        left: '25px',
        right: '25px',
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: '16px',
        letterSpacing: 0,
        color: "#dc0707",
        backgroundColor: '#ffffff'
    }
}));
 
const CheckboxForImpact = ({ isHandheld, options, section, question, setRefreshCount, props }) => {
    const styles = useStyles();
    let isReadOnly = props?.isReadOnly;
    
    const [showCheckboxChooser, setShowCheckboxChooser] = useState(false);
    const [selectedCheckbox, setSelectedCheckbox] = useState(getSelectedOptions(question, props));
    const [minChekeableErrorMessage, setMinChekeableErrorMessage] = useState('');

    useEffect(() => {
        if(props?.module?.notAssessed === "Y"){
            setSelectedCheckbox([]);
        }
    }, [props.module.notAssessed]);

    const handleMultiCheckboxClick = () => {
        setShowCheckboxChooser(true);
    };
 
    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
        setSelectedCheckbox(prevState => {
            if (checked) {
                return [...prevState, value];
            } else {
                return prevState.filter(item => item !== value);
            }
        });
    };
 
    const handleCancelClick = () => {
        setShowCheckboxChooser(false);
        setMinChekeableErrorMessage('');
        setSelectedCheckbox(question?.selectedAnswer ? question?.selectedAnswer : []);
    };
 
    const handleSaveClick = () => {
        if(question && minChekeableValidation(question)) {
            setMinChekeableErrorMessage('');
            question.selectedAnswer = selectedCheckbox;
            props?.setNotAssessed("N");
            onChangeUpdates(question, props);
            assignAnsKeyMulti(question, selectedCheckbox);
            setShowCheckboxChooser(false);
            props.module.notAssessed = "N";
            validateImpactFields(props?.module);
            props?.handleSave();
            refreshUi(setRefreshCount);
        }
    };

    const minChekeableValidation = (targetQuestion) =>{
        if(selectedCheckbox?.length > targetQuestion?.minCheckeable) {
            setMinChekeableErrorMessage(`You may choose a maximum of ${targetQuestion?.minCheckeable} responses. Please correct and save.`);
            return false;
        }
        return true;
    }

    const isChecked = (option, props) => {
        if(props?.module?.notAssessed === "N") {
            return selectedCheckbox?.includes(option?.key);
        }
    }

    const getCheckedValues = (props) => {
        let textValues = [];
        if(props?.module?.notAssessed === "N") {
            selectedCheckbox?.forEach((ele) => {
                let selectedItem = options?.find(opt => opt?.key === ele);
                if (selectedItem) {
                    textValues.push(selectedItem?.text);
                }
            });
        }

        let textValuesWithComma = textValues.join(', ');

        return textValuesWithComma;
    }
 
    return (
        <>
        {
            isReadOnly ? <label className = { isHandheld ? "wrapText" : "" }>{ getCheckedValues(props) }</label> : 
            <div onClick = { handleMultiCheckboxClick } className = {styles.inputBox} style = { isReadOnly ? { backgroundColor: '#ededed' } : { backgroundColor: "#ffffff" } }>
            <Input 
                type = "text" 
                disableUnderline = {true} 
                readOnly 
                value = {getCheckedValues(props)}
                placeholder = "Add" 
                style = {{ padding: "3px 0px 0px", width: "100%" }}  
                key = { selectedCheckbox.join(', ') }
                disabled = { isReadOnly }
            />
            <KeyboardArrowRightIcon id = { `rightArrowIcon-${section?.id}-${question?.id}` } className = { styles.checkboxArrowIcon } onClick = { handleMultiCheckboxClick } />
        </div>
        }

        {showCheckboxChooser && <Dialog classes={{ paper: styles.dialogPaper }} open={showCheckboxChooser} onClose={handleCancelClick} disableEscapeKeyDown={true} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle classes={{ root: styles.dialogTitle }} id="checkboxChooserDialog">Select</DialogTitle>
            <DialogContent classes={{ root: styles.dialogContent }} style ={minChekeableErrorMessage ? {padding: "19px 19px 30px 19px"}: {}}>
                {options?.map((option, index) => (
                    <div key = {index} className = {styles.responsive}>
                        <label style={{ fontSize: "20px" }}>{option?.text}</label>
                        <EvvCheckBox
                            className = {isHandheld ? `${styles.filterSelect} ${styles.forHandheld}` : `${styles.filterSelect} ${styles.inputLabel}`} 
                            id = { `impact-checkbox-${ question?.id }-${ option?.id }` }
                            name = { option?.text }
                            value = { option?.key }
                            onChange = { handleCheckboxChange }
                            checked = { isChecked(option, props) }
                        />
                    </div>
                ))}
            </DialogContent>
            <DialogActions classes={{ root: styles.saveCancelButtonGroup }}>
            {minChekeableErrorMessage && <div className={styles.errorMessage} style = {isHandheld ? {right:'15px', overflow: 'hidden'}: {}} >{minChekeableErrorMessage}</div>}
                <div className={styles.globalButton}>
                    <EvvButton id="impactCheckboxCancel" type="secondary" onClick={handleCancelClick}>Cancel</EvvButton>
                </div>
                <div className={styles.globalButton}>
                    <EvvButton id="impactCheckboxSave" type='primary' onClick={handleSaveClick}>Save</EvvButton>
                </div>
            </DialogActions>
        </Dialog>}
        </>
    );
};
 
export default CheckboxForImpact;