import { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import EvvButton from "../../../common/components/EvvButton";
import InputForPassword from "../ResetPasswordDialog/InputForPassword";
import LoginTextField from "../../../common/components/LoginTextField";
import * as rdd from "react-device-detect";
import { getDevicePlatformType } from "../../../common/utils/systemUtils";
import { evvRepository } from "../../../db/evv";
import userService from "../../../common/services/userService";
import { appCache } from "../../../cache/slices/app/appSlice";
import { useHistory } from "react-router-dom";
import activityLogRepository from "../../../db/activityLogRepository";
import useStyles from "../../../common/stylesCarelogicMobile";
import { isOnline } from "../../forms/common/Util";

export default function OfflinePinPageAuth() {

  const USERNAME = "username";
  const PIN = "pin";
  const MOBILE_PLATFORM = "Mobile";
  const ERROR_MSG_MISSING_USERNAME = "Please enter your username";
  const ERROR_MSG_MISSING_PIN = "Please enter your Offline PIN";
  const ERROR_MSG_DIGITS_REQUIRED_PIN = "PIN must be 4 digits";
  const ERROR_MSG_LOGIN_INVALID_CREDENTIALS = "Username and/or Offline PIN is incorrect. Please try again.";
  const ERROR_MSG_LOGIN_FIRST_TIME = "User has not logged in while connected to the internet. Please connect to the internet and try again.";

  const isHandheld = (getDevicePlatformType(rdd) === MOBILE_PLATFORM);

  const containerStyleHandheld = isHandheld ? { height:"643px", width: "375px", background: "none", boxShadow: "none"} : {};
  const headerStyleHandheld = isHandheld ? { paddingTop: "24px" } : {};
  const logoStyleHandheld = isHandheld ? { width: "190px", height: "50px" } : {};

  const [username, setUsername] = useState("");
  const [pin, setPin] = useState("");
  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [invalidCredentials, setInvalidCredentials] = useState(false);
  const [invalidLogin, setInvalidLogin] = useState(false);

  const loggedInUserRef = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const styles = useStyles();
  const newErrors = { ...errors };

  useEffect(() => {
    if (isValid) authenticateOffline();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid]);

  const authenticateOffline = async () => {
    if (isValid) {
      if (isOnline()) {
        history.replace({ pathname: "/" });
      } else {
        loginOffline(username, pin);
      }
    }
  }

  const notValidLogin = () => {
    setInvalidLogin(true);
    setIsValid(false);
  }

  const notValidCredentials = () => {
    setInvalidCredentials(true);
    setIsValid(false);
  }

  const loginOffline = (username, pin) => {
    evvRepository.databaseExists()
      .then(exists => {
        if (!exists) {
          notValidLogin();
          return;
        }

        return evvRepository.initializeDatabase(username, pin)
          .then(() => {
            loadExistingUserFromDbOffline(username);
          })
      })
      .catch((error) => {
        console.log("An error or invalid login occurred: ", error);
        notValidLogin();
      });
  };

  const loadExistingUserFromDbOffline = (username) => {
    userService.loadExistingUserFromDb()
      .then((user) => {
        if (user) {
          if (user.userName === username) {
            loginSuccessful(user);
          } else {
            notValidCredentials();
          }
        } else {
          notValidLogin();
        }
      })
      .catch((error) => {
        console.log("An error or invalid login credentials occurred: ", error);
        notValidCredentials();
      });
  }

  const loginSuccessful = (user) => {
    evvRepository.writingProcess = "false";
    dispatch(appCache.toggleOnlineFlag(false));
    loggedInUserRef.current = user;
    dispatch(appCache.toggleHandheldFlag(isHandheld));
    dispatch(appCache.login(user));
    activityLogRepository.saveActivityLog(user, "Login");
    history.replace({ pathname: "/" });
  }

  const handleSignIn = (event) => {
    event.preventDefault();
    setInvalidCredentials(false);
    setInvalidLogin(false);
    
    const isUsernameValid = validateInput(USERNAME, username);
    const isPinValid = validateInput(PIN, pin);
    if (isUsernameValid && isPinValid) setIsValid(true);
  };

  const handleChange = (event) => {
    setInvalidCredentials(false);
    setInvalidLogin(false);
    const { id, value } = event.target;
    if (id === USERNAME) setUsername(value);
    if (/^\d{0,4}$/.test(value) && id === PIN) setPin(value);
    validateInput(id, value);
  };

  const validateInput = (id, value) => {
    if (id === USERNAME) {
      value.length === 0 ? newErrors.username = ERROR_MSG_MISSING_USERNAME : delete newErrors.username;
    } else if (id === PIN) {
      value.length === 0 ? newErrors.pin = ERROR_MSG_MISSING_PIN :
      value.length < 4 ? newErrors.pin = ERROR_MSG_DIGITS_REQUIRED_PIN : delete newErrors.pin;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  if (isOnline()) {
    history.replace({ pathname: "/" });
    return null;
  }

  return (
    <div className={styles.tabletSetOfflinePin}>
      <div className={styles.offlinePageContainer} style={containerStyleHandheld}>
        <div className={styles.header} style={headerStyleHandheld}>
          <img className={styles.logo} src={`${process.env.PUBLIC_URL}/images/carelogic-mobile-logo.png`} alt="Carelogic Mobile" style={logoStyleHandheld} />
        </div>
        <div className={styles.content}>
          <div className={styles.text}>Offline log in</div>
          <div className={styles.description}>Enter your username and offline PIN to access CareLogic Mobile offline.</div>
          <form className={styles.formContainer} onSubmit={handleSignIn} noValidate autoComplete="off" onChange={handleChange} >
            <div className={styles.spaceBelow}>
              <div className={styles.textWrapperLabel}>Username</div>
              <LoginTextField id="username" value={username} placeholder="Enter Username" onChange={handleChange} />
              {errors.username && <div className={styles.errorMessage}>{errors.username}</div>}
            </div>
            <div className={styles.spaceBelow}>
              <div className={styles.textWrapperLabel}>Offline PIN</div>
              <InputForPassword id="pin" value={pin} placeholder="Enter Offline PIN" type="password" onChange={handleChange} />
              {errors.pin && <div className={styles.errorMessage}>{errors.pin}</div>}
              {invalidLogin && <div className={styles.errorMessage}>{ERROR_MSG_LOGIN_FIRST_TIME}</div>}
              {invalidCredentials && <div className={styles.errorMessage}>{ERROR_MSG_LOGIN_INVALID_CREDENTIALS}</div>}
            </div>
            {isHandheld ? (
              <div className={styles.spaceBelow} styleOverride={{ paddingTop: '90px' }}>
                <EvvButton id="signIn" type="primary" onClick={handleSignIn} styleOverride={{ marginTop: '15px' }}>Sign in</EvvButton>
              </div>
            ) : (
              <EvvButton id="signIn" type="primary" onClick={handleSignIn} styleOverride={{ marginTop: '15px' }}>Sign in</EvvButton>
            )}
          </form>
        </div>
      </div>
    </div>
  );
  
}
