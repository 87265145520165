import {makeStyles} from "@material-ui/core/styles";
import {useSelector, useStore} from "react-redux";
import {useEffect} from "react";
import {scheduleCache} from "../../../cache/slices/schedule/scheduleCache";
import TabletScheduleTitleBar from "../ScheduleTitleBar/TabletScheduleTitleBar";
import syncService from "../../../common/services/syncService";
import {appCache} from "../../../cache/slices/app/appSlice";
import {evvRepository} from "../../../db/evv";
import { CircularProgress } from "@material-ui/core";
import { PRIMARY_COLOR } from "../../../common/constants";
import { ASYNC_OPERATION_STATUS } from "../../../cache/asyncHandler";

const useStyles = makeStyles((theme) => ({
    schedulePage: {
        ...theme.shadowedContainer,
        display: 'flex',
        flexDirection: 'column'
    },
    loadingSpinnerContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    loadingSpinner: {
        color: PRIMARY_COLOR
    }
}));


export default function TabletSchedulePage() {
    const appointments = useSelector(syncService.appointmentService.getResults());
    const appointmentsLoading = useSelector(syncService.appointmentService.isLoading());
    const selectedDate = useSelector(scheduleCache.getSelectedDate);
    const styles = useStyles();
    const clients = useSelector(appCache.getClients);
    const store = useStore();
    const user = useSelector(appCache.getUser);
    const activityServiceStatus = useSelector(syncService.activityService.getStatus());

    useEffect(() => {
        if (!appointmentsLoading && evvRepository.isWritenProcess === 'false') {
            syncService.syncAppointmentData({store});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(appointments && appointments.length > 0) {
            if(appointments.length > 1) {
                syncService.auditService.saveAuditEvent(clients, null, user, "EVV Schedule", "View");
            } else {
                syncService.auditService.saveAuditEvent(null, clients[0], user, "EVV Schedule", "View");
            }
        }
    }, [appointments, clients, user]);

    const renderSchedulePage = () => {
        if (activityServiceStatus === ASYNC_OPERATION_STATUS.SUCCESS) {
            return (
                <div className={styles.schedulePage}>
                    <TabletScheduleTitleBar selectedDate={selectedDate} />
                </div>
            );
        } else {
            return (
                <div className={styles.schedulePage}>
                    <div className={styles.loadingSpinnerContainer}>
                        <CircularProgress size={50} className={styles.loadingSpinner} />
                    </div>
                </div>
            );
        }
    }

    return (
        <>{ renderSchedulePage() }</>
    );
}
