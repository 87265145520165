import FormField from "./FormField";
import { Grid } from "@material-ui/core";
import { renderLookUpField } from "../common/FormFieldsUtils";
import { getXSAndSMValueForField } from "../common/Util";

class LookUpField extends FormField {
    constructor(props) {
        super(props);
        props.fieldDescriptor = this.fieldDescriptor;

        let columnObj = this.fieldDescriptor.attributes.find( (attribute) => attribute.attributeId === this.fieldDescriptor.id +"_column" );
        let column = columnObj?.value;

        this.state = {
            column: column,
            data: props
        };
    }

    renderField(props, state){
        let breakPoints = getXSAndSMValueForField(props.isHandheld, props.orientation, 12, 6);
        props.fieldDescriptor = this.fieldDescriptor;

        return (
            <div>
                {this.fieldDescriptor?.businessRuleApplied !== "Hide" &&
                <>
                <Grid  item = { true } xs={breakPoints.xsValue} sm={breakPoints.smValue}>
                    <div className="lookUpField">
                        <>{ renderLookUpField(this.state?.column, props?.selectedClient, state?.descriptorData, 'uiValue') }</>
                    </div>
                </Grid>
                </>
                }
            </div>
        )
    }
}

export default LookUpField;
