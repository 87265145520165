import { Dialog, DialogContent, DialogTitle, InputAdornment, makeStyles, TextField } from '@material-ui/core';
import { React, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import StaffList from './StaffList';
import { CancelRounded } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
    dialogTitle: {
        justifyContent: "space-between",
        display: "flex",
        fontWeight: 'bold',
        fontSize: '18px',
        color: '#ffffff'
    },
    searchField: {
        paddingLeft: '15px', 
        paddingRight: '15px',
        border: 'none',
        marginTop: '15px'
    },
    searchTextField: {
        borderRadius: '5px',
        backgroundColor: "#ffffff",
        borderStyle: "solid",
        borderWidth: '1px',
        borderColor: "#acacac",
        width: '100%'
    },
    searchAdornment: {
        marginRight: "0px",
        padding: '4px 3px 4px 5px',
        width: '42px',
        height: '38px'
    },
    searchIcon: {
        width: '100%',
        height: '100%',
        opacity: 0.43
    },
    dialogPaper: {
        minHeight: '80vh',
        maxHeight: '80vh',
        minWidth: '50vh',
        maxWidth: '50vh',
    },
}));

function AssignStaff(props) {
    let { showStaffChooser, setShowStaffChooser } = props;

    const styles = useStyles();

    const [staffSearchInput, setStaffSearchInput] = useState("");

    let staffSearchHandler = (e) => {
        var lowerCaseInput = e?.target?.value.toLowerCase();
        setStaffSearchInput(lowerCaseInput);
    };

    const handleCancelClick = () => {
        setShowStaffChooser(false);
    };

    return (
        <Dialog 
            classes={{ paper: styles.dialogPaper }}
            open = {showStaffChooser} onClose = { handleCancelClick } disableEscapeKeyDown = { true } aria-labelledby = "assign-staff-member" aria-describedby = "assign-staff-member">
            <DialogTitle>
                <div className = {styles.dialogTitle}>
                    Assign Staff Member<CancelRounded onClick = { handleCancelClick }/>
                </div>
            </DialogTitle>
            <div className = {styles.searchField}>
                <TextField
                    label = ""
                    id = "selectedStaff"
                    classes = {{root: styles.searchTextField}}
                    autoComplete = "off"
                    onChange = { staffSearchHandler }
                    placeholder = "Search Client Name/ID"
                    InputProps = {{
                        startAdornment: (
                            <InputAdornment position = 'start' classes = {{ positionStart: styles.searchAdornment }}>
                                <SearchIcon className = {styles.searchIcon}  />
                            </InputAdornment>
                        ),
                        disableUnderline: true
                    }}
                />
            </div>
            <DialogContent style = {{padding: '16px 24px'}}>
                <StaffList 
                    searchInput = { staffSearchInput }
                    { ...props }
                />
            </DialogContent>
        </Dialog>
    )
}

export default AssignStaff