import {makeStyles} from "@material-ui/core/styles";
import {useHistory, useLocation} from "react-router-dom";
import {useDispatch} from "react-redux";
import {clientCache} from "../../../cache/slices/client/clientSlice";
import { PRIMARY_COLOR } from "../../../common/constants";

const useStyles = makeStyles(() => ({
    allergyCard: {
        width: '100%',
        height: '68px',
        minHeight: '68px',
        backgroundColor: "#ffffff",
        borderBottom: '1px solid #d0d0d0',
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingLeft: '16px',
        paddingRight: '16px',
    },
    allergy: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'space-between'
    },
    noKnownAllergy: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    noKnownAllergyLabel: {
        fontSize: '16px',
        fontWeight: "bold",
        fontStyle: "normal",
        lineHeight: '20px',
        letterSpacing: 0,
        color: "#4f4f4f"
    },
    allergyColumn: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
     },
    allergyName: {
        fontSize: '16px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: PRIMARY_COLOR,
        paddingBottom: '8px'
    },
    allergyTypeContainer: {
    },
    allergyTypeLabel: {
        fontSize: '16px',
        fontWeight: "bold",
        fontStyle: "normal",
        lineHeight: '20px',
        letterSpacing: 0,
        color: "#4f4f4f"
    },
    allergyTypeValue: {
        fontSize: '16px',
        fontWeight: "normal",
        fontStyle: "normal",
        letterSpacing: 0,
        color: "#4f4f4f",
        paddingLeft: '6px'
    },
    imageColumn: {
        paddingLeft: '14px',
    },
    imageContainer: {
        width: '40px',
        height: '40px'
    },
    image: {
        height: '100%',
        width: '100%'
    }
}));

export default function AllergyCard({allergy}) {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const styles = useStyles();

    const handleAllergyClick = () => {
        if (allergy.nka === 'Y' || allergy.nkda === 'Y'){
            return;
        }

        dispatch(clientCache.selectAllergy(allergy));
        history.push(`${location.pathname}/allergy`);
    }

    const renderContent = () => {
        if (allergy.nkda === 'Y') {
            return (
                <div className={styles.noKnownAllergy}>
                   <div className={styles.noKnownAllergyLabel}>No Known Drug Allergies</div>
                </div>
            );

        } else if (allergy.nka === 'Y') {
            return (
                <div className={styles.noKnownAllergy}>
                    <div className={styles.noKnownAllergyLabel}>No Known Allergies</div>
                </div>
            );
        } else {
            return (
                <div className={styles.allergy}>
                    <div className={styles.allergyColumn}>
                        <div className={styles.allergyName}>
                            {allergy.allergyName}
                        </div>
                        <div className={styles.allergyTypeContainer}>
                            <span className={styles.allergyTypeLabel}>Type:</span>
                            <span className={styles.allergyTypeValue}>{allergy.allergyType}</span>
                        </div>
                    </div>
                    <div className={styles.imageColumn}>
                        <div className={styles.imageContainer}>
                            <img className={styles.image} src='../../images/iconIcoChevronRight.svg' alt='allergy details'/>
                        </div>
                    </div>
                </div>
            );
        }
    }

    return (
        <div data-testid='AllergyCard' className={styles.allergyCard} onClick={handleAllergyClick}>
            {renderContent()}
        </div>
    )
}
