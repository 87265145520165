import LinkButton from "../../../common/components/LinkButton";
import {makeStyles} from '@material-ui/core/styles';
import {useLocation} from "react-router-dom";
import {PageDescriptors} from "../../../common/utils/pageUtils";
import { getDevicePlatformType } from "../../../common/utils/systemUtils";
import * as rdd from 'react-device-detect';
import { PRIMARY_COLOR } from "../../../common/constants";

const useStyles = makeStyles((theme) => ({
    navigationBarForhandHeld: {
        height: '79px',
        width: '100%',
        backgroundColor: '#f6f6f6',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: '#979797',
        paddingTop: '7px',
        paddingBottom: '28px',
        display: 'flex',
        justifyContent: 'space-around'
    },
    navigationBar: {
            width: '125px',
            height: '100%',
            backgroundColor: '#f2f2f2',
            paddingLeft: '16px',
            paddingRight: '16px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start'
    },
    buttonContainerForHandheld: {
        '& a': {
            display: 'flex',
            flexDirection: 'column'
        },
        '& .MuiSvgIcon-root': {
            width: '30px',
            height: '30px'
        },
    },
    buttonContainer: {
        paddingBottom: '35px',
        '& a': {
            display: 'flex',
            flexDirection: 'column'
        },
        '& .MuiSvgIcon-root': {
            width: '48px',
            height: '48px'
        }
    },
    navigationIconForHandheld: {
        width: '30px',
        height: '30px'
    },
    navigationIcon: {
        width: '48px',
        height: '48px'
    }
}));

const SELECTED_STYLE = { color: PRIMARY_COLOR};
const EMPTY_STYLE = {};

export default function NavigationBar() {
    let location = useLocation();
    const styles = useStyles();
    let devicePlatformType = getDevicePlatformType(rdd);
    const isHandheld = (devicePlatformType === "Mobile" ? true : false);

    const isSelected = (rootPathName) => {
        return location.pathname.startsWith(rootPathName)
    }

    const renderLinkButton = (pageDescriptor) => {
        const selected = isSelected(pageDescriptor.href);
        const style = selected ? SELECTED_STYLE : EMPTY_STYLE;
        const navigationIconClass = isHandheld ? styles.navigationIconForHandheld : styles.navigationIcon;
        const classes = {root: navigationIconClass}

        return (
            <div className={isHandheld ? styles.buttonContainerForHandheld : styles.buttonContainer}>
                <LinkButton href={pageDescriptor.href} label={pageDescriptor.title} selected={selected}>
                    {pageDescriptor.renderIcon(style, classes)}
                </LinkButton>
            </div>
        );
    }

    return (
        <div className={isHandheld ? styles.navigationBarForhandHeld : styles.navigationBar}>
            {renderLinkButton(PageDescriptors.dashboard)}
            {renderLinkButton(PageDescriptors.schedule)}
            {renderLinkButton(PageDescriptors.caseload)}
            {renderLinkButton(PageDescriptors.settings)}
        </div>
    );
}