import { orderBy } from "lodash";
import {makeStyles} from "@material-ui/core/styles";
import {Menu,MenuItem} from "@material-ui/core";
import Section from "../Section";
import {useEffect, useState} from "react";
import TaskCard from "../../../features/dashboard/TaskCard";
import { evvRepository } from "../../../db/evv";
import { useDispatch, useSelector } from "react-redux";
import syncService from "../../services/syncService";
import { convertServiceDocuments,  getTasksAndIncompletDocuments, getTasksForExternalSignNeeded } from "../../utils/documentUtils";
import { clientCache } from "../../../cache/slices/client/clientSlice";
import { appCache } from "../../../cache/slices/app/appSlice";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as msgConst from '../../../features/forms/common/Messages';
import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import Check from '@mui/icons-material/Check';
import SortIcon from '@mui/icons-material/Sort';
import { PRIMARY_COLOR } from "../../constants"
import { documentCache } from "../../../cache/slices/document/documentCache";
import { getTaskDataForExternalSign } from "../../../features/forms/common/IncompleteTasksUtils";
import { ASYNC_OPERATION_STATUS } from "../../../cache/asyncHandler";

const useStyles = makeStyles((theme) => ({
    tasks: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: "#ffffff"
    },
    taskCards: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            borderTop: '2px solid #979797',
            paddingTop: '14px',
            paddingLeft: '16px',
            paddingRight: '16px'
        },
        [theme.breakpoints.up(601)]: {
            paddingRight: '10px'
        }
    },
    loadingSpinnerStyle: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            borderTop: '2px solid #979797',
        },
        [theme.breakpoints.up(601)]: {
            paddingRight: '10px'
        }
    },
    loadingSpinnerContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    loadingSpinner: {
        color: PRIMARY_COLOR
    },
    noIncompleteTaskMessage: {
        width: '100%',
        height: '100%',
        display: 'flex',
        fontSize: '18px',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'bold'
    },
    menuPopup:{
        translate: "-5%",
        marginTop: "-5%",
        [theme.breakpoints.down('sm')]: {
           translate: "-10%",
        }
    },
    mainDiv:{
        display: "flex",
        flexDirection: 'row',
        position: "sticky", 
        top: "0" ,
        backgroundColor: "#ffffff"
    }, 
    menuItem:{
        paddingLeft: "35px",
        width: "200px"
    },
    listItemIcon:{
        right: "69%",
        zIndex: "1",
        position: "absolute"
    },
    sortButton:{
        [theme.breakpoints.down('sm')]: {
            borderTop: '2px solid #979797 !important',
            backgroundColor: "#f6f6f6 !important",
        },
        [theme.breakpoints.up(601)]: {
            backgroundColor: "#ffffff !important",
        }
    
    } 
}));

function sortTasks(tasks, sortType){
    if(sortType === 'sort-1') {
        return orderBy(tasks,['startDateInstance'],['desc'])
    }
    if(sortType === 'sort-2') {
        return orderBy(tasks,['startDateInstance'],['asc'])
    }
    if(sortType === 'sort-3') {
        return orderBy(tasks,[task => task?.client?.lastName?.toLowerCase(), task => task?.client?.firstName?.toLowerCase(),'startDateInstance'],['asc', 'asc','desc'])
    } 
    if(sortType === 'sort-4') {
        return orderBy(tasks,['status','startDateInstance'],['asc','desc'])
    }
}

export default function Tasks({tasks, standalone, isFacesheet}) {
    const dispatch = useDispatch();
    const [tasksAndDocs, setTasksAndDocs] = useState([]);
    const styles = useStyles();
    const [visitCollection, setVisitCollection] = useState(null);
    const documents = useSelector(syncService.documentService.getResults());
    const [clientDocuments, setClientDocuments] = useState([]);
    const [apptsForUnsignedDocs, setApptsForUnsignedDocs] = useState([]);
    const clients = useSelector(appCache.getClients);
    const client = useSelector(clientCache.getSelectedClient);

    const organizationsLoading = useSelector(syncService.organizationService.isLoading());
    const locationOrganizationsLoading = useSelector(syncService.serviceLocationOrgService.isLoading());
    const documentsLoading = useSelector(syncService.documentService.isLoading());
    const clientsLoading = useSelector(appCache.clientsLoading);
    const visitLoading = useSelector(syncService.visitService.isLoading());
    const isHandheld = useSelector(appCache.isHandheld);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const incompleteTaskSortId = useSelector(appCache.getIncompleteTaskSortId);
    const [selectedId, setSelectedId] = useState(isFacesheet ? 'sort-1' : incompleteTaskSortId);
    const user = useSelector(appCache.getUser);
    const evvDocuments = useSelector(documentCache.getEvvDocuments);
    const [evvDocWithSign, setEvvDocWithSign] = useState([]);
    const activityServiceStatus = useSelector(syncService.activityService.getStatus());
    const tasksLoadingStatus = useSelector(documentCache.getTasksLoadingStatus);

    const sortItems = [
        {id: 'sort-1', label:'Sort by Date Newest'},
        {id: 'sort-2', label:'Sort by Date Oldest'},
        {id: 'sort-3', label:'Sort by Name'},
        {id: 'sort-4', label:'Sort by Type'}
    ];

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (incompleteTaskSortId) => {
        setAnchorEl(null);
        setSelectedId(incompleteTaskSortId)
        if(!isFacesheet) {
            syncService.changeIncompleteTaskSortId({dispatch}, incompleteTaskSortId);
        }
    };

    function generateSortItem(item, selectedId, handleClose){
        return (
            <MenuItem className={styles.menuItem} onClick={() => handleClose(item.id)}>
                {selectedId === item.id && (
                    <ListItemIcon className={styles.listItemIcon}>
                        <Check />
                    </ListItemIcon>
                )}
                {item.label}
            </MenuItem>
        );
    }

    const getVisitCollection = async () =>{
        return evvRepository.evvDb.visit.toArray();
    }

    const getVisits = async() => {
        await getVisitCollection().then((result) => {
            setVisitCollection(result);
        }).catch((error) => {
            console.log('Error: ', error);
        });
    }

    useEffect(() => {
        getDocsCollection();
        getAppointmentCollection();
    }, [tasks, documents]);


    const getDocsCollection = async () => {
        let UnsignedIncompleteDocs = [];
        UnsignedIncompleteDocs = await syncService.documentService.getUnsignedIncompleteDocs();
        setClientDocuments(convertServiceDocuments(UnsignedIncompleteDocs));
    }

    const getAppointmentCollection = async () => {
        let unSignedDocAppointments = [];
        unSignedDocAppointments = await syncService.appointmentService.getAppointmentsForUnSignedDocs();
        setApptsForUnsignedDocs(unSignedDocAppointments);
    }

    useEffect(() => {
        getVisits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(!isFacesheet) {
            dispatch(clientCache.selectClient(''));
        }
        // eslint-disable-next-line
    }, [isFacesheet]);

    useEffect(() => {
        if ((tasks && tasks.length > 0) || documents.length > 0) {
            setTasksAndDocs(getSortedTasksAndDocs(tasks));
        }
        // eslint-disable-next-line
    }, [tasks, clientDocuments, apptsForUnsignedDocs, selectedId]);

    useEffect(() => {
        if (evvDocuments && evvDocuments.length > 0) {
            setEvvDocWithSign(getTaskDataForExternalSign(evvDocuments));
        }
    }, [evvDocuments]);

    const getSortedTasksAndDocs = (tasks) => {
        let incompleteTasks = tasks ? tasks : [];
        let incompleteTasksAndDocs = [...incompleteTasks];
        if(clientDocuments.length > 0) {
            incompleteTasksAndDocs = getTasksAndIncompletDocuments(clients, client, clientDocuments, tasks, incompleteTasksAndDocs, apptsForUnsignedDocs);
        }
        if(evvDocWithSign && evvDocWithSign.length > 0) {
            incompleteTasksAndDocs = getTasksForExternalSignNeeded(clients, incompleteTasksAndDocs, evvDocWithSign, client);
        }
        return sortTasks(incompleteTasksAndDocs, selectedId);
    }

    const renderIncompleteTask = () => {
        if (!user.staff || clientsLoading || locationOrganizationsLoading || documentsLoading || organizationsLoading || visitLoading || activityServiceStatus !== ASYNC_OPERATION_STATUS.SUCCESS || tasksLoadingStatus) {
            return (
                <div className={`${styles.loadingSpinnerStyle} ${styles.loadingSpinnerContainer}`}>
                    <CircularProgress size={50} className={styles.loadingSpinner} />
                </div>
            );
        }
        if ((tasks?.length === 0 && (tasksAndDocs && tasksAndDocs.length === 0))) {
            return (
                <div className={styles.noIncompleteTaskMessage}>
                    {msgConst.noIncompleteTaskMessage}
                </div>
            );
        }
        if (tasksAndDocs && tasksAndDocs.length > 0) {
            return (
                <div className={styles.taskCards}>
                    {tasksAndDocs && tasksAndDocs.map((task, index) =>
                        <TaskCard key={index} task={task} visitCollection={visitCollection} />
                    )}
                </div>
            );
        }
    }
    return (
        <div className={styles.tasks}>
            <div className={styles.mainDiv} style={isHandheld ? {backgroundColor:"#f6f6f6"}:{backgroundColor:"#ffffff"}}>
                <Section title='Incomplete Tasks' standalone={standalone} />
                    <Button
                        id="basic-button"
                        aria-controls={open ? 'sort-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        className={styles.sortButton}>
                        <SortIcon style={{ height:"25px",width:"32px"}} /> 
                    </Button>
                    <Menu
                        id="sort-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={()=>{ handleClose(selectedId)}}
                        className={styles.menuPopup}>
                    
                        {sortItems.map((item) => {
                            if(item.id === 'sort-3' && isFacesheet){
                                return null;
                            }else{
                                return generateSortItem(item, selectedId, handleClose);
                            }
                        })}
                    </Menu>                   
            </div>
            {renderIncompleteTask()} 
        </div>
    );
}


