import {makeStyles} from "@material-ui/core/styles";
import ClientStrip from "../ClientStrip";
import Appointments from "../../../common/components/Appointments";
import Tasks from "../../../common/components/Tasks";
import {useDispatch, useSelector, useStore} from "react-redux";
import {appCache} from "../../../cache/slices/app/appSlice";
import {useEffect, useState} from "react";
import clientService from "../../../common/services/clientService";
import {DateTime} from "luxon";
import CircularProgress from "@material-ui/core/CircularProgress";
import syncService from "../../../common/services/syncService";
import { prepareTasks } from "../../../common/data/tasks";
import { getTaskData } from "../../forms/common/IncompleteTasksUtils";
import { getSystemInformation } from "../../../common/utils/systemUtils";
import {evvRepository} from "../../../db/evv";
import { documentCache } from "../../../cache/slices/document/documentCache";
import { PRIMARY_COLOR } from "../../../common/constants"
import { ASYNC_OPERATION_STATUS } from '../../../cache/asyncHandler';
import { getMobileAppointments } from "../../../common/utils/appointmentUtils";

const useStyles = makeStyles(() => ({
    dashboardPage: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'unset'
    },
    loadingSpinnerContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    loadingSpinner: {
        color: PRIMARY_COLOR
    },
    recentClients: {
        width: '100%',
        height: '135px',
        minHeight: '135px',
        maxHeight: '135px',
        paddingLeft: '16px',
        paddingRight: '16px'
    },
    dashboardAppointments: {
        width: '100%',
        height: '50%',
        overflow: 'auto'
    },
    dashboardTasks: {
        width: '100%',
        height: '50%',
        overflow: 'auto'
    }
}));

export default function DashboardPage() {
    const recentClientsLoading = useSelector(appCache.recentClientsLoading);
    const recentClients = useSelector(appCache.getRecentClients);
    const clientsLoading = useSelector(appCache.clientsLoading);
    const clients = useSelector(appCache.getClients);
    const appointments = useSelector(syncService.appointmentService.getResults());
    const appointmentsLoading = useSelector(syncService.appointmentService.isLoading());
    const tasks = useSelector(appCache.getTasks);
    const store = useStore();
    const user = useSelector(appCache.getUser);
    const dispatch = useDispatch();
    const styles = useStyles();
    const [firstRender, setFirstRender] = useState(true);
    const documentCrosswalks = useSelector(syncService.documentCrosswalkService.getResults());
    const serviceDocuments = useSelector(syncService.serviceDocumentService.getResults());
    const [taskDataArr, setTaskDataArr] = useState([]);
    const currentDocumentId = useSelector(documentCache.getCurrentDocumentId);
    const organizationServiceStatus = useSelector(syncService.organizationService.getStatus());
    const activityServiceStatus = useSelector(syncService.activityService.getStatus());
    const activities = useSelector(syncService.activityService.getResults());

    useEffect(() => {
        const systemInformation = getSystemInformation();
        if (!appointmentsLoading && organizationServiceStatus === ASYNC_OPERATION_STATUS.SUCCESS && systemInformation.platformType === 'Mobile' && evvRepository.isWritenProcess === 'false') {
            syncService.syncAppointmentData({store});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationServiceStatus]);
    

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if ( organizationServiceStatus === ASYNC_OPERATION_STATUS.SUCCESS && activityServiceStatus === ASYNC_OPERATION_STATUS.SUCCESS && (appointments.length > 0 || currentDocumentId) ) {
            getTaskData(appointments, documentCrosswalks, serviceDocuments, setTaskDataArr, user.staff, activities, dispatch);
        }
    }, [appointments, currentDocumentId, organizationServiceStatus, activityServiceStatus, activities]);

    useEffect(() => {
        if(firstRender && clients && clients.length > 0) {
            if(clients.length > 1) {
                syncService.auditService.saveAuditEvent(clients, null, user, "EVV Dashboard", "View");
            } else {
                syncService.auditService.saveAuditEvent(null, clients[0] , user, "EVV Dashboard", "View");
            }
            setFirstRender(false);
        }
    },[firstRender, clients, user]);

    useEffect(() => {
        clientService.loadRecentClients(dispatch, clients);
    }, [clients]);

    useEffect(() => {
        loadIncompleteTasks();
    }, [taskDataArr]);

    const loadIncompleteTasks = () => {
        const userToTasksMap = {};
        userToTasksMap[user?.userName] = prepareTasks(taskDataArr);
        dispatch(appCache.setTasks(userToTasksMap[user?.userName]));
    }

    const renderRecentClients = () => {
        if (recentClientsLoading || clientsLoading) {
            return (
                <div className={`${styles.recentClients} ${styles.loadingSpinnerContainer}`}>
                    <CircularProgress size={50} className={styles.loadingSpinner} />
                </div>
            );
        } else {
            return (
                <div className={styles.recentClients}>
                    <ClientStrip title='Recent Clients' clients={recentClients} />
                </div>
            );
        }
    }

    return (
        <div className={styles.dashboardPage}>
            {renderRecentClients()}
            <div className={styles.dashboardAppointments} >
                <Appointments appointments={getMobileAppointments(appointments, activities)} appointmentsLoading={appointmentsLoading} startDate={DateTime.now()} maxAppointmentsToDisplay={5} ignorePastAppointments={true} />
            </div>
            <div className={styles.dashboardTasks} >
                <Tasks tasks={tasks} isFacesheet={false}/>
            </div>
        </div>
    )
}
