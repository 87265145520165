import { DateTime } from "luxon";

export const getFormattedDateTime = (date) => {
    if (date) {
      return DateTime.fromISO(date).toFormat('MM/dd/yyyy hh:mm a');
    } else {
      return "Not Set";
    }
};

export const getElementWithUnit = (element, unit) => {
    if (element) {
      return `${element}${unit}`;
    } else {
      return "-";
    }
  }

export const getBp = (row) => {
    if (row?.bPSystolic && row?.bPDiastolic) {
        return `${row?.bPSystolic}/${row?.bPDiastolic}`;
    } else {
      return "-";
    }
}

export const getFormattedValue = (element) => {
  if (element) {
    return element;
  } else {
    return "-";
  }
}