import api from "./api";
import {DateTime} from "luxon";
import LookupService from "./lookupService";
import {dateToStringWithFormat, VISIT_DATE_TIME_FORMAT} from "../utils/formatUtils";
import {OFFLINE,PENDING} from "../constants";

class AuditService extends LookupService {

    saveAuditEvent = async (clients, client, user, name, type) => {        
        let idClient = null;
        if(client) {
            idClient = client.clientId;
        } else {
            if(clients && clients.length === 1) {
                idClient = clients[0].clientId;
            }
        }
        
        const config = {doNotClear: true};
        const eventDateTime = dateToStringWithFormat(DateTime.now(), VISIT_DATE_TIME_FORMAT);
        const auditToSave = this.createAuditObject(eventDateTime, type, name, user.currentOrganizationId, idClient,  user.staffId);
        auditToSave.auditLogId = (-1 * Date.now());
        auditToSave.syncState = PENDING;
        const auditList = [auditToSave];
        
        return this.save(auditList, config)
                .then(() => {
                    return this.writeAuditToServer(auditList, config);
                })
    }

    createAuditObject = (eventDateTime, actionType, pageName, organizationId, clientId, staffId) => {
        const auditObj = {
            eventDateTime: eventDateTime,
            actionType: actionType,
            pageName: pageName,
            organizationId: organizationId,
            clientId: clientId,
            staffId: staffId,
        }
        return auditObj;
    }

    writeToServer = (config) => {
        return this.repository.loadAllByProperty('syncState', OFFLINE)
            .then(listAudit => {
                if (listAudit && listAudit.length > 0){
                    return this.writeAuditToServer(listAudit,config);
                }
            })
            .catch(error => {
                console.log("Error thrown while writing to audit server: ");
                console.log(error);
            })
    }

    writeAuditToServer = async (auditList, config) => {           
        const auditToWriteList = auditList.map(audit => this.createAuditObject(audit.eventDateTime, audit.actionType, audit.pageName, audit.organizationId, audit.clientId, audit.staffId));
        const writeAuditRequest = {
            resources: auditToWriteList
        };

        console.log("Writing audit to server: ");
        return api.post(this.writeUrl, { json: writeAuditRequest })
            .then(async writeAuditResponse => {
                console.log("Succes writing audit to server: ");
                if (writeAuditResponse.resources && writeAuditResponse.resources.length > 0) {
                    writeAuditResponse.resources.forEach((auditFromServer) => {
                        const index = writeAuditResponse.resources.indexOf(auditFromServer);
                        const originalAudit = auditList[index];
                        this.deleteByPrimaryKey(originalAudit.auditLogId);
                    })
                }
                return Promise.resolve(auditToWriteList);
            })
            .catch(error => {
                const offlineAudits = auditList.map(audit => ({  ...audit, 'syncState' : OFFLINE}) );
                this.save(offlineAudits, config);
                const connectionLost = api.isConnectionLost(error);

                if (connectionLost){
                    return Promise.resolve(writeAuditRequest);
                } else {
                    console.log("Error thrown while writing audit log: ");
                    console.log(error);
                    return Promise.reject(error);
                }
            });
    }
}

export default AuditService;