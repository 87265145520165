import LookupService from "./lookupService";
import {DateTime} from "luxon";
import {appCache} from "../../cache/slices/app/appSlice";
import {
    APPOINTMENT_START_STOP_DATE_TIME_FORMAT,
    dateToString,
    dateToStringWithFormat,
    formatClientNameWithoutId
} from "../utils/formatUtils";
import api from "./api";
import {getClientProgramFromAppointment, isAddressRequiredForOrganization} from "../utils/appointmentUtils";
import {combineDateAndTime} from "../utils/dateTimeUtils";
import {getDispatchFromConfig} from "../utils/miscUtils";
import syncService from "./syncService";
import { findVisitAndUpdate, findVisitAndRemove } from "../utils/visitUtils";
import { evvRepository } from "../../db/evv";

class AppointmentService extends LookupService{
    getFetchParameters = (parameters) => {
        return parameters ? {"patientId": parameters.clientId} : {};
    };

    saveAppointment = (appointment, config) => {
        console.log('AppointmentService.saveAppointmentAndVisit - config:');
        console.log(config);
        if (appointment) {
            const ignoreVisitSave = (config.ignoreVisitSave === true);
            if (!appointment.appointmentId) {
                appointment.appointmentId = (-1 * Date.now());
                appointment.evvVisitId = appointment.appointmentId;
            }

            appointment.syncState = "dirty";

            const appointments = [appointment];

            return this.save(appointments, config)
                .then(() => {
                    return this.writeAppointmentsToServer(this.afterLoad(appointments, config), config)
                        .then(savedAppointments => {
                            let savedAppointment = null;

                            if (savedAppointments && savedAppointments.length > 0){
                                const dispatch = getDispatchFromConfig(config);
                                savedAppointment = savedAppointments[0];

                                dispatch(this.operationReplace(savedAppointment));

                                if (savedAppointment.syncState !== 'error' && !ignoreVisitSave) {
                                    console.log('save visit for appointment');
                                    console.log(savedAppointment);
                                    syncService.visitService.saveVisitForAppointment(savedAppointment, config);
                                }
                            }

                            return savedAppointment;
                        })
                })
        }
    }

    removeAppointment = (appointment, config) => {
        if (appointment) {
            this.deleteByPrimaryKey(appointment.appointmentId)
                .then(() => {
                    return this.load(config);
                })
        }
    }

    beforeSave = (appointments, config) => {
        return appointments.map(appointment => {
            if (!appointment.syncState){
                appointment.syncState = "clean";
            }

            return appointment;
        });
    }

    afterLoad = (appointments, config) => {
        if (appointments){
            const state = config.store.getState();
            const clients = appCache.getClients(state);
            const activities = syncService.getActivities(state);
            const organizations = syncService.getOrganizations(state);
            const programs = syncService.getPrograms(state);
            const serviceLocations = syncService.getServiceLocations(state);

            console.log("appointments.afterLoad - initial");
            console.log(appointments);

            appointments.forEach(appointment => {
                const appointmentDateInstance = (appointment.appointmentDate ? DateTime.fromISO(appointment.appointmentDate.substring(0,10)) : DateTime.now()).startOf('day');
                const startDateInstance = combineDateAndTime(appointmentDateInstance, appointment.startTime);
                let endDateInstance = combineDateAndTime(appointmentDateInstance, appointment.endTime);
                if (endDateInstance < startDateInstance){
                    endDateInstance = combineDateAndTime(startDateInstance.plus({days: 1}), appointment.endTime);
                    console.log("Overnight ---> Id : "+appointment.appointmentId + "Apptointment Date: " + dateToString(appointmentDateInstance) + " *** Start : " + dateToString(startDateInstance) + " *** End : " + dateToString(endDateInstance));
                }
                // if (validateRangeTimePicker(appointment.startTime, appointment.endTime)) {
                //     const appointmentEndDateInstance = appointmentDateInstance.plus({day : 1 });
                //     endDateInstance = combineDateAndTime(appointmentEndDateInstance, appointment.endTime);
                //     console.log("Overnigth ---> Id : "+appointment.appointmentId + " *** Start : " + startDateInstance.toJSDate() + " *** End : " + endDateInstance.toJSDate());
                // } else {
                //     endDateInstance = combineDateAndTime(appointmentDateInstance, appointment.endTime);
                // }
                appointment.startDateInstance = startDateInstance;
                appointment.endDateInstance = endDateInstance;
                appointment.start = startDateInstance.toJSDate();
                appointment.end = endDateInstance.toJSDate();

                this.fillAppointmentFields(
                    appointment, clients, activities, organizations, programs, serviceLocations);

                appointment.title = appointment.client ? formatClientNameWithoutId(appointment.client) : `Unknown Client (${appointment.clientId})`;
            });
        }

        return appointments;
    }

    fillAppointmentFields = (appointment, clients, activities, organizations, programs, serviceLocations) => {
        if (!appointment.client) {
            // eslint-disable-next-line eqeqeq
            appointment.client = clients && clients.find(c => c.clientId == appointment.clientId);
        }
        if (!appointment.activity) {
            appointment.activity = activities && activities.find(a => a.activityId === appointment.activityId);
        }
        if (!appointment.organization) {
            appointment.organization = organizations && organizations.find(o => o.organizationId === appointment.organizationId);
        }
        if (!appointment.program) {
            appointment.program = programs && programs.find(p => p.programId === appointment.programId);
        }
        if (!appointment.serviceLocation) {
            appointment.serviceLocation = serviceLocations && serviceLocations.find(sl => sl.serviceLocationId === appointment.serviceLocationId);
        }
    }

    // readFromServer = (config) => {
    //     return this.writeToServer(config)
    //         .then(() => {
    //             try {
    //                 return super.readFromServer(config);
    //             }catch(x){
    //                 console.log(x);
    //             }
    //         });
    // }

    createWriteAppointment = (appointment, clientPrograms) => {
        const beginTime = dateToStringWithFormat(appointment.startDateInstance, APPOINTMENT_START_STOP_DATE_TIME_FORMAT );
        const endTime = dateToStringWithFormat(appointment.endDateInstance, APPOINTMENT_START_STOP_DATE_TIME_FORMAT );
        const clientProgram = getClientProgramFromAppointment(appointment, clientPrograms);
        const excludeActualTimes = appointment.appointmentId < 0 && appointment.status === 'None';

        if (!clientProgram){
            const message = `Unable to determine client program: Client[${appointment.clientId}] Program[${appointment.programId}] Org[${appointment.organizationId}].`;
            console.log(message);
            console.log(clientPrograms);
            this.deleteByPrimaryKey(appointment.appointmentId);
            throw new Error(message);
        }

        const writeAppointment = {
            staffId: appointment.staffId,
            clientId: appointment.clientId,
            clientProgramId: clientProgram?.clientProgramId,
            organizationId: appointment.organizationId,
            appointmentDate: appointment.appointmentDate,
            beginTime: beginTime,
            endTime: endTime,
            activityId: appointment.activityId,
            serviceLocationId: appointment.serviceLocationId,
            serviceDocumentId: '',
            status: appointment.status
        };

        if (!excludeActualTimes){
            writeAppointment.actualStartTime = beginTime;
            writeAppointment.actualEndTime = endTime;
        }

        if (appointment.appointmentId > 0){
            writeAppointment.activityDetailId = appointment.activityDetailId;
            writeAppointment.activityLogId = appointment.appointmentId;
        }

        if (appointment?.status === 'Kept' && appointment?.activityDetailDescriptorList){
            writeAppointment.activityQualifiers = appointment?.activityDetailDescriptorList.filter(add => Number.isInteger(add?.descriptorId) && add?.descriptorId > 0);
        } else {
            writeAppointment.activityQualifiers = [];
        }

        if (isAddressRequiredForOrganization(appointment.organization)){
            if (appointment.startVisitAddress){
                writeAppointment.sameAsEndAddress = appointment.sameAsEndAddress;
                writeAppointment.startVisitAddress = {...appointment.startVisitAddress};
            }
            if (appointment.endVisitAddress){
                writeAppointment.sameAsEndAddress = appointment.sameAsEndAddress;
                writeAppointment.endVisitAddress = {...appointment.endVisitAddress};
            }
        }

        return writeAppointment;
    }

    saveAppointments = async(config, appointmentsToSave) => {
        console.log('AppointmentService.writeAppointmentsToServer - config:');
        console.log(config);
        return this.save(appointmentsToSave, {store: config.store, doNotClear: true})
            .then(() => {
                console.log("appointmentService.writeAppointmentsToServer: records saved successfully");
                console.log(appointmentsToSave);
                return Promise.resolve(appointmentsToSave);
            });
    }

    loopServerAppointments = async(appointments, writeAppointmentResponse, config) => {
        const appointmentsToSave = [];
        let appointmentsError = [];
        const dispatch = getDispatchFromConfig(config);
        if (writeAppointmentResponse.resources && writeAppointmentResponse.resources.length > 0) {
            writeAppointmentResponse.resources.forEach((appointmentFromServer) => {
                const index = writeAppointmentResponse.resources.indexOf(appointmentFromServer);
                const originalAppointment = appointments[index];
                const appointmentToSave = {...originalAppointment};
                if (appointmentFromServer.error && appointmentFromServer.error.length > 0){
                    this.deleteByPrimaryKey(originalAppointment.appointmentId);
                    findVisitAndRemove(config, originalAppointment);
                    appointmentFromServer.error.forEach((error) => {
                        appointmentsError.push(error);
                    })
                } else {
                    appointmentToSave.syncState = 'clean';

                    if (originalAppointment.appointmentId < 0) {
                        appointmentToSave.appointmentId = appointmentFromServer.activityLogId;
                        appointmentToSave.activityDetailId = appointmentFromServer.activityDetailId;
                        appointmentToSave.evvVisitId = appointmentFromServer.visitId;
                        findVisitAndUpdate(config, appointmentFromServer, originalAppointment, appointmentToSave);

                        syncService.documentService.updateAppointmentDocument(originalAppointment.appointmentId, appointmentToSave.activityDetailId);

                        console.log(`Original temp appointment id: ${originalAppointment.appointmentId} - final appointment id from server: ${appointmentToSave.appointmentId}`);
                        console.log(`DELETING original temp appointment with id: [${originalAppointment.appointmentId}]  to avoid duplicates.`);
                        this.deleteByPrimaryKey(originalAppointment.appointmentId);
                    }

                    if (isAddressRequiredForOrganization(originalAppointment.organization)) {
                        appointmentToSave.sameAsEndAddress = appointmentFromServer.sameAsEndAddress;
                        if (appointmentFromServer.startVisitAddress) {
                            appointmentToSave.startVisitAddress = appointmentFromServer.startVisitAddress;
                        }
                        if (appointmentFromServer.endVisitAddress) {
                            appointmentToSave.endVisitAddress = appointmentFromServer.endVisitAddress;
                        }
                    }

                    if (originalAppointment.appointmentId < 0) {
                        dispatch(this.operationRemove(originalAppointment));
                    }

                    appointmentsToSave.push(appointmentToSave);
                }
            })
        }
        
        if (appointmentsError?.length > 0) {
            throw new Error(this.errorFromServer(appointmentsError));
        } else if (appointmentsToSave.length > 0) {
            return this.saveAppointments(config, appointmentsToSave);
        } else {
            return Promise.resolve(appointments);
        }
    }

    errorFromServer = (appointmentsError) => {
        let error = '';
        if (appointmentsError?.length === 1) {
            error = appointmentsError[0];
        } else if (appointmentsError?.length > 1) {
            for (let i = 0; i < appointmentsError.length; i++) {
                error += appointmentsError[i] + "\n";
            }
        }
        return error;
    }

    writeAppointmentsToServer = async (appointments, config) => {
        let clientPrograms = syncService.getClientPrograms(config.store.getState());
        if (clientPrograms.length === 0){
            clientPrograms = await syncService.clientProgramService.loadFromRepository(config);
        }
        const writeAppointments = appointments.map(appointment => this.createWriteAppointment(appointment, clientPrograms));

        const writeAppointmentRequest = {
            resources: writeAppointments
        };

        console.log("Writing appointment to server: ");
        console.log(writeAppointmentRequest);

        return api.post(this.writeUrl, this.getFetchConfig(config, writeAppointmentRequest))
            .then(async writeAppointmentResponse => {
                console.log("Succes writing appointment to server: ");
                console.log(writeAppointmentResponse);
                return this.loopServerAppointments(appointments, writeAppointmentResponse, config);
            })
            .catch(error => {
                if (api.isConnectionLost()) {
                    return Promise.resolve(appointments);
                } else {  
                    return Promise.reject(error);    
                }
            });
    }

    writeToServer = (config) => {
        console.log('AppointmentService.writeToServer - config:');
        console.log(config);
        return this.repository.loadAllByProperty('syncState', 'dirty')
            .then(appointments => {
                if (appointments && appointments.length > 0){
                    return this.writeAppointmentsToServer(this.afterLoad(appointments, config), config)
                        .then(() => syncService.visitService.dependentWriteToServer(config));
                } else {
                    return syncService.visitService.dependentWriteToServer(config)
                        .then(() => Promise.resolve(appointments));
                }
            })
            .catch(error => {
                console.log("Error thrown while writing to Appointment server: ");
                console.log(error);
            })
    }

    isSyncRequired = () => {
        return this.repository.loadAllByProperty('syncState', 'dirty')
            .then(appointments => {
                if (appointments.length > 0){
                    return Promise.resolve(true);
                }

                return syncService.visitService.isSyncRequired();
            });
    }

    getAppointmentsForUnSignedDocs = () => {
        return evvRepository?.evvDb?.appointment
            .where("status").anyOf("Kept", "Co-Staff", "In Session", "Checked In")
            .toArray();
    }

    checkHasAppointment = (appointmentId) => {
        return this.repository.loadAllByProperty('appointmentId', appointmentId)
            .then(appointment => {
                if(appointment && appointment.length > 0) {
                    return appointment;
                }
            })
    }

    getAppointmentActivity = (activityId) => {
        return evvRepository?.evvDb?.activity.where({activityId: activityId}).toArray();
    }
}

export default AppointmentService;
