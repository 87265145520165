import appReducer from './slices/app/appSlice';
import clientReducer from './slices/client/clientSlice';
import scheduleReducer from './slices/schedule/scheduleCache';
import documentReducer from './slices/document/documentCache';
import syncService from '../common/services/syncService';
import {combineReducers} from 'redux';

const reducer = (state, action) => {
    if (action.type === 'LOGOUT'){
        state = undefined;
    }

    return combinedReducer(state, action);
}

const combinedReducer = combineReducers({
    app: appReducer,
    client: clientReducer,
    schedule: scheduleReducer,
    document: documentReducer,
    sync: combineReducers(syncService.getReducers())
});

export default reducer;
