import DisplayErrorMessages from "../common/DisplayErrorMessages";
import FormField from "./FormField";
import { Grid } from "@material-ui/core";
import LoginTextField from "../../../common/components/LoginTextField";
import { getXSAndSMValueForField } from "../common/Util";

const MINLENGTH = "";
const MAXLENGTH = 30
class TextField extends FormField {
    constructor(props) {
        super(props);

        let description = "";
        let datatype = "";
        let minlength = "";
        let maxlength = "";
        let isRequired = this.isMandatory();
        props.fieldDescriptor = this.fieldDescriptor;

        let descObj = this.fieldDescriptor.attributes.find( (attribute) => attribute.attributeId === this.fieldDescriptor.id +"_description" );
        description = descObj.value;

        let datatypeobj = this.fieldDescriptor.attributes.find( (attribute) => attribute.attributeId === this.fieldDescriptor.id +"_datatype" );
        datatype = datatypeobj.value;
       
        let minlengthobj = this.fieldDescriptor.attributes.find( (attribute) => attribute.attributeId === this.fieldDescriptor.id +"_minlength" );
        minlength = minlengthobj?.value;

        let maxlengthobj = this.fieldDescriptor.attributes.find( (attribute) => attribute.attributeId === this.fieldDescriptor.id +"_maxlength" );
        maxlength = maxlengthobj?.value;

        this.state = {
            description: description,
            data: props,
            isRequired: isRequired,
            datatype: datatype,
            minlength: minlength,
            maxlength: maxlength,
        };
    }

    getValue = () => {
        if(!this.fieldDescriptor.value && this.fieldDescriptor?.dbSavedValue) {
            this.fieldDescriptor.value = this.fieldDescriptor?.dbSavedValue;
        }

        return this.fieldDescriptor.value;
    }

    renderField(props, state){
        let breakPoints = getXSAndSMValueForField(props.isHandheld, props.orientation, 12, 6);
        props.fieldDescriptor = this.fieldDescriptor;

        return (
            <div className="textField">
                { this.fieldDescriptor?.businessRuleApplied !== "Hide" &&
                <Grid container item={true} xs={breakPoints.xsValue} sm={breakPoints.smValue}>
                <span>{ this.state.description }</span>
                <LoginTextField
                    {...props}
                    id={'textField-' + this.fieldDescriptor.id}
                    name={'textField-' + this.fieldDescriptor.id}
                    value={ this.getValue() }
                    type={this.state.datatype}
                    required={this.state.isRequired}
                    onChange={(event) => { props.onChange(event, this.fieldDescriptor, props); } }
                    minCharacters={this.state.minlength? this.state.minlength : MINLENGTH}
                    maxCharacters={this.state.maxlength? this.state.maxlength : MAXLENGTH}
                    disabled = { state.isReadOnlyDoc }
                />
                <div>
                    <DisplayErrorMessages
                        {...props}
                    />
                </div>
                </Grid>
                }
            </div>
        );
    }
}

export default TextField;
