import {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from "@material-ui/core/styles";
import EvvButton from "../../../common/components/EvvButton";
import StackedField from "../../../common/components/StackedField";
import {TextField} from "@material-ui/core";
import EvvSelect from "../../../common/components/EvvSelect";
import { renderReasonCodesOptions } from '../../forms/common/Util';

const useStyles = makeStyles(() => ({
    dialogPaper: {
        width: '400px'
    },
    dialogTitle: {
        maxWidth: '660px',
        maxHeight: '800px',
        height: '42px',
        width: 'auto',
        fontSize: '16px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: "#ffffff",
        paddingLeft: '16px',
        display: 'flex',
        alignItems: 'center'
    },
    dialogContent: {
        padding: "19px 19px 25px 19px",
    },
    okCancelButtonGroup: {
        padding: "0px 16px 15px 16px",
        justifyContent: 'space-between'
    },
    okButton: {
        padding: '0',
        minWidth: '125px'
    },
    cancelButton: {
        padding: '0'
    },
    label: {
        flexDirection: 'column',
        textTransform: "none",
        color: '#888888'
    },
    filterSelect: {
        width: '100%',
        height: '27px',
        borderRadius: '2px',
        background: "transparent",
        borderStyle: "solid",
        borderWidth: '1px',
        borderColor: "#b6b6b6"
    },
    errorMessage : {
        position: 'relative',
        bottom: '-5px',
        fontSize: '13px',
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: '16px',
        letterSpacing: 0,
        color: "#dc0707"
    },
    cursorColor: {
        '& .MuiOutlinedInput-input': { 
            caretColor: '#888888'
        },
    }
}));

export default function AddressDistanceDialog({visit, reasonCodes, onClose, message}){
    const [addressDistanceReason, setAddressDistanceReason] = useState(visit.savedAddressDistanceReason || '');
    const [comments, setComments] = useState(visit.savedAddressDistanceComments || '');
    const [saveAttempted, setSaveAttempted] = useState(false);
    const styles = useStyles();

    const fieldToSetterMap = {
        addressDistanceReason: setAddressDistanceReason,
        comments: setComments
    };

    const handleTextFieldChange = (evt) => {
        fieldToSetterMap[evt.target.id](evt.target.value);
    };

    const handleSave = () => {
        if ((reasonCodes && reasonCodes.length > 0 && addressDistanceReason.length === 0) || comments.length === 0) {
            setSaveAttempted(true);
        } else {
            const editedVisit = {
                ...visit,
                addressDistanceReason: addressDistanceReason,
                addressDistanceComments: comments,
                savedAddressDistanceReason: addressDistanceReason,
                savedAddressDistanceComments: comments
            }

            if (onClose) {
                onClose(editedVisit);
            }
        }
    };

    const handleCancel = () => {
        if (onClose){
            onClose(null);
        }
    }

    const renderContent = () => {
        return (
            <div>
                <div>
                    <StackedField mandatory={false} label={message} boldLabel={false} paddingTop="0px" />
                    <StackedField mandatory={true} label='Reason - Distance Discrepancy' paddingTop="0px" >
                        <EvvSelect id='addressDistanceReason' value={addressDistanceReason} className={styles.filterSelect} onChange={handleTextFieldChange}>
                            {renderReasonCodesOptions(reasonCodes)}
                        </EvvSelect>
                        {saveAttempted && addressDistanceReason.length === 0 &&
                        <div className={styles.errorMessage} >Reason is a required field. Please complete and save.</div>
                        }
                    </StackedField>
                </div>
                <div>
                    <StackedField mandatory={true} label='Comments' paddingTop="22px" >
                        <TextField
                            id="comments"
                            value={comments}
                            onChange={handleTextFieldChange}
                            multiline={true}
                            rows={5}
                            focused={false}
                            variant="outlined"
                            fullWidth={true}
                            className={styles.cursorColor}
                            inputProps={{
                                maxLength: 256
                            }}
                        />
                        {saveAttempted && comments.length === 0 &&
                        <div className={styles.errorMessage} >Comments is a required field. Please complete and save.</div>
                        }
                    </StackedField>
                </div>
            </div>
        );
    }

    return (
        <div>
            <Dialog
                classes={{paper: styles.dialogPaper}}
                open={true}
                disableEscapeKeyDown={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle classes={{root: styles.dialogTitle}} id="alert-dialog-title">Address Discrepancy</DialogTitle>
                <DialogContent classes={{root: styles.dialogContent}}>
                    {renderContent()}
                </DialogContent>
                <DialogActions classes={{root: styles.okCancelButtonGroup}}>
                    <div className={styles.cancelButton}>
                        <EvvButton type='tertiary' onClick={handleCancel} >Cancel</EvvButton>
                    </div>
                    <div className={styles.okButton}>
                        <EvvButton type='primary' onClick={handleSave} >Save</EvvButton>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
}
