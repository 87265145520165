import { Divider, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import GAComments from "./GAComments";
import "../ServiceDocName.css";
import { getGaFieldById, handleChange, insertGaComment, pgoiHandleChange, getSatusOptionsFun } from "../../../common/utils/goalsAddressedUtils";
import { GATYPE } from "../../../common/constants";
import { SERVICE_DOCUMENT_STATUS_INCOMPLETE, SERVICE_DOCUMENT_STATUS_SIGNED } from "../serviceDocumentUtils";
import GaTxPlans from "./GaTxPlans";
import { gaValidationWithFocus, validateGaFields } from "./ValidateGaFields";
import { focusOnElement } from "../common/FindFirstErrorFieldUtils";
import { useSelector } from "react-redux";
import { appCache } from "../../../cache/slices/app/appSlice";

const useStyles = makeStyles((theme) => ({
    GoalsAddressed: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '17px',
        paddingLeft: '15px',
        paddingRight: '15px'
    },
    hide: {
        display: 'none'
    }
}));

export default function GoalsAddressed (props) {
    const { hide, module, serviceDocument, documentController } = props;
    let isHandheld = documentController.dataProvider.isHandheld();
    const styles = useStyles();
    const className = styles.GoalsAddressed + ' ' + (hide ? styles.hide : '');
    const id = `goalsAddressed${module.moduleId}`;
    let document = (props?.documentController?.appointmentDocument && props?.documentController?.appointmentDocument.length > 0) ? props?.documentController?.appointmentDocument[0] : props?.documentForWizard;
    let isReadOnly = document?.documentStatus === SERVICE_DOCUMENT_STATUS_SIGNED ? true : false;
    let [statusOptions, setStatusOptions] = useState([]);
    // eslint-disable-next-line
    let [errorCount, setErrorCount] = useState(0);
    const user = useSelector(appCache?.getUser);

    let getOrientation = window.matchMedia("(orientation: portrait)");
    let orientation = getOrientation.matches ? "portrait" : "landscape";
    props = { ...props, isHandheld, orientation };

    useEffect(() => {
        applyEventHandlers()
        createFormFields();
        validateOnLoad();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getSatusOptionsFun(setStatusOptions, document, user);
        // eslint-disable-next-line
    }, [user]);

    const validateOnLoad = () => {
        setTimeout(() => {
            if (module?.fields?.length > 0) {
                if (module?.formValidated === false) {
                    gaValidationWithFocus("validateOnLoad", module, setErrorCount);
                }
                handleSave();
            }
        }, 1000);
    }

    const applyEventHandlers = () =>   {
        module.onValidate = handleValidate;
        module.onPageChange = handlePageChange;
    }

    const createFormFields = () => {
        module.fields = [];
        module.fieldData = [];
        module.isPgoiChanged = false;
        module.isPgoiUpdated = false;
        module.pgoi = [];
    }

    const handleValidate = (currentPage, wizardProps) => {
        let validationResult = validateGaFields("handleValidate", module);
        focusOnElement(currentPage);
        if(currentPage && validationResult?.showFloatingErrorMsg) {
            props?.documentController.showFloatingErrorMessage();
        }
        // Set floating error message using parent callback function(setFloatingErrorMsg)
        props?.setFloatingErrorMsg(validationResult?.showFloatingErrorMsg);
        currentPage?.fields?.map(field => field.nextClicked = true);
        return validationResult.isValid;
    }

    const handlePageChange = (sourcePage, targetPage, changeType) => {
        gaValidationWithFocus("handlePageChange", module);
        handleSave();
    }

    const handleSave = () => {
        let moduleData = {
            moduleId: module.moduleId,
            moduleType: GATYPE,
            fieldData: module?.fieldData
        };

        moduleData = insertGaComment(moduleData, props);

        if (!documentController.moduleData){
            documentController.moduleData = {};
        }

        documentController.moduleData[module.moduleId] = moduleData;
    }

    const updateDocStatus = () => {
        props.state.serviceDocument.status = SERVICE_DOCUMENT_STATUS_INCOMPLETE;
        props.state.status = SERVICE_DOCUMENT_STATUS_INCOMPLETE;
    }

    const handleFieldChange = (type, evt, props, formField, setValue) => {
        let pgoiFormTypes = ["addressed", "status", "comments"];
        if ( pgoiFormTypes.includes(type)) {
            pgoiHandleChange(type, evt, props, formField);
        } else if (formField) {
            let field = getGaFieldById(props, formField?.id);
            if (field) {
                handleChange(field, evt, props, setValue);
                handleSave();
                updateDocStatus();
            }
        }
        validateGaFields("handleFieldChange", module);
    }

    return (
        <div id = { id } data-testid = 'goalsAddressed' className = { className }>
            <div className = "serviceDocumentName">
            { !isHandheld && <><div className = "serviceDocName" data-testid = 'serviceDocName'>{ serviceDocument?.description }</div><Divider /></>}</div>
            { isHandheld && <><div>{ props.renderFormName() }</div><Divider /></>}
            <div>
                { <GaTxPlans { ...props } onChange = { handleFieldChange } isReadOnly = { isReadOnly } statusOptions = { statusOptions } document = { document } /> }
                { <GAComments { ...props } onChange = { handleFieldChange } isReadOnly = { isReadOnly } /> }
            </div>
        </div>
    );
}