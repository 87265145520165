import { orderBy } from "lodash";
import { ACCORDIANFONT, GANAME, GATYPE } from "../constants";
import { DateTime } from "luxon";
import goalAddressedService from "../services/goalAddressedService";
import { Typography } from "@material-ui/core";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export const isGA = (module) => {
    return ( module?.name === GANAME || module?.moduleType === GATYPE );
}

export const getGaFieldByType = (fieldType, module) => {
    let field = module?.fields?.find(field => field.type === fieldType);
    return field;
}

export const checkUpdatedFieldGa = (module) => {
    let updated  = false;

    let changedFields = module?.fields?.filter(field => field.isChanged === true);
    if ( (changedFields && changedFields.length > 0) || module?.isPgoiChanged) {
        updated = true;
    }

    return updated;
}

export const getGaFieldValue = (field, call = "single") => {
    return field?.value;
}

export const getDbFieldValue = (field, props, call = "single") => {
    let dbFieldValue = getGaFieldValue(field, call);
    let dbModuleData = (props?.documentController?.appointmentDocument && props?.documentController?.appointmentDocument?.length > 0) ? props?.documentController?.appointmentDocument[0]?.moduleData : [];
    if (dbModuleData && dbModuleData.length > 0) {
        let dbModule = dbModuleData.find(mod => props?.module?.moduleId === mod.moduleId);
        let dbFieldData = dbModule?.fieldData;

        if (dbModule && dbFieldData && dbFieldData.length > 0) {
            if (props?.module) {
                props.module.fieldData = dbModule?.fieldData;
                if (props?.documentController?.moduleData && props?.documentController?.moduleData[props?.module?.moduleId]) {
                    props.documentController.moduleData[props.module.moduleId].fieldData = dbModule?.fieldData;
                }
            }
            if (call === "single") {
                let dbField = dbFieldData.find(data => data.goalComment !== undefined);
                if (dbField) {
                    dbFieldValue = dbField.goalComment;
                    field.value = dbFieldValue;
                }
            } else {
                let dbField = dbFieldData.filter(data => data.treatmentPlanId !== undefined);
                if (dbField && dbField.length > 0) {
                    dbFieldValue = dbField?.map(ele => {
                        return ele.treatmentPlanId.toString();
                    });
                    field.value = dbFieldValue;
                }
            }
        }
    }

    return dbFieldValue;
}

export const getGaFieldById = (props, fieldId) => {
    return props.module.fields.find(ele => ele.id === fieldId);
}

export const isFieldExists = (props, fieldId) => {
    let field = getGaFieldById(props, fieldId);
    return field ? true : false;
}

export const isPlanActive = (plan, appointment) => {
    let isActive = false;
    const currentDate = DateTime.now();
    let planBeginDate = plan.beginDate ? DateTime.fromISO(plan.beginDate): '';
    let planEndDate = plan.endDate ? DateTime.fromISO(plan.endDate) : '';

    if (appointment) {
        //Service Document associated with an appointment(Begin date is =< the appointment start date and appointment End date is not set or is => current date)
        let appointmentStartDate = appointment.startDateInstance;
        isActive = (planBeginDate <= appointmentStartDate ) && ( !planEndDate || planEndDate >= appointmentStartDate );
    } else {
        //Service Document not associated with an appointment(Begin date is =< the current date and End date is not set or is => current date)
        isActive = (planBeginDate <= currentDate ) && ( !planEndDate || planEndDate >= currentDate );
    }

    return isActive;
}

export const getAppointmentData = (documentController) => {
    let appointment;
    if (attachedToAppointment(documentController)) {
        let appointmentArr = documentController.dataProvider.getAppointments()?.filter(ele => (ele?.activityDetailId || ele?.appointmentId) === documentController?.appointmentDocument[0]?.appointmentId);
        if (appointmentArr && appointmentArr.length > 0) {
            appointment = appointmentArr[0];
        }
    }
    return appointment;
}

export const attachedToAppointment = (documentController) => {
    return documentController?.appointmentDocument && documentController?.appointmentDocument[0]?.appointmentId;
}

export const sortTxPlans = (txPlans) => {
    txPlans = orderBy(txPlans, ['beginDate']);
    return txPlans;
}

export const getClientTxPlans = (documentController) => {
    let gaTxPlans = sortTxPlans(documentController.dataProvider.getGoalAddressedTxplan());
    let appointment = getAppointmentData(documentController);
    let clientTxPlans = gaTxPlans.filter(plan => Number(plan?.clientId) === Number(documentController?.client?.clientId)).filter(plan => isPlanActive(plan, appointment));
    return clientTxPlans;
}

export const formattedTxPlanDate = (value) => {
    let formattedDate = '';
    if (value) {
        let dateWithHyphen = value.split('T')[0];
        let dateArr = dateWithHyphen.split('-');
        formattedDate = dateArr[1] + "/" + dateArr[2] + "/" + dateArr[0];
    }
    return formattedDate;
}

export const getTxPlanLabel = (plan) => {
    const planTypeDsc = plan.planTypeDsc ? ' - ' + plan.planTypeDsc : '';
    return plan.name + planTypeDsc + ' - ' + formattedTxPlanDate(plan.beginDate) + '-' + formattedTxPlanDate(plan.endDate);
}

export const removeFieldDataEntry = (props, newValue) => {
    let fieldData = props?.module?.fieldData;
    let txPlanEntry = fieldData?.find(ele => ele.treatmentPlanId === Number(newValue));
    if (txPlanEntry) {
        const foundIndex = fieldData.indexOf(txPlanEntry);
        fieldData.splice(foundIndex, 1);
    }
}

export const handleChange = (field, evt, props, setValue) => {
    let fieldValue;
    let oldValue = field?.value;
    let newValue = evt.target.value;

    field.isChanged = true;
    field.isUpdated = true;

    switch(field?.type) {
        case "GA_Checkbox":
            if (evt.target.checked) {
                if (!oldValue.includes(newValue)) {
                    oldValue.push(newValue);
                }
            } else {
                if (oldValue.includes(newValue)) {
                    const foundIndex = oldValue.indexOf(newValue);
                    oldValue.splice(foundIndex, 1);
                }
                removeFieldDataEntry(props, newValue);
            }
            setValue(oldValue);
            fieldValue = oldValue;
        break;

        default:
            setValue(newValue);
            fieldValue = newValue;
    }

    field.value = fieldValue;
    return fieldValue;
}

export const getPgoiData = (txPlan, setPgoiData) => {
    //Get all problems, goals, objectives & interventions associated with the selected treatment plan
    goalAddressedService.getPgoiCollection(txPlan).then((result) => {
        setPgoiData(result);
    }).catch((error) => {
        console.log('Error: ', error);
    });
}

export const getGAEle = (pgoiData, type) => {
    let elements = pgoiData.filter(pgoi => pgoi.type === type);
    elements = sortFunction(elements, 'priorityMappedValue');
    elements?.forEach(ele => {
        ele.formAddressed = false;
        ele.formStatus = "";
        ele.formComments = "";
        if (ele?.type === "P" || ele?.type === "I") {
            let eleName = `is${ele?.type}Expanded`;
            ele[eleName] = false;
        }
        return ele;
    });
    return elements;
}

export const getTxPlanName = (plan) => {
    return plan?.name;
}

export const getPgoMapData = (txPlan, setPgoMapData) => {
    //Get the problems, goals & objectives mapping
    goalAddressedService.getPgoMapCollection(txPlan).then((result) => {
        result?.map((ele) => {
            ele.isGExpanded = false;
            ele.isOExpanded = false;
            return ele;
        });
        setPgoMapData(result);
    }).catch((error) => {
        console.log('Error: ', error);
    });
}

export const getAccordionSummary = (element, styles, config, uniqueMapping, isHandheld) => {
    let heading = config?.layerLabel;
    let eleName = `is${element.type}Expanded`;
    if (heading) {
        return <Typography className={!uniqueMapping[eleName] ? styles.planHeadingCollapsed : styles.planHeadingExpanded} style = {isHandheld ? {fontSize: ACCORDIANFONT}: {}}>
            { !uniqueMapping[eleName] && element?.formAddressed && <CheckCircleIcon style = {{ color: "#43B9AA", verticalAlign: "sub" }} /> }&nbsp;
            { `${heading} - ${element?.name}` }</Typography>;
    }
}

export const getProblemMapping = (problem, pgoMapData) => {
    //Look for the entries corresponding to particular problem
    let problemMapping = pgoMapData.filter(data => data.problemId === problem.pgoiId);
    return problemMapping;
}

export const getPgoiConfig = (configurations, configName) => {
    let element = configurations?.find(config => config.name === configName);
    return element;
}

export const getServiceDocConfig = async(plan) => {
    //svcdoc/Problems/Goals/Objectives module/configurations
    let sdPgoModuleConfig;
    let serviceDocWithConfig = await goalAddressedService.getConfigServiceDoc(plan?.serviceDocSetupId);

    let sdPgoModule = serviceDocWithConfig[0]?.modules?.filter(module => module.name === "Problems/Goals/Objectives");
    if (sdPgoModule && sdPgoModule.length > 0) {
        sdPgoModuleConfig = sdPgoModule[0]?.configurations;
    }
    return sdPgoModuleConfig;
}

export const getShowHide = (showHideObj) => {
    let show = false;
    if (showHideObj) {
        show = (showHideObj?.value === "S") ? true : false;
    }
    return show;
}

export const getLayerLabel = (layerLabelObj) => {
    let layerLabel = '';
    if (layerLabelObj) {
        layerLabel = layerLabelObj?.value;
    }
    return layerLabel;
}

export const getPriorityLabel = (priorityLabelObj) => {
    let priorityLabel = '';
    if (priorityLabelObj) {
        priorityLabel = priorityLabelObj?.value;
    }
    return priorityLabel;
}

export const getAllowSelection = (selectionObj) => {
    let allowSelection = false;
    if (selectionObj) {
        allowSelection = (selectionObj?.value === "N") ? false : true;
    }
    return allowSelection;
}

export const getRequireSelection = (selectionObj) => {
    let requireSelection = false;
    if (selectionObj) {
        requireSelection = (selectionObj?.value === "Y") ? true : false;
    }
    return requireSelection;
}

export const fetchConfig = async(type, plan, module) => {
    let config, showHideObj, layerLabelObj, priorityLabelObj, selectionObj, showHideStatusObj, statusLabelObj, showHideCommentObj, commentLabelObj, includeCompletedObj, requireSelectionObj;
    let gAConfig = module?.configurations;

    let sdPgoModuleConfig = await getServiceDocConfig(plan);

    if (gAConfig && sdPgoModuleConfig) {
        switch(type) {
            case 'goals':
                showHideObj = getPgoiConfig(gAConfig, "Goals Field");
                layerLabelObj = getPgoiConfig(sdPgoModuleConfig, "Goal Layer Label");
                priorityLabelObj = getPgoiConfig(sdPgoModuleConfig, "Goal Priority Label");
                selectionObj = getPgoiConfig(gAConfig, "Goals Selection");
            break;

            case 'objectives':
                showHideObj = getPgoiConfig(gAConfig, "Objectives Field");
                layerLabelObj = getPgoiConfig(sdPgoModuleConfig, "Obj Layer Label");
                priorityLabelObj = getPgoiConfig(sdPgoModuleConfig, "Obj Priority Label");
                selectionObj = getPgoiConfig(gAConfig, "Objectives Selection");
            break;

            case 'interventions':
                showHideObj = getPgoiConfig(gAConfig, "Interventions Field");
                layerLabelObj = getPgoiConfig(sdPgoModuleConfig, "Int Layer Label");
                priorityLabelObj = getPgoiConfig(sdPgoModuleConfig, "Int Priority Label");
                selectionObj = getPgoiConfig(gAConfig, "Interventions Selection");
            break;

            case 'global':
                showHideStatusObj = getPgoiConfig(gAConfig, "Status Field");
                statusLabelObj = getPgoiConfig(gAConfig, "Status Label");
                showHideCommentObj = getPgoiConfig(gAConfig, "Comments Field");
                commentLabelObj = getPgoiConfig(gAConfig, "Comments Label");
                includeCompletedObj = getPgoiConfig(gAConfig, "Include Completed Items");
                requireSelectionObj = getPgoiConfig(gAConfig, "Require Selection");
            break;

            default:
                showHideObj = getPgoiConfig(gAConfig, "Problems Field");
                layerLabelObj = getPgoiConfig(sdPgoModuleConfig, "Prob Layer Label");
                priorityLabelObj = getPgoiConfig(sdPgoModuleConfig, "Prob Priority Label");
            break;
        }
    }

    if (type === "global") {
        let showHideStatus = getShowHideRequired(showHideStatusObj);
        let statusLabel = getLayerLabel(statusLabelObj);
        let showHideComment = getShowHideRequired(showHideCommentObj);
        let commentLabel = getLayerLabel(commentLabelObj);
        let includeCompleted = getAllowSelection(includeCompletedObj);
        let requireSelection = getRequireSelection(requireSelectionObj);

        config = {
            showHideStatus,
            statusLabel,
            showHideComment,
            commentLabel,
            includeCompleted,
            requireSelection
        };
    } else {
        let show = getShowHide(showHideObj);
        let layerLabel = getLayerLabel(layerLabelObj);
        let priorityLabel = getPriorityLabel(priorityLabelObj);
        let allowSelection = getAllowSelection(selectionObj);

        config = {
            show,
            layerLabel,
            priorityLabel,
            allowSelection
        };
    }

    return config;
}

export const getConfigurations = async(plan, module, setProblemsConfig, setGoalsConfig, setObjectivesConfig, setInterventionsConfig, setGlobalConfig) => {
    //Fetch PGOI configurations
    let problemsConfig = await fetchConfig('problems', plan, module);
    let goalsConfig = await fetchConfig('goals', plan, module);
    let objectivesConfig = await fetchConfig('objectives', plan, module);
    let interventionsConfig = await fetchConfig('interventions', plan, module);
    let globalConfig = await fetchConfig('global', plan, module);

    setProblemsConfig(problemsConfig);
    setGoalsConfig(goalsConfig);
    setObjectivesConfig(objectivesConfig);
    setInterventionsConfig(interventionsConfig);
    setGlobalConfig(globalConfig);
}

export const createSubHeader = (str, config) => {
    if (str.length > 0) {
        str += "/";
    }
    str += config?.layerLabel;
    return str;
}

export const getSubHeader = (params) => {
    let {setSubHeader, problemsConfig, goalsConfig, objectivesConfig, interventionsConfig, linkIntervention, problems, interventions} = params;
    let subHeaderStr = "";

    if (problems && problems.length > 0 && problemsConfig?.show) {
        subHeaderStr = createSubHeader(subHeaderStr, problemsConfig);
    }

    if (goalsConfig?.show) {
        subHeaderStr = createSubHeader(subHeaderStr, goalsConfig);
    }

    if (objectivesConfig?.show) {
        subHeaderStr = createSubHeader(subHeaderStr, objectivesConfig);
    }

    if (interventions && interventions.length > 0 && interventionsConfig?.show && linkIntervention === "Y") {
        subHeaderStr = createSubHeader(subHeaderStr, interventionsConfig);
    }

    setSubHeader(subHeaderStr);
}

export const getPgoIntMapData = (txPlan, setPgoIntMapData) => {
    //Get interventions mapping
    goalAddressedService.getPgoIntMapCollection(txPlan).then((result) => {
        result?.map((ele) => {
            return ele.isIExpanded = false;
        });
        setPgoIntMapData(result);
    }).catch((error) => {
        console.log('Error: ', error);
    });
}

export const getInterventionMapping = (objective, pgoMapData, pgoIntMapData, goal) => {
    //Look for the interventions corresponding to particular objective
    let interventionMapping = [];
    let intPgoMapping = pgoMapData.find(data => data?.objectiveId === objective?.pgoiId && data?.goalId === goal?.pgoiId);
    if (intPgoMapping) {
        interventionMapping = pgoIntMapData.filter(data => data.pgoMapId === intPgoMapping.pgoMapId);
    }
    return interventionMapping;
}

export const filterByTplanMasterId = (PGOI, tPlanMasterId) => {
    return PGOI.filter(ele => ele.tPlanMasterId === tPlanMasterId);
}

export const getUpdatedEntry = (type, gaDetailEntry, value) => {
    let updatedEntry;

    if (type === "status") {
        updatedEntry = { ...gaDetailEntry, status: value};
    } else if (type === "comments") {
        updatedEntry = { ...gaDetailEntry, comment: value};
    } else {
        updatedEntry = { ...gaDetailEntry, isAddressed: true};
    }

    return updatedEntry;
}

export const updateGaDetails = (type, txPlanEntry, newTxPlanEntry, newGaDetailEntry, formField, value, fieldData) => {
    if (txPlanEntry) {
        let gaDetailEntry = txPlanEntry?.goalAddressedDetail.find(ele => ele.pgoId === formField?.pgoiId);
        if (!gaDetailEntry) {
            txPlanEntry?.goalAddressedDetail?.push(newGaDetailEntry);
        } else {
            const foundIndex = txPlanEntry?.goalAddressedDetail?.indexOf(gaDetailEntry);
            let updatedEntry = getUpdatedEntry(type, gaDetailEntry, value);
            txPlanEntry.goalAddressedDetail[foundIndex] = updatedEntry;
        }
    } else {
        fieldData.push(newTxPlanEntry);
    }
}

export const getNewTxPlanEntry = (treatmentPlanId, goalAddressedDetailEntry) => {
    return {
        moduleType: GATYPE,
        treatmentPlanId: treatmentPlanId,
        goalAddressedDetail: [goalAddressedDetailEntry]
    };
}

export const updateFlags = (formField, props) => {
    if (formField) {
        formField.isChanged = true;
        formField.isUpdated = true;
    }

    if (props?.module) {
        props.module.isPgoiChanged = true;
        props.module.isPgoiUpdated = true;
    }
}

export const pgoiHandleChange = (type, evt, props, formField) => {
    let value, newTxPlanEntry, goalAddressedDetailEntry;
    let fieldData = props?.module?.fieldData;
    let treatmentPlanId = props?.gaTxPlan?.treatmentPlanId;
    let txPlanEntry = fieldData?.find(ele => ele.treatmentPlanId === treatmentPlanId);

    updateFlags(formField, props);

    switch(type) {
        case 'addressed':
            goalAddressedDetailEntry = {
                pgoId: formField?.pgoiId,
                status: "",
                comment: "",
                isAddressed: true
            };

            newTxPlanEntry = getNewTxPlanEntry(treatmentPlanId, goalAddressedDetailEntry);

            if (evt?.target?.checked) {
                formField.formAddressed = true;
                updateGaDetails(type, txPlanEntry, newTxPlanEntry, goalAddressedDetailEntry, formField, value, fieldData);
            } else {
                formField.formAddressed = false;
                if (txPlanEntry) {
                    let pgoiEntry = txPlanEntry.goalAddressedDetail.find(ele => ele?.pgoId === formField?.pgoiId);
                    if (pgoiEntry) {
                        const foundIndex = txPlanEntry.goalAddressedDetail.indexOf(pgoiEntry);
                        txPlanEntry.goalAddressedDetail[foundIndex] = { ...pgoiEntry, isAddressed: false};
                    }
                }
            }
        break;

        case 'status':
            value = evt?.target?.value;
            formField.formStatus = value;

            goalAddressedDetailEntry = {
                pgoId: formField?.pgoiId,
                status: value,
                comment: "",
                isAddressed: false
            };

            newTxPlanEntry = getNewTxPlanEntry(treatmentPlanId, goalAddressedDetailEntry);
            updateGaDetails(type, txPlanEntry, newTxPlanEntry, goalAddressedDetailEntry, formField, value, fieldData);
        break;

        default:
            //comments
            value = evt?.target?.value;
            formField.formComments = value;

            goalAddressedDetailEntry = {
                pgoId: formField?.pgoiId,
                status: "",
                comment: value,
                isAddressed: false
            };

            newTxPlanEntry = getNewTxPlanEntry(treatmentPlanId, goalAddressedDetailEntry);
            updateGaDetails(type, txPlanEntry, newTxPlanEntry, goalAddressedDetailEntry, formField, value, fieldData);
        break;
    }
}

export const getDbPgoiData = (element, txPlanDbData) => {
    return txPlanDbData?.goalAddressedDetail?.find(ele => ele.pgoId === element.pgoiId);
}

export const isChecked = (element) => {
    return element?.formAddressed;
}

export const getSelectedStatus = (element) => {
    return element?.formStatus;
}

export const getCommentsValue = (element) => {
    return element?.formComments;
}

export const insertGaComment = (moduleData, props) => {
    let isGaCommentExists = moduleData?.fieldData?.find(ele => ele.goalComment !== undefined);
    let gaCommentsField = getGaFieldById(props, "gaComments");
    let gaCommentsData = {
        moduleType: GATYPE,
        goalComment: gaCommentsField?.value
    };

    if (!isGaCommentExists) {
        moduleData?.fieldData?.push(gaCommentsData);
    } else {
        const foundIndex = moduleData?.fieldData?.indexOf(isGaCommentExists);
        moduleData.fieldData[foundIndex] = { ...gaCommentsData }
    }

    return moduleData;
}

export const getTxPlanDbData = (props, gaTxPlan, setTxPlanDbData) => {
    let document = props?.documentController?.appointmentDocument ? props?.documentController?.appointmentDocument[0] : props?.documentForWizard;
    if (document?.moduleData) {
        let dbModuleData = document?.moduleData?.find(mod => props?.module?.moduleId === mod.moduleId);
        if (dbModuleData) {
            if (dbModuleData?.fieldData) {
                props.module.fieldData = dbModuleData?.fieldData;
                let txPlanDbData = dbModuleData?.fieldData?.find(ele => ele?.treatmentPlanId === gaTxPlan?.treatmentPlanId);
                if (txPlanDbData) {
                    setTxPlanDbData(txPlanDbData);
                }
            }
        }
    }
}

export const trackMapping = (element, uniqueMapping) => {
    //Need this for keeping accordian open/close on validations
    let mapping = [];
    let mapIdType, mapId;

    if (uniqueMapping?.type) {
        mapIdType = 'pgoiId';
        mapId = uniqueMapping?.pgoiId;
        mapping.push({ mapIdType, mapId });
    } else {
        mapIdType = 'pgoMapId';
        mapId = uniqueMapping?.pgoMapId;

        if (element?.mapping) {
            mapping = [ ...element?.mapping ];
            let mappingExists = mapping?.find(map => map?.mapId === mapId);
            if (!mappingExists) {
                mapping.push({ mapIdType, mapId });
            }
        } else {
            mapping.push({ mapIdType, mapId });
        }
    }

    element.mapping = [ ...mapping ];
}

export const updateElement = (element, txPlanDbData, uniqueMapping) => {
    trackMapping(element, uniqueMapping);

    if (!element?.isUpdated) {
        let pgoiDbEle = getDbPgoiData(element, txPlanDbData);
        if (pgoiDbEle) {
            element.formAddressed = pgoiDbEle?.isAddressed;
            element.formStatus = pgoiDbEle?.status;
            element.formComments = pgoiDbEle?.comment;
        }
    }
}

export const sortFunction = (elements, sortBy) => {
    return elements.sort((a, b) => {
        const orderA = parseFloat(a[sortBy]);
        const orderB = parseFloat(b[sortBy]);

        if (isNaN(orderA) && isNaN(orderB)) {
            return a.name.localeCompare(b.name);
        }

        if (sortBy === 'priorityMappedValue') {
            if (a[sortBy] === "" &&  b[sortBy] === ""){
                return new Date(a?.audit?.createdDate) - new Date(b?.audit?.createdDate);
            }
        }

        return isNaN(orderA) ? 1 : isNaN(orderB) ? -1 : orderA - orderB;
    });
};
   
export const getSatusOptionsFun = (setStatusOptions, document, user) => {
    const organizationId = user?.currentOrganizationId;
    goalAddressedService.getDescriptorsForStatus(organizationId).then(result => {
        const sortedResults = sortFunction(result, 'order');
        const filteredResults = sortedResults.filter(result => (result?.endDate === '' || result?.endDate > document?.serviceDate));
        setStatusOptions(filteredResults);
    }).catch(error => {
        console.log('Error: ', error);
    });
}

export const getShowHideRequired = (showHideObj) => {
    return showHideObj?.value;
}

export const expandCollapseItems = (data, isExpanded, call) => {
    data?.forEach((item) => {
        if(call === 'I')
        item.isIExpanded = isExpanded;
        else 
        item.isPExpanded = isExpanded;
    });
};
 
export const expandCollapse = (pgoMapData, pgoIntMapData, problems, interventions, expandIcon) => {
    if (expandIcon) {
        pgoMapData?.forEach((data) => {
            data.isGExpanded = true;
            data.isOExpanded = true;
        });
        expandCollapseItems(pgoIntMapData, true, 'I');
        expandCollapseItems(problems, true, 'P');
        expandCollapseItems(interventions, true, 'I');
    } else {
        pgoMapData?.forEach((data) => {
            data.isGExpanded = false;
            data.isOExpanded = false;
        });
        expandCollapseItems(pgoIntMapData, false, 'I');
        expandCollapseItems(problems, false, 'P');
        expandCollapseItems(interventions, false, 'I');
    }
};

export const initializePgoi = (module, treatmentPlanId) => {
    if (module && module?.pgoi && treatmentPlanId) {
        let isExists = module?.pgoi?.find(ele => ele?.treatmentPlanId === treatmentPlanId);
        if (!isExists) {
            module.pgoi.push({ treatmentPlanId });
        }
    }
}