import React from "react";
import EvvSelect from "../../../common/components/EvvSelect";
import FormField from "./FormField";
import StackedField from "../../../common/components/StackedField";
import DisplayErrorMessages from "../common/DisplayErrorMessages";
import { Grid } from "@material-ui/core";
import { getXSAndSMValueForField, sortElements } from '../common/Util';
import { findValueForDropdown } from "../common/DropDownFieldUtil";

class DropDown extends FormField {
    constructor(props) {
        super(props);
        
        let description = "";
        
        description = this.getDescription();
        let isRequired = this.isMandatory();
        props.fieldDescriptor = this.fieldDescriptor;

        this.state = {
            description: description,
            isRequired: isRequired,
            data: props
        };
    }

    getDescription = () => {
        let description = "";
        let typeOfDropDown = this.fieldDescriptor.type;
        if(typeOfDropDown === "DropDown" || typeOfDropDown === "Descriptor DropDown" || typeOfDropDown === "ActiveMedicationDropDown" ) {
            let filteredObj = this.fieldDescriptor.attributes.filter((attribute) => attribute.attributeId === this.fieldDescriptor.id +"_description");
            description = filteredObj[0].value;
        }
        return description;
    }

    renderField(props, state){
        let options;
        let breakPoints = getXSAndSMValueForField(props.isHandheld, props.orientation, 12, 6);

        if (this.fieldDescriptor.type === 'DropDown') {
            options = this.fieldDescriptor.options;
            sortElements(options, 'value', 'string');
        } else {
            options = state.dbOptions[this.fieldDescriptor.formId + "-" + this.fieldDescriptor.id];
        }

        return (
            <div>
                { this.fieldDescriptor?.businessRuleApplied !== "Hide" &&
                <StackedField paddingTop = "0px">
                    <span>{ this.state.description }</span>
                    <div>
                        <Grid container item = { true } xs = { breakPoints.xsValue } sm = { breakPoints.smValue }>
                            <EvvSelect
                                { ...props }
                                id = { 'dropDown-' + this.fieldDescriptor.id }
                                value = { findValueForDropdown(this.fieldDescriptor) || state[this.fieldDescriptor.selectedValue] }
                                required = { this.state.isRequired }
                                onChange = { (event) => { props.onChange(event, this.fieldDescriptor, props); } }
                                disabled = { state.isReadOnlyDoc }
                                >
                                {state.isReadOnlyDoc === true ? <option selectedValue= ''></option>: <option value = ''>Select</option>}
                                {options?.length > 0 && 
                                    options?.map((item, index) =>
                                    <option key = { `option-${index}` } value = { item.value } >{ item.value }</option>
                                )}
                            </EvvSelect>
                        </Grid>
                    </div>
                    <div>
                        <DisplayErrorMessages
                            { ...this.state.data }
                        />
                    </div>
                </StackedField>
                }
            </div>
        )
    }
}

export default DropDown;