// const asyncOperationDescriptor = {
//     stateName: 'clientsState',
//     resultsTransformer: transformerFunction,
//     reducerNames: {
//         startHandler: 'fetchClientsStart',
//         successHandler: 'fetchClientsSuccess',
//         errorHandler: 'fetchClientsError'
//     }
// };

export const ASYNC_OPERATION_STATUS = {
    INITIAL: 0,
    START: 1,
    SUCCESS: 2,
    ERROR: 3
};

export const createAsyncOperation = (descriptor) => {
    return {
        stateName: descriptor.stateName,
        state: {
            status: ASYNC_OPERATION_STATUS.INITIAL,
            results: null,
            error: null
        },
        reducers: {
            [descriptor.reducerNames.startHandler]: (state) => {
                state[descriptor.stateName].status = ASYNC_OPERATION_STATUS.START;
            },
            [descriptor.reducerNames.successHandler]: (state, action) => {
                state[descriptor.stateName].status = ASYNC_OPERATION_STATUS.SUCCESS;
                if (descriptor.resultsTransformer){
                    state[descriptor.stateName].results = descriptor.resultsTransformer(action.payload);
                } else {
                    state[descriptor.stateName].results = action.payload;
                }
            },
            [descriptor.reducerNames.errorHandler]: (state, action) => {
                state[descriptor.stateName].status = ASYNC_OPERATION_STATUS.ERROR;
                state[descriptor.stateName].error = action.payload;
            }
        }
    }
}

