import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Input from "@material-ui/core/Input";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    inputContainer: {
        width: '100%',
        height: theme.textFieldHeight,
        padding: '0px',
        borderRadius: '4px',
        backgroundColor: "#ffffff",
        borderStyle: "solid",
        borderWidth: '1px',
        borderColor: "#aaa9a9",
        bottomBorder: "none"
    },
    inputContainerDisabled: {
        backgroundColor:'#e0e0e0'
    },
    input: {
        width: '100%',
        height: '100%',
        outline: 'none',
        paddingLeft: '10.5px',
        paddingTop: '10.5px',
        paddingBottom: '9.625px',
        borderStyle: "none",
        borderWidth: 0,
        borderColor: "#ffffff",
        fontSize: '16px',
        fontWeight: "normal",
        fontStyle: "normal",
        letterSpacing: '.21px',
        caretColor: '#888888',
        '&:disabled':{
            backgroundColor:'#e0e0e0',
            color: "#4f4f4f"
        },
        '&::placeholder': {
            color: "#888888",
        }
    },
    maskIcon: {
        width: '33px',
        height: '22.5px', 
    },
    unMaskIcon: {
        width: '33px',
        height: '28.5px', 
    },
}));

const InputForPassword = ({id, value, placeholder, onChange, classes}) => {

    const styles = useStyles();

	const [values, setValues] = React.useState({
		showPassword: false,
	});

	const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword });
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	return (
		<div className={styles.inputContainer}>
			<Input
				data-testid={id}
				id={id}
                disableUnderline={true}
				className={styles.input}
                placeholder={placeholder}
				type={values.showPassword ? "text" : "password"}
				onChange = {onChange}
				value={value}
				endAdornment={
					<InputAdornment position="end">
						<IconButton
                            className={values.showPassword ? styles.unMaskIcon: styles.maskIcon }
                            data-testid={`maskUnMaskIcon-${id}`}
                            id="maskUnMaskIcon"
							onClick={handleClickShowPassword}
							onMouseDown={handleMouseDownPassword}
						>
							{values.showPassword ?<Visibility /> : <VisibilityOff />}
						</IconButton>
					</InputAdornment>
				}
			/>
		</div>
	);
};

export default InputForPassword;
