import {makeStyles} from "@material-ui/core/styles";
import {useSelector} from "react-redux";
import DataTable from "../../../common/components/DataTable";
import {sortWithDirection, uniqueScalarsByProperty} from "../../../common/utils/miscUtils";
import FilterBar from "../../../common/components/FilterBar";
import {useState, useEffect} from "react";
import {formatDate, getOrderByProperty} from "../../../common/utils/tableUtils";
import CircularProgress from "@material-ui/core/CircularProgress";
import syncService from "../../../common/services/syncService";
import {clientCache} from "../../../cache/slices/client/clientSlice";
import {appCache} from "../../../cache/slices/app/appSlice";
import { PRIMARY_COLOR } from "../../../common/constants"

const useStyles = makeStyles(() => ({
    allergiesTab: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column'
    },
    allergiesHeader: {
        width: '100%',
        display: 'flex',
        borderBottom: '1px solid #c1c1c1'
    },
    loadingSpinnerContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    loadingSpinner: {
        color: PRIMARY_COLOR
    },
    noAllergiesFoundContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    noAllergiesFound: {
        fontSize: '18px',
        fontWeight: "bold",
        fontStyle: "normal",
        color: "#000000",
        paddingTop: '56px'
    }
}));

const EMPTY_TYPE_FILTER = 'Show All';

const columnDescriptors = [
    { id: 'allergen', bold: true, sort:"asc", fieldName: 'allergyName', label: 'Allergen'},
    { id: 'reaction', fieldName: 'allergyReaction', label: 'Reaction' },
    { id: 'type', fieldName: 'allergyType', label: 'Type' },
    { id: 'beginDate', fieldName: 'beginDate', label: 'Begin Date', formatter: formatDate },
    { id: 'endDate', fieldName: 'endDate', label: 'End Date', formatter: formatDate },
];

function createFilterOptions(allergies){
    return [EMPTY_TYPE_FILTER, ...uniqueScalarsByProperty(allergies, 'allergyType').sort()];
}

export default function AllergiesTab() {
    const allergies = useSelector(syncService.allergyService.getResults());
    const isLoading = useSelector(syncService.allergyService.isLoading());
    const [filteredAllergies, setFilteredAllergies] = useState(allergies);
    const styles = useStyles();
    const client = useSelector(clientCache.getSelectedClient);
    const user = useSelector(appCache.getUser);

    useEffect(() => {
        if(allergies && allergies.length > 0) {
            syncService.auditService.saveAuditEvent(null, client, user, "EVV Client Allergies", "View");
        }
    }, [allergies, client, user]);

    const handleFilterChange = (type) => {
        if (allergies && allergies.length > 0) {
            const filtered = type === EMPTY_TYPE_FILTER ? [...allergies] : allergies.filter((allergy) => allergy.allergyType === type);
            setFilteredAllergies(filtered);
        } else {
            setFilteredAllergies([]);
        }
    }

    const handleSort = (records, columnDescriptor, orderByDirection) => {
        const alwaysFirst = records.filter(allergy => allergy.allergen === '');
        const recordsToSort = records.filter(allergy => allergy.allergen !== '');

        return [
            ...alwaysFirst,
            ...sortWithDirection(
                recordsToSort,
                [getOrderByProperty(columnDescriptor), (a) => a.beginDate ? a.beginDate.toMillis() : 0],
                [orderByDirection, 'desc']
            )];
    };

    const shouldSpanColumns = (row) => {
        if (row.nka === 'Y'){
            return 'No Known Allergies';
        } else if (row.nkda === 'Y'){
            return 'No Known Drug Allergies';
        }

        return null;
    }

    const renderPage = () => {
        if (isLoading) {
            return (
                <div className={styles.loadingSpinnerContainer}>
                    <CircularProgress size={50} className={styles.loadingSpinner} />
                </div>
            );
        } else {
            return (
                <>
                    <div className={styles.allergiesHeader}>
                        <FilterBar
                            filterByLabel='Filter By Type'
                            options={createFilterOptions(allergies)}
                            onFilterChange={handleFilterChange}
                        />
                    </div>
                    <DataTable
                        columnDescriptors={columnDescriptors}
                        records={filteredAllergies}
                        handleSort={handleSort}
                        shouldSpanColumns={shouldSpanColumns} />

                    {(!allergies || allergies.length === 0) &&
                    <div className={styles.noAllergiesFoundContainer}>
                        <div className={styles.noAllergiesFound} >
                            No Allergies Found
                        </div>
                    </div>
                    }
                </>
            );
        }
    }

    return (
        <div className={styles.allergiesTab}>
            {renderPage()}
        </div>
    );
}
