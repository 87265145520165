import {createSlice} from '@reduxjs/toolkit'
import {ASYNC_OPERATION_STATUS} from "../../asyncHandler";

class LookupCache {
    constructor(stateName, primaryKey, syncService) {
        this.stateName = stateName;
        this.primaryKey = primaryKey;
        this.syncService = syncService;
        this.slice = createSlice({
            name: stateName,
            initialState: this.createInitialState(),
            reducers: this.createReducers()
        });

        this.actions = {
            ...this.slice.actions,
            ...this.createActions()
        }
    };

    createInitialState = () => {
        return {
            status: ASYNC_OPERATION_STATUS.INITIAL,
            results: []
        };
    }

    createReducers = () => {
        return {
            operationStart: (state) => {
                state.status = ASYNC_OPERATION_STATUS.START;
            },
            operationSuccess: (state, action) => {
                state.status = ASYNC_OPERATION_STATUS.SUCCESS;
                state.results = action.payload;
            },
            operationError: (state, action) => {
                state.status = ASYNC_OPERATION_STATUS.ERROR;
                state.error = action.payload;
            },
            operationAdd: (state, action) => {
                if (state.results) {
                    state.results.push(action.payload);
                } else {
                    state.results = [action.payload];
                }
            },
            operationReplace: (state, action) => {
                if (this.primaryKey && state.results) {
                    let found = false;
                    for (let index = 0; index < state.results.length; index++){
                        if (state.results[index][this.primaryKey] === action.payload[this.primaryKey]){
                            state.results[index] = action.payload;
                            found = true;
                            break;
                        }
                    }

                    if (!found){
                        state.results.push(action.payload);
                    }
                }
            },
            operationReplaceIfExists: (state, action) => {
                if (this.primaryKey && state.results) {
                    for (let index = 0; index < state.results.length; index++){
                        if (state.results[index][this.primaryKey] === action.payload[this.primaryKey]){
                            state.results[index] = action.payload;
                            break;
                        }
                    }
                }
            },
            operationRemove: (state, action) => {
                if (this.primaryKey && state.results) {
                    for (let index = 0; index < state.results.length; index++) {
                        if (state.results[index][this.primaryKey] === action.payload[this.primaryKey]) {
                            state.results.splice(index, 1);
                            break;
                        }
                    }
                }
            },
            applyGlobalFilter: (state, action) => {
                state.globalFilterByName = action.payload.filterByName;
                state.globalFilterByValue = action.payload.filterByValue;

                if (!state.unfilteredResults){
                    state.unfilteredResults = state.results;
                }

                state.results = state.unfilteredResults.filter(record => record[state.globalFilterByName] === state.globalFilterByValue);
            },
            filterByOrganization: (state, action) => {
                if (!state.unfilteredResults){
                    state.unfilteredResults = state.results;
                }
                state.results = this.syncService.organizationService.getRecordsForOrganization(
                    state.unfilteredResults,
                    action.payload.organizationId,
                    !(action.payload.includeDescendants === false),
                    action.payload.includeAncestors === true,
                    action.payload.isArrayProperty === true
                );
            },
        };
    }

    createActions = () => {
        return {
            isLoading: (state) => state.sync[this.stateName].status === ASYNC_OPERATION_STATUS.START,
            getStatus: (state) => state.sync[this.stateName].status,
            getResults: (state) => state.sync[this.stateName].results,
            getError: (state) => state.sync[this.stateName].error,
            filterResults: (state, filterByName, filterByValue) => {
                return this.actions.getResults(state).filter(record => record[filterByName] === filterByValue);
            }
        }
    }

    isLoading = () => {
        return this.actions.isLoading;
    };

    getStatus = () => {
        return this.actions.getStatus;
    };

    getResults = () => {
        return this.actions.getResults;
    };

    filterResults = () => {
        return this.actions.filterResults;
    };

    getError = () => {
        return this.actions.getError;
    };
}

export default LookupCache;
