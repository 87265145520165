import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import syncService from "../../../common/services/syncService";
import {ASYNC_OPERATION_STATUS} from "../../../cache/asyncHandler";

// Component with no UI to coordinate filtering by organization after certain caches have been loaded
export default function OrganizationInitializer({user, onInitialized}){
    const organizationsLoadingStatus = useSelector(syncService.organizationService.getStatus());
    const appointmentsLoadingStatus = useSelector(syncService.appointmentService.getStatus());
    const serviceDocumentsLoadingStatus = useSelector(syncService.serviceDocumentService.getStatus());
    const [initialized, setInitialized] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!initialized){
            if (organizationsLoadingStatus > ASYNC_OPERATION_STATUS.START &&
                appointmentsLoadingStatus > ASYNC_OPERATION_STATUS.START &&
                serviceDocumentsLoadingStatus > ASYNC_OPERATION_STATUS.START){
                setInitialized(true);
                syncService.filterByOrganization(dispatch, user.currentOrganizationId);
                if (onInitialized){
                    onInitialized();
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationsLoadingStatus, appointmentsLoadingStatus, serviceDocumentsLoadingStatus]);

    return null;
}
