import {makeStyles} from "@material-ui/core/styles";
import {Menu, MenuItem} from "@material-ui/core";
import {useState} from "react";
import MoreVert from "@mui/icons-material/MoreVert";
import AlertDialog from '../../../common/components/AlertDialog';
import syncService from "../../../common/services/syncService";
import { useStore, useDispatch, useSelector } from "react-redux";
import {documentCache} from "../../../cache/slices/document/documentCache";
import { cloneDeep } from "lodash/lang";
import { MAX_OPEN_DOCUMENTS, PDF_ONLINE_ERROR } from "../../../common/constants";
import { tabDocEditAction, hasMaxDocsOpened, checkIfDocExistsInTab, chekDocPdf } from "../../../common/utils/documentUtils";

const useStyles = makeStyles((theme) => ({
    kebabCell: {
        display: "flex",
    },
    kebabImageContainer: {
    },
    kebabImage: {
        color: '#424242',
        width: '20px',
        height: '30px'
    },
    menuItemRoot: {
        padding: '0px',
        marginLeft: '16px',
        marginRight: '16px',
        "&:not(:last-child)": {
            borderBottom: '2px solid #979797'
        },
    },
    menuItem: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        paddingTop: '14px',
        paddingBottom: '14px',
    },
    imageContainer: {
        width: '24px',
        height: '24px'
    },
    image: {
        height: '100%',
        width: '100%'
    },
    menuItemLabel: {
        fontSize: '14px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: -0.06,
        color: "#434041",
        paddingLeft: '18px'
    }
}));

const menuStyle = {
    width: '175px',
    backgroundColor: "#f8f8f8",
    borderStyle: "solid",
    borderWidth: '1px',
    borderColor: "#979797",
    zIndex: 1200
};

export default function KebabCell({clientDocument, onKebabClick, setClickedRowFn, clickedRow, setDocumentForWizard}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [handleDelete, setHandleDelete] = useState(false);
    const [alertDialogConfig, setAlertDialogConfig] = useState(null);
    const [handleDeleteTabDoc, setHandleDeleteTabDoc] = useState(false);
    const styles = useStyles();
    const store = useStore();
    const dispatch = useDispatch();
    const serviceDocTab = cloneDeep(useSelector(documentCache.getServiceDocumentsForTabs));

    const handleClick = (event) => {
        setClickedRowFn(clientDocument);

        if (anchorEl){
            setAnchorEl(null);
        } else {
            setAnchorEl(event.currentTarget);
        }

        if (onKebabClick){
            onKebabClick(clientDocument);
        }
    };

    const closeContextMenu = () => {
        setAnchorEl(null);
    }

    const handleAlertDialogClose = () => {
        setAlertDialogConfig(null);
    }

    const handleDeleteTabDialogClose = (isOk) => {
        setHandleDeleteTabDoc(false);
        if (isOk) {
            syncService.documentService.deleteDocument(clickedRow, {store, doNotClear: true});
            if (serviceDocTab) {
                const removeDoc = serviceDocTab.filter((item) => item.id !== clickedRow.id);
                dispatch(documentCache.setServiceDocumentsForTabs(removeDoc));
            }
        }
    }

    const processPdfDoc = async (clickedRow) => {
        let savedDocument = await syncService.documentService.findDocumentById(clickedRow.id);
        let docPdfStatus = await chekDocPdf(savedDocument);

        if (docPdfStatus.error) {
            let pdfErrorDialogContent = {...PDF_ONLINE_ERROR, dialogMessage: docPdfStatus.errorMsg, dialogTitle: docPdfStatus.errorTitle};
            setAlertDialogConfig(pdfErrorDialogContent);
        } else {
            savedDocument = { ...savedDocument, pdfUrl: docPdfStatus.pdfUrl }
            setDocumentForWizard(savedDocument);
            dispatch(documentCache.setCurrentAptDocument([savedDocument]));
            tabDocEditAction(serviceDocTab, savedDocument, dispatch);
        }
    }

    const handleEditViewDocument = async() => {
        setAnchorEl(null);
    	if (!checkIfDocExistsInTab(serviceDocTab, clickedRow) && hasMaxDocsOpened(serviceDocTab, clientDocument.clientId)) {
            setAlertDialogConfig(MAX_OPEN_DOCUMENTS);
        } else if (clickedRow.status !== "PDF") {
            const aptDocCollection = await syncService.documentService.getServiceDoc(clickedRow.serviceDocumentId);
            if (aptDocCollection && aptDocCollection.length > 0) {
                const savedDocument = await syncService.documentService.findDocumentById(clickedRow.id);
                setDocumentForWizard(savedDocument);
                dispatch(documentCache.setCrosswalkedDocuments(aptDocCollection));
                dispatch(documentCache.setCurrentDocument(aptDocCollection[0]));
                dispatch(documentCache.setCurrentData({}));
                dispatch(documentCache.setCurrentAptDocument([savedDocument]));
                tabDocEditAction(serviceDocTab, savedDocument, dispatch);
            }
        } else {
            await processPdfDoc(clickedRow);
        }
    }

    const handleDeleteDocument = () => {
        setAnchorEl(null);
        if (checkIfDocExistsInTab(serviceDocTab, clickedRow)) {
            setHandleDeleteTabDoc(true);
        } else {
            setHandleDelete(true);
        }
    }

    const menuItems = [
        {
            id: 'EDIT_DOCUMENT',
            label: 'Edit',
            image: `${process.env.PUBLIC_URL}/images/iconsEdit.svg`,
            statuses: ['Complete', 'Incomplete', 'Unsigned'],
            clickHandler: handleEditViewDocument
        },
        {
            id: 'DELET_DOCUMENT',
            label: 'Delete',
            image: `${process.env.PUBLIC_URL}/images/iconsDelete.svg`,
            statuses: ['Incomplete', 'Unsigned'],
            clickHandler: handleDeleteDocument
        },
        {
            id: 'VIEW_DOCUMENT',
            label: 'View',
            image: `${process.env.PUBLIC_URL}/images/iconsView.svg`,
            statuses: ['Signed', 'PDF'],
            clickHandler: handleEditViewDocument
        }
    ];

    const getVisibleMenuItems = () => {
        return menuItems.filter(item => item.statuses.includes(clientDocument.status));
    }

    const renderMenuItemContent = (menuItem) => {
        return (
            <div className={styles.menuItem} onClick={menuItem.clickHandler ? menuItem.clickHandler : () => alert(menuItem.label)}>
                <div className={styles.imageContainer}>
                    <img className={styles.image} src={menuItem.image} alt={menuItem.label} />
                </div>
                <div className={styles.menuItemLabel}>{menuItem.label}</div>
            </div>
        );
    };

    const renderMenuItem = (menuItem) => {
        const menuItemContent = renderMenuItemContent(menuItem);

        return menuItemContent
            ? (
                <MenuItem key={menuItem.id} className={styles.menuItemRoot} onClick={closeContextMenu}>
                    {menuItemContent}
                </MenuItem>
            )
            : null;
    };

    const handleDeleteDialogClose = (isOk) => {
        setHandleDelete(false);
        if (isOk) {
            syncService.documentService.deleteDocument(clickedRow, {store, doNotClear: true})
            dispatch(documentCache.setAttachedDoc([]));
        }
    }

    return (
        <div data-testid='KebabCell' className={styles.kebabCell}>
            <div className={styles.kebabImageContainer}>
                <MoreVert data-testid='KebabCell_kebab_button' className={styles.kebabImage} onClick={handleClick}/>
            </div>
            {anchorEl &&
            <Menu
                id="DocumentKebabMenu"
                anchorEl={anchorEl}
                keepMounted={false}
                open={true}
                onClose={closeContextMenu}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                PaperProps={{
                    style: menuStyle
                }}
            >
                {getVisibleMenuItems().map((menuItem) => renderMenuItem(menuItem))}
            </Menu>
            }

            { handleDelete &&
                <AlertDialog
                open = { true }
                dialogTitle = 'Confirm Delete'
                dialogMessage = 'Are you sure you want to delete this document?'
                showOkButton = { true }
                okButtonText = 'Ok'
                showCancelButton = { true }
                cancelButtonText = 'Cancel'
                handleClose = { handleDeleteDialogClose }
            />
            }

            { alertDialogConfig &&
                <AlertDialog
                    open={true}
                    dialogTitle={alertDialogConfig.dialogTitle}
                    dialogMessage={alertDialogConfig.dialogMessage}
                    showOkButton={alertDialogConfig.showOkButton}
                    okButtonText={alertDialogConfig.okButtonText ? alertDialogConfig.okButtonText : 'Ok'}
                    showCancelButton={alertDialogConfig.showCancelButton}
                    handleClose={handleAlertDialogClose}
                />
            }

            { handleDeleteTabDoc &&
                <AlertDialog
                open = { true }
                dialogTitle = 'Delete Open Document'
                dialogMessage = 'This document is open in another tab. Selecting OK will close the tab and delete the document. Are you sure you want to proceed?'
                showOkButton = { true }
                okButtonText = 'Ok'
                showCancelButton = { true }
                cancelButtonText = 'Cancel'
                handleClose = { handleDeleteTabDialogClose }
            />
            }
        </div>
    );
}
