import {evvRepository} from './evv';

const loadClients = () => {
   return evvRepository.evvDb.client.toArray();
}

const loadClient = (clientId) => {
    return evvRepository.evvDb.client.toArray()
        // eslint-disable-next-line eqeqeq
        .then(clients => clients.find(c => c.clientId == clientId));
}

const clearAndSaveClients = (clients) => {
    const allClients = [...clients];

    return evvRepository.evvDb.client.clear()
        .then((x) => {
            console.log("client table cleared - inserting clients: ");
            console.log(allClients);
            return evvRepository.evvDb.client.bulkPut(allClients)
                .then((lastKey) => {
                    console.log("client table populated - last key inserted: ");
                    return allClients;
                });
        });
}

const saveClients = (clients) => {
    console.log("save clients - do not clear first: ");
    console.log(clients);
    if (clients && clients.length > 0){
        return evvRepository.evvDb.client.bulkPut(clients)
            .then(() => {
                console.log("client table populated");
                return loadClients();
            });
    } else {
        return loadClients();
    }
}

const saveClient = (client) => {
    return evvRepository.evvDb.client.put(client);
}

const updateRecentClient = (client, lastVisitedMillis) => {
    return evvRepository.evvDb.recentClient.put({clientId: client.clientId, lastVisitedMillis})
}

const loadRecentClients = () => {
    return evvRepository.evvDb.recentClient.orderBy('lastVisitedMillis').reverse().limit(4).toArray();
}

const clientRepository = {
    loadClients,
    loadClient,
    clearAndSaveClients,
    saveClients,
    saveClient,
    updateRecentClient,
    loadRecentClients
};

export default clientRepository;
