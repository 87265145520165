import {makeStyles} from "@material-ui/core/styles";
import {
    formatBirthDate, formatClientId,
    formatGender,
    formatUserName,
    getClientEmail,
    getPhoneInfo
} from "../../../common/utils/formatUtils";
import {showEmailApplication, showPhoneApplication} from "../../../common/utils/systemUtils";
import {Tooltip} from "@material-ui/core";
import {getClientImage} from "../../../common/utils/imageUtils";
import {clientCache} from "../../../cache/slices/client/clientSlice";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import clientService from "../../../common/services/clientService";
import {DateTime} from "luxon";
import syncService from "../../../common/services/syncService";
import { FILTER_COLOR, PRIMARY_COLOR, QUATERNARY_COLOR } from "../../../common/constants";

const wrapText = {
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
    whiteSpace: 'nowrap'
};

const useStyles = makeStyles(() => ({
    recentClient: {
        display: 'flex',
        flexDirection: 'column'
    },
    facesheetTitle: {
        fontSize: '21px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: PRIMARY_COLOR,
        paddingBottom: '6px'
    },
    clientSnapshotColumn: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRight: '1px solid #e4e4e4'
        // alignItems: 'center'
    },
    clientSnapshotColumnOne: {
        width: '100%',
        display: 'flex',
        alignItems: 'center'
    },
    imageColumn: {
        alignSelf: 'start',
        paddingRight: '16px'
    },
    image: {
        height: '74px',
        width: '74px',
        borderRadius: '50%'
    },
    clientColumn: {
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'hidden',
        width: '17vw'
    },
    clientNameRow: {
        display: 'flex',
        paddingBottom: '6px'
    },
    clientNameLabel: {
        ...wrapText,
        fontSize: '20px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: PRIMARY_COLOR
    },
    viewFacesheetImageColumn: {
        paddingLeft: '2px',
        filter: FILTER_COLOR
    },
    viewFacesheetImage: {
        width: '24px',
        height: '24px',
    },
    clientRow: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: '6px'
    },
    addressRow: {
        display: 'flex',
        alignItems: 'start',
        paddingBottom: '6px'
    },
    addressItemsGrid: {
        width: '100%',
        display: 'grid',
        rowGap: '1px',
        gridTemplateColumns: '1fr',
        alignItems: 'flex-start',
        paddingTop: '2px'
    },
    clientSnapshotColumnThree: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '20px',
        paddingRight: '13px'
    },
    diagnosisRow: {
        width: '100%',
        display: 'flex',
        paddingTop: '9px'
    },
    detailLabel: {
        fontSize: '14px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: "#000000"
    },
    regularDetailValue: {
        fontSize: '14px',
        fontWeight: "normal",
        fontStyle: "normal",
        letterSpacing: 0,
        color: "#000000",
        paddingLeft: '4px',
        paddingRight: '4px'
    },
    emphasizedDetailValue: {
        textOverflow: 'ellipsis',
        overflowX: 'hidden',
        whiteSpace: 'nowrap',
        fontSize: '14px',
        fontWeight: "normal",
        fontStyle: "normal",
        color: QUATERNARY_COLOR,
        paddingLeft: '4px',
        paddingRight: '4px'
    },
    diagnosisValue: {
        fontSize: '14px',
        fontWeight: "normal",
        fontStyle: "normal",
        color: QUATERNARY_COLOR,
        display: '-webkit-box',
        maxWidth: '200px',
        '-webkit-line-clamp': 4,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        paddingLeft: '4px'
    },
    checked: {
        "&:after": {
            content: "'\\2713'",
            display: "inline-block",
            fontSize: '16px',
            fontWeight: "bold",
            fontStyle: "normal",
            letterSpacing: 0,
            color: "#006c5A",
            paddingLeft: '10px'
        }
    },
    textLinkFont: {
        color: QUATERNARY_COLOR,
        fontSize: '14px',
        fontWeight: "normal",
        fontStyle: "normal",
        letterSpacing: 0,
        paddingLeft: '4px',
        paddingRight: '4px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    }
}));

export default function RecentClient({client}) {
    const phoneTypes = useSelector(syncService.descriptorCacheService.phoneContactType.getResults());
    const dispatch = useDispatch();
    const history = useHistory();
    const styles = useStyles();

    const handleClientClick = () => {
        clientService.visitClient(client, DateTime.now().toMillis());
        dispatch(clientCache.selectClient(client));
        history.push('/dashboard/facesheet');
    }

    const phoneInfo = getPhoneInfo(client?.phones, phoneTypes);

    return (
        <div className={styles.recentClient}>
            <div className={styles.facesheetTitle}>Facesheet</div>
            <div >
                <div className={styles.clientSnapshotColumnOne} >
                    <div data-testid='RecentClient_clientImage' className={styles.imageColumn} onClick={handleClientClick}>
                        <img className={styles.image} src={getClientImage(client)} alt='client' />
                    </div>
                    <div className={styles.clientColumn}>
                        <div className={styles.clientNameRow} onClick={handleClientClick}>
                            <div className={styles.clientNameLabel}>
                                {formatUserName(client)}
                            </div>
                            <div className={styles.viewFacesheetImageColumn} onClick={handleClientClick}>
                                <img className={styles.viewFacesheetImage} src='images/iconIcoViewFacesheet.png' alt='view facesheet' />
                            </div>
                        </div>
                        <div className={styles.clientRow}>
                            <span className={styles.detailLabel}>ID:</span>
                            <span className={styles.regularDetailValue}>{formatClientId(client)}</span>
                        </div>
                        <div className={styles.clientRow}>
                            <div className={styles.detailLabel}>Gender:</div>
                            <div className={styles.regularDetailValue}>{formatGender(client.gender)}</div>
                        </div>
                        <div className={styles.clientRow}>
                            <div className={styles.detailLabel}>DOB:</div>
                            {client.birthDate && <div className={styles.regularDetailValue}>{formatBirthDate(client.birthDate)}</div>}
                            {client.birthDate && <div className={styles.detailLabel}>Age:</div>}
                            {client.birthDate && <div className={styles.regularDetailValue}>{client.age}</div>}
                        </div>
                        {phoneInfo.primaryPhoneNumber &&
                        <div className={styles.clientRow}>
                            <div className={styles.detailLabel}>{phoneInfo.primaryLabel}</div>
                            <Tooltip title={'Ok to ID'} placement="top-start">
                                <div data-testid='RecentClient_primaryPhone'
                                    style = {{marginTop:'-4px'}}
                                    className={styles.textLinkFont + (phoneInfo.primaryChecked ? ' ' + styles.checked : '')}
                                    onClick={() => showPhoneApplication(phoneInfo.primaryPhoneNumber)}>
                                    {phoneInfo.primaryPhoneNumber}
                                </div>
                            </Tooltip>
                        </div>
                        }
                        {phoneInfo.secondaryPhoneNumber &&
                        <div className={styles.clientRow}>
                            <div className={styles.detailLabel}>{phoneInfo.secondaryLabel}</div>
                            <div data-testid='RecentClient_secondaryPhone' className={styles.textLinkFont + (phoneInfo.secondaryChecked ? ' ' + styles.checked : '')}
                                style = {{marginTop:'-2px'}} 
                                onClick={() => showPhoneApplication(phoneInfo.secondaryPhoneNumber)}>
                                {phoneInfo.secondaryPhoneNumber}
                            </div>
                        </div>
                        }
                        <div className={styles.clientRow}>
                            <div className={styles.detailLabel}>Email:</div>
                            <div data-testid='RecentClient_email' className={styles.emphasizedDetailValue}  onClick={() => showEmailApplication(getClientEmail(client.emails))}>{getClientEmail(client.emails)}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
