import * as constData from "../../../common/constants";
import { getFieldValueToCompare } from '../common/BusinessRuleUtils';
import { getDescriptorList } from "./DropDownFieldUtil";
import { renderLookUpField } from "./FormFieldsUtils";

export const checkExactlyMatch = (fieldValueDataType, fieldValueToCompare, comparisonValue) => {
    let isConditionPassed = false;
    if ( (constData.IS_ARR_OBJ.includes(fieldValueDataType) && fieldValueToCompare.includes(comparisonValue)) || (fieldValueDataType === 'string' && comparisonValue === fieldValueToCompare) ) {
        isConditionPassed = true;
    }
    return isConditionPassed;
}

export const checkDoesNotMatch = (fieldValueDataType, fieldValueToCompare, comparisonValue) => {
    let isConditionPassed = false;
    if ( (constData.IS_ARR_OBJ.includes(fieldValueDataType) && !fieldValueToCompare.includes(comparisonValue)) || (fieldValueDataType === 'string' && comparisonValue !== fieldValueToCompare) ) {
        isConditionPassed = true;
    }
    return isConditionPassed;
}

export const checkIsNotNull = (fieldValueDataType, fieldValueToCompare) => {
    let isConditionPassed = false;
    if ( (constData.IS_ARR_OBJ.includes(fieldValueDataType) && fieldValueToCompare.length > 0) || (fieldValueDataType === 'string' && fieldValueToCompare !== '') ) {
        isConditionPassed = true;
    }
    return isConditionPassed;
}

export const checkIsNull = (fieldValueDataType, fieldValueToCompare) => {
    let isConditionPassed = false;
    if ( (constData.IS_ARR_OBJ.includes(fieldValueDataType) && fieldValueToCompare.filter(value => value !== '').length === 0) || (fieldValueDataType === 'string' && fieldValueToCompare === '') ) {
        isConditionPassed = true;
    }
    return isConditionPassed;
}

export const getDescriptorTableData = async (orgId) => {
    let descriptorList = await getDescriptorList(orgId);
    if (descriptorList.length > 0) {
        return descriptorList;
    }
    return [];
}

export const checkLike = (fieldToCheck, fieldValueDataType, fieldValueToCompare, comparisonValue, selectedClient, descriptorData) => {
    // Like Condition can only apply:  1. Text Field (only for the datatype = ”NONE”, “AL”, “AL+SP”)
    // 2. Text Area (all datatype)  3.LookUp*
    let isConditionPassed = false;
    switch (fieldToCheck.type) {
        case 'Text Field':
            let datatypeobj = fieldToCheck.attributes.find((attribute) => attribute.attributeId === fieldToCheck.id +"_datatype");
            let datatype = datatypeobj.value;
            if(datatype !== "NUM") {
                isConditionPassed = likeConditionPassed(fieldValueDataType, fieldValueToCompare, comparisonValue);
            }
        break;

        case 'Text Area':
            isConditionPassed = likeConditionPassed(fieldValueDataType, fieldValueToCompare, comparisonValue);
        break;

        case 'LookUp':
            let columnObj = fieldToCheck.attributes.find( (attribute) => attribute.attributeId === fieldToCheck.id +"_column" );
            let column = columnObj?.value;
            fieldValueToCompare = renderLookUpField(column, selectedClient, descriptorData)
            isConditionPassed = likeConditionPassed(fieldValueDataType, fieldValueToCompare, comparisonValue);
        break;

        default:
            break;
    }
    return isConditionPassed;
}

export const likeConditionPassed = (fieldValueDataType, fieldValueToCompare, comparisonValue) => {
    let isConditionPassed = false;

    // If the Condition Value does NOT have the '%' character, then the string value on the form must exactly match in order to execute the BR action.
    if(comparisonValue.includes('%')) {
        let comparisonValue2 = undefined;
        if(comparisonValue.includes('_')) {
            comparisonValue2 = comparisonValue.replace(/_/g,' ');
        }
        isConditionPassed = matchRuleShort(fieldValueToCompare, comparisonValue, comparisonValue2)
    } else {
        let value = comparisonValue;
        let value2 = undefined;
        // comparisonValue for “Like” has the underscore character ('_') within the string, then the system can also match it with a blank space
        if(value.includes('_')) {
            value2 = value.replace(/_/g, ' ');
        }

        if((constData.IS_ARR_OBJ.includes(fieldValueDataType) && fieldValueToCompare.includes(comparisonValue))
                || (fieldValueDataType === 'string' && (fieldValueToCompare === value || fieldValueToCompare === value2))) {
            isConditionPassed = true;
        }
    }

    return isConditionPassed;
}

export const matchRuleShort = (str, rule, rule2) => {
    let result = false;
    let wildcard = "";
    wildcard = rule.replace(/%/g, "*");
    if(rule2) {
        wildcard = rule2.replace(/%/g, "*");
        str = str.replace(/_/g, " ");
    }
    let w = wildcard.replace(/[.+^${}()|[\]\\]/g, '\\$&'); // regexp escape
    const re = new RegExp(`^${w.replace(/\*/g,'.*').replace(/\?/g,'.')}$`);
    result = re.test(str);
    return result;
}

export const checkArithmeticOps = (condition, comparisonValue, fieldValueToCompare) => {
    let isConditionPassed = false;

    if (fieldValueToCompare !== '') {
        fieldValueToCompare = Number(fieldValueToCompare);
        if (condition !== 'Between') {
            comparisonValue = Number(comparisonValue);
        }

        switch(condition) {
            case '>':
                if (fieldValueToCompare > comparisonValue) {
                    isConditionPassed = true;
                }
            break;

            case '<':
                if (fieldValueToCompare < comparisonValue) {
                    isConditionPassed = true;
                }
            break;

            case '=':
                // eslint-disable-next-line
                if (fieldValueToCompare == comparisonValue) {
                    isConditionPassed = true;
                }
            break;

            case '>=':
                if (fieldValueToCompare >= comparisonValue) {
                    isConditionPassed = true;
                }
            break;

            case '<=':
                if (fieldValueToCompare <= comparisonValue) {
                    isConditionPassed = true;
                }
            break;

            default:
                // Between condition
                let comparisonValueArr = comparisonValue.split('/');
                if (fieldValueToCompare >= Number(comparisonValueArr[0]) && fieldValueToCompare <= Number(comparisonValueArr[1])) {
                    isConditionPassed = true;
                }
        }
    }

    return isConditionPassed;
}

export const checkArithmetic = (condition, fieldToCheck, fieldValueToCompare, comparisonValue, selectedClient) => {
    let isConditionPassed = false;
    const { type } = fieldToCheck;

    //Condition Operators for Lookup - Age and Text Field ("numeric" data type) values only
    if (type === "Text Field") {
        let datatypeObj = fieldToCheck.attributes.find((attribute) => attribute.attributeId === fieldToCheck.id +"_datatype");
        let datatype = datatypeObj.value;
        if (datatype === 'NUM') {
            isConditionPassed = checkArithmeticOps(condition, comparisonValue, fieldValueToCompare);
        }
    } else if (type === "LookUp") {
        let columnObj = fieldToCheck.attributes.find((attribute) => attribute.attributeId === fieldToCheck.id +"_column");
        let column = columnObj.value;
        if (column === 'AGE') {
            fieldValueToCompare = selectedClient['age'];
            isConditionPassed = checkArithmeticOps(condition, comparisonValue, fieldValueToCompare);
        }
    }

    return isConditionPassed;
}

export const checkFieldCondition = (condition, formFields, selectedClient, descriptorData) => {
    let isConditionPassed = false;

    let fieldToCheck = formFields.find((field) => condition.fieldId === field.id);    //Find the field to check condition on
    if (fieldToCheck) {
        let comparisonValue = condition.comparisonValue;
        let fieldValueToCompare = getFieldValueToCompare(fieldToCheck, selectedClient, descriptorData);   //Need this as we have different names used to store values for different fields
        let fieldValueDataType = typeof fieldValueToCompare;

        switch(condition.comparison) {
            case 'Exactly Matches':
                isConditionPassed = checkExactlyMatch(fieldValueDataType, fieldValueToCompare, comparisonValue);
            break;

            case 'Does Not Match':
                isConditionPassed = checkDoesNotMatch(fieldValueDataType, fieldValueToCompare, comparisonValue);
            break;

            case 'Is Not Null':
                isConditionPassed = checkIsNotNull(fieldValueDataType, fieldValueToCompare);
            break;

            case 'Like':
                if(comparisonValue) {
                    isConditionPassed = checkLike(fieldToCheck, fieldValueDataType, fieldValueToCompare, comparisonValue, selectedClient, descriptorData);
                }
            break;

            case 'Between':
            case '>':
            case '<':
            case '=':
            case '>=':
            case '<=':
                isConditionPassed = checkArithmetic(condition.comparison, fieldToCheck, fieldValueToCompare, comparisonValue, selectedClient);
            break;

            default:
                // Is Null Case
                isConditionPassed = checkIsNull(fieldValueDataType, fieldValueToCompare);
        }
    }
    
    return isConditionPassed;
}