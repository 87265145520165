import {makeStyles} from "@material-ui/core/styles";
import {formatClientId, formatUserName, getPhoneInfo} from "../../../common/utils/formatUtils";
import {useHistory} from "react-router-dom";
import {showMapApplication, showPhoneApplication} from "../../../common/utils/systemUtils";
import {clientCache} from "../../../cache/slices/client/clientSlice";
import {useDispatch, useSelector} from "react-redux";
import {getClientImage} from "../../../common/utils/imageUtils";
import clientService from "../../../common/services/clientService";
import {DateTime} from "luxon";
import syncService from "../../../common/services/syncService";
import { FILTER_COLOR, PRIMARY_COLOR } from "../../../common/constants";


const useStyles = makeStyles(() => ({
    caseloadCardContainer: {
        width: '100%',
        display: 'grid',
        rowGap: '14px',
        gridTemplateColumns: '48px 1fr 52px 38px', // 'minmax(12%,48px) 64% 12% 12%',
        backgroundColor: "#ffffff",
        borderBottom: '1px solid #d0d0d0',
        padding: '12px 0px 8px 16px'
    },
    imageColumn: {
        alignSelf: 'center'
    },
    image: {
        width: '48px',
        height: '48px',
        borderRadius: '50%'
    },
    clientColumn: {
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'column'
    },
    clientNameLabel: {
        fontSize: '16px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: PRIMARY_COLOR,
        paddingLeft: '11.6px',
        paddingBottom: '4px'
    },
    clientIdContainer: {
        paddingLeft: '12px'
    },
    clientIdLabel: {
        fontSize: '14px',
        fontWeight: "bold",
        fontStyle: "normal",
        lineHeight: '20px',
        letterSpacing: 0,
        color: "#4f4f4f"
    },
    clientIdValue: {
        fontSize: '14px',
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: '20px',
        letterSpacing: 0,
        color: "#4f4f4f",
        paddingLeft: '6px'
    },
    iconColumn: {
        alignSelf: 'center',
        filter: FILTER_COLOR
    }
}));

export default function CaseloadCard({client}) {
    const phoneTypes = useSelector(syncService.descriptorCacheService.phoneContactType.getResults());
    const dispatch = useDispatch();
    const history = useHistory();
    const styles = useStyles();

    const handleCaseloadClick = () => {
        clientService.visitClient(client, DateTime.now().toMillis());
        dispatch(clientCache.selectClient(client));
        history.push('/caseload/facesheet');
    }

    const handlePhoneClick = () => {
        const phoneInfo = getPhoneInfo(client.phones, phoneTypes);

        if (phoneInfo && phoneInfo.primaryPhoneNumber){
            showPhoneApplication(phoneInfo.primaryPhoneNumber);
        }
    }

    const handleMapClick = () => {
        showMapApplication(client);
    }

    return (
        <div className={styles.caseloadCardContainer}>
            <div data-testid='caseloadCard_clientImage' className={styles.imageColumn} onClick={handleCaseloadClick}>
                <img className={styles.image} src={getClientImage(client)} alt='client' />
            </div>
            <div data-testid='caseloadCard_clientName' className={styles.clientColumn} onClick={handleCaseloadClick}>
                <div className={styles.clientNameLabel}>
                    {formatUserName(client)}
                </div>
                <div className={styles.clientIdContainer}>
                    <span className={styles.clientIdLabel}>ID:</span>
                    <span className={styles.clientIdValue}>{formatClientId(client)}</span>
                </div>
            </div>
            <div data-testid='caseloadCard_phone' className={styles.iconColumn} onClick={handlePhoneClick}>
                <img src='../images/iconIcoCall@2x.png' alt='phone' />
            </div>
            <div data-testid='caseloadCard_map' className={styles.iconColumn} onClick={handleMapClick}>
                <img src='../images/iconIcoLocation@2x.png' alt='map' />
            </div>
        </div>
    )
}
