import {makeStyles} from "@material-ui/core/styles";
import {ClickAwayListener, Fade, IconButton, Popper} from "@material-ui/core";
import {useRef, useState} from "react";
import { PRIMARY_COLOR, QUATERNARY_COLOR } from "../../constants";

const useStyles = makeStyles((theme) => ({
    medicationInformationCell: {
        display: "flex",
    },
    textColumn: {
        display: "flex",
        flexDirection: 'column',
        paddingRight: '10px'
    },
    medicationDescription: {
        fontSize: '16px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: "#424242"
    },
    medicationInstructions: {
        fontSize: '14px',
        fontWeight: "normal",
        fontStyle: "normal",
        letterSpacing: 0,
        color: "#424242"
    },
    infoImage: {
        alignSelf: "center",
        display: 'flex',
    },
    infoTitle: {
        fontSize: '14px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: PRIMARY_COLOR,
        alignSelf: 'center',
        paddingLeft: '4px'
    },
    infoImageButton: {
        padding: "0px"
    },
    infoPopper: {
        ...theme.shadowedContainer,
        display: 'flex',
        flexDirection: 'column',
        width: '300px',
        height: '250px',
        borderRadius: '5px',
        backgroundColor: "#efefef",
        borderStyle: "solid",
        borderWidth: '1px',
        borderColor: "#979797",
        paddingLeft: '18px',
        paddingRight: '18px'
    },
    infoPopperTitle: {
        fontSize: '18px',
        fontWeight: "bold",
        fontStyle: "normal",
        // lineHeight: 9.4,
        // letterSpacing: -0.08,
        color: QUATERNARY_COLOR,
        paddingTop: '11px',
        paddingBottom: '5px',
        borderBottom: '1px solid #979797'
    },
    infoRow: {
        fontSize: '16px',
        fontWeight: "normal",
        fontStyle: "normal",
        // lineHeight: 10,
        // letterSpacing: -0.07,
        color: "#1c1c1c",
        paddingTop: '15px'
    },
    arrow: {
        display: 'none',
        position: 'absolute',
        fontSize: '7px',
        width: '3em',
        height: '3em',
        '&:before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid'
        }
    },
    // '&[x-placement*="right"] .arrow': {
    //     left: 0,
    //     width: 0,
    //     height: 0,
    //     borderTop: '1em solid transparent',
    //     borderBottom: '1em solid transparent',
    //     borderRight: '1em solid #2c3e50',
    //     marginLeft: '-0.9em',
    //     '&:before': {
    //         borderWidth: '1em 1em 1em 0',
    //         borderColor: 'transparent white transparent transparent'
    //     }
    // }
}));

export default function InfoPopper({infoTitle, infoRows}) {
    const [showPopper, setShowPopper] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const arrowRef = useRef();
    const styles = useStyles();

    const handleClickAway = (event) => {
        setAnchorEl(null);
        setShowPopper(false);
    }

    const handleClick = (event) => {
        if (anchorEl){
            setAnchorEl(null);
            setShowPopper(false);
        } else {
            setAnchorEl(event.currentTarget);
            setShowPopper(true);
        }
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className={styles.infoImage}>
                <IconButton data-testid={`${infoTitle}_button`} type="submit" className={styles.infoImageButton} onClick={handleClick}>
                    <img src='../images/icInfoOutline.svg' alt="show comment"/>
                    <Popper style={{zIndex: 1400}} open={showPopper} anchorEl={anchorEl} placement='bottom-start' transition modifiers={{
                        // arrow: {
                        //     enabled: true,
                        //     element: '#popperArrow',
                        // }
                    }}>
                        {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout={350}>
                                <div className={styles.infoPopper}>
                                    <span id='popperArrow' className={styles.arrow} ref={arrowRef} />
                                    <div className={styles.infoPopperTitle}>Rules</div>
                                    {infoRows.map((infoRow, index) => <div key={`infoRow_${index}`} className={styles.infoRow}>{infoRow}</div>)}
                                </div>
                            </Fade>
                        )}
                    </Popper>
                </IconButton>
                <div className={styles.infoTitle} >{infoTitle}</div>
            </div>
        </ClickAwayListener>
    );
}
