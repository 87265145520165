import FormField from "./FormField";
import TextAreaField from "./TextAreaField";
import TimeField from "./TimeField";
import DropDownField from "./DropDownField";
import RadioButtonField from "./RadioButtonField";
import CheckBoxField from "./CheckBoxField";
import AddressField from "./AddressField";
import GenderField from "./GenderField";
import YesOrNoField from "./YesOrNoField";
import TextField from "./TextField";
import PhoneField from "./PhoneField";
import ReadOnlyTextField from "./ReadOnlyTextField";
import FormBreakField from "./FormBreakField";
import DateField from "./DateField";
import DateTimeField from "./DateTimeField";
import SSNField from "./SSNField";
import LookUpField from "./LookUpField";

const FormFields = {
    'Text Area': TextAreaField,
    'Time': TimeField,
    'DropDown': DropDownField,
    'RadioButton': RadioButtonField,
    'CheckBox': CheckBoxField,
    'Descriptor CheckBox' : CheckBoxField,
    'Descriptor DropDown': DropDownField,
    'ProgramDropDown': DropDownField,
    'StaffDropDown': DropDownField,
    'ActiveMedicationDropDown': DropDownField,
    'Address': AddressField,
    'Gender': GenderField,    
    'Yes or No' : YesOrNoField,
    'Text Field' : TextField,
    'Phone' : PhoneField,
    'ReadOnlyText' : ReadOnlyTextField,
    'Form Break' : FormBreakField,
    'Date' : DateField,
    'DateTime' : DateTimeField,
    'SSN' : SSNField,
    'ReferralSourceDropDown' :DropDownField,
    'LookUp' : LookUpField
}
export default class FormFieldFactory{
    static createInstance(descriptor, index, selectedClient){
        if(descriptor.type === "Address") {
            descriptor.options = [
                {id:"text0", name: "Street 1", value : "", placeholder:"Street 1", required: false, maxCharacters : 100 },
                {id:"text1", name: "Street 2", value : "", placeholder:"Street 2", required: false, maxCharacters: 100 },
                {id:"text2", name: "APT/Suite", value : "", placeholder:"APT/Suite", required: false, maxCharacters: 10 },
                {id:"text3", name: "City", value : "", placeholder:"City", required: false, maxCharacters: 60 },
                {id:"text4", name: "State/Province", value : "", placeholder:"State/Province", required: false, maxCharacters: 60 },
                {id:"text5", name: "Zip", value : "", placeholder:"Zip", required: false, maxCharacters: 5 },
                {id:"text6", name: "Zip + 4", value : "", placeholder:"Zip + 4", required: false, maxCharacters: 4}
            ]
        } else if(descriptor.type === "Phone") {
            descriptor.options = [
                {id:"text0", name: "countryCode", value : "", placeholder:"XXX", required: true, maxCharacters : 3, maxLength: 3 },
                {id:"text1", name: "phoneNumber", value : "", placeholder:"XXX-XXXX", required: true, maxCharacters: 8, maxLength: 8 },
                {id:"text2", name: "ext", value : "", placeholder:"XXXXX", required: false, maxCharacters: 5, maxLength: 5 },
            ]
        } else if(descriptor.type === "SSN") {
            descriptor.options = [
                {id:"text0", name: "SSN", value : "", placeholder:"XXX-XX-XXXX", required: true, maxCharacters : 11, maxLength: 11 },
            ]
        }

        const formFieldConstructor = FormFields[descriptor.type];

        return formFieldConstructor ? new formFieldConstructor(descriptor, index, selectedClient) : new FormField(descriptor, index, selectedClient);
    }
}