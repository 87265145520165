import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import SortIcon from '@mui/icons-material/Sort';
import CircularProgress from "@material-ui/core/CircularProgress";
import { PRIMARY_COLOR } from "../../../common/constants";
import SortDrawer from "../../../common/components/SortDrawer";
import { useHistory, useLocation } from "react-router-dom";
import syncService from "../../../common/services/syncService";
import { clientCache } from "../../../cache/slices/client/clientSlice";
import { appCache } from "../../../cache/slices/app/appSlice";
import { useDispatch, useSelector } from "react-redux";
import { getFormattedDateTime } from "../../../common/utils/vitalsUtils";
 
const useStyles = makeStyles((theme) => ({
    vitals: { 
        fontSize: '16px', 
        fontStyle: 'normal', 
        letterSpacing: 0, 
        paddingBottom: '9px' 
    },
    loadingSpinnerContainer: { 
        width: '100%', 
        height: '100%', 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        paddingTop: '60%' 
    },
    loadingSpinner: { 
        color: PRIMARY_COLOR 
    },
    noVitalsFoundContainer: { 
        width: '100%', 
        display: 'flex', 
        justifyContent: 'center' 
    },
    noVitalsFound: { 
        fontSize: '18px', 
        fontWeight: "bold", 
        fontStyle: "normal", 
        color: "#000000", 
        paddingTop: '56px' 
    },
    sectionContainer: { 
        width: '100%', 
        height: '62px', 
        backgroundColor: "#ffffff", 
        display: 'flex', 
        borderTop: '1px solid #979797', 
        paddingBottom: '8px', 
        paddingLeft: '16px', 
        paddingRight: '8px', 
        justifyContent: 'space-between' 
    },
    dateHeading: {
        width: '100%', 
        height: '38px', 
        backgroundColor: "#ffffff", 
        display: 'flex', 
        borderTop: '1px solid #979797', 
        paddingBottom: '8px', 
        paddingLeft: '16px', 
        paddingRight: '8px', 
        paddingTop: "10px" 
    },
    image: { 
        height: '100%', 
        width: '100%' 
    },
    imageColumn: { 
        paddingLeft: '32%', 
        paddingTop: '10px' 
    },
    imageContainer: { 
        width: '40px', 
        height: '40px' 
    },
}));
 
export const VitalsTabPage = () => {
    const vitals = useSelector(syncService.vitalService.getResults());
    const isLoading = useSelector(syncService.messageService.isLoading());
    const client = useSelector(clientCache.getSelectedClient);
    const user = useSelector(appCache.getUser);
    const sortDescriptor = useSelector(clientCache.getVitalsSort);

    const [drawerOpened, setDrawerOpened] = useState(false);
    const [sortedVitals, setSortedVitals] = useState([]);

    const location = useLocation();
    const history = useHistory();
    const styles = useStyles();
    const dispatch = useDispatch();

    const columnDescriptors = [
        { name: 'Sort Ascending', title: 'Ascending' },
        { name: 'Sort Descending', title: 'Descending' },
    ];

    const sortedColumn = sortDescriptor ? sortDescriptor.sortDescriptor : columnDescriptors[1];
    const sortDirection = sortDescriptor ? sortDescriptor.sortDirection : 'desc';

    useEffect(() => {
        if(vitals && vitals.length > 0) {
            syncService.auditService.saveAuditEvent(null, client, user, "EVV Client Vitals", "View");
        }
    }, [vitals, client, user]);

    useEffect(() => {
        localStorage.setItem('sortDescriptor', JSON.stringify(sortDescriptor));
    }, [sortDescriptor]);
 
    useEffect(() => {
        const sortedVitalsArray = [...vitals].sort((a, b) => {
            const dateA = new Date(a?.dateRecorded);
            const dateB = new Date(b?.dateRecorded);

            if (sortDirection === 'asc') {
                return dateA - dateB;
            } else {
                return dateB - dateA;
            }
        });

        if (JSON.stringify(sortedVitalsArray) !== JSON.stringify(sortedVitals)) {
            setSortedVitals(sortedVitalsArray);
        }
     // eslint-disable-next-line
    }, [sortDescriptor, sortDirection]);

    const handleVitalsClick = (vital, selectedDate) => {
        dispatch(clientCache.selectVital(vital));
        history.push({ pathname: `${location.pathname}/vitalsInfo`, state: { selectedDate } });
    };
 
    const handleSortRequest = () => {
        toggleDrawer();
    };
 
    const handleSortBy = (columnDescriptor) => {
        const isCurrentColumn = columnDescriptor?.name === sortedColumn?.name;
        const isAscending = sortDirection === 'asc';
    
        const newSortDirection = 
            columnDescriptor?.name === 'Sort Ascending' ? 'asc' :
            columnDescriptor?.name === 'Sort Descending' ? 'desc' :
            isCurrentColumn ? (isAscending ? 'desc' : 'asc') : 'desc';
    
        setDrawerOpened(false);
        
        dispatch(clientCache.sortVitals({
            sortDescriptor: columnDescriptor,
            sortDirection: newSortDirection
        }));
    };

    const toggleDrawer = () => {
        setDrawerOpened(!drawerOpened);
    };
 
    return (
        <div>
            {isLoading ? (
                <div className={styles.loadingSpinnerContainer}><CircularProgress className={styles.loadingSpinner} /></div>
            ) : (
                <>
                    {sortedVitals.length > 0 ? (
                        <div className={styles.vitals}>
                            <div style={{ color: '#4F4F4F' }} className={styles.dateHeading}>
                                <div className={styles.sortIcon}>
                                    <SortIcon onClick={handleSortRequest} />
                                    <SortDrawer anchor="bottom" open={drawerOpened} columnDescriptors={columnDescriptors} showCancel={true} handleCancel={toggleDrawer} handleSortBy={handleSortBy} />
                                </div>
                                <div style={{ paddingLeft: '20px' }}>Date</div>
                            </div>
                            {sortedVitals.map((vital, index) => (
                                <div key={index} className={styles.sectionContainer} style={{ color: '#3b276a' }} onClick={() => handleVitalsClick(vital, vital?.dateRecorded)}>
                                    <div style={{ paddingTop: '6%', fontWeight: 'bold' }}>{getFormattedDateTime(vital?.dateRecorded)}</div>
                                    <div className={styles.imageColumn}>
                                        <div className={styles.imageContainer}>
                                            <img className={styles.image} src='../../images/iconIcoChevronRight.svg' alt='vital details' />
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <hr />
                        </div>
                    ) : (
                        <div className={styles.noVitalsFoundContainer}>
                            <div className={styles.noVitalsFound}>No vitals found</div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};