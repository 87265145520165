import {TableCell, TableHead, TableRow, TableSortLabel} from "@material-ui/core";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {makeStyles} from "@material-ui/core/styles";
import { PRIMARY_COLOR } from "../../constants";

const useStyles = makeStyles((theme) => ({
    tableHead: {
        backgroundColor: "#ffffff"
    },
    tableHeadHidden: {
        visibility: 'collapse'
    },
    tableRow: {
        paddingLeft: '11px',
        paddingRight: '20px'
    },
    headerCell: {
        ...theme.cell,
        fontWeight: "bold",
        color: "#0079c1",
        backgroundColor: '#ffffff'
    },
    headerSortLabel: {
        fontSize: '16px',
        fontWeight: "bold",
        fontStyle: "normal",
        color: PRIMARY_COLOR
    },
    headerSortLabelIcon: {
        width: '32px',
        height: '32px',
        color: '#383838 !important'
    },
    headerSortLabelIconHidden: {
        width: '32px',
        height: '32px',
        color: '#383838 !important',
        visibility: 'hidden'
    },
    headerSortLabelIconRmoved: {
        width: '0px !important',
        height: '0px !important',
        color: '#383838 !important',
        visibility: 'hidden',
        margin: "0px !important"
    }
}));

export default function DataTableHead(props) {
    const {orderByDirection, orderByProperty, handleSortRequest, columnDescriptors, isHidden} = props;
    const styles = useStyles();

    const tableHeadClassName = styles.tableHead + (isHidden ? ' ' + styles.tableHeadHidden : '');

    const createSortHandler = (columnDescriptor) => (event) => {
        if (!columnDescriptor.disableSort) {
            handleSortRequest(columnDescriptor);
        }
    };

    const renderHeaderCell = (cd) => {
        if (cd.label) {
            let iconClass;
            if (cd.disableSort && cd.removeSortIcon) {
                iconClass = styles.headerSortLabelIconRmoved;
            } else if (cd.disableSort) {
                iconClass = styles.headerSortLabelIconHidden;
            } else {
                iconClass = styles.headerSortLabelIcon;
            }

            return (
                <TableSortLabel
                    className={styles.headerSortLabel}
                    classes={{icon: iconClass}}
                    active={orderByProperty === cd.id}
                    direction={orderByProperty === cd.id ? orderByDirection : 'asc'}
                    onClick={createSortHandler(cd)}
                    IconComponent={ArrowDropDownIcon}
                >
                    {cd.label}
                </TableSortLabel>
            )
        } else {
            return (
                <div>
                    {cd.label}
                </div>
            )
        }
    };

    return (
        <TableHead className={tableHeadClassName}>
            <TableRow className={styles.tableRow}>
                {columnDescriptors.map((cd) => (
                    <TableCell
                        key={cd.id}
                        style={{width: cd.width}}
                        className={styles.headerCell}
                        sortDirection={orderByProperty === cd.id ? orderByDirection : false}
                    >
                        {renderHeaderCell(cd)}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
