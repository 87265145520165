import {Radio, withStyles} from "@material-ui/core";
import { PRIMARY_COLOR } from "../../constants";

const EvvRadio = withStyles({
    root: {
        width: '55px',
        height: '55px',
        color: '#a1a1a1',
        '&$checked': {
            color: PRIMARY_COLOR,
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);

export default EvvRadio;