import LookupService from "./lookupService";
import syncService from "../services/syncService";
import { getDispatchFromConfig, sort } from "../utils/miscUtils";
import { evvRepository } from "../../db/evv";
import documentRepository from "../../db/documentRepository";
import api from "./api";
import { SERVICE_DOCUMENT_STATUS_INCOMPLETE, SERVICE_DOCUMENT_STATUS_SIGNED, SERVICE_DOCUMENT_STATUS_UNSIGNED } from "../../features/forms/serviceDocumentUtils";
import { documentCache } from "../../cache/slices/document/documentCache";
import { isGA } from "../utils/goalsAddressedUtils";

const SERVICE_DOCUMENT_WRITE = '/evv/write/document';
const PDF_READ = '/evv/read/documentPdf';
const EVV_READ_DOCUMENT = '/evv/read/evvdocuments';


class DocumentService extends LookupService{

    saveDocument = (document, config) => {  
        if (document) {
            const docId = (-1 * Date.now());
            if (!document.id) {
                document.id = docId;
            }

            const documents = [document];

            config = {  ...config, 'saveDocument': true };

            return this.save(documents, config)
                .then(() => {
                    const dispatch = getDispatchFromConfig(config);
                    dispatch(this.operationAdd(document));
                    return document;
                })
        }
    }

    deleteDocument = (document, config) => {
        return this.deleteByPrimaryKey(document.id)
            .then(() => {
                const dispatch = getDispatchFromConfig(config);
                dispatch(this.operationRemove(document));
            })
    }

    updateDocument = async (id, moduleData, signature, documentStatus, clientProgramId) => {
        let documentData = {
            "moduleData": moduleData,
            "signature": signature,
            "documentStatus": documentStatus,
        }
        documentData.clientProgramId = clientProgramId;
        evvRepository?.evvDb?.document
            .where("id").equals(id)
            .modify(
                    documentData
            );
    }

    getServiceDoc = async(serviceDocumentId) => {
        return evvRepository?.evvDb?.serviceDocument?.where("setupId").equals(serviceDocumentId).toArray();
    }

    findDocumentById = (id) => {
        return evvRepository?.evvDb?.document?.where({id: id}).first().then(doc => doc)
    }

    getGaModulesToSave = (module, newModuleToSave) => {
        module?.fieldData?.forEach(fieldData => {
            if (fieldData?.goalAddressedDetail) {
                let addressedElements = fieldData?.goalAddressedDetail?.filter(ele => ele.isAddressed);
                if (addressedElements?.length > 0) {
                    let addressedGaDetails = [];
                    let fieldDataToSave = { ...fieldData };

                    addressedElements?.forEach((ele) => {
                        const {isAddressed, ...rest} = { ...ele };
                        let temp = { ...rest };
                        addressedGaDetails.push(temp);
                    });

                    fieldDataToSave.goalAddressedDetail = [...addressedGaDetails];
                    newModuleToSave.push(fieldDataToSave);
                }
            } else {
                newModuleToSave.push(fieldData);
            }
        });
        return newModuleToSave;
    }

    getImpactModulesToSave = (module, newModuleToSave) => {
        let newModule = {};
        let newFields = [];

        newModule.measureId = module.measureId;
        newModule.moduleType = module.moduleType;
        newModule.score = module.totalScore;
        newModule.notAssessed = module.notAssessed;
        newModule.subTotal = module.subTotal;

        module.fieldData.forEach(fieldData => {
            let newFieldData = {};
            newFieldData.key = fieldData.key;
            newFieldData.value = Array.isArray(fieldData.value) ? fieldData.value.map((item) => item && item.length !== 0 ? item : 'null').join(",") : fieldData.value;
            newFields.push(newFieldData);
        });
        newModule.fieldData = newFields;
        newModuleToSave.push(newModule);
        return newModuleToSave;
    }

    writeDocument = async (serviceDocument, modulesToSave) => {
        const notIncludedFields = ['loaded', 'dbOptions', 'nextClicked'];
        let newModuleToSave  = [];
        modulesToSave.forEach(module => {
            if (module.moduleType === "cf") {
                let newModule = {};
                newModule.moduleId = module.moduleId;
                newModule.moduleType = module.moduleType;
                let newFields = [];
                module.fieldData.forEach(fieldData => {
                    if (!notIncludedFields.includes(fieldData.key)){
                        let newFieldData = {};
                        newFieldData.key = fieldData.key;
                        newFieldData.value = Array.isArray(fieldData.value) ? fieldData.value.map((item) => item && item.length !== 0 ? item : 'null').join("|") : fieldData.value;
                        newFields.push(newFieldData);
                    }
                });
                newModule.fieldData = newFields;
                newModuleToSave.push(newModule);
            } else if (isGA(module)) {
                //Goals Addressed Module
                newModuleToSave = this.getGaModulesToSave(module, newModuleToSave);
            } else {
                //Impact Module
                newModuleToSave = this.getImpactModulesToSave(module, newModuleToSave);
            }
        });

        let signatureInfo = {...serviceDocument.signature};
        if (signatureInfo.nextStaffId) {
            signatureInfo.nextStaffId = signatureInfo.nextStaffId.staffId;
        }
        
        let parameters = {
            resources: [
                {
                    id: serviceDocument.status === SERVICE_DOCUMENT_STATUS_SIGNED ? serviceDocument.documentId : null,
                    serviceDocumentId: serviceDocument.serviceDocumentId,
                    appointmentId: serviceDocument.appointmentId,
                    clientId: serviceDocument.clientId,
                    clientProgramId: serviceDocument.clientProgramId < 0 ? null : serviceDocument.clientProgramId,
                    staffId: serviceDocument.staffId,
                    organizationId: serviceDocument.organizationId,
                    serviceDate: serviceDocument.serviceDate.substring(0,10),
                    fullySigned: serviceDocument.fullySigned,
                    moduleData: newModuleToSave,
                    signature: Object.keys(signatureInfo).length > 0 ? signatureInfo : undefined
                }
            ]
        };
        return api.post(SERVICE_DOCUMENT_WRITE, { json: parameters })
            .then(response => {
                return Promise.resolve(response);
            })
            .catch(error => {
                const connectionLost = api.isConnectionLost(error);

                if (connectionLost){
                    return Promise.resolve(parameters);
                } else {
                    console.log("Error thrown while writing service document: ");
                    console.log(error);
                    return Promise.reject(error);
                }
            });
    }

    updateAppointmentDocument = async (oldAppointmentId, newAppointmentId) => {
        evvRepository?.evvDb?.document
            .where("appointmentId").equals(oldAppointmentId)
            .modify({
                "appointmentId": newAppointmentId
            });
    }

    checkHasServiceDoc = (appointment) => {
        return this.repository.loadAllByProperty('appointmentId', appointment.activityDetailId || appointment.appointmentId)
            .then(clientDocs => {
                if (clientDocs && clientDocs.length > 0){
                    return true;
                } else {
                    return false;
                }
            })
    }

    checkHasAttachedDoc = (appointment) => {
        return this.repository.loadAllByProperty('appointmentId', appointment.activityDetailId || appointment.appointmentId)
            .then(doc => {
                if (doc && doc.length > 0) {
                    return doc;
                }
            })
    }

    getUnsignedIncompleteDocs = () => {
        return evvRepository?.evvDb?.document
        .where("documentStatus").anyOf(SERVICE_DOCUMENT_STATUS_UNSIGNED, SERVICE_DOCUMENT_STATUS_INCOMPLETE)
        .toArray();
    }

    getDocsCollection = (client) => {
        return evvRepository?.evvDb?.document.where("clientId").equals(Number(client.clientId)).toArray();
    }

    saveNewDocument = (documentToSave, store, dispatch) => {
        documentToSave.syncState = "dirty";
        this.saveDocument(documentToSave, {store, doNotClear: true})
            .then(savedDocument => {
                console.log('created a new document with an appointment');
                console.log(savedDocument);
                dispatch(documentCache.setCurrentDocumentId(savedDocument.id));
            })
            .catch(error => {
                console.log('Document can not be created with an appointment');
                console.log(error);
            });
    }

    getPdfUrl = async (documentId, force) => {
        let parameters = {
            documentId: documentId
        };

        if (force) {
            parameters.force = true;
        }

        return api.post(PDF_READ, { json: parameters })
            .then(response => {
                return Promise.resolve(response);
            })
            .catch(error => {
                const connectionLost = api.isConnectionLost(error);
                if (connectionLost){
                    return Promise.resolve({connectionLost: true});
                } else {
                    console.log("Error thrown while reading pdf document: ");
                    console.log(error);
                    return Promise.reject(error);
                }
            });
    }

    addPdfUrl = async (documentId, pdfUrl) => {
        evvRepository?.evvDb?.document
            .where("id").equals(documentId)
            .modify({
                "pdfUrl": pdfUrl
            });
    }
    
    readDocument = async (documentId) => {
        let parameters = {};
        if (documentId) {
            parameters = {
                documentId: documentId
            };
        }

        return api.post(EVV_READ_DOCUMENT, { json: parameters })
            .then(response => {
                return Promise.resolve(response);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    }

    updatePdfDocument = async (id, docData) => {
        evvRepository?.evvDb?.document
            .where("id").equals(id)
            .modify(docData);
    }

    createSignaturaInfo = (document) => {
        let signatureInfo = {...document.signature};

        if (signatureInfo.nextStaffId)
            signatureInfo.nextStaffId = signatureInfo.nextStaffId.staffId;

        return signatureInfo;
    }

    createModuleToSave = (document) => {
        const notIncludedFields = ['loaded', 'dbOptions', 'nextClicked'];
        let newModuleToSave  = [];

        document.moduleData.forEach(module => {
            if (module.moduleType === "cf") {
                let newModule = {};
                let newFields = [];

                newModule.moduleId = module.moduleId;
                newModule.moduleType = module.moduleType;

                module.fieldData.forEach(fieldData => {
                    if (!notIncludedFields.includes(fieldData.key)) {
                        let newFieldData = {};
                        newFieldData.key = fieldData.key;
                        newFieldData.value = Array.isArray(fieldData.value) ? fieldData.value.map((item) => item && item.length !== 0 ? item : 'null').join("|") : fieldData.value;
                        newFields.push(newFieldData);
                    }
                });

                newModule.fieldData = newFields;
                newModuleToSave.push(newModule);
            } else if (isGA(module)) {
                //Goals Addressed Module
                newModuleToSave = this.getGaModulesToSave(module, newModuleToSave);
            } else {
                //Impact Module
                newModuleToSave = this.getImpactModulesToSave(module, newModuleToSave);
            }
        });

        return newModuleToSave;
    }

    createRequestWriteDocument = (document) => {
        let signatureInfo = this.createSignaturaInfo(document);
        let newModuleToSave  = this.createModuleToSave(document);

        const writeDocument = {
            id: document.id > 0 ? document.id : null,
            serviceDocumentId: document.serviceDocumentId,
            appointmentId: document.appointmentId,
            clientId: document.clientId,
            clientProgramId: document.clientProgramId < 0 ? null : document.clientProgramId,
            staffId: document.staffId,
            organizationId: document.organizationId,
            serviceDate: document.serviceDate.substring(0,10),
            fullySigned: document.documentStatus === SERVICE_DOCUMENT_STATUS_SIGNED,
            moduleData: newModuleToSave,
            signature: Object.keys(signatureInfo).length > 0 ? signatureInfo : undefined
        }

        return writeDocument;
    }

    writeDocumentOffline = async (documents, config) => {
        const requestWriteDocuments = documents.map(document => this.createRequestWriteDocument(document));
        const parameters = { resources: requestWriteDocuments };
        
        return api.post(SERVICE_DOCUMENT_WRITE, { json: parameters })
            .then(response => {
                if (response.resources && response.resources.length > 0) {
                    response.resources.forEach((documentFromServer) => {
                        const index = response.resources.indexOf(documentFromServer);
                        const originalDocument = documents[index];

                        documentRepository.findDocumentById(originalDocument.id).then(docById => {
                            const newDoc = {...docById}
                            const store = config.store;
                            
                            syncService.documentService.deleteDocument(docById, {store, doNotClear: true})
                            newDoc.id = response.resources[0].id
                            newDoc.syncState = "clean"
                            newDoc.clientProgramId = document.clientProgramId;
                            newDoc.organizationId = document.organizationId;
                            syncService.documentService.saveDocument(newDoc, {store, doNotClear: true})
                        });
                    });
                }
            })
            .catch(error => {
                const connectionLost = api.isConnectionLost(error);

                if (connectionLost) {
                    return Promise.resolve(parameters);
                } else {
                    console.log("Error thrown while writing service document: ");
                    console.log(error);
                    return Promise.reject(error);
                }
            });
    }

    sortDocuments = (documents) => {
        return [...sort(documents, ['serviceDate'])];
    };

    writeToServer = (config) => {
        console.log('DocumentService.writeToServer - config:');
        console.log(config);
        return documentRepository.getDocumentsSignedAndDirty()
            .then(documents => {
                const documentsSort = this.sortDocuments(documents);
                console.log('Documents in getDocumentsSignedAndDirty() : ' + JSON.stringify(documentsSort));
                if (documentsSort && documentsSort.length > 0) {
                    return this.writeDocumentOffline(documentsSort, config);
                }
            })
            .catch(error => {
                console.log("Error thrown while writing to Documents server: ");
                console.log(error);
            })
    }

    saveDoc = async (id, moduleData, documentStatus, signatures, clientProgramId) => {
        let documentData = {
            "moduleData": moduleData,
            "documentStatus": documentStatus,
            "signature": signatures,
            "clientProgramId": clientProgramId
        }
        evvRepository?.evvDb?.document.where("id").equals(id).modify(documentData);
    }

    writeExtSigNeededDoc = async(parameters) => {
        return api.post(SERVICE_DOCUMENT_WRITE, { json: parameters })
            .then(response => {
                return Promise.resolve(response);
            })
            .catch(error => {
                const connectionLost = api.isConnectionLost(error);

                if (connectionLost){
                    return Promise.resolve(parameters);
                } else {
                    console.log("Error thrown while writing service document: ");
                    console.log(error);
                    return Promise.reject(error);
                }
            });
    }

    addExtSigDocument = async (document, store) => {
        this.saveDocument(document, {store, doNotClear: true})
            .then(savedDocument => {
                console.log(savedDocument, 'addExtSigDocument');
            })
            .catch(error => {
                console.log(error);
            });
    }
}

export default DocumentService;
