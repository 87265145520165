import {createTheme} from "@material-ui/core";
import { APPFONT, PRIMARY_COLOR } from "../constants";

const DEFAULT_TEXT_FIELD_HEIGHT = 45;
// box shadow definition -
// 0px, 0px no offset (surrounds the box)
// 9px blur (how blurry it gets 0 = no blur, just straight up background),
// 3px spread - how far out shadow goes
const DEFAULT_BOX_SHADOW = '0px 0px 9px 3px rgba(0, 0, 0, 0.11)';
const DEFAULT_BOX_SHADOW_MARGIN = '9px 9px 9px 9px';

const standardFont = {
    fontSize: '16px',
    fontWeight: "normal",
    fontStyle: "normal",
    letterSpacing: '0',
    color: "#4f4f4f"
};

const standardBoldFont = {
    ...standardFont,
    fontWeight: "bold"
};

const EVV_THEME = {
    scale: 1,
    textFieldHeightRaw: DEFAULT_TEXT_FIELD_HEIGHT,
    textFieldHeight: DEFAULT_TEXT_FIELD_HEIGHT + 'px',
    boxShadow: DEFAULT_BOX_SHADOW,
    boxShadowMargin: DEFAULT_BOX_SHADOW_MARGIN,
    boxShadowDimension: 18,
    buttonHeight: '45px',
    verticalSpaceBetween: 35,
    minScreenWidth: 360,
    appPaddingX: 24,
    buttonFont: {

    },
    shadowedContainer:{
        width: 'calc(100% - 18px)',
        height: 'calc(100% - 18px)',
        backgroundColor: "#ffffff",
        borderRadius: '3px',
        overflow: 'auto',
        boxShadow: DEFAULT_BOX_SHADOW,
        margin: DEFAULT_BOX_SHADOW_MARGIN
    },
    cell:{
        fontSize: '16px',
        fontWeight: "normal",
        fontStyle: "normal",
        letterSpacing: '-0.14px',
        border: 0,
        paddingRight: '0px'
    },
    overrides: {
        MuiTypography: {
          root: {
            fontFamily: APPFONT
          }
        },
        MuiDialogTitle: {
            root: {
              backgroundColor: PRIMARY_COLOR
            }
        },
    },

    fieldLabel:standardFont,
    fieldValue:standardBoldFont
};

export const theme = createTheme(EVV_THEME);
