import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    stackedField: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '18px'
    },
    stackedFieldLabelContainer: {
        display: 'flex',
    },
    stackedFieldLabel: {
        fontSize: '16px',
        fontWeight: "bold",
        fontStyle: "normal",
        lineHeight: '20px',
        letterSpacing: '.3px',
        color: "#4f4f4f",
        paddingBottom: '4px',
        display: 'flex'
    },
    stackedFieldValue: {
        minHeight: '20px',
        lineHeight: '22px',
        fontSize: '16px',
        fontWeight: "normal",
        fontStyle: "normal",
        letterSpacing: 0,
        color: "#4f4f4f",
        paddingTop: '2px'
    },
    mandatoryIndicator: {
        paddingLeft: '4px',
        verticalAlign: 'text-bottom',
        fontSize: '18px',
        fontWeight: "bold",
        color: '#FF3B30',
        marginTop: "-2px" 
    },
    selectLabel: {
        fontSize: '16px',
        fontWeight: "bold",
        fontStyle: "normal",
        color: "#000000",
        paddingBottom: '4px',
        display: 'flex'
    },
}));

export default function StackedField({label, boldLabel = true, value, boldValue = false, paddingTop, mandatory, children, selectLabel}) {
    const styles = useStyles();
    const fieldStyle = paddingTop ? {paddingTop: paddingTop} : {};
    const labelStyle = boldLabel ? {} : {fontWeight: 'normal'};
    const valueStyle = boldValue ? {fontWeight: 'bold'} : {};

    const renderMandatoryLabel = () => {
        return (
            <div style={labelStyle} className={selectLabel ? styles.selectLabel : styles.stackedFieldLabel} >
                <span>{label}</span>
                <span className={styles.mandatoryIndicator}>*</span>
            </div>
        );
    }

    const renderLabel = () => {
        return (
            <div style={labelStyle} className={selectLabel ? styles.selectLabel : styles.stackedFieldLabel} >
                {label}
            </div>
        );
    }

    return (
        <div style={fieldStyle} className={styles.stackedField} >
            {mandatory === true
            ? renderMandatoryLabel()
            : renderLabel()
            }
            {children
            ? children
            : <div style={valueStyle} className={styles.stackedFieldValue} >
                    {value ? value : children}
              </div>
            }
        </div>
    )
}