import {makeStyles} from "@material-ui/core/styles";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {defaultDateAsString} from "../../../common/utils/formatUtils";
import {clientCache} from "../../../cache/slices/client/clientSlice";
import { PRIMARY_COLOR } from "../../../common/constants";

const useStyles = makeStyles(() => ({
    allergyPage: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '17px',
        paddingLeft: '15px'
    },
    allergyName: {
        fontSize: '18px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: PRIMARY_COLOR
    },
    allergyField: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '18px'
    },
    allergyFieldLabel: {
        fontSize: '16px',
        fontWeight: "bold",
        fontStyle: "normal",
        lineHeight: '20px',
        letterSpacing: 0,
        color: "#4f4f4f"
    },
    allergyFieldValue: {
        minHeight: '20px',
        fontSize: '16px',
        fontWeight: "normal",
        fontStyle: "normal",
        letterSpacing: 0,
        color: "#4f4f4f",
        paddingTop: '2px'
    },
}));

export default function AllergyPage() {
    const allergy = useSelector(clientCache.getSelectedAllergy);
    const history = useHistory();
    const styles = useStyles();

    if (!allergy){
        history.push("/");
        return null;
    }

    return (
        <div data-testid='allergyPage' className={styles.allergyPage}>
            <div className={styles.allergyName} >
                {allergy.allergyName}
            </div>
            <div className={styles.allergyField} >
               <div className={styles.allergyFieldLabel} >
                   Reaction
               </div>
                <div className={styles.allergyFieldValue} >
                    {allergy.allergyReaction}
                </div>
            </div>
            <div className={styles.allergyField} >
                <div className={styles.allergyFieldLabel} >
                    Type
                </div>
                <div className={styles.allergyFieldValue} >
                    {allergy.allergyType}
                </div>
            </div>
            <div className={styles.allergyField} >
                <div className={styles.allergyFieldLabel} >
                    Begin Date
                </div>
                <div className={styles.allergyFieldValue} >
                    {defaultDateAsString(allergy.beginDate, '')}
                </div>
            </div>
            <div className={styles.allergyField} >
                <div className={styles.allergyFieldLabel} >
                    End Date
                </div>
                <div className={styles.allergyFieldValue} >
                    {defaultDateAsString(allergy.endDate, '')}
                </div>
            </div>
        </div>
    );
}
