import {getRandomInteger} from "../utils/miscUtils";
import {DateTime} from "luxon";
import {getFormattedStartDate, getFormattedStartTime} from "../utils/formatUtils";

const now = DateTime.now();
const topOfHour = now.startOf("hour");

const userToTasksMap = {};

export const generateTasks = (user, count, clients) => {
    if (userToTasksMap[user.userName]){
        return userToTasksMap[user.userName];
    }

    const tasks = Array.from({length: count}, (_, index) => {
        const client=clients[getRandomInteger(0, clients.length - 1)];
        return {
            userName: user.userName,
            taskId: index,
            client: client,
            appointment:{
                clientId: client.clientId
            },
            status: statuses[getRandomInteger(0, statuses.length - 1)],
            startDate: topOfHour.minus({months: getRandomInteger(0, 36), days: getRandomInteger(), hours: getRandomInteger(0,23), minutes: minutes[getRandomInteger(0, minutes.length - 1)]}).toSQL()
        }
    });
    userToTasksMap[user.userName] = prepareTasks(tasks, 'dummy');
    return userToTasksMap[user.userName];
}

export const prepareTasks = (tasks, call = '') => {
    if (tasks && tasks.length > 0) {
        tasks.forEach((a) => {
            let temp = {};
            if (call === 'dummy') {
                temp.startDateInstance = DateTime.fromSQL(a?.startDate);
            }
            temp.formattedStartDate = getFormattedStartDate(a?.startDateInstance);
            temp.formattedStartTime = getFormattedStartTime(a?.startDateInstance);
            temp.clientId = a?.client?.clientId;
            a = {...a, ...temp};
        });
    }
    return tasks;
}

const minutes = [0,15,30,45];
const statuses = [
    '',
    'Unstatused Appointment',
    'Unsigned Service Document',
    'Missing Service Document',
    'Incomplete Service Document'
];
