import { sortElements } from '../common/Util';
import { checkFieldCondition } from '../common/ConditionsUtils';
import { performActions } from '../common/ActionsUtils';
import { getOptions } from './ConfigurableFormsUtils';
import { renderLookUpField } from './FormFieldsUtils';

export const unsetBusinessRuleParams = (field)  => {
    delete field.businessRuleApplied;
    delete field.businessRuleError;
    field['hasBRError'] = false;
}

export const getTextValue = (field) => {
    let fieldValue = '';
    if (field.dbSavedValue && field.dbSavedValue !== '') {
        fieldValue = field.dbSavedValue;
    } else if (field.value) {
        fieldValue = field.value;
    }
    return fieldValue;
}

export const getRadioFieldValue = (field) => {
    let fieldValue = '';
    if (field.dbSavedValue && field.dbSavedValue !== '') {
        fieldValue = field.dbSavedValue;
    } else if (field.selectedOption) {
        fieldValue = field.selectedOption;
    }
    return fieldValue;
}

export const getCheckboxFieldValue = (field) => {
    let fieldValue = [];
    if (field.dbSavedValue && field.dbSavedValue.length > 0) {
        fieldValue = field.dbSavedValue;
    } else if (field.selectedOption) {
        fieldValue = field.selectedOption;
    }
    return fieldValue;
}

export const getDropdownFieldValue = (field) => {
    let fieldValue = '';
    if (field.dbSavedValue && field.dbSavedValue !== '') {
        fieldValue = field.dbSavedValue;
    } else if (field.selectedValue) {
        fieldValue = getFieldValueForDropdown(field);
    }
    return fieldValue;
}

export const getAddPhoFieldValue = (field) => {
    let fieldValue = [];
    if (field.dbSavedValue && field.dbSavedValue.length > 0) {
        fieldValue = field.dbSavedValue;
    } else if (field.options.length > 0) {
        field.options.forEach((option) => {
            fieldValue.push(option.value);
        });
    }
    return fieldValue;
}

export const getLookUpFieldValue = (fieldToCheck, selectedClient, descriptorData) => {
    let columnObj = fieldToCheck.attributes.find( (attribute) => attribute.attributeId === fieldToCheck.id +"_column" );
    let column = columnObj?.value;
    let fieldValueToCompare = renderLookUpField(column, selectedClient, descriptorData);
    if(column === "BIRTH_DATE") {
        // in case of BIRTH_DATE
        // Client API birthdate: “1945-04-01”
        // Condition accepted values that can match: “4/1/1945”; “04/01/1945”; “1945-4-1“; “1945-04-01“
        fieldValueToCompare = getBirthDatePossibleValues(fieldValueToCompare);
    }
    return fieldValueToCompare;
}

export const getBirthDatePossibleValues = (fieldValueToCompare) => {
    let allowedFieldValues = [];
    const date = new Date(fieldValueToCompare);
    let year = date.getFullYear();
    let month = date.getMonth();
    let day = date.getDate();
    let leadingZeroMonth = (date.getMonth() + 1).toString().padStart(2, "0");
    let leadingZeroDay   = date.getDate().toString().padStart(2, "0");

    const withoutLeadingZeroForDash = [year, (month + 1), day].join('-');
    const withLeadingZeroForDash = [year, leadingZeroMonth, leadingZeroDay].join('-');
    const withoutLeadingZeroForSlash = [(month + 1), day, year].join('/');
    const withLeadingZeroForSlash = [leadingZeroMonth, leadingZeroDay, year].join('/');

    allowedFieldValues.push(withLeadingZeroForDash, withoutLeadingZeroForDash, withoutLeadingZeroForSlash, withLeadingZeroForSlash);
    return allowedFieldValues;
}

export const getFieldValueToCompare = (field, selectedClient, descriptorData) => {
    let fieldValue;
    switch(field?.type) {
        case 'Text Field':
        case 'Text Area':
        case 'Date':
        case 'Time':
        case 'DateTime':
        case 'SSN':
            fieldValue = getTextValue(field);
        break;

        case 'Gender':
        case 'RadioButton':
        case 'Yes or No':
            fieldValue = getRadioFieldValue(field);
        break;

        case 'CheckBox':
        case 'Descriptor CheckBox':
            fieldValue = getCheckboxFieldValue(field);
        break;

        case 'DropDown':
        case 'Descriptor DropDown':
        case 'ProgramDropDown':
        case 'StaffDropDown':
        case 'ActiveMedicationDropDown':
        case 'ReferralSourceDropDown':
            fieldValue = getDropdownFieldValue(field);
        break;
          
        case 'Address':
        case 'Phone':
            fieldValue = getAddPhoFieldValue(field);
        break;

        case 'LookUp':
            fieldValue = getLookUpFieldValue(field, selectedClient, descriptorData);
        break;

        default:
        break;
    }
    fieldValue = (fieldValue === undefined) ? "" : fieldValue;
    return fieldValue;
}

export const processBusinessRules = (formFields, formRules, selectedClient, descriptorData) => {
    if (formRules?.length > 0) {
        sortElements(formRules, 'order', 'number'); //Sort business rules by order

        if (formFields.length > 0) {
            formFields.forEach((field) => {
                unsetBusinessRuleParams(field);
            });
        }

        formRules.forEach(rule => {
            let allConditionsFlag = false;
            let allConditionsStatus = [];
            let conditionsToCheck = rule.conditions;
            let actionsToPerform = rule.actions;
            
            if(conditionsToCheck.length > 0) {
                //Check all conditions
                conditionsToCheck.forEach(condition => {
                    let isConditionPassed = checkFieldCondition(condition, formFields, selectedClient, descriptorData);
                    allConditionsStatus.push(isConditionPassed);
                });

                allConditionsFlag = allConditionsStatus.filter((status) => status === false);   //Check if all conditions are passed

                if (allConditionsFlag.length === 0) {   //All conditions are passed
                    if(actionsToPerform.length > 0) {
                        sortElements(actionsToPerform, 'order', 'number'); //Sort actions by order
                        //Perform all actions
                        actionsToPerform.forEach((action) => {
                            performActions(action, formFields, selectedClient, descriptorData);
                        });
                    }
                }
            }
        });
    }
}

export const loopAllBusinessRules = (module, selectedClient, descriptorData) => {
    let formRules = module?.configurableForm?.rules;
    let formFields = module?.configurableForm?.fields;
    processBusinessRules(formFields, formRules, selectedClient, descriptorData);
}

export const getFieldValueForDropdown = (field) => {
    let fieldValue;
    switch(field?.type) {
        case 'ReferralSourceDropDown':
        case 'ProgramDropDown':
        case 'StaffDropDown':
            fieldValue = getOptions(field).toString();
        break;
        
        default:
            fieldValue = field.selectedValue;
        break;
    }
    return fieldValue;
 }