import {makeStyles} from "@material-ui/core/styles";
import {luxonLocalizer} from 'react-big-calendar';
import {DateTime} from "luxon";
import DayScheduler from "../DayScheduler";
import {CircularProgress, IconButton} from "@material-ui/core";
import {useSelector, useStore} from "react-redux";
import {useLayoutEffect, useState, useEffect} from "react";
import {scheduleCache} from "../../../cache/slices/schedule/scheduleCache";
import AppointmentDialog from "../../facesheet/AppointmentDialog";
import {createNewAppointment} from "../../../common/utils/appointmentUtils";
import syncService from "../../../common/services/syncService";
import {appCache} from "../../../cache/slices/app/appSlice";
import {evvRepository} from "../../../db/evv";
import { PRIMARY_COLOR } from "../../../common/constants";
import { ASYNC_OPERATION_STATUS } from "../../../cache/asyncHandler";

const useStyles = makeStyles((theme) => ({
    schedulePage: {
        [theme.breakpoints.up(601)]: {
            ...theme.shadowedContainer,
        },
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            height: '100%'
        }
    },
    addButton: {
        alignSelf: 'flex-end',
        position: 'fixed',
        bottom: '95px',
        right: '21px',
        zIndex: 10
    },
    iconButton: {
        padding: '0px',
        width: '48px',
        height: '48px'
    },
    iconButtonLabel: {
        width: '100%',
        height: '100%'
    },
    loadingSpinnerContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    loadingSpinner: {
        color: PRIMARY_COLOR
    }
}));

const localizer = luxonLocalizer(DateTime)

export default function SchedulePage() {
    const [showAppointmentDialog, setShowAppointmentDialog] = useState(false);
    const [currentAppointment, setCurrentAppointment] = useState(null);
    const selectedDate = useSelector(scheduleCache.getSelectedDate);
    const appointments = useSelector(syncService.appointmentService.getResults());
    const appointmentsLoading = useSelector(syncService.appointmentService.isLoading());
    const styles = useStyles();
    const clients = useSelector(appCache.getClients);
    const store = useStore();
    const user = useSelector(appCache.getUser);
    const activityServiceStatus = useSelector(syncService.activityService.getStatus());

    useEffect(() => {
        if (!appointmentsLoading && evvRepository.isWritenProcess === 'false') {
            syncService.syncAppointmentData({store});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(appointments && appointments.length > 0) {
            if(appointments.length > 1) {
                syncService.auditService.saveAuditEvent(clients, null, user, "EVV Schedule", "View");
            } else {
                syncService.auditService.saveAuditEvent(null, appointments[0].client, user, "EVV Schedule", "View");
            }
        }
    }, [appointments, clients, user]);

    useLayoutEffect(() => {
        if (activityServiceStatus === ASYNC_OPERATION_STATUS.SUCCESS) {
            const daySlot = document.querySelector('.rbc-day-slot');
            const timeslots = daySlot.querySelectorAll('.rbc-timeslot-group');
            const hourSlot = timeslots[selectedDate.hour];
            hourSlot.scrollIntoView();
        }
    }, [activityServiceStatus, selectedDate]);

    const handleAddRequest = () => {
        setCurrentAppointment(createNewAppointment(selectedDate, ''));
        setShowAppointmentDialog(true);
    }

    const handleAppointmentDialogClose = (appointment) => {
        setShowAppointmentDialog(false);
    }

    const renderSchedulePage = () => {
        if (activityServiceStatus === ASYNC_OPERATION_STATUS.SUCCESS) {
            return (
                <div className={styles.schedulePage}>
                    <DayScheduler
                        localizer={localizer}
                        events={appointments}
                        startAccessor="start"
                        endAccessor="end"
                    />
                    <div className={styles.addButton} >
                        <IconButton data-testid='addAppointmentButton' type="button" classes={{label: styles.iconButtonLabel}} className={styles.iconButton}
                                    onClick={handleAddRequest} >
                            <img className={styles.iconButtonLabel} src='../images/iconAddAppointment.svg' alt="add appointment"/>
                        </IconButton>
                    </div>
                    {showAppointmentDialog &&
                    <AppointmentDialog
                        onClose={handleAppointmentDialogClose}
                        appointment={currentAppointment}
                        showStatus={false}
                    />
                    }
                </div>
            );
        } else {
            return (
                <div className={styles.schedulePage}>
                    <div className={styles.loadingSpinnerContainer}>
                        <CircularProgress size={50} className={styles.loadingSpinner} />
                    </div>
                </div>
            );
        }
    }

    return (
        <>{ renderSchedulePage() }</>
    );
}
