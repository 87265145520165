import {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import SortBar from "../../../common/components/SortBar";
import SortDrawer from "../../../common/components/SortDrawer";
import {sortWithDirection} from "../../../common/utils/miscUtils";
import {useDispatch, useSelector} from "react-redux";
import {clientCache} from "../../../cache/slices/client/clientSlice";
import DiagnosisCard from "../DiagnosisCard";
import CircularProgress from "@material-ui/core/CircularProgress";
import syncService from "../../../common/services/syncService";
import {appCache} from "../../../cache/slices/app/appSlice";
import { PRIMARY_COLOR } from "../../../common/constants"

const useStyles = makeStyles(() => ({
    diagnosesPage: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column'
    },
    loadingSpinnerContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    loadingSpinner: {
        color: PRIMARY_COLOR
    },
    noDiagnoses: {
        fontSize: "18px",
        fontWeight: "bold",
        fontStyle: "normal",
        lineHeight: "28px",
        letterSpacing: -0.08,
        textAlign: "center",
        color: "#000000",
        paddingTop: '53px'
    }
}));

const columnDescriptors = [
    {
        name: 'priority',
        title: 'Priority'
    },
    {
        name: 'iCD10Code',
        title: 'ICD- 10 Code'
    },
    {
        name: 'effectiveDate',
        title: 'Effective Date',
        sortDirection: 'desc'
    }
];

export default function DiagnosesPage() {
    const diagnoses = useSelector(syncService.diagnosisService.getResults());
    const isLoading = useSelector(syncService.diagnosisService.isLoading());
    const sortDescriptor = useSelector(clientCache.getDiagnosesSort);
    const [sortedDiagnoses, setSortedDiagnoses] = useState([]);
    const [drawerOpened, setDrawerOpened] = useState(false);
    const dispatch = useDispatch();
    const styles = useStyles();
    const client = useSelector(clientCache.getSelectedClient);
    const user = useSelector(appCache.getUser);

    const sortedColumn = sortDescriptor ? sortDescriptor.sortDescriptor : columnDescriptors[0];
    const sortDirection = sortDescriptor ? sortDescriptor.sortDirection : 'asc';

    useEffect(() => {
        if(diagnoses && diagnoses.length > 0) {
            syncService.auditService.saveAuditEvent(null, client, user, "EVV Client Diagnosis", "View");
        }
    }, [diagnoses, client, user]);

    useEffect(() => {
        const sortPropertyNames = [sortedColumn.name];
        const sortDirections = [sortDirection];

        setSortedDiagnoses(sortWithDirection(
            diagnoses,
            sortPropertyNames,
            sortDirections
        ));

    }, [sortedColumn, sortDirection, diagnoses]);

    const handleSortRequest = () => {
        toggleDrawer();
    }

    const handleSortBy = (columnDescriptor) => {
        let newSortDirection = 'asc';

        if (columnDescriptor.name === sortedColumn.name){
            if (sortDirection === 'asc'){
                newSortDirection = 'desc';
            }
        } else {
            if (columnDescriptor.sortDirection){
                newSortDirection = columnDescriptor.sortDirection
            }
        }

        setDrawerOpened(false);

        dispatch(clientCache.sortDiagnoses({
            sortDescriptor: columnDescriptor,
            sortDirection: newSortDirection
        }));
    }

    const toggleDrawer = () => {
        setDrawerOpened(!drawerOpened);
    }

    const renderPageContent = () => {
        if (sortedDiagnoses && sortedDiagnoses.length > 0){
            return (
                <>
                    <SortBar columnDescriptors={columnDescriptors}
                             sortedColumn={sortedColumn}
                             handleSortRequest={handleSortRequest}/>
                    {
                        sortedDiagnoses.map((diagnosis, index) =>
                            <DiagnosisCard key={`${diagnosis.code}_${index}`} diagnosis={diagnosis} />
                        )
                    }
                    <SortDrawer
                        anchor='bottom'
                        open={drawerOpened}
                        columnDescriptors={columnDescriptors}
                        showCancel={true}
                        handleCancel={toggleDrawer}
                        handleSortBy={handleSortBy}
                    />
                </>
            );
        } else {
            return (<div className={styles.noDiagnoses} >No Diagnoses Found</div>)
        }
    }

    const renderPage = () => {
        if (isLoading){
            return (
                <div className={styles.loadingSpinnerContainer}>
                    <CircularProgress size={50} className={styles.loadingSpinner} />
                </div>
            );
        } else {
            return renderPageContent();
        }
    }

    return (
        <div className={styles.diagnosesPage}>
            {renderPage()}
        </div>
    );
}
