import { CircularProgress, Divider } from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import { MobilePDFReader } from 'react-read-pdf';
import { documentCache } from "../../../cache/slices/document/documentCache";
import { useSelector } from "react-redux";
import { getSystemInformation } from "../../../common/utils/systemUtils";
import { PRIMARY_COLOR } from "../../../common/constants"
import '../../../../src/index.css';
import "../../forms/ServiceDocName.css";
import { chekDocPdf } from "../../../common/utils/documentUtils";

const useStyles = makeStyles(() => ({
    serviceDocumentPagePdf: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        position: 'relative'
    },
    loadingSpinnerContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    loadingSpinner: {
        color: PRIMARY_COLOR
    },
    pdfDiv: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        padding: '17px 14px'
    },
    hide: {
        display: 'none'
    }
}));

export default function ServiceDocumentWizardPagePdf({hide, renderFormName, serviceDocument}) {
    let document;
    const aptDocument = useSelector(documentCache.getCurrentAptDocument);

    if (serviceDocument) {
        document = {...serviceDocument};
    } else {
        document = aptDocument[0];
    }

    const systemInformation = getSystemInformation();
    let deviceType = systemInformation.platformType;

    const [pdfUrl, setPdfUrl] = useState();

    const styles = useStyles();
    const className = styles.pdfDiv + ' ' + (hide ? styles.hide : '');

    const loadPdf = async() => {
        if (!document?.documentStatus) {
            if (document?.pdfUrl) {
                setPdfUrl(document.pdfUrl);
            } else {
                let docPdf = await chekDocPdf(document);
                setPdfUrl(docPdf?.pdfUrl);
            }
        }
    }

    useEffect(() => {
        if (document) {
            loadPdf();
        }
        // eslint-disable-next-line
    }, [document]);

    const renderPdf = () => {
        if (pdfUrl) {
            if (deviceType === 'Tablet' || deviceType === 'Mobile') {
                return (
                    <div id='serviceDocumentWizardPage' data-testid='serviceDocumentWizardPage' className={styles.serviceDocumentPagePdf}>
                        <MobilePDFReader url={`data:application/pdf;base64,${ pdfUrl }`} isShowHeader = { false } />
                    </div>
                );
            } 
            else {
                return (
                    <div id='serviceDocumentWizardPage' data-testid='serviceDocumentWizardPage' className={styles.serviceDocumentPagePdf}>
                        <MobilePDFReader url={`data:application/pdf;base64,${ pdfUrl }`} isShowHeader = { false } isShowFooter = { false } />
                    </div>
                );
            }
        } else {
            return (
                <div className={styles.loadingSpinnerContainer}>
                    <CircularProgress size={50} className={styles.loadingSpinner} />
                </div>
            );
        }
    }

    return (
        <div className = { className }>
            { renderFormName && 
                <><div className="serviceDocName">{renderFormName()}</div><Divider /></>
            }
            { renderPdf() }
        </div>
    )
}