import React from "react";
import FormField from "./FormField";
import { Grid } from "@material-ui/core";
import "./ReadOnlyTextField.css";
import { renderParagraph } from "../common/FormFieldsUtils";
import { getXSAndSMValueForField } from "../common/Util";

class ReadOnlyTextField extends FormField{
    renderField(props, state){
        let breakPoints = getXSAndSMValueForField(props.isHandheld, props.orientation, 12, 6);
        let fieldText = "";

        let filteredObj = this.fieldDescriptor.attributes.find((attribute) => attribute.attributeId === this.fieldDescriptor.id +"_Text");
        if(Object.keys(filteredObj).length > 0) {
            fieldText = filteredObj?.value;
        }
        props.fieldDescriptor = this.fieldDescriptor;
        return (
            <div>
                {this.fieldDescriptor?.businessRuleApplied !== "Hide" &&
                <>
                <Grid  item = { true } xs={breakPoints.xsValue} sm={breakPoints.smValue}>
                    <div className="readOnlytext"> 
                        {renderParagraph(fieldText)}
                    </div>
                </Grid>
                </>
                }
            </div>
        )
    }
}

export default ReadOnlyTextField;