import EvvButton from "../../../common/components/EvvButton/EvvButton";
import {useEffect, useState} from "react";
import Organizations from "../../../common/components/Organizations";
import {formatOrganization} from "../../../common/utils/formatUtils";
import {makeStyles} from "@material-ui/core/styles";
import AlertDialog from "../../../common/components/AlertDialog";
import GeneralInformation from "../GeneralInformation";
import {useDispatch, useSelector} from "react-redux";
import {appCache} from "../../../cache/slices/app/appSlice";
import syncService from "../../../common/services/syncService";
import * as rdd from 'react-device-detect';
import { getDevicePlatformType } from "../../../common/utils/systemUtils";
import { PRIMARY_COLOR } from "../../../common/constants";
import {useHistory} from "react-router-dom";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useAuth } from "react-oidc-context";
import { isOnline } from "../../forms/common/Util";

const useStyles = makeStyles((theme) => ({
    settingsPage: {
        [theme.breakpoints.up(601)]: {
            ...theme.shadowedContainer,
        }
    },
    settingsContainer: {
        maxWidth: '550px',
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '41px',
        paddingTop: '24px',
        height: '150%'
    },
    settingsContainerForHandheld: {
        maxWidth: '550px',
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '16px',
        paddingRight: '16px',
        paddingTop: '30px'
    },
    logoutButtonContainer: {
        paddingTop: '25px'
    },
    currentOrganizationContainer: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '40px'
    },
    currentOrganizationTitle: {
        fontSize: '18px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: "#4f4f4f",
        paddingBottom: '8px'
    },
    currentOrganization: {
        fontSize: '16px',
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: '14px',
        letterSpacing: 0,
        color: "#4f4f4f"
    },
    orgContainer: {
        height: '150px',
        paddingBottom: '40px',
        overflow: 'hidden',
    },
    organizationsTitle: {
        fontSize: '18px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: "#4f4f4f",
        paddingTop: '30px',
        paddingBottom: '8px'
    },
    organizationsContainer: {
        width: '100%',
        height: '100%',
        overflow: 'scroll',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1
    },
    buttonsContainer: {
        display: 'grid',
        gridAutoFlow: 'column',
        gridAutoColumns: '1fr',
        paddingTop: '30px',
        paddingBottom: '47px'
    },
    buttonsSeparator: {
        paddingRight: '38px'
    },
    changePinLink: {
        fontSize: '16px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: PRIMARY_COLOR,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
        width: '100%'
    },
    separatorSettings: {
        borderBottom: '1px solid #E0E0E0',
        marginTop: '26px',
        width: '100%'
    },
    arrowIcon: {
        fontSize: "16px"
    }
}));

export default function SettingsPage() {
    const organizationsService = useSelector(syncService.organizationService.getResults());
    const user = useSelector(appCache.getUser);
    const dispatch = useDispatch();
    const styles = useStyles();
    const [alertDialogConfig, setAlertDialogConfig] = useState(null);
    // eslint-disable-next-line
    const [currentOrganization, setCurrentOrganization] = useState(null);
    const [selectedOrganization, setSelectedOrganization] = useState(null);
    const [availableOrganizations, setAvailableOrganizations] = useState([]);
    const [selectableOrganizations, setSelectableOrganizations] = useState([]);
    const [isCarelogicMobile, setIsCarelogicMobile] = useState(false);
    let devicePlatformType = getDevicePlatformType(rdd);
    const isHandheld = (devicePlatformType === "Mobile" ? true : false);
    const staffSignatures = useSelector(syncService.staffSignatureService.getResults());
    let hostname = window.location.hostname;
    const history = useHistory();
    const auth = useAuth();
    
    useEffect(() => {
        const primaryOrganizationId = user.primaryOrganizationId;
        const staffId = user?.staffId;
        const filteredStaffSignatures = staffSignatures.filter(ss => ss.staffId === staffId);
        const organizations = syncService.organizationService.getAvailableOrganizations(
                primaryOrganizationId,
                filteredStaffSignatures
            );
        setCurrentOrganization(organizationsService ? organizationsService.find(o => o.organizationId === user.currentOrganizationId) : null);
        setAvailableOrganizations(organizations.filter(o => o.evv === true));
        setSelectableOrganizations(organizations.filter(o => (o.organizationId !== user.currentOrganizationId && o.evv === true)));
        setSelectedOrganization(currentOrganization);

        if (hostname.startsWith("mobile")) {
            setIsCarelogicMobile(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.primaryOrganizationId, user.currentOrganizationId, currentOrganization]);

    const handleLogout = () => {
        showLogoutMessage({
            title: 'Log out',
            message: 'Are you sure you want to log out?'
        })
    };

    const handleOrganizationSelection = (newOrganization) => {
        setSelectedOrganization(newOrganization);
    }

    const handleOrganizationChange = () => {
        setSelectableOrganizations(availableOrganizations.filter(o => o.organizationId !== selectedOrganization.organizationId && o.evv === true));
        setCurrentOrganization(selectedOrganization);
        syncService.changeCurrentOrganization({dispatch}, selectedOrganization);
    }

    const showLogoutMessage = (errorDescriptor) => {
        let dialogMessage = errorDescriptor.message;

        setAlertDialogConfig({
            dialogTitle: errorDescriptor.title,
            dialogMessage: dialogMessage,
            showOkButton: true,
            showCancelButton: true
        });
    }

    const handleClose = (okToLogout) => {
        setAlertDialogConfig(null);

        if (okToLogout){
            if (isCarelogicMobile) {
                if (!isOnline()) {
                    history.push('/loginOffline');
                    return;
                }
                auth.signoutRedirect()
                  .then(() => {
                      appCache.logout(dispatch);
                  })
                  .catch((error) => {
                      console.error("Error during sign-out:", error);
                  });
            } else {
                appCache.logout(dispatch);
            }
        }
    }

    const handleChangeOffLinePin = () => {
        history.push('/changeOfflinePin');
    }

    return (
        <div className={styles.settingsPage}>
            <div className={isHandheld ? styles.settingsContainerForHandheld : styles.settingsContainer}>
                <GeneralInformation/>
                {isHandheld &&
                <div className={styles.logoutButtonContainer}>
                    <EvvButton data-testid='logoutButton' type='primary' onClick={handleLogout}>Log Out</EvvButton>
                </div>
                }

                {isCarelogicMobile &&
                    <>
                    <div className={styles.separatorSettings}></div>
                    <div className={styles.currentOrganizationContainer}>
                        <div className={styles.currentOrganizationTitle}>Account Settings</div>
                        <div className={styles.changePinLink} onClick={handleChangeOffLinePin}>
                            Change Offline PIN <ArrowForwardIosIcon className={styles.arrowIcon} />
                        </div>
                    </div>
                    </>
                }
                
                <div className={styles.currentOrganizationContainer}>
                    <div className={styles.currentOrganizationTitle}>Current Organization</div>
                    <div className={styles.currentOrganization}>{organizationsService ? formatOrganization(organizationsService.find(o => o.organizationId === user.currentOrganizationId)) : ''}</div>
                </div>
                <div className={styles.orgContainer}>
                {selectableOrganizations && selectableOrganizations.length > 0 &&
                <div className={styles.organizationsTitle}>Available Organizations</div>
                }
                {selectableOrganizations && selectableOrganizations.length > 0 &&
                <div className={styles.organizationsContainer}>
                    <Organizations
                    organizations={selectableOrganizations}
                    currentOrganization={selectedOrganization}
                    handleOrganizationChange={handleOrganizationSelection}
                    />
                </div>
                }
                </div>
                <div className={styles.buttonsContainer}>
                    {!isHandheld &&
                    <div className={styles.buttonsSeparator}>
                        <EvvButton data-testid='logoutButton' type='secondary' onClick={handleLogout}>Log Out</EvvButton>
                    </div>
                    }
                    {selectableOrganizations && selectableOrganizations.length > 0 &&
                    <EvvButton data-testid='changeOrgButton' type='primary' onClick={handleOrganizationChange}>Change Organization</EvvButton>
                    }
                </div>
                {alertDialogConfig &&
                <AlertDialog
                    id='SettingsPage'
                    open={alertDialogConfig != null}
                    dialogTitle={alertDialogConfig.dialogTitle}
                    dialogMessage={alertDialogConfig.dialogMessage}
                    showOkButton={alertDialogConfig.showOkButton}
                    okButtonText='Log Out'
                    showCancelButton={alertDialogConfig.showCancelButton}
                    cancelButtonText='Cancel'
                    handleClose={handleClose}
                />
                }
            </div>
        </div>
    )
}
