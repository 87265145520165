export const floatingErrorMessageString = "field(s) have missing/invalid information. Please resolve and try again.";
export const errorMessageString = "is a required field. Please enter a value.";
export const mustEqual = " must equal ";
export const mustNotEqual = " must not equal ";
export const noAppointmentMessage = " There are no scheduled appointments ";
export const noIncompleteTaskMessage = " There are no incomplete tasks ";
export const minLengthMessage = " Minimum length for";
export const alphabetic = " must contain only alphabetic characters.";
export const number = "must be entered as a number.";
export const characterOrSymbols = " must contain only alphabetic characters or symbols.";
export const maxValuMessage = "is greater than the maxValue =";
export const validSSNFieldMsg = "Please enter a valid SSN: 999-99-9999";
export const validPhoneFieldMsg = "Please enter a valid Phone: 999-999-9999";
export const floatingErrorMessageGa = "field(s) have missing information. Please resolve and try again.";
export const addressedErrorMsg  = "Please select an item as Addressed before selecting Next.";
export const noClientsFound = "No clients found";
export const impactErrorMsg = "This is a required field. Please enter a value.";