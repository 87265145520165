import {makeStyles} from "@material-ui/core/styles";
import {Select} from "@material-ui/core";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { PRIMARY_COLOR } from "../../constants";

const useStyles = makeStyles((theme) => ({
    inputContainer: {
        width: '100%',
        // height: theme.textFieldHeight,
        padding: '2px',
        borderRadius: '5px',
        backgroundColor: "#ffffff",
        borderStyle: "solid",
        borderWidth: '1px',
        borderColor: "#afafb3"
    },
    selectRoot: {
        fontSize: '16px',
        fontWeight: "normal",
        fontStyle: "normal",
        letterSpacing: 0,
        color: "#888888",
        height:'24px',
        padding: '6px 42px 6px 12px',
        // padding: '13px 42px 13px 12px',
        '&:focus':{
            backgroundColor:'#ffffff'
        },
    },
    input: {
        width: '100%',
        height: '100%',
        outline: 'none',
        paddingLeft: '10.5px',
        paddingTop: '10.5px',
        paddingBottom: '9.625px',
        backgroundColor: "#ffffff",
        borderStyle: "none",
        borderWidth: 0,
        borderColor: "#ffffff",
        fontSize: '16px',
        fontWeight: "normal",
        fontStyle: "normal",
        letterSpacing: '.21px',
        color: "#4f4f4f"
    },
    icon: {
        color: PRIMARY_COLOR,
    },
    inputContainerDisabled: {
        backgroundColor:'#e0e0e0'
    },
}));

export default function EvvSelect(props){
    const {id, disabled, onChange, value, children, required} = props;
    const styles = useStyles();

    return (
                <Select
                    native={true}
                    data-testid={'EvvSelect' + (id || '')}
                    IconComponent={KeyboardArrowDownIcon}
                    id={id}
                    className={styles.inputContainer + (disabled ? ' ' + styles.inputContainerDisabled : '')}
                    classes={{root: styles.selectRoot, icon: styles.icon}}
                    onChange = {onChange}
                    disabled={disabled}
                    disableUnderline={true}
                    value={value}
                    required={required}
                >
                    {children}
                </Select>
    );
}
