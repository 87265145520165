import {DateTime} from "luxon";
import {useEffect, useRef, useState} from "react";
import Stopwatch from "../../../common/components/Stopwatch";
import AlertDialog from "../../../common/components/AlertDialog";
import {useDispatch, useSelector, useStore} from "react-redux";
import {appCache} from "../../../cache/slices/app/appSlice";
import AppointmentDialog from "../AppointmentDialog";
import {
    documentNotRequired,
    appointmentHasErrors,
    createNewAppointment,
    filterDocumentCrosswalks
} from "../../../common/utils/appointmentUtils";
import syncService from "../../../common/services/syncService";
import {documentCache} from "../../../cache/slices/document/documentCache";
import clientService from "../../../common/services/clientService";
import {clientCache} from "../../../cache/slices/client/clientSlice";
import {useHistory} from "react-router-dom";
import DocumentChooserDialog from "../../forms/DocumentChooserDialog";
import {createNewDocument, handleSaveNoDocument, hasMaxDocsOpened, tabDocAddAction} from "../../../common/utils/documentUtils";
import { getSystemInformation } from "../../../common/utils/systemUtils";
import { MAX_OPEN_DOCUMENTS, NO_DOCUMENT_REQUIRED, START_SESSION, STOP_SESSION } from "../../../common/constants";
import VisitVerification from "../VisitVerificationDialog/VisitVerification";
import VisitVerificationDialog from "../VisitVerificationDialog/VisitVerificationDialog";
import { cloneDeep } from "lodash";
import { getActivity, getDiscriptors, removePropertiesVisit, showVerifySignature } from "../../../common/utils/visitUtils";

let stateCurrentPosition = '';

export default function VisitStopwatch({embedded, durationSize, hideDuration, client, visits, renderSessionToggleComponent, appointment, onhandleToggle, isVisible, forceCD, jestTestCase, call, selectedTab}) {
    const documentCrosswalks = useSelector(syncService.documentCrosswalkService.getResults());
    const serviceDocuments = useSelector(syncService.serviceDocumentService.getResults());
    const serviceDocTab = cloneDeep(useSelector(documentCache.getServiceDocumentsForTabs));
    const [crosswalkedServiceDocuments, setCrosswalkedServiceDocuments] = useState(null);
    const [showAppointmentDialog, setShowAppointmentDialog] = useState(false);
    const [showVisitVerificationDialog, setShowVisitVerificationDialog] = useState(false);
    const [alertDialogConfig, setAlertDialogConfig] = useState(null);
    const [currentAppointment, setCurrentAppointment] = useState(null);
    const [showDocumentChooser, setShowDocumentChooser] = useState(false);
    const [showVisitVerificationSignature, setShowVisitVerificationSignature] = useState(false);
    const [currentVisit, setCurrentVisit] = useState(null);
    const [updatedStatus, setUpdatedStatus] = useState(null);
    const [reasonCodes, setReasonCodes] = useState([]);
    const [reasonCodeUi, setReasonCodeUi] = useState(false);
    const isHandheld = useSelector(appCache.isHandheld);
    const user = useSelector(appCache.getUser);
    const staff = user.staff;
    const dispatch = useDispatch();
    const history = useHistory();
    const stopwatch = useRef({});
    const store = useStore();
    let newDocument = {};
    const evvState = currentVisit?.appointment?.organization?.evvState;
    const [appointmentStatus, setAppointmentStatus] = useState('');
    const [clientData, setClientData] = useState([]);
    const [activity, setActivity] = useState();
    const orgId = user?.currentOrganizationId;

    useEffect(() => {
        const visit = client && visits && visits.find((v) => v.clientId === client.clientId);
        setCurrentVisit(visit ? { ...visit, appointmentStatus: 'In Session' } : null);
    }, [user, client, visits]);

    useEffect(() => {
        if(evvState && orgId){
            getDiscriptors(orgId, evvState, setReasonCodes, setReasonCodeUi);
        }
    }, [evvState, orgId]);

    useEffect(() => {
        if (!activity && currentVisit && !currentVisit?.appointment?.activity && currentVisit?.appointment?.activityId) {
            getActivity(currentVisit, setActivity);
        }
        // eslint-disable-next-line
    }, [currentVisit]);

    const handleStartRequest = (startTimer) => {
        clientService.fetchAddresses({store}, appCache.getClients(store.getState()), setClientData);
        const appointmentToStart = appointment ? {...appointment, status: 'In Session'} : createNewAppointment(DateTime.now(), 'In Session', client);
        if (appointmentToStart) {
            setCurrentAppointment(appointmentToStart);
            setShowAppointmentDialog(true);
            stopwatch.current.startTimer = startTimer;
        }
    }

    const chooseServiceDocument = (serviceDocument, appointment) => {
        setShowDocumentChooser(false);
        if (serviceDocument){
            if (documentNotRequired(serviceDocument)) {
                let appointmentData = appointment ? appointment : currentAppointment;
                handleSaveNoDocument(serviceDocument, client, staff, appointmentData, store, dispatch);
                setAlertDialogConfig(NO_DOCUMENT_REQUIRED);
            } else {
                dispatch(documentCache.setCrosswalkedDocuments([serviceDocument]));
                dispatch(documentCache.setCurrentDocument(serviceDocument));
                dispatch(documentCache.setCurrentData({}));
                clientService.visitClient(currentAppointment.client, DateTime.now().toMillis());
                handleSaveNewDocument(serviceDocument, appointment);
                dispatch(clientCache.selectClient(currentAppointment.client));
                if (isHandheld){
                    history.push({
                        pathname: '/dashboard/facesheet/documents/serviceDocument',
                        state: { serviceDocument: newDocument }
                    });
                } else {
                    history.push({
                        pathname: '/dashboard/facesheet',
                        state: { serviceDocument: newDocument }
                    });
                }
            }
        }
    }

    const handleSaveNewDocument = (document, appointment) => {
        let appointmentForDoc = (appointment && appointment.appointmentId) ? appointment : currentAppointment;
        newDocument = createNewDocument(document, currentAppointment?.client, staff, appointmentForDoc);
        syncService.documentService.saveNewDocument(newDocument, store, dispatch);
        dispatch(documentCache.setCurrentAptDocument([newDocument]));
        tabDocAddAction(serviceDocTab, newDocument, dispatch, currentAppointment?.client);
    }

    const showDocumentsFromCrosswalk = async(appointment) => {
        const filteredServiceDocuments = await filterDocumentCrosswalks(appointment, documentCrosswalks, serviceDocuments, staff);
        if (filteredServiceDocuments && filteredServiceDocuments.length > 0) {
            if (filteredServiceDocuments.length === 1){
                if (documentNotRequired(filteredServiceDocuments[0])) {
                    handleSaveNoDocument(filteredServiceDocuments[0], client, staff, appointment, store, dispatch);
                    setAlertDialogConfig(NO_DOCUMENT_REQUIRED);
                } else {
                    chooseServiceDocument(filteredServiceDocuments[0], appointment);
                }
            } else {
                setCrosswalkedServiceDocuments(filteredServiceDocuments);
                setShowDocumentChooser(true);
            }
        }
    }

    const fetchDocumentCrosswalk = (appointment) => {
        if (appointment && !appointmentHasErrors(appointment)){
            const documentCrosswalkParameters = {
                organizationId: appointment.organizationId
            };

            syncService.documentCrosswalkService.fetch({store, parameters: documentCrosswalkParameters})
                .then(crosswalks => showDocumentsFromCrosswalk(appointment));
        }
    }

    const handleAppointmentDialogClose = (appointment) => {
        setShowAppointmentDialog(false);

        console.log('handleAppointmentDialogClose - appointment');
        console.log(appointment);

        if (appointment && !appointmentHasErrors(appointment)){
            setCurrentAppointment(appointment);
            startVisit(appointment);
        }
    }

    const startVisit = (appointment) => {
        const systemInformation = getSystemInformation();
        const optionsGeolocation = { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 };
        stateCurrentPosition = 'timeout';

        if ((systemInformation.platformType === 'Mobile' || systemInformation.platformType === 'Tablet')
            && (systemInformation.osName === 'macOS' || systemInformation.osName === 'iOS')
            && systemInformation.browserName === 'Safari') {
            const currentPosition = new Promise((resolve) => {
                setTimeout(resolve, 1000, navigator.geolocation.getCurrentPosition(
                    syncService.visitService.startClientVisit(store, appointment, user, stopwatch.current.startTimer, handleVisitStarted),
                    handleSessionEndFailure,
                    optionsGeolocation));
            });

            const timeOutGeolocation = new Promise(() => {
                setTimeout(() => handleGeoLocationNotResponse(), 3000);
            });

            Promise.race([currentPosition, timeOutGeolocation]);
        } else {
            if(forceCD){
                chooseServiceDocument(serviceDocuments[0]); 
            }
            navigator.permissions.query({ name: 'geolocation' }).then((result) => {
                navigator.geolocation.getCurrentPosition(
                    syncService.visitService.startClientVisit(store, appointment, user, stopwatch.current.startTimer, handleVisitStarted),
                    handleSessionEndFailure,
                    optionsGeolocation);
            });
        }
    }

    const handleVisitStarted = (visit, appointment) => {
        stateCurrentPosition = 'success';
        setAlertDialogConfig(null);
        setCurrentVisit(visit);
        if (hasMaxDocsOpened(serviceDocTab, appointment.clientId)) {
            setAlertDialogConfig(MAX_OPEN_DOCUMENTS);
        } else {
            fetchDocumentCrosswalk(appointment);
        }
    }

    const handleStopRequest = (stopTimer, restartTimer) => {
        stopwatch.current.restartTimer = restartTimer;
        const systemInformation = getSystemInformation();
        const optionsGeolocation = { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 };
        stateCurrentPosition = 'timeout';

        if (jestTestCase && jestTestCase.testShowVisitVerificationDialog) {
            handleVisitEnded(jestTestCase.testVisit);
        }

        if ((systemInformation.platformType === 'Mobile' || systemInformation.platformType === 'Tablet')
            && (systemInformation.osName === 'macOS' || systemInformation.osName === 'iOS')
            && systemInformation.browserName === 'Safari') {
            const currentPosition = new Promise((resolve) => {
                setTimeout(resolve, 1000, navigator.geolocation.getCurrentPosition(
                    syncService.visitService.endClientVisit(store, user, client, stopTimer, handleVisitEnded),
                    handleSessionEndFailure,
                    optionsGeolocation));
            });

            const timeOutGeolocation = new Promise(() => {
                setTimeout(() => handleGeoLocationNotResponse(), 3000);
            });

            Promise.race([currentPosition, timeOutGeolocation]);
        } else {
            navigator.permissions.query({ name: 'geolocation' }).then((result) => {
                navigator.geolocation.getCurrentPosition(
                    syncService.visitService.endClientVisit(store, user, client, stopTimer, handleVisitEnded),
                    handleSessionEndFailure,
                    optionsGeolocation);
            });
        }
    }

    const handleVisitEnded = (visit) => {
        stateCurrentPosition = 'success';
        setAlertDialogConfig(null);
        console.log("Visit ended: ");
        console.log(visit);
        setCurrentVisit(visit);
        setShowVisitVerificationDialog(true);
        setShowVisitVerificationSignature(false);
    }

    const handleSessionEndFailure = () => {
        stateCurrentPosition = 'failure';
        failVisit(stateCurrentPosition);
    }

    function handleGeoLocationNotResponse() {
        if (stateCurrentPosition === 'timeout') {
            failVisit(stateCurrentPosition);
        }
    }

    const failVisit = (stateCurrentPosition) => {
        const actionType = stopwatch ? STOP_SESSION : START_SESSION;
        let dialogMessage = '';

        if (stateCurrentPosition === 'failure') {
            dialogMessage = `Cannot ${actionType} for a Client with location services turned off. Please turn on location services for your browser and try again.`
        } else if (stateCurrentPosition === 'timeout') {
            dialogMessage = `Cannot ${actionType}. Please set Location Services on your device to Allow for your device and browser.`
        }

        if (stateCurrentPosition === 'failure' || stateCurrentPosition === 'timeout') {
            setAlertDialogConfig({
                dialogTitle: 'GPS Error',
                dialogMessage: dialogMessage,
                showOkButton: true,
                showCancelButton: false
            });
        }
    }

    const handleVisitVerificationDialogClose = (verifiedVisit) => {
        setShowVisitVerificationDialog(false);

        if (verifiedVisit){
            verifiedVisit =  {...removePropertiesVisit(verifiedVisit)};
            syncService.visitService.verifyClientVisit(store, user, client, verifiedVisit);
        } else {
            stopwatch.current.restartTimer();
            syncService.visitService.cancelVerifyClientVisit(store, user, client);
        }
        setShowVisitVerificationSignature(false);
        setShowVisitVerificationDialog(false);
    }

    const handleAlertDialogClose = () => {
        setAlertDialogConfig(null);
    }

    const visitVerificationNextClick = (updatedVisit) => {
        setCurrentVisit(updatedVisit);
        setShowVisitVerificationDialog(false);
        setShowVisitVerificationSignature(true);
    }

    const handlePrevious = (updatedVisit) => {
        setCurrentVisit(updatedVisit);
        setUpdatedStatus(updatedVisit.appointmentStatus);
        setShowVisitVerificationSignature(false);
        setShowVisitVerificationDialog(true);
    }

    const handleAppointmentStatusChange = (newState) => {
        setAppointmentStatus(newState);
    };

    return (
        <>
            <Stopwatch
                sessionStartTime={currentVisit && currentVisit.complete === 0 && Number.isInteger(currentVisit.startDateTime) && currentVisit.clientId === client.clientId ? currentVisit.actualStartDateInstance : null}
                onStartRequest={handleStartRequest}
                onStopRequest={handleStopRequest}
                embedded={embedded}
                durationSize={durationSize}
                hideDuration={hideDuration}
                renderStopwatchToggleComponent={renderSessionToggleComponent}
                onhandleToggle={onhandleToggle}
                isVisible={isVisible}
                isHandheld ={isHandheld}
            />
            {showAppointmentDialog &&
            <AppointmentDialog
                onClose={handleAppointmentDialogClose}
                disableStatus={true}
                appointment={currentAppointment}
                clientData = {clientData}
                call = { 'startSession' }
            />
            }
            {showVisitVerificationDialog &&
            <VisitVerification
                visit={currentVisit}
                reasonCodes={reasonCodes}
                onClose={handleVisitVerificationDialogClose}
                onvisitVerificationNextClick={visitVerificationNextClick}
                updatedStatus={updatedStatus}
                appointmentStatusChange={handleAppointmentStatusChange}
                evvState={evvState}
                reasonCodeUi = {reasonCodeUi}
                call={call}
                selectedTab={selectedTab}
            />
            }
            {showVisitVerificationSignature && showVerifySignature(evvState, appointmentStatus, currentVisit?.appointment, activity) &&
                <VisitVerificationDialog
                    visit={currentVisit}
                    reasonCodes={reasonCodes}
                    onClose={handleVisitVerificationDialogClose}
                    onhandlePrevious={handlePrevious}
                    call={call}
                    selectedTab={selectedTab}
                />
            }
            {alertDialogConfig &&
            <AlertDialog
                open={true}
                dialogTitle={alertDialogConfig.dialogTitle}
                dialogMessage={alertDialogConfig.dialogMessage}
                showOkButton={alertDialogConfig.showOkButton}
                okButtonText={alertDialogConfig.okButtonText ? alertDialogConfig.okButtonText : 'Ok'}
                showCancelButton={alertDialogConfig.showCancelButton}
                handleClose={handleAlertDialogClose}
            />
            }
            {showDocumentChooser &&
            <DocumentChooserDialog documents={crosswalkedServiceDocuments} onClose={chooseServiceDocument} />
            }
        </>
    )
}
