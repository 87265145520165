import {makeStyles} from "@material-ui/core/styles";
import {ClickAwayListener, Fade, IconButton, Popper} from "@material-ui/core";
import {useRef, useState} from "react";
import {formatMedication} from "../../../common/utils/formatUtils";
import { QUATERNARY_COLOR } from "../../../common/constants";

const useStyles = makeStyles((theme) => ({
    medicationInformationCell: {
        display: "flex",
    },
    textColumn: {
        display: "flex",
        flexDirection: 'column',
        paddingRight: '10px'
    },
    medicationDescription: {
        fontSize: '16px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: "#424242"
    },
    medicationInstructions: {
        fontSize: '14px',
        fontWeight: "normal",
        fontStyle: "normal",
        letterSpacing: 0,
        color: "#424242"
    },
    buttonColumn: {
        minWidth: '24px',
        alignSelf: "center",
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row-reverse'
    },
    commentsButton: {
        padding: "0px"
    },
    commentsPopper: {
        ...theme.shadowedContainer,
        display: 'flex',
        flexDirection: 'column',
        width: '288px',
        height: '162px',
        borderRadius: '5px',
        backgroundColor: "#efefef",
        borderStyle: "solid",
        borderWidth: '1px',
        borderColor: "#979797",
        paddingLeft: '18px',
        paddingRight: '18px'
    },
    commentsTitle: {
        fontSize: '18px',
        fontWeight: "bold",
        fontStyle: "normal",
        // lineHeight: 9.4,
        // letterSpacing: -0.08,
        color: QUATERNARY_COLOR,
        paddingTop: '11px',
        paddingBottom: '5px',
        borderBottom: '1px solid #979797'
    },
    comments: {
        fontSize: '16px',
        fontWeight: "normal",
        fontStyle: "normal",
        // lineHeight: 10,
        // letterSpacing: -0.07,
        color: "#1c1c1c",
        paddingTop: '18px',
        paddingBottom: '8px'
    },
    arrow: {
        display: 'none',
        position: 'absolute',
        fontSize: '7px',
        width: '3em',
        height: '3em',
        '&:before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid'
        }
    },
    // '&[x-placement*="right"] .arrow': {
    //     left: 0,
    //     width: 0,
    //     height: 0,
    //     borderTop: '1em solid transparent',
    //     borderBottom: '1em solid transparent',
    //     borderRight: '1em solid #2c3e50',
    //     marginLeft: '-0.9em',
    //     '&:before': {
    //         borderWidth: '1em 1em 1em 0',
    //         borderColor: 'transparent white transparent transparent'
    //     }
    // }
}));

export default function MedicationInfoCell({medication}) {
    const [showComments, setShowComments] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const arrowRef = useRef();
    const styles = useStyles();

    const handleClickAway = (event) => {
        setAnchorEl(null);
        setShowComments(false);
    }

    const handleClick = (event) => {
        if (anchorEl){
            setAnchorEl(null);
            setShowComments(false);
        } else {
            setAnchorEl(event.currentTarget);
            setShowComments(true);
        }
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
        <div data-testid='MedicationInfoCell' className={styles.medicationInformationCell}>
            <div className={styles.textColumn}>
                <div className={styles.medicationDescription}>{formatMedication(medication)}</div>
                <span>{medication.frequency}</span>
                <div className={styles.medicationInstructions}>{medication.instructions}</div>
            </div>
            <div className={styles.buttonColumn}>
                {medication.comments &&
                <IconButton data-testid='MedicationInfoCell_CommentsButton' type="submit" className={styles.commentsButton} onClick={handleClick}>
                    <img src='../images/icInfoOutline.svg' alt="show comment"/>
                    <Popper style={{zIndex: 5}} open={showComments} anchorEl={anchorEl} placement='right' transition modifiers={{
                        // arrow: {
                        //     enabled: true,
                        //     element: '#popperArrow',
                        // }
                    }}>
                        {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout={350}>
                                <div className={styles.commentsPopper}>
                                    <span id='popperArrow' className={styles.arrow} ref={arrowRef} />
                                    <div id='MedicationInfoCell_CommentsTitle' className={styles.commentsTitle}>Comments</div>
                                    <div className={styles.comments}>{medication.comments}</div>
                                </div>
                            </Fade>
                        )}
                    </Popper>
                </IconButton>
                }
            </div>
        </div>
        </ClickAwayListener>
    );
}
