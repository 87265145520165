import {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from "@material-ui/core/styles";
import ActiveSessions from "../ActiveSessions";
import EvvButton from "../../../common/components/EvvButton";

const useStyles = makeStyles((theme) => ({
    dialogPaper: {
        width: '659px',
        height: '803px'
    },
    dialogTitle: {
        height: '42px',
        width: 'auto',
        fontSize: '16px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: "#ffffff",
        paddingLeft: '16px',
        display: 'flex',
        alignItems: 'center'
    },
    dialogContent: {
        padding: "10px 0px",
    },
    dialogText: {
        fontSize: '16px',
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: "20px",
        letterSpacing: 0,
        color: "#747474"
    },
    okButtonGroup: {
        padding: "0px 16px 8px 16px",
    },
    okCancelButtonGroup: {
        padding: "0px 16px 8px 16px",
        justifyContent: 'space-between'
    },
    okButton: {
        padding: '0',
        minWidth: '125px'
    },
    cancelButton: {
        padding: '0'
    },
    label: {
        flexDirection: 'column',
        textTransform: "none",
        color: '#888888'
    }
}));

export default function ActiveSessionsDialog({handleClose}){
    const [open, setOpen] = useState(true);
    const styles = useStyles();

    const handleActiveSessionSelected = (e) => {
        handleOk();
    }

    const handleOk = () => {
        setOpen(false);

        if (handleClose){
            handleClose(true);
        }
    };

    return (
        <div>
            <Dialog
                classes={{paper: styles.dialogPaper}}
                open={open}
                disableEscapeKeyDown={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle classes={{root: styles.dialogTitle}} id="alert-dialog-title">Active Sessions</DialogTitle>
                <DialogContent classes={{root: styles.dialogContent}}>
                    <ActiveSessions onActiveSessionSelected={handleActiveSessionSelected} />
                </DialogContent>
                <DialogActions classes={{root: styles.okButtonGroup}}>
                    <div className={styles.okButton}>
                    <EvvButton type='primary' onClick={handleOk} > Close </EvvButton>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
}
