import { React, useEffect, useState } from 'react';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { makeStyles } from '@material-ui/core';
import { updateNextStaffFun } from './SignaturePageUtil';

const useStyles = makeStyles(() => ({
    staffList: {
        justifyContent: "space-between",
        display: "flex",
        color: '#3b276a',
        fontWeight: 'bold',
        fontSize: '16px',
        letterSpacing: '0.14px'
    },
    checkboxArrowIcon: {
        position: "relative",
    }
}));

function StaffList(props) {
    let { searchInput, setShowStaffChooser, setSelectedStaff, user, documentController, serviceDocument, externalSignatures } = props;
    const staffSignatures = documentController.dataProvider.getStaffSignatures();

    const styles = useStyles();

    const [staffMembers, setStaffMembers] = useState([]);
    const [combinedMembers, setCombinedMembers] = useState([]);

    const getSupervisors = () => {
        let supervisoryList = [];

        if (staffSignatures && staffSignatures?.length > 0) {
            let supervisoryIds = staffSignatures[0]?.supervisoryIds;
            if (supervisoryIds && supervisoryIds?.length > 0) {
                supervisoryList = staffMembers.filter(item => supervisoryIds?.includes(item.staffId));
            }
        }

        return supervisoryList;
    }

    const getMembersToShow = () => {
        if (searchInput === "") {
            let supervisoryList = getSupervisors();
            setCombinedMembers(supervisoryList);
        } else {
            setCombinedMembers(staffMembers);
        }
    }

    useEffect(() => {
        setStaffMembers(documentController.dataProvider.getStaffForOrganization(user.currentOrganizationId) || []);
    }, [documentController.dataProvider, user.currentOrganizationId]);

    useEffect(() => {
        if (staffMembers && staffMembers?.length > 0) {
            getMembersToShow();
        }
        // eslint-disable-next-line
    }, [searchInput, staffMembers, staffSignatures]);

    const staffMembersList = combinedMembers?.filter((el) => {
        if (searchInput === '') {
            return el;
        } else {
            return ( el?.value?.toLowerCase().includes(searchInput) || el?.staffId?.toString().includes(searchInput) );
        }
    });

    const handleStaffChange = (evt) => {
        updateNextStaffFun(serviceDocument, documentController, externalSignatures, evt);
        setSelectedStaff(evt);
        setShowStaffChooser(false);
    };

    return (
        <>
            {staffMembersList?.map((staffMember) => (
                <>
                    <div className = {styles.staffList} onClick={() => handleStaffChange(staffMember)}>
                        {staffMember?.value} <KeyboardArrowRightIcon className = {styles.checkboxArrowIcon} />                
                    </div>
                    <hr></hr>
                </>
            ))}
        </>
    )
}

export default StaffList