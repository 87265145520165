import {useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from "@material-ui/core/styles";
import {APPOINTMENT_START_STOP_DATE_TIME_FORMAT, defaultDateAsString, defaultTimeAsString, stringToDate, stringToDateWithFormat} from "../../../common/utils/formatUtils";
import {combineDateAndTime, validateCurrentDate, validateCurrentTime} from "../../../common/utils/dateTimeUtils";
import EvvButton from "../../../common/components/EvvButton";
import StackedField from "../../../common/components/StackedField";
import {TextField} from "@material-ui/core";
import {DateTime} from "luxon";
import EvvSelect from "../../../common/components/EvvSelect";
import AlertDialog from "../../../common/components/AlertDialog";
import TimePickerField from "../../../common/components/TimePicker/TimePickerField";

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Stack from '@mui/material/Stack';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import { styled } from "@material-ui/styles";
import { renderReasonCodesOptions } from '../../forms/common/Util';
import { ALERT_DIALOG_OUT_OF_RANGE, ALERT_DIALOG_OVERNIGHT, PRIMARY_COLOR } from '../../../common/constants';
import { appointmentOverlaps, getInSessionVisits, isEvvActivity, validDateForEditTimes } from '../../../common/utils/appointmentUtils';
import { useSelector } from 'react-redux';
import syncService from '../../../common/services/syncService';
import { appCache } from '../../../cache/slices/app/appSlice';
import CircularProgress from "@material-ui/core/CircularProgress";

const CancelText=styled('span')({
    textTransform:'initial'
});
const OkText=styled('span')({
    textTransform:'initial'
});

const useStyles = makeStyles(() => ({
    dialogPaper: {
        width: '400px',
        maxWidth: '400px'
    },
    dialogTitle: {
        backgroundColor: PRIMARY_COLOR,
        maxWidth: '660px',
        maxHeight: '800px',
        height: '41.4px',
        width: 'auto',
        fontSize: '18px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: "#ffffff",
        paddingLeft: '16px',
        display: 'flex',
        alignItems: 'center'
    },
    dialogContent: {
        padding: "19px 19px 25px 19px",
    },
    timesGrid: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        columnGap: '26px'
    },
    okCancelButtonGroup: {
        padding: "0px 16px 15px 16px",
        justifyContent: 'space-between'
    },
    okButton: {
        padding: '0',
        minWidth: '125px'
    },
    cancelButton: {
        padding: '0'
    },
    label: {
        flexDirection: 'column',
        textTransform: "none",
        color: '#888888'
    },
    filterSelect: {
        width: '100%',
        height: '27px',
        borderRadius: '2px',
        background: "transparent",
        borderStyle: "solid",
        borderWidth: '1px',
        borderColor: "#b6b6b6"
    },
    errorMessage : {
        position: 'relative',
        bottom: '-5px',
        fontSize: '13px',
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: '16px',
        letterSpacing: 0,
        color: "#dc0707"
    },
    errorMessageForDate : {
        position: 'relative',
        bottom: '17px',
        fontSize: '13px',
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: '16px',
        letterSpacing: 0,
        color: "#dc0707"
    },
    selectValue: {
        fontSize: '16px',
        fontWeight: "normal",
        fontStyle: "normal",
        color: "#a0a0a0"
    },
    loadingSpinner: {
        color: PRIMARY_COLOR
    },
    loadingSpinnerContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    cursorColor: {
        '& .MuiOutlinedInput-input': { 
            caretColor: '#888888'
        }
    }
}));


export default function EditTimesDialog({visit, reasonCodes, onClose, reasonCodeUi}){
    const startDateTimeAsDate = visit?.originalStartDate ? DateTime.fromMillis(visit?.startDateTime) : DateTime.fromMillis(visit?.appointment?.startDateInstance?.ts);
    const endDateTimeAsDate = DateTime.fromMillis(visit.endDateTime);
    const maxDate = stringToDate(new Date().toLocaleString('en-US', { year: 'numeric', month:'2-digit',day:'2-digit' }));
    const actualVisitStartDateTime = stringToDateWithFormat(visit.beginActualDateTime, APPOINTMENT_START_STOP_DATE_TIME_FORMAT);
    const priorDateForStarDate = actualVisitStartDateTime.minus({day : 1 });
    const minDateStarDate = new Date((priorDateForStarDate.toLocaleString('en-US', { year: 'numeric', month:'2-digit',day:'2-digit' })));
    const maxDateStarDate = new Date((actualVisitStartDateTime.toLocaleString('en-US', { year: 'numeric', month:'2-digit',day:'2-digit' })));
    const [appointmentDate, setAppointmentDate] = useState(defaultDateAsString(startDateTimeAsDate));
    const [appointmentEndDate, setAppointmentEndDate] = useState(defaultDateAsString(endDateTimeAsDate));
    const [startTime, setStartTime] = useState(defaultTimeAsString(startDateTimeAsDate));
    const [endTime, setEndTime] = useState(defaultTimeAsString(endDateTimeAsDate));
    let incorrectRangeDates = false;
    const [reasonForChange, setReasonForChange] = useState(visit.editTimesReason ? visit.editTimesReason : visit.savedEditTimesReason || '');
    const [comments, setComments] = useState(visit.editTimesComments ? visit.editTimesComments : visit.savedEditTimesComments || '');
    const [saveAttempted, setSaveAttempted] = useState(false);
    const [selectDatePicker, setSelectDatePicker] = useState(false);
    const [alertDialogConfig, setAlertDialogConfig] = useState(null);
    const [openAlertDialog, setOpenAlertDialog] = useState(true);
    const [notAllowedMultiBookSchedule, setNotAllowedMultiBookSchedule] = useState(false);
    const appointments = useSelector(syncService.appointmentService.getResults());
    const user = useSelector(appCache.getUser);
    const staff = user.staff;
    const styles = useStyles();
    let isLoading = useSelector(syncService.descriptorService.isLoading());   
    const [inSessionVisits, setInSessionVisits] = useState([]);
    const activities = useSelector(syncService.activityService.getResults());
    const [activity, setActivity] = useState(visit?.appointment?.activity);

    useEffect(() => {
        getInSessionVisits(setInSessionVisits);
    }, []);

    useEffect(() => {
        if(visit?.appointment?.activityId && activities && activities.length > 0 ) {
            const objActivity = activities.find(a => a?.activityId === visit?.appointment?.activityId);
            setActivity(objActivity);
        }
        // eslint-disable-next-line
    }, [activities]);

    const fieldToSetterMap = {
        startTime: setStartTime,
        endTime: setEndTime,
        reasonForChange: setReasonForChange,
        comments: setComments
    };

    const handleTextFieldChange = (evt) => {
        fieldToSetterMap[evt.target.id](evt.target.value);
    };

    const hasTimeChanged = () => {
        return startTime !== defaultTimeAsString(startDateTimeAsDate) || appointmentDate !== defaultTimeAsString(startDateTimeAsDate) ||
               endTime !== defaultTimeAsString(endDateTimeAsDate) || appointmentEndDate !== defaultTimeAsString(endDateTimeAsDate);
    };

    const calculateEditedDate = (initialDate, time) => {
        const initialDateStartOfDay = initialDate.startOf("day");
        const hours = time.substring(0,2);
        const minutes = time.substring(3,5);
        const meridian = time.substring(6,8);
        return initialDateStartOfDay.plus({hours: getHours(hours, meridian), minutes: parseInt(minutes)});
    }

    const getHours = (hours, meridian) => {
        let actualHours = hours;
        if(meridian === 'AM' && hours === "12") {
            actualHours = 0;
        }
        actualHours = (parseInt(actualHours) + (meridian === 'PM' && hours !== "12" ? 12 : 0));
        return actualHours;
    }

    const handleDateOrTimeChange = (evt) => {
        setSelectDatePicker(true);
        handleTextFieldChange(evt);
        setNotAllowedMultiBookSchedule(false);
    }

    const handleSave = () => {
        if(overlapAppointment()) {
            setNotAllowedMultiBookSchedule(true);
        } else {
            if (isEvvActivity(activity) && (reasonForChange.length === 0 || comments.length === 0 || incorrectRangeDates)) {
                setSaveAttempted(true);
            } else {
                let outOfRangeDate = validDateForEditTimes(appointmentDate);
                if(outOfRangeDate === 'true'){
                    setAlertDialogConfig({
                        dialogId: ALERT_DIALOG_OUT_OF_RANGE,
                        dialogTitle: 'Confirm appointment date',
                        dialogMessage:  `This appointment will not be visible within the EVV application because it is out of the date range limit. Do you want to save and continue?`,
                        showOkButton: true,
                        okButtonText: 'Yes',
                        showCancelButton: true,
                        cancelButtonText: 'No'
                    });
                    setOpenAlertDialog(true);
                    return;
                }
                returnInformationToVisit();
                if (!selectDatePicker) {
                    returnInformationToVisit();
                    return;
                }
                if (overnightValidation()) {
                    setAlertDialogConfig({
                        dialogId: ALERT_DIALOG_OVERNIGHT,
                        dialogTitle: 'Overnight Appointment',
                        dialogMessage: 'You have entered a Start Time and End Time that will result in an activity that spans 2 days. If this is correct, click OK, otherwise click Cancel to correct.',
                        showOkButton: true,
                        okButtonText: 'Ok',
                        showCancelButton: true,
                        cancelButtonText: 'Cancel'
                    });
                    return;
                }else {
                    returnInformationToVisit();
                }
            }
        }
    };

    const overlapAppointment = () => {
        let overlapAppointment = false;
        const startTimeFormat = getHoursTime(startTime);
        const endTimeFormat = getHoursTime(endTime);

        const startDateTime = combineDateAndTime(stringToDate(appointmentDate).startOf('day'), startTimeFormat);
        const endDateTime = combineDateAndTime(stringToDate(appointmentEndDate).startOf('day'), endTimeFormat);
    
        let appointment = {
            startDateInstance: startDateTime,
            endDateInstance: endDateTime,
            appointmentId: visit.appointmentId
        }
        const overlaps = appointmentOverlaps(inSessionVisits, staff, appointment, appointments);
        if(overlaps) {
            if(staff?.isMultiBookSchedule === false) {
                overlapAppointment = true;
            }
        }
        return overlapAppointment;
    }

    const returnInformationToVisit = () => {
        const editedVisit = {
            ...visit,
            editTimesReason: reasonForChange,
            editTimesComments: comments,
            savedEditTimesReason: reasonForChange,
            savedEditTimesComments: comments,
            editTimesEventType: 'CHANGETiME',
            actualStartDateInstance: combineDateAndTime(stringToDate(appointmentDate).startOf('day'), getHoursTime(startTime)),
            actualEndDateInstance: combineDateAndTime(stringToDate(appointmentDate).startOf('day'), getHoursTime(endTime))

        }

        if (hasTimeChanged()){
            if (!editedVisit.originalStartDate) {
                editedVisit.originalStartDate = visit.startDateTime;
                editedVisit.originalEndDate = visit.endDateTime;
            }
            editedVisit.startDateTime = calculateEditedDate(stringToDate(appointmentDate),  getHoursTime(startTime)).toMillis();
            editedVisit.endDateTime = calculateEditedDate(stringToDate(appointmentEndDate),  getHoursTime(endTime)).toMillis();
        }

        if (onClose){
            onClose(editedVisit);
        }
    }

    const handleCancel = () => {
        if (onClose){
            onClose(null);
        }
    }

    const handleAlertDialogClose = (okClicked) => {
        setAlertDialogConfig(null);
        setOpenAlertDialog(false);
        if (okClicked && alertDialogConfig.dialogId === ALERT_DIALOG_OUT_OF_RANGE) {
            returnInformationToVisit();
            return;
        }
        if (!okClicked && alertDialogConfig.dialogId === ALERT_DIALOG_OVERNIGHT) {
            return;
        }
        if (okClicked && alertDialogConfig.dialogId === ALERT_DIALOG_OVERNIGHT) {
            returnInformationToVisit();
        }
        onClose(null);
    }

    const overnightValidation = () => {
        const startTimeFormat = getHoursTime(startTime);
        const endTimeFormat = getHoursTime(endTime);

        const startDateTime = combineDateAndTime(stringToDate(appointmentDate).startOf('day'), startTimeFormat);
        const endDateTime = combineDateAndTime(stringToDate(appointmentEndDate).startOf('day'), endTimeFormat);
        return !incorrectRangeDates && endDateTime > startDateTime && appointmentDate !== appointmentEndDate;
    }

    const validateDates = () => {
        const startTimeFormat = getHoursTime(startTime);
        const endTimeFormat = getHoursTime(endTime);

        const startDateTime = combineDateAndTime(stringToDate(appointmentDate).startOf('day'), startTimeFormat);
        const endDateTime = combineDateAndTime(stringToDate(appointmentEndDate).startOf('day'), endTimeFormat);

        incorrectRangeDates = endDateTime < startDateTime && hasTimeChanged();

        return incorrectRangeDates;
    }

    const durationExceeded = () => {
        let startDateTime = calculateEditedDate(stringToDate(appointmentDate), getHoursTime(startTime)).toMillis();
        let endDateTime = calculateEditedDate(stringToDate(appointmentEndDate), getHoursTime(endTime)).toMillis();
        let durationExceeded = false;

        let diffInMs = endDateTime - startDateTime;
        const seconds = diffInMs / 1000;
        const minutes = seconds / 60;

        const hrs = Math.floor(minutes / 60);
        if (hrs >= 24) {
            durationExceeded = true;
        }
       return durationExceeded;
    }

    const getHoursTime = (time) => {
         return typeof time === "string" && time.includes("M") ? time : time.toLocaleString('en-US', { hour: '2-digit', minute:'2-digit', hour12: true });
    }

    const getReasonCodesUI = () => {
        if (isLoading && !reasonCodeUi){
            return (
                <div className={styles.loadingSpinnerContainer}>
                    <CircularProgress size={50} className={styles.loadingSpinner} />
                </div>
            );
        } else {
            return ( 
                <div>
                    <StackedField mandatory={true} label='Reason for Change' paddingTop="22px">
                        <EvvSelect id='reasonForChange' value={reasonForChange} className={styles.filterSelect} onChange={handleTextFieldChange}>
                            {renderReasonCodesOptions(reasonCodes)}
                        </EvvSelect>
                        {saveAttempted && reasonForChange.length === 0 && hasTimeChanged() && (
                            <div data-testid='reasonRequired' className={styles.errorMessage}>Reason For Change is a required field. Please complete and save.</div>
                        )}
                    </StackedField>
                </div>
            );
        }
    }

    const renderContent = () => {
        return (
            <div style = {isLoading && !reasonCodeUi ? {opacity:0.3}: {}}>
                <div className={styles.timesGrid}>
                    <div>
                        <StackedField label='Start Date' paddingTop="17px">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Stack>
                                    <MobileDatePicker
                                    value={appointmentDate}
                                    showToolbar={false}
                                    minDate={isEvvActivity(activity) ? minDateStarDate : null}                                    
                                    maxDate={maxDateStarDate}
                                    onChange={(newValue) => {
                                        setAppointmentDate(`${newValue.toLocaleString('en-US', { year: 'numeric', month:'2-digit',day:'2-digit' })}`)
                                        setSelectDatePicker(true)
                                        setNotAllowedMultiBookSchedule(false);
                                    }}
                                    cancelText={<CancelText>Cancel</CancelText>}
                                    okText={<OkText>Ok</OkText>}
                                    renderInput={(params) => <TextField disabled={false}
                                        id="appointmentStartDate"
                                        onChange={handleDateOrTimeChange}
                                        error={appointmentDate===""}
                                        helperText={appointmentDate==="" ? '' : ' '}
                                        {...params}
                                        focused={false}
                                    />}
                                    />
                                </Stack>
                            </LocalizationProvider>
                        </StackedField>
                        {validateCurrentDate(appointmentDate) &&
                            <div className={styles.errorMessageForDate}>Start Date cannot be a future date.</div>
                        }
                    </div>
                    <div>
                        <StackedField label='End Date' paddingTop="17px">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Stack>
                                    <MobileDatePicker
                                    value={appointmentEndDate}
                                    showToolbar={false}
                                    minDate={isEvvActivity(activity) ? new Date(defaultDateAsString(actualVisitStartDateTime)) : null} 
                                    maxDate={new Date(defaultDateAsString(maxDate))}
                                    onChange={(newValue) => {
                                        setAppointmentEndDate(`${newValue.toLocaleString('en-US', { year: 'numeric', month:'2-digit',day:'2-digit' })}`)
                                        setSelectDatePicker(true)
                                        setNotAllowedMultiBookSchedule(false);
                                    }}
                                    cancelText={<CancelText>Cancel</CancelText>}
                                    okText={<OkText>Ok</OkText>}
                                    renderInput={(params) => <TextField disabled={false}
                                        id="appointmentEndDate"
                                        onChange={handleDateOrTimeChange}
                                        error={appointmentDate===""}
                                        helperText={appointmentDate==="" ? '' : ' '}
                                        {...params}
                                        focused={false}
                                    />}
                                    />
                                </Stack>
                            </LocalizationProvider>
                        </StackedField>
                        {validateCurrentDate(appointmentEndDate) &&
                        <div className={styles.errorMessageForDate}>End Date cannot be a future date.</div>
                        }
                    </div>
                </div>
                <div className={styles.timesGrid}>
                    <div>
                        <StackedField label='Start Time' paddingTop="0px">
                            <TimePickerField id='startTime' value={startTime} onChange={handleDateOrTimeChange}/>
                        </StackedField>
                        {validateCurrentTime(startTime,appointmentDate) &&
                        <div className={styles.errorMessage}>Start Time cannot be a future time.</div>
                        }
                        {!validateCurrentTime(startTime,appointmentDate) && validateDates() &&
                        <div className={styles.errorMessage}>Start Time cannot be after the End Time.</div>
                        }
                        {durationExceeded() &&
                        <div className={styles.errorMessage}>Appointment must be less than 24 hours.</div>
                        }
                    </div>
                    <div>
                        <StackedField label='Stop Time' paddingTop="0px" >
                            <TimePickerField id='endTime' value={endTime} onChange={handleDateOrTimeChange}/>
                        </StackedField>
                        {validateCurrentTime(endTime,appointmentEndDate) &&
                        <div className={styles.errorMessage}>Stop Time cannot be a future time.</div>
                        }
                        {!validateCurrentTime(endTime,appointmentEndDate) && validateDates() &&
                        <div className={styles.errorMessage}>Stop Time cannot be before the Start Time.</div>
                        }
                        {durationExceeded() &&
                        <div className={styles.errorMessage}>Appointment must be less than 24 hours.</div>
                        }
                    </div>
                </div>
                {notAllowedMultiBookSchedule &&
                    <div className={styles.errorMessage}>These times conflict with another appointment.</div>
                }
                {isEvvActivity(activity) && getReasonCodesUI()}
                {isEvvActivity(activity) &&
                <div>
                    <StackedField mandatory={true} label='Comments' paddingTop="22px" >
                        <TextField
                            id="comments"
                            value={comments}
                            onChange={handleTextFieldChange}
                            multiline={true}
                            rows={5}
                            focused={false}
                            variant="outlined"
                            fullWidth={true}
                            className={styles.cursorColor}
                            inputProps={{
                                maxLength: 256
                            }}                        />
                        {saveAttempted && comments.length === 0 && hasTimeChanged() &&
                        <div data-testid='commentsRequired' className={styles.errorMessage} >Comments is a required field. Please complete and save.</div>
                        }
                    </StackedField>
                </div>
                }
                {alertDialogConfig &&
                <AlertDialog
                    open={openAlertDialog}
                    dialogTitle={alertDialogConfig.dialogTitle}
                    dialogMessage={alertDialogConfig.dialogMessage}
                    showOkButton={alertDialogConfig.showOkButton}
                    showCancelButton={alertDialogConfig.showCancelButton}
                    okButtonText={alertDialogConfig.okButtonText || 'Ok'}
                    cancelButtonText={alertDialogConfig.cancelButtonText || 'Cancel'}
                    handleClose={handleAlertDialogClose}
                />
                }
            </div>
        );
    }

    return (
        <div>
            <Dialog
                data-testid={'EditTimesDialog'}
                open={true}
                disableEscapeKeyDown={true}
                classes={{paper: styles.dialogPaper}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                disableEnforceFocus={true}
            >
                <DialogTitle disableTypography={true} classes={{root: styles.dialogTitle}} id="alert-dialog-title">Edit Times</DialogTitle>
                <DialogContent classes={{root: styles.dialogContent}}>
                    {renderContent()}
                </DialogContent>
                <DialogActions classes={{root: styles.okCancelButtonGroup}}>
                    <div className={styles.cancelButton}>
                        <EvvButton type='tertiary' onClick={handleCancel} >Cancel</EvvButton>
                    </div>
                    <div className={styles.okButton}>
                        <EvvButton type='primary' onClick={handleSave} disabled = {notAllowedMultiBookSchedule || durationExceeded() || validateCurrentTime(startTime,appointmentDate) || validateCurrentTime(endTime,appointmentEndDate) || validateCurrentDate(appointmentDate) || validateCurrentDate(appointmentEndDate) || (!validateCurrentTime(endTime,appointmentEndDate) && validateDates()) || (!validateCurrentTime(startTime,appointmentDate) && validateDates())} >Save</EvvButton>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
}
