import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import { PRIMARY_COLOR, QUINARY_COLOR } from "../../constants";

const useStyles = makeStyles((theme) => ({
    primary: {
        width: '100%',
        height: theme.buttonHeight,
        borderRadius: '5px',
        borderColor: PRIMARY_COLOR,
        borderWidth: '0px',
        backgroundColor: PRIMARY_COLOR,
        fontSize: '16px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        textAlign: "center",
        color: "#ffffff"
    },
    secondary: {
        width: '100%',
        height: theme.buttonHeight,
        borderRadius: '5px',
        backgroundColor: "#ffffff",
        borderStyle: "solid",
        borderWidth: '1px',
        borderColor: PRIMARY_COLOR,
        fontSize: "16px",
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        textAlign: "center",
        color: PRIMARY_COLOR,
    },
    tertiary: {
        width: '100%',
        height: theme.buttonHeight,
        // borderRadius: '5px',
        backgroundColor: "#ffffff",
        // borderStyle: "solid",
        borderWidth: '0px',
        // borderColor: "#006ca7",
        // fontSize: "16px",
        // fontWeight: "bold",
        // fontStyle: "normal",
        // letterSpacing: 0,
        // textAlign: "center",
        // color: "#0770a9"
        fontSize: '18px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: -0.03,
        color: PRIMARY_COLOR
    },
    okcancelbutton: {
        width: '100%',
        height: theme.buttonHeight,
        backgroundColor: "#ffffff",
        borderWidth: '0px',
        fontSize: '0.875rem',
        fontWeight: 500,
        fontStyle: "normal",
        letterSpacing: 0.02875,
        color: PRIMARY_COLOR
    },
    ctaLarge: {
        width: '100%',
        height: theme.buttonHeight,
        borderRadius: '4px',
        borderWidth: '0px',
        backgroundColor: QUINARY_COLOR,
        fontSize: '18px',
        fontWeight: "normal",
        fontStyle: "normal",
        letterSpacing: 0,
        textAlign: "center",
        color: "#ffffff"
    },
    ctaMedium: {
        width: '100%',
        height: '31px',
        borderRadius: '5px',
        borderWidth: '0px',
        backgroundColor: QUINARY_COLOR,
        fontSize: '16px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        textAlign: "center",
        color: "#ffffff"
    },
    ctaSmall: {
        borderRadius: '5px',
        backgroundColor: QUINARY_COLOR,
        borderStyle: "solid",
        borderWidth: '1px',
        padding: '4px',
        fontSize: 10,
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0.01,
        textAlign: "center",
        color: "#ffffff"
    },
    ctaLogin: {
        width: '100%',
        height: theme.buttonHeight,
        borderRadius: '4px',
        borderWidth: '0px',
        backgroundColor: QUINARY_COLOR,
        fontSize: '16px',
        fontWeight: "normal",
        fontStyle: "normal",
        letterSpacing: 0,
        textAlign: "center",
        color: "#ffffff"
    },
    disabled:{
        backgroundColor: "#c9c9c9"
    }
}));

export default function EvvButton({id = '', type, disabled, children, onClick, styleOverride = {}}) {
    const styles = useStyles();
    const buttonClassName = styles[type];

    const handleClick = (e) => {
        if (onClick){
            onClick(e);
        }
    }

    return <button id={id ? id : `EvvButton`} data-testid={id ? id : `EvvButton`} style={styleOverride} onClick={handleClick} disabled={disabled} className={buttonClassName + (disabled ? ' ' + styles.disabled : '')}>{children}</button>;
}