import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import { PRIMARY_COLOR } from '../../../common/constants';
import syncService from '../../../common/services/syncService';
import { clientCache } from '../../../cache/slices/client/clientSlice';
import { appCache } from '../../../cache/slices/app/appSlice';
import { useSelector } from 'react-redux';
import { orderBy } from 'lodash';
 
const useStyles = makeStyles((theme) => ({
  message: {
    fontSize: '16px',
    fontStyle: 'normal',
    letterSpacing: 0,
    paddingBottom: '9px 9px 9px 9px',
  },
  loadingSpinnerContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '80%'
  },
  loadingSpinner: {
    color: PRIMARY_COLOR 
  },
  messagesText: {
    padding: "0px 16px 0px 16px" 
  },
  noMessagesFoundContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  noMessagesFound: {
      fontSize: '18px',
      fontWeight: "bold",
      fontStyle: "normal",
      color: "#000000",
      paddingTop: '56px'
  }
}));
 
export const MessageBoardPage = () => {
  const styles = useStyles();

  const clientMessages = useSelector(syncService.messageService.getResults());
  const isLoading = useSelector(syncService.messageService.isLoading());
  const client = useSelector(clientCache.getSelectedClient);
  const user = useSelector(appCache.getUser);

  const [messages, setMessages] = useState([]);

  useEffect(() => {
    let orderedMessages = [];
    if(clientMessages && clientMessages.length > 0) {
      orderedMessages = orderBy(clientMessages, ['postDate'], ['desc']);
      syncService.auditService.saveAuditEvent(null, client, user, "EVV Client Messages", "View");
    }
    setMessages(orderedMessages);
  }, [clientMessages, client, user]);

  const getFormattedMessage = (color, message) => {
    return <p style = {{ color: (color && color !== "" && color !== "None") ? color : '#424242' }}> { message ? message : '' } </p>
  }
  
  return (
    <div>
      {isLoading ? (
        <div className={styles.loadingSpinnerContainer}>
          <CircularProgress className={styles.loadingSpinner} />
        </div>
      ) : (
            <>
            {messages.length > 0 ? (
              <div className={styles.message}>
                {messages.map((row, index) => (
                    <div key={index} className = {styles.messagesText}>
                        { getFormattedMessage(row?.highlightColor, row?.message) }
                        <hr />
                    </div>
                ))}
            </div>
            ) : (
              <div className={styles.noMessagesFoundContainer}>
                <div className={styles.noMessagesFound} >
                  No messages found
                </div>
              </div>
            )}
        </>
      )}
    </div>
  )
}