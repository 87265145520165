import {DateTime} from "luxon";
import {dateToStringWithFormat, getFormattedStartDate, getFormattedStartTime, ISO_DATE_TIME_FORMAT} from "./formatUtils"
import { SERVICE_DOCUMENT_STATUS_INCOMPLETE, SERVICE_DOCUMENT_STATUS_SIGNED, SERVICE_DOCUMENT_STATUS_UNSIGNED } from "../../features/forms/serviceDocumentUtils";
import { documentCache } from "../../cache/slices/document/documentCache";
import syncService from "../services/syncService";
import { PDF_OFFLINE_MSG, PDF_OFFLINE_TITLE, PDF_SERVER_TITLE, PDF_UPDATE_TITLE } from "../constants";
import { formatLatestVal, setCopyForwardValue } from "../../features/forms/common/FormFieldsUtils";
import { hasAttachedDoc } from "../../features/forms/common/IncompleteTasksUtils";

export const EMPTY_STATUS_FILTER = 'Show All';

export const STATUSES = ["Complete", "Incomplete", "Signed", "Unsigned", "PDF"];

export const colorStatusMap = {
    Complete: '#9cbb34',
    Incomplete: '#e16b5a',
    Signed: '#9cbb34',
    Unsigned: '#facd64',
    PDF: '#2979c1',
    Cancel: ''
};

export const colorForStatus = status => {
    let color = '';

    if (status && status.length){
        color = colorStatusMap[status];
    }

    return color;
};

export const convertServiceDocumentsToClientDocuments = (serviceDocuments) => {
    if (serviceDocuments){
        return serviceDocuments.map((sd, index) => {
            return {
                ...sd,
                serviceDate: DateTime.now(),
                status: sd.documentStatus
            }
        });
    }

    return [];
}

export const convertServiceDocuments = (documentsServ) => {
    if (documentsServ && documentsServ.length > 0){
        return documentsServ.filter(doc => doc?.documentCode !== "NONE" && !doc?.docSignaturePadList).map((ds) => {
            return {
                appointmentId: ds.appointmentId,
                clientId: ds.clientId,
                description: ds.documentName,
                serviceDate: ds.serviceDate,
                status: ds.documentStatus ? ds.documentStatus : 'PDF',
                id: ds.id,
                serviceDocumentId: (ds.serviceDocumentId ? ds.serviceDocumentId : ''),
                moduleData: (ds.moduleData ? ds.moduleData : [])
            }
        });
    }

    return [];
}

export const createNewDocument = (serviceDocument, client, staff, appointment, documentStatus) => {
    const documentDate = DateTime.now().toFormat('yyyy-MM-dd HH:mm:ss');

    let newDocument = {
        serviceDate: documentDate,
        clientId: Number(client.clientId),
        staffId: staff.staffId,
        serviceDocumentId: serviceDocument.setupId,
        serviceDocumentTemplateId: serviceDocument.id,
        documentName: serviceDocument.description,
        documentCode: serviceDocument.code,
        documentStatus: documentStatus && documentStatus.length > 0 ? documentStatus : 'Incomplete'
    };

    if(appointment && appointment.appointmentId){
        newDocument.appointmentId = appointment.activityDetailId || appointment.appointmentId;
        newDocument.serviceDate = dateToStringWithFormat(appointment?.startDateInstance, ISO_DATE_TIME_FORMAT);
        newDocument.appointmentIdForQuery = appointment.appointmentId;
    }

    return newDocument;
}

export const getUnsignedDocuments = (clientDocuments, clients, keptCoStaffAppointments) => {
    let unsignedDocuments = [];
    let unSignedStatusDocs = clientDocuments && clientDocuments.filter((doc) => (doc.status === SERVICE_DOCUMENT_STATUS_UNSIGNED));
    unSignedStatusDocs.forEach((unSignedStatusDoc) => {
        let unsignedDocs = [];

        if(unSignedStatusDoc.appointmentId) {
            let appointmentWithUnsignedDoc = [];
            appointmentWithUnsignedDoc = keptCoStaffAppointments && keptCoStaffAppointments.filter((appointment) => (appointment.activityDetailId === unSignedStatusDoc.appointmentId));
            if(appointmentWithUnsignedDoc && appointmentWithUnsignedDoc.length > 0) {
                unsignedDocs = prepareDocs([unSignedStatusDoc], clients);
            }
        } else {
            unsignedDocs = prepareDocs([unSignedStatusDoc], clients);
        }

        unsignedDocuments = [...unsignedDocs, ...unsignedDocuments];
    })
    return unsignedDocuments;
}

export const getTasksAndIncompletDocuments = (clients, client, clientDocuments, tasks, tasksAndDocs, keptCoStaffAppointments) => {
    let incompletDocuments = [];
    let unsignedDocuments = [];

    if(client) {
        let filteredDocuments = (clientDocuments && clientDocuments.filter(doc => doc.clientId === Number(client.clientId)));
        incompletDocuments = prepareDocs((filteredDocuments && filteredDocuments.filter(doc => doc.status === SERVICE_DOCUMENT_STATUS_INCOMPLETE)), [client]);
        unsignedDocuments = getUnsignedDocuments(filteredDocuments, [client], keptCoStaffAppointments);
    } else {
        incompletDocuments = prepareDocs((clientDocuments && clientDocuments.filter(doc => doc.status === SERVICE_DOCUMENT_STATUS_INCOMPLETE)), clients);
        unsignedDocuments = getUnsignedDocuments(clientDocuments, clients, keptCoStaffAppointments);
    }
    tasks = [...tasks, ...unsignedDocuments];

    if(incompletDocuments.length > 0 || unsignedDocuments.length > 0) {
        tasksAndDocs = [...tasks, ...incompletDocuments];
    }
    return tasksAndDocs;
}

export const getTasksForExternalSignNeeded = (clients, incompleteTasksAndDocs, evvDocWithSign, client) => {
    let externalSignDocument = [];
    if(client) {
        let clientExternalDoc = evvDocWithSign && evvDocWithSign?.filter(doc => doc?.clientId === Number(client?.clientId));
        externalSignDocument = prepareDocs(clientExternalDoc, clients);
    } else {
        externalSignDocument = prepareDocs(evvDocWithSign, clients);
    }
    incompleteTasksAndDocs = [...incompleteTasksAndDocs, ...externalSignDocument];
    return incompleteTasksAndDocs;
}

export const prepareDocs = (incompletDocuments, clients) => {
    incompletDocuments.forEach((incompletDocument) => {
        incompletDocument.client = clients.filter(client => Number(client.clientId) === incompletDocument.clientId)[0];
        incompletDocument.startDateInstance = DateTime.fromSQL(incompletDocument.serviceDate);
        incompletDocument.formattedStartDate = getFormattedStartDate(incompletDocument.startDateInstance);
        incompletDocument.formattedStartTime = getFormattedStartTime(incompletDocument.startDateInstance);
    });
    return incompletDocuments;
}

export const hasMaxDocsOpened = (serviceDocTab, clientId) => {
    return serviceDocTab && serviceDocTab?.length === 5 && serviceDocTab[1].clientId === Number(clientId);
}

export const checkIfDocExistsInTab = (serviceDocTab, document) => {
    return serviceDocTab.find((item) => item.id === document?.id) ? true : false;
}

export const tabDocEditAction = (serviceDocTab, document, dispatch, client) => {
    dispatch(documentCache.setSelectedDocTab(document));
    let activeDoc = serviceDocTab.find((item) => item.id === document?.id);
    if (!activeDoc) {
        if(client) {
            setServiceDocumentsForTabsFun(serviceDocTab, document, dispatch, client);
        } else {
            let documentsForTabs = [...serviceDocTab, document];
            dispatch(documentCache.setServiceDocumentsForTabs(documentsForTabs));
            if (documentsForTabs && documentsForTabs?.length > 0) {
                dispatch(documentCache.setSelectedDocTabGlobal(documentsForTabs?.length - 1));
            }
        }
    } else {
        let activeDocIndex = serviceDocTab.indexOf(activeDoc);
        dispatch(documentCache.setSelectedDocTabGlobal(activeDocIndex));
    }
    dispatch(documentCache.setSelectedDocTab(document));
}

export const tabDocAddAction = (serviceDocTab, document, dispatch, client) => {
    if(client) {
        setServiceDocumentsForTabsFun(serviceDocTab, document, dispatch, client);
    } else {
        let documentsForTabs = [...serviceDocTab, document];
        dispatch(documentCache.setServiceDocumentsForTabs(documentsForTabs));
    }
    dispatch(documentCache.setSelectedDocTab(document));
}

export const handleSaveNoDocument = (document, client, staff, currentAppointment, store, dispatch) => {
    let noDocument = createNewDocument(document, client, staff, currentAppointment, 'noStatus');
    syncService.documentService.saveNewDocument(noDocument, store, dispatch);
}

export const isErrorResponse = (response) => {
    if (response instanceof Response || response.errors) {
        if (response.errors && response.errors.length > 0) {
            return true;
        }
    }
    return false;
}

export const getPdfUrlFromServer = async (doc, force = false) => {
    return syncService.documentService.getPdfUrl(doc.id, force);
}

export const updateDbDocument = async (doc, setAttachedDoc) => {
    await syncService.documentService.readDocument(doc.id)
        .then(response => {
            if (response.resources && response.resources.length > 0) {
                let document = response?.resources[0];
                if (setAttachedDoc) {
                    setAttachedDoc([document]);
                }
                let updateInfo = {changedDate: document.changedDate, uploadDate: document.uploadDate};
                syncService.documentService.updatePdfDocument(doc.id, updateInfo);
            }
        })
        .catch(error => {
            console.log(error, 'Error in updateDbDocument');
        });
}

export const pdfResponse = (response, doc, setAttachedDoc) => {
    if (isErrorResponse(response)) {
        return { error: true,  errorMsg: response.message, errorTitle: PDF_SERVER_TITLE };
    } else if (response.connectionLost) {
        return { error: true, errorMsg: PDF_OFFLINE_MSG, errorTitle: PDF_OFFLINE_TITLE };
    } else {
        if (response.resources && response.resources.length > 0) {
            if (response.resources[0].exceptionMessage) {
                setTimeout(() => {
                    //Need to update latest dates for document in indexedDB, after generating a latest PDF in AWS
                    updateDbDocument(doc, setAttachedDoc);
                }, 5000);
                return { error: true,  errorMsg: response.resources[0].exceptionMessage, errorTitle: PDF_UPDATE_TITLE };
            } else {
                let pdfUrl = response.resources[0].pdf;
                //Add PDF Url in documents table
                syncService.documentService.addPdfUrl(doc.id, pdfUrl);
                return { error: false, pdfUrl: pdfUrl };
            }
        } else {
            return { error: true,  errorMsg: "Response do not contain resources in proper format", errorTitle: PDF_SERVER_TITLE };
        }
    }
}

export const chekDocPdf = async (doc, setAttachedDoc) => {
    if (doc.uploadDate === '' || doc.changedDate <= doc.uploadDate) {
        if (doc?.pdfUrl) {
            return { error: false, pdfUrl: doc.pdfUrl };
        } else {
            //The PDF document does NOT exist because the document is newly signed
            let response = await getPdfUrlFromServer(doc);
            return pdfResponse(response, doc, setAttachedDoc);
        }
    } else {
        let response = await getPdfUrlFromServer(doc, true);
        return pdfResponse(response, doc, setAttachedDoc);
    }
}

export const getLatestSignedDoc = async(staffId, selectedClientId, serviceDocName, evvDocuments) => {
    let serviceDoc = evvDocuments.filter(doc => doc.staffId === staffId && doc.clientId === selectedClientId && doc.documentName === serviceDocName);
    return serviceDoc;
}

export const assignCopyForwardedVal = (latestModule, fieldId, fieldDescriptor, doc) => {
    let latestField;

    if (doc.documentStatus && doc.documentStatus === SERVICE_DOCUMENT_STATUS_SIGNED) {
        latestField = latestModule.fieldData.find(field => field.key === fieldId);
    } else {
        latestField = latestModule.data.find(field => field.key === fieldId);
    }

    let latestFieldVal = latestField.value;

    if (latestField) {
        if (doc.documentStatus && doc.documentStatus === SERVICE_DOCUMENT_STATUS_SIGNED) {
            setCopyForwardValue(fieldDescriptor, latestFieldVal, doc);
        } else {
            if (latestFieldVal && Array.isArray(latestFieldVal) && latestFieldVal.length > 0) {
                formatLatestVal(fieldDescriptor, latestFieldVal, doc);
            }
        }
    }
}

export const getValueByDoc = async(latestSignedDocArr, formId, moduleId, fieldId, fieldDescriptor) => {
    let latestSignedDoc = latestSignedDocArr[0];

    if (latestSignedDoc.moduleData && latestSignedDoc.moduleData.length > 0) {
        let moduleValidator = (latestSignedDoc.documentStatus && latestSignedDoc.documentStatus === SERVICE_DOCUMENT_STATUS_SIGNED) ? moduleId : formId;
        let latestModule = latestSignedDoc.moduleData.find(module => module.moduleId === moduleValidator);
        if (latestModule) {
            assignCopyForwardedVal(latestModule, fieldId, fieldDescriptor, latestSignedDoc);
        }
    }
}

export const getSignedDoc = (documents, staffId, selectedClientId, formId, moduleId) => {
    let found = false;
    let copyForwardDocObj = { document: [] };

    documents?.forEach((doc) => {
        if (!found && doc.staffId === staffId && doc.clientId === selectedClientId && doc.moduleData && doc.moduleData.length > 0) {
            let latestModule;

            if (doc.documentStatus && doc.documentStatus === SERVICE_DOCUMENT_STATUS_SIGNED) {
                latestModule = doc.moduleData.find(module => module.moduleId === moduleId);
            } else {
                latestModule = doc.moduleData.find(module => module.moduleId === formId);
            }

            if (latestModule) {
                copyForwardDocObj = { document: [doc], module: latestModule };
                found = true;
            }
        }
    })

    return copyForwardDocObj;
}

export const getValueByForm = async(staffId, selectedClientId, formId, moduleId, fieldId, evvDocuments, fieldDescriptor, signedDocuments, isOffline) => {
    let copyForwardModule;
    let copyForwardDocArr = [];

    let latestSignedDocObj = getSignedDoc(evvDocuments, staffId, selectedClientId, formId, moduleId);
    let latestSignedDBDocObj = getSignedDoc(signedDocuments, staffId, selectedClientId, formId, moduleId);

    if (isOffline) {
        copyForwardDocArr = [...latestSignedDBDocObj?.document];
        copyForwardModule = latestSignedDBDocObj?.module;
    } else {
        copyForwardDocArr = getLatestCopyFwdDoc(latestSignedDocObj?.document, latestSignedDBDocObj?.document);
        if (copyForwardDocArr.length > 0) {
            if (copyForwardDocArr[0].id === latestSignedDocObj?.document.id) {
                copyForwardModule = latestSignedDocObj.module;
            } else {
                copyForwardModule = latestSignedDBDocObj.module;
            }
        }
    }

    if (copyForwardModule) {
        assignCopyForwardedVal(copyForwardModule, fieldId, fieldDescriptor, copyForwardDocArr[0]);
    }

}

export const getLatestCopyFwdDoc = (apiDocArr, dbDocArr) => {
    let latestDocArr = [];

    if (apiDocArr.length > 0 && dbDocArr.length > 0) {
        let apiDoc = apiDocArr[0];
        let dbDoc = dbDocArr[0];

        if (apiDoc.id === dbDoc.id) {
            latestDocArr = [...apiDocArr];
        } else {
            latestDocArr = (apiDoc.serviceDate > dbDoc.serviceDate) ? [...apiDocArr] : [...dbDocArr];
        }
    } else if (apiDocArr.length > 0) {
        latestDocArr = [...apiDocArr];
    } else if (dbDocArr.length > 0) {
        latestDocArr = [...dbDocArr];
    }

    return latestDocArr;
}

export const hasAttachedDocument = async(appointment, dispatch) => {
    await hasAttachedDoc(appointment).then((doc) => {
        if (doc && doc?.length > 0) {
            dispatch(documentCache.setAttachedDoc(doc));
        } else {
            dispatch(documentCache.setAttachedDoc([]));
        }
    }).catch((error) => {
        console.log('Error: ', error);
    });
}

export const setServiceDocumentsForTabsFun = (serviceDocTab, document, dispatch, client) => {
    let documents = [];
    if(serviceDocTab && serviceDocTab.length > 0) {
        documents = serviceDocTab?.filter((docTab) => docTab?.clientId === Number(client?.clientId));
        if(documents && documents?.length > 0) {
            let documentsForTabs = [{description:'All Documents'}, ...documents, document];
            dispatch(documentCache.setServiceDocumentsForTabs(documentsForTabs));
        } else {
            dispatch(documentCache.setServiceDocumentsForTabs([{description:'All Documents'}, document]));
        }
    }
}

export const addLatestExtSigDocument = async (doc, store, dispatch) => {
    syncService.documentService.deleteDocument(doc, {store, doNotClear: true});
    await syncService.documentService.readDocument()
        .then(response => {
            let latestEvvDocuments = response.resources;
            if (latestEvvDocuments && latestEvvDocuments.length > 0) {
                let document = latestEvvDocuments?.filter(ele => ele?.id === doc?.id);
                if (document && document?.length > 0) {
                    syncService.documentService.addExtSigDocument(document[0], store);
                }
                dispatch(documentCache.setEvvDocuments(latestEvvDocuments));
            }
        })
        .catch(error => {
            console.log(error);
        });
}