import { Grid } from "@material-ui/core";
import TextAreaComponent from "../fields/TextAreaComponent";
import { GACOMMENTS, GACOMMENTSLIMIT } from "../../../common/constants";
import { getXSAndSMValueForField } from "../common/Util";
import StackedField from "../../../common/components/StackedField";
import { useEffect, useState } from "react";
import { getDbFieldValue, getGaFieldById, getGaFieldValue, isFieldExists } from "../../../common/utils/goalsAddressedUtils";

export default function GAComments (props) {
    let changedPortrait = window.matchMedia("(orientation: portrait)");
    let orientation = changedPortrait.matches ? "portrait" : "landscape";
    let breakPoints = getXSAndSMValueForField(props.isHandheld, orientation, 12, 6);
    let isReadOnly = props?.isReadOnly;
    
    const [formField, setFormField] = useState();
    const [value, setValue] = useState("")

    useEffect(() => {
        createFormField();
        getValue();
    });

    const createFormField = () => {
        if (props?.module?.fields && !isFieldExists(props, "gaComments")) {
            let GACommentsField = {
                formId: props.module.id,
                id: "gaComments",
                label: GACOMMENTS,
                name: "gaComments",
                type: "GA_Comments",
                value: '',
                isChanged: false,
                isUpdated: false
            };
            props.module.fields = [ ...props?.module?.fields, GACommentsField ];
            setFormField(GACommentsField);
        }
    }

    const getValue = () => {
        if (formField) {
            let fieldValue;
            let field = getGaFieldById(props, formField?.id);
            if (field) {
                if (field.isUpdated) {
                    fieldValue = getGaFieldValue(field);
                } else {
                    fieldValue = getDbFieldValue(field, props);
                }
                setValue(fieldValue);
            }
        }
    }

    return (
        <div>
            {formField && <StackedField label = { GACOMMENTS } paddingTop = { '26px' }>
                    <Grid container item = {true} xs = { breakPoints.xsValue }>
                        <TextAreaComponent
                            id = { formField?.id }
                            name = { formField?.name }
                            value = { value }
                            onChange = {(event) => { props.onChange('gaComment', event, props, formField, setValue); } }
                            maxCharacters = { 4000 }
                            rows = { 5 }
                            cols = { 80 }
                            disabled = { isReadOnly }
                        />
                        <span style = {{ width: '100%' }}>{ GACOMMENTSLIMIT }</span>
                    </Grid>
            </StackedField>}
        </div>
    );
}