import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DataTable from '../../../common/components/DataTable';
import CircularProgress from '@material-ui/core/CircularProgress';
import { PRIMARY_COLOR } from '../../../common/constants';
import { sortWithDirection } from '../../../common/utils/miscUtils';
import { getOrderByProperty } from '../../../common/utils/tableUtils';
import syncService from '../../../common/services/syncService';
import { clientCache } from '../../../cache/slices/client/clientSlice';
import { appCache } from '../../../cache/slices/app/appSlice';
import { useSelector } from 'react-redux';
import { getBp, getElementWithUnit, getFormattedDateTime, getFormattedValue } from '../../../common/utils/vitalsUtils';
 
const useStyles = makeStyles(() => ({
  vitalsTab: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    '& .MuiTableCell-root': {
      textAlign: 'center',
    },
    '& .MuiTableSortLabel-root:hover': {
      color: '#3b276a'
    },
  },
  loadingSpinnerContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingSpinner: {
    color: PRIMARY_COLOR,
  },
  noVitalsFoundContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  noVitalsFound: {
    fontSize: '18px',
    fontStyle: 'normal',
    color: '#000000',
    paddingTop: '56px',
    fontWeight: "bold"
  },
}));
 
export const VitalsTab = () => {
  const vitals = useSelector(syncService.vitalService.getResults());
  const isLoading = useSelector(syncService.vitalService.isLoading());
  const client = useSelector(clientCache.getSelectedClient);
  const user = useSelector(appCache.getUser);

  const styles = useStyles();

  useEffect(() => {
    if(vitals && vitals.length > 0) {
        syncService.auditService.saveAuditEvent(null, client, user, "EVV Client Vitals", "View");
    }
  }, [vitals, client, user]);
 
  const handleSort = (records, columnDescriptor, orderByDirection) => {
    const alwaysFirst = records.filter(vital => vital?.vitals === '');
    const recordsToSort = records.filter(vital => vital?.vitals !== '');
    return [
      ...alwaysFirst,
      ...sortWithDirection(
          recordsToSort,
          [getOrderByProperty(columnDescriptor), (a) => a?.Date ? a?.Date : 0],
          [orderByDirection, 'asc']
        ),
    ];
  };

  const columnDescriptors = [
    { id: 'Date', sort: 'desc', label: 'Date', fieldName: 'dateRecorded', contentRenderer: (row) => (getFormattedDateTime(row?.dateRecorded)) },
    { id: 'Blood Pressure', removeSortIcon: true, disableSort: true, label: 'Blood Pressure', fieldName: 'bPSystolic', contentRenderer: (row) => (getBp(row)) },
    { id: 'Heart Rate', removeSortIcon: true, disableSort: true, label: 'Heart Rate', fieldName: 'heartRate', contentRenderer: (row) => (getFormattedValue(row?.heartRate)) },
    { id: 'Respiratory Rate', removeSortIcon: true, disableSort: true, label: 'Respiratory Rate', fieldName: 'respiratoryRate', contentRenderer: (row) => (getFormattedValue(row?.respiratoryRate)) },
    { id: 'Temperature', removeSortIcon: true, disableSort: true, label: 'Temperature', fieldName: 'temperature', contentRenderer: (row) => (getElementWithUnit(row?.temperature, "°F")) },
    { id: 'Height', removeSortIcon: true, disableSort: true, label: 'Height', fieldName: 'height', contentRenderer: (row) => (getElementWithUnit(row?.height, " in")) },
    { id: 'Weight', removeSortIcon: true, disableSort: true, label: 'Weight', fieldName: 'weight', contentRenderer: (row) => (getElementWithUnit(row?.weight, " lbs")) },
    { id: 'BMI', removeSortIcon: true, disableSort: true, label: 'BMI', fieldName: 'bmi', contentRenderer: (row) => (getFormattedValue(row?.bmi)) },
    { id: 'Pain', removeSortIcon: true, disableSort: true, label: 'Pain', fieldName: 'pain', contentRenderer: (row) => (getFormattedValue(row?.pain)) },
    { id: 'Pulse Oximetry', removeSortIcon: true, disableSort: true, label: 'Pulse Oximetry', fieldName: 'pulseOximetry', contentRenderer: (row) => (getElementWithUnit(row?.pulseOximetry, "%")) },
    { id: 'Inhaled Oxygen Concentration', removeSortIcon: true, disableSort: true, label: 'Inhaled Oxygen Concentration', fieldName: 'inhaledOxygen', contentRenderer: (row) => (getElementWithUnit(row?.inhaledOxygen, "%")) },
  ];
 
  const renderPage = () => {
    if (isLoading) {
      return (
        <div className={styles.loadingSpinnerContainer}>
          <CircularProgress size={50} className={styles.loadingSpinner} />
        </div>
      );
    } else {
      return (
        <>
          <DataTable columnDescriptors={columnDescriptors} records={vitals} handleSort={handleSort}/>
          {(!vitals || vitals.length === 0) &&
            <div className={styles.noVitalsFoundContainer}>
              <div className={styles.noVitalsFound}>No vitals found</div>
            </div>
          }
        </>
      );
    }
  };
 
  return <div className={styles.vitalsTab}>{renderPage()}</div>;
};