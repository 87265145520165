import {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import AllergyCard from "../AllergyCard";
import SortBar from "../../../common/components/SortBar";
import SortDrawer from "../../../common/components/SortDrawer";
import {sortWithDirection} from "../../../common/utils/miscUtils";
import {useDispatch, useSelector} from "react-redux";
import {clientCache} from "../../../cache/slices/client/clientSlice";
import CircularProgress from "@material-ui/core/CircularProgress";
import syncService from "../../../common/services/syncService";
import {appCache} from "../../../cache/slices/app/appSlice";
import { PRIMARY_COLOR } from "../../../common/constants"

const useStyles = makeStyles(() => ({
    allergiesPage: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column'
    },
    loadingSpinnerContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    loadingSpinner: {
        color: PRIMARY_COLOR
    },
    noAllergies: {
        fontSize: "18px",
        fontWeight: "bold",
        fontStyle: "normal",
        lineHeight: "28px",
        letterSpacing: -0.08,
        textAlign: "center",
        color: "#000000",
        paddingTop: '53px'
    }
}));

const columnDescriptors = [
    {
        name: 'allergyName',
        title: 'Name'
    },
    {
        name: 'allergyType',
        title: 'Type'
    },
];

export default function AllergiesPage() {
    const client = useSelector(clientCache.getSelectedClient);
    const allergies = useSelector(syncService.allergyService.getResults());
    const isLoading = useSelector(syncService.allergyService.isLoading());
    const sortDescriptor = useSelector(clientCache.getAllergySort);
    const [sortedAllergies, setSortedAllergies] = useState([]);
    const [drawerOpened, setDrawerOpened] = useState(false);
    const dispatch = useDispatch();
    const styles = useStyles();
    const user = useSelector(appCache.getUser);

    const sortedColumn = sortDescriptor ? sortDescriptor.sortDescriptor : columnDescriptors[0];
    const sortDirection = sortDescriptor ? sortDescriptor.sortDirection : 'asc';

    useEffect(() => {
        if(allergies && allergies.length > 0) {
            syncService.auditService.saveAuditEvent(null, client, user, "EVV Client Allergies", "View");
        }
    }, [allergies, client, user]);

    useEffect(() => {
        if (allergies && allergies.length > 0) {
            const sortPropertyNames = [sortedColumn.name, 'beginDate'];
            const sortDirections = [sortDirection, 'asc'];

            setSortedAllergies(sortWithDirection(
                allergies,
                sortPropertyNames,
                sortDirections,
                (allergy) => allergy.allergen === ''
            ));
        }else{
            setSortedAllergies([]);
        }
    }, [sortedColumn, sortDirection, allergies]);


    const handleSortRequest = () => {
        toggleDrawer();
    }

    const handleSortBy = (columnDescriptor) => {
        let newSortDirection = 'asc';

        if (columnDescriptor.name === sortedColumn.name){
            if (sortDirection === 'asc'){
                newSortDirection = 'desc';
            }
        } else {
            if (columnDescriptor.sortDirection){
                newSortDirection = columnDescriptor.sortDirection
            }
        }

        setDrawerOpened(false);

        dispatch(clientCache.sortAllergies({
            sortDescriptor: columnDescriptor,
            sortDirection: newSortDirection
        }));
    }

    const toggleDrawer = () => {
        setDrawerOpened(!drawerOpened);
    }

    const renderPage = () => {
        if (isLoading){
            return (
                <div className={styles.loadingSpinnerContainer}>
                    <CircularProgress size={50} className={styles.loadingSpinner} />
                </div>
            );
        } else {
            return renderPageContent();
        }
    }

    const renderPageContent = () => {
        if (sortedAllergies && sortedAllergies.length > 0){
            return (
                <>
                    <SortBar columnDescriptors={columnDescriptors}
                             sortedColumn={sortedColumn}
                             handleSortRequest={handleSortRequest}/>
                    {
                        sortedAllergies.map((allergy, index) => <AllergyCard key={`${allergy.allergyId}_${index}`} client={client} allergy={allergy} />)
                    }
                    <SortDrawer
                        anchor='bottom'
                        open={drawerOpened}
                        columnDescriptors={columnDescriptors}
                        showCancel={true}
                        handleCancel={toggleDrawer}
                        handleSortBy={handleSortBy}
                    />
                </>
            );
        } else {
            return (<div className={styles.noAllergies} >No Allergies Found</div>)
        }
    }

    return (
        <div className={styles.allergiesPage}>
            {renderPage()}
        </div>
    );
}
