import * as msgConst from '../common/Messages';
import * as constData from "../../../common/constants";

export const getMinLength = (field) => {
    let minlength = "";
    let minlengthobj = field?.attributes?.find( (attribute) => attribute.attributeId === field.id +"_minlength" );
    minlength = minlengthobj?.value;
    return minlength;
}

export const getDataType = (field) => {
    let datatype = "";
    let datatypeobj = field?.attributes?.find( (attribute) => attribute.attributeId === field.id +"_datatype" );
    datatype = datatypeobj?.value;
    return datatype;
}

export const setMandatoryErrorMsg = (field) => {
    field['requiredFieldErrorMessage'] = `${field.label} ${msgConst.errorMessageString}`;
}

export const validatePhonePart1 = (value) => {
    let isValid = true;
    if (value.length !== 3 || !constData.PHONE_REGEX.test(value)) {
        isValid = false;
    }
    return isValid;
}

export const validatePhonePart2 = (secondPart) => {
    let isValid = true;
    if (secondPart.length > 0) {
        if (secondPart[0].length !== 3 || secondPart[1].length !== 4 || !constData.PHONE_REGEX.test(secondPart[0]) || !constData.PHONE_REGEX.test(secondPart[1])) {
            isValid = false;
        }
    }
    return isValid;
}

export const validatePhonePart3 = (value) => {
    let isValid = true;
    if (!constData.PHONE_REGEX.test(value)) {
        isValid = false;
    }
    return isValid;
}

export const validatePhone = (fieldValueToCompare, field) => {
    let i = 0;
    let isValid = true;

    fieldValueToCompare.forEach(value => {
        if (i <= 1) {
            if (i === 0) {  //First part
                isValid = validatePhonePart1(value);
                if (!isValid) {
                    return isValid;
                }
            } else {    //Second part
                let secondPart = value.split('-');
                isValid = validatePhonePart2(secondPart);
            }
        }

        if (isValid && i === 2 && value !== '') {
            isValid = validatePhonePart3(value);
        }

        i++;
    });

    if (isValid) {
        delete field.onChangeError;
    } else {
        field['onChangeError'] = `${msgConst.validPhoneFieldMsg}`;
    }

    return isValid;
}

export const validatePhoneField = (field) => {
    let phoneArr = [];

    field.options.forEach((phoneField) => {
        phoneArr.push(phoneField.value);
    });

    if (phoneArr.length > 0) {
        let isEmpty = ( phoneArr[0] === '' || phoneArr[1] === '' );
        if (isEmpty) {
            setMandatoryErrorMsg(field);
        } else {
            validatePhone(phoneArr, field);
        }
    }
}


export const validateTimeField = (field) => {
    const { value } = field;
    
    if(hasDbSavedArrLen(field, 2)){
        if(!value || value === "") {
            setMandatoryErrorMsg(field);
        }
    }
}

export const validateDateTimeField = (field) => {
    if(hasDbSavedArrLen(field, 3) && (!field.value || (field.timeValue === "" || field.dateValue === ""))) {
        setMandatoryErrorMsg(field);
    }
}

export const validateDateField = (field) => {
    if(hasDbSavedStr(field) && (!field.value || field.dateValue === "")) {
        setMandatoryErrorMsg(field);
    }
 }

 //validation for text area
export const validateFieldsForTextArea = (field) => {
    const { value } = field;

    let minlength = getMinLength(field);
    let datatype = getDataType(field);
    
    if(hasDbSavedStr(field) && (!value || value === "")) {
        setMandatoryErrorMsg(field);
    }

    // To show minlength message
    if(datatype !== "NUM" && value?.length < minlength ) {
        field['requiredFieldErrorMessage'] = `${msgConst.minLengthMessage} ${field.label} is ${minlength}`;
    }
}   
    //validating Checkbox after clicking next
const isCheckBoxChecked = (field) => {
    let isAnyCheckboxChecked = false;
    if ((field.dbSavedValue && field.dbSavedValue.length > 0) || (field.selectedOption && field.selectedOption.length > 0)) {
        isAnyCheckboxChecked = true;
    }
    return isAnyCheckboxChecked;
}

export const validateFieldsForCheckbox = (field) => {
    if (!isCheckBoxChecked(field)) {
        setMandatoryErrorMsg(field);
    }
}

//validation for text field
export const validateFieldsForTextField = (field) => {
    const { value } = field;

    let minlength = getMinLength(field);
    let datatype = getDataType(field);
    
    if(!value || value === "") {
        setMandatoryErrorMsg(field);
    }
    // To show minlength message
    if(datatype !== "NUM" && value?.length < minlength ) {
        field['requiredFieldErrorMessage'] = `${msgConst.minLengthMessage} ${field.label} is ${minlength}`;
    }
}

export const validateFieldsForDropdown = (field) => {
    if(hasDbSavedStr(field) && (field.selectedValue === undefined || field.selectedValue === '')) {
        setMandatoryErrorMsg(field);
    }
}

export const hasDbSavedStr = (field) => {
    return !field.dbSavedValue || (field.dbSavedValue && field.dbSavedValue === '');
}

export const hasDbSavedArr = (field) => {
    return !field.dbSavedValue || (field.dbSavedValue && (field.dbSavedValue.filter(ele => ele !== '').length === 0));
}

export const hasDbSavedArrLen = (field, len) => {
    return !field.dbSavedValue || (field.dbSavedValue && (field.dbSavedValue.filter(ele => ele !== '').length !== len));
}

export const hasDbSavedArrOpt = (field, index) => {
    return !field.dbSavedValue || (field.dbSavedValue && !field.dbSavedValue[index]);
}

export const validateFieldsForRadio = (field) => {
    if(hasDbSavedStr(field) && (field.selectedOption === '' || field.selectedOption === undefined)) {
        setMandatoryErrorMsg(field);
    }
}

export const validateFieldsForAddress = (field) => {
    const { options } = field;
    options.forEach((option, index) => {
        if(option.required && option.value  === "" && hasDbSavedArrOpt(field, index)) {
            field['requiredFieldErrorMessage'] = `${option.name} ${msgConst.errorMessageString}`;
        }
    });
}

export const validateFieldsForYesOrNO = (field) => {
    if(hasDbSavedStr(field) && (field.selectedOption === '' || field.selectedOption === undefined)) {
        setMandatoryErrorMsg(field);
    }
}

 //validation for SSN field
 export const validateSSNField = (field) => {
    const { value } = field;
    if(hasDbSavedStr(field) && (!value || value === "")) {
        setMandatoryErrorMsg(field);
    }
    return field;
} 