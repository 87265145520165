import { handleChangedInputForTextArea, handlePhoneInput, handleChangedInputForTextField, handleDateTimeInput, handleTimeInput, handleDateInput, handleChangedInputForCheckbox, handleSSNInput } from "./HandleChangedInputUtils";
import { getFieldElementToFocusOn, focusOnElement } from '../common/FindFirstErrorFieldUtils';
import { renderLookUpField } from "./FormFieldsUtils";

export const setFieldsFromModule = (module) => {
    return module.configurableForm.fields;
}

// adding an atribute to JSON to retain the entered input.
export const handleChangedInput = (field, event) => {
    var changedValueAssigned = false;
    const { type } = field;
    switch (type) {
        case 'Text Field':
            handleChangedInputForTextField(field, event);
        break;

        case 'Gender':
        case 'RadioButton':
        case 'Yes or No':
            field.dbSavedValue = '';
            field['selectedOption'] = event.target.value;
        break;

        case 'CheckBox':
        case 'Descriptor CheckBox':
            handleChangedInputForCheckbox(field, event);
        break;

        case 'DropDown':
        case 'Descriptor DropDown':
        case 'ProgramDropDown':
        case 'StaffDropDown':
        case 'ActiveMedicationDropDown':
        case "ReferralSourceDropDown":
            field.dbSavedValue = '';
            field['selectedValue'] = event.target.value;
            break;

        case 'Address':
            field.dbSavedValue = [];
            field.options.forEach((textField) => {
                if(event.target.id === textField.id) {
                    textField.value = event.target.value;
                }
            })
        break;

        case 'Text Area':
            let value = event?.target?.value.replaceAll('\n', '\r\n');
            handleChangedInputForTextArea(field, value);
        break; 
          
        case 'Phone':
            field.dbSavedValue = [];
            field = handlePhoneInput(event, field);
        break;

        case 'Date':
            field = handleDateInput(event, field);
        break
            
        case 'Time':
            field = handleTimeInput(event, field);
        break;   
        
        case 'DateTime':
            field = handleDateTimeInput(event, field);
        break;

        case 'SSN':
            field = handleSSNInput(event, field);
        break;

        default:
        break;
    }
    
    if(field.value || field.selectedOption || field.selectedValue) {
        changedValueAssigned = true;
    }
    return changedValueAssigned;
}

// Scrolls the form to the position of the first field with an error
export const findFirstErrorField = (module) => {
    module.configurableForm.fields.forEach(field => {
        let element = getFieldElementToFocusOn(field);

        if (element) {
            if ( field.businessRuleApplied !== "Hide" && (field.hasBRError || field.requiredFieldErrorMessage !== '') ) {
                element.className += " input-error";
            } else {
                element.classList.remove("input-error");
            }
        }
    })
    
    let isError = focusOnElement(module);
    return isError;
}

export const changeFocus = (fieldDescriptor, index) => {
    const phoneFields = fieldDescriptor.options;
    const currentPhoneField = phoneFields[index];

    if (currentPhoneField &&
        currentPhoneField.value.length === currentPhoneField.maxLength &&
      index < phoneFields.length - 1
    ) {
        let inputArea = document.getElementById(phoneFields[index + 1].id);
        inputArea?.focus();
    }
}

export const getDefaultVal = (field) => {
    let defaultVal;

    switch (field.type) {
        case 'Text Field':
        case 'Text Area':
        case 'Date':
        case 'SSN':
        case 'DropDown':
        case 'Descriptor DropDown':
        case 'ActiveMedicationDropDown':
        case "ReferralSourceDropDown":
        case 'ProgramDropDown':
        case 'StaffDropDown':
        case 'Gender':
        case 'RadioButton':
        case 'Yes or No':
            defaultVal = '';
        break;

        case 'CheckBox':
        case 'Descriptor CheckBox':
        case 'DateTime':
        case 'Time':
            defaultVal = [];
        break;

        case 'Address':
            defaultVal = ['', '', '', '', '', '', ''];
        break;

        case 'Phone':
            defaultVal = ['', '', ''];
        break;

        default:
            defaultVal = '';
        break;
    }

    return defaultVal;
}

export const getFieldValues = (fields, selectedClient, descriptorData) => {
    let fieldData = [];
    let val = undefined;
    
    fields?.forEach(field => {
        switch (field.type) {
            case 'Text Field':
            case 'Text Area':
            case 'Date':
            case 'DateTime':
            case 'SSN':
                val = field.value;
            break;

            case 'Gender':
            case 'RadioButton':
            case 'Yes or No':
            case 'CheckBox':
            case 'Descriptor CheckBox':
                val = field.selectedOption;
            break;

            case 'DropDown':
            case 'Descriptor DropDown':
            case 'ActiveMedicationDropDown':
                val = field.selectedValue;
            break;

            case 'Address':
            case 'Phone':
                val = getFieldValFromOptions(field);
            break;

            case 'Time':
                val = undefined;
                if(field.value){
                    val = field.value.split(' ');
                }
            break;

            case "ReferralSourceDropDown":
            case 'ProgramDropDown':
            case 'StaffDropDown':
                val = getOptions(field);
            break;

            case 'LookUp':
                const lookupData = field?.attributes?.find((atr) => atr?.attributeId === field?.id + "_column"); 
                if (lookupData) {
                    val = renderLookUpField(lookupData?.value, selectedClient, descriptorData, 'DbValue'); 
                }
            break;

            default:
                val = undefined;
            break;
        }

        if(val !== undefined){
            fieldData.push({key: field.id, value: val})
        } else if(field.dbSavedValue !== undefined) {
            fieldData.push({key: field.id, value: field.dbSavedValue})
        } else if (val === undefined && field.dbSavedValue === undefined) {
            fieldData.push({key: field.id, value: getDefaultVal(field)})
        }
    });
    return fieldData;
}

export const getFieldValFromOptions = (field) => {
    let val = [];
    let optionsLength = field.options.length;
    if (optionsLength > 0) {
        field.options.forEach((option) => {
            val.push(option.value);
        })
    }
    return val;
}

export const getIDForDD = (field, ID) => {
    let options = undefined;
    field.options.forEach((option) => {
        if(option.value === field.selectedValue){
            options = option[ID];
        }
    })
    return options;
}

export const getValForDD = (field, ID, actionValue) => {
    let optionVal = undefined;
    field.options.forEach((option) => {
        if(option[ID].toString() === actionValue){
            optionVal = option.value;
        }
    })

    return optionVal;
}

export const getOptions = (field, actionValue) => {
    let options = undefined;
    switch(field?.type) {
        case 'ReferralSourceDropDown':
            options = (actionValue) ? getValForDD(field, 'referralSourceId', actionValue) : getIDForDD(field, 'referralSourceId') ;
        break;

        case 'ProgramDropDown':
            options = (actionValue) ? getValForDD(field, 'programId', actionValue) : getIDForDD(field, 'programId') ;
        break;

        case 'StaffDropDown':
            options = (actionValue) ? getValForDD(field, 'staffId', actionValue) : getIDForDD(field, 'staffId') ;
        break;

        default:
        break;
    }
    return options;
 }