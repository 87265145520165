import {makeStyles} from "@material-ui/core/styles";
import {IconButton} from "@material-ui/core";
import { PRIMARY_COLOR } from "../../constants";

const useStyles = makeStyles((theme) => ({
    section: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            borderTop: '2px solid #979797',
            backgroundColor: "#f6f6f6",
        },
        [theme.breakpoints.up(601)]: {
            backgroundColor: "#ffffff",
        }
    },
    sectionHeader: {
        height: '52px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '16px',
            paddingRight: '16px'
        },
        [theme.breakpoints.up(601)]: {
        }
    },
    sectionTitle: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '18px',
            fontWeight: "bold",
            fontStyle: "normal",
            letterSpacing: 0,
            color: PRIMARY_COLOR,
            paddingTop: '14px',
            paddingBottom: '11px'
        },
        [theme.breakpoints.up(601)]: {
            fontSize: '21px',
            fontWeight: "bold",
            fontStyle: "normal",
            letterSpacing: 0,
            color: PRIMARY_COLOR,
            paddingTop: '9px',
            paddingBottom: '13px'
        }
    },
    iconButton: {
        padding: '0px',
        width: '32px',
        height: '32px'
    },
    iconButtonLabel: {
        width: '100%',
        height: '100%'
    }
}));

export default function Section({title, standalone = true, handleAddRequest, children, paddingRight}) {
    const styles = useStyles();

    const sectionHeaderStyle = paddingRight ? {paddingRight} : {};

    return (
        <div className={styles.section}>
            <div className={styles.sectionHeader} style={sectionHeaderStyle}>
                {title &&
                <div className={styles.sectionTitle}>{title}</div>
                }
                {handleAddRequest &&
                <div>
                    <IconButton data-testid="sectionIconButton" type="button" classes={{label: styles.iconButtonLabel}} className={styles.iconButton}
                                aria-label="search" onClick={handleAddRequest}>
                        <img className={styles.iconButtonLabel}  src='../images/iconAddAppointment.svg' alt="add appointment"/>
                    </IconButton>
                </div>
                }
            </div>
            {children}
        </div>
    )
}
