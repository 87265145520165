import { useEffect, useState } from "react";
import EvvButton from "../../../common/components/EvvButton";
import CarelogicMobileLogo from "../../../assets/images/carelogic-mobile-logo.png";
import InputForPassword from "../ResetPasswordDialog/InputForPassword";
import * as rdd from "react-device-detect";
import { getDevicePlatformType } from "../../../common/utils/systemUtils";
import StaffService from "../../../common/services/staffService";
import AlertDialog from "../../../common/components/AlertDialog";
import useStyles from "../../../common/stylesCarelogicMobile";
import { evvRepository } from "../../../db/evv";
import { useAuth } from "react-oidc-context";
import {useHistory} from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function OfflinePinUpdatePage() {

  const PIN = "pin";
  const CONFIRM_PIN = "confirmPin";
  const MOBILE_PLATFORM = "Mobile";
  const ERROR_MSG_MISSING_PIN = "Please enter a PIN";
  const ERROR_MSG_MISSING_CONFIRM_PIN = "Please re-enter your PIN";
  const ERROR_MSG_DIGITS_REQUIRED_PIN = "PIN must be 4 digits";
  const ERROR_MSG_NOT_MATCHED_PIN = "PIN does not match";
  const ERROR_DIALOG_NETWORK_CONNECTION_ERROR = "ERROR_DIALOG_NETWORK_CONNECTION_ERROR";
  const ERROR_DIALOG_SERVICE_RESPONSE_ERROR = "ERROR_DIALOG_SERVICE_RESPONSE_ERROR";

  const isHandheld = (getDevicePlatformType(rdd) === MOBILE_PLATFORM);

  const containerStyleHandheld = isHandheld ? { height:"643px", width: "375px", background: "none", boxShadow: "none"} : {};
  const headerStyleHandheld = isHandheld ? { paddingTop: "24px" } : {};
  const logoStyleHandheld = isHandheld ? { width: "190px", height: "50px" } : {};

  const [pin, setPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const [errors, setErrors] = useState({});
  const [isValidPin, setIsValidPin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [alertDialogConfig, setAlertDialogConfig] = useState(null);

  const styles = useStyles();

  const auth = useAuth();
  const currentUsername = auth.user?.profile?.userName;
  const history = useHistory();

  useEffect(() => {
    if (isValidPin) {
      savePinOffline();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidPin, pin]);

  const savePinOffline = async () => {
    try {
      if (!window.navigator.onLine) {
        showNetworkConnectionError();
        return;
      }

      setIsLoading(true);

      const staffService = new StaffService();
      const currentPinOffLine = await staffService.callReadPinOffLine();

      if (!currentPinOffLine || !currentPinOffLine.resources || currentPinOffLine.resources.length === 0) {
        showServiceResponseError();
        setIsLoading(false);
        return;
      }

      const body = currentPinOffLine.resources[0];
      const currentPin = body?.pin?.trim();

      if (currentPin) {
        await evvRepository.updateEncryptionKey(currentUsername, currentPin, confirmPin);

        const response = await staffService.callWritePinOffLine(pin);
        if (!response || !response.resources || response.resources.length === 0) {
          showServiceResponseError();
          setIsLoading(false);
          return;
        }

        const body = response.resources[0];
        const isValid = body?.pin?.trim() && body.staffId !== null;

        if (isValid) {
          history.push('/settings');
          setIsLoading(false);
        } else {
          showServiceResponseError();
          setIsLoading(false);
        }
      } else {
        showServiceResponseError();
        setIsLoading(false);
      }
    } catch (error) {
      showServiceResponseError();
      setIsLoading(false);
      console.error("Error saving pin offline: ", error);
    }
  };
  
  const showNetworkConnectionError = () => {
    setAlertDialogConfig({
      dialogId: ERROR_DIALOG_NETWORK_CONNECTION_ERROR,
      dialogTitle: "Network connection error",
      dialogMessage: "The device is offline. Please check your network connection and try again.",
      okButtonText: "Close",
      showOkButton: true,
      handleClose: handleDialogClose
    });
  }

  const showServiceResponseError = () => {
    setAlertDialogConfig({
      dialogId: ERROR_DIALOG_SERVICE_RESPONSE_ERROR,
      dialogTitle: "Service response error",
      dialogMessage: "A problem occurred with the service response. Please try again later.",
      okButtonText: "Close",
      showOkButton: true,
      handleClose: handleDialogClose
    });
  }

  const handleDialogClose = () => {
    setIsValidPin(false);
    setAlertDialogConfig(null);
  }

  const validate = (id, value) => {
    const newErrors = { ...errors };
    if (id === PIN) {
      if (value.length === 0) {
        newErrors.pin = ERROR_MSG_MISSING_PIN;
      } else if (value.length < 4) {
        newErrors.pin = ERROR_MSG_DIGITS_REQUIRED_PIN;
      } else {
        delete newErrors.pin;
      }
    }

    if (id === CONFIRM_PIN) {
      if (value.length === 0) {
        newErrors.confirmPin = ERROR_MSG_MISSING_CONFIRM_PIN;
      } else if (value.length < 4) {
        newErrors.confirmPin = ERROR_MSG_DIGITS_REQUIRED_PIN;
      } else {
        delete newErrors.confirmPin;
      }
    }

    if (pin.length === 4 && confirmPin.length === 4 && pin !== confirmPin) {
      newErrors.match = ERROR_MSG_NOT_MATCHED_PIN;
    } else {
      delete newErrors.match;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (event) => {
    const { id, value } = event.target;

    // Ensure the input value consists of 0 to 4 digits only
    if (/^\d{0,4}$/.test(value)) {
      if (id === PIN) {
        setPin(value);
      } else if (id === CONFIRM_PIN) {
        setConfirmPin(value);
      }

      validate(id, value);
    }
  };

  const handleCreatePinRequest = (event) => {
    event.preventDefault();
    const isPinValid = validate(PIN, pin);
    const isConfirmPinValid = validate(CONFIRM_PIN, confirmPin);

    if (pin.length === 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        pin: ERROR_MSG_MISSING_PIN,
      }));
    }

    if (confirmPin.length === 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmPin: ERROR_MSG_MISSING_CONFIRM_PIN,
      }));
    }

    if (isPinValid && isConfirmPinValid && (pin === confirmPin)) {
      setIsValidPin(true);
    }
  };

  const handleCancel = (event) => {
    event.preventDefault();
    history.push('/settings');
  };

  return ( 
    <>
      <div className={styles.tabletSetOfflinePin}>
        <div className={styles.offlinePageContainer} style={containerStyleHandheld}>
          <div className={styles.header} style={headerStyleHandheld}>
            <img className={styles.logo} src={CarelogicMobileLogo} alt="Carelogic Mobile" style={logoStyleHandheld} />
          </div>
          <div className={styles.content}>
            <div className={styles.text}>Change Offline PIN</div>
            <div className={styles.description}>Enter a 4-digit PIN to use when accessing Carelogic Mobile offline.</div>
            <form className={styles.formContainer} onSubmit={handleCreatePinRequest} noValidate autoComplete="off">
            <div className={styles.spaceBelow}>
                <div className={styles.textWrapperLabel}>New Offline PIN<span className={styles.mandatoryIndicator}>*</span></div>
                <InputForPassword id="pin" value={pin} placeholder="Enter PIN" type="password" onChange={handleChange}/>
                {errors.pin && <div className={styles.errorMessage}>{errors.pin}</div>}
              </div>
              <div className={styles.spaceBelow}>
                <div className={styles.textWrapperLabel}>Confirm Offline PIN<span className={styles.mandatoryIndicator}>*</span></div>
                <InputForPassword id="confirmPin" value={confirmPin} placeholder="Re-enter PIN" type="password" onChange={handleChange}/>
                {errors.confirmPin && <div className={styles.errorMessage}>{errors.confirmPin}</div>}
                {!errors.confirmPin && errors.match && <div className={styles.errorMessage}>{errors.match}</div>}
              </div>

              {isLoading && (
                <div className={styles.loadingSpinnerContainer}>
                  <CircularProgress size={40} className={styles.loadingSpinner} />
                </div>
              )}

              {!isLoading && (
                isHandheld ? (
                  <>
                    <div className={styles.spaceBelowButton}>
                      <EvvButton id="CreatePinButton" type="primary" onClick={handleCreatePinRequest}>Change PIN</EvvButton>
                    </div>
                    <div className={styles.spaceBelowButton}>
                      <EvvButton id="CreatePinButton"
                        type="primary"
                        styleOverride={{ paddingTop: '0px', color: '#3b276a', background: '#ffffff', border: '1px solid #3b276a' }}
                        onClick={handleCancel}>Cancel</EvvButton>
                    </div>
                  </>
                ) : (
                  <>
                  <div className={styles.spaceBelowButton}>
                    <EvvButton id="CreatePinButton" type="primary" onClick={handleCreatePinRequest}>Change PIN</EvvButton>
                    </div>
                    <div className={styles.spaceBelowButton}>
                        <EvvButton id="CreatePinButton"
                          type="primary"
                          styleOverride={{ paddingTop: '0px', color: '#3b276a', background: '#ffffff', border: '1px solid #3b276a' }}
                          onClick={handleCancel}>Cancel</EvvButton>
                      </div>
                  </>
                )
              )}

            </form>
          </div>
        </div>
      </div> 
      { alertDialogConfig &&
        <AlertDialog
            open={true}
            dialogTitle={alertDialogConfig.dialogTitle}
            dialogMessage={alertDialogConfig.dialogMessage}
            showOkButton={alertDialogConfig.showOkButton}
            showCancelButton={alertDialogConfig.showCancelButton}
            okButtonText={alertDialogConfig.okButtonText || 'Ok'}
            cancelButtonText={alertDialogConfig.cancelButtonText || 'Cancel'}
            handleClose={alertDialogConfig.handleClose}
        />
      }
    </>
  );
  
}
