import * as msgConst from '../common/Messages';
import * as validateFn from './ValidateFieldUtils';
import { loopAllBusinessRules } from '../common/BusinessRuleUtils'; 

export const checkIsMandatory = (type, field) => {
    switch (type) {
        case "Text Field":
            validateFn.validateFieldsForTextField(field);
        break;
            
        case "CheckBox":
        case 'Descriptor CheckBox':
            validateFn.validateFieldsForCheckbox(field);
        break;

        case "DropDown" :
        case "Descriptor DropDown" :
        case "ActiveMedicationDropDown":
        case "ProgramDropDown":
        case "StaffDropDown":
        case "ReferralSourceDropDown":
            validateFn.validateFieldsForDropdown(field);
        break;
        
        case "Gender":
        case "RadioButton":
            validateFn.validateFieldsForRadio(field);
        break;
        case "Address":
            validateFn.validateFieldsForAddress(field);
        break;

        case "Yes or No":
            validateFn.validateFieldsForYesOrNO(field);
        break;

        case "Text Area":
            validateFn.validateFieldsForTextArea(field);
        break;
        
        case "Phone":
            validateFn.validatePhoneField(field);
        break;

        case "Date":
            validateFn.validateDateField(field);
        break;
            
        case "Time":
            validateFn.validateTimeField(field);
        break;

        case "DateTime":
            validateFn.validateDateTimeField(field);
        break;

        case "SSN":
            validateFn.validateSSNField(field);
        break;

        default:
        break;
    }
}

export const loopThroughField = (field) => {
    let isMandatory = false;
    const { type } = field;

    if(field.businessRuleApplied === "Hide") {
        field.hasBRError = false;
        field['businessRuleError'] = "";
    } else {
        let filteredObj = field.attributes.filter((attribute) => attribute.attributeId === field.id +"_required");
        if(filteredObj[0] !== undefined) {
            isMandatory = filteredObj[0].value;
        }
    } 

    field['requiredFieldErrorMessage'] = "";

    // eslint-disable-next-line
    if (isMandatory === true || isMandatory == "true") {
        checkIsMandatory(type, field);
    }
}

export const validateFields = (module, nextClicked, selectedClient, descriptorData) => {
    let validateResult = {
        isValid: true,
        showFloatingErrorMsg: false
    }

    let count = 0;

    loopAllBusinessRules(module, selectedClient, descriptorData);

    module?.configurableForm?.fields?.forEach((field) => {
        field.nextClicked = nextClicked;
        // If a business rule indicates that the field must be hidden then even if it's required, it becomes not required.
        loopThroughField(field);

        if ( (field.hasBRError && field.hasBRError === true) || (field.onChangeError && field.onChangeError !== '') || (field.requiredFieldErrorMessage && field.requiredFieldErrorMessage !== '') ){
            count++;
        }
    });

    if(count > 0 ) {
        if(count > 1) {
            validateResult.showFloatingErrorMsg = true;
            module['floatingErrorMessage'] = `${count} ${msgConst.floatingErrorMessageString}`;
        }
        validateResult.isValid = false;
    }
    return validateResult;
}

export const validateSignaturesPDF = (externalSignatures, setErrors) => {
    let newErrors = { signatures: [], names: [], unableReasons: [] };
    let isValid = true;

    externalSignatures?.forEach((signatureObj, index) => {
        const { name, signature, unableToCollectYN, unableReason } = signatureObj;

        // Check if name is required but missing
        if (!name && signature) {
            newErrors.names[index] = msgConst.errorMessageString;
            isValid = false;
        }

        if (name && !signature) {
            newErrors.signatures[index] = msgConst.errorMessageString;
            isValid = false;
        }

        // Check if reason is required but missing (if unwilling is 'yes')
        if (unableToCollectYN === 'Y' && !unableReason) {
            newErrors.unableReasons[index] = msgConst.errorMessageString;
            isValid = false;
        }

       // if all fields are undefined
        if (!name && !signature && !unableToCollectYN && !unableReason) {
            newErrors.names[index] = "";
            newErrors.signatures[index] = "";
            newErrors.unableReasons[index] = "";
        }

    });
    setErrors(newErrors);
    return isValid;
};