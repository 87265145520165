import { makeStyles } from "@material-ui/core/styles";
import { PRIMARY_COLOR } from "./constants";

const useStyles = makeStyles((theme) => ({
    tabletSetOfflinePin: {
        width: "100%",
        height: "100%",
        paddingLeft: theme.appPaddingX + "px",
        paddingRight: theme.appPaddingX + "px",
        background: "#f9f9f9",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    offlinePageContainer: {
        width: "450px",
        height: "625px",
        background: "#ffffff",
        borderRadius: "8px",
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)",
        marginTop: "8px",
        padding: "32px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "12px",
    },
    offlinePageUpdateContainer: {
        width: "450px",
        height: "570px",
        background: "#ffffff",
        borderRadius: "8px",
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)",
        marginTop: "8px",
        padding: "32px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "12px",
    },
    tabletSetLoginPageError: {
        width: "100%",
        height: "100%",
        paddingLeft: theme.appPaddingX + "px",
        paddingRight: theme.appPaddingX + "px",
        background: "#f9f9f9",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    loginPageErrorContainer: {
        width: "450px",
        height: "360px",
        background: "#ffffff",
        borderRadius: "8px",
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)",
        marginTop: "8px",
        padding: "32px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "12px",
    },
    stack: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        width: "100%",
    },
    header: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        width: "100%",
        marginBottom: "24px",
    },
    logo: {
        width: "240px",
        height: "64px",
    },
    content: {
        width: "100%",
    },
    text: {
        fontFamily: "Arial",
        fontSize: "20px",
        fontWeight: "bold",
        color: "#28292b",
        marginBottom: "8px",
    },
    description: {
        fontFamily: "Arial",
        fontSize: "14px",
        color: "#28292b",
        marginBottom: "24px",
    },
    descriptionSignature: {
        fontFamily: "Arial",
        fontSize: "14px",
        color: "#28292b",
        marginBottom: "12px",
    },
    textWrapperLabel: {
        fontFamily: "Arial",
        fontSize: "16px",
        fontWeight: "bold",
        color: "#28292b",
        marginBottom: "8px",
    },
    textWrapperLabelInfo: {
        fontFamily: "Arial",
        fontSize: "16px",
        fontWeight: "bold",
        color: PRIMARY_COLOR,
        marginBottom: "24px",
    },
    iconInfoIndicator: {
        color: PRIMARY_COLOR,
        marginLeft: "0.70ch"
    },
    subline: {
        fontFamily: "Arial",
        fontSize: "14px",
        color: "#696b6d",
        marginBottom: "16px",
    },
    inputContainer: {
        width: "100%",
        backgroundColor: "#ffffff",
        border: "2px solid #c5c7ca",
        borderRadius: "5px",
        marginBottom: "24px",
        padding: "12px",
    },
    errorMessage: {
        position: "absolute",
        letterSpacing: 0,
        color: "#dc0707",
        fontSize: "13px",
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: "16px",
        marginTop: "8px",
        marginBottom: "24px",
    },
    spaceBelow: {
        paddingBottom: "38px",
        paddingTop: "6px",
        position: "relative",
    },
    spaceBelowButton: {
        paddingBottom: "12px",
        paddingTop: "6px",
        position: "relative",
    },
    mandatoryIndicator: {
        paddingLeft: "4px",
        verticalAlign: "text-bottom",
        fontSize: "18px",
        fontWeight: "bold",
        color: "#FF3B30",
        paddingTop: "4px",
    },
    modalSignatureRequirements: {
        textAlign: "left",
        padding: "0px 16px 0px 0px"
    },
    loadingSpinnerContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));

export default useStyles;