import { evvRepository } from "../../db/evv";

const getPgoiCollection = (txPlan) => {
    return evvRepository?.evvDb?.goalAddressedPgoi.where("tPlanMasterId").equals(txPlan.tPlanMasterId).toArray();
}

const getPgoMapCollection = (txPlan) => {
    return evvRepository?.evvDb?.goalAddressedPgoMap.where("tPlanMasterId").equals(txPlan.tPlanMasterId).toArray();
}

const getPgoIntMapCollection = (txPlan) => {
    return evvRepository?.evvDb?.goalAddressedPgoIntMap.where("tPlanMasterId").equals(txPlan.tPlanMasterId).toArray();
}

const getConfigServiceDoc = async(serviceDocumentId) => {
    return evvRepository.evvDb.serviceDocument.where("setupId").equals(serviceDocumentId).toArray();
}

const getDescriptorsForStatus = async(organizationId) => {
    return evvRepository.evvDb.descriptor.where(['type+organizationId']).anyOf(['Tx Plan Goals Addressed Status', organizationId], ['Tx Plan Goals Addressed Status', '']).toArray();
}

const goalAddressedService = {
    getPgoiCollection,
    getPgoMapCollection,
    getPgoIntMapCollection,
    getConfigServiceDoc,
    getDescriptorsForStatus
};

export default goalAddressedService;