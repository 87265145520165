import {makeStyles} from "@material-ui/core/styles";
import {useState} from "react";
import { PRIMARY_COLOR } from "../../constants";

const useStyles = makeStyles((theme) => ({
    switch: {
        width: '104px',
        height: '32px',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        borderRadius: '3px',
        backgroundColor: "#ffffff",
        borderStyle: "solid",
        borderWidth: '1px',
        borderColor: PRIMARY_COLOR
    },
    unselected: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '3px',
        backgroundColor: "#ffffff",
        "& > div": {
            fontSize: "14px",
            fontWeight: "normal",
            fontStyle: "normal",
            letterSpacing: 0,
            textAlign: "center",
            color: PRIMARY_COLOR
        }
    },
    unselectedRight: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '3px',
        borderLeft: '1px solid #3b276a',
        backgroundColor: "#ffffff",
        "& > div": {
            fontSize: "14px",
            fontWeight: "normal",
            fontStyle: "normal",
            letterSpacing: 0,
            textAlign: "center",
            color: PRIMARY_COLOR
        }
    },
    selected: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '3px',
        backgroundColor: PRIMARY_COLOR,
        "& > div": {
            fontSize: "14px",
            fontWeight: "normal",
            fontStyle: "normal",
            letterSpacing: 0,
            textAlign: "center",
            color: "#ffffff"
        }
    },
    divider: {
        height: '100%',
        width: '1px',
        backgroundColor: '#0279c0'
    }
}));

export default function Switch({onText = 'ON', offText = 'OFF', value, onChange, nullable, id}) {
    const [on, setOn] = useState(value);
    const styles = useStyles();

    const handleOn = () => {
        setOn(true);
        if (onChange){
            onChange(true);
        }
    };

    const handleOff = () => {
        setOn(false);
        if (onChange){
            onChange(false);
        }
    };

    const onClassName = on === true ? styles.selected : styles.unselected;
    let offClassName = on === true ? styles.unselected : styles.selected;

    if (nullable === true){
        if (on !== false && on !== true){
            offClassName = styles.unselectedRight;
        } else {
            offClassName = on === false ? styles.selected : styles.unselected;
        }
    }

    return (
        <div className={styles.switch}>
            <div id={id + 'SwitchOn'} data-testid='switchOnContainer' className={onClassName} onClick={handleOn} >
                <div>{onText}</div>
            </div>
            <div id={id + 'SwitchOff'} data-testid='switchOffContainer' className={offClassName} onClick={handleOff} >
                <div>{offText}</div>
            </div>
        </div>
    );
}
