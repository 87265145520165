import React from "react";
import FormField from "./FormField";
import DisplayErrorMessages from "../common/DisplayErrorMessages";
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Stack from '@mui/material/Stack';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import TextField from '@mui/material/TextField';
import { defaultDateAsString } from "../../../common/utils/formatUtils";
import { convertToDatejs } from "../common/HandleChangedInputUtils";
import { setValue } from "./DateUtils";
import { styled } from "@material-ui/styles";
const CancelText=styled('span')({
    textTransform:'initial'
});
const OkText=styled('span')({
    textTransform:'initial'
});

class DateField extends FormField{
 
   constructor(props) {
       super(props);
       this.dateValue = '';
   }
 
   setDateValue = (newValue) => {
        setValue(this.fieldDescriptor, newValue, this.dateValue);
   }

   handleDateChange = (date) => {
    this.dateValue = `${date?.toLocaleString('en-US', { year: 'numeric', month:'2-digit',day:'2-digit' })}`;
    }

    getDateValue = () => {
        let dateInstance = convertToDatejs(this.fieldDescriptor?.dbSavedValue);
        if(dateInstance) {
            this.dateValue = defaultDateAsString(dateInstance);
        }
        return this.dateValue;
    }
    
   renderDate = (props, isRequired, disabled) => {
    
       return (
           <LocalizationProvider dateAdapter={AdapterDateFns}>
               <Stack>
                   <MobileDatePicker
                       value={ this.getDateValue()}
                       showToolbar={false}
                       onAccept={this.handleDateChange}
                       onChange={(newValue) => {
                        this.setDateValue(newValue);
                        props.onChange(newValue, this.fieldDescriptor, props);
                        
                       }}
                       disabled = { disabled }
                       cancelText={<CancelText>Cancel</CancelText>}
                       okText={<OkText>Ok</OkText>}
                       renderInput={(params) => <TextField disabled={false}
                           id={`date-${this.fieldDescriptor.id}`}
                           sx={{
                            "& .MuiInputBase-root": {
                            height: "42px",
                            width: "226px",
                            backgroundColor: "#ffffff",
                            borderStyle: "none",
                            borderWidth: 0,
                            borderColor: "#ffffff",
                            fontSize: '16px',
                            fontWeight: "normal",
                            fontStyle: "normal",
                            letterSpacing: '.21px',
                            color: "#888888",
                            }
                        }}
                           {...params}
                           error={false}
                           required={isRequired}
                           focused={false}
                       />}
                   />
               </Stack>
           </LocalizationProvider>
       );
   };
 
   renderField(props, state){
       let isRequired = this.isMandatory();
       let description = "";
       let filteredObj = this.fieldDescriptor.attributes.find((attribute) => attribute.attributeId === this.fieldDescriptor.id +"_description");
       if(Object.keys(filteredObj).length > 0) {
           description = filteredObj.value;
       }
       props.fieldDescriptor = this.fieldDescriptor;
 
       return (
           <div>
               {this.fieldDescriptor?.businessRuleApplied !== "Hide" &&
               <>
                   <div>
                        <span>{description}</span>
                   </div>
                    <div>
                        <div className="dateField">
                            {this.renderDate(props, isRequired, state.isReadOnlyDoc)}
                        </div>
                    </div>
                    <div>
                        <DisplayErrorMessages
                            {...props} 
                        />
                    </div>
               </>
               }
           </div>
       )
   }
}
 
export default DateField;