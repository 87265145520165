import {makeStyles} from "@material-ui/core/styles";
import { PRIMARY_COLOR } from "../../../common/constants";
import { useEffect, useRef, useState } from "react";
import userService from "../../../common/services/userService";
import { useDispatch } from "react-redux";
import { appCache } from "../../../cache/slices/app/appSlice";
import { evvRepository } from "../../../db/evv";
import activityLogRepository from "../../../db/activityLogRepository";
import * as rdd from "react-device-detect";
import { getDevicePlatformType } from "../../../common/utils/systemUtils";
import SplashPage from "../SplashPage";
import { useAuth } from "react-oidc-context";
import StaffService from "../../../common/services/staffService";
import OfflinePinPage from '../OfflinePin/OfflinePin';
import ESignaturePage from "../ESignaturePage";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
  documentsPage: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column'
},
  loadingSpinnerContainer: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
  },
  loadingSpinner: {
      color: PRIMARY_COLOR
  }
}));

export default function LoginPagePingOneCallback() {
  let devicePlatformType = getDevicePlatformType(rdd);

  const auth = useAuth();
  const [userProfile, setUserProfile] = useState(null);

  const isHandheld = devicePlatformType === "Mobile" ? true : false;
  const dispatch = useDispatch();
  const history = useHistory();  
  const styles = useStyles();
  const loggedInUserRef = useRef();
  const staffService = new StaffService();
  const [hasOfflinePin, setHasOfflinePin] = useState(undefined);
  const [hasESignature, setHasESignature] = useState(undefined);
  const [offlinePin, setOfflinePin] = useState(undefined);
  const [securityRule, setSecurityRule] = useState(undefined);

  const responseParamsCallBack = {
    PARAM_ERROR: "error",
    ACCESS_DENIED: "access_denied"
  };

  let queryParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    const error = queryParams.get(responseParamsCallBack.PARAM_ERROR);

    if (error) {
      if (error === responseParamsCallBack.ACCESS_DENIED) {
        history.push({
          pathname: "/loginFailed",
          state: { typeError: responseParamsCallBack.ACCESS_DENIED }
        });
      } else {
        history.push({
          pathname: "/loginFailed"
        });
      }
    } else {
      if (auth.isAuthenticated && auth.user?.profile) {
        const {
          userName,
          account,
          staffId,
          family_name: familyName,
          given_name: givenName,
          primaryOrganizationId: orgId,
        } = auth.user.profile;

        setUserProfile({
          userName,
          account,
          staffId,
          familyName,
          givenName,
          orgId,
        });

        handleLoginRequest();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.isAuthenticated, auth.user]);

  useEffect(() => {
    if (hasOfflinePin && hasESignature) {
      handleLoginOnlineResponse(offlinePin);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasOfflinePin, hasESignature, offlinePin]);

  const handleLoginRequest = async () => {
    if (auth.user) {
      const accessToken = auth.user.access_token;
      const refreshToken = auth.user.refresh_token;
      const expiresAt = auth.user.expires_at;
      
      if (accessToken && refreshToken && expiresAt) {
        evvRepository.accessToken = accessToken;
        evvRepository.refreshToken = refreshToken;
        evvRepository.expiresAt = expiresAt;
        evvRepository.writingProcess = "false";
      }

      await getSecurityRule();
      attemptLoginOnline();
    }
  };

  const attemptLoginOnline = () => {
    getPinOffline();
    getStaffSignature();
  };

  const handleLoginOnlineResponse = (pin) => {
    if (!userProfile) return;

    const { familyName, givenName, userName, account, staffId, orgId } = userProfile;
    const displayName = `${familyName}, ${givenName} (${staffId})`;
    const userFromServer = {
      firstName: givenName,
      lastName: familyName,
      displayName: displayName,
      userName: userName,
      account: account,
      staffId: staffId,
      staff: staffId,
      orgId: orgId,
      primaryOrganizationId: orgId,
      accessToken: auth.user?.access_token,
      refreshToken: auth.user?.refresh_token,
      expiresInSeconds: auth.user?.expires_at,
      securityRule: securityRule
    };

    return evvRepository.initializeDatabase(userName, pin).then(() => {
      loadExistingUserFromDbOnline(userFromServer);
    });
  };

  const loginSuccessful = (user) => {
    loggedInUserRef.current = user;

    dispatch(appCache.toggleHandheldFlag(isHandheld));
    dispatch(appCache.login(user));
    activityLogRepository.saveActivityLog(user, "Login");
    history.replace({ pathname: "/" });
  };

  const loadExistingUserFromDbOnline = (userFromServer) => {
    userService
      .loadExistingUserFromDb()
      .then((userFromDb) => {
        if (userFromDb) {
          if (userFromDb.userName === userFromServer.userName) {
            loginSuccessful(userFromServer);
          }
        } else {
          loginSuccessful(userFromServer);
        }
      })
      .catch((error) => {
        console.log("Error loading existing user from db - probably different password");
        console.log(error);
        loggedInUserRef.current = userFromServer;
      });
  };

  const getPinOffline = async () => {
    try {
      const response = await staffService.callReadPinOffLine();

      if (response.resources && response.resources.length > 0) {
        const body = response.resources[0];
        const offlinePin = body?.pin?.trim();

        if (offlinePin) {
          setHasOfflinePin(true);
          setOfflinePin(offlinePin);
        } else {
          setHasOfflinePin(false);
          console.log('Pin is empty in response payload.');
        }
      } else {
        setHasOfflinePin(false);
        console.log('Could not find pin in payload.');
      }
    } catch (error) {
      console.error('Error calling read Pin Offline: ', error);
    }
  };

  const getStaffSignature = async () => {
    try {
      const response = await staffService.callReadSignature();

      if (response.resources && response.resources.length > 0) {
        const body = response.resources[0];
        const electronicSignature = body?.electronicSignature?.trim();

        if (electronicSignature) {
          setHasESignature(true);
        } else {
          setHasESignature(false);
          console.log('Signature is empty in response payload.');
        }
      } else {
        setHasESignature(false);
        console.log('Could not find signature in payload.');
      }
    } catch (error) {
      console.error('Error calling read Signature: ', error);
    }
  };

  const getSecurityRule = async () => {
    try {
      const response = await staffService.callReadSecurityRule();
      if (!response || !response.resources || response.resources.length === 0) {
        console.error('Error read Security Rules validation payload.');
        return;
      }
 
      setSecurityRule(response?.resources[0]);
    } catch (error) {
      console.error("Error read Security Rules: ", error);
    }
  };

  const renderPage = () => {
    if (hasOfflinePin === undefined || hasESignature === undefined) {
      return (
        <div className={styles.loadingSpinnerContainer}>
            <CircularProgress size={50} className={styles.loadingSpinner}/>
        </div>
      );
    } else {
      if (!hasOfflinePin) {
        return <OfflinePinPage />;
      } else if (!hasESignature) {
        return <ESignaturePage />;
      } else {
        return <SplashPage />;
      }
    }
  };

  return ( <div className={styles.documentsPage}>{ renderPage() }</div> );

}
