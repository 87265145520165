import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from "@material-ui/core/styles";
import EvvButton from "../EvvButton";
import TimePicker from "./TimePicker";
import {useEffect, useState} from "react";

const useStyles = makeStyles(() => ({
    dialogPaper: {
        width: '250px'
    },
    dialogTitle: {
        maxWidth: '250px',
        maxHeight: '800px',
        height: '42px',
        width: 'auto',
        fontSize: '16px',
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: "#ffffff",
        paddingLeft: '16px',
        display: 'none',
        alignItems: 'center'
    },
    dialogContent: {
        padding: "0px",
    },
    okCancelButtonGroup: {
        padding: "0px 16px",
        justifyContent: 'flex-start',
        paddingLeft: '80px',
    },
    okButton: {
        padding: '0',
        minWidth: '75px',
        width: '75px',
        paddingRight: '40px',
    },
    cancelButton: {
        padding: '0',
        minWidth: '75px',
        width: '75px'
    }
}));

export default function TimePickerDialog({value, onClose}) {
    const [timeAsText, setTimeAsText] = useState(value);
    const styles = useStyles();

    useEffect(() => {
        setTimeAsText(value);
    }, [value]);

    const handleTimeChange = (changedTime) => {
        setTimeAsText(changedTime);
    };

    const handleSave = () => {
        if (onClose) {
            onClose(timeAsText);
        }
    };

    const handleCancel = () => {
        if (onClose) {
            onClose(null);
        }
    }

    const renderContent = () => {
        return (
            <div>
                <TimePicker value={timeAsText} onChange={handleTimeChange} />
            </div>
        );
    }

    return (
        <div>
            <Dialog
                classes={{paper: styles.dialogPaper}}
                open={true}
                disableEscapeKeyDown={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle classes={{root: styles.dialogTitle}} id="alert-dialog-title">Choose Time</DialogTitle>
                <DialogContent classes={{root: styles.dialogContent}}>
                    {renderContent()}
                </DialogContent>
                <DialogActions classes={{root: styles.okCancelButtonGroup}}>
                    <div className={styles.cancelButton}>
                        <EvvButton type='okcancelbutton' onClick={handleCancel}>Cancel</EvvButton>
                    </div>
                    <div className={styles.okButton}>
                        <EvvButton type='okcancelbutton' onClick={handleSave}>Ok</EvvButton>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
}
