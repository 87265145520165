import {getDistance, convertDistance} from "geolib";
import Bowser from "bowser";
import * as rdd from 'react-device-detect';

let SYSTEM_INFORMATION = {};

export const getConnectionType = (connection) => {
    if (connection && connection.type){
        return connection.type;
    }else{
        return 'Not Supported';
    }
}

export const getSystemInformation = () => {
    if (typeof navigator !== "undefined" && !SYSTEM_INFORMATION.browserName) {
        const systemInformation = Bowser.getParser(navigator.userAgent);
        if (systemInformation){
            const platformType = getDevicePlatformType(rdd);

            SYSTEM_INFORMATION = {
                browserName: systemInformation.getBrowserName(),
                browserVersion: systemInformation.getBrowserVersion(),
                osName: getOSName(rdd, systemInformation),
                osVersion: getVersionName(systemInformation),
                platformType: platformType,
                deviceType: getDeviceTypeAndModel(platformType, rdd),
                result: systemInformation.getResult(),
                network: navigator.connection ? getConnectionType(navigator.connection) : 'Unsupported'
            }
        }
    }

    return SYSTEM_INFORMATION;
}

export const getDevicePlatformType = (rdd) => {
    if (rdd.isTablet) {
        return 'Tablet';
    } else if (rdd.isMobile) {
        return 'Mobile';
    } else {
        return 'Desktop';
    }
}

export const getVersionName = async (systemInformation) => {
    let vName = '';

    if (getOSName(rdd, systemInformation) === 'Android' || getOSName(rdd, systemInformation) === 'Windows') {
        const platformDetail = await getPlatformVersion();
        vName = platformDetail;
    } else {
        if(systemInformation?.getOSVersion() !== undefined) {
            vName = systemInformation.getOSVersion();
        }
    }
    return vName;
}

export const getPlatformVersion = async () => (
    'userAgentData' in navigator ? await navigator.userAgentData.getHighEntropyValues(["platform", "platformVersion"]) : {}
).platformVersion || '';

export const getOSName = (rdd, systemInformation, platformType) => {
    let osName = '';
    if(rdd.isIOS && platformType === 'Tablet') {
        osName = "iPadOS";
    } else if(systemInformation?.getOSName() !== undefined) {
        osName = systemInformation.getOSName();
    } else {
        osName = rdd.osName;
    }
    return osName;
}

export const getDeviceTypeAndModel = (platformType, rdd) => {
    let deviceType = '';

    if(platformType === 'Tablet'){
        if(rdd.isIOS) {
            deviceType = 'Apple - iPad';
        } else {
            deviceType = deviceValuesCheckFn(platformType);
        }
    } else {
        deviceType = deviceValuesCheckFn(platformType);
    }

    return deviceType;
}

export const deviceValuesCheckFn = (platformType) => {
    let deviceType = '';
    let valuesCheck = ['', 'none', 'undefined'];
    if(!valuesCheck.includes(rdd.mobileVendor.trim()) && !valuesCheck.includes(rdd.mobileModel.trim())) {
        deviceType = `${rdd.mobileVendor} - ${rdd.mobileModel}`;
    } else {
        deviceType = `${platformType} - ${rdd.osName}`;
    }
    return deviceType;
}

export const getUserAgent = () => {return navigator.userAgent};
export const android = () => {return /Android/i.test(getUserAgent()) && !windows()};
export const iPhone = () => {return /iPhone/i.test(getUserAgent()) && !iPad() && !windows()};
export const iPod = () => {return /iPod/i.test(getUserAgent())};
export const iPad = () => {return /iPad/i.test(getUserAgent())};
export const iOS = () => {return (iPad() || iPod() || iPhone() || iWhatever())};
export const iWhatever = () => {return (
    (navigator.platform.indexOf("iPhone") !== -1) ||
    (navigator.platform.indexOf("iPad") !== -1) ||
    (navigator.platform.indexOf("MacIntel") !== -1) ||
    (navigator.platform.indexOf("iPod") !== -1))
};
export const opera = () => {return /Opera Mini/i.test(getUserAgent())};
export const windows = () => {return /Windows Phone|IEMobile|WPDesktop/i.test(getUserAgent())};
export const isMobile = () => {return (android() | iOS() || opera())};

export const showPhoneApplication = (phoneNumber) => {
    if (isMobile()){
        if (phoneNumber) {
            document.location.href = 'tel:+1' + phoneNumber;
            return true;
        }
    }

    return false;
};

const addAddressPart = (part) => {
    return part ? '+' + part : '';
}

export const showMapApplication = (client) => {
    if (client && client.addresses && client.addresses.length > 0){
        const addressEntity = client.addresses[0]
        const address = addressEntity.street1
            + addAddressPart(addressEntity.city)
            + addAddressPart(addressEntity.state)
            + addAddressPart(addressEntity.zip);

        if (iOS()){
            window.open("maps://maps.google.com/maps?q=" + address);
        } else {
            window.open("https://maps.google.com/maps?q=" + address);
        }
    }
};

export const showEmailApplication = (emailAddress) => {
    window.open("mailto:" + emailAddress);
};

export const getDistanceInMeters = (start, end) => {
    return getDistance(start, end);
};

export const getDistanceInMiles = (start, end) => {
    const distanceInMeters = getDistanceInMeters(start, end);

    return convertDistance(distanceInMeters, 'mi');
};

export function getHostName(){
    let hostName = window.location.hostname

    switch (hostName){
        case 'evv-mobile-dev.qualifacts.org':
            hostName = 'MOBILE-DEV'
            break;
        case 'evv-qareg.qualifacts.org':
            hostName = 'QAREG'
            break;
        case 'evv-cert.qualifacts.org':
            hostName = 'CERT'
            break;
        case 'evv-perf.qualifacts.org':
            hostName = 'PERF'
            break;
        case 'evv.qualifacts.org':
            hostName = 'PROD'
            break;
        case 'evv-mobile-kony-uat.qualifacts.org':
            hostName = 'MOBILE-KONY-UAT'
            break;
        default:
            hostName = 'DEV';
    }
    return hostName;
}
