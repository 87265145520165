import { focusOnElement } from '../common/FindFirstErrorFieldUtils';
import * as msgConst from '../common/Messages';

export const loopMappedData = (mapping, mapData, eleName) => {
    mapping?.forEach(ele => {
        let mappedEle = mapData?.find(data => data?.pgoMapId === ele?.mapId);
        if (mappedEle) {
            mappedEle[eleName] = true;
        }
    });
}

export const openAccordian = (element, pgoi) => {
    let mapping = element?.mapping;
    let pgoMapData = pgoi?.pgoMapData;
    let pgoIntMapData = pgoi?.pgoIntMapData;

    if (mapping && mapping.length > 0) {
        let eleName = `is${element?.type}Expanded`;
        let directEle = mapping.find(map => map?.mapIdType === "pgoiId");

        if (directEle) {
            //Unlinked interventions case
            element[eleName] = true;
        } else {
            if (element?.type === "I") {
                loopMappedData(mapping, pgoIntMapData, eleName);
            } else {
                loopMappedData(mapping, pgoMapData, eleName);
            }
        }
    }
}

export const errorMsgAssignment = (call, element, errorMsg) => {
    if (call === "status") {
        element.statusErrorMsg = errorMsg;
    } else {
        element.commentErrorMsg = errorMsg;
    }
}

export const errorAssignment = (call, element, data, label, count, pgoi, fnCall) => {
    let fieldErrorExists;

    if (call === "status") {
        fieldErrorExists = data?.goalAddressedDetail?.find(detail => element?.pgoiId === detail?.pgoId && detail.isAddressed && detail?.status === "");
    } else {
        fieldErrorExists = data?.goalAddressedDetail?.find(detail => element?.pgoiId === detail?.pgoId && detail.isAddressed && detail?.comment === "");
    }
    
    if (fieldErrorExists) {
        if (fnCall !== "handleFieldChange") {
            openAccordian(element, pgoi);
        }

        let errorMsg = `${label} is a required field. Please enter a value.`;
        element.gaError = true;
        errorMsgAssignment(call, element, errorMsg);
        count++;
    } else {
        errorMsgAssignment(call, element, "");
    }

    return count;
}

export const addFocus = (ele, uiElements, fnCall) => {
    if (uiElements && uiElements.length > 0) {
        uiElements.forEach(ui => {
            if (ele?.gaError && fnCall !== "handleFieldChange") {
                ui.className += " input-error";
            }
        });
    }
}

export const removeFocus = (statusUi, commmentUi) => {
    if (statusUi) {
        statusUi.classList.remove("input-error");
    }

    if (commmentUi) {
        commmentUi.classList.remove("input-error");
    }
}

export const getUiElements = (ele, treatmentPlanId) => {
    let uiElements = [];

    if (ele?.mapping && ele?.mapping.length > 0) {
        ele?.mapping?.forEach(map => {
            let uniqueId = (map?.mapIdType === 'pgoiId') ? map?.mapId + "-" + treatmentPlanId : map?.mapId + "-" + ele?.pgoiId + "-" + treatmentPlanId;
            let statusUi = document.getElementById('status-' + uniqueId);
            let commmentUi = document.getElementById('comments-' + uniqueId);

            removeFocus(statusUi, commmentUi);

            if (ele?.gaError && ele?.statusErrorMsg !== "" && statusUi) {
                uiElements.push(statusUi);
            }

            if (ele?.gaError && ele?.commentErrorMsg !== "" && commmentUi) {
                uiElements.push(commmentUi);
            }
        });
    }

    return uiElements;
}

export const loopThroughElements = (globalConfig, elements, fieldData, count, pgoi, fnCall) => {
    let treatmentPlanId = pgoi?.treatmentPlanId;
    let showHideStatus = globalConfig?.showHideStatus;
    let showHideComment = globalConfig?.showHideComment;
    let statusLabel = globalConfig?.statusLabel;
    let commentLabel = globalConfig?.commentLabel;

    elements?.forEach(ele => {
        ele.gaError = false;

        fieldData?.forEach(data => {
            if (data?.goalAddressedDetail && data?.treatmentPlanId === treatmentPlanId) {
                if (showHideStatus === 'R') {
                    count = errorAssignment('status', ele, data, statusLabel, count, pgoi, fnCall);
                }
                
                if (showHideComment === 'R') {
                    count = errorAssignment('comment', ele, data, commentLabel, count, pgoi, fnCall);
                }
            }
        });

        let uiElements = getUiElements(ele, treatmentPlanId);
        addFocus(ele, uiElements, fnCall);
    });

    return count;
}

export const isTxPlanSelectedFn = (fields) => {
    let isSelected = false;

    if (fields) {
        let gaTxPlans = fields?.find(ele => ele.id === "gaTxPlans");
        if (gaTxPlans) {
            let selectedTxPlans = gaTxPlans?.value;
            isSelected = (selectedTxPlans && selectedTxPlans?.length > 0) ? true : false;
        }
    }

    return isSelected;
}

export const getErrorCount = (pgoi, fieldData, count, fnCall) => {
    let goals = pgoi?.goals;
    let objectives = pgoi?.objectives;
    let interventions = pgoi?.interventions;
    let globalConfig = pgoi?.globalConfig;
    let goalsConfig = pgoi?.goalsConfig;
    let objectivesConfig = pgoi?.objectivesConfig;
    let interventionsConfig = pgoi?.interventionsConfig;
    let showHideStatus = globalConfig?.showHideStatus;
    let showHideComment = globalConfig?.showHideComment;

    if (showHideStatus === 'R' || showHideComment === 'R') {
        if (goalsConfig?.show && goals?.length > 0) {
            count = loopThroughElements(globalConfig, goals, fieldData, count, pgoi, fnCall);
        }

        if (objectivesConfig?.show && objectives?.length > 0) {
            count = loopThroughElements(globalConfig, objectives, fieldData, count, pgoi, fnCall);
        }

        if (interventionsConfig?.show && interventions?.length > 0) {
            count = loopThroughElements(globalConfig, interventions, fieldData, count, pgoi, fnCall);
        }
    }

    return count;
}

export const checkIfNeedsToBeAddressed = (requireSelection, isTxPlanSelected, fieldData, hasAddressedError) => {
    let hasAddressedElements = [];

    if (requireSelection && isTxPlanSelected) {
        //When the Goals Addressed document is configured to Require Selection, a minimum of 1 Addressed checkbox must be selected in a TxPlan.
        //If multiple TxPlans are selected then at least 1 of the TxPlans needs to have a minimum of 1 Addressed checkbox selected.
        fieldData?.forEach(data => {
            if (data?.goalAddressedDetail) {
                let addressedElements = data?.goalAddressedDetail?.filter(ele => ele.isAddressed);
                if (addressedElements.length > 0) {
                    addressedElements?.forEach(ele => {
                        hasAddressedElements.push(ele);
                    });
                }
            }
        });

        if (hasAddressedElements?.length === 0) {
            hasAddressedError = true;
        }
    }

    return hasAddressedError;
}

export const setFloatingErrorMsg = (hasAddressedError, validateResult, module, count) => {
    let floatingErrorMessage = "";

    if (hasAddressedError) {
        validateResult.showFloatingErrorMsg = true;
        floatingErrorMessage = `${msgConst.addressedErrorMsg}`;
    } else if(count > 1) {
        validateResult.showFloatingErrorMsg = true;
        floatingErrorMessage = `${count} ${msgConst.floatingErrorMessageGa}`;
    }

    module.floatingErrorMessage = floatingErrorMessage;
    validateResult.isValid = false;

    return validateResult;
}

export const validateGaFields = (fnCall, module, setErrorCount) => {
    let requireSelection;
    let hasAddressedError = false;
    let count = 0;
    let validateResult = {
        isValid: true,
        showFloatingErrorMsg: false
    }
    let pgoiData = module?.pgoi;
    let fieldData = module?.fieldData;
    let isTxPlanSelected = isTxPlanSelectedFn(module?.fields);

    if (pgoiData && pgoiData.length > 0 && fieldData) {
        pgoiData?.forEach((pgoi) => {
            let globalConfig = pgoi?.globalConfig;
            requireSelection = globalConfig?.requireSelection;
            count = getErrorCount(pgoi, fieldData, count, fnCall);
        });

        hasAddressedError = checkIfNeedsToBeAddressed(requireSelection, isTxPlanSelected, fieldData, hasAddressedError);
    }

    if (hasAddressedError) {
        count++;
    }

    module.hasAddressedError = hasAddressedError;
    module.floatingErrorMessage = "";

    if(count > 0) {
        validateResult = setFloatingErrorMsg(hasAddressedError, validateResult, module, count);

        if (setErrorCount) {
            setErrorCount((prevCount) => ({
                errorCount: prevCount + 1
            }))
        }
    }

    return validateResult;
}

export const gaValidationWithFocus = (call, module, setErrorCount) => {
    let validateResult = validateGaFields(call, module, setErrorCount);
    focusOnElement(module);
    return validateResult;
}