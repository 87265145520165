import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import LoginTextField from "../LoginTextField";
import TimePickerDialog from "./TimePickerDialog";
import { DateTime } from "luxon";
import { defaultTimeAsString } from "../../utils/formatUtils";

const useStyles = makeStyles(() => ({
    timePickerField: {
    }
}));

const TimePickerField = ({ id, onChange, value, defaultValue, disabled}) => {
    const [timePickerValue, setTimePickerValue] = useState(defaultValue);
    const [showPicker, setShowPicker] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const styles = useStyles();

    let time = DateTime.now();
    let currentTimeFormat ='';
    currentTimeFormat = defaultTimeAsString(time);

    useEffect(() => {
        setTimePickerValue(value);
    }, [value])

    const handleClick = (event) => {
        if (anchorEl){
            setAnchorEl(null);
            setShowPicker(false);
        } else {
            setAnchorEl(event.currentTarget);
            setShowPicker(true);
        }
    };

    const handleTimePickerClose = (timeAsText) => {
        if(timeAsText === undefined || timeAsText === "") {
            setTimePickerValue(currentTimeFormat);
            fireEvent(anchorEl);
        }else if(timeAsText){
            setTimePickerValue(timeAsText);
            fireEvent(anchorEl);
        }

        setAnchorEl(null);
        setShowPicker(false);
    }

    const fireEvent = (inputElement) => {
        const event = new InputEvent('input', {
            view: window,
            bubbles: true,
            cancelable: true
        });

        setTimeout(() => inputElement.dispatchEvent(event), 200);
    }

    const renderPickerAsDialog = () => {
        return (
          <TimePickerDialog value={timePickerValue} onClose={handleTimePickerClose} />
        );
    }

    return (
        <div className={styles.timePickerField}>
            <LoginTextField id={id ? id : 'TimePickerField'} onClick={handleClick} onInput={onChange} onChange={onChange} readOnly={true} value={timePickerValue} disabled = { disabled } timeField={true} maxCharacters={8}/>
            {showPicker && renderPickerAsDialog()}
        </div>
    )
}

export default TimePickerField;